import React, { useCallback, useEffect, useState } from "react";
import {
  AssetStore,
  IAssetInAssetModel,
  IAssetModel,
  RecordStatus,
} from "@bms/common-services";
import {
  Button,
  Heading,
  Icon,
  ITableColumnProps,
  ITablePaginationConfig,
  Modal,
  PageContent,
  Pagination,
  Popconfirm,
  TableWithDraggableSorter,
  Tag,
  useAppFeedback,
} from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AddAssetToAssetCollection } from "../AddAssetToAssetCollection";
import { ReloadOutlined } from "@ant-design/icons";

export interface IAssetInAssetListProps {
  title: string;
  asset?: IAssetModel;
}

export const AssetInAssetList: React.FC<IAssetInAssetListProps> = (props) => {
  const { title, asset } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { notification } = useAppFeedback();

  const { assetsInAssets, assetInAssets, action, isProcessing } = useSelector(
    AssetStore.Selectors.assetSelector
  );

  const [showAssetTableModal, setShowAssetTableModal] = useState<boolean>(
    false
  );

  const [statePagination, setStatePagination] = useState<
    ITablePaginationConfig
  >({
    pageSize: 10,
    total: 0,
    current: 1,
    showTotal: (total, range) =>
      t("TABLE_PAGINATION_TOTAL", {
        rangeFrom: range[0],
        rangeTo: range[1],
        total: total,
      }),
    showSizeChanger: true,
    defaultPageSize: 10,
    pageSizeOptions: ["10", "30", "50", "100"],
  });

  statePagination.total = assetsInAssets.TotalCount;

  const searchAssets = useCallback(() => {
    if (asset?.Id) {
      dispatch(AssetStore.Actions.selectAssetsInAsset(asset?.Id));
    }
  }, [dispatch]);
  const updateAssetInAsset = useCallback(
    (data: IAssetInAssetModel) =>
      dispatch(AssetStore.Actions.updateAssetInAsset(data)),
    [dispatch]
  );

  useEffect(() => {
    searchAssets();
  }, []);

  useEffect(() => {
    switch (action?.type) {
      case AssetStore.Consts.INSERT_ASSET_IN_ASSET_SUCCESS:
        searchAssets();
        notification.success({
          message: t("ASSET_CHILDREN_INSERT_CHILDREN_SUCCESSFULLY"),
        });
        break;

      case AssetStore.Consts.UPDATE_ASSET_IN_ASSET_SUCCESS:
        searchAssets();
        break;
      case AssetStore.Consts.INSERT_ASSET_IN_ASSET_FAILURE:
        notification.error({
          message: t("ASSET_CHILDREN_INSERT_CHILDREN_FAILURE"),
          description: action?.error?.Message,
        });
        break;
      case AssetStore.Consts.UPDATE_ASSET_IN_ASSET_FAILURE:
        notification.error({
          message: t("ASSET_DETAILS__ASSET_UPDATE_FAILURE"),
          description: action?.error?.Message,
        });
        break;
      case AssetStore.Consts.DELETE_ASSET_IN_ASSET_SUCCESS:
        notification.success({
          message: t("ASSET_DETAILS__ASSET_DELETE_SUCCESS"),
        });
        searchAssets();
        break;
      case AssetStore.Consts.DELETE_ASSET_IN_ASSET_FAILURE:
        notification.error({
          message: t("ASSET_DETAILS__ASSET_DELETE_FAILURE"),
          description: action?.error?.Message,
        });
        break;
    }
  }, [action]);

  const getColumnsProps = (): Array<ITableColumnProps<IAssetInAssetModel>> => {
    return [
      {
        key: "Sequence",
        dataIndex: "Sequence",
        title: t("MODEL_ORDER_IN_PARENT"),
        width: "100px",
      },
      {
        key: "AssetTitle",
        dataIndex: "AssetTitle",
        title: t("MODEL_TITLE"),
        className: "drag-visible",
      },
      {
        key: "AssetTypeCode",
        dataIndex: "AssetTypeCode",
        title: t("MODEL_ASSET_TYPE_CODE"),
        width: "200px",
        render: (text: any, row: IAssetInAssetModel) => {
          return (
            <Tag color={row.AssetTypeCode ? "#f50" : "#1890ff"}>
              {row.AssetTypeCode}
            </Tag>
          );
        },
      },
      {
        key: "RemoveAssetFromAssetAction",
        dataIndex: "RemoveAssetFromAssetAction",
        title: t("TABLE_ACTION_COLUMN"),
        width: "150px",
        align: "center",
        render: (text: any, row: IAssetInAssetModel) => (
          <Popconfirm
            title={t("DELETE_ASSET_FROM_ASSET_MESSAGE")}
            onConfirm={() => {
              dispatch(AssetStore.Actions.deleteAssetInAsset(row));
            }}
            okText={t("BUTTON_YES")}
            cancelText={t("BUTTON_NO")}
          >
            <Button
              icon={<Icon type="delete" />}
              title={t("DELETE_ASSET_FROM_ASSET")}
              danger
            ></Button>
          </Popconfirm>
        ),
      },
    ];
  };

  const addAssetToCollection = (insertedAsset: IAssetModel) => {
    if (!insertedAsset || !insertedAsset.Id || !asset || !asset) {
      return;
    }

    const assetInAssetObject: IAssetInAssetModel = {
      AssetParentId: asset.Id,
      AssetId: insertedAsset.Id,
      AssetTitle: insertedAsset.Title ?? undefined,
      AssetTypeCode: insertedAsset.AssetTypeCode ?? undefined,
      RecordStatus: RecordStatus.Inserted,
    };

    dispatch(AssetStore.Actions.insertAssetInAsset(assetInAssetObject));
  };

  const onRefreshClick = () => searchAssets();

  const onTableChange = (pagination: ITablePaginationConfig) => {
    setStatePagination(pagination);
  };

  const onMoveRow = (dragIndex: number, hoverIndex: number) => {
    const draggedAsset: IAssetInAssetModel = assetsInAssets.Entities[dragIndex];
    const hoveredAsset: IAssetInAssetModel =
      assetsInAssets.Entities[hoverIndex];

    if (
      draggedAsset.Sequence === undefined ||
      hoveredAsset.Sequence === undefined
    ) {
      return;
    }

    const assetToUpdate: IAssetInAssetModel = {
      ...draggedAsset,
      Sequence: hoveredAsset.Sequence ?? 1,
    };

    const sequenceChanged = draggedAsset.Sequence !== assetToUpdate.Sequence;
    const draggedToNewPosition = draggedAsset.AssetId !== hoveredAsset.AssetId;

    if (draggedToNewPosition && sequenceChanged) {
      updateAssetInAsset(assetToUpdate);
    }
  };

  const renderAssetTableModal = () => {
    const assetsIds = assetsInAssets.Entities.map((asset) => asset.AssetId);

    return (
      <Modal
        width="90%"
        title={t("ASSET_IN_ASSET_LIST__ADD_ASSET")}
        onCancel={() => {
          setShowAssetTableModal(false);
          searchAssets();
        }}
        visible={showAssetTableModal}
        footer={null}
      >
        <AddAssetToAssetCollection
          addAssetToCollection={addAssetToCollection}
          assetsIds={assetsIds}
        />
      </Modal>
    );
  };

  return (
    <>
      <PageContent footer={<Pagination {...statePagination} />}>
        <Heading
          title={title}
          actions={
            <>
              <Button
                key="add"
                shape="circle"
                type="primary"
                icon={<Icon type="plus" />}
                onClick={() => setShowAssetTableModal(true)}
                title={t("ADD_ASSET")}
              />
              <Button
                key="reload"
                shape="circle"
                icon={<ReloadOutlined />}
                onClick={onRefreshClick}
                title={t("BUTTON_REFRESH_TITLE")}
              />
            </>
          }
        />
        <TableWithDraggableSorter<IAssetInAssetModel>
          dragType="handler"
          columns={getColumnsProps()}
          dataSource={assetsInAssets.Entities}
          rowKey="AssetId"
          loading={
            assetsInAssets.IsLoading ||
            assetInAssets.IsProcessing ||
            isProcessing
          }
          pagination={false}
          onChange={onTableChange}
          onMoveRow={onMoveRow}
        />
      </PageContent>

      {renderAssetTableModal()}
    </>
  );
};
