export const TOGGLE_ADVERTISEMENT_BOARD_MODAL = "TOGGLE_ADVERTISEMENT_BOARD_MODAL";

export const ADD_ADVERTISEMENT_BLOCK = "ADD_ADVERTISEMENT_BLOCK";

export const ADD_ADVERTISEMENT_BLOCKS = "ADD_ADVERTISEMENT_BLOCKS";
export const ADD_ADVERTISEMENT_BLOCKS_SUCCESS = "ADD_ADVERTISEMENT_BLOCKS_SUCCESS";
export const ADD_ADVERTISEMENT_BLOCKS_FAILURE = "ADD_ADVERTISEMENT_BLOCK_FAILURE";

export const UPDATE_ADVERTISEMENT_BLOCK = "UPDATE_ADVERTISEMENT_BLOCK";
export const UPDATE_ADVERTISEMENT_BLOCK_SUCCESS = "UPDATE_ADVERTISEMENT_BLOCK_SUCCESS";
export const UPDATE_ADVERTISEMENT_BLOCK_FAILURE = "UPDATE_ADVERTISEMENT_BLOCK_FAILURE";
export const UPDATE_TEMPORARY_ADVERTISEMENT_BLOCK = "UPDATE_TEMPORARY_ADVERTISEMENT_BLOCK";
export const UPDATE_ADVERTISEMENT_BLOCK_WITH_DETAILS = "UPDATE_ADVERTISEMENT_BLOCK_WITH_DETAILS";
export const UPDATE_ADVERTISEMENT_BLOCK_WITH_DETAILS_SUCCESS = "UPDATE_ADVERTISEMENT_BLOCK_WITH_DETAILS_SUCCESS";
export const UPDATE_ADVERTISEMENT_BLOCK_WITH_DETAILS_FAILURE = "UPDATE_ADVERTISEMENT_BLOCK_WITH_DETAILS_FAILURE";
export const PUBLISH_ADVERTISEMENT_BLOCK = "PUBLISH_ADVERTISEMENT_BLOCK";
export const PUBLISH_ADVERTISEMENT_BLOCK_SUCCESS = "PUBLISH_ADVERTISEMENT_BLOCK_SUCCESS";
export const PUBLISH_ADVERTISEMENT_BLOCK_FAILURE = "PUBLISH_ADVERTISEMENT_BLOCK_FAILURE";
export const ARCHIVE_ADVERTISEMENT_BLOCK = "ARCHIVE_ADVERTISEMENT_BLOCK";
export const ARCHIVE_ADVERTISEMENT_BLOCK_SUCCESS = "ARCHIVE_ADVERTISEMENT_BLOCK_SUCCESS";
export const ARCHIVE_ADVERTISEMENT_BLOCK_FAILURE = "ARCHIVE_ADVERTISEMENT_BLOCK_FAILURE";
export const RESTORE_ADVERTISEMENT_BLOCK = "RESTORE_ADVERTISEMENT_BLOCK";
export const RESTORE_ADVERTISEMENT_BLOCK_SUCCESS = "RESTORE_ADVERTISEMENT_BLOCK_SUCCESS";
export const RESTORE_ADVERTISEMENT_BLOCK_FAILURE = "RESTORE_ADVERTISEMENT_BLOCK_FAILURE";

export const TOGGLE_ADVERTISEMENT_BLOCK_MODAL = "TOGGLE_ADVERTISEMENT_BLOCK_MODAL";

export const DELETE_ADVERTISEMENT_BLOCK = "DELETE_ADVERTISEMENT_BLOCK";
export const DELETE_ADVERTISEMENT_BLOCK_SUCCESS = "DELETE_ADVERTISEMENT_BLOCK_SUCCESS";
export const DELETE_ADVERTISEMENT_BLOCK_FAILURE = "DELETE_ADVERTISEMENT_BLOCK_FAILURE";

export const SEARCH_ADVERTISEMENT_BLOCK = "SEARCH_ADVERTISEMENT_BLOCK";
export const SEARCH_ADVERTISEMENT_BLOCK_SUCCESS = "SEARCH_ADVERTISEMENT_BLOCK_SUCCESS";
export const SEARCH_ADVERTISEMENT_BLOCK_FAILURE = "SEARCH_ADVERTISEMENT_BLOCK_FAILURE";

export const GET_ADVERTISEMENT_BLOCK = "GET_ADVERTISEMENT_BLOCK";
export const GET_ADVERTISEMENT_BLOCK_SUCCESS = "GET_ADVERTISEMENT_BLOCK_SUCCESS";
export const GET_ADVERTISEMENT_BLOCK_FAILURE = "GET_ADVERTISEMENT_BLOCK_FAILURE";

export const GET_ADVERTISEMENT_BOARD = "GET_ADVERTISEMENT_BOARD";
export const GET_ADVERTISEMENT_BOARD_SUCCESS = "GET_ADVERTISEMENT_BOARD_SUCCESS";
export const GET_ADVERTISEMENT_BOARD_FAILURE = "GET_ADVERTISEMENT_BOARD_FAILURE";

export const SELECT_ADVERTISEMENT_BOARD_TYPE = "SELECT_ADVERTISEMENT_BOARD_TYPE";
export const SELECT_ADVERTISEMENT_BOARD_TYPE_SUCCESS =
  "SELECT_ADVERTISEMENT_BOARD_TYPE_SUCCESS";
export const SELECT_ADVERTISEMENT_BOARD_TYPE_FAILURE =
  "SELECT_ADVERTISEMENT_BOARD_TYPE_FAILURE";
