export const SEARCH_RESOURCE = "SEARCH_RESOURCE";
export const SEARCH_RESOURCE_SUCCESS = "SEARCH_RESOURCE_SUCCESS";
export const SEARCH_RESOURCE_FAILURE = "SEARCH_RESOURCE_FAILURE";

export const BROWSE_RESOURCE = "BROWSE_RESOURCE";
export const BROWSE_RESOURCE_SUCCESS = "BROWSE_RESOURCE_SUCCESS";
export const BROWSE_RESOURCE_FAILURE = "BROWSE_RESOURCE_FAILURE";

export const SELECT_RESOURCE = "SELECT_RESOURCE";
export const SELECT_RESOURCE_SUCCESS = "SELECT_RESOURCE_SUCCESS";
export const SELECT_RESOURCE_FAILURE = "SELECT_RESOURCE_FAILURE";

export const UPDATE_RESOURCE = "UPDATE_RESOURCE";
export const UPDATE_RESOURCE_SUCCESS = "UPDATE_RESOURCE_SUCCESS";
export const UPDATE_RESOURCE_FAILURE = "UPDATE_RESOURCE_FAILURE";

export const INSERT_RESOURCE = "INSERT_RESOURCE";
export const INSERT_RESOURCE_SUCCESS = "INSERT_RESOURCE_SUCCESS";
export const INSERT_RESOURCE_FAILURE = "INSERT_RESOURCE_FAILURE";

export const DELETE_RESOURCE = "DELETE_RESOURCE";
export const DELETE_RESOURCE_SUCCESS = "DELETE_RESOURCE_SUCCESS";
export const DELETE_RESOURCE_FAILURE = "DELETE_RESOURCE_FAILURE";

export const DELETE_RESOURCES_BY_KEY = "DELETE_RESOURCES_BY_KEY";
export const DELETE_RESOURCES_BY_KEY_SUCCESS =
  "DELETE_RESOURCES_BY_KEY_SUCCESS";
export const DELETE_RESOURCES_BY_KEY_FAILURE =
  "DELETE_RESOURCES_BY_KEY_FAILURE";

export const SELECT_APPLICATION = "SELECT_APPLICATION";
export const SELECT_APPLICATION_SUCCESS = "SELECT_APPLICATION_SUCCESS";
export const SELECT_APPLICATION_FAILURE = "SELECT_APPLICATION_FAILURE";

export const SELECT_LANGUAGE = "SELECT_LANGUAGE";
export const SELECT_LANGUAGE_SUCCESS = "SELECT_LANGUAGE_SUCCESS";
export const SELECT_LANGUAGE_FAILURE = "SELECT_LANGUAGE_FAILURE";

export const UPDATE_LANGUAGE = "UPDATE_LANGUAGE";
export const UPDATE_LANGUAGE_SUCCESS = "UPDATE_LANGUAGE_SUCCESS";
export const UPDATE_LANGUAGE_FAILURE = "UPDATE_LANGUAGE_FAILURE";

export const SELECT_PLATFORM = "SELECT_PLATFORM";
export const SELECT_PLATFORM_SUCCESS = "SELECT_PLATFORM_SUCCESS";
export const SELECT_PLATFORM_FAILURE = "SELECT_PLATFORM_FAILURE";

export const SELECT_CONSENT = "SELECT_CONSENT";
export const SELECT_CONSENT_SUCCESS = "SELECT_CONSENT_SUCCESS";
export const SELECT_CONSENT_FAILURE = "SELECT_CONSENT_FAILURE";

export const UPDATE_CONSENT = "UPDATE_CONSENT";
export const UPDATE_CONSENT_SUCCESS = "UPDATE_CONSENT_SUCCESS";
export const UPDATE_CONSENT_FAILURE = "UPDATE_CONSENT_FAILURE";

export const INSERT_CONSENT = "INSERT_CONSENT";
export const INSERT_CONSENT_SUCCESS = "INSERT_CONSENT_SUCCESS";
export const INSERT_CONSENT_FAILURE = "INSERT_CONSENT_FAILURE";

export const DELETE_CONSENT = "DELETE_CONSENT";
export const DELETE_CONSENT_SUCCESS = "DELETE_CONSENT_SUCCESS";
export const DELETE_CONSENT_FAILURE = "DELETE_CONSENT_FAILURE";
