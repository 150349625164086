import { ITestApplicationModel, TimeHelper } from "@bms/common-services";
import {
  Button,
  Col,
  Form,
  IFormValues,
  Input,
  required,
  Row,
  Spin,
  Switch,
  TextArea,
} from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import React from "react";
import { PlatformTypeHelper } from "../../../../../../helpers";

interface TestApplicationEditFormProps {
  testApplication: ITestApplicationModel;
  updateTestApplication: (
    testApplication: ITestApplicationModel
  ) => Promise<void>;
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 6,
    },
  },
};

export const TestApplicationEditForm = (
  props: TestApplicationEditFormProps
) => {
  const { testApplication, updateTestApplication } = props;

  const [form] = Form.useForm();
  const { t } = useTranslation();

  const onFinish = async (values: IFormValues) => {
    await updateTestApplication({ ...testApplication, ...values });
  };

  const renderNameInput = () => {
    return (
      <Form.Item name="Name" label={t("MODEL_NAME")} rules={[required()]}>
        <Input placeholder={t("MODEL_NAME")} />
      </Form.Item>
    );
  };

  const renderDescriptionInput = () => {
    return (
      <Form.Item name="Description" label={t("MODEL_DESCRIPTION")}>
        <TextArea maxLength={250} placeholder={t("MODEL_DESCRIPTION")} />
      </Form.Item>
    );
  };

  const renderBundleIdInput = () => {
    return (
      <Form.Item
        name="BundleID"
        label={t("TESTING_APPLICATIONS_BUNDLE_ID")}
        rules={[required()]}
      >
        <Input placeholder={t("TESTING_APPLICATIONS_BUNDLE_ID")} />
      </Form.Item>
    );
  };

  const renderMinOsVersionInput = () => {
    return (
      <Form.Item
        name="MinOSVersion"
        label={t("TESTING_APPLICATIONS_MIN_OS_VERSION")}
        rules={[required()]}
      >
        <Input placeholder={t("TESTING_APPLICATIONS_MIN_OS_VERSION")} />
      </Form.Item>
    );
  };

  const renderUpToDate = () => {
    return (
      <Form.Item {...tailFormItemLayout}>
        <Row gutter={8}>
          <Col>
            <Form.Item name="UpToDate" valuePropName="checked">
              <Switch />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="UpToDate">
              <label>{t("MODEL_UP_TO_DATE")}</label>
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>
    );
  };

  const renderPlatform = () => {
    return (
      <Form.Item label={t("MODEL_PLATFORM")}>
        <span>
          {PlatformTypeHelper.getTag(testApplication?.PlatformCode as string)}
        </span>
      </Form.Item>
    );
  };

  const renderCreationDateBy = () => {
    return (
      <Form.Item label={t("MODEL_CREATED")}>
        <span>
          {testApplication?.Created
            ? TimeHelper.format(testApplication.Created)
            : null}
        </span>
      </Form.Item>
    );
  };

  const renderCreatedBy = () => {
    return (
      <Form.Item label={t("MODEL_CREATED_BY")}>
        <span>{testApplication?.CreatedByFullName}</span>
      </Form.Item>
    );
  };

  return (
    <Spin spinning={!Boolean(testApplication)}>
      <Form
        name="TestApplicationEditForm"
        form={form}
        initialValues={testApplication}
        onFinish={onFinish}
        {...formItemLayout}
      >
        <Row direction="column" justify="space-between" className="full-height">
          <Col>
            {renderNameInput()}
            {renderBundleIdInput()}
            {renderMinOsVersionInput()}
            {renderDescriptionInput()}
            {renderPlatform()}
            {renderUpToDate()}
            {renderCreationDateBy()}
            {renderCreatedBy()}
            <Form.Item {...tailFormItemLayout}>
              <Button type="primary" htmlType="submit">
                {t("BUTTON_SAVE")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};
