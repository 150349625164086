import React from "react";
import { useTranslation } from "react-i18next";
import { Modal, Spin } from "@bms/common-ui";

import "./ImagePreviewModal.scss";

export interface IImagePreviewModalProps {
  visible: boolean;
  imageSrc?: string;
  onCancel?: () => void;
}

export const ImagePreviewModal = ({
  visible,
  imageSrc,
  onCancel,
}: IImagePreviewModalProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [imageUrl, setImageUrl] = React.useState("");

  React.useEffect(() => {
    const imageLoader = new Image();
    setImageUrl("");

    if (imageSrc) {
      setLoading(true);
      imageLoader.src = imageSrc;
      imageLoader.onload = () => {
        setLoading(false);
        setImageUrl(imageSrc);
      };
      imageLoader.onerror = () => {
        setLoading(false);
      };
    }

    return () => {
      imageLoader.src = "";
    };
  }, [imageSrc]);

  return (
    <Modal
      visible={visible}
      footer={null}
      onCancel={() => onCancel?.()}
      preview
    >
      <div className="ImagePreviewModal">
        {loading && <Spin className="spin" />}
        {imageUrl && <img src={imageUrl} />}
        {!loading && !imageUrl && (
          <p className="info">
            {t("CONFIGURATION_IMAGE__PREVIEW_NOT_AVAILABLE_MESSAGE")}
          </p>
        )}
      </div>
    </Modal>
  );
};
