import React, { useCallback, useState } from "react";
import {
  Button,
  SectionGrid,
  SectionGridItem,
  useAppFeedback,
} from "@bms/common-ui";
import { AssetForm } from "../../AssetForm";
import {
  descriptionCommonFields,
  getSectionsFields,
} from "../../AssetForm/AssetFormUtils";
import { ICommonAssetDetailsTabProps } from "./ICommonAssetDetailsTabProps";
import {
  AssetService,
  AssetType,
  IAssetPeopleModel,
  RecordStatus,
  useDataLoader,
  useServiceCaller,
} from "@bms/common-services";
import { useTranslation } from "react-i18next";
import { PlusOutlined } from "@ant-design/icons";
import { FormModal } from "../../../../../components";
import { DictionaryPeopleForm } from "../../../../Dictionaries/components/DictionaryPeopleForm";

const assetService = new AssetService().promisify();

const defaultPerson: IAssetPeopleModel = {
  Id: -1,
  FullName: "",
  RecordStatus: RecordStatus.Inserted,
};

export const DetailsTab = ({
  asset,
  updateAssetState,
  updateAsset,
}: ICommonAssetDetailsTabProps) => {
  const { t } = useTranslation();
  const { notification } = useAppFeedback();

  const [showPeopleModal, setShowPopleModal] = useState(false);

  const peopleLoader = useDataLoader({
    loader: (data: string) =>
      assetService.searchAssetPeople({
        PageNumber: 1,
        PageSize: 999,
        FullTextSearch: data,
        IncludeCount: true,
      }),
    deps: [],
  });

  const sections = asset?.AssetTypeCode
    ? getSectionsFields(asset.AssetTypeCode)
    : null;

  const showPersonModal = useCallback(() => {
    setShowPopleModal((showForm) => !showForm);
  }, [showPeopleModal]);

  const [addNewPerson, isProcessing] = useServiceCaller(
    async (person: IAssetPeopleModel) => {
      const res = await assetService.addAssetPeople({
        ...person,
        RecordStatus: RecordStatus.Inserted,
      });
      if (res.ok) {
        notification.success({
          message: t("DICTIONARY_PEOPLE_INSERT_SUCCESS"),
        });
        await peopleLoader.refresh();
        showPersonModal();
      } else {
        notification.error({
          message: t("DICTIONARY_PEOPLE_INSERT_FAILURE"),
          description: res.error?.Message,
        });
      }
    },
    []
  );

  return (
    <SectionGrid style={{ maxWidth: "90%" }}>
      {(sections?.general?.length || 0) > 0 && (
        <SectionGridItem
          header={t("ASSET_DETAILS_SECTION_GENERAL")}
          processing={updateAssetState?.processing}
        >
          <AssetForm
            key={`AssetForm-${asset?.RowVersion}`}
            isEditMode={true}
            asset={asset}
            section="general"
            onSubmit={updateAsset}
            hiddenFields={[...descriptionCommonFields]}
          />
        </SectionGridItem>
      )}
      {(sections?.restriction?.length || 0) > 0 && (
        <SectionGridItem
          header={t("ASSET_DETAILS_SECTION_RESTRICTION")}
          processing={updateAssetState?.processing}
        >
          <AssetForm
            key={`AssetForm-${asset?.Id}`}
            isEditMode={true}
            asset={asset}
            section="restriction"
            onSubmit={updateAsset}
          />
        </SectionGridItem>
      )}
      {asset?.AssetTypeCode !== AssetType.Podcast &&
        (sections?.people?.length || 0) > 0 && (
          <SectionGridItem
            header={t("ASSET_DETAILS_SECTION_PEOPLE")}
            processing={updateAssetState?.processing}
          >
            <Button
              key="add"
              shape="circle"
              type="primary"
              icon={<PlusOutlined />}
              onClick={showPersonModal}
              title={t("DICTIONARY_PEOPLE_MODAL_BUTTON_NEW")}
            />
            <FormModal
              isLoading={!isProcessing}
              isVisible={showPeopleModal}
              isNewForm
              isDeleteButtonEnabled={false}
              createFormTitle={t("DICTIONARY_PEOPLE_MODAL_NEW")}
              modalClassName="DictionaryPeopleModal"
              submitFormName="DictionaryPeopleForm"
              onCloseModal={showPersonModal}
            >
              <DictionaryPeopleForm
                isProcessing={!isProcessing}
                person={defaultPerson}
                addNewPerson={addNewPerson}
              />
            </FormModal>
            <AssetForm
              key={`AssetForm-${asset?.Id}`}
              isEditMode={true}
              asset={asset}
              section="people"
              onSubmit={updateAsset}
            />
          </SectionGridItem>
        )}
    </SectionGrid>
  );
};
