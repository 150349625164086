import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import {
  Button,
  Icon,
  InputSearch,
  ITableColumnProps,
  ITableFilter,
  Link,
  Modal,
  PageContent,
  PageHeader,
  Pagination,
  Popconfirm,
  SectionGrid,
  SectionGridItem,
  setTableColumnSearchProps,
  Table,
  Tag,
  TAG_PALETTE,
  Text,
  Tooltip,
  useAppFeedback,
} from "@bms/common-ui";
import { FilterCleanIcon } from "../../../../../../resources/icons";
import {
  ITestExecutionModel,
  PlatformType,
  TimeHelper,
} from "@bms/common-services";
import {
  TestExecutionStatusHelper,
  useDateFilterDropdown,
} from "../../../../../../helpers";
import { ROUTES as TESTING_ROUTES } from "../../../../constants";
import { FormModal } from "../../../../../../components";
import { useTestExecutions } from "./useTestExecutions";
import { TestDefinitionExecutionForm } from "./TestDefinitionExecutionForm";

interface TestDefinitionExecutionsTabProps {
  testDefinitionId: number;
  platforms: PlatformType[];
  isExecutable: boolean;
}

export const TestDefinitionExecutionsTab = (
  props: TestDefinitionExecutionsTabProps
) => {
  const { testDefinitionId, isExecutable, platforms } = props;

  const [addModalVisible, setAddModalVisible] = useState(false);

  const { t } = useTranslation();
  const { modal } = useAppFeedback();

  const {
    isLoading,
    data,
    pagination,
    fullTextSearch,
    setFullTextSearch,
    filters,
    refreshExecutions,
    createExecutions,
    deleteExecution,
  } = useTestExecutions({ testDefinitionId });

  const { filterDropdown: startedFilterDropdown } = useDateFilterDropdown({
    initialStartDateFrom: filters.current.StartDateFrom,
    initialStartDateTo: filters.current.StartDateTo,
    onReset: () =>
      filters.update((oldFilters) => ({
        ...oldFilters,
        StartDateFrom: undefined,
        StartDateTo: undefined,
      })),
    onFilter: (startDateFrom?: string, startDateTo?: string) =>
      filters.update((oldFilters) => ({
        ...oldFilters,
        StartDateFrom: startDateFrom,
        StartDateTo: startDateTo,
      })),
  });

  const { filterDropdown: finishedFilterDropdown } = useDateFilterDropdown({
    initialStartDateFrom: filters.current.FinishDateFrom,
    initialStartDateTo: filters.current.FinishDateTo,
    onReset: () =>
      filters.update((oldFilters) => ({
        ...oldFilters,
        FinishDateFrom: undefined,
        FinishDateTo: undefined,
      })),
    onFilter: (startDateFrom?: string, startDateTo?: string) =>
      filters.update((oldFilters) => ({
        ...oldFilters,
        FinishDateFrom: startDateFrom,
        FinishDateTo: startDateTo,
      })),
  });

  const showAddModal = () => {
    setAddModalVisible(true);
  };

  const onSearch = (value: string) =>
    filters.update((currentFilters) => ({
      ...currentFilters,
      FullTextSearch: value || undefined,
    }));

  const onTableChange = (_: any, incomingFilters: ITableFilter) =>
    filters.update((currentFilters) => ({
      ...currentFilters,
      ...incomingFilters,
    }));

  const getExtraButtons = () => {
    return (
      <>
        <InputSearch
          key="search"
          placeholder={t("SEARCH_PLACEHOLDER")}
          value={fullTextSearch}
          onSearch={onSearch}
          onChange={({ target: { value } }) => setFullTextSearch(value)}
          style={{ width: 250 }}
          allowClear
        />
        <Tooltip key="filters-tooltip" overlay={t("MENU_OPTION_CLEAR_FILTERS")}>
          <Button
            key="clear-filters"
            shape="circle"
            icon={<FilterCleanIcon />}
            onClick={filters.clear}
            disabled={!filters.anyActive}
            title={t("MENU_OPTION_CLEAR_FILTERS")}
          />
        </Tooltip>
        <Tooltip key="reload-tooltip" overlay={t("BUTTON_REFRESH_TITLE")}>
          <Button
            key="reload"
            shape="circle"
            icon={<ReloadOutlined />}
            onClick={refreshExecutions}
            title={t("BUTTON_REFRESH_TITLE")}
          />
        </Tooltip>
        <Tooltip
          key="add-tooltip"
          overlay={t("TESTING_TEST_DEFINITIONS_EXECUTION_ADD_BUTTON")}
        >
          <Button
            key="add"
            shape="circle"
            type="primary"
            icon={<PlusOutlined />}
            title={t("TESTING_TEST_DEFINITIONS_EXECUTION_ADD_BUTTON")}
            onClick={showAddModal}
            disabled={!isExecutable}
          />
        </Tooltip>
      </>
    );
  };

  const getColumnsProps = (): Array<ITableColumnProps<ITestExecutionModel>> => {
    return [
      {
        key: "DeviceName",
        dataIndex: "DeviceName",
        title: t("TESTING_TEST_DEFINITIONS_EXECUTION_DEVICE"),
        align: "left",
        filteredValue: filters.asTableArray.DeviceName,
        ...setTableColumnSearchProps(
          "DeviceName",
          t("TESTING_TEST_DEFINITIONS_EXECUTION_DEVICE")
        ),
        render: (_, execution) => {
          return (
            <Link
              to={`${TESTING_ROUTES.DEVICES}/?Name=${execution.TestDeviceName}`}
            >
              {execution.TestDeviceName}
            </Link>
          );
        },
      },
      {
        key: "ApplicationName",
        dataIndex: "ApplicationName",
        title: t("TESTING_TEST_DEFINITIONS_EXECUTION_APPLICATION"),
        align: "left",
        filteredValue: filters.asTableArray.ApplicationName,
        ...setTableColumnSearchProps(
          "ApplicationName",
          t("TESTING_TEST_DEFINITIONS_EXECUTION_APPLICATION")
        ),
        render: (_, execution) => {
          return (
            <Link
              to={`${TESTING_ROUTES.APPLICATION_DETAILS}/${execution.TestApplicationId}`}
            >
              {execution.TestApplicationName}
            </Link>
          );
        },
      },
      {
        key: "ApplicationVersion",
        dataIndex: "ApplicationVersion",
        title: t("TESTING_TEST_DEFINITIONS_EXECUTION_APPLICATION_VERSION"),
        align: "left",
        filteredValue: filters.asTableArray.ApplicationVersion,
        ...setTableColumnSearchProps(
          "ApplicationVersion",
          t("TESTING_TEST_DEFINITIONS_EXECUTION_APPLICATION_VERSION")
        ),
        render: (_, execution) => (
          <Tag color={TAG_PALETTE.GREY}>
            {execution.TestApplicationVersionVersion}
          </Tag>
        ),
      },
      {
        key: "ResultUrl",
        dataIndex: "ResultUrl",
        title: t("TESTING_TEST_DEFINITIONS_EXECUTION_RESULT_URL"),
        render: (_, execution) => {
          return (
            execution.ResultUrl && (
              <a
                title={`${execution.Id}-${execution.TestDeviceName}`}
                href={execution.ResultUrl}
                target="_blank"
                download
              >
                <Text ellipsis copyable>
                  {execution.ResultUrl}
                </Text>
              </a>
            )
          );
        },
      },
      {
        key: "Started",
        dataIndex: "Started",
        title: t("TESTING_TEST_DEFINITIONS_EXECUTION_STARTED"),
        align: "center",
        width: "140px",
        filteredValue:
          filters.asTableArray.StartDateFrom ||
          filters.asTableArray.StartDateTo ||
          null,
        filterDropdown: startedFilterDropdown,
        render: (date: Date) => date && TimeHelper.format(date),
      },
      {
        key: "Finished",
        dataIndex: "Finished",
        title: t("TESTING_TEST_DEFINITIONS_EXECUTION_FINISHED"),
        align: "center",
        width: "140px",
        filteredValue:
          filters.asTableArray.FinishDateFrom ||
          filters.asTableArray.FinishDateTo ||
          null,
        filterDropdown: finishedFilterDropdown,
        render: (date: Date) => date && TimeHelper.format(date),
      },
      {
        key: "Statuses",
        dataIndex: "Statuses",
        title: t("TESTING_TEST_DEFINITIONS_EXECUTION_STATUS"),
        align: "center",
        width: "170px",
        filters: TestExecutionStatusHelper.getFilterOptions(),
        filteredValue: filters.asTableArray.Statuses,
        render: (_, execution) => {
          return (
            <>
              {TestExecutionStatusHelper.getTag(execution.Status as string)}
              {execution.ErrorMessage && (
                <Button
                  icon={<Icon type="info-circle" />}
                  title={t("BUTTON_ERROR_INFO_TITLE")}
                  onClick={() => {
                    modal.error({
                      title: t(
                        "TESTING_TEST_DEFINITIONS_EXECUTION_ERROR_TITLE"
                      ),
                      icon: <Icon type="exclamation-circle" />,
                      content: execution.ErrorMessage,
                    });
                  }}
                />
              )}
            </>
          );
        },
      },
      {
        key: "Actions",
        dataIndex: "Actions",
        title: t("TABLE_ACTIONS_COLUMN"),
        align: "center",
        width: "100px",
        render: (_: any, execution: ITestExecutionModel) => (
          <Popconfirm
            title={t("DELETE_ELEMENT_DOUBLE_CONFIRMATION_QUESTION")}
            onConfirm={async (event?: React.MouseEvent<HTMLElement>) => {
              event?.preventDefault();
              await deleteExecution(execution);
            }}
            okText={t("BUTTON_YES")}
            cancelText={t("BUTTON_NO")}
          >
            <Button
              danger={true}
              icon={<Icon type="delete" />}
              title={t("DELETE_ELEMENT")}
              disabled={!isExecutable}
            />
          </Popconfirm>
        ),
      },
    ];
  };

  return (
    <SectionGrid>
      <SectionGridItem>
        <PageContent footer={<Pagination {...pagination.props} />}>
          <PageHeader extra={getExtraButtons()} />
          <Table<ITestExecutionModel>
            rowKey="Id"
            columns={getColumnsProps()}
            dataSource={data?.Entities}
            loading={isLoading}
            pagination={false}
            onChange={onTableChange}
          />
          <FormModal
            isLoading={false}
            isVisible={addModalVisible}
            isNewForm={false}
            createFormTitle={""}
            editFormTitle={t("TESTING_TEST_DEFINITIONS_EXECUTION_ADD_HEADER")}
            modalClassName="TestDefinitionExecutionModal"
            submitFormName="TestDefinitionExecutionForm"
            onCloseModal={() => setAddModalVisible(false)}
            isDeleteButtonEnabled={false}
          >
            <TestDefinitionExecutionForm
              testId={testDefinitionId}
              platforms={platforms}
              setVisibilityStatus={setAddModalVisible}
              createExecutions={createExecutions}
            />
          </FormModal>
        </PageContent>
      </SectionGridItem>
    </SectionGrid>
  );
};
