import React, { memo } from "react";
import { LabeledValueText, Card, Skeleton } from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { ISalesRevenueDataCardProps } from "./ISalesRevenueDataCardProps";

export const SalesRevenueSalesDataCard = memo(
  ({ data, isLoading }: ISalesRevenueDataCardProps) => {
    const { t } = useTranslation();
    return (
      <Card style={{ width: "50%" }} title={t("SALES_REVENUE_SALES_DATA")}>
        <Skeleton active={true} loading={isLoading} paragraph={true}>
          <LabeledValueText
            label={t("SALES_REVENUE_ORDERS_COUNT")}
            value={data?.OrdersCount}
          />
          <LabeledValueText
            label={t("SALES_REVENUE_TOTAL_INCOME")}
            value={`${data?.Income?.toFixed(2)}`.replace(".", ",")}
          />
          <LabeledValueText
            label={t("SALES_REVENUE_AVG_ORDER_PRICE")}
            value={`${data?.AverageOrderPrice?.toFixed(2)}`.replace(".", ",")}
          />
        </Skeleton>
      </Card>
    );
  }
);
