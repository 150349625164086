import { IQuizStatusModel } from "../../models";
import { Observable, Observer } from "rxjs";
import { HTTP_METHOD } from "../../constants/http";
import { AxiosSubscriber } from "../AxiosSubscriber";

export class QuizStatusService {
  get url(): string {
    return "/QuizStatuses";
  }

  public select = (): Observable<IQuizStatusModel[]> =>
    new Observable(
      (observer: Observer<IQuizStatusModel[]>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `${this.url}/Select`,
        })
    );
}
