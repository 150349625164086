import { Table as AntTable } from "antd";
import {
  ColumnProps,
  TablePaginationConfig,
  TableProps as AntTableProps,
} from "antd/lib/table";
import {
  FilterDropdownProps,
  SorterResult,
  TableCurrentDataSource,
  TableRowSelection,
} from "antd/lib/table/interface";
import React from "react";
import { Trans } from "react-i18next";
import { Button } from "../Button";
import { Icon } from "../Icon";
import { Input } from "../Input";

export interface ITablePaginationConfig extends TablePaginationConfig {}

export interface ITableRowSelection<T> extends TableRowSelection<T> {}

export interface ITableColumnProps<T> extends ColumnProps<T> {}

export interface ITableColumnFilterDropdownProps extends FilterDropdownProps {}

export type ITableFilter = Record<string, any[] | null>;

export type ITableFilterValue = string | number | boolean;

export interface ITableCurrentDataSource<T> extends TableCurrentDataSource<T> {}

export declare type ITableSorterResult<T> = SorterResult<T> | SorterResult<T>[];

export interface ITableProps<T> extends AntTableProps<T> {
  columns?: ITableColumnProps<T>[];
  pagination?: ITablePaginationConfig | false;
  onChange?: (
    pagination: ITablePaginationConfig,
    filters: ITableFilter,
    sorter: ITableSorterResult<T>,
    extra: ITableCurrentDataSource<T>
  ) => void;
}

export class Table<T extends {}> extends React.PureComponent<ITableProps<T>> {
  private _onChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, (React.Key | boolean)[] | null>,
    sorter: SorterResult<T> | SorterResult<T>[],
    extra: TableCurrentDataSource<T>
  ) => {
    const { onChange } = this.props;

    if (onChange) {
      onChange(pagination, filters, sorter, extra);
    }
  };

  public render() {
    const { onChange, ...rest } = this.props;

    return <AntTable onChange={this._onChange} {...rest} />;
  }
}

export const setTableColumnSearchProps = (
  dataIndex: string,
  displayName?: string
) => {
  const inputSearchId = `input-search-${dataIndex}`;

  return {
    filterDropdown: (event: ITableColumnFilterDropdownProps) => {
      const { setSelectedKeys, selectedKeys, confirm, clearFilters } = event;
      const filterValue =
        selectedKeys && selectedKeys.length > 0 ? selectedKeys[0] : undefined;

      return (
        <div style={{ padding: 8 }}>
          <Input
            id={inputSearchId}
            key={dataIndex}
            placeholder={`${displayName ?? dataIndex}...`}
            value={filterValue}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (setSelectedKeys) {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              }
            }}
            onPressEnter={() => {
              if (confirm) {
                confirm();
              }
            }}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              type="link"
              size="small"
              disabled={!filterValue}
              onClick={() => {
                const inputSearch = document.getElementById(
                  inputSearchId
                ) as HTMLInputElement;

                if (inputSearch) {
                  inputSearch.value = "";
                }

                if (clearFilters) {
                  clearFilters();
                }
              }}
            >
              <Trans i18nKey="BUTTON_RESET">Reset</Trans>
            </Button>
            <Button
              type="primary"
              onClick={() => {
                if (confirm) {
                  confirm();
                }
              }}
              icon={<Icon type="search" />}
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              <Trans i18nKey="BUTTON_SEARCH">Search</Trans>
            </Button>
          </div>
        </div>
      );
    },
    filterIcon: () => <Icon type="search" />,
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => {
          const inputSearch = document.getElementById(
            inputSearchId
          ) as HTMLInputElement;

          if (inputSearch) {
            inputSearch.select();
          }
        });
      }
    },
  };
};
