import React, { useCallback, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import ReactQuill, { Quill } from "react-quill-with-table";
import cx from "classnames";
import FileUploader from "quill-file-uploader";
import QuillTableUI, { MenuItem } from 'quill-table-ui'
import { deltaToHtml, convertHtmlToEditorContent } from "./utils";
import "./TextEditor.scss";

const Bold = Quill.import("formats/bold");
const Italic = Quill.import("formats/italic");

Bold.tagName = "b";
Italic.tagName = "i";

Quill.register({
  "formats/bold": Bold,
  "formats/italic": Italic,
  "modules/fileUploader": FileUploader,
  "modules/tableUI": QuillTableUI
});

const colorPicker = [
  "#000000",
  "#e60000",
  "#ff9900",
  "#ffff00",
  "#008a00",
  "#0066cc",
  "#9933ff",
  "#ffffff",
  "#facccc",
  "#ffebcc",
  "#ffffcc",
  "#cce8cc",
  "#cce0f5",
  "#ebd6ff",
  "#bbbbbb",
  "#f06666",
  "#ffc266",
  "#ffff66",
  "#66b966",
  "#66a3e0",
  "#c285ff",
  "#888888",
  "#a10000",
  "#b26b00",
  "#b2b200",
  "#006100",
  "#0047b2",
  "#6b24b2",
  "#444444",
  "#5c0000",
  "#663d00",
  "#666600",
  "#003700",
  "#002966",
  "#3d1466",
];

// the order of items matters
const tableMenuTranslationKeys = [
  "TEXT_EDITOR_MENU_INSERT_COLUMN_RIGHT",
  "TEXT_EDITOR_MENU_INSERT_COLUMN_LEFT",
  "TEXT_EDITOR_MENU_INSERT_ROW_ABOVE",
  "TEXT_EDITOR_MENU_INSERT_ROW_BELOW",
  "TEXT_EDITOR_MENU_DELETE_COLUMN",
  "TEXT_EDITOR_MENU_DELETE_ROW",
  "TEXT_EDITOR_MENU_DELETE_TABLE",
]

export interface ITextEditorProps {
  value?: string;
  className?: string;
  onChange?: (model: string) => void;
  addImage?: boolean;
  onImageUpload?: (file: File) => Promise<string | undefined>;
  addTable?: boolean;
}

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      {
        color: colorPicker,
      },
      {
        background: colorPicker,
      },
    ],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    [{ align: [] }],
    ["clean"],
  ],
};

export const TextEditor: React.FC<ITextEditorProps> = ({
  onChange,
  value,
  className,
  addImage,
  onImageUpload,
  addTable,
}) => {
  const { t } = useTranslation();
  const quillRef = useRef<ReactQuill>(null);
  const onChangeHandler = useCallback(
    (_content: any, _delta: any, _source: any, editor: any) => {
      const delta = editor.getContents();
      const html = deltaToHtml(delta.ops);
      onChange?.(html);
    },
    [onChange]
  );
  const containerClassName = cx(className, "gm-text-editor");
  const key = value !== undefined ? "keyWithInitialValue" : "defaultKey";
  useMemo(
    () => {
      if (!quillRef.current)
        return;

      // patches menu items and applies translations
      const tableUI = quillRef.current.getEditor().getModule("tableUI");
      tableUI.menuItems.forEach((element: MenuItem, index: number) => {
        element.title = t(tableMenuTranslationKeys[index], element.title);
      });

      return tableUI.menuItems;
    },
    [quillRef.current]
  );
  const editorModules = useMemo(
    () => ({
      toolbar: {
        container: [...modules.toolbar, ["link", addImage ? "image" : "", addTable ? "table" : ""]],
      },
      table: true,
      tableUI: true,
      fileUploader: {
        upload: (file: File) => {
          if (onImageUpload) {
            return onImageUpload(file);
          }

          return Promise.reject(t("ASSET_IMAGE_UPLOAD_FAILURE"));
        },
      },
    }),
    [addImage]
  );

  return (
    <ReactQuill
      ref={quillRef}
      key={key}
      className={containerClassName}
      defaultValue={convertHtmlToEditorContent(value)}
      modules={editorModules}
      onChange={onChangeHandler}
    />
  );
};
