import { SimpleServiceBase } from "../Base";
import {
  ITestDeviceModel,
  ITestDevicesFilterModel,
  ITestDevicesListModel,
} from "../../models";

export class TestDevicesService extends SimpleServiceBase<
  ITestDeviceModel,
  ITestDevicesListModel,
  number,
  ITestDevicesFilterModel
> {
  get url() {
    return "/TestDevices";
  }
}
