import { useTranslation } from "react-i18next";
import { useTableDataProvider } from "../../../../helpers";
import {
  ITestCaseModel,
  TestCasesService,
  useServiceCaller,
} from "@bms/common-services";
import { useAppFeedback } from "@bms/common-ui";

const testCasesService = new TestCasesService().promisify();

export const useTestCases = () => {
  const { t } = useTranslation();
  const { notification } = useAppFeedback();

  const {
    dataLoader,
    filters,
    pagination,
    fullTextSearch,
    setFullTextSearch,
  } = useTableDataProvider({
    filtersSchema: {
      FullTextSearch: "string",
      Code: "string",
      Name: "string",
      Description: "string",
      Platforms: "strings",
      UpToDate: "boolean",
    },
    loader: (filters, pagination) =>
      testCasesService.search({
        ...filters,
        ...pagination,
      }),
    deps: [],
    onError: (error) =>
      notification.error({
        message: t("LOADING_DATA_ERROR_MESSAGE"),
        description: error.Message,
      }),
  });

  const [createTestCase, { processing: createProcessing }] = useServiceCaller(
    async (testCase: ITestCaseModel) => {
      const result = await testCasesService.insert(testCase);

      if (result.ok) {
        dataLoader.refresh();
        notification.success({
          message: t("TESTING_TEST_CASE_CREATE_SUCCESS"),
        });
      } else {
        notification.error({
          message: t("TESTING_TEST_CASE_CREATE_FAILURE"),
          description: result.error?.Message,
        });
      }
      return result.ok;
    },
    []
  );

  const [updateTestCase, { processing: updateProcessing }] = useServiceCaller(
    async (testCase: ITestCaseModel) => {
      const result = await testCasesService.update(testCase);

      if (result.ok) {
        dataLoader.refresh();
        notification.success({
          message: t("TESTING_TEST_CASE_UPDATE_SUCCESS"),
        });
      } else {
        notification.error({
          message: t("TESTING_TEST_CASE_UPDATE_FAILURE"),
          description: result.error?.Message,
        });
      }
      return result.ok;
    },
    []
  );

  const [deleteTestCase, { processing: deleteProcessing }] = useServiceCaller(
    async (testCase: ITestCaseModel) => {
      const result = await testCasesService.delete(testCase);

      if (result.ok) {
        dataLoader.refresh();
        notification.success({
          message: t("TESTING_TEST_CASE_DELETE_SUCCESS"),
        });
      } else {
        notification.error({
          message: t("TESTING_TEST_CASE_DELETE_ERROR"),
          description: result.error?.Message,
        });
      }
      return result.ok;
    },
    []
  );

  const isLoading =
    dataLoader.loading ||
    deleteProcessing ||
    updateProcessing ||
    createProcessing;

  return {
    isLoading,
    data: dataLoader.data,
    refreshTestCases: dataLoader.refresh,
    filters,
    pagination,
    fullTextSearch,
    setFullTextSearch,
    createTestCase,
    updateTestCase,
    deleteTestCase,
  };
};
