import React from "react";
import { Tabs as AntTabs } from "antd";
import { TabPaneProps as AntTabPaneProps } from "antd/lib/tabs";

const AntTabPane = AntTabs.TabPane;

export interface ITabPaneProps extends AntTabPaneProps {
  children?: React.ReactNode;
}

export const TabPane = (props: ITabPaneProps) => {
  const { children, ...rest } = props;

  return <AntTabPane {...rest}>{children}</AntTabPane>;
};
