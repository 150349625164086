import React from "react";
import { AssetImages } from "../../AssetImages";
import { ICommonAssetDetailsTabProps } from "./ICommonAssetDetailsTabProps";

interface IImageTabProps extends ICommonAssetDetailsTabProps {
  refresh: () => Promise<void>;
}

export const ImageTab = ({ asset, refresh }: IImageTabProps) => {
  return (
    <>
      <AssetImages
        key={`AssetImages-${asset?.Id}`}
        asset={asset!}
        refreshAsset={refresh}
      />
    </>
  );
};
