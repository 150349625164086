import React from "react";
import { Collapse as AntCollapse } from "antd";
import { CollapsePanelProps as AntCollapsePanelProps } from "antd/lib/collapse";

export interface CollapsePanelProps extends AntCollapsePanelProps {}

export class CollapsePanel extends React.PureComponent<CollapsePanelProps> {
  public render() {
    const { children, ...rest } = this.props;
    return <AntCollapse.Panel {...rest}>{children}</AntCollapse.Panel>;
  }
}
