import React from "react";
import { TimePicker as AntTimePicker, TimePickerProps as AntTimePickerProps } from "antd";
import { Dayjs } from "dayjs"

export declare type IDatePickerMoment = Dayjs;

export interface TimePickerProps extends AntTimePickerProps {
  addon?: () => React.ReactNode;
  popupClassName?: string;
}

export const TimePicker: React.FC<TimePickerProps> = (props) => {
  return <AntTimePicker {...props} />;
};
