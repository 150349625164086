export const GET_TEXT_COMPONENT = "GET_TEXT_COMPONENT";
export const GET_TEXT_COMPONENT_SUCCESS = "GET_TEXT_COMPONENT_SUCCESS";
export const GET_TEXT_COMPONENT_FAILURE = "GET_TEXT_COMPONENT_FAILURE";

export const GET_TEXT_COMPONENT_ATTACHEMENT_UPLOAD_URL =
  "GET_TEXT_COMPONENT_ATTACHEMENT_UPLOAD_URL";
export const GET_TEXT_COMPONENT_ATTACHEMENT_UPLOAD_URL_SUCCESS =
  "GET_TEXT_COMPONENT_ATTACHEMENT_UPLOAD_URL_SUCCESS";
export const GET_TEXT_COMPONENT_ATTACHEMENT_UPLOAD_URL_FAILURE =
  "GET_TEXT_COMPONENT_ATTACHEMENT_UPLOAD_URL_FAILURE";

export const GET_TEXT_COMPONENT_ATTACHEMENT_URL =
  "GET_TEXT_COMPONENT_ATTACHEMENT_URL";
export const GET_TEXT_COMPONENT_ATTACHEMENT_URL_SUCCESS =
  "GET_TEXT_COMPONENT_ATTACHEMENT_URL_SUCCESS";
export const GET_TEXT_COMPONENT_ATTACHEMENT_URL_FAILURE =
  "GET_TEXT_COMPONENT_ATTACHEMENT_URL_FAILURE";

export const INSERT_TEXT_COMPONENT = "INSERT_TEXT_COMPONENT";
export const INSERT_TEXT_COMPONENT_SUCCESS = "INSERT_TEXT_COMPONENT_SUCCESS";
export const INSERT_TEXT_COMPONENT_FAILURE = "INSERT_TEXT_COMPONENT_FAILURE";

export const UPDATE_TEXT_COMPONENT = "UPDATE_TEXT_COMPONENT";
export const UPDATE_TEXT_COMPONENT_SUCCESS = "UPDATE_TEXT_COMPONENT_SUCCESS";
export const UPDATE_TEXT_COMPONENT_FAILURE = "UPDATE_TEXT_COMPONENT_FAILUR";
