import { Observable, Observer } from "rxjs";
import { HTTP_METHOD } from "../../constants";
import {
  IAdvertisementBlockModel,
  IAdvertisementBlocksListModel,
  IAdvertisementBlocksSearchFilterModel,
} from "../../models";
import { AxiosSubscriber } from "../AxiosSubscriber";
import { SimpleServiceBase } from "../Base";

export class AdvertisementService extends SimpleServiceBase<
  IAdvertisementBlockModel,
  IAdvertisementBlocksListModel,
  number,
  IAdvertisementBlocksSearchFilterModel
> {
  get url(): string {
    return "/AdBlocks";
  }

  public getDetails = (
    id: number
  ): Observable<IAdvertisementBlockModel> =>
    new Observable(
      (observer: Observer<IAdvertisementBlockModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: {
              id,
            },
          },
          method: HTTP_METHOD.GET,
          url: `${this.url}/GetWithDetails`,
        })
    );

  public saveWithDetails = (
    data: IAdvertisementBlockModel
  ): Observable<IAdvertisementBlockModel> =>
    new Observable(
      (observer: Observer<IAdvertisementBlockModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: `${this.url}/SaveWithDetails`,
        })
    );

  public publish = (
    data: IAdvertisementBlockModel,
    html: string,
  ): Observable<IAdvertisementBlockModel> =>
    new Observable(
      (observer: Observer<IAdvertisementBlockModel>) =>
        new AxiosSubscriber(observer, {
          data: {
            Id: data.Id,
            Html: html,
            RowVersion: data.RowVersion,
          },
          method: HTTP_METHOD.PUT,
          url: `${this.url}/Publish`,
        })
    );

  public archive = (
    data: IAdvertisementBlockModel,
  ): Observable<IAdvertisementBlockModel> =>
    new Observable(
      (observer: Observer<IAdvertisementBlockModel>) =>
        new AxiosSubscriber(observer, {
          data: {
            Id: data.Id,
            RowVersion: data.RowVersion,
          },
          method: HTTP_METHOD.PUT,
          url: `${this.url}/Archive`,
        })
    );

  public restore = (
    data: IAdvertisementBlockModel,
  ): Observable<IAdvertisementBlockModel> =>
    new Observable(
      (observer: Observer<IAdvertisementBlockModel>) =>
        new AxiosSubscriber(observer, {
          data: {
            Id: data.Id,
            RowVersion: data.RowVersion,
          },
          method: HTTP_METHOD.PUT,
          url: `${this.url}/Restore`,
        })
    );
}
