import {
  AssetPurchasePeriodTypeService,
  CurrencyService,
  IAssetPriceModel,
  ICurrencyModel,
  useDataLoader,
} from "@bms/common-services";
import {
  Button,
  Choose,
  ChooseOption,
  Col,
  DatePicker,
  Icon,
  IFormValues,
  Input,
  InputNumber,
  Row,
  Spin,
  IChooseValue,
} from "@bms/common-ui";
import { Form } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AssetBrowserModal } from "../AssetBrowserModal";

const currencyService = new CurrencyService().promisify();
const purchasePeriodTypeService = new AssetPurchasePeriodTypeService().promisify();

export interface IAssetPriceFormProps {
  assetPrice: IAssetPriceModel;
  onCreate?: (data: IAssetPriceModel) => void;
  onUpdate?: (data: IAssetPriceModel) => void;
}

interface ISelectedAsset {
  AssetId: number;
  Title: string;
}

export const AssetPriceForm: React.FC<IAssetPriceFormProps> = (props) => {
  const { assetPrice, onCreate, onUpdate } = props;

  const [form] = Form.useForm();
  const { t } = useTranslation();

  const [browserVisible, setBrowserVisible] = useState<boolean>(false);
  const [blockAssetField, setBlockAssetField] = useState<boolean>(false);
  const [selectedAsset, setSelectedAsset] = useState<ISelectedAsset>();
  const [currencyMinPrice, setCurrencyMinPrice] = useState<number>(0);

  const purchasePeriodTypeLoader = useDataLoader({
    loader: () => purchasePeriodTypeService.select(),
    deps: [],
  });

  const currenciesLoader = useDataLoader({
    loader: () => currencyService.select(),
    onLoad: (data: ICurrencyModel[]) => {
      if (assetPrice.CurrencyId === -1 && data.length > 0) {
        form.setFieldsValue({ CurrencyId: data[0].Id });
      }
    },
    deps: [],
  });

  const availableCurrencies = currenciesLoader.data?.filter(
    (item) => item.UpToDate
  );

  useEffect(() => {
    form.resetFields();

    if (assetPrice.AssetId && assetPrice.AssetTitle) {
      setSelectedAsset({
        AssetId: assetPrice.AssetId ?? -1,
        Title: assetPrice.AssetTitle ?? "",
      });
      setBlockAssetField(true);
    }
  }, []);

  const onFinish = async (values: IFormValues) => {
    let updatedAssetPrice: IAssetPriceModel = {
      ...assetPrice,
      AssetId: values.AssetId,
      AssetPurchasePeriodTypeCode: values.AssetPurchasePeriodTypeCode,
      Price: values.Price,
      CurrencyId: values.CurrencyId,
      AvailableFrom: values.AvailableFrom
        ? values.AvailableFrom.toISOString()
        : null,
      AvailableTo: values.AvailableTo ? values.AvailableTo.toISOString() : null,
    };

    if (assetPrice.Id !== -1) {
      onUpdate?.(updatedAssetPrice);
    } else {
      onCreate?.(updatedAssetPrice);
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      AssetId: selectedAsset?.AssetId,
      AssetTitle: selectedAsset?.Title,
    });
  }, [selectedAsset]);

  // clear initial currency field if initial currency is not available
  useEffect(() => {
    if (!currenciesLoader.loading) {
      const currentCurrencyValue = form.getFieldValue("CurrencyId");

      const isActive =
        availableCurrencies?.find(
          (currency) => currency.Id === currentCurrencyValue
        ) !== undefined;

      !isActive && form.setFieldsValue({ CurrencyId: undefined });
    }
  }, [currenciesLoader.loading]);

  const onCurrencyChange = (value: IChooseValue) => {
    const selectedCurrency = availableCurrencies?.find(
      ({ Id }) => Id === value
    );

    if (selectedCurrency?.MinPayment !== undefined) {
      setCurrencyMinPrice(selectedCurrency.MinPayment);
    }
  };

  const renderAssetField = () => {
    if (blockAssetField) {
      return (
        <Form.Item name="AssetTitle" label={t("MODEL_ASSET_PRICE_TITLE")}>
          <Input disabled />
        </Form.Item>
      );
    }

    return (
      <Form.Item>
        <Row gutter={8}>
          <Col span={18}>
            <Form.Item
              label={t("MODEL_ASSET_PRICE_TITLE")}
              name="AssetTitle"
              rules={[
                {
                  required: true,
                  message: t("REQUIRED_VALIDATION_MESSAGE"),
                },
              ]}
            >
              <Input
                disabled
                placeholder={t("MODEL_ASSET_PRICE_TITLE_PLACEHOLDER")}
              />
            </Form.Item>
          </Col>
          <Col span={6} style={{ textAlign: "right" }}>
            <Form.Item label=" ">
              {selectedAsset && (
                <Button
                  icon={<Icon type="delete" />}
                  onClick={() => {
                    setSelectedAsset(undefined);
                  }}
                  style={{ marginRight: "8px" }}
                />
              )}
              <Button
                icon={<Icon type="edit" />}
                onClick={() => {
                  setBrowserVisible(true);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>
    );
  };

  return (
    <Spin
      spinning={currenciesLoader.loading || purchasePeriodTypeLoader.loading}
    >
      <Form
        name="AssetPriceForm"
        className="AssetPriceForm"
        form={form}
        onFinish={onFinish}
        layout="vertical"
      >
        <Row direction="column" justify="space-between" className="full-height">
          <Col>
            <Form.Item hidden={true} name="AssetId">
              <Input hidden />
            </Form.Item>
            {renderAssetField()}
            <Form.Item
              name="AssetPurchasePeriodTypeCode"
              initialValue={assetPrice.AssetPurchasePeriodTypeCode}
              label={t("MODEL_ASSET_PURCHASE_PERIOD_NAME")}
              rules={[
                {
                  required: true,
                  message: t("REQUIRED_VALIDATION_MESSAGE"),
                },
              ]}
            >
              <Choose
                placeholder={t("MODEL_ASSET_PURCHASE_PERIOD_NAME_PLACEHOLDER")}
                loading={purchasePeriodTypeLoader.loading}
                disabled={purchasePeriodTypeLoader.loading}
              >
                {purchasePeriodTypeLoader.data?.map((purchasePeriodType) => (
                  <ChooseOption
                    key={purchasePeriodType.Code}
                    value={purchasePeriodType.Code}
                  >
                    {t(purchasePeriodType.TranslationKey)}
                  </ChooseOption>
                ))}
              </Choose>
            </Form.Item>
            <Form.Item
              name="Price"
              label={t("MODEL_PRICE")}
              style={{ display: "inline-block", width: "calc(50%)" }}
              initialValue={assetPrice.Price}
              rules={[
                {
                  required: true,
                  message: t("REQUIRED_VALIDATION_MESSAGE"),
                },
              ]}
            >
              <InputNumber
                style={{ width: "100%" }}
                placeholder={t("MODEL_PRICE_PLACEHOLDER")}
                step={0.1}
                min={currencyMinPrice}
              />
            </Form.Item>
            <Form.Item
              name="CurrencyId"
              label={t("MODEL_CURRENCY")}
              initialValue={
                assetPrice.CurrencyId !== -1 ? assetPrice.CurrencyId : undefined
              }
              style={{
                display: "inline-block",
                width: "calc(50% - 8px)",
                margin: "0 0 0 8px",
              }}
              rules={[
                {
                  required: true,
                  message: t("REQUIRED_VALIDATION_MESSAGE"),
                },
              ]}
            >
              <Choose
                placeholder={t("MODEL_CURRENCY_PLACEHOLDER")}
                loading={currenciesLoader.loading}
                disabled={currenciesLoader.loading}
                onChange={onCurrencyChange}
              >
                {availableCurrencies?.map((currency) => (
                  <ChooseOption key={currency.Id} value={currency.Id}>
                    {currency.Name}
                  </ChooseOption>
                ))}
              </Choose>
            </Form.Item>
            <Form.Item style={{ marginBottom: 0 }}>
              <Form.Item
                name="AvailableFrom"
                label={t("MODEL_AVAILABLE_FROM")}
                dependencies={["AvailableTo"]}
                rules={[
                  {
                    type: "object",
                    required: true,
                    message: t("REQUIRED_VALIDATION_MESSAGE"),
                  },
                  {
                    validator: (_, AvailableFrom) => {
                      const AvailableTo = form.getFieldValue("AvailableTo");
                      if (AvailableTo && AvailableFrom?.isAfter(AvailableTo)) {
                        return Promise.reject(
                          new Error(t("AVAILABLE_FROM_DATE_VALIDATION_MESSAGE"))
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
                style={{
                  display: "inline-block",
                  width: "calc(50% - 4px)",
                }}
                initialValue={
                  assetPrice.AvailableFrom
                    ? dayjs(assetPrice.AvailableFrom)
                    : dayjs()
                }
              >
                <DatePicker
                  showToday={true}
                  showTime
                  style={{ width: `100%` }}
                />
              </Form.Item>

              <Form.Item
                name="AvailableTo"
                label={t("MODEL_AVAILABLE_TO")}
                dependencies={["AvailableFrom"]}
                initialValue={
                  assetPrice.AvailableTo
                    ? dayjs(assetPrice.AvailableTo)
                    : undefined
                }
                style={{
                  display: "inline-block",
                  width: "calc(50% - 4px)",
                  marginLeft: 8,
                }}
                rules={[
                  {
                    validator: (_, AvailableTo) => {
                      const AvailableFrom = form.getFieldValue("AvailableFrom");
                      if (
                        AvailableFrom &&
                        AvailableTo?.isBefore(AvailableFrom)
                      ) {
                        return Promise.reject(
                          new Error(t("AVAILABLE_TO_DATE_VALIDATION_MESSAGE"))
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <DatePicker
                  placeholder={t("COMMON_SELECT_DATE")}
                  showToday={true}
                  showTime
                  style={{ width: `100%` }}
                />
              </Form.Item>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <AssetBrowserModal
        visible={browserVisible}
        selectionMode={"single"}
        onSelect={(asset) => {
          setSelectedAsset({
            AssetId: asset.Id,
            Title: asset.Title as string,
          });
        }}
        onCancel={() => setBrowserVisible(false)}
      />
    </Spin>
  );
};
