import React from "react";
import {
  ColorResult,
  RGBColor,
  SketchPicker,
  SketchPickerProps,
} from "react-color";
import "./ColorPicker.scss";

export interface IColorResult extends ColorResult {}

export interface IColorPickerProps extends SketchPickerProps {
  color?: string;
  pickerStyle?: React.CSSProperties;
}

export interface IColorPickerState {
  displayColorPicker: boolean;
  color: RGBColor;
}

export class ColorPicker extends React.Component<
  IColorPickerProps,
  IColorPickerState
> {
  public state: Readonly<IColorPickerState> = {
    displayColorPicker: false,
    color: ColorPicker.hexaToRgbA(this.props.color) || {
      r: 24,
      g: 144,
      b: 255,
      a: 1,
    },
  };

  public static hexaToRgbA(hexaVal?: string): RGBColor | undefined {
    if (!hexaVal) {
      return undefined;
    }

    let color: RegExpMatchArray | null = [];

    if (hexaVal && hexaVal !== "inherit") {
      // Remove hash
      let hex = hexaVal.slice(1);
      // Split to four channels
      color = hex ? hex.match(/.{1,2}/g) : [];
    }

    if (color && color.length >= 3) {
      let a: number = 1;

      // RgbA properties
      const r = parseInt(color[0], 16);
      const g = parseInt(color[1], 16);
      const b = parseInt(color[2], 16);

      if (color.length === 4) {
        a = (parseInt(color[3], 16) / 255) * 100;
      }

      return { r, g, b, a };
    }

    return undefined;
  }

  render() {
    const { color, onChange, pickerStyle, ...rest } = this.props;
    const { displayColorPicker } = this.state;

    const colorStyle: React.CSSProperties = {
      width: "48px",
      height: "20px",
      borderRadius: "2px",
      backgroundColor: `rgba(${this.state.color.r}, ${this.state.color.g}, ${this.state.color.b}, ${this.state.color.a})`,
    };

    let pickerInnerStyle: React.CSSProperties = {
      position: "absolute",
      zIndex: 2,
      ...pickerStyle,
    };

    return (
      <div className="ColorPicker">
        <div
          className="ColorPicker-background"
          onClick={() =>
            this.setState({ displayColorPicker: !displayColorPicker })
          }
        >
          <div style={colorStyle} />
        </div>
        {displayColorPicker ? (
          <div className="ColorPicker__SketchPicker" style={pickerInnerStyle}>
            <div
              className="ColorPicker__SketchPicker-wrapper"
              style={{
                position: "fixed",
                top: "0px",
                right: "0px",
                bottom: "0px",
                left: "0px",
              }}
              onClick={() => this.setState({ displayColorPicker: false })}
            />
            <SketchPicker
              color={this.state.color}
              onChange={(color: IColorResult) => {
                this.setState({ color: color.rgb });

                if (onChange) {
                  onChange(color);
                }
              }}
            />
          </div>
        ) : null}
      </div>
    );
  }
}
