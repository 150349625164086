import React from "react";
import { useTranslation } from "react-i18next";
import { AssetChildrenList } from "../../AssetChildrenList";
import { ICommonAssetDetailsTabProps } from "./ICommonAssetDetailsTabProps";
import { AssetType } from "@bms/common-services";

export const SeasonsTab = ({ asset }: ICommonAssetDetailsTabProps) => {
  const { t } = useTranslation();
  return (
    <AssetChildrenList
      key={`AssetContent-${asset?.Id}`}
      title={t("ASSET_DETAILS_SECTION_SEASONS_LIST")}
      asset={asset}
      tabType={AssetType.Season}
    />
  );
};
