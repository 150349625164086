import { AssetContentType } from "../enums";

enum MediaElementType {
  Audio = "audio",
  Video = "video",
}

export class FileHelper {
  static fileToBase64(file: File | Blob) {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  }

  static blobToFile = (
    theBlob: Blob,
    fileName: string,
    type?: string
  ): File => {
    return new File([theBlob], fileName, { type });
  };

  static async getMediaDurationUrl(
    url: string,
    contentType: AssetContentType
  ): Promise<number> {
    let mediaType = MediaElementType.Video;

    if (contentType.startsWith("audio/")) {
      mediaType = MediaElementType.Audio;
    }

    return this._getMediaDuration(url, mediaType);
  }

  static async getMediaDurationFile(file: File): Promise<number> {
    let mediaType = MediaElementType.Video;

    if (file.type.startsWith("audio/")) {
      mediaType = MediaElementType.Audio;
    } else if (file.type.startsWith("video/")) {
      mediaType = MediaElementType.Video;
    } else {
      return Promise.reject("Unsupported media file");
    }

    try {
      return new Promise((resolve) => {
        const rd = new FileReader(); // create a FileReader
        rd.onload = function (e) {
          // when file has read:
          if (!e.target || !e.target.result) {
            return resolve(0);
          }
          // create a blob of buffer
          const blob = new Blob([e.target.result], {
            type: file.type,
          });
          const url = (URL || webkitURL).createObjectURL(blob); // create o-URL of blob
          const mediaElement = document.createElement(mediaType); // create media element

          mediaElement.preload = "metadata"; // preload setting

          mediaElement.addEventListener("loadedmetadata", function () {
            // when enough data loads
            (URL || webkitURL).revokeObjectURL(url); // clean up
            resolve(mediaElement.duration);
          });
          mediaElement.onerror = function (err) {
            console.error(err);
            resolve(0);
          };

          mediaElement.src = url; // start video load
        };

        let chunk: File | Blob = file
        if (mediaType === MediaElementType.Video) {
          chunk = file.slice(0, 5000000); // up to 50MB
        }
        rd.readAsArrayBuffer(chunk); // read file object
      });
    } catch (error) {
      console.error("Error during getting media duration", error);
      return Promise.resolve(0);
    }
  }

  private static async _getMediaDuration(
    url: string,
    mediaType: MediaElementType
  ): Promise<number> {
    return new Promise((resolve) => {
      const mediaElement = document.createElement(mediaType) as
        | HTMLVideoElement
        | HTMLAudioElement;
      mediaElement.muted = true;
      const source = document.createElement("source");
      source.src = url;
      mediaElement.preload = "metadata";
      mediaElement.appendChild(source);
      mediaElement.onloadedmetadata = function () {
        resolve(mediaElement.duration);
      };
      mediaElement.onerror = function () {
        resolve(0);
      };
    });
  }
}
