import { ITestCaseModel, TimeHelper } from "@bms/common-services";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useTestingPlatforms } from "../../hooks";
import {
  Button,
  Icon,
  InputSearch,
  ITableColumnProps,
  ITableFilter,
  PageContent,
  PageHeader,
  Pagination,
  Popconfirm,
  setTableColumnSearchProps,
  Table,
  TagUpToDate,
  Tooltip,
} from "@bms/common-ui";
import { generateBreadcrumb, PlatformTypeHelper } from "../../../../helpers";
import { ROUTES } from "../../constants";
import { FilterCleanIcon } from "../../../../resources/icons";
import { PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import { FormModal } from "../../../../components";
import { useTestCases } from "./useTestCases";
import { TestingTestCaseForm } from "./components";

const defaultTestCase: ITestCaseModel = {
  Id: -1,
  UpToDate: true,
};

export const TestingTestCasesList = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [selected, setSelected] = useState<ITestCaseModel>(defaultTestCase);

  const { t } = useTranslation();
  const { platforms } = useTestingPlatforms();

  const {
    isLoading,
    data,
    pagination,
    fullTextSearch,
    setFullTextSearch,
    filters,
    refreshTestCases,
    createTestCase,
    updateTestCase,
    deleteTestCase,
  } = useTestCases();

  const isNewTestCase = selected.Id < 0;

  const showAddModal = () => {
    setSelected({ ...defaultTestCase });
    setModalVisible(true);
  };

  const onSearch = (value: string) =>
    filters.update((currentFilters) => ({
      ...currentFilters,
      FullTextSearch: value || undefined,
    }));

  const onTableChange = (_: any, incomingFilters: ITableFilter) =>
    filters.update((currentFilters) => ({
      ...currentFilters,
      ...incomingFilters,
    }));

  const onDeleteTestCase = async (testCase: ITestCaseModel) => {
    await deleteTestCase(testCase);
  };

  const handleDeleteTestCase = async () => {
    await deleteTestCase(selected);
    setModalVisible(false);
  };

  const getBreadcrumbProps = () => {
    return generateBreadcrumb([
      {
        path: ROUTES.TEST_CASES,
        breadcrumbName: t("TESTING_TEST_CASES"),
      },
    ]);
  };

  const getExtraButtons = () => {
    return (
      <>
        <InputSearch
          key="search"
          placeholder={t("SEARCH_PLACEHOLDER")}
          value={fullTextSearch}
          onSearch={onSearch}
          onChange={({ target: { value } }) => setFullTextSearch(value)}
          style={{ width: 250 }}
          allowClear
        />
        <Tooltip key="filters-tooltip" overlay={t("MENU_OPTION_CLEAR_FILTERS")}>
          <Button
            key="clear-filters"
            shape="circle"
            icon={<FilterCleanIcon />}
            onClick={filters.clear}
            disabled={!filters.anyActive}
            title={t("MENU_OPTION_CLEAR_FILTERS")}
          />
        </Tooltip>
        <Tooltip key="reload-tooltip" overlay={t("BUTTON_REFRESH_TITLE")}>
          <Button
            key="reload"
            shape="circle"
            icon={<ReloadOutlined />}
            onClick={refreshTestCases}
            title={t("BUTTON_REFRESH_TITLE")}
          />
        </Tooltip>
        <Tooltip key="add-tooltip" overlay={t("TESTING_TEST_CASES_ADD_BUTTON")}>
          <Button
            key="add"
            shape="circle"
            type="primary"
            icon={<PlusOutlined />}
            title={t("TESTING_TEST_CASES_ADD_BUTTON")}
            onClick={showAddModal}
          />
        </Tooltip>
      </>
    );
  };

  const getColumnsProps = (): Array<ITableColumnProps<ITestCaseModel>> => {
    return [
      {
        key: "Code",
        dataIndex: "Code",
        title: t("MODEL_CODE"),
        filteredValue: filters.asTableArray.Code,
        ...setTableColumnSearchProps("Code", t("MODEL_CODE")),
        render: (_, testCase) => {
          return (
            <a
              title={testCase.Code}
              onClick={() => {
                setModalVisible(true);
                setSelected(testCase);
              }}
            >
              {testCase.Code}
            </a>
          );
        },
      },
      {
        key: "Name",
        dataIndex: "Name",
        title: t("MODEL_NAME"),
        filteredValue: filters.asTableArray.Name,
        ...setTableColumnSearchProps("Name", t("MODEL_NAME")),
      },
      {
        key: "Description",
        dataIndex: "Description",
        ellipsis: true,
        title: t("MODEL_DESCRIPTION"),
        filteredValue: filters.asTableArray.Description,
        ...setTableColumnSearchProps("Description", t("MODEL_DESCRIPTION")),
      },
      {
        key: "Platforms",
        dataIndex: "Platforms",
        title: t("MODEL_PLATFORMS"),
        filters: platforms.map((platform) => ({
          text: platform.DisplayName,
          value: platform.Code,
        })),
        filteredValue: filters.asTableArray.Platforms,
        render: (_, testCase) =>
          testCase.Platforms?.map((platform) =>
            PlatformTypeHelper.getTag(platform.PlatformCode)
          ),
      },
      {
        key: "Created",
        dataIndex: "Created",
        title: t("MODEL_CREATION_DATE"),
        align: "center",
        width: "140px",
        render: (creationDate: Date) => TimeHelper.format(creationDate),
      },
      {
        key: "UpToDate",
        dataIndex: "UpToDate",
        title: t("MODEL_UP_TO_DATE"),
        align: "center",
        filters: [
          { text: t("CLASS_LIST_TABLE_UPTODATE_COLUMN_FILTER"), value: "true" },
          {
            text: t("CLASS_LIST_TABLE_OUTTODATE_COLUMN_FILTER"),
            value: "false",
          },
        ],
        filteredValue: filters.asTableArray.UpToDate,
        render: (upToDate: boolean) => <TagUpToDate value={upToDate} />,
      },
      {
        key: "Actions",
        dataIndex: "Actions",
        title: t("TABLE_ACTIONS_COLUMN"),
        align: "center",
        width: "100px",
        render: (_: any, testCase: ITestCaseModel) => (
          <Popconfirm
            title={t("DELETE_ELEMENT_DOUBLE_CONFIRMATION_QUESTION")}
            onConfirm={async (event?: React.MouseEvent<HTMLElement>) => {
              event?.preventDefault();
              await onDeleteTestCase(testCase);
            }}
            okText={t("BUTTON_YES")}
            cancelText={t("BUTTON_NO")}
          >
            <Button
              danger={true}
              icon={<Icon type="delete" />}
              title={t("DELETE_ELEMENT")}
            />
          </Popconfirm>
        ),
      },
    ];
  };

  return (
    <>
      <PageContent footer={<Pagination {...pagination.props} />}>
        <PageHeader
          title={t("TESTING_TEST_CASES")}
          breadcrumb={getBreadcrumbProps()}
          extra={getExtraButtons()}
        />
        <Table<ITestCaseModel>
          rowKey="Id"
          columns={getColumnsProps()}
          dataSource={data?.Entities}
          loading={isLoading}
          pagination={false}
          onChange={onTableChange}
        />
      </PageContent>
      <FormModal
        isLoading={isLoading}
        isVisible={modalVisible}
        isNewForm={isNewTestCase}
        createFormTitle={t("TESTING_TEST_CASES_ADD_HEADER")}
        editFormTitle={t("TESTING_TEST_CASES_EDIT_HEADER")}
        modalClassName="TestingTestCaseModal"
        submitFormName="TestingTestCaseForm"
        onCloseModal={() => setModalVisible(false)}
        onDeleteButtonClick={handleDeleteTestCase}
        isDeleteButtonEnabled
      >
        <TestingTestCaseForm
          testCase={selected}
          setVisibilityStatus={setModalVisible}
          createTestCase={createTestCase}
          updateTestCase={updateTestCase}
          isLoading={isLoading}
        />
      </FormModal>
    </>
  );
};
