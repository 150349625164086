import { ICommonAppState } from "@bms/common-services";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { TextWidgetComponentPoperties as TextWidgetComponentPopertiesDefinition } from "./TextWidgetComponentPoperties";
import { Dispatch } from "redux";

const mapStateToProps = (state: ICommonAppState) => {
  return {
    isProcessingData: state.applicationConfiguration.isProcessingData,
    sources: state.textComponents,
    files: state.textComponents.fileInfo,
  };
};

const mapDispatchToProps = (_: Dispatch) => ({});

export const TextWidgetComponentPoperties = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(TextWidgetComponentPopertiesDefinition));
