import { ICommonAppState, IUserModel, UserStore } from "@bms/common-services";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { IUserAccountSettingsFormDispatchProps, IUserAccountSettingsFormOwnProps, IUserAccountSettingsFormStateProps, UserAccountSettingsForm as UserAccountSettingsFormDefinition } from "./UserAccountSettingsForm";

const mapStateToProps = (state: ICommonAppState): IUserAccountSettingsFormStateProps => {
  return {
    actionType: state.user.actionType,
    isProcessingData: state.user.isProcessingData,
    error: state.user.error,
    profiles: state.user.profiles,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): IUserAccountSettingsFormDispatchProps => ({
  updateUser: (data: IUserModel) => {
    return dispatch(UserStore.Actions.updateUser(data));
  },
  selectProfiles: () => {
    return dispatch(UserStore.Actions.selectProfiles());
  },
});

export const UserAccountSettingsForm = connect<IUserAccountSettingsFormStateProps, IUserAccountSettingsFormDispatchProps, IUserAccountSettingsFormOwnProps, ICommonAppState>(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(UserAccountSettingsFormDefinition));
