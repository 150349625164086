import { ApplicationResourceType } from "@bms/common-services";
import {
  Button,
  Col,
  Form,
  Icon,
  IFormInstance,
  IFormValues,
  Input,
  Row,
} from "@bms/common-ui";
import React from "react";
import { WithTranslation } from "react-i18next";
import { ApplicationRegisterSuccessPropertyModel } from "../../models";
import { ApplicationResourceUploadModal } from "../ApplicationResourceUploadModal";
import {
  ComponentPropertiesBase,
  IComponentPropertiesBaseProps,
  IComponentPropertiesBaseState,
} from "../ComponentPropertiesBase";
import { ImagePreviewModal } from "../ImagePreviewModal";

export interface ApplicationRegisterSuccessPropertiesStateProps {
  isProcessingData: boolean;
}

export interface ApplicationRegisterSuccessPropertiesDispatchProps {}

export interface ApplicationRegisterSuccessPropertiesOwnProps {}

export interface ApplicationRegisterSuccessPropertiesProps
  extends ApplicationRegisterSuccessPropertiesStateProps,
    ApplicationRegisterSuccessPropertiesDispatchProps,
    ApplicationRegisterSuccessPropertiesOwnProps,
    IComponentPropertiesBaseProps,
    WithTranslation {}

export interface ApplicationRegisterSuccessPropertiesState
  extends IComponentPropertiesBaseState {
  applicationResourceUploadModalVisible: boolean;
  backgroundPreviewModalVisible: boolean;
}

export class ApplicationRegisterSuccessProperties extends ComponentPropertiesBase<
  ApplicationRegisterSuccessPropertiesProps,
  ApplicationRegisterSuccessPropertiesState,
  ApplicationRegisterSuccessPropertyModel
> {
  public static defaultProps = {
    isProcessingData: false,
  };

  formRef = React.createRef<IFormInstance>();

  public state: Readonly<ApplicationRegisterSuccessPropertiesState> = {
    applicationResourceUploadModalVisible: false,
    backgroundPreviewModalVisible: false,
  };

  getPropertiesModel(): ApplicationRegisterSuccessPropertyModel {
    const { component } = this.props;
    const properties = new ApplicationRegisterSuccessPropertyModel();
    properties.init(component);

    return properties;
  }

  public onFinish = (values: IFormValues) => {
    const { component } = this.props;

    if (!component) {
      return;
    }

    component.Name = values.Name;
  };

  public onApplicationResourceUploadCancel = () => {
    this.setState({ applicationResourceUploadModalVisible: false });
  };

  public onApplicationResourceUploadSuccess = (
    filePath?: string,
    fileUrl?: string
  ) => {
    this.properties.BackgroundUrl = filePath;
    this.properties.BackgroundAbsoluteUrl = fileUrl;
    this.onPropertyChange("BackgroundUrl");
    this.formRef?.current?.setFieldsValue({
      BackgroundUrl: filePath,
    });
    this.setState({ applicationResourceUploadModalVisible: false });
  };

  public onShowBackgroundPreviewModal = () => {
    const videoUrl =
      this.properties.BackgroundAbsoluteUrl ?? this.properties.BackgroundUrl;
    if (!videoUrl) {
      return;
    }

    this.setState({ backgroundPreviewModalVisible: true });
  };

  public onCloseBackgroundPreviewModal = () => {
    this.setState({ backgroundPreviewModalVisible: false });
  };

  public renderBackgroundUrlProperty = () => {
    const { component, isProcessingData, t } = this.props;
    const {
      applicationResourceUploadModalVisible,
      backgroundPreviewModalVisible,
    } = this.state;

    return (
      <Row gutter={8}>
        <Col flex="auto">
          <Form.Item
            name="BackgroundUrl"
            label={t("CONFIGURATION_PROPERTY__BACKGROUND_URL")}
            initialValue={this.properties.BackgroundUrl}
          >
            <Input
              disabled={isProcessingData}
              placeholder={"https://"}
              onChange={(e) => {
                this.properties.BackgroundUrl = e.target.value;
                this.onPropertyChange("BackgroundUrl");
              }}
            />
          </Form.Item>
        </Col>
        <Col style={{ textAlign: "right" }}>
          <ApplicationResourceUploadModal
            key={`ApplicationResourceUploadModal-${component.Id}`}
            visible={applicationResourceUploadModalVisible}
            applicationConfigurationId={component.ApplicationConfigurationId}
            resourceType={ApplicationResourceType.Image}
            titleTransKey="CONFIGURATION_BUTTON__UPLOAD_IMAGE_TITLE"
            onCancel={this.onApplicationResourceUploadCancel}
            onSuccess={this.onApplicationResourceUploadSuccess}
          />
          {this.properties.BackgroundAbsoluteUrl && (
            <>
              <ImagePreviewModal
                visible={backgroundPreviewModalVisible}
                imageSrc={this.properties.BackgroundAbsoluteUrl}
                onCancel={this.onCloseBackgroundPreviewModal}
              />
              <Button
                title={t("CONFIGURATION_BUTTON__PREVIEW_IMAGE_TITLE")}
                style={{ marginRight: "8px" }}
                icon={<Icon type="Eye" style={{ fontSize: "20px" }} />}
                onClick={() => this.onShowBackgroundPreviewModal()}
              />
            </>
          )}
          <Button
            icon={<Icon type="upload" />}
            title={t("CONFIGURATION_BUTTON__UPLOAD_IMAGE_TITLE")}
            onClick={() => {
              this.setState({ applicationResourceUploadModalVisible: true });
            }}
          />
        </Col>
      </Row>
    );
  };

  public render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 8 },
        lg: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 16 },
        lg: { span: 20 },
      },
    };

    return (
      <Form
        name="ApplicationRegisterSuccessProperties"
        ref={this.formRef}
        {...formItemLayout}
        onFinish={this.onFinish}
      >
        <Row direction="column" justify="space-between" className="full-height">
          <Col>{this.renderBackgroundUrlProperty()}</Col>
        </Row>
      </Form>
    );
  }
}
