import {
  ApplicationConfigurationStore,
  IApplicationScreenModel,
  ICommonAppState,
} from "@bms/common-services";
import {
  Button,
  Icon,
  ITableColumnProps,
  ITableFilter,
  ITablePaginationConfig,
  Link,
  PageContent,
  PageHeader,
  Popconfirm,
  Table,
  useAppFeedback,
  useSearchParamsFilters,
} from "@bms/common-ui";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { PlatformTypeHelper } from "../../../../helpers";
import { ROUTES } from "../../constants";
import { ScreenTypeHelper } from "../../helpers";
import { ApplicationScreenAddModal } from "../ApplicationScreenAddModal";

import "./ApplicationConfigurationScreensList.scss";

export interface IApplicationConfigurationScreensListProps {
  configurationId?: number;
  screens?: IApplicationScreenModel[];
}

export const ApplicationConfigurationScreensList = ({
  configurationId,
  screens,
}: IApplicationConfigurationScreensListProps) => {
  const [t] = useTranslation();
  const { notification } = useAppFeedback();
  const dispatch = useDispatch();

  const filters = useSearchParamsFilters({
    ScreenTypeCode: "strings",
    Platforms: "strings",
  });

  const [addScreenModalVisible, setAddScreenModalVisible] = useState(false);

  const {
    actionType,
    error,
    isLoadingData,
    platforms,
    screensTypes,
  } = useSelector(({ applicationConfiguration, common }: ICommonAppState) => ({
    actionType: applicationConfiguration.actionType,
    error: applicationConfiguration.error,
    isLoadingData: applicationConfiguration.isLoadingData,
    platforms: common.platforms,
    screensTypes: applicationConfiguration.screensTypes,
  }));

  const deleteScreen = useCallback(
    (screen: IApplicationScreenModel) =>
      dispatch(ApplicationConfigurationStore.Actions.deleteScreen(screen)),
    [dispatch]
  );

  const getConfiguration = useCallback(
    (id: number) =>
      dispatch(ApplicationConfigurationStore.Actions.getConfiguration(id)),
    [dispatch]
  );

  useEffect(() => {
    switch (actionType) {
      case ApplicationConfigurationStore.Consts
        .DELETE_APPLICATION_SCREEN_FAILURE:
        return notification.error({
          message: t("APPLICATION_SCREEN_DETAILS__DELETE_SCREEN_FAILURE"),
          description: error ? error.Message : undefined,
        });
      case ApplicationConfigurationStore.Consts
        .DELETE_APPLICATION_SCREEN_SUCCESS:
        notification.success({
          message: t("APPLICATION_SCREEN_DETAILS__DELETE_SCREEN_SUCCESS"),
        });
        const paramId = parseInt(
          location.pathname.substring(location.pathname.lastIndexOf("/") + 1)
        );
        getConfiguration(paramId);
        break;
    }
  }, [actionType, error]);

  const getColumnsProps = (): Array<
    ITableColumnProps<IApplicationScreenModel>
  > => {
    return [
      {
        key: "Name",
        dataIndex: "Name",
        title: t("MODEL_NAME"),
        render: (text: any, row: IApplicationScreenModel) => {
          return (
            <Link
              to={{
                pathname: `${ROUTES.CONFIGURATION_SCREEN_DETAILS}/${row.Id}`,
                state: { prevId: configurationId },
              }}
            >
              {row.Name}
            </Link>
          );
        },
      },
      {
        key: "ScreenTypeCode",
        dataIndex: "ScreenTypeCode",
        title: t("MODEL_TYPE_CODE"),
        filterMultiple: false,
        filters: screensTypes?.map((screenType) => ({
          text: screenType.Name,
          value: screenType.Code,
        })),
        filteredValue: filters.asTableArray.ScreenTypeCode,
        onFilter: (value, record) =>
          record.ScreenTypeCode!.includes(`${value}`),
        render: (text: any, row: IApplicationScreenModel) =>
          ScreenTypeHelper.getTag(row.ScreenTypeCode),
      },
      {
        key: "Platforms",
        dataIndex: "Platforms",
        title: t("MODEL_PLATFORMS"),
        filters: platforms?.map((platform) => ({
          text: platform.DisplayName,
          value: platform.Code,
        })),
        filteredValue: filters.asTableArray.Platforms,
        onFilter: (value, record) => {
          if (!record.Platforms) {
            return false;
          }
          const isPlatformAvaiable = screens
            ?.filter(
              (screen) => screen.ScreenTypeCode === record.ScreenTypeCode
            )
            .some((screen) =>
              screen.Platforms?.some(
                (platform) => platform.PlatformCode === value
              )
            );

          if (!isPlatformAvaiable) {
            return record.Platforms.some((platform) => {
              return platform.PlatformCode === "ANY";
            });
          }

          return record.Platforms.some((platform) => {
            return platform.PlatformCode === value;
          });
        },
        render: (text: any, row: IApplicationScreenModel) => {
          const platformsView: React.ReactNode[] = [];

          if (row.Platforms && row.Platforms.length > 0) {
            for (const platform of row.Platforms) {
              platformsView.push(
                PlatformTypeHelper.getTag(platform.PlatformCode)
              );
            }
          }

          return platformsView;
        },
      },
      {
        dataIndex: "Description",
        title: t("MODEL_DESCRIPTION"),
      },
      {
        key: "Actions",
        dataIndex: "Actions",
        align: "center",
        title: t("TABLE_ACTIONS_COLUMN"),
        render: (_: any, row: IApplicationScreenModel) => (
          <Popconfirm
            title={t("DELETE_ELEMENT_DOUBLE_CONFIRMATION_QUESTION")}
            onConfirm={async (e?: React.MouseEvent<HTMLElement>) => {
              e?.preventDefault();
              deleteScreen(row);
            }}
            okText={t("BUTTON_YES")}
            cancelText={t("BUTTON_NO")}
          >
            <Button
              danger={true}
              icon={<Icon type="delete" />}
              title={t("DELETE_ELEMENT")}
            />
          </Popconfirm>
        ),
      },
    ];
  };

  const onAddScreenClick = () => {
    if (!configurationId) {
      return;
    }

    setAddScreenModalVisible(true);
  };

  const closeAddScreenModal = () => {
    setAddScreenModalVisible(false);
  };

  const onTableChange = (
    _: ITablePaginationConfig,
    tableFilter: ITableFilter
  ) => {
    filters.update((currentFilter) => ({
      ...currentFilter,
      ...tableFilter,
    }));
  };

  return (
    <PageContent>
      <PageHeader
        title={t("APPLICATION_CONFIGURATION__SCREENS_TAB")}
        extra={
          <Button
            shape="circle"
            type="primary"
            icon={<Icon type="plus" />}
            onClick={onAddScreenClick}
            title={t("APPLICATION_CONFIGURATION_SCREENS__ADD_SCREEN")}
          />
        }
      />
      <Table<IApplicationScreenModel>
        pagination={false}
        rowKey="Guid"
        columns={getColumnsProps()}
        dataSource={screens}
        loading={isLoadingData}
        onChange={onTableChange}
      />
      <ApplicationScreenAddModal
        configurationId={configurationId!}
        visible={addScreenModalVisible}
        onCancel={closeAddScreenModal}
        onSuccess={closeAddScreenModal}
      />
    </PageContent>
  );
};

export default ApplicationConfigurationScreensList;
