import {
  IApplicationComponentLayoutModel,
  IApplicationComponentModel,
  RecordStatus,
} from "@bms/common-services";
import { ComponentLayoutPropertyModel } from "./ComponentLayoutPropertyModel";
import { LayoutOptionsModel } from "./LayoutOptionsModel";
import { ListComponentPropertyModel } from "./ListComponentPropertyModel";

export class PlayListComponentPropertyModel extends ListComponentPropertyModel {
  public getLayoutProperty(
    component: IApplicationComponentModel,
    layoutOptions: LayoutOptionsModel
  ): ComponentLayoutPropertyModel {
    if (!component.Layouts) {
      component.Layouts = [];
    }

    let componentLayout = component.Layouts.find(
      (row: IApplicationComponentLayoutModel) =>
        row.PlatformCode === layoutOptions.Platform
    );

    if (!componentLayout) {
      componentLayout = {
        RecordStatus: RecordStatus.Inserted,
        ApplicationComponentId: component.Id,
        PlatformCode: layoutOptions.Platform,
        PositionX: 0,
        PositionY: layoutOptions.CurrentPositionY,
        Width: 6,
        Height: 6,
      };

      component.Layouts.push(componentLayout);
    }

    return {
      PositionX: componentLayout.PositionX,
      PositionY: componentLayout.PositionY,
      Width: componentLayout.Width,
      Height: componentLayout.Height,
      IsResizable: true,
      IsDraggable: true,
    };
  }
}
