import React, { useMemo } from "react";
import {
  DatePicker,
  Form,
  FormFieldRule,
  IFormInstance,
  useValidate,
} from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { IAssetFormFieldCommonProps } from "./IAssetFormFieldCommonProps";
import { AssetType } from "@bms/common-services";

interface IStartEndDatesFieldsProps extends IAssetFormFieldCommonProps {
  form: IFormInstance;
}

export const StartEndDatesFields = ({
  isHidden,
  isEditMode,
  asset,
  form,
}: IStartEndDatesFieldsProps) => {
  const { t } = useTranslation();
  const { isBefore, isAfter } = useValidate();
  const isRequired = useMemo(
    () => form.getFieldValue("AssetTypeCode") === AssetType.Program,
    []
  );

  if (isHidden) {
    return null;
  }

  const rules: FormFieldRule[] = [];

  switch (asset?.AssetTypeCode) {
    case AssetType.Program:
      rules.push({
        type: "object",
        required: true,
        message: t("REQUIRED_VALIDATION_MESSAGE"),
      });
      break;
  }

  return (
    <React.Fragment key="StartEndDates">
      <Form.Item
        name="StartDateTime"
        label={t("MODEL_START_DATE_TIME")}
        key="StartDateTime"
        validateFirst
        dependencies={["AvailableFrom"]}
        initialValue={
          isEditMode && asset?.StartDateTime
            ? new Date(asset?.StartDateTime)
            : undefined
        }
        rules={[
          ...rules,
          { required: isRequired },
          ...isAfter(
            form,
            "AvailableFrom",
            "StartDateTime",
            "START_DATE_AFTER_AVAILABLE_FROM_MESSAGE"
          ),
          ...isBefore(
            form,
            "StartDateTime",
            "EndDateTime",
            "START_DATE_TIME_MESSAGE"
          ),
        ]}
      >
        <DatePicker
          showTime={true}
          placeholder={t("MODEL_START_DATE_TIME_PLACEHOLDER")}
          style={{ width: "100%" }}
        />
      </Form.Item>
      <Form.Item
        name="EndDateTime"
        label={t("MODEL_END_DATE_TIME")}
        key="EndDateTime"
        initialValue={
          isEditMode && asset?.EndDateTime
            ? new Date(asset?.EndDateTime)
            : undefined
        }
        rules={[
          ...rules,
          { required: isRequired },
          ...isAfter(
            form,
            "StartDateTime",
            "EndDateTime",
            "END_DATE_TIME_MESSAGE"
          ),
        ]}
      >
        <DatePicker
          showTime={true}
          placeholder={t("MODEL_END_DATE_TIME_PLACEHOLDER")}
          style={{ width: "100%" }}
        />
      </Form.Item>
    </React.Fragment>
  );
};
