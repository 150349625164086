import {
  AdvertisementStore,
  ICommonAppState,
} from "@bms/common-services";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
  AdvertisementBoardAddModal as AdvertisementBoardAddModalDefinition,
  IAdvertisementBoardAddModalDispatchProps,
  IAdvertisementBoardAddModalOwnProps,
  IAdvertisementBoardAddModalStateProps,
} from "./AdvertisementBoardAddModal";

const mapStateToProps = (
  state: ICommonAppState,
  ownProps: IAdvertisementBoardAddModalOwnProps
): IAdvertisementBoardAddModalStateProps => {
  return {
    advertisementBlock: state.advertisement.advertisementBlock,
    advertisementBoard: state.advertisement.advertisementBoard,
    actionType: state.advertisement.actionType,
    error: state.advertisement.error,
    boardsTypes: state.advertisement.boardsTypes,
    isProcessingData: state.advertisement.isProcessingData,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch
): IAdvertisementBoardAddModalDispatchProps => ({
    selectBoardsTypes: () => {
      return dispatch(AdvertisementStore.Actions.selectBoardsTypes());
    },
});

export const AdvertisementBoardAddModal = connect<
  IAdvertisementBoardAddModalStateProps,
  IAdvertisementBoardAddModalDispatchProps,
  IAdvertisementBoardAddModalOwnProps,
  ICommonAppState
>(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(AdvertisementBoardAddModalDefinition));
