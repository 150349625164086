import React from "react";
import { Pagination as AntPagination } from "antd";
import { PaginationConfig, PaginationProps } from "antd/lib/pagination";

export interface IPaginationConfig extends PaginationConfig {}

export interface IPaginationProps extends PaginationProps {
  children?: React.ReactNode;
}

export class Pagination extends React.PureComponent<IPaginationProps> {
  public render() {
    let { children, ...rest } = this.props;

    return <AntPagination {...rest}>{children}</AntPagination>;
  }
}
