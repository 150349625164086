import {
  IApplicationModel,
  IConsentModel,
  IErrorModel,
  ILanguageModel,
  IPlatformModel,
  IResourceModel,
  IResourcesListModel,
  IResourcesSearchFilterModel,
} from "../../models";
import * as Consts from "./consts";
import {
  CommonActionsTypes,
  IBrowseResourceAction,
  IBrowseResourceFailureAction,
  IBrowseResourceSuccessAction,
} from "./types";

export const searchResource = (
  filter: IResourcesSearchFilterModel
): CommonActionsTypes => {
  return {
    filter,
    type: Consts.SEARCH_RESOURCE,
  };
};

export const searchResourceSuccess = (
  data: IResourcesListModel
): CommonActionsTypes => {
  return {
    payload: data,
    type: Consts.SEARCH_RESOURCE_SUCCESS,
  };
};

export const searchResourceFailure = (
  error?: IErrorModel
): CommonActionsTypes => {
  return {
    error,
    type: Consts.SEARCH_RESOURCE_FAILURE,
  };
};

export const browseResource = (
  filter: IResourcesSearchFilterModel
): IBrowseResourceAction => {
  return {
    filter,
    type: Consts.BROWSE_RESOURCE,
  };
};

export const browseResourceSuccess = (
  data: IResourcesListModel
): IBrowseResourceSuccessAction => {
  return {
    payload: data,
    type: Consts.BROWSE_RESOURCE_SUCCESS,
  };
};

export const browseResourceFailure = (
  error?: IErrorModel
): IBrowseResourceFailureAction => {
  return {
    error,
    type: Consts.BROWSE_RESOURCE_FAILURE,
  };
};

export const selectResource = (resourceKey: string): CommonActionsTypes => {
  return {
    resourceKey,
    type: Consts.SELECT_RESOURCE,
  };
};

export const selectResourceSuccess = (
  data: IResourcesListModel
): CommonActionsTypes => {
  return {
    payload: data,
    type: Consts.SELECT_RESOURCE_SUCCESS,
  };
};

export const selectResourceFailure = (
  error?: IErrorModel
): CommonActionsTypes => {
  return {
    error,
    type: Consts.SELECT_RESOURCE_FAILURE,
  };
};

export const updateResource = (data: IResourceModel): CommonActionsTypes => {
  return {
    payload: data,
    type: Consts.UPDATE_RESOURCE,
  };
};

export const updateResourceSuccess = (
  data: IResourceModel
): CommonActionsTypes => {
  return {
    payload: data,
    type: Consts.UPDATE_RESOURCE_SUCCESS,
  };
};

export const updateResourceFailure = (
  error?: IErrorModel
): CommonActionsTypes => {
  return {
    error,
    type: Consts.UPDATE_RESOURCE_FAILURE,
  };
};

export const insertResource = (data: IResourceModel): CommonActionsTypes => {
  return {
    payload: data,
    type: Consts.INSERT_RESOURCE,
  };
};

export const insertResourceSuccess = (
  data: IResourceModel
): CommonActionsTypes => {
  return {
    payload: data,
    type: Consts.INSERT_RESOURCE_SUCCESS,
  };
};

export const insertResourceFailure = (
  error?: IErrorModel
): CommonActionsTypes => {
  return {
    error,
    type: Consts.INSERT_RESOURCE_FAILURE,
  };
};

export const deleteResource = (data: IResourceModel): CommonActionsTypes => {
  return {
    payload: data,
    type: Consts.DELETE_RESOURCE,
  };
};

export const deleteResourceSuccess = (): CommonActionsTypes => {
  return {
    type: Consts.DELETE_RESOURCE_SUCCESS,
  };
};

export const deleteResourceFailure = (
  error?: IErrorModel
): CommonActionsTypes => {
  return {
    error,
    type: Consts.DELETE_RESOURCE_FAILURE,
  };
};

export const deleteResourcesByKey = (
  resourceKey: string
): CommonActionsTypes => {
  return {
    payload: resourceKey,
    type: Consts.DELETE_RESOURCES_BY_KEY,
  };
};

export const deleteResourcesByKeySuccess = (): CommonActionsTypes => {
  return {
    type: Consts.DELETE_RESOURCES_BY_KEY_SUCCESS,
  };
};

export const deleteResourcesByKeyFailure = (
  error?: IErrorModel
): CommonActionsTypes => {
  return {
    error,
    type: Consts.DELETE_RESOURCES_BY_KEY_FAILURE,
  };
};

export const selectApplications = (): CommonActionsTypes => {
  return {
    type: Consts.SELECT_APPLICATION,
  };
};

export const selectApplicationsSuccess = (
  data: IApplicationModel[]
): CommonActionsTypes => {
  return {
    payload: data,
    type: Consts.SELECT_APPLICATION_SUCCESS,
  };
};

export const selectApplicationsFailure = (
  error?: IErrorModel
): CommonActionsTypes => {
  return {
    error,
    type: Consts.SELECT_APPLICATION_FAILURE,
  };
};

export const selectLanguages = (): CommonActionsTypes => {
  return {
    type: Consts.SELECT_LANGUAGE,
  };
};

export const selectLanguagesSuccess = (
  data: ILanguageModel[]
): CommonActionsTypes => {
  return {
    payload: data,
    type: Consts.SELECT_LANGUAGE_SUCCESS,
  };
};

export const selectLanguagesFailure = (
  error?: IErrorModel
): CommonActionsTypes => {
  return {
    error,
    type: Consts.SELECT_LANGUAGE_FAILURE,
  };
};

export const updateLanguage = (data: ILanguageModel): CommonActionsTypes => {
  return {
    payload: data,
    type: Consts.UPDATE_LANGUAGE,
  };
};

export const updateLanguageSuccess = (
  data: ILanguageModel
): CommonActionsTypes => {
  return {
    payload: data,
    type: Consts.UPDATE_LANGUAGE_SUCCESS,
  };
};

export const updateLanguageFailure = (
  error?: IErrorModel
): CommonActionsTypes => {
  return {
    error,
    type: Consts.UPDATE_LANGUAGE_FAILURE,
  };
};

export const selectPlatforms = (): CommonActionsTypes => {
  return {
    type: Consts.SELECT_PLATFORM,
  };
};

export const selectPlatformsSuccess = (
  data: IPlatformModel[]
): CommonActionsTypes => {
  return {
    payload: data,
    type: Consts.SELECT_PLATFORM_SUCCESS,
  };
};

export const selectPlatformsFailure = (
  error?: IErrorModel
): CommonActionsTypes => {
  return {
    error,
    type: Consts.SELECT_PLATFORM_FAILURE,
  };
};

export const selectConsents = (): CommonActionsTypes => {
  return {
    type: Consts.SELECT_CONSENT,
  };
};

export const selectConsentsSuccess = (
  data: IConsentModel[]
): CommonActionsTypes => {
  return {
    payload: data,
    type: Consts.SELECT_CONSENT_SUCCESS,
  };
};

export const selectConsentsFailure = (
  error?: IErrorModel
): CommonActionsTypes => {
  return {
    error,
    type: Consts.SELECT_CONSENT_FAILURE,
  };
};

export const updateConsent = (data: IConsentModel): CommonActionsTypes => {
  return {
    payload: data,
    type: Consts.UPDATE_CONSENT,
  };
};

export const updateConsentSuccess = (
  data: IConsentModel
): CommonActionsTypes => {
  return {
    payload: data,
    type: Consts.UPDATE_CONSENT_SUCCESS,
  };
};

export const updateConsentFailure = (
  error?: IErrorModel
): CommonActionsTypes => {
  return {
    error,
    type: Consts.UPDATE_CONSENT_FAILURE,
  };
};

export const insertConsent = (data: IConsentModel): CommonActionsTypes => {
  return {
    payload: data,
    type: Consts.INSERT_CONSENT,
  };
};

export const insertConsentSuccess = (
  data: IConsentModel
): CommonActionsTypes => {
  return {
    payload: data,
    type: Consts.INSERT_CONSENT_SUCCESS,
  };
};

export const insertConsentFailure = (
  error?: IErrorModel
): CommonActionsTypes => {
  return {
    error,
    type: Consts.INSERT_CONSENT_FAILURE,
  };
};

export const deleteConsent = (data: IConsentModel): CommonActionsTypes => {
  return {
    payload: data,
    type: Consts.DELETE_CONSENT,
  };
};

export const deleteConsentSuccess = (): CommonActionsTypes => {
  return {
    type: Consts.DELETE_CONSENT_SUCCESS,
  };
};

export const deleteConsentFailure = (
  error?: IErrorModel
): CommonActionsTypes => {
  return {
    error,
    type: Consts.DELETE_CONSENT_FAILURE,
  };
};

export const Actions = {
  searchResource,
  browseResource,
  selectResource,
  selectApplications,
  selectLanguages,
  updateLanguage,
  selectPlatforms,
  updateResource,
  insertResource,
  deleteResource,
  deleteResourcesByKey,
  selectConsents,
  insertConsent,
  updateConsent,
  deleteConsent,
};
