import {
  ApplicationConfigurationStore,
  IApplicationConfigurationModel,
  IStateModel,
} from "@bms/common-services";
import {
  AppFeedbackContext,
  Button,
  IBreadcrumbProps,
  Icon,
  PageContent,
  PageHeader,
  Spin,
  TabPane,
  Tabs,
} from "@bms/common-ui";
import React from "react";
import { WithTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";
import { ActionCreator } from "redux";
import { ApplicationConfigurationBrandingThemesList } from "../ApplicationConfigurationBrandingThemesList";
import { ApplicationConfigurationDetailsForm } from "../ApplicationConfigurationDetailsForm";
import { ApplicationConfigurationScreensList } from "../ApplicationConfigurationScreensList";
import { ROUTES } from "../../constants";
import { generateBreadcrumb } from "../../../../helpers";

import "./ApplicationConfigurationDetails.scss";

export interface IApplicationConfigurationDetailsStateProps {
  configuration: IStateModel<IApplicationConfigurationModel>;
  action?: ApplicationConfigurationStore.Types.ApplicationConfigurationActionsTypes;
}

export interface IApplicationConfigurationDetailsDispatchProps {
  getConfiguration: ActionCreator<
    ApplicationConfigurationStore.Types.IGetApplicationConfigurationAction
  >;
  publishConfiguration: ActionCreator<
    ApplicationConfigurationStore.Types.IPublishApplicationConfigurationAction
  >;
}

export interface IApplicationConfigurationDetailsOwnProps {}

export interface IApplicationConfigurationDetailsProps
  extends IApplicationConfigurationDetailsStateProps,
    IApplicationConfigurationDetailsDispatchProps,
    IApplicationConfigurationDetailsOwnProps,
    WithTranslation,
    RouteComponentProps<{ id: string }> {}

export interface IApplicationConfigurationDetailsState {
  activeTabKey: string;
}

export class ApplicationConfigurationDetails extends React.Component<
  IApplicationConfigurationDetailsProps,
  IApplicationConfigurationDetailsState
> {
  static contextType = AppFeedbackContext;
  declare context: React.ContextType<typeof AppFeedbackContext>;

  public state: Readonly<IApplicationConfigurationDetailsState> = {
    activeTabKey:
      new URLSearchParams(this.props.location.search).get("tab") ?? "DETAILS",
  };

  public componentDidMount() {
    const { getConfiguration, match } = this.props;

    getConfiguration(match.params.id);
  }

  componentDidUpdate(prevProps: IApplicationConfigurationDetailsProps) {
    const { configuration, action, t } = this.props;

    if (prevProps.action === action) {
      return;
    }

    switch (action?.type) {
      case ApplicationConfigurationStore.Consts
        .PUBLISH_APPLICATION_CONFIGURATION_SUCCESS:
        this.context.notification.success({
          message: t("APPLICATION_CONFIGURATION__PUBLISH_SUCCESS"),
        });
        break;
      case ApplicationConfigurationStore.Consts
        .PUBLISH_APPLICATION_CONFIGURATION_FAILURE:
        this.context.notification.error({
          description: configuration?.Error?.Message,
          message: t("APPLICATION_CONFIGURATION__PUBLISH_FAILED"),
        });
        break;
      case ApplicationConfigurationStore.Consts
        .UPDATE_APPLICATION_CONFIGURATION_FAILURE:
        this.context.notification.error({
          message: t("APPLICATION_CONFIGURATION__UPDATE_FAILED"),
          description: configuration?.Error?.Message,
        });
        break;
      case ApplicationConfigurationStore.Consts
        .UPDATE_APPLICATION_CONFIGURATION_SUCCESS:
        this.context.notification.success({
          message: t("APPLICATION_CONFIGURATION__UPDATE_SUCCESS"),
        });
        break;
    }
  }

  private onTabClick = (key: string) => {
    this.setState({ activeTabKey: key });
  };

  public onRefreshClick = () => {
    const { getConfiguration, match } = this.props;

    getConfiguration(match.params.id);
  };

  private onPublishClick = () => {
    const { t, configuration } = this.props;

    if (!configuration?.Data) {
      return;
    }

    this.context.modal.confirm({
      title: t("APPLICATION_CONFIGURATION__PUBLISH_TITLE"),
      content: t("APPLICATION_CONFIGURATION__PUBLISH_MESSAGE", {
        configurationName: configuration?.Data.Name,
      }),
      okText: t("APPLICATION_CONFIGURATION__PUBLISH"),
      cancelText: t("COMMON_CANCEL"),
      onOk: this.onPublish,
    });
  };

  public onPublish = () => {
    const { configuration, publishConfiguration } = this.props;

    if (!configuration?.Data) {
      return;
    }

    publishConfiguration(configuration.Data.Id, configuration.Data.RowVersion);
  };

  public render() {
    const { t, configuration } = this.props;
    const { activeTabKey } = this.state;

    const getBreadcrumbProps = (): IBreadcrumbProps => {
      return generateBreadcrumb([
        {
          path: `${ROUTES.CONFIGURATION_LIST}`,
          breadcrumbName: t("APPLICATION_CONFIGURATION_TITLE"),
        },
        {
          path: `${ROUTES.CONFIGURATION_SCREEN_DETAILS}/${configuration?.Data?.Id}`,
          breadcrumbName: t("APPLICATION_CONFIGURATION__MAIN_CONFIGURATION"),
        },
      ]);
    };

    return (
      <div className="ApplicationConfigurationDetails">
        <Spin spinning={configuration.IsProcessing}>
          <PageContent>
            <PageHeader
              title={configuration?.Data?.Name}
              breadcrumb={getBreadcrumbProps()}
              onBack={() => window.history.back()}
              extra={
                <>
                  <Button
                    type="primary"
                    key="action-save"
                    icon={<Icon type="upload" />}
                    title={t("APPLICATION_CONFIGURATION__PUBLISH_TITLE")}
                    loading={configuration.IsProcessing}
                    disabled={configuration.IsProcessing}
                    onClick={this.onPublishClick}
                  >
                    {t("BUTTON_PUBLISH")}
                  </Button>
                  <Button
                    shape="circle"
                    key="action-reload"
                    icon={<Icon type="reload" />}
                    onClick={this.onRefreshClick}
                    title={t("BUTTON_REFRESH_TITLE")}
                  />
                </>
              }
            ></PageHeader>
            <Tabs
              defaultActiveKey={activeTabKey}
              destroyInactiveTabPane
              onTabClick={this.onTabClick}
            >
              <TabPane
                key="DETAILS"
                tab={t("APPLICATION_CONFIGURATION__DETAILS_TAB")}
              >
                <ApplicationConfigurationDetailsForm
                  key={`ApplicationConfigurationDetailsForm-${configuration?.Data?.Id}`}
                  configuration={configuration}
                  isActive={activeTabKey === "DETAILS"}
                />
              </TabPane>
              <TabPane
                key="BRANDING"
                tab={t("APPLICATION_CONFIGURATION__BRANDING_TAB")}
              >
                <ApplicationConfigurationBrandingThemesList
                  configurationId={configuration?.Data?.Id}
                />
              </TabPane>
              <TabPane
                key="SCREENS"
                tab={t("APPLICATION_CONFIGURATION__SCREENS_TAB")}
              >
                <ApplicationConfigurationScreensList
                  key={`ApplicationConfigurationScreensList-${configuration?.Data?.Id}`}
                  configurationId={configuration?.Data?.Id}
                  screens={configuration?.Data?.Screens}
                />
              </TabPane>
              {/* INFO: Tab will be enabled when functionalities will be ready.
              <TabPane
                key="USERS_SCREENS"
                tab={t("users_screens", "Users screens")}
              >
                <ApplicationsUsersScreensList
                  key={`ApplicationsUsersScreensList-${configuration?.Data?.Id}`}
                  configurationId={configuration?.Data?.Id}
                />
              </TabPane> */}
            </Tabs>
          </PageContent>
        </Spin>
      </div>
    );
  }
}
