import React, { useRef, useState } from "react";
import { PageContent, getRandomColorHex } from "@bms/common-ui";
import {
  AggregationPeriodTypeHelper,
  IAnalyticsUserPlatformRatingResultsModel,
} from "@bms/common-services";

import { RatingAppHeader } from "./RatingAppHeader";
import { useUserPlatformRatingDataAnalyticsProvider } from "../../hooks";
import { RatingAppChart } from "./RatingAppChart";
import { RatingAppDataCard } from "./RatingAppDataCard";

import "./RatingApp.scss";

export interface IRatingAppWithColorsModel
  extends IAnalyticsUserPlatformRatingResultsModel {
  Color: string;
}

export const RatingApp = () => {
  const ref = useRef<HTMLDivElement>(null);

  const [ratingsListWithColors, setRatingsListWithColors] = useState<
    IRatingAppWithColorsModel[]
  >([]);
  const {
    data,
    filter,
    loading,
    refreshData,
    setFilter,
    resetFilter,
  } = useUserPlatformRatingDataAnalyticsProvider();

  if (data && ratingsListWithColors?.length === 0) {
    const datas = data.Results.map((o) => ({
      ...o,
      Color: getRandomColorHex(),
    }));
    setRatingsListWithColors(datas);
  }

  return (
    <PageContent ref={ref} className="RatingApp">
      <RatingAppHeader
        filter={filter}
        setFilter={setFilter}
        refreshData={refreshData}
        resetFilter={resetFilter}
      />
      <RatingAppChart
        data={data}
        isLoading={loading}
        ratingsListWithColors={ratingsListWithColors}
        filter={filter}
        aggregationPeriod={AggregationPeriodTypeHelper.getValue(filter.Period)}
      />
      <div className="RatingApp__cards-container">
        <RatingAppDataCard data={data} isLoading={loading} />
      </div>
    </PageContent>
  );
};
