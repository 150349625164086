import React from "react";
import {
  ItemsAlignmentType,
  ItemsAlignmentTypeHelper as CommonItemsAlignmentTypeHelper,
} from "@bms/common-services";
import { ChooseOption } from "@bms/common-ui";
import { Trans } from "react-i18next";

export class ItemsAlignmentTypeHelper extends CommonItemsAlignmentTypeHelper {
  static getOptions(): React.ReactElement[] {
    return [
      <ChooseOption value={ItemsAlignmentType.FitToContent}>
        <Trans i18nKey="APPLICATION_CONFIGURATION_PROPERTIES__FIT_TO_CONTENT">
          {ItemsAlignmentTypeHelper.getDescription(
            ItemsAlignmentType.FitToContent
          )}
        </Trans>
      </ChooseOption>,
      <ChooseOption value={ItemsAlignmentType.JustifyCenter}>
        <Trans i18nKey="APPLICATION_CONFIGURATION_PROPERTIES__JUSTIFY_CENTER">
          {ItemsAlignmentTypeHelper.getDescription(
            ItemsAlignmentType.JustifyCenter
          )}
        </Trans>
      </ChooseOption>,
    ];
  }
}
