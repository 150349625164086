import React from "react";

export function ArrowLeft() {
  return <svg
    width="1em"
    height="1em"
    viewBox="0 0 8 12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7.00002 9.87499L3.12002 5.99499L7.00002 2.11499C7.39002 1.72499 7.39002 1.09499 7.00002 0.704992C6.81319 0.517739 6.55954 0.412506 6.29502 0.412506C6.0305 0.412506 5.77685 0.517739 5.59002 0.704992L1.00002 5.29499C0.61002 5.68499 0.61002 6.31499 1.00002 6.70499L5.59002 11.295C5.98002 11.685 6.61002 11.685 7.00002 11.295C7.38002 10.905 7.39002 10.265 7.00002 9.87499Z" />
  </svg>
}

export function ArrowRight() {
  return <svg
    width="1em"
    height="1em"
    viewBox="0 0 8 12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1.99984 0L0.589844 1.41L5.16984 6L0.589844 10.59L1.99984 12L7.99984 6L1.99984 0Z" />
  </svg>
}
