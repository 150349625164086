import React from "react";
import { useTranslation } from "react-i18next";
import {
  ITestApplicationModel,
  PlatformType,
  TestApplicationsService,
} from "@bms/common-services";
import { Form, useValidate } from "@bms/common-ui";
import { LazyChoose } from "../../../../../../components";
import { PlatformTypeHelper } from "../../../../../../helpers";

interface TestApplicationFieldProps {
  name: string;
  label: string;
  placeholder?: string;
  platforms: PlatformType[];
  disabled?: boolean;
  onChange?: (application?: ITestApplicationModel) => void;
}

const testApplicationsService = new TestApplicationsService().promisify();

export const TestApplicationField = (props: TestApplicationFieldProps) => {
  const {
    name,
    label,
    placeholder,
    platforms,
    onChange,
    disabled = false,
  } = props;

  const { t } = useTranslation();
  const validate = useValidate();

  const loader = (search: string) =>
    testApplicationsService.search({
      PageNumber: 1,
      PageSize: 100,
      FullTextSearch: search,
      Platforms: platforms,
    });

  const toOption = (item: ITestApplicationModel) => {
    const platform = PlatformTypeHelper.getDescription(
      item.PlatformCode as string
    );

    return {
      label: `${item.Name!} (${platform})`,
      value: item.Id.toString(),
      item,
    };
  };

  return (
    <Form.Item
      name={name}
      label={label}
      key={name}
      rules={validate.required({
        choiceField: true,
        message: t("REQUIRE_TEST_APPLICATION_VALIDATION_MESSAGE"),
      })}
    >
      <LazyChoose<ITestApplicationModel, ITestApplicationModel>
        placeholder={placeholder}
        multiple={false}
        disabled={disabled}
        loaderDeps={[platforms]}
        onValueChange={onChange}
        loader={loader}
        candidateToOption={toOption}
        selectedToOption={toOption}
      />
    </Form.Item>
  );
};
