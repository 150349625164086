import React, { useMemo, useState } from "react";
import { Form, RadioGroup, Switch, TextArea, TextEditor } from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { IAssetFormFieldCommonProps } from "./IAssetFormFieldCommonProps";
import { AssetDescriptionMode } from "../AssetFormUtils";

interface IDescriptionFieldProps extends IAssetFormFieldCommonProps {}

export const DescriptionField = ({
  isEditMode,
  isHidden,
  asset,
}: IDescriptionFieldProps) => {
  const { t } = useTranslation();
  const [isHTMLPreview, setIsHTMLPreview] = useState(false);
  const [descriptionEditorMode, setDescriptionEditorMode] = useState(
    "LongDescription"
  );

  const descriptionEditorModeOptions: AssetDescriptionMode[] = useMemo(
    () => [
      {
        name: t("COMMON_LONG_LABEL"),
        label: t("MODEL_LONG_DESCRIPTION"),
        value: "LongDescription",
        initialValue: asset?.LongDescription,
      },
      {
        name: t("COMMON_MEDIUM_LABEL"),
        label: t("MODEL_MEDIUM_DESCRIPTION"),
        value: "Description",
        maxLength: 255,
        initialValue: asset?.Description,
      },
      {
        name: t("COMMON_SHORT_LABEL"),
        label: t("MODEL_SHORT_DESCRIPTION"),
        value: "ShortDescription",
        maxLength: 100,
        initialValue: asset?.ShortDescription,
      },
    ],
    [asset]
  );

  if (!isEditMode || isHidden) {
    return null;
  }

  return (
    <Form.Item label={t("MODEL_DESCRIPTION")}>
      <Form.Item>
        <RadioGroup
          buttons
          defaultValue={descriptionEditorMode}
          data={descriptionEditorModeOptions}
          onChange={(e) => setDescriptionEditorMode(e.target.value)}
        />
      </Form.Item>
      <Form.Item>
        <Switch
          checkedChildren={t("COMMON_ACTION_HTML_ON")}
          unCheckedChildren={t("COMMON_ACTION_HTML_OFF")}
          onChange={setIsHTMLPreview}
          defaultChecked={isHTMLPreview}
        />
      </Form.Item>
      {descriptionEditorModeOptions.map((mode) => (
        <Form.Item
          name={mode.value}
          key={mode.value}
          initialValue={isEditMode ? mode.initialValue : ""}
          hidden={mode.value !== descriptionEditorMode}
          rules={[
            {
              max: mode.maxLength,
              message: `"${mode.label}" cannot be longer that ${mode.maxLength} characters`,
            },
          ]}
        >
          {!isHTMLPreview ? (
            <TextArea
              placeholder={t("MODEL_DESCRIPTION_PLACEHOLDER")}
              autoSize={{ minRows: 4 }}
            />
          ) : (
            <TextEditor addImage={mode.value === "LongDescription"} />
          )}
        </Form.Item>
      ))}
    </Form.Item>
  );
};
