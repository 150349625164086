import { IAssetModel, ImageHelper } from "@bms/common-services";
import React, { useEffect, useRef } from "react";
import "./AssetPreview.scss";

interface IAssetPreviewProps {
  asset?: IAssetModel;
}

const srcDoc = require("srcdoc-polyfill");

export const AssetPreview = ({ asset }: IAssetPreviewProps) => {
  const content = asset?.Contents && asset.Contents[0].Content;
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const img = ImageHelper.getFrameImageUrl(asset?.Images);

  const renderDocumentHandler = () => {
    if (iframeRef.current) {
      let height = 0;
      const doc =
        iframeRef.current.contentDocument ??
        iframeRef.current.contentWindow?.document;

      if (doc) {
        const style = document.createElement("style");
        style.innerHTML = `
            body{
            height: 100%;
            padding: 7%;
            color: #fff;
            }

         
            img{
              max-width: 100%;
              height: auto;
            }

            h1 {
              font-size: 2rem;
              font-weight: 400;
            }

            h2 {
              font-weight: 400;
            }

            h3 {
              font-weight: 400;
            }

            h4 {
              font-weight: 400;
            }

            h5 {
              font-weight: 400;
            }

          
          `;
        doc.head.appendChild(style);

        const body = doc.body,
          html = doc.documentElement;
        height = body.scrollHeight;
      }

      if (height) {
        height += 150;
        iframeRef.current.height = `${height}px`;
        iframeRef.current.style.height = `${height}px`;
      } else {
        iframeRef.current.height = "100%";
      }
    }
  };

  useEffect(() => {
    if (content && iframeRef.current) {
      srcDoc.set(iframeRef.current, content);
    }
  }, []);

  const renderArticle = () => {
    {
      return (
        <>
          <div className="AssetPreview__background">
            <div className="AssetPreview__overlay"></div>
            <img src={img}></img>
          </div>

          <iframe
            ref={iframeRef}
            className="AssetPreview__article"
            width="85%"
            onLoad={renderDocumentHandler}
          />
        </>
      );
    }
  };

  return <div className="AssetPreview">{renderArticle()}</div>;
};
