import { AssetType } from "@bms/common-services";
import React from "react";
import { useTranslation } from "react-i18next";
import { AssetChildrenList } from "../../AssetChildrenList";
import { ICommonAssetDetailsTabProps } from "./ICommonAssetDetailsTabProps";

export const EpisodesTab = ({ asset }: ICommonAssetDetailsTabProps) => {
  const { t } = useTranslation();
  return (
    <AssetChildrenList
      key={`AssetContent-${asset?.Id}`}
      title={
        asset?.AssetTypeCode === AssetType.Album
          ? t("ASSET_DETAILS_SECTION_PODCASTS_LIST")
          : t("ASSET_DETAILS_SECTION_EPISODES_LIST")
      }
      asset={asset}
      tabType={
        asset?.AssetTypeCode === AssetType.Album
          ? AssetType.Podcast
          : AssetType.Episode
      }
    />
  );
};
