export const COLORS = [
  "#ef5d28",
  "#406ae9",
  "#12939a",
  "#79c7e3",
  "#0d964a",
  "#ff9833",
];

export const getColor = (index: number) => COLORS[index % COLORS.length];
