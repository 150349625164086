import { connect } from "react-redux";
import { IAppState } from "../../store";
import { AuthenticatedRoute as AuthenticatedRouteDefinition } from "./AuthenticatedRoute";

const mapStateToProps = (state: IAppState) => ({
    isAuthenticated: state.auth.isAuthenticated,
  });

export const AuthenticatedRoute = connect(mapStateToProps)(
  AuthenticatedRouteDefinition
);
