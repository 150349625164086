import { IErrorModel } from "../../models";
import { IAssetSourceEntities } from "../../models/Asset/IAssetSourceEntities";
import {
  GET_SOURCE_ENTITIES,
  GET_SOURCE_ENTITIES_FAILURE,
  GET_SOURCE_ENTITIES_SUCCESS,
  IGetSourceEntitiesAction,
  IGetSourceEntitiesFailureAction,
  IGetSourceEntitiesSuccessAction
} from "./types";

export const getSourceEntities = (): IGetSourceEntitiesAction => {
  return {
    type: GET_SOURCE_ENTITIES,
  };
};

export const getSourceEntitiesSuccess = (payload: IAssetSourceEntities[]): IGetSourceEntitiesSuccessAction => {
  return {
    type: GET_SOURCE_ENTITIES_SUCCESS,
    payload
  };
};

export const getSourceEntitiesFailure = (
  error?: IErrorModel
): IGetSourceEntitiesFailureAction => {
  return {
    error,
    type: GET_SOURCE_ENTITIES_FAILURE,
  };
};

export const SourceEntitiesActions = {
  getSourceEntities,
};
