import {
  CommonStore,
  ICommonAppState,
  IResourcesSearchFilterModel,
} from "@bms/common-services";
import { push } from "connected-react-router";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { ROUTES } from "../../constants";
import { TranslationsList as TranslationsListDefinition } from "./TranslationsList";

const mapStateToProps = (state: ICommonAppState) => {
  return {
    actionType: state.common.actionType,
    isLoadingData: state.common.isLoadingData,
    applications: state.common.applications,
    languages: state.common.languages,
    platforms: state.common.platforms,
    resources: state.common.resources,
    resource: state.common.resource,
    error: state.common.error,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  selectLanguages: () => {
    return dispatch(CommonStore.Actions.selectLanguages());
  },
  selectPlatforms: () => {
    return dispatch(CommonStore.Actions.selectPlatforms());
  },
  selectApplications: () => {
    return dispatch(CommonStore.Actions.selectApplications());
  },
  searchTranslations: (filter: IResourcesSearchFilterModel) => {
    return dispatch(CommonStore.Actions.searchResource(filter));
  },
  goToDetails: (resourceKey: number) => {
    return dispatch(push(`${ROUTES.CONFIGURATION_TRANSLATION_DETAILS}/${resourceKey}`));
  },
  deleteResourcesByKey: (resourceKey: string) => {
    return dispatch(CommonStore.Actions.deleteResourcesByKey(resourceKey));
  },
});

export const TranslationsList = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(TranslationsListDefinition));
