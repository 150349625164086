export const ADD_FAMILY_MEMBER = "ADD_FAMILY_MEMBER";
export const ADD_FAMILY_MEMBER_SUCCESS = "ADD_FAMILY_MEMBER_SUCCESS";
export const ADD_FAMILY_MEMBER_FAILURE = "ADD_FAMILY_MEMBER_FAILURE";

export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_FAILURE = "GET_PROFILE_FAILURE";

export const GET_PROFILE_DETAILS = "GET_PROFILE_DETAILS";
export const GET_PROFILE_DETAILS_SUCCESS = "GET_PROFILE_DETAILS_SUCCESS";
export const GET_PROFILE_DETAILS_FAILURE = "GET_PROFILE_DETAILS_FAILURE";

export const GET_PUBLIC_PROFILE = "GET_PUBLIC_PROFILE";
export const GET_PUBLIC_PROFILE_SUCCESS = "GET_PUBLIC_PROFILE_SUCCESS";
export const GET_PUBLIC_PROFILE_FAILURE = "GET_PUBLIC_PROFILE_FAILURE";

export const GET_AGE_RESTRICTIONS = "GET_AGE_RESTRICTIONS";
export const GET_AGE_RESTRICTIONS_SUCCESS = "GET_AGE_RESTRICTIONS_SUCCESS";
export const GET_AGE_RESTRICTIONS_FAILURE = "GET_AGE_RESTRICTIONS_FAILURE";

export const GET_FAMILY_MEMBERS = "GET_FAMILY_MEMBERS";
export const GET_FAMILY_MEMBERS_SUCCESS = "GET_FAMILY_MEMBERS_SUCCESS";
export const GET_FAMILY_MEMBERS_FAILURE = "GET_FAMILY_MEMBERS_FAILURE";

export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAILURE = "UPDATE_PROFILE_FAILURE";

export const UPDATE_USER_BRANDING = "UPDATE_USER_BRANDING";
export const UPDATE_USER_BRANDING_SUCCESS = "UPDATE_USER_BRANDING_SUCCESS";
export const UPDATE_USER_BRANDING_FAILURE = "UPDATE_USER_BRANDING_FAILURE";

export const GET_USER_BRANDING_SETTINGS = "GET_USER_BRANDING_SETTINGS";
export const GET_USER_BRANDING_SETTINGS_SUCCESS =
  "GET_USER_BRANDING_SETTINGS_SUCCESS";
export const GET_USER_BRANDING_SETTINGS_FAILURE =
  "GET_USER_BRANDING_SETTINGS_FAILURE";

export const SEARCH_USER = "SEARCH_USER";
export const SEARCH_USER_SUCCESS = "SEARCH_USER_SUCCESS";
export const SEARCH_USER_FAILURE = "SEARCH_USER_FAILURE";

export const GET_USER = "GET_USER";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILURE = "GET_USER_FAILURE";

export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";
export const GET_USER_DETAILS_FAILURE = "GET_USER_DETAILS_FAILURE";

export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";

export const ANONYMIZE_USER = "ANONYMIZE_USER";
export const ANONYMIZE_USER_SUCCESS = "ANONYMIZE_USER_SUCCESS";
export const ANONYMIZE_USER_FAILURE = "ANONYMIZE_USER_FAILURE";

export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";

export const INSERT_USER = "INSERT_USER";
export const INSERT_USER_SUCCESS = "INSERT_USER_SUCCESS";
export const INSERT_USER_FAILURE = "INSERT_USER_FAILURE";

export const RESET_USER_PASSWORD = "RESET_USER_PASSWORD";
export const RESET_USER_PASSWORD_SUCCESS = "RESET_USER_PASSWORD_SUCCESS";
export const RESET_USER_PASSWORD_FAILURE = "RESET_USER_PASSWORD_FAILURE";

export const CREATE_USER = "CREATE_USER";

export const SELECT_PROFILES = "SELECT_PROFILES";
export const SELECT_PROFILES_SUCCESS = "SELECT_PROFILES_SUCCESS";
export const SELECT_PROFILES_FAILURE = "SELECT_PROFILES_FAILURE";

export const BROWSE_USERS = "BROWSE_USERS";
export const BROWSE_USERS_SUCCESS = "BROWSE_USERS_SUCCESS";
export const BROWSE_USERS_FAILURE = "BROWSE_USERS_FAILURE";
export const BROWSE_USERS_CANCEL = "BROWSE_USERS_CANCEL";

export const CHANGE_USER_PASSWORD = "CHANGE_USER_PASSWORD";
export const CHANGE_USER_PASSWORD_SUCCESS = "CHANGE_USER_PASSWORD_SUCCESS";
export const CHANGE_USER_PASSWORD_FAILURE = "CHANGE_USER_PASSWORD_FAILURE";

export const GET_USER_CONSENTS = "GET_USER_CONSENTS";
export const GET_USER_CONSENTS_SUCCESS = "GET_USER_CONSENTS_SUCCESS";
export const GET_USER_CONSENTS_FAILURE = "GET_USER_CONSENTS_FAILURE";

export const UPDATE_USER_CONSENT = "UPDATE_USER_CONSENT";
export const UPDATE_USER_CONSENT_SUCCESS = "UPDATE_USER_CONSENT_SUCCESS";
export const UPDATE_USER_CONSENT_FAILURE = "UPDATE_USER_CONSENT_FAILURE";

export const UPDATE_USER_CONSENTS = "UPDATE_USER_CONSENTS";
export const UPDATE_USER_CONSENTS_SUCCESS = "UPDATE_USER_CONSENTS_SUCCESS";
export const UPDATE_USER_CONSENTS_FAILURE = "UPDATE_USER_CONSENTS_FAILURE";

export const RESEND_CONFIRMATION_EMAILS = "RESEND_CONFIRMATION_EMAILS";
export const RESEND_CONFIRMATION_EMAILS_SUCCESS =
  "RESEND_CONFIRMATION_EMAILS_SUCCESS";
export const RESEND_CONFIRMATION_EMAILS_FAILURE =
  "RESEND_CONFIRMATION_EMAILS_FAILURE";
