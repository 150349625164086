import { Observable, Observer } from "rxjs";
import { HTTP_METHOD } from "../../constants/http";
import {
  IAnalyticsAssetImpressionsExportRequestModel,
  IAnalyticsAssetImpressionsFilterModel,
  IAnalyticsAssetImpressionsModel,
  IAnalyticsPaymentListFilterModel,
  IAnalyticsSalesRevenueFilterModel,
  IAnalyticsSalesRevenueModel,
} from "../../models";
import { IAnalyticsPaymentListModel } from "../../models/Analytics/IAnalyticsPaymentListModel";
import { AxiosSubscriber } from "../AxiosSubscriber";
import { PromisifiableBase } from "../Base";

export class AnalyticsService extends PromisifiableBase {
  get url(): string {
    return "/Analytics";
  }

  public getSalesRevenue = (
    filter: IAnalyticsSalesRevenueFilterModel
  ): Observable<IAnalyticsSalesRevenueModel> =>
    new Observable(
      (observer: Observer<IAnalyticsSalesRevenueModel>) =>
        new AxiosSubscriber(observer, {
          data: filter,
          method: HTTP_METHOD.POST,
          url: `${this.url}/GetSalesRevenue`,
        })
    );

  public getPaymentsList = (
    filter: IAnalyticsPaymentListFilterModel
  ): Observable<IAnalyticsPaymentListModel> =>
    new Observable(
      (observer: Observer<IAnalyticsPaymentListModel>) =>
        new AxiosSubscriber(observer, {
          data: filter,
          method: HTTP_METHOD.POST,
          url: `${this.url}/GetPaymentsList`,
        })
    );

  public getAssetImpressions = (
    filter: IAnalyticsAssetImpressionsFilterModel
  ): Observable<IAnalyticsAssetImpressionsModel> =>
    new Observable(
      (observer: Observer<IAnalyticsAssetImpressionsModel>) =>
        new AxiosSubscriber(observer, {
          data: filter,
          method: HTTP_METHOD.POST,
          url: `${this.url}/GetAssetImpressions`,
        })
    );

  public exportAssetImpressions = (
    options: IAnalyticsAssetImpressionsExportRequestModel
  ): Observable<Blob> => {
    return new Observable(
      (observer: Observer<Blob>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            responseType: "blob",
          },
          data: options,
          method: HTTP_METHOD.POST,
          url: `${this.url}/ExportAssetImpressions`,
        })
    );
  };

  public downloadPaymentListReport = (
    filter: IAnalyticsPaymentListFilterModel
  ): Observable<Blob> =>
    new Observable(
      (observer: Observer<Blob>) =>
        new AxiosSubscriber(observer, {
          data: { ...filter, ExportToXlsx: true },
          method: HTTP_METHOD.POST,
          url: `${this.url}/GetPaymentsList`,
          axiosConfig: { responseType: "blob" },
        })
    );
}
