import { useCallback, useEffect, useMemo, useState } from "react";

interface IUseLocalStorageParams<T> {
  key: string;
  parse?: (value: string) => T | null;
  serialize?: (value: T) => string | null;
  fallback?: Exclude<T, undefined>;
}

const defaultParse = <T>(value: string) => {
  try {
    return JSON.parse(value) as T;
  } catch (err) {
    return null;
  }
};

const defaultSerialize = <T>(data: T) => {
  try {
    return JSON.stringify(data);
  } catch (err) {
    return null;
  }
};

export const useLocalStorage = <T>({
  key,
  parse,
  serialize,
  fallback,
}: IUseLocalStorageParams<T>) => {
  const prefixedKey = `${process.env.REACT_APP_KEY || "default"}.${key}`;
  const [localStorageValue, setLocalStorageValue] = useState(() =>
    localStorage.getItem(prefixedKey)
  );

  useEffect(() => {
    if (localStorageValue === null) {
      localStorage.removeItem(prefixedKey);
    } else {
      localStorage.setItem(prefixedKey, localStorageValue);
    }
  }, [localStorageValue, prefixedKey]);

  const value = useMemo(
    () =>
      localStorageValue === null
        ? null
        : (parse ?? defaultParse)(localStorageValue),
    [localStorageValue, parse]
  );

  const setValue = useCallback(
    (v: T | null) => {
      const serializedValue =
        v === null ? null : (serialize ?? defaultSerialize)(v);
      setLocalStorageValue(serializedValue);
    },
    [setLocalStorageValue, serialize]
  );

  return [value ?? fallback, setValue] as const;
};
