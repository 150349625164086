import { IUserModel } from "@bms/common-services";
import { SectionGrid, SectionGridItem, Tag } from "@bms/common-ui";
import React from "react";
import { useTranslation } from "react-i18next";
import "./UserWallet.scss";

interface UserWalletProps {
  user: IUserModel;
}

export const UserWallet = ({ user }: UserWalletProps) => {
  const { t } = useTranslation();

  return (
    <>
      {user.Wallet &&
        user.Wallet.map((walletElement) => (
          <SectionGrid key={walletElement.CurrencyId}>
            <SectionGridItem>
              <div className="UserWallet__block">
                <p className="UserWallet__description">
                  {t("WALLET_CURRENCY_CODE")}
                </p>
                <Tag color="rgb(51, 153, 51)">{walletElement.CurrencyCode}</Tag>
              </div>
              <div className="UserWallet__block">
                <p className="UserWallet__description">{t("WALLET_AMOUNT")}</p>
                <p>{walletElement.Amount}</p>
              </div>
            </SectionGridItem>
          </SectionGrid>
        ))}
    </>
  );
};
