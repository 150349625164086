/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import { HTTP_RESPONSE_TYPE } from "../../../../constants";
import {
  IErrorModel as ICommonErrorModel,
  IAssetCollectionModel,
  IPlaylistSourceModel,
} from "../../../../models";
import { PlayListSourceType } from "../../../../modules";
import { IPlaylistModel } from "../models";

const EXTERNAL_SOURCE = "Axinom";

export class ModelsMapperHelper {
  static toPlaylistSourceModel(entity: IPlaylistModel): IPlaylistSourceModel {
    let result: IPlaylistSourceModel = {
      Id: entity.id,
      Name: entity.title,
      Description: entity.description,
      Type: PlayListSourceType.Standard,
    };

    return result;
  }

  static toAssetCollectionModel(entity: IPlaylistModel): IAssetCollectionModel {
    let result: IAssetCollectionModel = {
      Id: entity.id,
      Name: entity.title,
      Description: entity.description,
      Created: entity.createdDate,
      CreatedByFullName: entity.createdUser,
      ExternalSource: EXTERNAL_SOURCE,
    };

    return result;
  }

  static toErrorModel(error: any): ICommonErrorModel {
    return {
      Message: error?.message,
      ResultType: HTTP_RESPONSE_TYPE.ERROR,
    };
  }
}
