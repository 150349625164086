import React from "react";
import cx from "classnames";

import "./Heading.scss";

export interface IHeadingProps {
  title?: React.ReactNode;
  actions?: React.ReactNode;
  actionsClassName?: string;
}

export const Heading: React.FC<IHeadingProps> = (props) => {
  const { title, actions, actionsClassName } = props;

  return (
    <div className="Heading">
      <div className="Heading--title">
        <h1>{title}</h1>
      </div>
      <div className={cx("Heading--actions", actionsClassName)}>{actions}</div>
    </div>
  );
};
