export const ROUTES = {
  BASE: "/",
  FAQ: "/faq",
  LOGIN: "/login",
  REGISTER: "/register",
  RESET_PASSWORD: "/forgot-password",
  RESET_PASSWORD_SUCCESS: "/forgot-password-success",
  RESET_PASSWORD_LINK: "/forgot-password-link",
  AUTH_CONFIRM_EMAIL: "/auth/confirmAccount",
  AUTH_RESET_PASSWORD: "/auth/resetPassword",
  DASHBOARD: "/dashboard",
  ANALYTICS: "/analytics",
  USER: "/user",
  USER_LIST: "/user/list",
  USER_DETAILS: "/user/details",
  USER_CREATE: "/user/create",
  PROFILE: "/management/profile",
  CONFIGURATION: "/configuration",
  CONFIGURATION_LIST: "/configuration/list",
  CONFIGURATION_DETAILS: "/configuration/details",
  CONFIGURATION_SCREEN_DETAILS: "/configuration/screen/details",
  CONFIGURATION_SCREEN_TYPES_LIST: "/configuration/screen-types/list",
  CONFIGURATION_COMPONENT_TYPES_LIST: "/configuration/component-types/list",
  CONFIGURATION_TRANSLATION_DETAILS: "/configuration/translations/details",
  QUIZ: "/quiz",
  QUIZ_LIST: "/quiz/list",
  QUIZ_DETAILS: "/quiz/details",
  QUIZ_STATUS_LIST: "/quiz/status/list",
  QUIZ_CLASSES_LIST: "/quiz/classes/list",
  QUIZ_CATEGORIES_LIST: "/quiz/categories/list",
  SETTINGS: "/settings",
  DICTIONARIES: "/dictionaries",
  DICTIONARY_APPLICATIONS: "/dictionary/applications",
  DICTIONARY_LANGUAGES: "/dictionary/languages",
  DICTIONARY_PLATFORMS: "/dictionary/platforms",
  DICTIONARY_VIDEO_AGE_RESTRICTIONS: "/dictionary/video-age-restrictions",
  ASSET: "/asset",
  ASSET_LIST: "/asset/list",
  ASSET_DETAILS: "/asset/details",
  ASSET_COLLECTION_LIST: "/asset-collection/list",
  ASSET_COLLECTION_DETAILS: "/asset-collection/details",
  ASSET_CATEGORIES: "/asset/categories",
};
