import { useTranslation } from "react-i18next";
import {
  ITestExecutionModel,
  TestExecutionService,
  useServiceCaller,
} from "@bms/common-services";
import { useTableDataProvider } from "../../../../../../helpers";
import { useAppFeedback } from "@bms/common-ui";

interface TestDefinitionExecutionsParams {
  testDefinitionId: number;
}

const testExecutionService = new TestExecutionService().promisify();

export const useTestExecutions = (params: TestDefinitionExecutionsParams) => {
  const { testDefinitionId } = params;

  const { t } = useTranslation();
  const { notification } = useAppFeedback();

  const {
    dataLoader,
    filters,
    pagination,
    fullTextSearch,
    setFullTextSearch,
  } = useTableDataProvider({
    filtersSchema: {
      FullTextSearch: "string",
      DeviceName: "string",
      ApplicationName: "string",
      ApplicationVersion: "string",
      Statuses: "strings",
      StartDateFrom: "string",
      StartDateTo: "string",
      FinishDateFrom: "string",
      FinishDateTo: "string",
    },
    loader: (filters, pagination) =>
      testExecutionService.search({
        TestId: testDefinitionId,
        ...filters,
        ...pagination,
      }),
    deps: [],
    onError: (error) =>
      notification.error({
        message: t("LOADING_DATA_ERROR_MESSAGE"),
        description: error.Message,
      }),
  });

  const [createExecutions, { processing: createProcessing }] = useServiceCaller(
    async (execution: ITestExecutionModel[]) => {
      const result = await testExecutionService.insertCollection(execution);

      if (result.ok) {
        dataLoader.refresh();
        notification.success({
          message: t("TESTING_TEST_DEFINITIONS_EXECUTION_CREATE_SUCCESS"),
        });
      } else {
        notification.error({
          message: t("TESTING_TEST_DEFINITIONS_EXECUTION_CREATE_FAILURE"),
          description: result.error?.Message,
        });
      }
      return result.ok;
    },
    []
  );

  const [deleteExecution, { processing: deleteProcessing }] = useServiceCaller(
    async (execution: ITestExecutionModel) => {
      const result = await testExecutionService.delete(execution);

      if (result.ok) {
        dataLoader.refresh();
        notification.success({
          message: t("TESTING_TEST_DEFINITIONS_EXECUTION_DELETE_SUCCESS"),
        });
      } else {
        notification.error({
          message: t("TESTING_TEST_DEFINITIONS_EXECUTION_DELETE_ERROR"),
          description: result.error?.Message,
        });
      }
      return result.ok;
    },
    []
  );

  const isLoading = dataLoader.loading || deleteProcessing || createProcessing;

  return {
    isLoading,
    data: dataLoader.data,
    refreshExecutions: dataLoader.refresh,
    createExecutions,
    deleteExecution,
    filters,
    pagination,
    fullTextSearch,
    setFullTextSearch,
  };
};
