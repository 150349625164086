export const ROUTES = {
  ASSET_BASE: "/asset",
  ASSET_LIST: "/asset/list",
  ASSET_DETAILS: "/asset/details",
  ASSET_COLLECTION_LIST: "/asset-collection/list",
  ASSET_COLLECTION_DETAILS: "/asset-collection/details",
  ASSET_CATEGORIES: "/asset/categories",
  ASSET_PRICE_LIST: "/asset/prices/list",
  ASSET_PRICE_DETAILS: "/asset/prices/details",
  ASSET_IMPRESSIONS: "/asset/asset-impressions",
};
