import React from "react";
import { useTranslation } from "react-i18next";
import { AssetInAssetList } from "../../AssetInAssetList";
import { ICommonAssetDetailsTabProps } from "./ICommonAssetDetailsTabProps";

export const AssetsTab = ({ asset }: ICommonAssetDetailsTabProps) => {
  const { t } = useTranslation();
  return (
    <AssetInAssetList
      key={`AssetContent-${asset?.Id}`}
      title={t("ASSET_DETAILS_SECTION_ASSETS_LIST")}
      asset={asset}
    />
  );
};
