import React from "react";

export const ExternalLink: React.FC<React.HTMLProps<HTMLAnchorElement>> = (
  props
) => {
  const { children, ...rest } = props;

  return (
    <a rel="noopener noreferrer" {...rest}>
      {children}
    </a>
  );
};
