import {
  IApplicationComponentModel,
  IApplicationComponentPropertyModel,
  PropertyType,
} from "@bms/common-services";
import { ComponentPropertyModel } from "./ComponentPropertyModel";

export class ApplicationIntroPropertyModel extends ComponentPropertyModel {
  BackgroundVideoUrl?: string;

  BackgroundVideoAbsoluteUrl?: string;

  constructor(component?: IApplicationComponentModel) {
    super();

    if (component) {
      this.init(component);
    }
  }

  initProperties(component: IApplicationComponentModel): void {
    if (!component.Properties) {
      component.Properties = [];
    }

    let backgroundVideoUrlProperty = component.Properties.find(
      (row: IApplicationComponentPropertyModel) =>
        row.Name === "BackgroundVideoUrl"
    );

    if (!backgroundVideoUrlProperty) {
      this.onPropertyChange("BackgroundVideoUrl", component);
    }
  }

  setProperties(component: IApplicationComponentModel): void {
    let backgroundVideoUrlProperty = component.Properties?.find(
      (row: IApplicationComponentPropertyModel) =>
        row.Name === "BackgroundVideoUrl"
    );

    if (backgroundVideoUrlProperty && backgroundVideoUrlProperty.Value) {
      this.BackgroundVideoUrl = backgroundVideoUrlProperty.Value.StringValue;
      this.BackgroundVideoAbsoluteUrl =
        backgroundVideoUrlProperty.Value.UrlValue;
    }
  }

  setProperty(property: IApplicationComponentPropertyModel) {
    switch (property.Name) {
      case "BackgroundVideoUrl":
        property.PropertyType = PropertyType.Url;
        property.IsEnumerable = false;
        break;
    }
  }

  setPropertyValue(property: IApplicationComponentPropertyModel) {
    switch (property.Name) {
      case "BackgroundVideoUrl":
        if (property.Value) {
          property.Value.StringValue = this.BackgroundVideoUrl;
          property.Value.UrlValue = this.BackgroundVideoAbsoluteUrl;
        }
        break;
    }
  }
}
