import { ITestDefinitionModel, TimeHelper } from "@bms/common-services";
import {
  Button,
  Col,
  Form,
  IFormValues,
  Input,
  required,
  Row,
  Spin,
  Tag,
  TAG_PALETTE,
  TextArea,
} from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import React from "react";
import {
  PlatformTypeHelper,
  TestStatusHelper,
} from "../../../../../../helpers";

interface TestDefinitionEditFormProps {
  testDefinition: ITestDefinitionModel;
  updateTestDefinition: (testDefinition: ITestDefinitionModel) => Promise<void>;
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 6,
    },
  },
};

export const TestDefinitionEditForm = (props: TestDefinitionEditFormProps) => {
  const { testDefinition, updateTestDefinition } = props;

  const [form] = Form.useForm();
  const { t } = useTranslation();

  const onFinish = async (values: IFormValues) => {
    await updateTestDefinition({ ...testDefinition, ...values });
  };

  const renderNameInput = () => {
    return (
      <Form.Item name="Name" label={t("MODEL_NAME")} rules={[required()]}>
        <Input placeholder={t("MODEL_NAME")} />
      </Form.Item>
    );
  };

  const renderDescriptionInput = () => {
    return (
      <Form.Item name="Description" label={t("MODEL_DESCRIPTION")}>
        <TextArea maxLength={250} placeholder={t("MODEL_DESCRIPTION")} />
      </Form.Item>
    );
  };

  const renderPlatforms = () => {
    return (
      <Form.Item label={t("MODEL_PLATFORMS")}>
        <span>
          {testDefinition.Platforms?.map((platform) =>
            PlatformTypeHelper.getTag(platform.PlatformCode)
          )}
        </span>
      </Form.Item>
    );
  };

  const renderTestCases = () => {
    return (
      <Form.Item label={t("TESTING_TEST_CASE")}>
        <span>
          {testDefinition.TestCases?.map((testCase) => (
            <Tag key={testCase.TestCaseCode} color={TAG_PALETTE.GREY}>
              {testCase.TestCaseName}
            </Tag>
          ))}
        </span>
      </Form.Item>
    );
  };

  const renderStatus = () => {
    return (
      <Form.Item label={t("MODEL_STATUS")}>
        <span>{TestStatusHelper.getTag(testDefinition?.Status as string)}</span>
      </Form.Item>
    );
  };

  const renderCreationDateBy = () => {
    return (
      <Form.Item label={t("MODEL_CREATED")}>
        <span>
          {testDefinition?.Created
            ? TimeHelper.format(testDefinition.Created)
            : null}
        </span>
      </Form.Item>
    );
  };

  const renderCreatedBy = () => {
    return (
      <Form.Item label={t("MODEL_CREATED_BY")}>
        <span>{testDefinition?.CreatedByFullName}</span>
      </Form.Item>
    );
  };

  return (
    <Spin spinning={!Boolean(testDefinition)}>
      <Form
        name="TestDefinitionEditForm"
        form={form}
        initialValues={{
          ...testDefinition,
          SelectedPlatforms: testDefinition.Platforms?.map(
            (platform) => platform.PlatformCode
          ),
        }}
        onFinish={onFinish}
        {...formItemLayout}
      >
        <Row direction="column" justify="space-between" className="full-height">
          <Col>
            {renderNameInput()}
            {renderDescriptionInput()}
            {renderPlatforms()}
            {renderTestCases()}
            {renderStatus()}
            {renderCreationDateBy()}
            {renderCreatedBy()}
            <Form.Item {...tailFormItemLayout}>
              <Button type="primary" htmlType="submit">
                {t("BUTTON_SAVE")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};
