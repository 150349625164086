import {
  ApplicationConfigurationStore,
  IApplicationScreenModel,
  ICommonAppState,
} from "@bms/common-services";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { withScreenContext } from "../../context";
import { ApplicationScreenDetailsForm as ApplicationScreenDetailsFormDefinition } from "./ApplicationScreenDetailsForm";

const mapStateToProps = (state: ICommonAppState) => {
  return {
    actionType: state.applicationConfiguration.actionType,
    platforms: state.common.platforms,
    isProcessingData: state.applicationConfiguration.isProcessingData,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateScreen: (data: IApplicationScreenModel) => {
    return dispatch(ApplicationConfigurationStore.Actions.updateScreen(data));
  },
});

export const ApplicationScreenDetailsForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withScreenContext(ApplicationScreenDetailsFormDefinition)));
