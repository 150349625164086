import { Orientation } from "../enums";

export class OrientationHelper {
  static getDescription(value: Orientation): string {
    switch (value) {
      case "DEFAULT":
        return "Default";
      case "VERTICAL":
        return "Vertical";
      case "HORIZONTAL":
        return "Horizontal";
      case "GRID":
        return "Grid";
      default:
        return "Undefined";
    }
  }

  static getValue(value: string): Orientation {
    switch (value) {
      case "DEFAULT":
        return Orientation.Default;
      case "VERTICAL":
        return Orientation.Vertical;
      case "HORIZONTAL":
        return Orientation.Horizontal;
      case "GRID":
        return Orientation.Grid;
      default:
        return Orientation.Default;
    }
  }

  static getStringValue(value: Orientation): string {
    switch (value) {
      case Orientation.Default:
        return "DEFAULT";
      case Orientation.Vertical:
        return "VERTICAL";
      case Orientation.Horizontal:
        return "HORIZONTAL";
      case Orientation.Grid:
        return "GRID";
      default:
        return "DEFAULT";
    }
  }
}
