import {
  IAssetInCollectionModel,
  IAssetInCollectionListModel,
  ISearchFilterModelBase,
} from "../../models";
import { SimpleServiceBase } from "../Base";

export class AssetInCollectionService extends SimpleServiceBase<
  IAssetInCollectionModel,
  IAssetInCollectionListModel,
  number,
  ISearchFilterModelBase
> {
  get url(): string {
    return "/AssetsInCollections";
  }
}
