import React from "react";
import { Tag } from "../Tag";

interface AgeRestrictionTagProps {
  age?: number;
}

export const AgeRestrictionTag: React.FC<AgeRestrictionTagProps> = ({
  age,
}) => {
  if (age === undefined) {
    return <Tag color="#666">?</Tag>;
  }
  const color = age < 10 ? "#0a0" : age < 18 ? "#e29800" : "#a00";
  return (
    <Tag
      style={{
        width: "30px",
        padding: "4px 0",
        textAlign: "center",
        fontWeight: 600,
      }}
      color={color}
    >
      {age}
    </Tag>
  );
};
