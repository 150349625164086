import {
  AssetStore,
  IAssetCategoryModel,
  ICommonAppState,
} from "@bms/common-services";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { AssetCategoryForm as AssetCategoryFormDefinition } from "./AssetCategoryForm";

const mapStateToProps = (state: ICommonAppState) => {
  return {
    isProcessing: state.asset.isProcessing,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateCategory: (category: IAssetCategoryModel) => {
    return dispatch(AssetStore.Actions.updateAssetCategory(category));
  },

  insertCategory: (category: IAssetCategoryModel) => {
    return dispatch(AssetStore.Actions.insertAssetCategory(category));
  },
});

export const AssetCategoryForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(AssetCategoryFormDefinition);
