import {
  AssetStore,
  IUserAssetPurchaseModel,
  IUserModel,
  TimeHelper,
  UserAssetPurchasesService,
} from "@bms/common-services";
import {
  Button,
  Icon,
  InputSearch,
  ITableColumnProps,
  ITableFilter,
  Link,
  PageContent,
  PageHeader,
  Pagination,
  PaymentStatusTag,
  setTableColumnSearchProps,
  Table,
  Tag,
  Tooltip,
  useAppFeedback,
} from "@bms/common-ui";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { FilterCleanIcon } from "../../../../resources/icons";
import { useTableDataProvider } from "../../../../helpers";
import { ROUTES as PAYMENT_ROUTES } from "../../../Payment/constants";
import { ROUTES as PRODUCT_ROUTES } from "../../../Products/constants";

const userAssetPurchasesService = new UserAssetPurchasesService().promisify();

export interface IUserPurchasedProductsProps {
  user: IUserModel;
}

export const UserPurchasedProducts: React.FC<IUserPurchasedProductsProps> = ({
  user,
}) => {
  const { t } = useTranslation();
  const { notification } = useAppFeedback();
  const dispatch = useDispatch();

  const {
    assetTypes: { data: assetTypesData = [] },
  } = useSelector(AssetStore.Selectors.assetSelector);

  useEffect(() => {
    dispatch(AssetStore.Actions.getAssetTypes());
  }, [dispatch]);

  const {
    dataLoader: userAssetPurchasesLoader,
    filters,
    pagination,
    fullTextSearch,
    setFullTextSearch,
  } = useTableDataProvider({
    filtersSchema: {
      FullTextSearch: "string",
      UserId: "number",
      AssetId: "number",
      AssetTitle: "string",
      AssetTypes: "strings",
      UpToDate: "boolean",
    },
    loader: (filters, pagination) =>
      userAssetPurchasesService.search({
        ...filters,
        ...pagination,
        UserId: user.Id,
      }),
    deps: [user.Id],
    onError: (error) =>
      notification.error({
        message: t("LOADING_DATA_ERROR_MESSAGE"),
        description: error.Message,
      }),
  });

  const userAssetPurchasesData = userAssetPurchasesLoader.data?.Entities;

  const getColumnsProps = (): Array<
    ITableColumnProps<IUserAssetPurchaseModel>
  > => {
    return [
      {
        key: "AssetTitle",
        dataIndex: "AssetTitle",
        title: t("MODEL_TITLE"),
        filteredValue: filters.asTableArray.AssetTitle,
        ...setTableColumnSearchProps("AssetTitle", t("MODEL_TITLE")),
        render: (text: string, row) => (
          <Link to={`${PRODUCT_ROUTES.PRODUCTS_DETAILS}/${row.AssetId}`}>
            {text}
          </Link>
        ),
      },
      {
        key: "AssetTypes",
        dataIndex: "AssetTypes",
        title: t("MODEL_TYPE_CODE"),
        filters: assetTypesData.map((assetType) => ({
          text: assetType.DisplayName,
          value: assetType.Code,
        })),
        filteredValue: filters.asTableArray.AssetTypes || null,
        render: (_, row) => (
          <Tag
            colorRotate={assetTypesData.findIndex(
              ({ Code }) => Code === row.AssetTypeCode
            )}
          >
            {row.AssetTypeCode}
          </Tag>
        ),
      },
      {
        key: "Price",
        dataIndex: "Price",
        title: t("MODEL_PRICE"),
      },
      {
        key: "Currency",
        dataIndex: "Currency",
        title: t("MODEL_CURRENCY"),
        render: (text: string, row) => (
          <Tag color="#1890ff">{row.Currency}</Tag>
        ),
      },
      {
        key: "AvailableFrom",
        dataIndex: "AvailableFrom",
        title: t("MODEL_AVAILABLE_FROM"),
        render: (availableFrom?: string) =>
          availableFrom ? TimeHelper.format(availableFrom) : null,
      },
      {
        key: "AvailableTo",
        dataIndex: "AvailableTo",
        title: t("MODEL_AVAILABLE_TO"),
        render: (availableTo?: string) =>
          availableTo ? TimeHelper.format(availableTo) : null,
      },
      {
        key: "PaymentKey",
        dataIndex: "PaymentKey",
        title: t("MODEL_PAYMENT_KEY"),
        width: "150px",
        ellipsis: true,
        render: (_: string, row) => {
          return (
            <Link
              to={`${PAYMENT_ROUTES.PAYMENT_DETAILS}/?paymentKey=${row.PaymentKey}`}
            >
              {row.PaymentKey}
            </Link>
          );
        },
      },
      {
        key: "PaymentStatusCode",
        dataIndex: "PaymentStatusCode",
        title: t("MODEL_PAYMENT_STATUS"),
        render: (_: string, row) => (
          <PaymentStatusTag status={row.PaymentStatusCode} />
        ),
      },
    ];
  };

  const onTableChange = (_: any, incomingFilters: ITableFilter) =>
    filters.update((oldFilters) => ({
      ...oldFilters,
      ...incomingFilters,
    }));
  const onSearch = (value: string) =>
    filters.update((oldFilters) => ({
      ...oldFilters,
      FullTextSearch: value || undefined,
    }));

  return (
    <PageContent
      className="UserPurchasedProducts"
      footer={<Pagination {...pagination.props} />}
    >
      <PageHeader
        title=""
        extra={[
          <InputSearch
            key="search"
            placeholder={t("SEARCH_PLACEHOLDER", "Search...")}
            value={fullTextSearch}
            onSearch={onSearch}
            onChange={({ target: { value } }) => setFullTextSearch(value)}
            style={{ width: 250 }}
            allowClear
          />,
          <Tooltip
            key="clear-filter-tooltip"
            overlay={t("MENU_OPTION_CLEAR_FILTERS", "")}
          >
            <Button
              key="clear-filters"
              shape="circle"
              icon={<Icon component={FilterCleanIcon} />}
              onClick={filters.clear}
              disabled={!filters.anyActive}
              title={t("MENU_OPTION_CLEAR_FILTERS", "Clear filters")}
            />
          </Tooltip>,
          <Button
            key="reload"
            shape="circle"
            icon={<Icon type="reload" />}
            onClick={userAssetPurchasesLoader.refresh}
            title={t("BUTTON_REFRESH_TITLE", "Refresh data")}
          />,
        ]}
      />
      <Table<IUserAssetPurchaseModel>
        columns={getColumnsProps()}
        rowKey="PaymentId"
        dataSource={userAssetPurchasesData}
        loading={userAssetPurchasesLoader.loading}
        pagination={false}
        onChange={onTableChange}
      />
    </PageContent>
  );
};
