import React from "react";
import { Form } from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { INotificationField } from "./INotificationField";

export const NotificationStatusReadOnlyField = ({
  notification,
  isHidden,
}: INotificationField) => {
  const { t } = useTranslation();

  if (isHidden) {
    return null;
  }

  return (
    <Form.Item
      name="NotificationStatus"
      label={t("NOTIFICATION_STATUS_FIELD")}
      key="NotificationStatus"
    >
      {t(`${notification?.Status}`)}
    </Form.Item>
  );
};
