import React from "react";
import { Row, Col } from "..";
import { Avatar } from "../Avatar";
import { getColor } from "../Chart/colors";
import { Link } from "../Link";
import "./TileListItem.scss";

interface ITileListItemProps {
  title: React.ReactNode;
  index?: number;
  icon?: React.ReactNode;
  linkTo?: string;
  year?: number;
  genre?: string;
  cover?: string;
  extra?: React.ReactNode;
}

export const TileListItem: React.FC<ITileListItemProps> = ({
  title,
  index,
  icon,
  linkTo,
  year,
  genre,
  cover,
  extra,
}) => {
  return (
    <Row className="tile-list-item">
      <Col xs={4}>
        {cover && (
          <div
            className="tile-list-item-cover"
            style={{ backgroundImage: `url("${cover}")` }}
          />
        )}

        {icon && (
          <Avatar
            icon={icon}
            size="large"
            style={{ backgroundColor: getColor(index ?? 0) }}
          />
        )}
      </Col>
      <Col xs={14}>
        <div className="tile-list-item-title">
          {linkTo ? <Link to={linkTo}>{title}</Link> : title}
        </div>
        <div className="tile-list-item-year">{year}</div>
        <div className="tile-list-item-genre">{genre}</div>
      </Col>
      <Col xs={5}>{extra}</Col>
    </Row>
  );
};
