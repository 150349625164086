import { Observable, Observer } from "rxjs";
import { HTTP_METHOD } from "../constants/http";
import { DeviceHelper } from "../helpers";
import {
  IAuthRequestModel,
  IAuthResponseModel,
  IChangePasswordModel,
  IUserDeviceModel
} from "../models";
import { AxiosSubscriber } from "./AxiosSubscriber";

export class AuthService {
  get url(): string {
    return "/Authorization";
  }

  public signIn = (data: IAuthRequestModel): Observable<IAuthResponseModel> =>
    new Observable(
      (observer: Observer<IAuthResponseModel>) => {
        return new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: `${this.url}/SignIn`,
        });
      }
    );

  public signOut = (): Observable<IAuthResponseModel> =>
    new Observable(
      (observer: Observer<IAuthResponseModel>) =>
        new AxiosSubscriber(observer, {
          data: DeviceHelper.deviceInfo(),
          method: HTTP_METHOD.POST,
          url: `${this.url}/SignOut`,
        })
    );

  public refreshToken = (
    refreshToken: string,
    device?: IUserDeviceModel
  ): Observable<IAuthResponseModel> =>
    new Observable(
      (observer: Observer<IAuthResponseModel>) =>
        new AxiosSubscriber(observer, {
          data: {
            Device: device ?? DeviceHelper.deviceInfo(),
            Token: refreshToken,
          },
          method: HTTP_METHOD.POST,
          url: `${this.url}/RefreshToken`,
        })
    );

  public changePassword = (
    data: IChangePasswordModel
  ): Observable<IAuthResponseModel> =>
    new Observable(
      (observer: Observer<IAuthResponseModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: `${this.url}/ChangePassword`,
        })
    );
}
