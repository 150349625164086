import { PlatformType } from "@bms/common-services";
import React from "react";
import { LayoutOptionsModel } from "../models";

export interface IDesignerContext {
  layoutOptions: LayoutOptionsModel;
  onLayoutOptionsChange?: (layoutOptions: LayoutOptionsModel) => void;
}

export const DesignerContext = React.createContext<IDesignerContext>({
  layoutOptions: {
    Platform: PlatformType.Web,
    Zoom: 0.5,
    RowHeight: 30,
    RowWidth: 1280,
    CurrentPositionY: 0,
    CurrentPositionX: 0,
  },
});
export const DesignerProvider = DesignerContext.Provider;
export const DesignerConsumer = DesignerContext.Consumer;

export function useDesignerContext() {
  return React.useContext<IDesignerContext>(DesignerContext);
}

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export interface WithScreenDesignerContextProps {
  layoutOptions: LayoutOptionsModel;
  onLayoutOptionsChange?: (layoutOptions: LayoutOptionsModel) => void;
}

export const withScreenDesignerContext = <
  P extends WithScreenDesignerContextProps
>(
  Component: React.ComponentType<P>
): React.FC<Omit<P, keyof WithScreenDesignerContextProps>> => (props) => (
  <DesignerConsumer>
    {({ layoutOptions, onLayoutOptionsChange }) => (
      <Component
        {...(props as P)}
        layoutOptions={layoutOptions}
        onLayoutOptionsChange={onLayoutOptionsChange}
      />
    )}
  </DesignerConsumer>
);
