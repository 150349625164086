import React from "react";
import { Form, Link } from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { IAssetFormFieldCommonProps } from "./IAssetFormFieldCommonProps";
import { ROUTES } from "../../../constants";

interface IChildFieldProps extends IAssetFormFieldCommonProps {
  childAssetId?: number;
  childAssetTitle?: string;
}

export const ChildField = ({
  isHidden = false,
  childAssetId,
  childAssetTitle,
}: IChildFieldProps) => {
  const { t } = useTranslation();
  if (isHidden || !childAssetId) {
    return null;
  }

  return (
    <Form.Item label={t("ASSET_CATCHUP")} key="Child">
      <Link to={`${ROUTES.ASSET_DETAILS}/${childAssetId}`}>
        {childAssetTitle}
      </Link>
    </Form.Item>
  );
};
