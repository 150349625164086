import {
  ApplicationConfigurationStore,
  CommonStore,
  IApplicationScreenModel,
  ICommonAppState,
} from "@bms/common-services";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { ApplicationScreenDetails as ApplicationScreenDetailsDefinition } from "./ApplicationScreenDetails";

const mapStateToProps = (state: ICommonAppState) => ({
  actionType: state.applicationConfiguration.actionType,
  platforms: state.common.platforms,
  screen: state.applicationConfiguration.screen,
  isProcessingData: state.applicationConfiguration.isProcessingData,
  isLoadingData: state.applicationConfiguration.isLoadingData,
  error: state.applicationConfiguration.error,
  configuration: state.applicationConfiguration.configuration,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getScreen: (id: number) => {
    return dispatch(ApplicationConfigurationStore.Actions.getScreen(id));
  },
  updateScreen: (screen: IApplicationScreenModel) => {
    return dispatch(ApplicationConfigurationStore.Actions.updateScreen(screen));
  },
  deleteScreen: (screen: IApplicationScreenModel) => {
    return dispatch(ApplicationConfigurationStore.Actions.deleteScreen(screen));
  },
  copyScreen: (screen: IApplicationScreenModel) => {
    return dispatch(
      ApplicationConfigurationStore.Actions.copyScreen({
        Name: screen.Name + " copy",
        SourceApplicationScreenId: screen.Id,
      })
    );
  },
  selectPlatforms: () => {
    return dispatch(CommonStore.Actions.selectPlatforms());
  },
  getConfiguration: (id: number) => {
    return dispatch(ApplicationConfigurationStore.Actions.getConfiguration(id));
  },
  publishConfiguration: (id: number, rowVersion: string) => {
    return dispatch(
      ApplicationConfigurationStore.Actions.publishConfiguration(id, rowVersion)
    );
  },
});

export const ApplicationScreenDetails = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ApplicationScreenDetailsDefinition));
