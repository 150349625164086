import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { Card, Skeleton, Rate } from "@bms/common-ui";
import { IAnalyticsUserPlatformRatingModel } from "@bms/common-services";

import "./RatingAppDataCard.scss";

export const COLORS: { [key: number]: string } = {
  5: "#77BB40",
  4: "#08A3D8",
  3: "#FFD800",
  2: "#FF6A00",
  1: "#FF0000",
};

export interface IRatingAppDataCardProps {
  data?: IAnalyticsUserPlatformRatingModel;
  isLoading?: boolean;
}

export const RatingAppDataCard = memo(
  ({ data, isLoading }: IRatingAppDataCardProps) => {
    const { t } = useTranslation();

    return (
      <Card className="RatingAppDataCard" title={t("RATING_APP_DATA")}>
        <Skeleton active paragraph loading={isLoading}>
          <div className="RatingAppDataCard__Row">
            {data?.Summaries.map((summary, index) => {
              return (
                <div
                  key={`${summary.PlatformCode}-${index}`}
                  className="RatingAppDataCard__Column"
                >
                  <span>{summary.PlatformName}</span>
                  {summary.Results?.map((result, index) => (
                    <p className="RatingAppDataCard__RateContainer">
                      <Rate
                        key={`${summary.PlatformCode}-${index}`}
                        disabled
                        defaultValue={result.Rating}
                        style={{ color: COLORS[result.Rating] }}
                      />
                      <span className="RateCounter">{result.Count}</span>
                    </p>
                  ))}
                </div>
              );
            })}
          </div>
        </Skeleton>
      </Card>
    );
  }
);
