export enum PropertyType {
  String = 0,
  Integer = 1,
  Numeric = 2,
  Boolean = 3,
  DateTimeOffset = 4,
  Guid = 5,
  Component = 6,
  ComponentReference = 7,
  Url = 8,
}
