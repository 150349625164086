/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  IUserPlatformRatingAnalyticsSummaryModel,
  UserPlatformRatingsService,
  useDataLoader,
} from "@bms/common-services";
import {
  CardGridItem,
  TileList,
  Spin,
  Row,
  Col,
  DonutChart,
  DonutChartPoint,
  Rate,
  Form,
  Choose,
  ChooseOption,
  Result,
} from "@bms/common-ui";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import "./RatingTile.scss";

const userPlatformRatingsService = new UserPlatformRatingsService().promisify();

export const COLORS: { [key: number]: string } = {
  5: "#77BB40",
  4: "#08A3D8",
  3: "#FFD800",
  2: "#FF6A00",
  1: "#FF0000",
};

export function RatingTile() {
  const { t } = useTranslation();
  const [platform, setPlatform] = useState<string | undefined>(undefined);

  const { data, loading, refresh } = useDataLoader({
    loader: () => userPlatformRatingsService.selectAnalyticsSummary(),
    onLoad: (values) => {
      if (values && values.length > 0) {
        setPlatform(values[0].PlatformCode);
      }
    },
    deps: [],
  });

  const [rating, reviews] = useMemo(() => {
    let result: DonutChartPoint[] = [];
    const appRating: IUserPlatformRatingAnalyticsSummaryModel = data?.find(
      (row) => row.PlatformCode === platform
    ) ?? {
      PlatformCode: "",
      PlatformName: "",
      Count: 0,
      Rating: 0,
      Results: [],
    };

    if (appRating?.Results) {
      result = appRating.Results.map((row) => ({
        angle: row.Count,
        label: `${row.Rating}`,
        color: COLORS[row.Rating],
        value: row.Rating,
      }));
    }

    return [appRating, result];
  }, [data, platform]);

  const renderContent = () => {
    if (loading) {
      return (
        <div className="RatingTile_SpinerContainer">
          <Spin />
        </div>
      );
    }

    if (!rating || !rating.PlatformCode) {
      return (
        <Result status="info" title={t("DASHBOARD_RATING_TILE_NO_DATA")} />
      );
    }

    return (
      <>
        <Row direction="row">
          <Col>
            <Form.Item
              style={{ marginBottom: 0, marginTop: 16 }}
              label={t("DASHBOARD_RATING_TILE_PLATFORM")}
            >
              <Choose
                placeholder={t("DASHBOARD_RATING_TILE_PLATFORM_PLACEHOLDER")}
                value={platform}
                loading={loading}
                disabled={loading}
                onChange={(value) => setPlatform(value)}
              >
                {data?.map((row) => (
                  <ChooseOption key={row.PlatformCode} value={row.PlatformCode}>
                    {row.PlatformName}
                  </ChooseOption>
                ))}
              </Choose>
            </Form.Item>
            <Form.Item
              style={{ marginBottom: 0 }}
              label={t("DASHBOARD_RATING_TILE_REVIEWS")}
            >
              <span>{rating?.Count}</span>
            </Form.Item>
          </Col>
        </Row>
        <Row direction="row" justify="center">
          <Col>
            <DonutChart height={250} width={250} data={reviews} />
          </Col>
          <Col>
            {reviews.map((review) => (
              <div
                key={`${platform}-${review.label}-container`}
                className="RatingTile_RateContainer"
              >
                <Rate
                  key={`${platform}-${review.label}`}
                  disabled
                  defaultValue={review.value}
                  style={{ color: review.color }}
                />
                <span className="RateCounter">{review.angle}</span>
              </div>
            ))}
          </Col>
        </Row>
      </>
    );
  };

  return (
    <CardGridItem>
      <TileList
        header={t("DASHBOARD_RATING_TILE_HEADER")}
        subheader={t("DASHBOARD_RATING_TILE_SUBHEADER")}
        headerValue={rating?.Rating}
        refresh={refresh}
      >
        {renderContent()}
      </TileList>
    </CardGridItem>
  );
}
