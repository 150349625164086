export enum AssetImageType {
  Cover = "COVER",
  Frame = "FRAME",
  Highlights = "HIGHLIGHTS",
  Square = "SQUARE",
  Background = "BACKGROUND",
  Round = "ROUND",
  HeroLandscape = "LANDSCAPE",
  Content = "CONTENT",
}
