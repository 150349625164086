import {
  ApplicationConfigurationStore,
  ICommonAppState,
} from "@bms/common-services";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { withScreenDesignerContext } from "../../context";
import {
  IPlayListComponentPreviewDispatchProps,
  IPlayListComponentPreviewOwnProps,
  IPlayListComponentPreviewStateProps,
  PlayListComponentPreview as PlayListComponentPreviewDefinition,
} from "./PlayListComponentPreview";

const mapStateToProps = (
  state: ICommonAppState
): IPlayListComponentPreviewStateProps => {
  return {
    sources: state.applicationConfiguration.sources,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch
): IPlayListComponentPreviewDispatchProps => ({
  getDataSource: (sourceId: number) => {
    return dispatch({ type: "nothing" });
  },
});

export const PlayListComponentPreview = connect<
  IPlayListComponentPreviewStateProps,
  IPlayListComponentPreviewDispatchProps,
  IPlayListComponentPreviewOwnProps,
  ICommonAppState
>(
  mapStateToProps,
  mapDispatchToProps
)(
  withScreenDesignerContext(
    withTranslation()(PlayListComponentPreviewDefinition)
  )
);
