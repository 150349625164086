import {
  IPlatformModel,
  PlatformType,
  ScreenTypeHelper,
} from "@bms/common-services";
import {
  Icon,
  Button,
  Choose,
  ChooseOption,
  IChooseValue,
} from "@bms/common-ui";
import cx from "classnames";
import React from "react";
import { WithTranslation } from "react-i18next";
import { Action, ActionCreator } from "redux";
import { WithScreenContextProps } from "../../../../context";
import { ApplicationScreenAddComponentModal } from "../../../../components";
import { WithScreenDesignerContextProps } from "../../context";
import "./DeviceFrame.scss";
import "./scss/devices.scss";

export interface IDeviceFrameProps
  extends WithTranslation,
    WithScreenDesignerContextProps,
    WithScreenContextProps {
  platforms: IPlatformModel[];
  selectPlatforms: ActionCreator<Action>;
  children?: React.ReactNode;
}

export interface IDeviceFrameState {
  addComponentModalVisible: boolean;
}

export class DeviceFrame extends React.Component<
  IDeviceFrameProps,
  IDeviceFrameState
> {
  public state: Readonly<IDeviceFrameState> = {
    addComponentModalVisible: false,
  };

  public static defaultProps: Partial<IDeviceFrameProps> = {};

  public componentDidMount() {
    const { platforms, selectPlatforms } = this.props;

    if (!platforms || platforms.length === 0) {
      selectPlatforms();
    }
  }

  public onPlatformChange = (value: IChooseValue) => {
    const { layoutOptions, onLayoutOptionsChange } = this.props;
    const platformCode = value ? (value as string) : "ANY";

    const newLayoutOptions = { ...layoutOptions };
    newLayoutOptions.Platform =
      (platformCode as PlatformType) || PlatformType.Any;

    if (onLayoutOptionsChange) {
      onLayoutOptionsChange(newLayoutOptions);
    }
  };

  public renderPlatformChooser = () => {
    const { platforms, layoutOptions, screen, t } = this.props;
    const options: React.ReactElement[] = [];
    let platformsData: IPlatformModel[] = [...platforms];
    const screenPlatforms =
      screen && screen.Platforms ? [...(screen && screen.Platforms)] : [];

    if (screenPlatforms.length > 0) {
      const hasOwnPlatforms =
        screenPlatforms.findIndex(
          (screenPlatform) => screenPlatform.PlatformCode !== PlatformType.Any
        ) >= 0;

      if (hasOwnPlatforms) {
        platformsData = platforms.filter((platform) => {
          return (
            screenPlatforms.findIndex(
              (screenPlatform) => screenPlatform.PlatformCode === platform.Code
            ) >= 0
          );
        });
      }
    }

    for (const platform of platformsData) {
      options.push(
        <ChooseOption value={platform.Code}>
          {platform.DisplayName}
        </ChooseOption>
      );
    }

    return (
      <React.Fragment>
        <label style={{ paddingRight: "16px" }}>{t("MODEL_PLATFORM")}:</label>
        <Choose
          showSearch
          defaultValue={layoutOptions.Platform}
          style={{ width: 180, marginRight: "16px" }}
          placeholder={t("USER_ACCOUNT_FORM_PROFILE_PLACEHOLDER")}
          optionFilterProp="children"
          onChange={this.onPlatformChange}
          children={options}
        />
      </React.Fragment>
    );
  };

  public onZoomChange = (value: IChooseValue) => {
    const { layoutOptions, onLayoutOptionsChange } = this.props;
    const newLayoutOptions = { ...layoutOptions };

    newLayoutOptions.Zoom = value ? (value as number) : 1;

    if (onLayoutOptionsChange) {
      onLayoutOptionsChange(newLayoutOptions);
    }
  };

  public renderZoomChooser = () => {
    const { layoutOptions } = this.props;

    const options: React.ReactElement[] = [];
    options.push(<ChooseOption value={0.5}>50%</ChooseOption>);
    options.push(<ChooseOption value={0.75}>75%</ChooseOption>);
    options.push(<ChooseOption value={1}>100%</ChooseOption>);

    return (
      <React.Fragment>
        <label style={{ paddingRight: "16px" }}>Zoom:</label>
        <Choose
          value={layoutOptions.Zoom}
          style={{ width: 100, marginRight: "16px" }}
          optionFilterProp="children"
          onChange={this.onZoomChange}
          children={options}
        />
      </React.Fragment>
    );
  };

  public onAddComponentClick = () => {
    this.setState({ addComponentModalVisible: true });
  };

  public renderAddComponentAction = () => {
    const { t, screen } = this.props;

    if (!ScreenTypeHelper.configurable(screen?.ScreenTypeCode)) {
      return null;
    }

    return (
      <Button
        icon={<Icon type="plus" />}
        onClick={this.onAddComponentClick}
        title={t("SCREEN_COMPONENTS_BUTTON__ADD_COMPONENT")}
      />
    );
  };

  public onAddComponentCancel = () => {
    this.setState({ addComponentModalVisible: false });
  };

  public onAddComponentSuccess = () => {
    this.setState({ addComponentModalVisible: false });
  };

  public renderAddComponentModal = () => {
    const { screen } = this.props;
    const { addComponentModalVisible } = this.state;

    if (!screen) {
      return null;
    }

    return (
      <ApplicationScreenAddComponentModal
        screenType={screen.ScreenTypeCode}
        visible={addComponentModalVisible}
        onCancel={this.onAddComponentCancel}
        onSuccess={this.onAddComponentSuccess}
      />
    );
  };

  public render() {
    const { children, layoutOptions } = this.props;

    let deviceClassName: string;

    switch (layoutOptions.Platform) {
      case PlatformType.iOSPhone:
        deviceClassName = "device-iphone-8 device-spacegray";
        break;
      case PlatformType.AndroidPhone:
        deviceClassName = "device-galaxy-s8 device-blue";
        break;
      case PlatformType.AndroidTablet:
      case PlatformType.iPad:
        deviceClassName = "device-ipad-pro device-spacegray";
        break;
      case PlatformType.AndroidTV:
      case PlatformType.AppleTV:
      case PlatformType.Tizen:
      case PlatformType.WebOS:
      case PlatformType.Roku:
        deviceClassName = "device-surface-studio";
        break;
      default:
        deviceClassName = "device-macbook-pro";
        break;
    }

    switch (layoutOptions.Zoom) {
      case 0.5:
        deviceClassName += " zoom-50";
        break;
      case 0.75:
        deviceClassName += " zoom-75";
        break;
      default:
        deviceClassName += " zoom-100";
        break;
    }

    return (
      <React.Fragment>
        <div className="DeviceFrame_options">
          {this.renderPlatformChooser()}
          {this.renderZoomChooser()}
          {this.renderAddComponentAction()}
        </div>
        <div className={cx("device", deviceClassName)}>
          <div className="device-frame">
            <div
              className="device-content"
              style={{ backgroundColor: "#1c1c1c" }}
            >
              {children}
            </div>
          </div>
          <div className="device-stripe"></div>
          <div className="device-header"></div>
          <div className="device-sensors"></div>
          <div className="device-btns"></div>
          <div className="device-power"></div>
        </div>
        {this.renderAddComponentModal()}
      </React.Fragment>
    );
  }
}
