import React, { memo, useEffect, useMemo, useState } from "react";
import {
  Card,
  IRadioProps,
  RadioGroup,
  Spin,
  StackedBarChart2,
  StackedBarChart2Control,
  Switch,
  Text,
  TStackedBarChart2Options,
} from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import {
  AnalyticsAggregationPeriodType,
  IAnalyticsAssetImpressionsAssetModel,
  IAnalyticsAssetImpressionsFilterModel,
} from "@bms/common-services";
import { prepareChartData, TGroupBy, TUnit } from "./utils";

import "./AssetImpressionsChart.scss";
import { IAnalyticsAssetImpressionsAssetWithColorsModel } from "../AssetImpressions";

interface IAssetImpressionsChartProps {
  data?: IAnalyticsAssetImpressionsAssetModel[];
  isLoading?: boolean;
  impressionsListWithColors?:
    | IAnalyticsAssetImpressionsAssetWithColorsModel[]
    | undefined;
  filter: IAnalyticsAssetImpressionsFilterModel;
  aggregationPeriod?: AnalyticsAggregationPeriodType;
}

export const AssetImpressionsChart = memo(
  ({
    data: assetImpressionsData = [],
    impressionsListWithColors,
    isLoading = false,
    filter,
    aggregationPeriod = AnalyticsAggregationPeriodType.YEAR,
  }: IAssetImpressionsChartProps) => {
    const [groupBy, setGroupBy] = useState<TGroupBy>("asset");
    const [unit, setUnit] = useState<TUnit>("count");
    const [axisReversed, setAxisReversed] = useState(true);
    const { t } = useTranslation();
    const chartOptions: TStackedBarChart2Options = useMemo(
      () =>
        aggregationPeriod === AnalyticsAggregationPeriodType.YEAR
          ? {
              indexAxis: axisReversed ? "y" : "x",
              scales: {
                x: {
                  stacked: false,
                },
                y: {
                  stacked: false,
                  ticks: {
                    min: 0,
                    stepSize: axisReversed ? 1 : unit === "count" ? 1 : 1000,
                  },
                },
              },
            }
          : {
              indexAxis: axisReversed ? "y" : "x",
              scales: {
                x: { stacked: true },
                y: {
                  stacked: true,
                  ticks: {
                    min: 0,
                    precision: 0,
                    count: unit === "duration" ? 15 : undefined,
                  },
                },
              },
            },
      [aggregationPeriod, axisReversed, unit]
    );
    const groupByOptions: IRadioProps[] = useMemo(() => {
      const options = [
        {
          value: "asset",
          name: t("ASSET_IMPRESSIONS_CHART_GROUP_BY_ASSET"),
        },
        {
          value: "platform",
          name: t("ASSET_IMPRESSIONS_CHART_GROUP_BY_PLATFORM"),
        },
      ];

      if (filter?.Users && filter?.Users.length > 0) {
        options.push({
          value: "user",
          name: t("ASSET_IMPRESSIONS_CHART_GROUP_BY_USER"),
        });
      }

      return options;
    }, [filter]);

    useEffect(() => {
      if (Object.keys(filter).length === 0) {
        setUnit("count");
        setGroupBy("asset");
        setAxisReversed(true);
      }

      if (groupBy === "user" && (!filter.Users || filter.Users.length === 0)) {
        setGroupBy("asset");
      }
    }, [filter]);
    const data = useMemo(
      () =>
        prepareChartData(
          assetImpressionsData,
          aggregationPeriod,
          groupBy,
          unit,
          impressionsListWithColors
        ),
      [assetImpressionsData, aggregationPeriod, groupBy, unit]
    );

    return (
      <Card className="AssetImpressionsChart">
        {isLoading && (
          <Spin size="large" className="AssetImpressionsChart__spin" />
        )}
        {!isLoading &&
          (!data ||
          data.datasets.length < 1 ||
          data.datasets[0].data.length < 1 ? (
            <Text className="AssetImpressionsChart__no-data-text">
              {t("ASSET_IMPRESSIONS_NO_CHART_DATA")}
            </Text>
          ) : (
            <StackedBarChart2
              title={t("ASSET_IMPRESSIONS_CHART_TITLE")}
              data={data}
              options={chartOptions}
              showToolbar
              customToolbarControls={[
                <StackedBarChart2Control
                  key="asset-impressions-chart--subject-control"
                  label={t("ASSET_IMPRESSIONS_CHART_SUBJECT")}
                >
                  <RadioGroup
                    data={[
                      {
                        value: "count",
                        name: t("ASSET_IMPRESSIONS_CHART_SUBJECT_COUNT"),
                      },
                      {
                        value: "duration",
                        name: t("ASSET_IMPRESSIONS_CHART_SUBJECT_DURATION"),
                      },
                    ]}
                    value={unit}
                    onChange={(e) => {
                      e.preventDefault();
                      setUnit(e.target.value);
                    }}
                  />
                </StackedBarChart2Control>,
                <StackedBarChart2Control
                  key="asset-impressions-chart--reverse-axis-control"
                  label={t("ASSET_IMPRESSIONS_CHART_REVERSE_AXIS")}
                >
                  <Switch
                    checked={axisReversed}
                    onChange={(val) => setAxisReversed(val)}
                  />
                </StackedBarChart2Control>,
                <StackedBarChart2Control
                  key="asset-impressions-chart--group-by-control"
                  label={t("ASSET_IMPRESSIONS_CHART_GROUP_BY")}
                >
                  <RadioGroup
                    data={groupByOptions}
                    value={groupBy}
                    onChange={(e) => {
                      e.preventDefault();
                      setGroupBy(e.target.value);
                    }}
                  />
                </StackedBarChart2Control>,
              ]}
            />
          ))}
      </Card>
    );
  }
);
