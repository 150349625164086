import React from "react";
import { Form, Switch } from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { IAssetFormFieldCommonProps } from "./IAssetFormFieldCommonProps";

interface IIsFreeFieldProps extends IAssetFormFieldCommonProps {
  formLayout?: any;
}
export const IsFreeField = ({
  isHidden,
  asset,
  formLayout = {},
}: IIsFreeFieldProps) => {
  const { t } = useTranslation();
  if (isHidden) {
    return null;
  }

  return (
    <Form.Item key="IsFree" wrapperCol={{ xs: 24 }} dependencies={["IsFree"]}>
      {({ getFieldValue }) => {
        const isFree = getFieldValue("IsFree");
        return (
          <>
            <Form.Item
              name="IsFree"
              key="IsFree"
              label={t("MODEL_IS_FREE")}
              valuePropName="checked"
              initialValue={asset?.IsFree ?? false}
              {...formLayout}
            >
              <Switch />
            </Form.Item>
          </>
        );
      }}
    </Form.Item>
  );
};
