import { ApplicationResourceType } from "@bms/common-services";
import {
  Button,
  Col,
  Form,
  Icon,
  IFormInstance,
  IFormValues,
  Input,
  Row,
} from "@bms/common-ui";
import React from "react";
import { WithTranslation } from "react-i18next";
import { ApplicationIntroPropertyModel } from "../../models";
import { ApplicationResourceUploadModal } from "../ApplicationResourceUploadModal";
import {
  ComponentPropertiesBase,
  IComponentPropertiesBaseProps,
  IComponentPropertiesBaseState,
} from "../ComponentPropertiesBase";
import { VideoPreview } from "../VideoPreview";

import "./ApplicationIntroProperties.scss";

export interface ApplicationIntroPropertiesStateProps {
  isProcessingData: boolean;
}

export interface ApplicationIntroPropertiesDispatchProps {}

export interface ApplicationIntroPropertiesOwnProps {}

export interface ApplicationIntroPropertiesProps
  extends ApplicationIntroPropertiesStateProps,
    ApplicationIntroPropertiesDispatchProps,
    ApplicationIntroPropertiesOwnProps,
    IComponentPropertiesBaseProps,
    WithTranslation {}

export interface ApplicationIntroPropertiesState
  extends IComponentPropertiesBaseState {
  applicationResourceUploadModalVisible: boolean;
  backgroundVideoPreviewModalVisible: boolean;
}

export class ApplicationIntroProperties extends ComponentPropertiesBase<
  ApplicationIntroPropertiesProps,
  ApplicationIntroPropertiesState,
  ApplicationIntroPropertyModel
> {
  public static defaultProps = {
    isProcessingData: false,
  };

  formRef = React.createRef<IFormInstance>();

  public state: Readonly<ApplicationIntroPropertiesState> = {
    applicationResourceUploadModalVisible: false,
    backgroundVideoPreviewModalVisible: false,
  };

  getPropertiesModel(): ApplicationIntroPropertyModel {
    const { component } = this.props;
    const properties = new ApplicationIntroPropertyModel();
    properties.init(component);

    return properties;
  }

  public onFinish = (values: IFormValues) => {
    const { component } = this.props;

    if (!component) {
      return;
    }

    component.Name = values.Name;
  };

  public onApplicationResourceUploadCancel = () => {
    this.setState({ applicationResourceUploadModalVisible: false });
  };

  public onApplicationResourceUploadSuccess = (
    filePath?: string,
    fileUrl?: string
  ) => {
    this.properties.BackgroundVideoUrl = filePath;
    this.properties.BackgroundVideoAbsoluteUrl = fileUrl;
    this.onPropertyChange("BackgroundVideoUrl");
    this.formRef?.current?.setFieldsValue({
      BackgroundVideoUrl: filePath,
    });
    this.setState({ applicationResourceUploadModalVisible: false });
  };

  public onShowBackgroundVideoPreviewModal = () => {
    const videoUrl =
      this.properties.BackgroundVideoAbsoluteUrl ??
      this.properties.BackgroundVideoUrl;
    if (!videoUrl) {
      return;
    }

    this.setState({ backgroundVideoPreviewModalVisible: true });
  };

  public onCloseBackgroundVideoPreviewModal = () => {
    this.setState({ backgroundVideoPreviewModalVisible: false });
  };

  public renderBackgroundVideoUrlProperty = () => {
    const { component, isProcessingData, t } = this.props;
    const {
      applicationResourceUploadModalVisible,
      backgroundVideoPreviewModalVisible,
    } = this.state;

    return (
      <Row gutter={8}>
        <Col flex="auto">
          <Form.Item
            name="BackgroundVideoUrl"
            label={t("CONFIGURATION_PROPERTY__BACKGROUND_VIDEO_URL")}
            initialValue={this.properties.BackgroundVideoUrl}
          >
            <Input
              disabled={isProcessingData}
              placeholder={"https://"}
              onChange={(e) => {
                this.properties.BackgroundVideoUrl = e.target.value;
                this.onPropertyChange("BackgroundVideoUrl");
              }}
            />
          </Form.Item>
        </Col>
        <Col style={{ textAlign: "right" }}>
          <ApplicationResourceUploadModal
            key={`ApplicationResourceUploadModal-${component.Id}`}
            visible={applicationResourceUploadModalVisible}
            applicationConfigurationId={component.ApplicationConfigurationId}
            resourceType={ApplicationResourceType.Video}
            titleTransKey="CONFIGURATION_BUTTON__UPLOAD_VIDEO_TITLE"
            onCancel={this.onApplicationResourceUploadCancel}
            onSuccess={this.onApplicationResourceUploadSuccess}
          />

          {(this.properties.BackgroundVideoUrl ||
            this.properties.BackgroundVideoAbsoluteUrl) && (
            <>
              <VideoPreview
                visible={backgroundVideoPreviewModalVisible}
                contentUrl={this.properties.BackgroundVideoAbsoluteUrl}
                onCancel={this.onCloseBackgroundVideoPreviewModal}
              />
              <Button
                title={t("CONFIGURATION_BUTTON__PREVIEW_VIDEO_TITLE")}
                style={{ marginRight: "8px" }}
                icon={<Icon type="PlayCircle" style={{ fontSize: "20px" }} />}
                onClick={() => this.onShowBackgroundVideoPreviewModal()}
              />
            </>
          )}
          <Button
            icon={<Icon type="upload" />}
            title={t("CONFIGURATION_BUTTON__UPLOAD_VIDEO_TITLE")}
            onClick={() => {
              this.setState({ applicationResourceUploadModalVisible: true });
            }}
          />
        </Col>
      </Row>
    );
  };

  public render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 8 },
        lg: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 16 },
        lg: { span: 20 },
      },
    };

    return (
      <Form
        name="ApplicationIntroProperties"
        ref={this.formRef}
        {...formItemLayout}
        onFinish={this.onFinish}
      >
        <Row direction="column" justify="space-between" className="full-height">
          <Col>{this.renderBackgroundVideoUrlProperty()}</Col>
        </Row>
      </Form>
    );
  }
}
