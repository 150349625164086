import { Menu as AntMenu } from "antd";
import { MenuInfo } from "rc-menu/lib/interface";
import { MenuItemProps as AntMenuItemProps } from "antd/lib/menu/MenuItem";
import React from "react";

export interface MenuItemClickEvent extends MenuInfo {}

export interface MenuItemProps extends AntMenuItemProps {
  onClick?: (param: MenuItemClickEvent) => void;
}

export class MenuItem extends React.PureComponent<MenuItemProps> {
  public render() {
    const { children, ...rest } = this.props;
    return <AntMenu.Item {...rest}>{children}</AntMenu.Item>;
  }
}
