/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { useSelector } from "react-redux";
import { ICommonAppState } from "../../store";
import { IConfigState } from "../../store/config/types";

export const useConfig = (): IConfigState => {
  const configState = useSelector((state: ICommonAppState) => state.config);

  return configState;
};
