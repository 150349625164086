import React from "react";
import { Typography as AntTypography } from "antd";
import { TextProps } from "antd/lib/typography/Text";
import { BaseType as AntBaseTypographyType } from "antd/lib/typography/Base";
import cx from "classnames";
import "./Text.scss";
import "./Typography.scss";

export type TextType = AntBaseTypographyType | "contrast";
export type TextSize = "small";

export interface ITextProps extends Omit<TextProps, "type"> {
  type?: TextType;
  size?: TextSize;
}

export const Text: React.FC<ITextProps> = (props) => {
  const { children, className, type, size, ...rest } = props;
  const _className = cx(className, "gm-text", {
    [`gm-text-${size}`]: size,
  });

  return (
    <AntTypography.Text
      className={_className}
      type={type as AntBaseTypographyType}
      {...rest}
    >
      {children}
    </AntTypography.Text>
  );
};
