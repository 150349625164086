import { Observable, Observer } from "rxjs";
import { AxiosSubscriber } from "../AxiosSubscriber";
import { HTTP_METHOD } from "../../constants";
import { PromisifiableBase } from "../Base";
import {
  IProductSearchFilterModel,
  IProductListModel,
  IProductModel,
} from "../..";

export class ProductsService extends PromisifiableBase {
  get url(): string {
    return "/Products";
  }

  public get = (id: number): Observable<IProductModel> =>
    new Observable(
      (observer: Observer<IProductModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: {
              id,
            },
          },
          method: HTTP_METHOD.GET,
          url: `${this.url}/Get`,
        })
    );

  public search = (
    filter: IProductSearchFilterModel
  ): Observable<IProductListModel> =>
    new Observable(
      (observer: Observer<IProductListModel>) =>
        new AxiosSubscriber(observer, {
          data: filter,
          method: HTTP_METHOD.POST,
          url: `${this.url}/Search`,
        })
    );
}
