import React from "react";
import { Tag } from "../Tag";
import { Domain, DOMAIN_PALETTE_MAP } from "./domainPaletteMap";

interface IDomainTagProps {
  domain: Domain;
  value?: string;
  noMargin?: boolean;
  colorRotate?: string | number;
}

export const DomainTag: React.FC<IDomainTagProps> = ({
  domain,
  value,
  colorRotate,
  noMargin,
}) => {
  if (!value) {
    return null;
  }
  const domainPalette = DOMAIN_PALETTE_MAP[domain];
  const tagColor = Array.isArray(domainPalette)
    ? undefined
    : domainPalette[colorRotate ?? value];
  const tagPalette = Array.isArray(domainPalette) ? domainPalette : undefined;
  const tagColorRotate = tagPalette && (colorRotate ?? value);
  return (
    <Tag
      noMargin={noMargin}
      colorRotate={tagColorRotate}
      palette={tagPalette}
      color={tagColor}
    >
      {value}
    </Tag>
  );
};
