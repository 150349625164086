import { Observable, Observer } from "rxjs";
import { HTTP_METHOD } from "../../constants/http";
import { AxiosSubscriber } from "../AxiosSubscriber";
import {
  IApplicationConfigurationBrandingModel,
  IApplicationConfigurationBrandingListModel,
  IApplicationsScreensSearchFilterModel,
} from "../../models";
import { SimpleServiceBase } from "../Base";

export class ApplicationBrandingsService extends SimpleServiceBase<
  IApplicationConfigurationBrandingModel,
  IApplicationConfigurationBrandingListModel,
  number,
  IApplicationsScreensSearchFilterModel
> {
  get url(): string {
    return "/ApplicationsConfigurationsBrandings";
  }

  public selectKeys = (applicationConfigurationId: number): Observable<any> =>
    new Observable(
      (observer: Observer<any>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: { applicationConfigurationId },
          },
          method: HTTP_METHOD.GET,
          url: `${this.url}/SelectKeys`,
        })
    );
}
