/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { Layout, Content } from "@bms/common-ui";
import React from "react";
import { Route, Redirect, RouteProps } from "react-router";
import { ROUTES } from "../../constants/routes";
import { Navbar, Sidebar } from "../../components";

interface IAuthenticatedRouteProps extends RouteProps {
  isAuthenticated: boolean;
}

export class AuthenticatedRoute extends React.Component<
  IAuthenticatedRouteProps
> {
  public render() {
    const { isAuthenticated, component: Component, ...rest } = this.props;

    return (
      <Route
        {...rest}
        render={(props) => {
          if (!isAuthenticated) {
            const redirectTo = {
              pathname: `${ROUTES.LOGIN}`,
              state: { from: props.location },
            };

            return <Redirect to={redirectTo} />;
          }

          return (
            <Layout className="main">
              <Navbar />
              <Layout className="sidebar-and-content">
                <Sidebar />
                <Content className="content">
                  {Component ? <Component {...props} /> : null}
                </Content>
              </Layout>
            </Layout>
          );
        }}
      />
    );
  }
}
