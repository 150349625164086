import {
  AdvertisementStore,
  IAdvertisementBlockModel,
  ICommonAppState,
  IStateModel,
} from "@bms/common-services";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
  AdvertisementBlockDetails as AdvertisementBlockDetailsDefinition
} from "./AdvertisementBlockDetails";

const mapStateToProps = (state: ICommonAppState) => ({
  actionType: state.advertisement.actionType,
  advertisementBlock: state.advertisement.advertisementBlock,
  error: state.advertisement.error,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getAdvertisementBlock: (id: number) => {
    return dispatch(AdvertisementStore.Actions.getAdvertisementBlock(id));
  },
  updateAdvertisementBlockWithDetails: (data: IStateModel<IAdvertisementBlockModel>) => {
    return dispatch(AdvertisementStore.Actions.updateAdvertisementBlockWithDetails(data));
  },
  deleteAdvertisementBlocks: (data: IAdvertisementBlockModel) => {
    return dispatch(AdvertisementStore.Actions.deleteAdvertisementBlock(data));
  },
  publishAdvertisementBlock: (data: IAdvertisementBlockModel, html: string) => {
    return dispatch(AdvertisementStore.Actions.publishAdvertisementBlock(data, html));
  },
  archiveAdvertisementBlock: (data: IAdvertisementBlockModel) => {
    return dispatch(AdvertisementStore.Actions.archiveAdvertisementBlock(data));
  },
  restoreAdvertisementBlock: (data: IAdvertisementBlockModel) => {
    return dispatch(AdvertisementStore.Actions.restoreAdvertisementBlock(data));
  },
});

export const AdvertisementBlockDetails = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(AdvertisementBlockDetailsDefinition));
