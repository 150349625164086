import React from "react";
import { range } from "lodash";
import "./AnimatedBackground.scss";

export function AnimatedBackground() {
  return <div className="background">
    {range(20).map((i) => (
      <span key={i} />
    ))}
  </div>
}
