import React, { memo, useMemo } from "react";
import {
  useDataLoader,
  PlatformService,
  IAnalyticsAssetImpressionsFilterModel,
} from "@bms/common-services";
import { useTranslation } from "react-i18next";
import { Choose, ChooseOption, useAppFeedback } from "@bms/common-ui";

interface IAssetImpressionsSelectProps {
  filter: IAnalyticsAssetImpressionsFilterModel;
  onChange?: (newValue: number[]) => void;
}

const platformService = new PlatformService().promisify();

export const AssetImpressionsPlatformSelect = memo(
  ({ filter, onChange }: IAssetImpressionsSelectProps) => {
    const { t } = useTranslation();
    const { notification } = useAppFeedback();

    const platforms = useDataLoader({
      loader: () => platformService.select(),
      onError: (error) =>
        notification.error({
          message: error.Title,
          description: error.Message,
        }),
      deps: [],
    });

    const value: any = useMemo(
      () =>
        platforms.data
          ?.filter((it) => filter.Platforms?.find((cu) => cu === it.Code))
          .map((it) => ({
            key: it.Code,
            value: it.Code,
            label: it.DisplayName,
          })) || [],
      [filter.Platforms, platforms]
    );
    const onValueChange = (newValue: any) => {
      if (onChange) {
        onChange(newValue.map((it: any) => it.value));
      }
    };

    return (
      <Choose
        mode="multiple"
        labelInValue={true}
        getPopupContainer={(trigger) => trigger.parentNode}
        defaultActiveFirstOption={false}
        filterOption={(inputValue, option: any) =>
          option?.children?.toLowerCase()?.includes(inputValue)
        }
        value={value}
        style={{ width: "100%" }}
        placeholder={t("ASSET_IMPRESSIONS_PLATFORM_PLACEHOLDER")}
        onChange={onValueChange}
      >
        {platforms.data
          ?.filter((it) => it.Code !== "ANY")
          .map((it) => (
            <ChooseOption key={`choose-platform--${it.Code}`} value={it.Code}>
              {it.DisplayName}
            </ChooseOption>
          ))}
      </Choose>
    );
  }
);
