import {
  AnalyticsAggregationPeriodType,
  IAnalyticsAssetImpressionsAssetModel,
} from "@bms/common-services";
import { IStackedBarChart2DataSet } from "@bms/common-ui";
import { IAnalyticsAssetImpressionsAssetWithColorsModel } from "../AssetImpressions";

export type TGroupBy = "asset" | "user" | "platform";
export type TUnit = "count" | "duration";

export interface IAssetImpressionsGrupedData {
  [key: string]: IAnalyticsAssetImpressionsAssetModel[];
}

export const groupDataBy = (
  data: IAnalyticsAssetImpressionsAssetModel[] = [],
  groupBy: keyof IAnalyticsAssetImpressionsAssetModel
) => {
  const result: IAssetImpressionsGrupedData = {};
  data.forEach((it) => {
    if (!result[it[groupBy]]) {
      result[it[groupBy]] = [];
    }
    result[it[groupBy]].push(it);
  });
  return result;
};

export const getLabels = (data: IAnalyticsAssetImpressionsAssetModel[] = []) =>
  Array.from(new Set(data.map((it) => it.Period)));

export const convertLabelsByAggregationPeriodType = (
  labels: string[],
  type: AnalyticsAggregationPeriodType = AnalyticsAggregationPeriodType.YEAR
) =>
  labels.map((it) => {
    const [year, month, day] = it.split("T")[0].split("-");
    switch (type) {
      case AnalyticsAggregationPeriodType.DAY:
        return `${day}-${month}-${year}`;
      case AnalyticsAggregationPeriodType.MONTH:
        return `${month}-${year}`;
      case AnalyticsAggregationPeriodType.YEAR:
        return `${year}`;
      default:
        return "";
    }
  });

export const colorOfImpression = (
  impression: IAnalyticsAssetImpressionsAssetModel,
  impressionsListWithColors:
    | IAnalyticsAssetImpressionsAssetWithColorsModel[]
    | undefined,
  groupBy: TGroupBy
) => {
  return impressionsListWithColors?.find((obj) => {
    return groupBy === "asset"
      ? obj.AssetId === impression?.AssetId
      : groupBy === "user"
      ? obj.UserId === impression?.UserId
      : obj.PlatformName === impression?.PlatformName;
  });
};

export const prepareChartData = (
  data: IAnalyticsAssetImpressionsAssetModel[] = [],
  aggregationPeriod: AnalyticsAggregationPeriodType = AnalyticsAggregationPeriodType.YEAR,
  groupBy: TGroupBy = "asset",
  unit: TUnit = "count",
  impressionsListWithColors:
    | IAnalyticsAssetImpressionsAssetWithColorsModel[]
    | undefined
) => {
  const labels = getLabels(data);
  const groupedBy = groupDataBy(
    data,
    groupBy === "asset"
      ? "AssetId"
      : groupBy === "user"
      ? "UserId"
      : "PlatformName"
  );
  const datasets: IStackedBarChart2DataSet<number>[] = [];
  Object.keys(groupedBy).forEach((key) => {
    const dataset: IStackedBarChart2DataSet<number> = {
      data: [],
      backgroundColor: undefined,
    };
    labels.forEach((period, index) => {
      const impression = groupedBy[key].find((val) => val.Period === period);

      if (impression) {
        const impressionWithColor = colorOfImpression(
          impression,
          impressionsListWithColors,
          groupBy
        );
        if (!dataset.label) {
          dataset.label =
            groupBy === "asset"
              ? impression.AssetTitle
              : groupBy === "user"
              ? impression.UserFullName
              : impression.PlatformName;
        }
        if (unit === "count") {
          dataset.data[index] = groupedBy[key]
            .filter((row) => row.Period === period)
            .reduce((a, b) => (a = a + b.Count), 0);
        } else {
          dataset.data[index] = groupedBy[key]
            .filter((row) => row.Period === period)
            .reduce((a, b) => (a = a + b.Duration), 0);
        }
        dataset.backgroundColor = impressionWithColor?.Color;
      } else {
        dataset.data[index] = null;
      }
    });
    datasets.push(dataset);
  });

  return {
    labels: convertLabelsByAggregationPeriodType(labels, aggregationPeriod),
    datasets,
  };
};
