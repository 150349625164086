import * as React from "react";
import { Icon } from "../Icon";

interface PreviewImageProps {
  src?: string;
  alt?: string;
  width?: string | number;
  height?: string | number;
  placeholderIconType?: string;
}

export const PreviewImage = (props: PreviewImageProps) => {
  const { src, alt, width, height, placeholderIconType } = props;

  return (
    <>
      {src ? (
        <picture>
          <img src={src} alt={alt || "preview"} width={width} height={height} />
        </picture>
      ) : (
        <Icon type={placeholderIconType || "inbox"} />
      )}
    </>
  );
};
