import { Observable, Observer } from "rxjs";
import { HTTP_METHOD } from "../../../constants/http";
import { AxiosSubscriber } from "../../../services/AxiosSubscriber";
import { IConfigurationModel } from "../models";

export class ConfigurationService {
  get url(): string {
    return "/Configurations";
  }

  public getConfiguration = (): Observable<IConfigurationModel> =>
    new Observable(
      (observer: Observer<IConfigurationModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            timeout: 60000,
            headers: {
              Authorization: "",
            },
          },
          method: HTTP_METHOD.GET,
          url: `${this.url}/GetConfiguration`,
        })
    );
}
