import {
  IApplicationComponentLayoutModel,
  IApplicationComponentModel,
  IApplicationComponentPropertyModel,
  PropertyType,
  RecordStatus,
} from "@bms/common-services";
import { ComponentLayoutPropertyModel } from "./ComponentLayoutPropertyModel";
import { ComponentPropertyModel } from "./ComponentPropertyModel";
import { LayoutOptionsModel } from "./LayoutOptionsModel";

export class TopFriendsWidgetComponentPropertyModel extends ComponentPropertyModel {
  SourceId?: number;

  constructor(component?: IApplicationComponentModel) {
    super();

    if (component) {
      this.init(component);
    }
  }

  public getLayoutProperty(
    component: IApplicationComponentModel,
    layoutOptions: LayoutOptionsModel
  ): ComponentLayoutPropertyModel {
    if (!component.Layouts) {
      component.Layouts = [];
    }

    let componentLayout = component.Layouts.find(
      (row: IApplicationComponentLayoutModel) =>
        row.PlatformCode === layoutOptions.Platform
    );

    if (!componentLayout) {
      componentLayout = {
        RecordStatus: RecordStatus.Inserted,
        ApplicationComponentId: component.Id,
        PlatformCode: layoutOptions.Platform,
        PositionX: 0,
        PositionY: layoutOptions.CurrentPositionY,
        Width: 12,
        Height: 4,
      };

      component.Layouts.push(componentLayout);
    }

    return {
      PositionX: componentLayout.PositionX,
      PositionY: componentLayout.PositionY,
      Width: componentLayout.Width,
      Height: componentLayout.Height,
      IsResizable: true,
      IsDraggable: true,
    };
  }

  initProperties(component: IApplicationComponentModel): void {
    if (!component.Properties) {
      component.Properties = [];
    }
  }

  setProperties(component: IApplicationComponentModel): void {
    let sourceIdProperty = component.Properties?.find(
      (row: IApplicationComponentPropertyModel) => row.Name === "SourceId"
    );

    if (sourceIdProperty && sourceIdProperty.Value) {
      this.SourceId = sourceIdProperty.Value.IntegerValue;
    }
  }

  setProperty(property: IApplicationComponentPropertyModel) {
    switch (property.Name) {
      case "SourceId":
        property.PropertyType = PropertyType.Integer;
        property.IsEnumerable = false;
        break;
    }
  }

  setPropertyValue(property: IApplicationComponentPropertyModel) {
    switch (property.Name) {
      case "SourceId":
        if (property.Value) {
          property.Value.IntegerValue = this.SourceId;
        }
        break;
    }
  }
}
