import { SimpleServiceBase } from "../Base";
import {
  ITestExecutionModel,
  ITestExecutionsFilterModel,
  ITestExecutionsListModel,
} from "../../models";
import { Observable, Observer } from "rxjs";
import { AxiosSubscriber } from "../AxiosSubscriber";
import { HTTP_METHOD } from "../../constants";

export class TestExecutionService extends SimpleServiceBase<
  ITestExecutionModel,
  ITestExecutionsListModel,
  number,
  ITestExecutionsFilterModel
> {
  get url() {
    return "/TestExecutions";
  }

  public insertCollection = (
    data: ITestExecutionModel[]
  ): Observable<ITestExecutionModel[]> =>
    new Observable(
      (observer: Observer<ITestExecutionModel[]>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: `${this.url}/InsertCollection`,
        })
    );
}
