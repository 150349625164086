import React from "react";

import { WithTranslation } from "react-i18next";
import { IPlatformModel } from "@bms/common-services";
import { Action, ActionCreator } from "redux";
import {
  Checkbox,
  IBreadcrumbProps,
  ITableColumnProps,
  PageContent,
  PageHeader,
  Table,
} from "@bms/common-ui";
import { generateBreadcrumb } from "../../../../helpers";
import { ROUTES } from "../../../../modules/Dictionaries/constants";

export interface IDictionaryPlatformListProps extends WithTranslation {
  platforms: IPlatformModel[];
  isLoadingData: boolean;
  selectPlatforms: ActionCreator<Action>;
}

export class DictionaryPlatformList extends React.PureComponent<
  IDictionaryPlatformListProps
> {
  public componentDidMount() {
    const { selectPlatforms } = this.props;

    selectPlatforms();
  }

  private getColumnsProps(): Array<ITableColumnProps<IPlatformModel>> {
    const { t } = this.props;

    return [
      {
        key: "Sequence",
        dataIndex: "Sequence",
        title: t("MODEL_SEQUENCE"),
      },
      {
        key: "Code",
        dataIndex: "Code",
        title: t("MODEL_CODE"),
      },
      {
        key: "DisplayName",
        dataIndex: "DisplayName",
        title: t("MODEL_DISPLAY_NAME"),
      },
      {
        key: "IsAndroid",
        dataIndex: "IsAndroid",
        title: t("MODEL_IS_ANDROID"),
        render: (text, record) => (
          <Checkbox checked={record.IsAndroid} disabled={true} />
        ),
      },
      {
        key: "IsIOS",
        dataIndex: "IsIOS",
        title: t("MODEL_IS_IOS"),
        render: (text, record) => (
          <Checkbox checked={record.IsIOS} disabled={true} />
        ),
      },
      {
        key: "IsWeb",
        dataIndex: "IsWeb",
        title: t("MODEL_IS_WEB"),
        render: (text, record) => (
          <Checkbox checked={record.IsWeb} disabled={true} />
        ),
      },
      {
        key: "IsTablet",
        dataIndex: "IsTablet",
        title: t("MODEL_IS_TABLET"),
        render: (text, record) => (
          <Checkbox checked={record.IsTablet} disabled={true} />
        ),
      },
      {
        key: "IsTV",
        dataIndex: "IsTablet",
        title: t("MODEL_IS_TV"),
        render: (text, record) => (
          <Checkbox checked={record.IsTV} disabled={true} />
        ),
      },
      {
        key: "ImageUrl",
        dataIndex: "ImageUrl",
        title: t("MODEL_IMAGE"),
        render: (text, record) => <img src={record.ImageUrl} alt="" />,
      },
    ];
  }

  public getBreadcrumbProps(): IBreadcrumbProps {
    const { t } = this.props;
    return generateBreadcrumb([
      {
        path: `${ROUTES.DICTIONARY_BASE}`,
        breadcrumbName: t("MENU_ADMINISTRATION_DICTIONARIES"),
      },
      {
        path: `${ROUTES.DICTIONARY_PLATFORM_LIST}`,
        breadcrumbName: t(
          "MENU_ADMINISTRATION_DICTIONARIES_PLATFORMS",
          "Platforms"
        ),
      },
    ]);
  }

  public render() {
    const { platforms, isLoadingData, t } = this.props;

    return (
      <PageContent>
        <PageHeader
          title={t("MENU_ADMINISTRATION_DICTIONARIES_PLATFORMS", "Platforms")}
          breadcrumb={this.getBreadcrumbProps()}
        />
        <Table<IPlatformModel>
          rowKey={(row) => row.Code}
          columns={this.getColumnsProps()}
          dataSource={platforms}
          loading={isLoadingData}
          pagination={false}
        />
      </PageContent>
    );
  }
}
