import React from "react";
import { useTranslation } from "react-i18next";
import { AssetPriceDetails } from "../../AssetPriceDetails";

export const PricesTab = () => {
  const { t } = useTranslation();

  return (
    <AssetPriceDetails title={t("ASSET_DETAILS_SECTION_PRICES")} asSection />
  );
};
