import { useDataLoader, AssetStreamsService } from "@bms/common-services";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { MediaChannelForm } from "..";
import { useAppFeedback } from "@bms/common-ui";

const assetStreamsService = new AssetStreamsService().promisify();

export const MediaChannelDetails: React.FC = () => {
  const { t } = useTranslation();
  const { notification } = useAppFeedback();
  const history = useHistory();

  const id = parseInt(useParams<{ id: string }>()["id"]);

  const { data, loading, refresh } = useDataLoader({
    loader: () => assetStreamsService.getChannelById(id),
    deps: [id],
    onError: (error) =>
      notification.error({
        message: t("LOADING_DATA_ERROR_MESSAGE"),
        description: error.Message,
      }),
  });

  return (
    <MediaChannelForm
      channel={data}
      loading={loading}
      refresh={refresh}
      title={t("MEDIA_CHANNEL_DETAILS_TITLE")}
      userId={data?.AssetId}
      onBack={history.goBack}
    />
  );
};
