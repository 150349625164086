import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import {
  AssetPriceService,
  AssetService,
  IAssetPriceModel,
  IAssetPriceSearchFilterModel,
  TimeHelper,
  useDataLoader,
  useServiceCaller,
} from "@bms/common-services";
import {
  Button,
  Icon,
  ITableColumnProps,
  ITablePaginationConfig,
  PageContent,
  PageHeader,
  Pagination,
  Heading,
  Table,
  DomainTag,
  Popconfirm,
  IBreadcrumbProps,
  useAppFeedback,
} from "@bms/common-ui";
import { ROUTES } from "../../constants";
import { AssetPriceForm } from "../AssetPriceForm";
import { FormModal } from "../../../../components";
import { defaultAssetPrice } from "../AssetPriceList";
import { generateBreadcrumb } from "../../../../helpers";

const assetPriceService = new AssetPriceService().promisify();
const assetService = new AssetService().promisify();

interface IAssetPriceModalState {
  isNew: boolean;
  priceFormVisible: boolean;
  selectedAssetPrice: IAssetPriceModel;
}

interface AssetPriceDetailsProps {
  title?: string | null;
  asSection?: boolean;
  onBack?: (() => void) | null;
}

export const AssetPriceDetails: React.FC<AssetPriceDetailsProps> = (props) => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { t } = useTranslation();
  const { notification } = useAppFeedback();

  const [state, setState] = useState<IAssetPriceModalState>({
    isNew: true,
    priceFormVisible: false,
    selectedAssetPrice: { ...defaultAssetPrice },
  });

  const [filter, setFilter] = useState<IAssetPriceSearchFilterModel>({
    PageSize: 10,
    PageNumber: 1,
    IncludeCount: true,
    AssetId: parseInt(id, 10),
  });

  const [createAssetPrice, createAssetPriceState] = useServiceCaller(
    async (data: IAssetPriceModel) => {
      const result = await assetPriceService.insert(data);

      if (result.ok) {
        setState({ ...state, priceFormVisible: false });
        onRefreshClick();
        notification.success({
          message: t("CREATE_ASSET_PRICE_SUCCESS"),
        });
      } else {
        notification.error({
          message: t("CREATE_ASSET_PRICE_FAILURE"),
          description: result?.error?.Message,
        });
      }
    },
    []
  );

  const [updateAssetPrice, updateAssetPriceState] = useServiceCaller(
    async (data: IAssetPriceModel) => {
      const result = await assetPriceService.update(data);

      if (result.ok) {
        setState({ ...state, priceFormVisible: false });
        onRefreshClick();
        notification.success({
          message: t("UPDATE_ASSET_PRICE_SUCCESS"),
        });
      } else {
        notification.error({
          message: t("UPDATE_ASSET_PRICE_FAILURE"),
          description: result?.error?.Message,
        });
      }
    },
    []
  );

  const [deleteAssetPrice, deleteAssetPriceState] = useServiceCaller(
    async (data: IAssetPriceModel) => {
      const result = await assetPriceService.delete(data);

      if (result.ok) {
        setState({ ...state, priceFormVisible: false });
        onRefreshClick();
        notification.success({
          message: t("DELETE_ASSET_PRICE_SUCCESS"),
        });
      } else {
        notification.error({
          message: t("DELETE_ASSET_PRICE_FAILURE"),
          description: result?.error?.Message,
        });
      }
    },
    []
  );

  const assetLoader = useDataLoader({
    loader: () => assetService.getAsset(parseInt(id, 10)),
    deps: [id],
  });

  const assetPriceLoader = useDataLoader({
    loader: () => assetPriceService.search(filter),
    onError: (error) => {
      notification.error({
        message: t("DATA_LOADING_FAILURE"),
        description: error?.Message,
      });
    },
    deps: [filter],
  });

  const getBreadcrumbProps = (): IBreadcrumbProps => {
    return generateBreadcrumb([
      {
        path: `${ROUTES.ASSET_PRICE_LIST}`,
        breadcrumbName: t("ASSET_PRICES_LIST_TITLE"),
      },
      {
        path: `${ROUTES.ASSET_PRICE_DETAILS}/${id}`,
        breadcrumbName:
          props.title ?? assetLoader.data?.Title ?? t("ASSET_PRICES_DETAILS"),
      },
    ]);
  };

  const onRefreshClick = () => setFilter({ ...filter });

  const pagination: ITablePaginationConfig = {
    current: filter.PageNumber,
    defaultPageSize: 10,
    pageSize: filter.PageSize,
    pageSizeOptions: ["10", "30", "50", "100"],
    showSizeChanger: true,
    showTotal: (total, range) =>
      t("TABLE_PAGINATION_TOTAL", {
        rangeFrom: range[0],
        rangeTo: range[1],
        total: total,
      }),
    total: assetPriceLoader.data?.TotalCount ?? 0,
    onChange: (page: number, pageSize?: number) => {
      setFilter({
        ...filter,
        PageNumber: page,
        PageSize: pageSize ?? filter.PageSize,
      });
    },
  };

  const columns: Array<ITableColumnProps<IAssetPriceModel>> = [
    {
      key: "Price",
      dataIndex: "Price",
      title: t("MODEL_PRICE"),
      width: "100px",
      align: "center",
      render: (_: any, row: IAssetPriceModel) => {
        return (
          <a
            onClick={() => {
              setState({
                ...state,
                priceFormVisible: true,
                selectedAssetPrice: row,
                isNew: false,
              });
            }}
          >
            {row.Price}
          </a>
        );
      },
    },
    {
      key: "CurrencyCode",
      dataIndex: "CurrencyCode",
      title: t("MODEL_CURRENCY"),
      width: "100px",
      align: "center",
      render: (text: any, row: IAssetPriceModel) => (
        <DomainTag noMargin domain="currency" value={row.CurrencyCode} />
      ),
    },
    {
      key: "AssetPurchasePeriodTypeName",
      dataIndex: "AssetPurchasePeriodTypeName",
      title: t("MODEL_ASSET_PURCHASE_PERIOD_NAME"),
      width: "100px",
      align: "center",
      render: (text: any, row: IAssetPriceModel) => (
        <DomainTag
          noMargin
          domain="purchase-period"
          colorRotate={row.AssetPurchasePeriodTypeCode}
          value={row.AssetPurchasePeriodTypeName}
        />
      ),
    },
    {
      key: "AvailableFrom",
      dataIndex: "AvailableFrom",
      title: t("MODEL_AVAILABLE_FROM"),
      width: "140px",
      align: "center",
      render: (text: any, row: IAssetPriceModel) =>
        row.AvailableFrom ? TimeHelper.format(row.AvailableFrom) : null,
    },
    {
      key: "AvailableTo",
      dataIndex: "AvailableTo",
      title: t("MODEL_AVAILABLE_TO"),
      width: "140px",
      align: "center",
      render: (text: any, row: IAssetPriceModel) =>
        row.AvailableTo ? TimeHelper.format(row.AvailableTo) : null,
    },
    {
      key: "Actions",
      dataIndex: "Actions",
      align: "center",
      width: "110px",
      title: t("TABLE_ACTIONS_COLUMN"),
      render: (_, assetPrice: IAssetPriceModel) => (
        <Popconfirm
          title={t("DELETE_ELEMENT_DOUBLE_CONFIRMATION_QUESTION")}
          onConfirm={async (e?: React.MouseEvent<HTMLElement>) => {
            e?.preventDefault();
            await deleteAssetPrice(assetPrice);
          }}
          okText={t("BUTTON_YES")}
          cancelText={t("BUTTON_NO")}
        >
          <Button
            danger={true}
            icon={<Icon type="delete" />}
            title={t("DELETE_ELEMENT")}
          />
        </Popconfirm>
      ),
    },
  ];

  const actions = (
    <>
      <Button
        shape="circle"
        type="primary"
        icon={<Icon type="plus" />}
        disabled={
          assetLoader.loading ||
          assetPriceLoader.loading ||
          assetLoader.data?.IsFree
        }
        onClick={() => {
          setState({
            ...state,
            selectedAssetPrice: {
              ...defaultAssetPrice,
              AssetId: assetLoader.data?.Id ?? defaultAssetPrice.AssetId,
              AssetTitle: assetLoader.data?.Title,
            },
            isNew: true,
            priceFormVisible: true,
          });
        }}
        title={
          assetLoader.data?.IsFree
            ? t("ASSET_PRICE_ADD_NOT_AVAILABLE_FOR_FREE_MESSAGE")
            : t("BUTTON_ADD")
        }
      />
      <Button
        shape="circle"
        icon={<Icon type="reload" />}
        onClick={onRefreshClick}
        title={t("BUTTON_REFRESH_TITLE")}
      />
    </>
  );

  const table = (
    <>
      <Table<IAssetPriceModel>
        rowKey="Id"
        columns={columns}
        dataSource={assetPriceLoader.data?.Entities}
        loading={assetPriceLoader.loading}
        pagination={false}
      />

      <FormModal
        isLoading={
          assetPriceLoader.loading ||
          createAssetPriceState.processing ||
          updateAssetPriceState.processing ||
          deleteAssetPriceState.processing
        }
        isVisible={state.priceFormVisible}
        isNewForm={state.isNew}
        isDeleteButtonEnabled={true}
        createFormTitle={t("ADD_ASSET_PRICE_MODAL_TITLE")}
        editFormTitle={t("EDIT_ASSET_PRICE_MODAL_TITLE")}
        modalClassName="AssetPriceModal"
        submitFormName="AssetPriceForm"
        onCloseModal={() => {
          setState({ ...state, priceFormVisible: false });
        }}
        onDeleteButtonClick={() => {
          deleteAssetPrice(state.selectedAssetPrice);
        }}
      >
        <AssetPriceForm
          assetPrice={state.selectedAssetPrice}
          onCreate={createAssetPrice}
          onUpdate={updateAssetPrice}
        />
      </FormModal>
    </>
  );

  return props.asSection ? (
    <>
      <Heading
        title={
          props.title === null
            ? undefined
            : props.title ?? assetLoader.data?.Title ?? t("LOADING_STATUS")
        }
        actions={actions}
      />
      {table}
    </>
  ) : (
    <PageContent footer={<Pagination {...pagination} />}>
      <PageHeader
        title={
          props.title === null
            ? undefined
            : props.title ?? assetLoader.data?.Title ?? t("LOADING_STATUS")
        }
        extra={actions}
        breadcrumb={getBreadcrumbProps()}
        onBack={
          props.onBack === null
            ? undefined
            : props.onBack ?? (() => history.push(ROUTES.ASSET_PRICE_LIST))
        }
      />
      {table}
    </PageContent>
  );
};
