export enum CreatorStreamState {
  Idle = "IDLE",
  Ready = "READY",
  Starting = "STARTING",
  Stopping = "STOPPING",
  Stopped = "STOPPED",
  Running = "RUNNING",
  Deleting = "DELETING",
  Deleted = "DELETED",
}
