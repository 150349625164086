import React, { memo } from "react";
import { LabeledValueText, Card, Skeleton } from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { ISalesRevenueDataCardProps } from "./ISalesRevenueDataCardProps";

export const SalesRevenuePaymentTypeSalesData = memo(
  ({ data, isLoading }: ISalesRevenueDataCardProps) => {
    const { t } = useTranslation();
    return (
      <Card
        style={{ width: "50%" }}
        title={t("SALES_REVENUE_PAYMENT_TYPE_SALES_DATA")}
      >
        <Skeleton active={true} loading={isLoading} paragraph={true}>
          {data?.PurchaseTypeIncome?.map((income, index) => (
            <LabeledValueText
              key={`sales-revenue-payment-type-income--${index}--${income.PurchaseType}`}
              label={t(
                `SALES_REVENUE_PAYMENT_TYPE_${income.PurchaseType.toUpperCase()}`
              )}
              value={`${income.Income.toFixed(2)}`.replace(".", ",")}
            />
          ))}
        </Skeleton>
      </Card>
    );
  }
);
