import React from "react";
import { Form } from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { IAssetFormFieldCommonProps } from "./IAssetFormFieldCommonProps";
import { LazyChoose } from "../../../../../components";
import {
  AssetCategoryModel,
  AssetCategoryService,
  IAssetCategoryModel,
} from "@bms/common-services";

interface ICategoriesFieldProps extends IAssetFormFieldCommonProps {}

const assetCategoryService = new AssetCategoryService().promisify();

export const CategoriesField = ({
  asset,
  isHidden,
  isEditMode,
}: ICategoriesFieldProps) => {
  const { t } = useTranslation();
  if (isHidden) {
    return null;
  }

  return (
    <Form.Item
      name="Categories"
      label={t("ASSET_CATEGORIES_LABEL")}
      key="Categories"
      initialValue={(isEditMode && asset?.Categories) || []}
    >
      <LazyChoose<IAssetCategoryModel, AssetCategoryModel>
        loader={(search: string) =>
          assetCategoryService.search({
            PageNumber: 1,
            PageSize: 100,
            FullTextSearch: search,
          })
        }
        candidateToOption={(item) => ({
          label: item.Name,
          value: `${item.Id}`,
          item: {
            AssetId: asset?.Id,
            AssetCategoryId: item.Id,
            AssetCategoryName: item.Name,
            AssetCategoryCode: item.Code,
            RowVersion: item.RowVersion,
          },
        })}
        selectedToOption={(item) => ({
          label: item.AssetCategoryName!,
          value: `${item.AssetCategoryId!}`,
          item,
        })}
      />
    </Form.Item>
  );
};
