import { Observable, Observer } from "rxjs";
import { AxiosSubscriber } from "../AxiosSubscriber";
import { HTTP_METHOD } from "../../constants";
import { IAssetPurchasePeriodTypeModel } from "../../models/Asset";
import { PromisifiableBase } from "../Base";

export class AssetPurchasePeriodTypeService extends PromisifiableBase {
  get url(): string {
    return "/AssetPurchasePeriodTypes";
  }

  public select = (): Observable<IAssetPurchasePeriodTypeModel[]> =>
    new Observable(
      (observer: Observer<IAssetPurchasePeriodTypeModel[]>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `${this.url}/Select`,
        })
    );
}
