import {
  GuidHelper,
  useDataLoader,
  useServiceCaller,
  CryptoCoinPriceListsService,
  TimeHelper,
  ICryptoCoinPriceListModel,
  CurrencyService,
} from "@bms/common-services";
import {
  IBreadcrumbProps,
  ITableColumnProps,
  PageContent,
  PageHeader,
  Button,
  Table,
  DomainTag,
  useAppFeedback,
} from "@bms/common-ui";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import { FormModal } from "../../../../components";
import { generateBreadcrumb } from "../../../../helpers";
import { ROUTES } from "../../constants";
import { CryptoCoinPricesForm } from "./CryptoCoinPricesForm";

const cryptoCoinPriceListsService = new CryptoCoinPriceListsService().promisify();
const currencyService = new CurrencyService().promisify();

export const defaultCryptoCoinPrice: ICryptoCoinPriceListModel = {
  Id: -1,
  Guid: "1",
  Price: 0.1,
  CurrencyId: -1,
  CurrencyCode: "",
  Amount: 1,
  AvailableFrom: TimeHelper.getCurrentDate().toISOString(),
};

export const CryptoCoinPrices = () => {
  const { t } = useTranslation();
  const { notification } = useAppFeedback();

  const [showCryptoCoinPricesModal, setShowCryptoCoinPricesModal] = useState(
    false
  );
  const [editableCryptoCoinPrices, setEditableCryptoCoinPrices] = useState<
    ICryptoCoinPriceListModel
  >(defaultCryptoCoinPrice);

  const cryptoCoinPricesLoader = useDataLoader({
    loader: () => cryptoCoinPriceListsService.select(),
    deps: [],
  });

  useEffect(() => {
    currencyService
      .select()
      .then((currencies) => {
        if (currencies.ok && currencies.data && currencies.data.length > 0) {
          const currency = currencies.data[0];

          defaultCryptoCoinPrice.CurrencyId = currency.Id;
          defaultCryptoCoinPrice.CurrencyCode = currency.Code;
          defaultCryptoCoinPrice.Price = currency.MinPayment;
        }
      })
      .catch((error) => {
        notification.error({
          message: t("DICTIONARY_CURRENCY_SELECT_FAILURE"),
          description: error?.Message,
        });
      });
  }, []);

  const [
    insertCryptoCoinPrice,
    { processing: insertProcessing },
  ] = useServiceCaller(async (cryptoCoinPrice: ICryptoCoinPriceListModel) => {
    const result = await cryptoCoinPriceListsService.insert({
      ...cryptoCoinPrice,
      Guid: GuidHelper.newGuid(),
    });
    if (result.ok) {
      notification.success({
        message: t("CRYPTO_COIN_PRICES_INSERT_SUCCESS"),
      });
      await cryptoCoinPricesLoader.refresh();
      closeModal();
    } else {
      notification.error({
        message: t("CRYPTO_COIN_PRICES_INSERT_FAILURE"),
        description: result.error?.Message,
      });
    }
  }, []);

  const [
    updateCryptoCoinPrice,
    { processing: updateProcessing },
  ] = useServiceCaller(async (cryptoCoinPrice: ICryptoCoinPriceListModel) => {
    const result = await cryptoCoinPriceListsService.update(cryptoCoinPrice);
    if (result.ok) {
      notification.success({
        message: t("CRYPTO_COIN_PRICES_UPDATE_SUCCESS"),
      });
      await cryptoCoinPricesLoader.refresh();
      closeModal();
    } else {
      notification.error({
        message: t("CRYPTO_COIN_PRICES_UPDATE_FAILURE"),
        description: result.error?.Message,
      });
    }
  }, []);

  const [
    deleteCryptoCoinPrice,
    { processing: deleteProcessing },
  ] = useServiceCaller(async (cryptoCoinPrice: ICryptoCoinPriceListModel) => {
    const result = await cryptoCoinPriceListsService.delete(cryptoCoinPrice);
    if (result.ok) {
      notification.success({
        message: t("CRYPTO_COIN_PRICES_DELETE_SUCCESS"),
      });
      await cryptoCoinPricesLoader.refresh();
      closeModal();
    } else {
      notification.error({
        message: t("CRYPTO_COIN_PRICES_DELETE_FAILURE"),
        description: result.error?.Message,
      });
    }
  }, []);

  const columns: Array<ITableColumnProps<ICryptoCoinPriceListModel>> = [
    {
      key: "Price",
      dataIndex: "Price",
      title: t("MODEL_PRICE"),
      width: "120px",
      render: (_: any, row: ICryptoCoinPriceListModel) => {
        return (
          <a
            title={String(row.Price)}
            className="imageUrl"
            onClick={() => {
              setShowCryptoCoinPricesModal(true);
              setEditableCryptoCoinPrices(row);
            }}
          >
            {row.Price}
          </a>
        );
      },
    },
    {
      key: "CurrencyCode",
      dataIndex: "CurrencyCode",
      title: t("MODEL_CURRENCY"),
      width: "120px",
      align: "center",
      render: (_, row: ICryptoCoinPriceListModel) => (
        <DomainTag domain="currency" noMargin value={row.CurrencyCode} />
      ),
    },
    {
      key: "Amount",
      dataIndex: "Amount",
      title: t("MODEL_AMOUNT"),
      width: "120px",
      align: "center",
    },
    {
      key: "AvailableFrom",
      dataIndex: "AvailableFrom",
      title: t("MODEL_AVAILABLE_FROM"),
      width: "200px",
      align: "center",
      render: (text: any, row: ICryptoCoinPriceListModel) =>
        row.AvailableFrom ? TimeHelper.format(row.AvailableFrom) : null,
    },
    {
      key: "AvailableTo",
      dataIndex: "AvailableTo",
      title: t("MODEL_AVAILABLE_TO"),
      width: "200px",
      align: "center",
      render: (text: any, row: ICryptoCoinPriceListModel) =>
        row.AvailableTo ? TimeHelper.format(row.AvailableTo) : null,
    },
    {
      key: "Type",
      dataIndex: "Type",
      title: t("MODEL_PURCHASE_TYPE"),
      align: "center",
      render: (text: any, row: ICryptoCoinPriceListModel) => (
        <DomainTag domain="purchase-period" noMargin value={row.Type} />
      ),
    },
  ];

  const onRefreshClick = () => {
    cryptoCoinPricesLoader.refresh();
  };

  const onDeleteCryptoCoinPrice = () => {
    if (editableCryptoCoinPrices) {
      deleteCryptoCoinPrice(editableCryptoCoinPrices);
    }
  };

  const onAddNewCryptoCoinPriceClick = () => {
    setShowCryptoCoinPricesModal(true);
  };

  const closeModal = () => {
    setShowCryptoCoinPricesModal(false);
    setEditableCryptoCoinPrices(defaultCryptoCoinPrice);
  };

  const getExtraButtons = () => {
    return (
      <>
        <Button
          key="add"
          shape="circle"
          type="primary"
          icon={<PlusOutlined />}
          onClick={onAddNewCryptoCoinPriceClick}
          title={t("DICTIONARY_CURRENCY_ADD_NEW")}
        />
        <Button
          key="reload"
          shape="circle"
          icon={<ReloadOutlined />}
          onClick={onRefreshClick}
          title={t("DICTIONARY_CURRENCY_REFRESH")}
        />
      </>
    );
  };

  const getBreadcrumbProps = (): IBreadcrumbProps => {
    return generateBreadcrumb([
      {
        path: `${ROUTES.CRYPTO_COIN_PRICES}`,
        breadcrumbName: t("CRYPTO_COIN_PRICES_TITLE", "Crypto Coin Prices"),
      },
    ]);
  };

  return (
    <div className="CryptoCoinPrices">
      <PageHeader
        title={t("CRYPTO_COIN_PRICES_TITLE", "Crypto Coin Prices")}
        extra={getExtraButtons()}
        breadcrumb={getBreadcrumbProps()}
      />
      <PageContent>
        <Table<ICryptoCoinPriceListModel>
          rowKey="Id"
          columns={columns}
          dataSource={cryptoCoinPricesLoader.data}
          loading={cryptoCoinPricesLoader.loading}
          pagination={false}
        />
      </PageContent>

      <FormModal
        isLoading={insertProcessing || updateProcessing || deleteProcessing}
        isVisible={showCryptoCoinPricesModal}
        isNewForm={editableCryptoCoinPrices.Id < 0}
        isDeleteButtonEnabled={editableCryptoCoinPrices.Id >= 0}
        createFormTitle={t("CRYPTO_COIN_PRICES_MODAL_NEW")}
        editFormTitle={t("CRYPTO_COIN_PRICES_MODAL_EDIT")}
        modalClassName="CryptoCoinPricesModal"
        submitFormName="CryptoCoinPricesForm"
        onCloseModal={closeModal}
        onDeleteButtonClick={onDeleteCryptoCoinPrice}
      >
        <CryptoCoinPricesForm
          cryptoCoinPrice={editableCryptoCoinPrices}
          onCreate={insertCryptoCoinPrice}
          onUpdate={updateCryptoCoinPrice}
        />
      </FormModal>
    </div>
  );
};
