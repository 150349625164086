import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ICreatorStreamModel,
  AssetStreamsService,
  CreatorStreamState,
  TimeHelper,
} from "@bms/common-services";
import {
  Button,
  Icon,
  InputSearch,
  ITableColumnProps,
  ITableFilter,
  Link,
  PageContent,
  PageHeader,
  Pagination,
  Table,
  Popconfirm,
  IBreadcrumbProps,
  useAppFeedback,
} from "@bms/common-ui";
import { MediaChannelStateTag } from "..";
import { generateBreadcrumb, useTableDataProvider } from "../../../../helpers";
import { ROUTES } from "../../constants";
import { ROUTES as ASSET_ROUTES } from "../../../Asset/constants";
import { CreateMediaChannelModal } from "../CreateMediaChannelModal";

const assetStreamsService = new AssetStreamsService().promisify();

export const MediaChannelList: React.FC = () => {
  const { t } = useTranslation();
  const { notification } = useAppFeedback();

  const [
    showCreateMediaChannelModal,
    setShowCreateMediaChannelModal,
  ] = useState<boolean>(false);

  const { destroyAllModals } = useAppFeedback();

  const {
    dataLoader: channelSearchLoader,
    filters,
    pagination,
    fullTextSearch,
    setFullTextSearch,
  } = useTableDataProvider({
    filtersSchema: {
      UserFullName: "string",
      FullTextSearch: "string",
    },
    loader: (filters, pagination) =>
      assetStreamsService.searchChannel({
        ...filters,
        ...pagination,
      }),
    deps: [],
    onError: (error) =>
      notification.error({
        message: t(
          "LOADING_DATA_ERROR_MESSAGE",
          "There was an error while loading data."
        ),
        description: error.Message,
      }),
  });

  const columns: Array<ITableColumnProps<ICreatorStreamModel>> = [
    {
      key: "ChannelId",
      dataIndex: "ChannelId",
      title: t("MODEL_CHANNEL_ID"),
      width: "200px",
      render: (_: string, row: ICreatorStreamModel) => {
        return (
          <Link to={`${ROUTES.CHANNEL_DETAILS}/${row.AssetId}`}>
            {row.ChannelId}
          </Link>
        );
      },
    },
    {
      key: "Name",
      dataIndex: "Name",
      title: t("MODEL_NAME"),
    },
    {
      key: "AssetId",
      dataIndex: "AssetId",
      title: t("MEDIA_CHANNEL_COLUMN_ASSET_TITLE"),
      render: (_: string, row: ICreatorStreamModel) => {
        return (
          <Link to={`${ASSET_ROUTES.ASSET_DETAILS}/${row.AssetId}`}>
            {row.AssetTitle}
          </Link>
        );
      },
    },
    {
      key: "Created",
      dataIndex: "Created",
      title: t("MODEL_CREATION_DATE"),
      align: "center",
      width: "140px",
      render: (creationDate: Date) => TimeHelper.format(creationDate),
    },
    {
      key: "CreatedByFullName",
      dataIndex: "CreatedByFullName",
      title: t("MODEL_CREATED_BY"),
      align: "center",
    },
    {
      key: "State",
      dataIndex: "State",
      title: t("MODEL_STATE"),
      width: "150px",
      align: "center",
      render: (_text: string, row) => (
        <MediaChannelStateTag state={row.State}></MediaChannelStateTag>
      ),
    },
    {
      key: "Actions",
      dataIndex: "Actions",
      align: "center",
      width: "100px",
      title: t("TABLE_ACTIONS_COLUMN"),
      render: (_: string, row: ICreatorStreamModel) => {
        if (row.State === CreatorStreamState.Deleting) {
          return null;
        }

        return (
          <Popconfirm
            title={t("DELETE_ELEMENT_DOUBLE_CONFIRMATION_QUESTION")}
            onConfirm={async (e?: React.MouseEvent<HTMLElement>) => {
              e?.preventDefault();
              const result = await assetStreamsService.delete(row.AssetId);

              if (result.ok) {
                notification.success({
                  message: t("DELETE_CHANNEL_SUCCESS"),
                });
                await channelSearchLoader.refresh();
              } else {
                notification.error({
                  message: t("DELETE_CHANNEL_FAILURE"),
                  description: result.error?.Message,
                });
              }
            }}
            okText={t("BUTTON_YES")}
            cancelText={t("BUTTON_NO")}
          >
            <Button
              danger={true}
              icon={<Icon type="delete" />}
              title={t("DELETE_ELEMENT")}
            />
          </Popconfirm>
        );
      },
    },
  ];

  const onTableChange = (_: any, incomingFilters: ITableFilter) =>
    filters.update((oldFilters) => ({
      ...oldFilters,
      ...incomingFilters,
    }));

  const onSearch = (value: string) =>
    filters.update((oldFilters) => ({
      ...oldFilters,
      FullTextSearch: value || undefined,
    }));

  const getBreadcrumbProps = (): IBreadcrumbProps => {
    return generateBreadcrumb([
      {
        path: `${ROUTES.CHANNEL_LIST}`,
        breadcrumbName: t("MEDIA_CHANNEL_LIST_TITLE"),
      },
    ]);
  };

  const onCancelCreateMediaChannel = () => {
    setShowCreateMediaChannelModal(false);
    destroyAllModals();
  };

  const onSuccessCreateMediaChannel = async () => {
    setShowCreateMediaChannelModal(false);
    destroyAllModals();
    channelSearchLoader.refresh();
  };

  return (
    <PageContent footer={<Pagination {...pagination.props} />}>
      <PageHeader
        title={t("MEDIA_CHANNEL_LIST_TITLE")}
        breadcrumb={getBreadcrumbProps()}
        extra={
          <>
            <InputSearch
              placeholder={t("SEARCH_PLACEHOLDER")}
              value={fullTextSearch}
              onChange={({ target: { value } }) => setFullTextSearch(value)}
              onSearch={onSearch}
              style={{ width: 250 }}
              allowClear
            />
            <Button
              shape="circle"
              type="primary"
              icon={<Icon type="plus" />}
              onClick={() => {
                setShowCreateMediaChannelModal(true);
              }}
              disabled={channelSearchLoader.loading}
              title={t("BUTTON_ADD")}
            />
            <Button
              shape="circle"
              icon={<Icon type="reload" />}
              onClick={channelSearchLoader.refresh}
              title={t("BUTTON_REFRESH_TITLE")}
            />
          </>
        }
      />
      <Table<ICreatorStreamModel>
        rowKey="Id"
        columns={columns}
        dataSource={channelSearchLoader.data?.Entities}
        loading={channelSearchLoader.loading}
        pagination={false}
        onChange={onTableChange}
      />
      <CreateMediaChannelModal
        visible={showCreateMediaChannelModal}
        onCancel={onCancelCreateMediaChannel}
        onSuccess={onSuccessCreateMediaChannel}
      />
    </PageContent>
  );
};
