import React, { useMemo, useState } from "react";
import {
  Button,
  Icon,
  PageContent,
  PageHeader,
  Spin,
  TabPane,
  Tabs,
  useSearchParamsTabs,
  IBreadcrumbProps,
} from "@bms/common-ui";
import { AssetType, IAssetModel } from "@bms/common-services";
import { useAssetDetailsController } from "./useAssetDetailsController";
import {
  AssetsTab,
  ContentTab,
  CustomTab,
  DescriptionTab,
  DetailsTab,
  ImageTab,
  PackagesTab,
  PricesTab,
  ProgramsTab,
  SeasonsTab,
  PreviewTab,
} from "./tabs";
import { EpisodesTab } from "./tabs/EpisodesTab";
import { AddAssetModal } from "../AddAssetModal";
import { AssetForm } from "../AssetForm";
import { ROUTES } from "../../constants/routes";
import { generateBreadcrumb } from "../../../../helpers";
import { useParams } from "react-router";

import "./AssetDetails.scss";

export const AssetDetails = () => {
  const [activeTabKey] = useSearchParamsTabs("DETAILS");
  const { id } = useParams<{ id: string }>();
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const {
    refresh,
    updateAsset,
    createChildAsset,
    createChildAssetState,
    onBackClick,
    onDeleteClick,
    isAssetInType,
    t,
    loading,
    updateAssetState,
    asset,
    dataPager,
    dataPagerLoading,
  } = useAssetDetailsController();

  const tabVisibility = useMemo(
    () => ({
      DETAILS: true,
      DESCRIPTION: true,
      IMAGES: true,
      CONTENT: !isAssetInType(
        AssetType.Program,
        AssetType.Package,
        AssetType.Advertisement
      ),
      SEASONS: isAssetInType(AssetType.Series),
      EPISODES: isAssetInType(
        AssetType.Season,
        AssetType.Album,
        AssetType.Series
      ),
      PROGRAMS: isAssetInType(AssetType.Channel),
      ASSETS: isAssetInType(AssetType.Package),
      PACKAGES: !isAssetInType(
        AssetType.Intro,
        AssetType.Program,
        AssetType.Package,
        AssetType.Advertisement
      ),
      PRICES: !isAssetInType(AssetType.Program, AssetType.Advertisement),
      CUSTOM: isAssetInType(
        AssetType.Advertisement,
        AssetType.Video,
        AssetType.Episode,
        AssetType.Live
      ),
      PREVIEW: isAssetInType(AssetType.Article),
    }),
    [asset?.AssetTypeCode]
  );

  const buttonVisibility = useMemo(
    () => ({
      ADD_CATCHUP: isAssetInType(AssetType.Live) && !asset?.ChildAssetId,
    }),
    [asset?.AssetTypeCode, asset?.Id]
  );

  const getBreadcrumbProps = (): IBreadcrumbProps => {
    return generateBreadcrumb([
      {
        path: `${ROUTES.ASSET_LIST}`,
        breadcrumbName: t("MENU_CMS_MEDIA"),
      },
      {
        path: `${ROUTES.ASSET_LIST}/${id}`,
        breadcrumbName: asset?.Title ?? t("MENU_CMS_MEDIA_DETAILS"),
      },
    ]);
  };

  const onCreateChildAsset = async (data: IAssetModel) => {
    const isOk = await createChildAsset(data, asset);

    if (isOk) {
      setModalOpen(false);
    }
  };

  return (
    <Spin spinning={loading || dataPagerLoading}>
      {buttonVisibility.ADD_CATCHUP && (
        <AddAssetModal
          visible={isModalOpen}
          close={() => setModalOpen(false)}
          processing={createChildAssetState.processing}
          translated={{ title: t("ASSET_ADD_CATCHUP") }}
        >
          <AssetForm
            isEditMode={false}
            parent={asset}
            onSubmit={onCreateChildAsset}
            hiddenFields={[
              "Categories",
              "IsFree",
              "Director",
              "Writer",
              "Cast",
              "Year",
              "StartEndDates",
              "Year",
            ]}
          />
        </AddAssetModal>
      )}
      <PageContent>
        <PageHeader
          title={asset?.Title || t("MENU_CMS_MEDIA_DETAILS")}
          onBack={onBackClick}
          breadcrumb={getBreadcrumbProps()}
          extra={
            <>
              {buttonVisibility.ADD_CATCHUP && (
                <Button
                  key="action-add-catchup"
                  shape="circle"
                  type="primary"
                  icon={<Icon type="plus" />}
                  onClick={() => setModalOpen(true)}
                  title={t("ASSET_ADD_CATCHUP")}
                />
              )}
              {dataPager.enabled && (
                <>
                  <Button
                    key="action-move-previous"
                    icon={<Icon type="arrow-left" />}
                    onClick={dataPager.movePrevious}
                    title={t("BUTTON_MOVE_PREVIOUS")}
                    shape="circle"
                    disabled={!dataPager.movePreviousEnabled}
                  />
                  <Button
                    key="action-move-next"
                    icon={<Icon type="arrow-right" />}
                    title={t("BUTTON_MOVE_NEXT")}
                    onClick={dataPager.moveNext}
                    shape="circle"
                    disabled={!dataPager.moveNextEnabled}
                  />
                </>
              )}
              <Button
                key="action-reload"
                icon={<Icon type="reload" />}
                onClick={refresh}
                title={t("BUTTON_REFRESH_TITLE")}
                shape="circle"
              />
              <Button
                danger
                key="action-delete"
                icon={<Icon type="delete" />}
                title={t("BUTTON_DELETE")}
                onClick={onDeleteClick}
                shape="circle"
              />
            </>
          }
        />
        <Tabs activeKey={activeTabKey} destroyInactiveTabPane={true}>
          {tabVisibility.DETAILS && (
            <TabPane key="DETAILS" tab={t("ASSET_DETAILS_TAB_DETAILS")}>
              <DetailsTab
                updateAssetState={updateAssetState}
                updateAsset={updateAsset}
                asset={asset}
              />
            </TabPane>
          )}
          {tabVisibility.DESCRIPTION && (
            <TabPane key="DESCRIPTION" tab={t("ASSET_DETAILS_TAB_DESCRIPTION")}>
              <DescriptionTab
                updateAssetState={updateAssetState}
                updateAsset={updateAsset}
                asset={asset}
              />
            </TabPane>
          )}
          {tabVisibility.IMAGES && (
            <TabPane key="IMAGES" tab={t("ASSET_DETAILS_TAB_IMAGES")}>
              <ImageTab refresh={refresh} asset={asset} />
            </TabPane>
          )}
          {tabVisibility.CONTENT && (
            <TabPane
              key="CONTENT"
              tab={t("ASSET_DETAILS_TAB_CONTENT")}
              forceRender
            >
              <ContentTab refresh={refresh} asset={asset} />
            </TabPane>
          )}
          {tabVisibility.SEASONS && (
            <TabPane key="SEASONS" tab={t("ASSET_DETAILS_TAB_SEASONS")}>
              <SeasonsTab asset={asset} />
            </TabPane>
          )}
          {tabVisibility.EPISODES && (
            <TabPane
              key="EPISODES"
              tab={
                isAssetInType(AssetType.Album)
                  ? t("ASSET_DETAILS_TAB_PODCASTS")
                  : t("ASSET_DETAILS_TAB_EPISODES")
              }
            >
              <EpisodesTab asset={asset} />
            </TabPane>
          )}
          {tabVisibility.PROGRAMS && (
            <TabPane key="PROGRAMS" tab={t("ASSET_DETAILS_TAB_PROGRAMS")}>
              <ProgramsTab asset={asset} />
            </TabPane>
          )}
          {tabVisibility.ASSETS && (
            <TabPane key="ASSETS" tab={t("ASSET_DETAILS_TAB_ASSETS")}>
              <AssetsTab asset={asset} />
            </TabPane>
          )}
          {tabVisibility.PACKAGES && (
            <TabPane key="PACKAGES" tab={t("ASSET_DETAILS_TAB_PACKAGES")}>
              <PackagesTab />
            </TabPane>
          )}
          {tabVisibility.PRICES && (
            <TabPane key="PRICES" tab={t("ASSET_DETAILS_TAB_PRICES")}>
              <PricesTab />
            </TabPane>
          )}
          {tabVisibility.CUSTOM && (
            <TabPane key="CUSTOM" tab={t("ASSET_DETAILS_TAB_CUSTOM")}>
              <CustomTab
                updateAssetState={updateAssetState}
                updateAsset={updateAsset}
                asset={asset}
              />
            </TabPane>
          )}
          {tabVisibility.PREVIEW && (
            <TabPane key="PREVIEW" tab={t("COMMON_PREVIEW")}>
              <PreviewTab asset={asset} />
            </TabPane>
          )}
        </Tabs>
      </PageContent>
    </Spin>
  );
};
