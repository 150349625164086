import { Observable, Observer } from "rxjs";
import { HTTP_METHOD } from "../constants/http";
import { CountryModel } from "../models";
import { AxiosSubscriber } from "./AxiosSubscriber";
import { PromisifiableBase } from "./Base";

export class CountriesService extends PromisifiableBase {
  get url(): string {
    return "/Countries";
  }

  public select = (): Observable<CountryModel[]> =>
    new Observable(
      (observer: Observer<CountryModel[]>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `${this.url}/Select`,
        })
    );

  public update = (data: CountryModel): Observable<CountryModel> =>
    new Observable(
      (observer: Observer<CountryModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.PUT,
          url: `${this.url}/Update`,
        })
    );

  public insert = (data: CountryModel): Observable<CountryModel> =>
    new Observable(
      (observer: Observer<CountryModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: `${this.url}/Insert`,
        })
    );

  public delete = (data: CountryModel): Observable<undefined> =>
    new Observable(
      (observer: Observer<undefined>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: `${this.url}/Delete`,
        })
    );
}
