import React from "react";
import { TextWidgetComponentPropertyModel } from "../../models";

export interface TextWidgetMediaComponentPreviewProps {
  properties?: TextWidgetComponentPropertyModel;
}

export const TextWidgetMediaComponentPreview = React.memo(
  ({ properties }: TextWidgetMediaComponentPreviewProps) => {
    if (!properties?.IconAbsoluteUrl) {
      return null;
    }

    return <img alt="" src={properties.IconAbsoluteUrl} />;
  }
);
