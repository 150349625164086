import { ICommonAppState } from "@bms/common-services";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
  ApplicationMenuItemProperties as ApplicationMenuItemPropertiesDefinition,
  IApplicationMenuItemPropertiesDispatchProps,
  IApplicationMenuItemPropertiesOwnProps,
  IApplicationMenuItemPropertiesStateProps,
} from "./ApplicationMenuItemProperties";

const mapStateToProps = (
  state: ICommonAppState
): IApplicationMenuItemPropertiesStateProps => {
  return {
    isProcessingData: state.applicationConfiguration.isProcessingData,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch
): IApplicationMenuItemPropertiesDispatchProps => ({});

export const ApplicationMenuItemProperties = connect<
  IApplicationMenuItemPropertiesStateProps,
  IApplicationMenuItemPropertiesDispatchProps,
  IApplicationMenuItemPropertiesOwnProps,
  ICommonAppState
>(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ApplicationMenuItemPropertiesDefinition));
