import { TestStatus } from "../enums";

export class TestStatusHelper {
  static getDescription(value: TestStatus): string {
    switch (value) {
      case TestStatus.Draft:
        return "Draft";
      case TestStatus.Processing:
        return "Processing";
      case TestStatus.Finished:
        return "Finished";
      default:
        return "Undefined";
    }
  }

  static getColor(value: TestStatus): string {
    let color: string;

    switch (value) {
      case TestStatus.Finished:
        color = "#418841";
        break;
      case TestStatus.Processing:
        color = "#2db7f5";
        break;
      case TestStatus.Draft:
      default:
        color = "#555";
        break;
    }

    return color;
  }

  static getValue(value: string): TestStatus {
    switch (value) {
      case "DRAFT":
        return TestStatus.Draft;
      case "PROCESSING":
        return TestStatus.Processing;
      case "FINISHED":
        return TestStatus.Finished;
      default:
        return TestStatus.Draft;
    }
  }

  static getFilterOptions() {
    return [
      {
        text: TestStatusHelper.getDescription(TestStatus.Draft),
        value: TestStatus.Draft,
      },
      {
        text: TestStatusHelper.getDescription(TestStatus.Processing),
        value: TestStatus.Processing,
      },
      {
        text: TestStatusHelper.getDescription(TestStatus.Finished),
        value: TestStatus.Finished,
      },
    ];
  }
}
