import {
  AssetImageType,
  AssetType,
  IAssetImageModel,
  IMediaImageModel,
  StreamType,
  TimeHelper,
} from "@bms/common-services";
import { Col, ImageWithPlaceholder, Row, Tag } from "@bms/common-ui";
import cx from "classnames";
import dayjs from "dayjs";
import React from "react";
import { IListItemComponentPreviewProps } from "./ListItemComponentPreview";
import "./ListItemCoverComponentPreview.scss";

export interface IListItemCoverComponentPreviewProps
  extends IListItemComponentPreviewProps {}

export class ListItemCoverComponentPreview extends React.Component<
  IListItemCoverComponentPreviewProps
> {
  public static defaultProps: IListItemCoverComponentPreviewProps = {
    isPlaceholder: false,
    width: 0,
  };

  public renderStatus = () => {
    const { asset } = this.props;

    if (!asset) {
      return null;
    }

    const isLiveBroadcast = asset.AssetTypeCode === AssetType.Live;
    let duration = "";

    switch (asset.AssetTypeCode) {
      case AssetType.Live:
      case AssetType.Channel: {
        if (asset.AvailableFrom) {
          const now = dayjs();
          const availableFrom = dayjs(asset.AvailableFrom);
          /* Some weird tree shaking (?) issue when using dayjs `from` method,
           * which - believe me - exists :P */
          duration = (availableFrom as any).from(now);
        } else {
          duration = TimeHelper.formatDurationMilliseconds(
            asset.DurationMiliseconds
              ? asset.DurationMiliseconds
              : asset.Contents?.find(
                  (c) => c.StreamTypeCode === StreamType.Main
                )?.DurationMiliseconds
          );
        }
        break;
      }
      default:
        duration = TimeHelper.formatDurationMilliseconds(
          asset.DurationMiliseconds
            ? asset.DurationMiliseconds
            : asset.Contents?.find((c) => c.StreamTypeCode === StreamType.Main)
                ?.DurationMiliseconds
        );
    }

    const liveIndicator = isLiveBroadcast && (
      <div className="ListItemCoverComponentPreview-status-online-indicator">
        <Tag color="#f50">Live</Tag>
      </div>
    );
    const durationIndicator = duration && (
      <div className="ListItemCoverComponentPreview-status-duration">
        <Tag color="#2D2D2D">{duration}</Tag>
      </div>
    );

    return (
      <div className="ListItemCoverComponentPreview-status">
        <Row justify="space-between">
          <Col>{liveIndicator}</Col>
          <Col>{durationIndicator}</Col>
        </Row>
      </div>
    );
  };

  public render() {
    const { isHovered, asset, width, placeholderSrc } = this.props;

    let frameImageUrl: string | undefined = "";
    let title: string | undefined = "";
    let description: string | undefined = "";

    if (asset) {
      description = asset.Description;
      title = asset.Title;

      if (asset.Images) {
        const assetFrameImage = asset.Images.find(
          (row: IMediaImageModel) => row.ImageTypeCode === AssetImageType.Frame
        );

        if (assetFrameImage) {
          frameImageUrl = assetFrameImage.Url;
        }
      }
    }

    const appListItemPaddingHorizontal = 20;

    const mainStyle: React.CSSProperties = {
      width: `${width}px`,
    };

    const containerStyle: React.CSSProperties = {
      width: `${width - appListItemPaddingHorizontal}px`,
      border: "2px solid #23232e",
      overflow: "hidden",
    };

    const imageContainerStyle: React.CSSProperties = {
      width: `${width - appListItemPaddingHorizontal}px`,
      height: `${Math.round(
        (4 * (width - appListItemPaddingHorizontal)) / 3
      )}px`,
    };

    return (
      <div
        className={cx("ListItemCoverComponentPreview", {
          "ListItemCoverComponentPreview-active": isHovered,
        })}
        style={mainStyle}
      >
        <div style={containerStyle}>
          <div
            className="ListItemCoverComponentPreview-image-container"
            style={imageContainerStyle}
          >
            <ImageWithPlaceholder
              animated={true}
              imageSrc={frameImageUrl}
              imageContainerClassName="ListItemCoverComponentPreview-image"
              placeholderSrc={placeholderSrc}
            />
            {this.renderStatus()}
          </div>
        </div>
      </div>
    );
  }
}
