import React from "react";
import { useTranslation } from "react-i18next";
import { AssetChildrenList } from "../../AssetChildrenList";
import { ICommonAssetDetailsTabProps } from "./ICommonAssetDetailsTabProps";

export const ProgramsTab = ({ asset }: ICommonAssetDetailsTabProps) => {
  const { t } = useTranslation();
  return (
    <AssetChildrenList
      key={`AssetContent-${asset?.Id}`}
      title={t("ASSET_DETAILS_SECTION_PROGRAMS_LIST")}
      asset={asset}
    />
  );
};
