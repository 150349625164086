import { Observable, Observer } from "rxjs";
import { HTTP_METHOD } from "../../constants";
import { AxiosSubscriber } from "../AxiosSubscriber";
import { PromisifiableBase } from "../Base/PromisifiableBase";
import {
  ICreatorStreamModel,
  ICreatorStreamSearchFilterModel,
  ICreatorStreamListModel,
} from "../../models";

export class AssetStreamsService extends PromisifiableBase {
  get url(): string {
    return "/AssetStreams";
  }

  public getChannelById = (id: number): Observable<ICreatorStreamModel> =>
    new Observable(
      (observer: Observer<ICreatorStreamModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: {
              id,
            },
          },
          method: HTTP_METHOD.GET,
          url: `${this.url}/GetChannelById`,
        })
    );

  public searchChannel = (
    filter: ICreatorStreamSearchFilterModel
  ): Observable<ICreatorStreamListModel> =>
    new Observable(
      (observer: Observer<ICreatorStreamListModel>) =>
        new AxiosSubscriber(observer, {
          data: filter,
          method: HTTP_METHOD.POST,
          url: `${this.url}/SearchChannel`,
        })
    );

  public create = (assetId: number): Observable<number> =>
    new Observable(
      (observer: Observer<number>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: {
              assetId,
            },
          },
          method: HTTP_METHOD.POST,
          url: `${this.url}/Create`,
        })
    );

  public delete = (assetId: number): Observable<void> =>
    new Observable(
      (observer: Observer<void>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: {
              assetId,
            },
          },
          method: HTTP_METHOD.DELETE,
          url: `${this.url}/Delete`,
        })
    );

  public start = (assetId: number): Observable<void> =>
    new Observable(
      (observer: Observer<void>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: {
              assetId,
            },
          },
          method: HTTP_METHOD.POST,
          url: `${this.url}/Start`,
        })
    );

  public stop = (assetId: number): Observable<void> =>
    new Observable(
      (observer: Observer<void>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: {
              assetId,
            },
          },
          method: HTTP_METHOD.POST,
          url: `${this.url}/Stop`,
        })
    );
}
