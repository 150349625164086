import React from "react";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import { isString } from "lodash";
import { UrlHelper } from "../../helpers";
import { ExternalLink } from "..";

export interface ILinkProps extends RouterLinkProps {
  target?: string;
}

export const Link: React.FC<ILinkProps> = (props) => {
  const { children, to, target, ...rest } = props;

  if (isString(to) && UrlHelper.isUrl(to)) {
    return (
      <ExternalLink href={to} target={target} {...rest}>
        {children}
      </ExternalLink>
    );
  }

  return (
    <RouterLink to={to} {...rest}>
      {children}
    </RouterLink>
  );
};
