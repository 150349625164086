import { CellType } from "../enums";

export class CellTypeHelper {
  static getDescription(value: CellType): string {
    switch (value) {
      case "DEFAULT":
        return "Default";
      case "HIGHLIGHTS":
        return "Highlights";
      case "HIGHLIGHTS_WIDESCREEN":
        return "Highlights Widescreen";
      case "HERO_LANDSCAPE":
        return "Hero Landscape";
      case "COVER":
        return "Cover";
      case "FRAME":
        return "Frame";
      case "PROMO":
        return "Promo";
      case "SQUARE":
        return "Square";
      case "ROUND":
        return "Round";
      default:
        return "Undefined";
    }
  }

  static getValue(value: string): CellType {
    switch (value) {
      case "DEFAULT":
        return CellType.Default;
      case "HIGHLIGHTS":
        return CellType.Highlights;
      case "HIGHLIGHTS_WIDESCREEN":
        return CellType.HighlightsWidescreen;
      case "HERO_LANDSCAPE":
        return CellType.HeroLandscape;
      case "COVER":
        return CellType.Cover;
      case "PROMO":
        return CellType.Promo;
      case "FRAME":
        return CellType.Frame;
      case "SQUARE":
        return CellType.Square;
      case "ROUND":
        return CellType.Round;
      default:
        return CellType.Default;
    }
  }

  static getStringValue(value: CellType): string {
    switch (value) {
      case CellType.Default:
        return "DEFAULT";
      case CellType.Highlights:
        return "HIGHLIGHTS";
      case CellType.HighlightsWidescreen:
        return "HIGHLIGHTS_WIDESCREEN";
      case CellType.HeroLandscape:
        return "HERO_LANDSCAPE";
      case CellType.Cover:
        return "COVER";
      case CellType.Frame:
        return "FRAME";
      case CellType.Promo:
        return "PROMO";
      case CellType.Square:
        return "SQUARE";
      case CellType.Round:
        return "ROUND";
      default:
        return "DEFAULT";
    }
  }
}
