import { useTranslation } from "react-i18next";
import {
  Button,
  Icon,
  InputSearch,
  ITableColumnProps,
  ITableFilter,
  PageContent,
  PageHeader,
  Pagination,
  Popconfirm,
  SectionGrid,
  SectionGridItem,
  setTableColumnSearchProps,
  Table,
  Tag,
  TAG_PALETTE,
  TagUpToDate,
  Tooltip,
} from "@bms/common-ui";
import { ITestApplicationVersionModel, TimeHelper } from "@bms/common-services";
import React, { useState } from "react";
import { PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import { useTestApplicationVersions } from "./useTestApplicationVersions";
import { TestApplicationVersionEditForm } from "./TestApplicationVersionEditForm";
import { TestApplicationVersionUploadModal } from "./TestApplicationVersionUploadModal";
import { FilterCleanIcon } from "../../../../../../resources/icons";
import { FormModal } from "../../../../../../components";

interface TestApplicationVersionsTabProps {
  applicationId: number;
}

export const TestApplicationVersionsTab = (
  props: TestApplicationVersionsTabProps
) => {
  const { applicationId } = props;

  const [addModalVisible, setAddModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [selected, setSelected] = useState<ITestApplicationVersionModel>();

  const { t } = useTranslation();

  const {
    isLoading,
    data,
    pagination,
    fullTextSearch,
    setFullTextSearch,
    filters,
    refreshApplicationVersions,
    createApplicationVersion,
    updateApplicationVersion,
    deleteApplicationVersion,
  } = useTestApplicationVersions({ applicationId });

  const showAddModal = () => {
    setAddModalVisible(true);
  };

  const hideAddModal = () => {
    setAddModalVisible(false);
  };

  const onSearch = (value: string) =>
    filters.update((currentFilters) => ({
      ...currentFilters,
      FullTextSearch: value || undefined,
    }));

  const onTableChange = (_: any, incomingFilters: ITableFilter) =>
    filters.update((currentFilters) => ({
      ...currentFilters,
      ...incomingFilters,
    }));

  const getExtraButtons = () => {
    return (
      <>
        <InputSearch
          key="search"
          placeholder={t("SEARCH_PLACEHOLDER")}
          value={fullTextSearch}
          onSearch={onSearch}
          onChange={({ target: { value } }) => setFullTextSearch(value)}
          style={{ width: 250 }}
          allowClear
        />
        <Tooltip key="filters-tooltip" overlay={t("MENU_OPTION_CLEAR_FILTERS")}>
          <Button
            key="clear-filters"
            shape="circle"
            icon={<FilterCleanIcon />}
            onClick={filters.clear}
            disabled={!filters.anyActive}
            title={t("MENU_OPTION_CLEAR_FILTERS")}
          />
        </Tooltip>
        <Tooltip key="reload-tooltip" overlay={t("BUTTON_REFRESH_TITLE")}>
          <Button
            key="reload"
            shape="circle"
            icon={<ReloadOutlined />}
            onClick={refreshApplicationVersions}
            title={t("BUTTON_REFRESH_TITLE")}
          />
        </Tooltip>
        <Tooltip
          key="add-tooltip"
          overlay={t("TESTING_APPLICATIONS_VERSIONS_ADD_BUTTON")}
        >
          <Button
            key="add"
            shape="circle"
            type="primary"
            icon={<PlusOutlined />}
            title={t("TESTING_APPLICATIONS_VERSIONS_ADD_BUTTON")}
            onClick={showAddModal}
          />
        </Tooltip>
      </>
    );
  };

  const getColumnsProps = (): Array<
    ITableColumnProps<ITestApplicationVersionModel>
  > => {
    return [
      {
        key: "Version",
        dataIndex: "Version",
        title: t("TESTING_APPLICATIONS_VERSIONS_VERSION"),
        ellipsis: true,
        align: "right",
        width: "200px",
        filteredValue: filters.asTableArray.Version,
        ...setTableColumnSearchProps(
          "Version",
          t("TESTING_APPLICATIONS_VERSIONS_VERSION")
        ),
        render: (_, applicationVersion) => (
          <Tag color={TAG_PALETTE.GREY}>{applicationVersion.Version}</Tag>
        ),
      },
      {
        key: "Url",
        dataIndex: "Url",
        title: t("TESTING_APPLICATIONS_VERSIONS_URL"),
        render: (_, applicationVersion) => {
          return (
            <a
              title={applicationVersion.Url}
              onClick={() => {
                setEditModalVisible(true);
                setSelected(applicationVersion);
              }}
            >
              {applicationVersion.Url}
            </a>
          );
        },
      },
      {
        key: "Created",
        dataIndex: "Created",
        title: t("MODEL_CREATION_DATE"),
        align: "center",
        width: "180px",
        render: (creationDate: Date) => TimeHelper.format(creationDate),
      },
      {
        key: "UpToDate",
        dataIndex: "UpToDate",
        title: t("MODEL_UP_TO_DATE"),
        align: "center",
        width: "140px",
        filters: [
          { text: t("CLASS_LIST_TABLE_UPTODATE_COLUMN_FILTER"), value: "true" },
          {
            text: t("CLASS_LIST_TABLE_OUTTODATE_COLUMN_FILTER"),
            value: "false",
          },
        ],
        filteredValue: filters.asTableArray.UpToDate,
        render: (upToDate: boolean) => <TagUpToDate value={upToDate} />,
      },
      {
        key: "Actions",
        dataIndex: "Actions",
        title: t("TABLE_ACTIONS_COLUMN"),
        align: "center",
        width: "100px",
        render: (_: any, applicationVersion: ITestApplicationVersionModel) => (
          <Popconfirm
            title={t("DELETE_ELEMENT_DOUBLE_CONFIRMATION_QUESTION")}
            onConfirm={async (event?: React.MouseEvent<HTMLElement>) => {
              event?.preventDefault();
              await deleteApplicationVersion(applicationVersion);
            }}
            okText={t("BUTTON_YES")}
            cancelText={t("BUTTON_NO")}
          >
            <Button
              danger={true}
              icon={<Icon type="delete" />}
              title={t("DELETE_ELEMENT")}
            />
          </Popconfirm>
        ),
      },
    ];
  };

  return (
    <SectionGrid>
      <SectionGridItem>
        <PageContent footer={<Pagination {...pagination.props} />}>
          <PageHeader extra={getExtraButtons()} />
          <Table<ITestApplicationVersionModel>
            rowKey="Id"
            columns={getColumnsProps()}
            dataSource={data?.Entities}
            loading={isLoading}
            pagination={false}
            onChange={onTableChange}
          />
          <TestApplicationVersionUploadModal
            applicationId={applicationId}
            visible={addModalVisible}
            createApplicationVersion={createApplicationVersion}
            onSuccess={hideAddModal}
            onCancel={hideAddModal}
          />
          <FormModal
            isLoading={false}
            isVisible={editModalVisible}
            isNewForm={false}
            createFormTitle={""}
            editFormTitle={t("TESTING_APPLICATIONS_VERSIONS_EDIT_HEADER")}
            modalClassName="TestApplicationVersionEditModal"
            submitFormName="TestApplicationVersionEditForm"
            onCloseModal={() => setEditModalVisible(false)}
            isDeleteButtonEnabled={false}
          >
            {selected && (
              <TestApplicationVersionEditForm
                applicationVersion={selected}
                setVisibilityStatus={setEditModalVisible}
                updateApplicationVersion={updateApplicationVersion}
                isLoading={isLoading}
              />
            )}
          </FormModal>
        </PageContent>
      </SectionGridItem>
    </SectionGrid>
  );
};
