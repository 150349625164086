import React from "react";
import {
  ActionType,
  ActionTypeHelper as CommonActionTypeHelper,
  ComponentType,
} from "@bms/common-services";
import { ChooseOption } from "@bms/common-ui";

export class ActionTypeHelper extends CommonActionTypeHelper {
  static getOptions(componentType?: ComponentType): React.ReactElement[] {
    switch (componentType) {
      case ComponentType.SectionMenuItem:
        return [
          <ChooseOption key="OPEN_SCREEN" value={ActionType.OpenScreen}>
            {ActionTypeHelper.getDescription(ActionType.OpenScreen)}
          </ChooseOption>,
        ];
      case ComponentType.ApplicationFooterItem:
        return [
          <ChooseOption key="OPEN_SCREEN" value={ActionType.OpenScreen}>
            {ActionTypeHelper.getDescription(ActionType.OpenScreen)}
          </ChooseOption>,
          <ChooseOption key="OPEN_URL" value={ActionType.OpenUrl}>
            {ActionTypeHelper.getDescription(ActionType.OpenUrl)}
          </ChooseOption>,
          <ChooseOption key="CALL_TO" value={ActionType.CallTo}>
            {ActionTypeHelper.getDescription(ActionType.CallTo)}
          </ChooseOption>,
          <ChooseOption key="EMAIL_TO" value={ActionType.EmailTo}>
            {ActionTypeHelper.getDescription(ActionType.EmailTo)}
          </ChooseOption>,
          <ChooseOption key="LANGUAGE_SELECT" value={ActionType.LanguageSelect}>
            {ActionTypeHelper.getDescription(ActionType.LanguageSelect)}
          </ChooseOption>,
        ];
      case ComponentType.SettingsMenuItem:
        return [
          <ChooseOption key="OPEN_SCREEN" value={ActionType.OpenScreen}>
            {ActionTypeHelper.getDescription(ActionType.OpenScreen)}
          </ChooseOption>,
          <ChooseOption key="OPEN_URL" value={ActionType.OpenUrl}>
            {ActionTypeHelper.getDescription(ActionType.OpenUrl)}
          </ChooseOption>,
        ];
      default:
        return [
          <ChooseOption key="OPEN_SCREEN" value={ActionType.OpenScreen}>
            {ActionTypeHelper.getDescription(ActionType.OpenScreen)}
          </ChooseOption>,
          <ChooseOption key="OPEN_URL" value={ActionType.OpenUrl}>
            {ActionTypeHelper.getDescription(ActionType.OpenUrl)}
          </ChooseOption>,
          <ChooseOption key="CALL_TO" value={ActionType.CallTo}>
            {ActionTypeHelper.getDescription(ActionType.CallTo)}
          </ChooseOption>,
          <ChooseOption key="EMAIL_TO" value={ActionType.EmailTo}>
            {ActionTypeHelper.getDescription(ActionType.EmailTo)}
          </ChooseOption>,
        ];
    }
  }
}
