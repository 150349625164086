import React, { memo, useEffect, useMemo, useState } from "react";
import {
  Card,
  IRadioProps,
  RadioGroup,
  Spin,
  StackedBarChart2,
  StackedBarChart2Control,
  Switch,
  Text,
  TStackedBarChart2Options,
} from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import {
  AnalyticsAggregationPeriodType,
  IAnalyticsUserPlatformRatingFilterModel,
  IAnalyticsUserPlatformRatingModel,
} from "@bms/common-services";
import { prepareChartData, TGroupBy, TUnit } from "./utils";
import { IRatingAppWithColorsModel } from "../RatingApp";

import "./RatingAppChart.scss";

interface IRatingAppChartProps {
  data?: IAnalyticsUserPlatformRatingModel;
  isLoading?: boolean;
  ratingsListWithColors?: IRatingAppWithColorsModel[] | undefined;
  filter: IAnalyticsUserPlatformRatingFilterModel;
  aggregationPeriod?: AnalyticsAggregationPeriodType;
}

export const RatingAppChart = memo(
  ({
    data: appRatingData,
    ratingsListWithColors,
    isLoading = false,
    filter,
    aggregationPeriod = AnalyticsAggregationPeriodType.YEAR,
  }: IRatingAppChartProps) => {
    const [groupBy, setGroupBy] = useState<TGroupBy>("platform");
    const [unit, setUnit] = useState<TUnit>("count");
    const [axisReversed, setAxisReversed] = useState(true);
    const { t } = useTranslation();

    const chartOption: TStackedBarChart2Options = useMemo(() => {
      return {
        indexAxis: axisReversed ? "y" : "x",
        scales: {
          x: {
            stacked: false,
          },
          y: {
            stacked: false,
            ticks: {
              min: 0,
              stepSize: axisReversed ? 1 : unit === "count" ? 1 : 0.5,
            },
          },
        },
      };
    }, [aggregationPeriod, axisReversed, unit]);

    const groupByOptions: IRadioProps[] = useMemo(() => {
      const options = [
        {
          value: "platform",
          name: t("RATING_APP_CHART_GROUP_BY_PLATFORM"),
        },
      ];

      return options;
    }, [filter]);

    const resetChart = () => {
      setUnit("count");
      setGroupBy("platform");
      setAxisReversed(true);
    };

    useEffect(() => {
      if (Object.keys(filter).length === 0) {
        resetChart();
      }
    }, [filter]);

    const data = useMemo(
      () =>
        appRatingData &&
        prepareChartData(
          appRatingData,
          unit,
          ratingsListWithColors,
          aggregationPeriod
        ),
      [appRatingData, aggregationPeriod, groupBy, unit]
    );

    return (
      <Card className="RatingAppChart">
        {isLoading && <Spin size="large" className="RatingAppChart__spin" />}
        {!isLoading &&
          (!data || data?.datasets.length < 1 ? (
            <Text className="RatingAppChart__no-data-text">
              {t("RATING_APP_NO_CHART_DATA")}
            </Text>
          ) : (
            <StackedBarChart2
              title={t("RATING_APP_CHART_TITLE")}
              data={data}
              options={chartOption}
              showToolbar
              customToolbarControls={[
                <StackedBarChart2Control
                  key="rating-app-chart--subject-control"
                  label={t("RATING_APP_CHART_SUBJECT")}
                >
                  <RadioGroup
                    data={[
                      {
                        value: "count",
                        name: t("RATING_APP_CHART_SUBJECT_COUNT"),
                      },
                      {
                        value: "rating",
                        name: t("RATING_APP_CHART_SUBJECT_RATING"),
                      },
                    ]}
                    value={unit}
                    onChange={(e) => {
                      e.preventDefault();
                      setUnit(e.target.value);
                    }}
                  />
                </StackedBarChart2Control>,
                <StackedBarChart2Control
                  key="rating-app-chart--reverse-axis-control"
                  label={t("RATING_APP_CHART_REVERSE_AXIS")}
                >
                  <Switch checked={axisReversed} onChange={setAxisReversed} />
                </StackedBarChart2Control>,
                <StackedBarChart2Control
                  key="rating-app-chart--group-by-control"
                  label={t("RATING_APP_CHART_GROUP_BY")}
                >
                  <RadioGroup
                    data={groupByOptions}
                    value={groupBy}
                    onChange={(e) => {
                      e.preventDefault();
                      setGroupBy(e.target.value);
                    }}
                  />
                </StackedBarChart2Control>,
              ]}
            />
          ))}
      </Card>
    );
  }
);
