/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { PlayListSourceType } from "../enums";

export class PlayListSourceTypeHelper {
  static getDescription(value?: PlayListSourceType): string {
    switch (value) {
      case "MY_FAVORITES":
        return "My favorites";
      case "RECENTLY_WATCHED":
        return "Recently watched";
      case "PURCHASED":
        return "Purchased";
      case "RECOMMENDATIONS":
        return "Recommendations";
      case "SEARCH":
        return "Search";
      case "STANDARD":
      default:
        return "Standard";
    }
  }

  static getValue(value: string): PlayListSourceType {
    switch (value) {
      case "MY_FAVORITES":
        return PlayListSourceType.MyFavorites;
      case "RECENTLY_WATCHED":
        return PlayListSourceType.RecentlyWatched;
      case "PURCHASED":
        return PlayListSourceType.Purchased;
      case "SEARCH":
        return PlayListSourceType.Search;
      case "RECOMMENDATIONS":
        return PlayListSourceType.Recommendations;
      default:
        return PlayListSourceType.Standard;
    }
  }
}
