import {
  AssetService,
  IAssetTypeModel,
  useDataLoader,
  useServiceCaller,
} from "@bms/common-services";
import {
  IBreadcrumbProps,
  ITableColumnProps,
  PageContent,
  PageHeader,
  TableWithDraggableSorter,
  useAppFeedback,
} from "@bms/common-ui";
import { generateBreadcrumb } from "../../../../helpers";
import React from "react";
import { useTranslation } from "react-i18next";
import { ROUTES } from "../../constants";

const assetService = new AssetService().promisify();

export const DictionaryAssetMediaTypeList = () => {
  const { t } = useTranslation();
  const { notification } = useAppFeedback();

  const assetTypesLoader = useDataLoader({
    loader: () => assetService.getAssetTypes(),
    deps: [],
  });

  const getColumnsProps = (): Array<ITableColumnProps<IAssetTypeModel>> => {
    return [
      {
        key: "Code",
        dataIndex: "Code",
        title: t("MODEL_CODE"),
      },
      {
        key: "DisplayName",
        dataIndex: "DisplayName",
        title: t("MODEL_NAME"),
      },
      {
        key: "TranslationKey",
        dataIndex: "TranslationKey",
        title: t("MODEL_TRANSLATION_KEY"),
      },
      {
        key: "Sequence",
        dataIndex: "Sequence",
        title: t("MODEL_SEQUENCE"),
      },
    ];
  };

  const [updateAssetMediaType] = useServiceCaller(
    async (assetMediaType: IAssetTypeModel) => {
      const result = await assetService.updateAssetTypes({
        ...assetMediaType,
      });
      if (result.ok) {
        notification.success({
          message: t("DICTIONARY_ASSET_TYPE_UPDATE_SUCCESS"),
        });
        await assetTypesLoader.refresh();
      } else {
        notification.error({
          message: t("DICTIONARY_ASSET_TYPE_UPDATE_FAILURE"),
          description: result.error?.Message,
        });
      }
    },
    []
  );

  const onMoveRow = (dragIndex: number, hoverIndex: number) => {
    const draggedAssetType = assetTypesLoader.data?.[dragIndex];
    const hoveredAssetType = assetTypesLoader.data?.[hoverIndex];

    if (!draggedAssetType || !hoveredAssetType) {
      return;
    }

    const assetToUpdate: IAssetTypeModel = {
      ...draggedAssetType,
      Sequence: hoveredAssetType.Sequence ?? 1,
    };

    const sequenceChanged =
      draggedAssetType.Sequence !== assetToUpdate.Sequence;

    if (sequenceChanged) {
      updateAssetMediaType(assetToUpdate);
    }
  };

  const getBreadcrumbProps = (): IBreadcrumbProps => {
    return generateBreadcrumb([
      {
        path: `${ROUTES.DICTIONARY_BASE}`,
        breadcrumbName: t("MENU_ADMINISTRATION_DICTIONARIES"),
      },
      {
        path: `${ROUTES.DICTIONARY_MEDIA_TYPE_LIST}`,
        breadcrumbName: t("DICTIONARY_ASSET_TYPE_TITLE"),
      },
    ]);
  };

  return (
    <div>
      <PageContent>
        <PageHeader
          title={t("DICTIONARY_ASSET_TYPE_TITLE")}
          breadcrumb={getBreadcrumbProps()}
        />
        <TableWithDraggableSorter<IAssetTypeModel>
          columns={getColumnsProps()}
          dragType="handler"
          dataSource={assetTypesLoader.data}
          pagination={false}
          onMoveRow={onMoveRow}
        />
      </PageContent>
    </div>
  );
};
