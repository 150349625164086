/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { IDataProvider } from "./IDataProvider";
import { InternalDataProvider } from "./Internal";
import { ConvertHelper } from "../../helpers/convert.helper";
import { JWPlayerDataProvider } from "./JWPlayer";
import { AxinomDataProvider } from "./Axinom";

const provider = ConvertHelper.toString(process.env.REACT_APP_DATA_PROVIDER);

let dataProvider: IDataProvider;

switch (provider) {
  case "JWPLAYER":
    dataProvider = new JWPlayerDataProvider();
    break;
  case "AXINOM":
    dataProvider = new AxinomDataProvider();
    break;
  default:
    dataProvider = new InternalDataProvider();
    break;
}

export const DataProvider: IDataProvider = dataProvider;
