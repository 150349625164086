import { ElevationLevel } from "../enums";

export class ElevationLevelHelper {
  static getDescription(value: ElevationLevel): string {
    switch (value) {
      case "SMALL":
        return "Small";
      case "MEDIUM":
        return "Medium";
      case "LARGE":
        return "Large";
      default:
        return "Undefined";
    }
  }

  static getValue(value: string | undefined): ElevationLevel | undefined {
    switch (value) {
      case "SMALL":
        return ElevationLevel.Small;
      case "MEDIUM":
        return ElevationLevel.Medium;
      case "LARGE":
        return ElevationLevel.Large;
      default:
        return undefined;
    }
  }

  static getStringValue(value: ElevationLevel): string {
    switch (value) {
      case ElevationLevel.Small:
        return "SMALL";
      case ElevationLevel.Medium:
        return "MEDIUM";
      case ElevationLevel.Large:
        return "LARGE";
    }
  }
}
