import { useTranslation } from "react-i18next";
import {
  ITestDefinitionModel,
  TestDefinitionService,
  useServiceCaller,
} from "@bms/common-services";
import { useTableDataProvider } from "../../../../helpers";
import { useHistory } from "react-router";
import { ROUTES } from "../../constants";
import { useAppFeedback } from "@bms/common-ui";

const testDefinitionService = new TestDefinitionService().promisify();

export const useTestDefinitions = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { notification } = useAppFeedback();

  const {
    dataLoader,
    filters,
    pagination,
    fullTextSearch,
    setFullTextSearch,
  } = useTableDataProvider({
    filtersSchema: {
      FullTextSearch: "string",
      Name: "string",
      Description: "string",
      Platforms: "strings",
    },
    loader: (filters, pagination) =>
      testDefinitionService.search({
        ...filters,
        ...pagination,
      }),
    deps: [],
    onError: (error) =>
      notification.error({
        message: t("LOADING_DATA_ERROR_MESSAGE"),
        description: error.Message,
      }),
  });

  const [
    createTestDefinition,
    { processing: createProcessing },
  ] = useServiceCaller(async (testDefinition: ITestDefinitionModel) => {
    const result = await testDefinitionService.insert(testDefinition);

    if (result.ok) {
      dataLoader.refresh();

      notification.success({
        message: t("TESTING_TEST_DEFINITIONS_CREATE_SUCCESS"),
      });

      history.push(`${ROUTES.TEST_DEFINITION_DETAILS}/${result.data.Id}`);
    } else {
      notification.error({
        message: t("TESTING_TEST_DEFINITIONS_CREATE_FAILURE"),
        description: result.error?.Message,
      });
    }

    return result.ok;
  }, []);

  const [
    deleteTestDefinition,
    { processing: deleteProcessing },
  ] = useServiceCaller(async (testDefinition: ITestDefinitionModel) => {
    const result = await testDefinitionService.delete(testDefinition);

    if (result.ok) {
      dataLoader.refresh();

      notification.success({
        message: t("TESTING_TEST_DEFINITIONS_DELETE_SUCCESS"),
      });
    } else {
      notification.error({
        message: t("TESTING_TEST_DEFINITIONS_DELETE_ERROR"),
        description: result.error?.Message,
      });
    }
    return result.ok;
  }, []);

  const isLoading = dataLoader.loading || deleteProcessing || createProcessing;

  return {
    isLoading,
    data: dataLoader.data,
    refreshTestDefinitions: dataLoader.refresh,
    createTestDefinition,
    deleteTestDefinition,
    filters,
    pagination,
    fullTextSearch,
    setFullTextSearch,
  };
};
