import { SimpleServiceBase } from "../Base";
import {
  ITestApplicationVersionModel,
  ITestApplicationVersionsFilterModel,
  ITestApplicationVersionsListModel,
  ITestApplicationVersionUploadFileOptions,
  UploadFileInfoModel,
} from "../../models";
import { Observable, Observer } from "rxjs";
import { AxiosSubscriber } from "../AxiosSubscriber";
import { HTTP_METHOD } from "../../constants";

export class TestApplicationVersionsService extends SimpleServiceBase<
  ITestApplicationVersionModel,
  ITestApplicationVersionsListModel,
  number,
  ITestApplicationVersionsFilterModel
> {
  get url() {
    return "/TestApplicationVersions";
  }

  public getUploadFileInfo = (
    data: ITestApplicationVersionUploadFileOptions
  ): Observable<UploadFileInfoModel> =>
    new Observable(
      (observer: Observer<UploadFileInfoModel>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.POST,
          url: `${this.url}/GetUploadFileInfo`,
          data,
        })
    );
}
