import { CommonStore, dispatch, ICommonAppState } from "@bms/common-services";
import { useSelector } from "react-redux";
import { useCallback, useEffect } from "react";

export const useTestingPlatforms = () => {
  const platforms = useSelector(
    (state: ICommonAppState) => state.common.platforms
  ).filter((platform) => platform.Code !== "ANY");

  const selectPlatforms = useCallback(
    () => dispatch(CommonStore.Actions.selectPlatforms()),
    [dispatch]
  );

  useEffect(() => {
    if (platforms?.length === 0) {
      selectPlatforms();
    }
  }, []);

  return { platforms };
};
