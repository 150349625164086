import {
  IDataLoader,
  IErrorModel,
  IListModelBase,
  IUseDataLoaderResult,
  useDataLoader,
} from "@bms/common-services";
import {
  ICurrentFilters,
  ICurrentPagination,
  IFiltersSchema,
  useSearchParamsFilters,
  useSearchParamsPagination,
  useSyncedState,
} from "@bms/common-ui";
import { useEffect, useState } from "react";

export function useTableDataProvider<
  D extends IListModelBase,
  F extends IFiltersSchema,
  E = IErrorModel
>({
  loader,
  deps,
  filtersSchema,
  debounce,
  onError,
}: {
  loader: (
    filters: ICurrentFilters<F>,
    pagination: ICurrentPagination
  ) => ReturnType<IDataLoader<D, E>>;
  deps: any[];
  filtersSchema: F;
  debounce?: number,
  onError?: (error: E) => void;
}) {
  const [totalCount, setTotalCount] = useState<number | undefined>(undefined);
  const pagination = useSearchParamsPagination(totalCount);

  const filters = useSearchParamsFilters(filtersSchema);

  const [fullTextSearch, setFullTextSearch] = useSyncedState<string>(
    () => filters.current.FullTextSearch?.toString() ?? "",
    [filters.current.FullTextSearch]
  );

  const dataLoader: IUseDataLoaderResult<D, E> = useDataLoader<D, E>({
    loader: () => loader(filters.current, pagination.current),
    debounce,
    deps: [...deps, filters.current, pagination.current],
    onError,
  });

  useEffect(() => {
    setTotalCount(dataLoader?.data?.TotalCount);
  }, [dataLoader?.data?.TotalCount]);

  return { dataLoader, filters, pagination, fullTextSearch, setFullTextSearch };
}
