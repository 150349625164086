import { IApplicationComponentTypeModel } from "@bms/common-services";
import {
  IBreadcrumbProps,
  ITableColumnProps,
  ITableFilter,
  ITablePaginationConfig,
  PageContent,
  PageHeader,
  Table,
} from "@bms/common-ui";
import { ROUTES } from "../../constants";
import { generateBreadcrumb } from "../../../../helpers";
import React from "react";
import { WithTranslation } from "react-i18next";
import { Action, ActionCreator } from "redux";
import "./ApplicationComponentsTypesList.scss";

export interface IApplicationComponentsTypesListProps extends WithTranslation {
  componentsTypes: IApplicationComponentTypeModel[];
  isLoadingData: boolean;
  selectComponentsTypes: ActionCreator<Action>;
}

interface IApplicationComponentsTypesListState {
  filters?: ITableFilter;
}

export class ApplicationComponentsTypesList extends React.PureComponent<
  IApplicationComponentsTypesListProps,
  IApplicationComponentsTypesListState
> {
  public state: Readonly<IApplicationComponentsTypesListState> = {};

  public componentDidMount() {
    const { selectComponentsTypes } = this.props;

    selectComponentsTypes();
  }

  private getColumnsProps(): Array<
    ITableColumnProps<IApplicationComponentTypeModel>
  > {
    const { t } = this.props;
    const { filters } = this.state;

    return [
      {
        key: "Code",
        dataIndex: "Code",
        title: t("MODEL_CODE"),
        filteredValue: filters?.Code,
        onFilter: (value, record) => record.Code.includes(`${value}`),
      },
      {
        key: "Name",
        dataIndex: "Name",
        title: t("MODEL_NAME"),
        filteredValue: filters?.Name,
        onFilter: (value, record) => record.Name.includes(`${value}`),
      },
      {
        key: "Description",
        dataIndex: "Description",
        title: t("MODEL_DESCRIPTION"),
        filteredValue: filters?.Description,
        onFilter: (value, record) =>
          record.Description?.includes(`${value}`) || false,
        ellipsis: true,
      },
    ];
  }

  public onTableChange = (
    pagination: ITablePaginationConfig,
    filters: ITableFilter
  ) => {
    this.setState({ filters });
  };

  public getBreadcrumbProps(): IBreadcrumbProps {
    const { t } = this.props;
    return generateBreadcrumb([
      {
        path: `${ROUTES.CONFIGURATION_BASE}`,
        breadcrumbName: t("MENU_ADMINISTRATION_DICTIONARIES"),
      },
      {
        path: `${ROUTES.CONFIGURATION_COMPONENT_TYPES_LIST}`,
        breadcrumbName: t("MENU_ADMINISTRATION_DICTIONARIES_COMPONENT_TYPES"),
      },
    ]);
  }

  public render() {
    const { componentsTypes, isLoadingData, t } = this.props;

    const columns = this.getColumnsProps();

    return (
      <PageContent>
        <PageHeader
          title={t("MENU_ADMINISTRATION_DICTIONARIES_COMPONENT_TYPES")}
          breadcrumb={this.getBreadcrumbProps()}
        />
        <Table<IApplicationComponentTypeModel>
          rowKey={(row) => row.Code}
          columns={columns}
          dataSource={componentsTypes}
          loading={isLoadingData}
          pagination={false}
          onChange={this.onTableChange}
        />
      </PageContent>
    );
  }
}
