import { TFunction } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import { FormFieldRule, IFormInstance, FormFieldRuleObject } from "../types";
import dayjs from "dayjs";

import { REGEX } from "../../../";

interface IValidatorParams {
  choiceField?: boolean;
  message?: string;
}

const isUnwrappedForm = (
  form: React.RefObject<IFormInstance> | IFormInstance
): form is IFormInstance =>
  (form as IFormInstance).getFieldsValue !== undefined;

const makeValidators = (t: TFunction) => ({
  phone: (): FormFieldRule[] => [
    {
      message: t("PHONE_VALIDATION_MESSAGE", "The phone number is not valid"),
      pattern: REGEX.PHONE,
    },
    {
      message: t(
        "PHONE_MIN_LENGTH_VALIDATION_MESSAGE",
        "Phone number should be at least 9 characters long"
      ),
      min: 9,
    },
    {
      message: t(
        "PHONE_MAX_LENGTH_VALIDATION_MESSAGE",
        "Phone number should be maximum 15 characters long"
      ),
      max: 15,
    },
  ],

  internationalPhone: (): FormFieldRule[] => [
    {
      message: t("PHONE_COUNTRYCODE_VALIDATION_MESSAGE", "Country code with '+' sign is required (e.g. +48)"),
      pattern: REGEX.COUNTRYCODE,
    },
  ],

  isBefore: (
    form: React.RefObject<IFormInstance> | IFormInstance,
    startDate: string,
    endDate: string,
    messageKey: string
  ): FormFieldRule[] => [
    {
      validator: (_: FormFieldRuleObject, value: string) => {
        const normalizedForm = isUnwrappedForm(form) ? form : form.current;
        if (!value || !form || !normalizedForm?.getFieldValue(endDate)) {
          return Promise.resolve();
        }

        return dayjs(
          new Date(normalizedForm?.getFieldValue(startDate)).toString()
        ).isBefore(
          dayjs(new Date(normalizedForm?.getFieldValue(endDate)).toString())
        )
          ? Promise.resolve()
          : Promise.reject(t(messageKey));
      },
    },
  ],

  isAfter: (
    form: React.RefObject<IFormInstance> | IFormInstance,
    startDate: string,
    endDate: string,
    messageKey: string
  ): FormFieldRule[] => [
    {
      validator: (_: FormFieldRuleObject, value: string) => {
        const normalizedForm = isUnwrappedForm(form) ? form : form.current;
        if (!value || !form || !normalizedForm?.getFieldValue(startDate)) {
          return Promise.resolve();
        }

        return dayjs(
          new Date(normalizedForm?.getFieldValue(endDate)).toString()
        ).isAfter(
          dayjs(new Date(normalizedForm?.getFieldValue(startDate)).toString())
        )
          ? Promise.resolve()
          : Promise.reject(t(messageKey));
      },
    },
  ],

  required: ({
    message,
    choiceField,
  }: IValidatorParams = {}): FormFieldRule[] => [
    {
      required: true,
      // Workaround for Choose field validation crashing against any value of whitespace prop
      ...(choiceField ? {} : { whitespace: true }),
      message:
        message || t("REQUIRED_VALIDATION_MESSAGE", "This field is required"),
    },
  ],

  password: (): FormFieldRule[] => [
    {
      message: t(
        "PASSWORD_LENGTH_VALIDATION_MESSAGE",
        "The password should be at least 8 characters long"
      ),
      min: 8,
    },
    {
      message: t(
        "PASSWORD_LETTERS_VALIDATION_MESSAGE",
        "The password must contain at least one uppercase letter, one lowercase letter, one number and one special character."
      ),
      pattern: REGEX.PASSWORD,
    },
    {
      message: t(
        "PASSWORD_WHITESPACE_VALIDATION_MESSAGE",
        "Valid password doesn't contain whitespace characters."
      ),
      pattern: /^\S*$/,
    },
  ],

  passwordConfirm: (
    form: React.RefObject<IFormInstance> | IFormInstance,
    fieldName = "Password"
  ): FormFieldRule[] => [
    {
      validator: async (_, value) => {
        if (!form) {
          return;
        }
        const normalizedForm = isUnwrappedForm(form) ? form : form.current;
        if (!normalizedForm) {
          throw t("PASSWORD_CONFIRM_VALIDATION_ERROR");
        }

        if (value && value !== normalizedForm.getFieldValue(fieldName)) {
          throw t(
            "PASSWORD_CONFIRM_VALIDATION_MESSAGE",
            "Two passwords that you enter is inconsistent"
          );
        }
      },
    },
  ],

  email: (): FormFieldRule[] => [
    {
      message: t("EMAIL_VALIDATION_MESSAGE", "The input is not valid E-mail"),
      type: "email",
    },
  ],

  positiveInteger: (): FormFieldRule[] => [
    {
      pattern: REGEX.POSITIVE_INTEGER,
      message: t("POSITIVE_INTEGER_VALIDATION_MESSAGE"),
    },
  ],

  fullName: (): FormFieldRule[] => [
    {
      message: t(
        "FULLNAME_LENGTH_VALIDATION_MESSAGE",
        "The full name should be at least 3 characters long"
      ),
      pattern: REGEX.FULL_NAME,
    }
  ],
});

export function useValidate() {
  const [t] = useTranslation();

  return React.useMemo(() => makeValidators(t), [t]);
}
