import React from "react";
import {
  ComponentType,
  ComponentTypeHelper as CommonComponentTypeHelper,
} from "@bms/common-services";
import { Tag } from "@bms/common-ui";

export class ComponentTypeHelper extends CommonComponentTypeHelper {
  static getTag(
    value: ComponentType,
    style?: React.CSSProperties
  ): React.ReactElement {
    const color = ComponentTypeHelper.getColor(value);
    const description = ComponentTypeHelper.getDescription(value);

    return (
      <Tag color={color} style={style}>
        {description}
      </Tag>
    );
  }
}
