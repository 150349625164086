import React from "react";
import { Form, Input, required } from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { IAssetFormFieldCommonProps } from "./IAssetFormFieldCommonProps";
import { CUSTOM_METADATA_FILEDS } from "../AssetFormUtils";

interface ITitleFieldProps extends IAssetFormFieldCommonProps {}

export const AdUrlField = ({
  isHidden,
  isEditMode,
  asset,
}: ITitleFieldProps) => {
  const { t } = useTranslation();
  if (isHidden) {
    return null;
  }

  return (
    <Form.Item
      key={CUSTOM_METADATA_FILEDS.AD_URL}
      name={CUSTOM_METADATA_FILEDS.AD_URL}
      label={t("MODEL_AD_URL")}
      initialValue={isEditMode ? asset?.CustomMetadata?.AdUrl || "" : ""}
    >
      <Input placeholder={t("MODEL_AD_URL_PLACEHOLDER")} />
    </Form.Item>
  );
};
