import React from "react";
import {
  ComponentType,
  IApplicationScreenModel,
  PlatformType,
} from "@bms/common-services";
import {
  ApplicationRegisterPropertyModel,
  LayoutOptionsModel,
} from "../../models";

import contentWebUrl from "./resources/register-content-web.png";

export interface IApplicationRegisterPreviewProps {
  screen: IApplicationScreenModel;
  layoutOptions: LayoutOptionsModel;
  children?: React.ReactNode;
}

export const ApplicationRegisterPreview: React.FC<IApplicationRegisterPreviewProps> = ({
  screen,
  layoutOptions,
  children,
}: IApplicationRegisterPreviewProps) => {
  const component = screen.Components?.find(
    (component) =>
      component.ComponentTypeCode === ComponentType.ScreenProperties
  );
  const properties = new ApplicationRegisterPropertyModel(component);

  const style: React.CSSProperties = {
    width: "100%",
    height: "100%",
  };

  let contentView: React.ReactNode;

  if (
    layoutOptions.Platform === PlatformType.Web ||
    layoutOptions.Platform === PlatformType.Any
  ) {
    if (properties.BackgroundAbsoluteUrl) {
      style.backgroundImage = `url(${properties.BackgroundAbsoluteUrl})`;
      style.backgroundRepeat = "no-repeat";
      style.backgroundSize = "cover";
    }

    contentView = (
      <img
        style={{
          position: "absolute",
          objectFit: "cover",
          width: "100%",
          height: "100%",
        }}
        alt={"content"}
        src={contentWebUrl}
      />
    );
  }

  return (
    <div style={style}>
      {contentView}
      {children}
    </div>
  );
};
