import React from "react";
import { Row } from "../Row";
import "./CardGrid.scss";
import { CardGridContext } from "./CardGridContext";

interface CardGridProps {
  style?: React.CSSProperties;
  cardWidth?: number;
  cardHeight?: number;
  children?: React.ReactNode;
}

export const CardGrid: React.FC<CardGridProps> = ({
  children,
  cardHeight = 300,
  cardWidth = 250,
}) => (
  <Row gutter={[16, 16]}>
    <CardGridContext.Provider value={{ cardHeight, cardWidth }}>
      {children}
    </CardGridContext.Provider>
  </Row>
);
