import React from "react";
import { Divider as AntDivider } from "antd";
import cx from "classnames";
import { DividerProps } from ".";
import "./Divider.scss";

export const Divider: React.FC<DividerProps> = (props) => {
  const { className, color, ...rest } = props;
  const dividerClassName = cx(className, "gm-divider", {
    [`gm-divider-${color}`]: color,
  });
  return <AntDivider className={dividerClassName} {...rest} />;
};
