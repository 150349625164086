import { ICommonAppState } from "@bms/common-services";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
  ApplicationFooterProperties as ApplicationFooterPropertiesDefinition,
  ApplicationFooterPropertiesDispatchProps,
  ApplicationFooterPropertiesOwnProps,
  ApplicationFooterPropertiesStateProps,
} from "./ApplicationFooterProperties";

const mapStateToProps = (
  state: ICommonAppState
): ApplicationFooterPropertiesStateProps => {
  return {
    isProcessingData: state.applicationConfiguration.isProcessingData,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch
): ApplicationFooterPropertiesDispatchProps => ({});

export const ApplicationFooterProperties = connect<
  ApplicationFooterPropertiesStateProps,
  ApplicationFooterPropertiesDispatchProps,
  ApplicationFooterPropertiesOwnProps,
  ICommonAppState
>(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ApplicationFooterPropertiesDefinition));
