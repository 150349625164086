import React from "react";
import { AssetType, IAssetModel } from "@bms/common-services";
import { Form, InputNumber } from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { IAssetFormFieldCommonProps } from "./IAssetFormFieldCommonProps";

interface IOrderInParentFieldProps extends IAssetFormFieldCommonProps {
  parentId?: number;
  parentAssetsCount: number;
}

export const OrderInParentField = ({
  isEditMode,
  isHidden,
  asset,
  parentId,
  parentAssetsCount = 0,
}: IOrderInParentFieldProps) => {
  const { t } = useTranslation();
  if (isHidden || (isEditMode && !asset?.ParentAssetId)) {
    return null;
  }

  const isRequired = parentId ? true : false;
  const orderInParent = asset
    ? asset.OrderInParent
    : parentId
    ? parentAssetsCount + 1
    : undefined;

  return (
    <Form.Item
      name="OrderInParent"
      label={t("MODEL_ORDER_IN_PARENT")}
      key="OrderInParent"
      initialValue={orderInParent}
      rules={[{ required: isRequired }]}
    >
      <InputNumber
        disabled={!parentId}
        min={1}
        placeholder={t("MODEL_ORDER_IN_PARENT_PLACEHOLDER")}
      />
    </Form.Item>
  );
};
