import cx from "classnames";
import React from "react";
import { WithTranslation } from "react-i18next";
import { WithScreenDesignerContextProps } from "../../context";
import { ApplicationHeaderItemPropertyModel } from "../../models";
import {
  ComponentPreviewBase,
  IComponentPreviewBaseProps,
  IComponentPreviewBaseState,
} from "../ComponentPreviewBase";
import "./ApplicationHeaderItemPreview.scss";

export interface IApplicationHeaderItemPreviewStateProps {}

export interface IApplicationHeaderItemPreviewDispatchProps {}

export interface IApplicationHeaderItemPreviewOwnProps
  extends IComponentPreviewBaseProps {
  properties: ApplicationHeaderItemPropertyModel;
  withTitle?: boolean;
}

export interface IApplicationHeaderItemPreviewProps
  extends IApplicationHeaderItemPreviewStateProps,
    IApplicationHeaderItemPreviewDispatchProps,
    IApplicationHeaderItemPreviewOwnProps,
    WithTranslation,
    WithScreenDesignerContextProps {}

export interface IApplicationHeaderItemPreviewState
  extends IComponentPreviewBaseState {}

export class ApplicationHeaderItemPreview extends ComponentPreviewBase<
  IApplicationHeaderItemPreviewProps,
  IApplicationHeaderItemPreviewState
> {
  public static defaultProps = {};

  public state: Readonly<IApplicationHeaderItemPreviewState> = {};

  public renderIcon = () => {
    const { properties } = this.props;
    const iconUrl = properties.IconUrl;

    return <img src={iconUrl} className="ApplicationHeaderItemPreview__Icon" />;
  };

  public renderTitle = () => {
    const { properties, withTitle } = this.props;

    if (withTitle) {
      return (
        <span className="ApplicationHeaderItemPreview__Title">
          {properties.Title}
        </span>
      );
    }

    return null;
  };

  public render() {
    const { component, properties, withTitle } = this.props;

    return (
      <div
        key={component.Id}
        className={cx("ApplicationHeaderItemPreview", { WithTitle: withTitle })}
        title={properties.Title}
      >
        {this.renderIcon()}
        {this.renderTitle()}
      </div>
    );
  }
}
