import { CountryModel } from "@bms/common-services";
import {
  Col,
  Form,
  IFormValues,
  Input,
  required,
  Row,
  Spin,
  Switch,
} from "@bms/common-ui";
import React from "react";
import { useTranslation } from "react-i18next";

export interface IDictionaryCountriesFormProps {
  country: CountryModel;
  isProcessing: boolean;
  insertCountry: (country: CountryModel) => void;
  updateCountry: (country: CountryModel) => void;
}

export const DictionaryCountriesForm = ({
  country,
  isProcessing,
  insertCountry,
  updateCountry,
}: IDictionaryCountriesFormProps) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const isNewCountry = country.Id < 0;

  const onFinish = async (values: IFormValues) => {
    const data: CountryModel = {
      ...country,
      ...values,
      Sequence: parseInt(values.Sequence, 10),
    };

    if (isNewCountry) {
      data.Sequence = undefined;
      insertCountry(data);
    } else {
      updateCountry(data);
    }
  };

  const renderCodeInput = () => {
    return (
      <Form.Item name="Code" label={t("MODEL_CODE")} rules={[required()]}>
        <Input placeholder={t("MODEL_CODE")} />
      </Form.Item>
    );
  };

  const renderNameInput = () => {
    return (
      <Form.Item name="Name" label={t("MODEL_NAME")} rules={[required()]}>
        <Input placeholder={t("MODEL_NAME")} />
      </Form.Item>
    );
  };

  const renderPath = () => {
    return (
      <Form.Item name="Flag" label={t("DICTIONARY_COUNTRY_MODAL_PATH")}>
        <Input placeholder={t("https://")} />
      </Form.Item>
    );
  };

  const renderSequenceField = () => {
    if (!isNewCountry) {
      return (
        <Form.Item
          name="Sequence"
          label={t("MODEL_SEQUENCE")}
          rules={[required()]}
        >
          <Input placeholder={t("MODEL_SEQUENCE")} />
        </Form.Item>
      );
    }
  };

  const renderUpToDate = () => {
    return (
      <Form.Item>
        <Row gutter={8}>
          <Col>
            <Form.Item name="UpToDate" valuePropName="checked">
              <Switch />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="UpToDate">
              <label>{t("MODEL_UP_TO_DATE")}</label>
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>
    );
  };

  return (
    <Spin spinning={isProcessing}>
      <Form
        name="DictionaryCountryForm"
        className="DictionaryCountryForm"
        form={form}
        initialValues={{
          ...country,
          Sequence: `${country.Sequence}`,
        }}
        onFinish={onFinish}
        layout="vertical"
      >
        <Row direction="column" justify="space-between" className="full-height">
          <Col>
            {renderCodeInput()}
            {renderNameInput()}
            {renderPath()}
            {renderSequenceField()}
            {renderUpToDate()}
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};
