import {
  AdvertisementActionsTypes,
  IAdvertisementState,
} from "./types";
import * as Consts from "./consts";

export const initialState: IAdvertisementState = {
  addModalVisible: false,
  addBoardModalVisible: false,
  action: undefined,
  actionType: undefined,
  advertisementBlock: {
    IsProcessing:false,
  },
  temporaryAdvertisementBlock: {
      RowVersion: "",
      Id: Math.floor(Math.random() * (99999 - 0)),
      Name: "",
      Description: "",
      Status: "",
      Url: "",
      Created: new Date(),
      CreatedBy: 0,
      CreatedByFullName: "",
      Modified: new Date(),
      ModifiedBy: 0,
      ModifiedByFullName: "",
      Boards:[],
  },
  advertisementBlocks: {
    Entities: [],
    TotalCount: 0,
    Filter: {
      PageNumber: 1,
      PageSize: 10,
      IncludeCount: true,
    },
  },
  boardsTypes: [],
  componentsTypes: [],
  translationsExport: {
    Data: undefined,
    IsProcessing: false,
  },
  isLoadingData: false,
  isProcessingData: false,
};

export const advertisementReducer = (
  state = initialState,
  action: AdvertisementActionsTypes
): IAdvertisementState => {
  state.actionType = action.type;
  state.action = action;

  switch (action.type) {
    case Consts.ADD_ADVERTISEMENT_BLOCK: {
      return {
        ...state,
        addModalVisible: false,
        temporaryAdvertisementBlock: {
          ...state.temporaryAdvertisementBlock,
          Id: Math.floor(Math.random() * (99999 - 0)),
          Name: "",
          Description: "",
        }
      };
    }
    case Consts.UPDATE_TEMPORARY_ADVERTISEMENT_BLOCK: {
      return {
        ...state,
        advertisementBlock: action.payload,
        addBoardModalVisible: false,
      };
    }
    case Consts.UPDATE_ADVERTISEMENT_BLOCK: {
      return {
        ...state,
        advertisementBlock: action.payload
      };
    }
    case Consts.UPDATE_ADVERTISEMENT_BLOCK_FAILURE: {
      return {
        ...state,
      };
    }
    case Consts.UPDATE_ADVERTISEMENT_BLOCK_SUCCESS: {
      return {
        ...state,
      };
    }
    case Consts.UPDATE_ADVERTISEMENT_BLOCK_WITH_DETAILS: {
      return {
        ...state,
        advertisementBlock: {
          ...action.payload,
          IsProcessing: true,
        },
        isProcessingData: true,
      };
    }
    case Consts.UPDATE_ADVERTISEMENT_BLOCK_WITH_DETAILS_SUCCESS: {
      return {
        ...state,
        advertisementBlock: {
          Data: action.payload,
          IsProcessing: false,
        },
        addBoardModalVisible: false,
        isProcessingData: false,
      };
    }
    case Consts.UPDATE_ADVERTISEMENT_BLOCK_WITH_DETAILS_FAILURE: {
      return {
        ...state,
        advertisementBlock: {
          ...state.advertisementBlock,
          Error: action.error,
          IsProcessing: false,
        },
        error: action.error,
        addBoardModalVisible: false,
        isProcessingData: false,
      };
    }
    case Consts.PUBLISH_ADVERTISEMENT_BLOCK: {
      return {
        ...state,
        advertisementBlock: {
          Data: state.advertisementBlock.Data,
          IsProcessing: true,
        },
        isProcessingData: true,
      };
    }
    case Consts.PUBLISH_ADVERTISEMENT_BLOCK_SUCCESS: {
      return {
        ...state,
        advertisementBlock: {
          Data: {
            ...action.payload,
            Boards: state.advertisementBlock.Data?.Boards || [],
          },
          IsProcessing: false,
        },
        isProcessingData: false,
      };
    }
    case Consts.PUBLISH_ADVERTISEMENT_BLOCK_FAILURE: {
      return {
        ...state,
        advertisementBlock: {
          Data: state.advertisementBlock.Data,
          IsProcessing: false
        },
        error: action.error,
        isProcessingData: false,
      };
    }
    case Consts.ARCHIVE_ADVERTISEMENT_BLOCK: {
      return {
        ...state,
        advertisementBlock: {
          Data: state.advertisementBlock.Data,
          IsProcessing: true,
        },
        isProcessingData: true,
      };
    }
    case Consts.ARCHIVE_ADVERTISEMENT_BLOCK_SUCCESS: {
      return {
        ...state,
        advertisementBlock: {
          Data: {
            ...action.payload,
            Boards: state.advertisementBlock.Data?.Boards || [],
          },
          IsProcessing: false
        },
        isProcessingData: false,
      };
    }
    case Consts.ARCHIVE_ADVERTISEMENT_BLOCK_FAILURE: {
      return {
        ...state,
        advertisementBlock: {
          Data: state.advertisementBlock.Data,
          IsProcessing: false
        },
        error: action.error,
        isProcessingData: false,
      };
    }
    case Consts.RESTORE_ADVERTISEMENT_BLOCK: {
      return {
        ...state,
        advertisementBlock: {
          Data: state.advertisementBlock.Data,
          IsProcessing: true,
        },
        isProcessingData: true,
      };
    }
    case Consts.RESTORE_ADVERTISEMENT_BLOCK_SUCCESS: {
      return {
        ...state,
        advertisementBlock: {
          Data: {
            ...action.payload,
            Boards: state.advertisementBlock.Data?.Boards || [],
          },
          IsProcessing: false,
        },
        isProcessingData: false,
      };
    }
    case Consts.RESTORE_ADVERTISEMENT_BLOCK_FAILURE: {
      return {
        ...state,
        advertisementBlock: {
          Data: state.advertisementBlock.Data,
          IsProcessing: false
        },
        error: action.error,
        isProcessingData: false,
      };
    }
    case Consts.TOGGLE_ADVERTISEMENT_BLOCK_MODAL: {
      return {
        ...state,
        addModalVisible: action.visibility,
        temporaryAdvertisementBlock: {
          ...state.temporaryAdvertisementBlock,
          Id: Math.floor(Math.random() * (99999 - 0)),
          Name: "",
          Description: "",
        }
      };
    }
    case Consts.ADD_ADVERTISEMENT_BLOCKS: {
      return {
        ...state,
        addModalVisible: true,
      };
    }
    case Consts.DELETE_ADVERTISEMENT_BLOCK: {
      return {
        ...state,
      };
    }
    case Consts.DELETE_ADVERTISEMENT_BLOCK_SUCCESS: {
      return {
        ...state,
        addModalVisible: false,
      };
    }
    case Consts.DELETE_ADVERTISEMENT_BLOCK_FAILURE: {
      return {
        ...state,
        addModalVisible: false,
      };
    }
    case Consts.ADD_ADVERTISEMENT_BLOCKS_SUCCESS: {
      return {
        ...state,
        advertisementBlocks:{
          ...state.advertisementBlocks,
          Entities:[
            action.payload,
            ...state.advertisementBlocks.Entities,
          ],
        },
        addModalVisible: false,
      };
    }
    case Consts.ADD_ADVERTISEMENT_BLOCKS_FAILURE: {
      return {
        ...state,
        addModalVisible: false,
      };
    }
    case Consts.SEARCH_ADVERTISEMENT_BLOCK: {
      return {
        ...state,
        isLoadingData: true,
      };
    }
    case Consts.SEARCH_ADVERTISEMENT_BLOCK_SUCCESS: {
      return {
        ...state,
        advertisementBlocks: action.payload,
        isLoadingData: false,
      };
    }
    case Consts.SEARCH_ADVERTISEMENT_BLOCK_FAILURE: {
      return {
        ...state,
        error: action.error,
        isLoadingData: false,
      };
    }
    case Consts.GET_ADVERTISEMENT_BLOCK: {
      const advertisementBlock = state.advertisementBlocks.Entities.filter(
        (adBlock)=>
        adBlock.Id == action.id)[0];
      return {
        ...state,
        advertisementBlock: {
          Data: advertisementBlock,
          Error: undefined,
          IsProcessing: true,
        },
      };
    }
    case Consts.GET_ADVERTISEMENT_BLOCK_SUCCESS: {
      return {
        ...state,
        advertisementBlock: {
          Data: action.payload,
          IsProcessing: false,
        },
      };
    }
    case Consts.GET_ADVERTISEMENT_BLOCK_FAILURE: {
      return {
        ...state,
        advertisementBlock: {
          Error: action.error,
          IsProcessing: false,
        },
      };
    }
    case Consts.TOGGLE_ADVERTISEMENT_BOARD_MODAL: {
      return {
        ...state,
        addBoardModalVisible: action.visibility,
      };
    }
    case Consts.GET_ADVERTISEMENT_BOARD: {
      return {
        ...state,
        advertisementBoard: undefined,
        isLoadingData: true,
      };
    }
    case Consts.GET_ADVERTISEMENT_BOARD_SUCCESS: {
      return {
        ...state,
        advertisementBoard: action.payload,
        isLoadingData: false,
      };
    }
    case Consts.GET_ADVERTISEMENT_BOARD_FAILURE: {
      return {
        ...state,
        error: action.error,
        isLoadingData: false,
      };
    }
    case Consts.SELECT_ADVERTISEMENT_BOARD_TYPE: {
      return {
        ...state,
        boardsTypes: [],
        isLoadingData: true,
      };
    }
    case Consts.SELECT_ADVERTISEMENT_BOARD_TYPE_SUCCESS: {
      return {
        ...state,
        boardsTypes: action.payload,
        isLoadingData: false,
      };
    }
    case Consts.SELECT_ADVERTISEMENT_BOARD_TYPE_FAILURE: {
      return {
        ...state,
        error: action.error,
        isLoadingData: false,
      };
    }
    default:
      return state;
  }
};
