import {
  ActionType,
  ActionTypeHelper,
  AssetService,
  AssetStore,
  AssetType,
  IAssetModel,
  IAssetTypeModel,
  IUserModel,
  TimeHelper,
  useDataLoader,
} from "@bms/common-services";
import {
  Form,
  IDatePickerMoment,
  IFormValues,
  useSendable,
  useSyncedState,
} from "@bms/common-ui";
import { pick } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  commonFields,
  CUSTOM_METADATA_FILEDS,
  FieldType,
  formLayouts,
  getFieldNamesByParams,
  getSectionsFields,
  ISection,
} from "./AssetFormUtils";

export interface IAssetFormProps {
  isEditMode: boolean;
  asset?: IAssetModel;
  parent?: IAssetModel;
  section?: ISection;
  parentAssetsCount?: number;
  availableAssets?: IAssetTypeModel[];
  hiddenFields?: FieldType[];
  onSubmit?: (data: IAssetModel) => void;
  tabType?: string;
}

const assetService = new AssetService().promisify();

const inferAssetTypeFromParent = (parent?: IAssetModel, tabType?: string) => {
  switch (parent?.AssetTypeCode) {
    case AssetType.Series:
      return tabType === AssetType.Season
        ? AssetType.Season
        : AssetType.Episode;
    case AssetType.Season:
      return AssetType.Episode;
    case AssetType.Channel:
      return AssetType.Program;
    case AssetType.Premiere:
      return AssetType.Premiere;
    case AssetType.Album:
      return AssetType.Podcast;
    case AssetType.Live:
      // catchup
      return AssetType.Video;
    default:
      return undefined;
  }
};

const getFirstAvailableAssetType = (availableAssets?: IAssetTypeModel[]) => {
  if (availableAssets && availableAssets.length > 0) {
    return availableAssets[0].Code;
  }
};

export const useAssetFormController = ({
  isEditMode,
  asset,
  parent: parentAsset,
  section,
  availableAssets,
  onSubmit,
  tabType,
}: IAssetFormProps) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { onFieldsChange, resetSendable, setDirty, sendable } = useSendable();

  const {
    assetAgeRestrictions: { data: assetAgeRestrictionsData = [] },
  } = useSelector(AssetStore.Selectors.assetSelector);

  const { id: assetId } = useParams<{ id: string }>();

  const [userCreator, setUserCreator] = useSyncedState<{
    Id?: string | number;
    FullName?: string | number;
  }>(
    () => ({
      Id: asset?.CreatorUserId,
      FullName: asset?.CreatorUserFullName || asset?.CreatorUserId,
    }),
    [asset]
  );

  const { data: assetParent } = useDataLoader({
    loader: () =>
      asset?.ParentAssetId
        ? assetService.getAsset(asset?.ParentAssetId)
        : undefined,
    deps: [asset?.ParentAssetId],
  });

  const [parent, setParent] = useState<IAssetModel | undefined>(
    parentAsset || assetParent
  );

  const [parentId, setParentId] = useSyncedState<number | undefined>(
    () => asset?.ParentAssetId || parent?.Id,
    [asset?.RowVersion]
  );

  const [parentTitle, setParentTitle] = useSyncedState<
    string | undefined
  >(() => {
    return parent?.Title || asset?.ParentAssetTitle;
  }, [asset?.RowVersion]);

  const [typeState, setTypeState] = useState<AssetType | undefined>(
    asset?.AssetTypeCode ||
      inferAssetTypeFromParent(parent, tabType) ||
      getFirstAvailableAssetType(availableAssets)
  );

  const formLayout = useMemo(
    () => (isEditMode ? formLayouts.formItem : formLayouts.modalFormItem),
    [isEditMode]
  );

  const formFieldNames = useMemo(() => {
    let fields = commonFields;
    const hasParent = !!(parentId && parentTitle);

    if (typeState) {
      fields = section
        ? getSectionsFields(typeState, hasParent)[section]
        : getFieldNamesByParams(typeState, hasParent);
    }

    return fields;
  }, [section, typeState, isEditMode]);

  const assetFields = useMemo(
    () => [
      ...formFieldNames.flatMap((name) => {
        switch (name) {
          case "Director":
          case "Cast":
          case "Writer":
            return ["People"];
          case "DurationMiliseconds":
            return ["DurationSeconds"];
          case "AvailableDate":
            return ["AvailableFrom", "AvailableTo"];
          case "StartEndDates":
            return ["StartDateTime", "EndDateTime"];
          case "CreatorUserId":
            return ["CreatorUserId", "CreatorUserFullName"];
          case "AssetAction":
          case "AdUrl":
            return ["CustomMetadata"];
          default:
            return [name];
        }
      }),
      "Payment",
      "ParentAssetId",
      "ParentAssetTitle",
    ],
    [formFieldNames]
  );

  const getAssetTypes = () => dispatch(AssetStore.Actions.getAssetTypes());

  const getAssetAgeRestrictions = () =>
    dispatch(AssetStore.Actions.getAssetAgeRestrictions());

  const selectAssetParent = (id: number) =>
    dispatch(AssetStore.Actions.selectAssetParent(id));

  const onFinish = async (_values: IFormValues) => {
    const values = { ..._values };
    const submittedKeys = Object.keys(values);
    const customMetadata = getAssetCustomMetadata(values, asset);
    const picked: any = {};

    const parserMapper: IFormValues = {
      AssetTypeCode: values.AssetTypeCode,
      Title: values.Title,
      Description: values.Description,
      CountriesAllowed: values.CountriesAllowed,
      CountriesBlocked: values.CountriesBlocked,
      ShortDescription: values.ShortDescription,
      LongDescription: values.LongDescription,
      AssetAgeRestrictionValueMin: values.AssetAgeRestrictionValueMin,
      Categories: values.Categories,
      CreatorUserId: userCreator.Id,
      CreatorUserFullName: userCreator.FullName,
      People: [
        ...(values.Director || []),
        ...(values.Cast || []),
        ...(values.Writer || []),
      ],
      OrderInParent: values.OrderInParent
        ? values.OrderInParent
        : parent?.Id
        ? 1
        : null,
      DurationMiliseconds: values.DurationMiliseconds
        ? TimeHelper.toDurationMilliseconds(values.DurationMiliseconds)
        : undefined,
      AvailableFrom: values.AvailableFrom
        ? values.AvailableFrom.toISOString()
        : null,
      AvailableTo: values.AvailableTo ? values.AvailableTo.toISOString() : null,
      StartDateTime: values.StartDateTime
        ? values.StartDateTime.toISOString()
        : null,
      EndDateTime: values.EndDateTime ? values.EndDateTime.toISOString() : null,
      ParentAssetId: parentId ?? null,
      ParentAssetTitle: parentTitle ?? null,
      IsFree: values.IsFree,
      CustomMetadata: customMetadata,
    };

    // Year is defined in general section and need to be conditionally
    // included in parser mapper since it will clear previous value when Year
    // field is not submitted, null value is required to clean field
    if (submittedKeys.includes("Year")) {
      parserMapper["Year"] = values.Year ? values.Year.year() : null;
    }

    assetFields
      .filter((it) => parserMapper[it] !== undefined)
      .forEach((it) => (picked[it] = parserMapper[it]));

    const assetDetails: IAssetModel = {
      Id: -1,
      ...asset,
      ...picked,
    };

    if (!assetDetails.AssetAgeRestrictionValueMin) {
      const minVal = Math.min(
        ...assetAgeRestrictionsData.map((it) => it.ValueMin)
      );
      assetDetails.AssetAgeRestrictionValueMin = minVal || 0;
      assetDetails.AssetAgeRestrictionImageUrl = assetAgeRestrictionsData.find(
        (it) => it.ValueMin === minVal
      )?.ImageUrl;
    }
    onSubmit?.(assetDetails);
  };

  const getAssetCustomMetadata = (values: IFormValues, asset?: IAssetModel) => {
    const customMetadata = {
      ...asset?.CustomMetadata,
    };

    if (values[CUSTOM_METADATA_FILEDS.ACTION_TYPE]) {
      const actionType = ActionTypeHelper.getValue(
        values[CUSTOM_METADATA_FILEDS.ACTION_TYPE]
      );

      switch (actionType) {
        case ActionType.OpenUrl:
          const url = values[CUSTOM_METADATA_FILEDS.ACTION_URL];

          customMetadata.Action = {
            ActionType: actionType,
            Url: url,
          };
          break;
        default:
          delete customMetadata.Action;
          break;
      }
    } else {
      delete customMetadata.Action;
    }

    if (values[CUSTOM_METADATA_FILEDS.AD_URL]) {
      const adUrl = values[CUSTOM_METADATA_FILEDS.AD_URL];
      customMetadata.AdUrl = adUrl;
    } else {
      delete customMetadata.AdUrl;
    }

    return customMetadata;
  };

  const handleDurationChange = (date: IDatePickerMoment | null) => {
    if (asset) {
      if (date) {
        asset.DurationMiliseconds =
          1000 * (date.second() + 60 * (date.minute() + 60 * date.hour()));
      } else {
        asset.DurationMiliseconds = undefined;
      }
    }
  };

  const onUserSelect = (row?: IUserModel) => {
    setUserCreator({ Id: row?.Id, FullName: row?.FullName || row?.Id });
    setDirty();
  };

  const onParentSelect = (asset?: IAssetModel) => {
    setParent(asset);
    setParentId(asset?.Id);
    setParentTitle(asset?.Title);
    setDirty();
  };

  const onParentClear = () => onParentSelect();

  const onUserClear = () => onUserSelect();

  useEffect(() => {
    getAssetTypes();
    getAssetAgeRestrictions();
    if (isEditMode) {
      selectAssetParent(Number.parseInt(assetId));
    }
  }, []);

  useEffect(() => {
    resetSendable();
    form.resetFields();
  }, [JSON.stringify(pick(asset, assetFields))]);

  return {
    formLayout,
    parentId,
    parentTitle,
    formFieldNames,
    sendable,
    parent,
    typeState,
    userCreator,
    form,
    setTypeState,
    setParentId,
    onFieldsChange,
    onUserClear,
    onUserSelect,
    onParentSelect,
    onParentClear,
    handleDurationChange,
    onFinish,
  };
};
