/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";

export const useSearchParamOnce = (key: string): string | null => {
  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);
  let initialValue = null;

  if (params.has(key)) {
    initialValue = params.get(key);
    params.delete(key);
    history.replace({
      ...location,
      search: params.toString(),
    });
  }

  const [value, setValue] = useState<string | null>(initialValue);

  useEffect(() => {
    if (params.has(key)) {
      setValue(params.get(key));
      params.delete(key);
      history.replace({
        ...location,
        search: params.toString(),
      });
    }
  }, [params]);

  return value;
};
