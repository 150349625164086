import { Observable, Observer } from "rxjs";
import { HTTP_METHOD } from "../../constants/http";
import { AxiosSubscriber } from "../AxiosSubscriber";
import { IConfigModel } from "../../models/Config";

export class ConfigService {
  get url(): string {
    return "/Configurations";
  }

  public get = (): Observable<IConfigModel> =>
    new Observable(
      (observer: Observer<IConfigModel>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `${this.url}/Get`,
        })
    );
}
