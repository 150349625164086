import {
  AdvertisementBoardType,
  IAdvertisementBoardModel
} from "@bms/common-services";

export class advertisementBoardLeadHelper {
    static isLeadPhone = (value: AdvertisementBoardType|IAdvertisementBoardModel) => {
      if(typeof value === "string"){
        return value === AdvertisementBoardType.LeadPhone;
      }else{
        return value.BoardType === AdvertisementBoardType.LeadPhone;
      }
      return false;
    }

    static isLeadEmail = (value: AdvertisementBoardType|IAdvertisementBoardModel) => {
      if(typeof value === "string"){
        return value === AdvertisementBoardType.LeadEmail;
      }else{
        return value.BoardType === AdvertisementBoardType.LeadEmail;
      }
      return false;
    }

    static isLead = (value: AdvertisementBoardType|IAdvertisementBoardModel) => {
        return (
          advertisementBoardLeadHelper.isLeadEmail(value) ||
          advertisementBoardLeadHelper.isLeadPhone(value)
        )
    }
}
