import { SimpleServiceBase } from "../Base";
import {
  ITestApplicationModel,
  ITestApplicationsFilterModel,
  ITestApplicationsListModel,
} from "../../models";

export class TestApplicationsService extends SimpleServiceBase<
  ITestApplicationModel,
  ITestApplicationsListModel,
  number,
  ITestApplicationsFilterModel
> {
  get url() {
    return "/TestApplications";
  }
}
