import { PlatformType } from "@bms/common-services";
import cx from "classnames";
import React from "react";
import { WithTranslation } from "react-i18next";
import { WithScreenDesignerContextProps } from "../../context";
import { ApplicationMenuItemPropertyModel } from "../../models";
import {
  ComponentPreviewBase,
  IComponentPreviewBaseProps,
  IComponentPreviewBaseState,
} from "../ComponentPreviewBase";
import "./ApplicationMenuItemPreview.scss";

export interface IApplicationMenuItemPreviewStateProps {}

export interface IApplicationMenuItemPreviewDispatchProps {}

export interface IApplicationMenuItemPreviewOwnProps
  extends IComponentPreviewBaseProps {
  properties: ApplicationMenuItemPropertyModel;
}

export interface IApplicationMenuItemPreviewProps
  extends IApplicationMenuItemPreviewStateProps,
    IApplicationMenuItemPreviewDispatchProps,
    IApplicationMenuItemPreviewOwnProps,
    WithTranslation,
    WithScreenDesignerContextProps {}

export interface IApplicationMenuItemPreviewState
  extends IComponentPreviewBaseState {}

export class ApplicationMenuItemPreview extends ComponentPreviewBase<
  IApplicationMenuItemPreviewProps,
  IApplicationMenuItemPreviewState
> {
  public static defaultProps = {};

  public state: Readonly<IApplicationMenuItemPreviewState> = {};

  public renderIcon = () => {
    const { properties, layoutOptions } = this.props;
    const iconUrl = properties.IconAbsoluteUrl
      ? properties.IconAbsoluteUrl
      : properties.IconUrl;
    if (
      (!iconUrl && layoutOptions.Platform !== PlatformType.AndroidTV) ||
      layoutOptions.Platform == PlatformType.AppleTV
    ) {
      return;
    }
    return <img src={iconUrl} className="ApplicationMenuItemPreview__Icon" />;
  };

  public renderTitle = () => {
    const { properties, layoutOptions } = this.props;
    if (layoutOptions.Platform === PlatformType.AndroidTV) {
      return;
    }
    return (
      <span className="ApplicationMenuItemPreview__Title">
        {properties.Title}
      </span>
    );
  };

  public render() {
    const { component, properties, layoutOptions } = this.props;
    let classItemName = "";
    switch (layoutOptions.Platform) {
      case PlatformType.AndroidTV:
      case PlatformType.AndroidPhone:
      case PlatformType.AndroidTablet:
      case PlatformType.iOSPhone:
      case PlatformType.iPad:
        classItemName = "Mobile";
        break;
      case PlatformType.AppleTV:
        classItemName = "AppleTV";
        break;
      case PlatformType.Tizen:
      case PlatformType.WebOS:
      case PlatformType.Roku:
      case PlatformType.Any:
      case PlatformType.Web:
        classItemName = "Web";
        break;
      default:
        break;
    }

    return (
      <div
        key={component.Id}
        className={cx("ApplicationMenuItemPreview", classItemName)}
        title={properties.Title}
      >
        {this.renderIcon()}
        {this.renderTitle()}
      </div>
    );
  }
}
