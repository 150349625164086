/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  AssetStore,
  BooleanHelper,
  DataProvider,
  IAssetCollectionModel,
  IAssetCollectionSearchFilterModel,
  ICommonAppState,
  TimeHelper,
} from "@bms/common-services";
import {
  Button,
  Empty,
  Icon,
  InputSearch,
  ITableColumnProps,
  ITableFilter,
  Link,
  PageContent,
  PageHeader,
  Pagination,
  setTableColumnSearchProps,
  Table,
  useSearchParamsFilters,
  useSearchParamsPagination,
  useSyncedState,
  Popconfirm,
  IBreadcrumbProps,
  useSearchParamOnce,
  useAppFeedback,
} from "@bms/common-ui";
import { generateBreadcrumb } from "../../../../helpers";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ROUTES } from "../../constants";
import { AddAssetCollectionModal } from "../AddAssetCollectionModal";
import { FilterCleanIcon } from "../../../../resources/icons";

const assetCollectionSelector = (state: ICommonAppState) => state.asset;

export const AssetsCollectionsList: React.FC = () => {
  const { t } = useTranslation();
  const { notification } = useAppFeedback();
  const dispatch = useDispatch();

  const showAddModalParam = useSearchParamOnce("showAddModal");

  const [
    addAssetCollectionModalVisible,
    setAddAssetCollectionModalVisible,
  ] = useState<boolean>(BooleanHelper.toBool(showAddModalParam, false));

  const { assetCollections, isFetching, action } = useSelector(
    assetCollectionSelector
  );

  const pagination = useSearchParamsPagination(assetCollections.TotalCount);

  const filters = useSearchParamsFilters({
    Name: "string",
    Description: "string",
    FullTextSearch: "string",
  });

  const [fullTextSearch, setFullTextSearch] = useSyncedState(
    () => filters.current.FullTextSearch,
    [filters.current.FullTextSearch]
  );

  const searchAssetCollection = useCallback(
    (filter: IAssetCollectionSearchFilterModel) =>
      dispatch(AssetStore.Actions.searchAssetCollection(filter)),
    [dispatch]
  );

  useEffect(() => {
    searchAssetCollection({
      ...filters.current,
      ...pagination.current,
    });
  }, [filters.current, pagination.current]);

  useEffect(() => {
    switch (action?.type) {
      case AssetStore.Consts.SEARCH_ASSETS_COLLECTION_FAILURE:
        notification.error({
          message: t("ASSET_COLLECTION_LIST__SEARCH_FAILURE"),
          description: action?.error?.Message,
        });
        break;
      case AssetStore.Consts.DELETE_ASSETS_COLLECTION_SUCCESS:
        notification.success({
          message: t("DELETE_ELEMENT_SUCCESS"),
        });
        onRefreshClick();
        break;
      case AssetStore.Consts.DELETE_ASSETS_COLLECTION_FAILURE:
        notification.error({
          message: t("DELETE_ELEMENT_FAILTURE"),
          description: action?.error?.Message,
        });
        break;
    }
  }, [action]);

  const onRefreshClick = () =>
    searchAssetCollection({
      ...filters.current,
      ...pagination.current,
    });

  const getColumnsProps = (): Array<
    ITableColumnProps<IAssetCollectionModel>
  > => {
    const columns: ITableColumnProps<IAssetCollectionModel>[] = [
      {
        key: "Name",
        dataIndex: "Name",
        title: t("MODEL_NAME"),
        ellipsis: true,
        width: "300px",
        filteredValue: filters.current.Name ? [filters.current.Name] : null,
        render: (_: any, row: IAssetCollectionModel) => {
          let link = DataProvider.AssetCollection?.link?.(row);

          if (!link) {
            link = {
              url: `${ROUTES.ASSET_COLLECTION_DETAILS}/${row.Id}`,
              target: "_self",
            };
          }

          return (
            <Link target={link.target} to={link.url}>
              {row.Name}
            </Link>
          );
        },
        ...setTableColumnSearchProps("Title"),
      },
      {
        key: "Description",
        dataIndex: "Description",
        ellipsis: true,
        title: t("MODEL_DESCRIPTION"),
        filteredValue: filters.current.Description
          ? [filters.current.Description]
          : null,
        ...setTableColumnSearchProps("Description"),
      },
      {
        key: "CreatedByFullName",
        dataIndex: "CreatedByFullName",
        title: t("MODEL_CREATED_BY"),
        align: "center",
        width: "250px",
      },
      {
        key: "Created",
        dataIndex: "Created",
        align: "center",
        width: "140px",
        title: t("PLAY_LIST_TABLE_CREATION_DATE_COLUMN"),
        render: (created?: string) =>
          created ? TimeHelper.format(created) : null,
      },
    ];

    if (DataProvider.AssetCollection?.Options.DeleteEnabled) {
      columns.push({
        key: "Actions",
        dataIndex: "Actions",
        align: "center",
        width: "100px",
        title: t("TABLE_ACTIONS_COLUMN"),
        render: (_: any, row: IAssetCollectionModel) => (
          <Popconfirm
            title={t("DELETE_ELEMENT_DOUBLE_CONFIRMATION_QUESTION")}
            onConfirm={async (e?: React.MouseEvent<HTMLElement>) => {
              e?.preventDefault();
              dispatch(AssetStore.Actions.deleteAssetsCollection(row));
            }}
            okText={t("BUTTON_YES")}
            cancelText={t("BUTTON_NO")}
          >
            <Button
              danger={true}
              icon={<Icon type="delete" />}
              title={t("DELETE_ELEMENT")}
            />
          </Popconfirm>
        ),
      });
    }

    return columns;
  };

  const onTableChange = (_: any, { Name, Description }: ITableFilter) =>
    filters.update((oldFilters) => ({
      ...oldFilters,
      Name: Name?.[0]?.toString(),
      Description: Description?.[0]?.toString(),
    }));

  const onSearch = (value: string) =>
    filters.update((oldFilters) => ({
      ...oldFilters,
      FullTextSearch: value || undefined,
    }));

  const columns = getColumnsProps();

  const renderAddAssetCollectionModal = () => {
    return (
      <AddAssetCollectionModal
        visible={addAssetCollectionModalVisible}
        onCancel={() => setAddAssetCollectionModalVisible(false)}
        onSuccess={() => setAddAssetCollectionModalVisible(false)}
      />
    );
  };
  const getBreadcrumbProps = (): IBreadcrumbProps => {
    return generateBreadcrumb([
      {
        path: `${ROUTES.ASSET_COLLECTION_LIST}`,
        breadcrumbName: t("MENU_CMS_MEDIA_COLLECTIONS", "Media collections"),
      },
    ]);
  };

  return (
    <PageContent footer={<Pagination {...pagination.props} />}>
      <PageHeader
        title={t("MENU_CMS_MEDIA_COLLECTIONS", "Media collections")}
        breadcrumb={getBreadcrumbProps()}
        extra={
          <>
            <InputSearch
              placeholder={t("SEARCH_PLACEHOLDER")}
              value={fullTextSearch}
              onChange={({ target: { value } }) => setFullTextSearch(value)}
              onSearch={onSearch}
              style={{ width: 250 }}
              allowClear
            />
            <Button
              shape="circle"
              disabled={!filters.anyActive}
              icon={<FilterCleanIcon />}
              onClick={filters.clear}
              title={t("MENU_OPTION_CLEAR_FILTERS")}
            />
            <Button
              shape="circle"
              icon={<Icon type="reload" />}
              onClick={onRefreshClick}
              title={t("BUTTON_REFRESH_TITLE")}
            />
            {DataProvider.AssetCollection?.Options.InsertEnabled && (
              <Button
                shape="circle"
                type="primary"
                icon={<Icon type="plus" />}
                onClick={() => setAddAssetCollectionModalVisible(true)}
                title={t("BUTTON_ADD")}
              />
            )}
          </>
        }
      />
      <Table<IAssetCollectionModel>
        locale={{
          emptyText: (
            <>
              <Empty />
              {filters.anyActive && (
                <Button icon={<FilterCleanIcon />} onClick={filters.clear}>
                  {t("MENU_OPTION_CLEAR_FILTERS")}
                </Button>
              )}
            </>
          ),
        }}
        rowKey="Guid"
        columns={columns}
        dataSource={assetCollections.Entities}
        loading={isFetching}
        pagination={false}
        onChange={onTableChange}
      />
      {renderAddAssetCollectionModal()}
    </PageContent>
  );
};
