import {
  AssetService,
  AssetType,
  IAssetModel,
  RecordStatus,
  useDataLoader,
  useServiceCaller,
} from "@bms/common-services";
import { useAppFeedback, useSyncedState } from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router";
import { useAssetDataPager } from "../../hooks";
import { ROUTES } from "../../constants";

const assetService = new AssetService().promisify();

export const useAssetDetailsController = () => {
  const id = parseInt(useParams<{ id: string }>()["id"]);
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [dataPager, dataPagerLoading] = useAssetDataPager(id);

  const { data, loading, refresh } = useDataLoader({
    loader: () => assetService.getAsset(id),
    deps: [id],
  });

  const [asset, setAsset] = useSyncedState(() => data, [data]);
  const { modal, notification } = useAppFeedback();

  const [createChildAsset, createChildAssetState] = useServiceCaller(
    async (data: IAssetModel, parent?: IAssetModel) => {
      const payload = {
        ...data,
        Categories: parent?.Categories?.map((p) => ({
          ...p,
          RecordStatus: RecordStatus.Inserted,
        })),
        IsFree: parent?.IsFree,
      };
      const result = await assetService.createAsset(payload);

      if (result.ok) {
        notification.success({
          message: t("ASSET_CREATE_SUCCESS"),
        });
        history.push(`${ROUTES.ASSET_DETAILS}/${result.data.Id}`);
      } else {
        notification.error({
          message: t("ASSET_CREATE_FAILURE"),
          description: result.error?.Message,
        });
      }
      return result.ok;
    },
    []
  );

  const [updateAsset, updateAssetState] = useServiceCaller(
    async (data: IAssetModel) => {
      const result = await assetService.updateAsset(data);
      if (result.ok) {
        setAsset(result.data);
        notification.success({
          message: t("ASSET_DETAILS__ASSET_UPDATE_SUCCESS"),
        });
      } else {
        notification.error({
          message: t("ASSET_DETAILS__ASSET_UPDATE_FAILURE"),
          description: result.error.Message,
        });
      }
    },
    []
  );

  const [deleteAsset] = useServiceCaller(async () => {
    const result = await assetService.deleteAsset(asset!);
    if (result.ok) {
      notification.success({
        message: t("ASSET_DETAILS__ASSET_DELETE_SUCCESS"),
      });
      history.push(ROUTES.ASSET_LIST);
    } else {
      notification.error({
        message: t("ASSET_DETAILS__ASSET_DELETE_FAILURE"),
        description: result.error?.Message,
      });
    }
  }, [asset]);

  const onDeleteClick = () => {
    if (!asset) {
      return;
    }

    modal.confirm({
      title: t("COMMON_DELETE"),
      content: t("DELETE_ASSET_MESSAGE", { assetName: asset?.Title }),
      okText: t("BUTTON_DELETE"),
      cancelText: t("BUTTON_CANCEL"),
      onOk: deleteAsset,
    });
  };

  const onBackClick = () => {
    if (location.state && location.state.hasOwnProperty("from")) {
      // use location from router state
      const state = location.state as { from: Location };
      history.push(state.from);
    } else {
      // use asset list location
      history.goBack();
    }
  };

  const isAssetInType = (...types: AssetType[]) =>
    !!asset?.AssetTypeCode && types.indexOf(asset?.AssetTypeCode) > -1;

  return {
    updateAssetState,
    asset,
    loading,
    dataPager,
    dataPagerLoading,
    refresh,
    updateAsset,
    createChildAsset,
    createChildAssetState,
    onDeleteClick,
    onBackClick,
    isAssetInType,
    t,
  };
};
