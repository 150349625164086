import { ActionType } from "../enums";

export class ActionTypeHelper {
  static getDescription(value: ActionType): string {
    switch (value) {
      case "OPEN_SCREEN":
        return "Open Screen";
      case "OPEN_URL":
        return "Open Url";
      case "CALL_TO":
        return "Call To";
      case "EMAIL_TO":
        return "Email To";
      case "LANGUAGE_SELECT":
        return "Select Language";
      case "SIGN_IN_OUT":
        return "Sign In/Out";
      default:
        return "Undefined";
    }
  }

  static getValue(value: string | undefined): ActionType | undefined {
    switch (value) {
      case "OPEN_SCREEN":
        return ActionType.OpenScreen;
      case "OPEN_URL":
        return ActionType.OpenUrl;
      case "CALL_TO":
        return ActionType.CallTo;
      case "EMAIL_TO":
        return ActionType.EmailTo;
      case "LANGUAGE_SELECT":
        return ActionType.LanguageSelect;
      case "SIGN_IN_OUT":
        return ActionType.SignInOut;
      default:
        return undefined;
    }
  }

  static getStringValue(value?: ActionType): string | undefined {
    switch (value) {
      case ActionType.OpenScreen:
        return "OPEN_SCREEN";
      case ActionType.OpenUrl:
        return "OPEN_URL";
      case ActionType.CallTo:
        return "CALL_TO";
      case ActionType.EmailTo:
        return "EMAIL_TO";
      case ActionType.LanguageSelect:
        return "LANGUAGE_SELECT";
      case ActionType.SignInOut:
        return "SIGN_IN_OUT";
      default:
        return undefined;
    }
  }
}
