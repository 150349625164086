import {
  IAssetCategoriesListModel,
  IResourceModel,
} from "@bms/common-services";
import {
  Button,
  Col,
  Form,
  Icon,
  IFormValues,
  Input,
  Link,
  Row,
} from "@bms/common-ui";
import React from "react";
import { WithTranslation } from "react-i18next";
import * as TranslationsModule from "../../../../../Translations";
import {
  ApplicationHeaderItemPropertyModel,
  ApplicationSectionMenuItemPropertyModel,
} from "../../models";
import { ActionProperties } from "../ActionProperties";
import {
  ComponentPropertiesBase,
  IComponentPropertiesBaseProps,
  IComponentPropertiesBaseState,
} from "../ComponentPropertiesBase";

export interface IApplicationSectionMenuItemPropertiesStateProps {
  isProcessingData: boolean;
  categories: IAssetCategoriesListModel;
}

export interface IApplicationSectionMenuItemPropertiesDispatchProps {}

export interface IApplicationSectionMenuItemPropertiesOwnProps {}

export interface IApplicationSectionMenuItemPropertiesProps
  extends IApplicationSectionMenuItemPropertiesStateProps,
    IApplicationSectionMenuItemPropertiesDispatchProps,
    IApplicationSectionMenuItemPropertiesOwnProps,
    IComponentPropertiesBaseProps,
    WithTranslation {}

export interface IApplicationSectionMenuItemPropertiesState
  extends IComponentPropertiesBaseState {
  translationsBrowserVisible: boolean;
}

export class ApplicationSectionMenuItemProperties extends ComponentPropertiesBase<
  IApplicationSectionMenuItemPropertiesProps,
  IApplicationSectionMenuItemPropertiesState,
  ApplicationHeaderItemPropertyModel
> {
  public static defaultProps = {
    isProcessingData: false,
  };

  public state: Readonly<IApplicationSectionMenuItemPropertiesState> = {
    translationsBrowserVisible: false,
  };

  getPropertiesModel(): ApplicationSectionMenuItemPropertyModel {
    const { component } = this.props;
    const properties = new ApplicationSectionMenuItemPropertyModel();
    properties.init(component);

    return properties;
  }

  public onFinish = (values: IFormValues) => {
    const { component } = this.props;

    if (!component) {
      return;
    }

    component.Name = values.Name;
  };

  public onTitleTranslationKeyCancel = () => {
    this.setState({ translationsBrowserVisible: false });
  };

  public onTitleTranslationKeySelect = (row: IResourceModel) => {
    this.properties.TitleTranslationKey = row.ResourceKey;
    this.onPropertyChange("TitleTranslationKey");

    this.setState({ translationsBrowserVisible: false });
  };

  public onTitleTranslationKeyClear = () => {
    const { component, onComponentChange } = this.props;

    this.properties.TitleTranslationKey = undefined;
    this.properties.deleteProperty("TitleTranslationKey", component);

    if (onComponentChange) {
      onComponentChange(component);
    }
  };

  public renderTitleTranslationKeyProperty = () => {
    const { component, t } = this.props;
    const { translationsBrowserVisible } = this.state;

    let titleTranslationKeyView: React.ReactElement = this.properties
      .TitleTranslationKey ? (
      <label>
        <Link
          to={`${TranslationsModule.ROUTES.CONFIGURATION_TRANSLATION_DETAILS}/${this.properties.TitleTranslationKey}`}
        >
          {this.properties.TitleTranslationKey}
        </Link>
      </label>
    ) : (
      <label>{t("MODEL_TRANSLATION_KEY_PLACEHOLDER")}</label>
    );

    return (
      <Form.Item name="TitleTranslationKey" label={t("MODEL_TITLE_KEY")}>
        <Row gutter={8}>
          <Col span={18}>
            <Form.Item>{titleTranslationKeyView}</Form.Item>
            <TranslationsModule.Components.TranslationsBrowserModal
              key={`TranslationsBrowser-${component.Id}`}
              visible={translationsBrowserVisible}
              onCancel={this.onTitleTranslationKeyCancel}
              onSelect={this.onTitleTranslationKeySelect}
            />
          </Col>
          <Col span={6} style={{ textAlign: "right" }}>
            {this.properties.TitleTranslationKey && (
              <Button
                icon={<Icon type="delete" />}
                onClick={this.onTitleTranslationKeyClear}
                style={{ marginRight: "8px" }}
              />
            )}
            <Button
              icon={<Icon type="edit" />}
              onClick={() => {
                this.setState({ translationsBrowserVisible: true });
              }}
            />
          </Col>
        </Row>
      </Form.Item>
    );
  };

  public renderTitleProperty = () => {
    const { isProcessingData, t, categories } = this.props;

    return (
      <Form.Item
        name="Title"
        label={t("MODEL_TITLE")}
        initialValue={this.properties.Title}
      >
        <Input
          disabled={isProcessingData}
          placeholder={t("MODEL_TITLE")}
          onChange={(e) => {
            this.properties.Title = e.target.value;
            this.onPropertyChange("Title");
          }}
        />
      </Form.Item>
    );
  };

  public renderActionProperty = () => {
    const { component, onComponentChange } = this.props;

    return (
      <ActionProperties
        key={`Action-${component.Id}`}
        component={component}
        onComponentChange={onComponentChange}
      />
    );
  };

  public render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 8 },
        lg: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 16 },
        lg: { span: 18 },
      },
    };

    return (
      <Form
        name="ApplicationSectionMenuItemProperties"
        {...formItemLayout}
        onFinish={this.onFinish}
      >
        <Row direction="column" justify="space-between" className="full-height">
          <Col>
            {this.renderTitleProperty()}
            {this.renderTitleTranslationKeyProperty()}
            {this.renderActionProperty()}
          </Col>
        </Row>
      </Form>
    );
  }
}
