import React from "react";
import { Icon, IIconProps } from "@bms/common-ui";

import { Filter } from "./Filter";
import { Spinner } from "./Spinner";
import { ArrowDown } from "./ArrowDown";
import { Volume } from "./Volume";
import { VolumeMute } from "./VolumeMute";

const FilterCleanIcon = () => <Icon component={Filter} />;
const ArrowDownIcon = () => <Icon component={ArrowDown} />;
const LoadingSpinnerIcon = () => (
  <Icon component={Spinner} spin style={{ fontSize: 20, marginTop: 1 }} />
);
const VolumeIcon = (props: IIconProps) => (
  <Icon component={Volume} {...props} />
);
const VolumeMuteIcon = (props: IIconProps) => (
  <Icon component={VolumeMute} {...props} />
);

export {
  FilterCleanIcon,
  LoadingSpinnerIcon,
  ArrowDownIcon,
  VolumeIcon,
  VolumeMuteIcon,
};
