import React from "react";
import { Form } from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { INotificationField } from "./INotificationField";

export const CreatorReadOnlyField = ({
  notification,
  isHidden,
}: INotificationField) => {
  const { t } = useTranslation();

  if (isHidden) {
    return null;
  }

  return (
    <Form.Item
      name="Creator"
      label={t("NOTIFICATION_CREATOR_FIELD", "Creator")}
      key="CreatorType"
    >
      {notification?.CreatorUserFullName}
    </Form.Item>
  );
};
