import { Observable, Observer } from "rxjs";
import { HTTP_METHOD } from "../../constants";
import { AxiosSubscriber } from "../AxiosSubscriber";
import { ICurrencyModel } from "../../models/Currencies";
import { PromisifiableBase } from "../Base";

export class CurrencyService extends PromisifiableBase {
  get url(): string {
    return "/Currencies";
  }

  public select = (): Observable<ICurrencyModel[]> =>
    new Observable(
      (observer: Observer<ICurrencyModel[]>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `${this.url}/Select`,
        })
    );

  public update = (data: ICurrencyModel): Observable<ICurrencyModel> =>
    new Observable(
      (observer: Observer<ICurrencyModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.PUT,
          url: `${this.url}/Update`,
        })
    );

  public insert = (data: ICurrencyModel): Observable<ICurrencyModel> =>
    new Observable(
      (observer: Observer<ICurrencyModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: `${this.url}/Insert`,
        })
    );

  public delete = (data: ICurrencyModel): Observable<undefined> =>
    new Observable(
      (observer: Observer<undefined>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: `${this.url}/Delete`,
        })
    );
}
