// tslint:disable:object-literal-sort-keys
import React, { useCallback, useEffect, useState } from "react";
import {
  AssetContentService,
  AssetContentType,
  AssetStore,
  AssetType,
  BooleanHelper,
  ContentStatus,
  IAssetContentModel,
  IAssetModel,
  IAssetSearchFilterModel,
  ICommonAppState,
  StreamType,
  TimeHelper,
  useExternalSources,
  useServiceCaller,
} from "@bms/common-services";
import {
  Button,
  Dropdown,
  Heading,
  Icon,
  ITableColumnProps,
  ITableFilter,
  ITablePaginationConfig,
  Modal,
  Popconfirm,
  setTableColumnSearchProps,
  Spin,
  Table,
  Tag,
  Text,
  useAppFeedback,
  useIntervalEffect,
} from "@bms/common-ui";
import {
  EditExternalAsset,
  EditType,
} from "../../../../components/EditExternalAsset/EditExternalAsset";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { FormModal, MycujooPlayer, Player } from "../../../../components";
import { ContentStatusHelper, isSafariBrowser } from "../../../../helpers";

import { AssetContentPathModal } from "../AssetContentPathModal";
import { AssetContentUploadModal } from "../AssetContentUploadModal";
import { AssetContentTranscodeModal } from "../AssetContentTranscodeModal";
import { FilterCleanIcon } from "../../../../resources/icons";

import "./AssetContentList.scss";

const assetContentService = new AssetContentService();

const assetSelector = (state: ICommonAppState) => state.asset;
type ColumnValue = boolean | React.Key | undefined;

const AUTO_REFRESH_INTERVAL = 60_000;
const STATUSES_REQUIRING_UPDATE: ContentStatus[] = [
  ContentStatus.Processing,
  ContentStatus.Queued,
];

interface IAssetContentListProps {
  asset?: IAssetModel;
  refreshAsset: () => void;
}

const ASSET_CONTENT_PATH_DISABLED = BooleanHelper.toBool(
  process.env.REACT_APP_ASSET_CONTENT_PATH_DISABLED,
  false
);

const ASSET_UPLOAD_DIRECT_DISABLED = BooleanHelper.toBool(
  process.env.REACT_APP_ASSET_CONTENT_UPLOAD_DIRECT_DISABLED,
  false
);

const ASSET_UPLOAD_TRANSCODING_DISABLED = BooleanHelper.toBool(
  process.env.REACT_APP_ASSET_CONTENT_UPLOAD_TRANSCODING_DISABLED,
  false
);

const ASSET_CONTENT_UPLOAD_DISABLED =
  ASSET_UPLOAD_DIRECT_DISABLED && ASSET_UPLOAD_TRANSCODING_DISABLED;

export const AssetContentList = ({
  asset,
  refreshAsset,
}: IAssetContentListProps) => {
  const { t } = useTranslation();
  const { modal, notification, destroyAllModals } = useAppFeedback();
  const dispatch = useDispatch();

  const sources = useExternalSources();

  const [tableView, setTableView] = useState<string>("standard");

  const [checkTranscodingStatus] = useServiceCaller(async () => {
    if (!asset?.Id) {
      return;
    }

    await assetContentService
      .checkTranscodingStatusByAssetId(asset.Id)
      .toPromise();
    refreshAsset();
  }, [refreshAsset, asset?.Id]);

  const [refreshInterval, setRefreshInterval] = useIntervalEffect(
    checkTranscodingStatus
  );
  const [stateFilter, setStateFilter] = useState<IAssetSearchFilterModel>({
    PageSize: 10,
    PageNumber: 1,
    IncludeCount: true,
  });
  const [editableContent, setEditableContent] = useState<IAssetContentModel>(
    {}
  );
  const [
    previewContent,
    setPreviewContent,
  ] = useState<IAssetContentModel | null>();

  const [showContentPreviewModal, setShowContentPreviewModal] = useState<
    boolean
  >(false);

  const [showContentPathModal, setShowContentPathModal] = useState<boolean>(
    false
  );

  const [showContentUploadModal, setShowContentUploadModal] = useState<boolean>(
    false
  );

  const [showContentTranscodeModal, setShowContentTranscodeModal] = useState<
    boolean
  >(false);

  const {
    assetContentTypes,
    assetContentStreamTypes,
    action,
    isProcessing,
  } = useSelector(assetSelector);

  const getAssetContentTypes = useCallback(
    () => dispatch(AssetStore.Actions.getAssetContentTypes()),
    [dispatch]
  );
  const getAssetContentStreamTypes = useCallback(
    () => dispatch(AssetStore.Actions.getAssetContentStreamTypes()),
    [dispatch]
  );
  const deleteAssetContent = useCallback(
    (data: IAssetContentModel) =>
      dispatch(AssetStore.Actions.deleteAssetContent(data)),
    [dispatch]
  );

  const cleanupAfterActions = () => {
    setShowContentPathModal(false);
    setShowContentUploadModal(false);
    setEditableContent({});
    refreshAsset();
  };

  useEffect(() => {
    getAssetContentTypes();
    getAssetContentStreamTypes();
  }, []);

  useEffect(() => {
    const shouldRefresh = asset?.Contents?.some(({ ContentStatusCode }) =>
      STATUSES_REQUIRING_UPDATE.includes(ContentStatusCode as ContentStatus)
    );

    if (shouldRefresh) {
      if (refreshInterval === 0) {
        setRefreshInterval(AUTO_REFRESH_INTERVAL);
      }
    } else {
      setRefreshInterval(0);
    }
  }, [asset?.Contents]);

  useEffect(() => {
    switch (action?.type) {
      case AssetStore.Consts.ADD_ASSET_CONTENT_SUCCESS:
        cleanupAfterActions();
        notification.success({
          message: t("ADD_ASSET_CONTENT_SUCCESS"),
        });
        break;
      case AssetStore.Consts.ADD_ASSET_CONTENT_FAILURE:
        notification.error({
          message: t("ADD_ASSET_CONTENT_FAILURE"),
          description: action?.error?.Message,
        });
        break;
      case AssetStore.Consts.UPDATE_ASSET_CONTENT_SUCCESS:
        cleanupAfterActions();
        notification.success({
          message: t("UPDATE_ASSET_CONTENT_SUCCESS"),
        });
        break;
      case AssetStore.Consts.UPDATE_ASSET_CONTENT_FAILURE:
        notification.error({
          message: t("UPDATE_ASSET_CONTENT_FAILURE"),
          description: action?.error?.Message,
        });
        break;
      case AssetStore.Consts.DELETE_ASSET_CONTENT_SUCCESS:
        cleanupAfterActions();
        notification.success({
          message: t("DELETE_ASSET_CONTENT_SUCCESS"),
        });
        break;
      case AssetStore.Consts.DELETE_ASSET_CONTENT_FAILURE:
        notification.error({
          message: t("DELETE_ASSET_CONTENT_FAILURE"),
          description: action?.error?.Message,
        });
        break;
    }
  }, [action]);

  const getColumnsProps = (): Array<ITableColumnProps<IAssetContentModel>> => {
    const contentTypeColumn: ITableColumnProps<IAssetContentModel> = {
      key: "ContentTypeDisplayName",
      dataIndex: "ContentTypeDisplayName",
      title: t("ASSET_CONTENT_TYPE"),
      width: "170px",
      align: "center",
      filters: assetContentTypes?.data?.map((type) => ({
        text: type.DisplayName,
        value: type.Code,
      })),
      filteredValue: stateFilter.AssetContentTypeDisplayNames || null,
      onFilter: (value: ColumnValue, record: IAssetContentModel) =>
        record.ContentTypeCode!.includes(`${value}`),
      render: (value, record: IAssetContentModel) => (
        <>
          <Tag colorRotate={value}>{value}</Tag>
          {record.DrmProvider && <Tag color="#404040">DRM</Tag>}
        </>
      ),
    };

    const streamTypeColumn: ITableColumnProps<IAssetContentModel> = {
      key: "StreamTypeDisplayName",
      dataIndex: "StreamTypeDisplayName",
      title: t("ASSET_CONTENT_STREAM_TYPE_LABEL"),
      width: "150px",
      align: "center",
      filters: assetContentStreamTypes?.data?.map((type) => ({
        text: type.DisplayName,
        value: type.Code,
      })),
      filteredValue: stateFilter.AssetStreamTypeDisplayNames || null,
      onFilter: (value: ColumnValue, record: IAssetContentModel) =>
        record.StreamTypeCode!.includes(`${value}`),
      render: (value, record: IAssetContentModel) => (
        <Tag colorRotate={value}>{record.StreamTypeDisplayName}</Tag>
      ),
    };

    const urlColumn: ITableColumnProps<IAssetContentModel> = {
      key: `Url-${tableView}`,
      dataIndex: "Url",
      width: "200px",
      title: t("COMMON_URL"),
      ...setTableColumnSearchProps("Url"),
      onFilter: (value: ColumnValue, record: IAssetContentModel) =>
        record.Url!.includes(`${value}`),
      filteredValue: stateFilter.Url ? [stateFilter.Url] : null,
      render: (_, record: IAssetContentModel) => {
        if (!record.Url) {
          return null;
        }

        return (
          <span
            title={record.Url}
            className="AssetContentList__TableColumn__Url"
            onClick={() => {
              setEditableContent(record);
              setShowContentPathModal(true);
            }}
          >
            <Text ellipsis copyable>
              {record.Url}
            </Text>
          </span>
        );
      },
    };

    const duratioColumn: ITableColumnProps<IAssetContentModel> = {
      key: "DurationMiliseconds",
      dataIndex: "DurationMiliseconds",
      title: t("MODEL_DURATION_MILISECONDS"),
      width: "120px",
      align: "center",
      render: (value) => {
        if (value) {
          return TimeHelper.formatPlayTimeFromMiliseconds(value);
        }

        return null;
      },
    };

    const contentStatusColumn: ITableColumnProps<IAssetContentModel> = {
      key: "ContentStatusDisplayName",
      dataIndex: "ContentStatusDisplayName",
      title: t("COMMON_STATUS"),
      width: "160px",
      filters: ContentStatusHelper.getFilterOptions(),
      filteredValue: stateFilter.AssetContentStatusDisplayNames || null,
      onFilter: (value: ColumnValue, record: IAssetContentModel) =>
        record.ContentStatusCode!.includes(`${value}`),
      align: "center",
      render: (_, row: IAssetContentModel) => {
        const additionalValue =
          row.ContentStatusCode === ContentStatus.Processing &&
          row.PercentComplete
            ? ` ${row.PercentComplete}%`
            : undefined;
        const statusTag = ContentStatusHelper.getTag(
          row.ContentStatusCode,
          undefined,
          additionalValue
        );

        let statusInfo =
          row.ContentStatusCode === ContentStatus.Error && row.ErrorMessage ? (
            <Button
              icon={<Icon type="info-circle" />}
              title={t("BUTTON_ERROR_INFO_TITLE")}
              onClick={() => {
                modal.error({
                  title: t("ASSET_CONTENT_TRANSCODING_ERROR_TITLE"),
                  icon: <Icon type="exclamation-circle" />,
                  content: row.ErrorMessage,
                });
              }}
            />
          ) : null;

        return (
          <>
            {statusTag}
            {statusInfo}
          </>
        );
      },
    };

    const transcoderColumn: ITableColumnProps<IAssetContentModel> = {
      key: "Transcoder",
      dataIndex: "Transcoder",
      title: t("ASSET_CONTENT_TRANSCODER_LABEL"),
      align: "center",
      width: "120px",
      render: (value, record: IAssetContentModel) => {
        if (!value) {
          return null;
        }

        return <Tag colorRotate={value}>{record.Transcoder}</Tag>;
      },
    };

    const transcoderJobIdColumn: ITableColumnProps<IAssetContentModel> = {
      key: "TranscoderJobId",
      dataIndex: "TranscoderJobId",
      title: t("ASSET_CONTENT_TRANSCODER_JOB_ID_LABEL"),
      align: "center",
      width: "150px",
      render: (value, record: IAssetContentModel) => {
        if (!value) {
          return null;
        }

        return (
          <Text ellipsis copyable>
            {value}
          </Text>
        );
      },
    };

    const previewColumn: ITableColumnProps<IAssetContentModel> = {
      key: "Preview",
      dataIndex: "Preview",
      title: t("COMMON_PREVIEW"),
      width: "90px",
      ellipsis: true,
      align: "center",
      fixed: "right",
      render: (_, record: IAssetContentModel) => {
        switch (record.ContentStatusCode) {
          case ContentStatus.Ready:
            return (
              <Button
                shape="circle"
                type="text"
                icon={<Icon type="PlayCircle" style={{ fontSize: "24px" }} />}
                onClick={() => onOpenContentPreviewModal(record)}
              />
            );
          case ContentStatus.Queued:
          case ContentStatus.Processing:
            return <Spin />;
          default:
            return null;
        }
      },
    };

    const actionsColumn: ITableColumnProps<IAssetContentModel> = {
      key: "Actions",
      dataIndex: "Actions",
      align: "center",
      fixed: "right",
      width: "140px",
      title: t("TABLE_ACTIONS_COLUMN"),
      render: (_, assetContent: IAssetContentModel) => (
        <div className="AssetContentList__TableColumn-actions">
          <EditExternalAsset
            asset={assetContent}
            externalSource={assetContent.ExternalSource}
            assetSourceEntities={sources}
            type={EditType.AssetDetails}
          />
          {!ASSET_CONTENT_UPLOAD_DISABLED &&
            assetContent.StreamTypeCode == StreamType.Source && (
              <Button
                key="transcode"
                icon={<Icon type="cloud-sync" />}
                onClick={() => {
                  setEditableContent(assetContent);
                  setShowContentTranscodeModal(true);
                }}
                title={t("BUTTON_TRANSCODE")}
              />
            )}
          <Button
            icon={<Icon type="edit" />}
            title={t("BUTTON_EDIT")}
            onClick={() => {
              setEditableContent(assetContent);
              setShowContentPathModal(true);
            }}
          />
          <Popconfirm
            title={t("DELETE_ELEMENT_DOUBLE_CONFIRMATION_QUESTION")}
            onConfirm={async (e?: React.MouseEvent<HTMLElement>) => {
              e?.preventDefault();
              await deleteAssetContent(assetContent);
            }}
            okText={t("BUTTON_YES")}
            cancelText={t("BUTTON_NO")}
          >
            <Button
              danger={true}
              icon={<Icon type="delete" />}
              title={t("BUTTON_DELETE")}
            />
          </Popconfirm>
        </div>
      ),
    };

    if (tableView === "full") {
      return [
        contentTypeColumn,
        streamTypeColumn,
        urlColumn,
        duratioColumn,
        contentStatusColumn,
        transcoderColumn,
        transcoderJobIdColumn,
        previewColumn,
        actionsColumn,
      ];
    }

    return [
      contentTypeColumn,
      streamTypeColumn,
      urlColumn,
      duratioColumn,
      contentStatusColumn,
      previewColumn,
      actionsColumn,
    ];
  };

  const onOpenContentPreviewModal = async (
    assetContent: IAssetContentModel
  ) => {
    const isDrmProtected = Boolean(assetContent.DrmProvider);

    if (isDrmProtected) {
      if (
        assetContent.ContentTypeCode === AssetContentType.HLS &&
        !isSafariBrowser
      ) {
        notification.error({
          message: t("PREVIEW_PROTECTED_HLS_NOT_SUPPORTED"),
        });
        return;
      }

      if (
        assetContent.ContentTypeCode === AssetContentType.MPD &&
        isSafariBrowser
      ) {
        notification.error({
          message: t("PREVIEW_PROTECTED_MPD_NOT_SUPPORTED"),
        });
        return;
      }
    }

    setShowContentPreviewModal(true);
    setPreviewContent(assetContent);
  };

  const onOpenContentPathModal = () => {
    setShowContentPathModal(true);
  };

  const onOpenContentUploadModal = () => {
    setShowContentUploadModal(true);
  };

  const onCancelContentPreviewModal = () => {
    setShowContentPreviewModal(false);
    setPreviewContent(null);
    destroyAllModals();
  };

  const onCancelContentPathModal = () => {
    setShowContentPathModal(false);
    setEditableContent({});
    destroyAllModals();
  };

  const onCancelContentUploadModal = () => {
    setShowContentUploadModal(false);
    setEditableContent({});
    destroyAllModals();
  };

  const onSuccessContentUploadModal = () => {
    refreshAsset();
    setShowContentUploadModal(false);
    setEditableContent({});
    destroyAllModals();
  };

  const onCancelContentTranscodeModal = () => {
    setShowContentTranscodeModal(false);
    setEditableContent({});
    destroyAllModals();
  };

  const onSuccessContentTranscodeModal = () => {
    refreshAsset();
    setShowContentTranscodeModal(false);
    setEditableContent({});
    destroyAllModals();
  };

  const onDeleteAssetContent = () => {
    deleteAssetContent(editableContent);
    destroyAllModals();
  };

  const onTableChange = (
    pagination: ITablePaginationConfig,
    filters: ITableFilter
  ) => {
    const filter: IAssetSearchFilterModel = { ...stateFilter };
    filter.PageNumber = pagination.current!;
    filter.PageSize = pagination.pageSize;

    filter.Url =
      filters.Url && filters.Url.length ? `${filters.Url[0]}` : undefined;

    filter.AssetContentTypeDisplayNames = filters.ContentTypeDisplayName?.length
      ? filters.ContentTypeDisplayName.map((row) => `${row}`)
      : undefined;

    filter.AssetContentStatusDisplayNames = filters.ContentStatusDisplayName
      ?.length
      ? filters.ContentStatusDisplayName.map((row) => `${row}`)
      : undefined;

    filter.AssetStreamTypeDisplayNames = filters.StreamTypeDisplayName?.length
      ? filters.StreamTypeDisplayName.map((row) => `${row}`)
      : undefined;

    setStateFilter(filter);
  };

  const onClearFiltersClick = () => {
    setStateFilter({});
  };

  const contentType =
    asset?.AssetTypeCode === AssetType.Podcast ? "audio" : "video";

  const showProperPlayer = () => {
    if (!showContentPreviewModal || !previewContent?.Url) {
      return;
    }

    if (
      asset?.AssetTypeCode === AssetType.Podcast &&
      !asset.ExternalId &&
      asset.ExternalSource !== "MCLS"
    ) {
      return <audio src={previewContent.Url} controls />;
    }

    if (asset?.ExternalId && asset.ExternalSource === "MCLS") {
      return (
        <MycujooPlayer
          eventId={asset.ExternalId}
          publicKey={process.env.REACT_APP_PLAYER_MCLS_PUBLIC_TOKEN || ""}
          showPictureInPicture={false}
          autoplay
        />
      );
    } else {
      return (
        <Player
          assetContent={previewContent}
          allowCrossSiteCredentials={previewContent.Transcoder !== "MUX"}
        />
      );
    }
  };

  return (
    <div className="AssetContentList">
      <Heading
        title={t("ASSET_CONTENT_LIST_TITLE")}
        actions={
          <>
            <Dropdown
              menu={{
                items: [
                  {
                    label: t("TABLE_VIEW_OPTION_STANDARD"),
                    key: "standard",
                  },
                  {
                    label: t("TABLE_VIEW_OPTION_FULL"),
                    key: "full",
                  },
                ],
                selectedKeys: [tableView],
                onClick: (e) => {
                  setTableView(e.key);
                },
              }}
            >
              <Button
                key="view-change"
                shape="circle"
                icon={<Icon type="folder-view" />}
                title={t("MENU_OPTION_CHANGE_VIEW")}
              />
            </Dropdown>
            <Button
              key="clear-filters"
              shape="circle"
              icon={<Icon component={FilterCleanIcon} />}
              onClick={onClearFiltersClick}
              title={t("MENU_OPTION_CLEAR_FILTERS")}
            />
            {!ASSET_CONTENT_PATH_DISABLED && (
              <Button
                key="add"
                type="primary"
                shape="circle"
                icon={<Icon type="link" />}
                onClick={onOpenContentPathModal}
                title={t("ASSET_CONTENT_LIST_ADD_CONTENT_URL_TITLE", {
                  contentType,
                })}
              />
            )}
            {!ASSET_CONTENT_UPLOAD_DISABLED && (
              <Button
                key="upload"
                type="primary"
                shape="circle"
                icon={<Icon type="cloud-upload" />}
                onClick={onOpenContentUploadModal}
                title={t("ASSET_CONTENT_LIST_ADD_CONTENT_FILE_TITLE", {
                  contentType,
                })}
              />
            )}
          </>
        }
      />
      <Table<IAssetContentModel>
        key={`asset-contents-${tableView}`}
        columns={getColumnsProps()}
        dataSource={asset?.Contents}
        rowKey="Id"
        loading={isProcessing}
        pagination={false}
        scroll={{
          x: 1130,
        }}
        onChange={onTableChange}
      />
      <Modal
        open={showContentPreviewModal}
        footer={null}
        onCancel={onCancelContentPreviewModal}
        preview
        destroyOnClose
      >
        {showProperPlayer()}
      </Modal>
      <FormModal
        isVisible={showContentPathModal}
        isLoading={isProcessing}
        isNewForm={editableContent.Id ? false : true}
        isDeleteButtonEnabled={editableContent.Id ? true : false}
        createFormTitle={t("ASSET_CONTENT_FORM_ADD_TITLE")}
        editFormTitle={t("ASSET_CONTENT_FORM_EDIT_TITLE")}
        modalClassName="AssetContentPathForm"
        submitFormName="AssetContentPathForm"
        onCloseModal={onCancelContentPathModal}
        onDeleteButtonClick={onDeleteAssetContent}
      >
        <AssetContentPathModal asset={asset} assetContent={editableContent} />
      </FormModal>
      <AssetContentUploadModal
        visible={showContentUploadModal}
        asset={asset}
        assetContent={editableContent}
        onCancel={onCancelContentUploadModal}
        onSuccess={onSuccessContentUploadModal}
      />
      <AssetContentTranscodeModal
        visible={showContentTranscodeModal}
        asset={asset}
        assetContent={editableContent}
        onCancel={onCancelContentTranscodeModal}
        onSuccess={onSuccessContentTranscodeModal}
      />
    </div>
  );
};
