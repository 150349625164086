import * as React from "react";
import { Avatar as AntAvatar } from "antd";
import { AvatarProps } from "antd/lib/avatar";

export const Avatar: React.FC<AvatarProps> = (props) => {
  const { children, icon } = props;

  return (
    <AntAvatar icon={icon} {...props}>
      {children}
    </AntAvatar>
  );
};
