/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export class ConvertHelper {
  static toString(value: any): string {
    if (value === null || value === undefined) {
      return "";
    }

    return `${value}`;
  }

  static toNumber(value: any): number | undefined {
    if (value === null || value === undefined) {
      return undefined;
    }

    return +value;
  }
}
