import React from "react";
import { Choose, ChooseOption, Form } from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { IAssetFormFieldCommonProps } from "./IAssetFormFieldCommonProps";
import { useSelector } from "react-redux";
import { AssetStore, IAssetModel } from "@bms/common-services";

interface IAgeRestrictionProps extends IAssetFormFieldCommonProps {
  parent?: IAssetModel;
}

export const AgeRestrictionField = ({
  isHidden,
  isEditMode,
  asset,
  parent,
}: IAgeRestrictionProps) => {
  const { t } = useTranslation();

  const {
    assetAgeRestrictions: {
      data: assetAgeRestrictionsData = [],
      isFetching: assetAgeRestrictionsDataLoading,
    },
  } = useSelector(AssetStore.Selectors.assetSelector);

  if (isHidden) {
    return null;
  }

  return (
    <Form.Item
      name="AssetAgeRestrictionValueMin"
      label={t("MODEL_AGE_RESTRICTION")}
      key="ageRestriction"
      initialValue={
        isEditMode
          ? asset?.AssetAgeRestrictionValueMin
          : parent?.AssetAgeRestrictionValueMin
      }
    >
      <Choose
        placeholder={t("MODEL_AGE_RESTRICTION_PLACEHOLDER")}
        loading={assetAgeRestrictionsDataLoading}
        disabled={assetAgeRestrictionsDataLoading}
      >
        {assetAgeRestrictionsData.map((assetAgeRestriction) => (
          <ChooseOption
            key={assetAgeRestriction.ValueMin}
            value={assetAgeRestriction.ValueMin}
          >
            {assetAgeRestriction.ValueMin}
          </ChooseOption>
        ))}
      </Choose>
    </Form.Item>
  );
};
