import { AxiosInstance, AxiosRequestConfig, CancelTokenSource } from "axios";

/**
 * Temporary added REACT_APP_TENANT_ORIGIN_URL to env to fast switch between API instances.
 */
const tenantOriginUrl = process.env.REACT_APP_TENANT_ORIGIN_URL || "";

export class HttpClient {
  public http: AxiosInstance;
  public cancelToken?: CancelTokenSource;

  constructor(
    axiosInstance: AxiosInstance,
    cancelTokenSource: CancelTokenSource
  ) {
    this.cancelToken = cancelTokenSource;
    this.http = axiosInstance;
  }

  public async get(url: string, config?: AxiosRequestConfig) {
    const cfg = this.mergeConfig(config);
    return this.http.get(url, cfg);
  }

  public async post(url: string, data: any, config?: AxiosRequestConfig) {
    return this.http.post(url, data, this.mergeConfig(config));
  }

  public async put(url: string, data: any, config?: AxiosRequestConfig) {
    return this.http.put(url, data, this.mergeConfig(config));
  }

  public async patch(url: string, data: any, config?: AxiosRequestConfig) {
    return this.http.patch(url, data, this.mergeConfig(config));
  }

  public async delete(url: string, config?: AxiosRequestConfig) {
    return this.http.delete(url, this.mergeConfig(config));
  }

  public async head(url: string, config?: AxiosRequestConfig) {
    return this.http.head(url, this.mergeConfig(config));
  }

  public async cancel() {
    return this.cancelToken?.cancel();
  }

  private mergeConfig(config: any = {}) {
    return {
      ...config,
      cancelToken: this.cancelToken?.token,
      headers: {
        ...(tenantOriginUrl ? { "X-TenantOrigin": tenantOriginUrl } : {}),
        ...config.headers,
      },
    };
  }
}
