import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ConfigProvider as AntConfigProvider } from "antd";
import { ConfigProviderProps as AntConfigProviderProps } from "antd/lib/config-provider";
import plPL from "antd/lib/locale/pl_PL";
import enEn from "antd/lib/locale/en_US";
import { theme } from "../../theme";

type Locale = any;

function getLocale(language: string): Locale {
  switch (language) {
    case "pl":
      return plPL;
    case "en":
      return enEn;
    default:
      return enEn;
  }
}

export interface IConfigProviderProps extends AntConfigProviderProps {}

export const ConfigProvider: React.FC<IConfigProviderProps> = ({
  children,
  ...rest
}: IConfigProviderProps) => {
  const { i18n } = useTranslation();
  const [locale, setLocale] = useState<Locale>(
    getLocale(i18n.language || "en")
  );

  const onLanguageChanged = async (lng: string) => {
    setLocale(getLocale(lng || "en"));
  };

  useEffect(() => {
    i18n.on("languageChanged", onLanguageChanged);

    return () => {
      i18n.off("languageChanged", onLanguageChanged);
    };
  }, []);

  return (
    <AntConfigProvider theme={theme} locale={locale} {...rest}>
      {children}
    </AntConfigProvider>
  );
};
