import React from "react";
import { GradientInputBorder } from "./";

export const GradientInputContainer: React.FC<{ children?: React.ReactNode }> = (props) => {
  return (
    <div className="gm-gradient-input-container">
      {props.children}
      <GradientInputBorder />
    </div>
  );
};
