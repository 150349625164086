import {
  ApplicationConfigurationStore,
  ICommonAppState,
} from "@bms/common-services";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, Spin } from "@bms/common-ui";
import "./TranslationExportModal.scss";

const applicationConfigurationSelector = (state: ICommonAppState) =>
  state.applicationConfiguration;

interface TranslationExportModalProps {
  visible: boolean;
  closeModal: () => void;
}

export const TranslationExportModal: React.FC<TranslationExportModalProps> = (
  props
) => {
  const { visible, closeModal } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { translationsExport } = useSelector(applicationConfigurationSelector);

  const exportTranslations = useCallback(
    () => dispatch(ApplicationConfigurationStore.Actions.exportTranslations()),
    [dispatch]
  );

  useEffect(() => {
    if (visible) {
      exportTranslations();
    }
  }, [visible]);

  const renderForm = () => {
    if (translationsExport.Data) {
      const url = URL.createObjectURL(translationsExport.Data);

      return (
        <Button type="primary" href={url}>
          Download
        </Button>
      );
    }

    return <Spin tip={t("LOADING_STATUS")} />;
  };

  return (
    <Modal
      title={t("TRANSLATION_EXPORT_TRANSLATION")}
      footer={null}
      visible={visible}
      destroyOnClose={true}
      onCancel={closeModal}
    >
      <div className="TranslationExportModal">{renderForm()}</div>
    </Modal>
  );
};
