import React, { memo, useState, useEffect } from "react";
import {
  UserService,
  IAnalyticsAssetImpressionsFilterModel,
  IUserModel,
} from "@bms/common-services";
import { useTranslation } from "react-i18next";
import { LazyChoose } from "../../../../../components";
import { useAppFeedback } from "@bms/common-ui";

interface IAssetImpressionsSelectProps {
  filter: IAnalyticsAssetImpressionsFilterModel;
  onChange?: (newValue: number[]) => void;
}

const userService = new UserService().promisify();

export const AssetImpressionsUserSelect = memo(
  ({ filter, onChange }: IAssetImpressionsSelectProps) => {
    const { t } = useTranslation();
    const { notification } = useAppFeedback();

    const [value, setValue] = useState<IUserModel[]>([]);

    useEffect(() => {
      if (!filter.Users) {
        setValue([]);
      }
    }, [filter.Users]);

    const onValueChange = (value: IUserModel[] | IUserModel) => {
      const newValue = Array.isArray(value) ? value : [value];
      setValue(newValue);

      if (onChange) {
        onChange(newValue.map((row) => row.Id));
      }
    };

    return (
      <LazyChoose<IUserModel, IUserModel>
        style={{ width: "100%" }}
        value={value}
        loader={(search: string) =>
          userService.search({
            PageNumber: 1,
            PageSize: 100,
            FullTextSearch: search,
          })
        }
        candidateToOption={(user) => ({
          label: `${user.FullName} (${user.Email})`,
          value: `${user.Id}`,
          item: user,
        })}
        selectedToOption={(user) => ({
          label: `${user.FullName || user.Email}`,
          value: `${user.Id}`,
          item: user,
        })}
        placeholder={t("ASSET_IMPRESSIONS_USERS_PLACEHOLDER")}
        onChange={onValueChange}
        onError={(error) =>
          notification.error({
            message: error.Title,
            description: error.Message,
          })
        }
        getPopupContainer={(trigger) => trigger.parentNode}
        multiple
      />
    );
  }
);
