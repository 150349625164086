import { IAssetListModel, IAssetModel } from "@bms/common-services";
import { Title } from "@bms/common-ui";
import React from "react";
import { WithTranslation } from "react-i18next";
import { Action, ActionCreator } from "redux";
import { WithScreenDesignerContextProps } from "../../context";
import { PlayListComponentPropertyModel } from "../../models";
import {
  ComponentPreviewBase,
  IComponentPreviewBaseProps,
  IComponentPreviewBaseState,
} from "../ComponentPreviewBase";
import "./PlayListComponentPreview.scss";
import { PlayListItemComponentPreview } from "./PlayListItemComponentPreview";

export interface IPlayListComponentPreviewStateProps {
  sources: { [key: string]: IAssetListModel };
}

export interface IPlayListComponentPreviewDispatchProps {
  getDataSource: ActionCreator<Action>;
}

export interface IPlayListComponentPreviewOwnProps
  extends IComponentPreviewBaseProps {
  properties: PlayListComponentPropertyModel;
}

export interface IPlayListComponentPreviewProps
  extends IPlayListComponentPreviewStateProps,
    IPlayListComponentPreviewDispatchProps,
    IPlayListComponentPreviewOwnProps,
    WithTranslation,
    WithScreenDesignerContextProps {}

export interface IPlayListComponentPreviewState
  extends IComponentPreviewBaseState {}

export class PlayListComponentPreview extends ComponentPreviewBase<
  IPlayListComponentPreviewProps,
  IPlayListComponentPreviewState
> {
  public static defaultProps = {
    sources: {},
  };

  public state: Readonly<IPlayListComponentPreviewState> = {};

  public componentDidMount() {
    const { getDataSource, sources, properties } = this.props;

    if (properties.SourceId) {
      const source = sources[properties.SourceId];

      if (!source) {
        getDataSource(properties.SourceId);
      }
    }
  }

  componentDidUpdate(prevProps: IPlayListComponentPreviewProps) {
    const { getDataSource, sources, properties } = this.props;

    if (
      properties?.SourceId !== prevProps.properties?.SourceId &&
      properties?.SourceId
    ) {
      const source = sources[properties.SourceId];

      if (!source) {
        getDataSource(properties.SourceId);
      }
    }
  }

  private getSource = (): IAssetListModel => {
    const { properties, sources } = this.props;

    let source: IAssetListModel | undefined;

    if (properties.SourceId) {
      source = sources[properties.SourceId];
    }

    if (!source || source.IsLoading || source.Error) {
      source = {
        Entities: [],
        TotalCount: properties.VisibleItemsCount + 1,
        Filter: {
          PageNumber: 1,
          PageSize: properties.VisibleItemsCount + 1,
          IncludeCount: true,
        },
      };

      for (let i = 0; i <= properties.VisibleItemsCount; i++) {
        source.Entities.push({
          Id: -i,
          Title: `Asset ${i} title...`,
          Description: `Asset ${i} description...`,
        });
      }
    }

    return source;
  };

  private renderItems = () => {
    const source = this.getSource();

    if (!source || !source.Entities) {
      return [];
    }

    const itemsView = source.Entities.map(
      (asset: IAssetModel, index: number) => (
        <PlayListItemComponentPreview
          key={`${asset.Id}_${index}`}
          asset={asset}
          isPlaceholder={false}
        />
      )
    );

    return itemsView;
  };

  public renderTitle = () => {
    const { properties } = this.props;

    return (
      properties.Title && (
        <div className="PlayListComponentPreview--title">
          <Title level={4}>{properties.Title}</Title>
        </div>
      )
    );
  };

  public render() {
    const { component } = this.props;

    return (
      <div key={component.Id} className="PlayListComponentPreview">
        {this.renderTitle()}
        <div className="PlayListComponentPreview--content">
          {this.renderItems()}
        </div>
      </div>
    );
  }
}
