import { Observable, Observer } from "rxjs";
import { HTTP_METHOD } from "../../constants/http";
import { IUserClassQuizCategoryModel } from "../../models";
import { AxiosSubscriber } from "../AxiosSubscriber";

export class UserClassQuizCategoryService {
  get url(): string {
    return "/UsersClassesQuizCategories";
  }

  public selectByUser = (
    userId: string
  ): Observable<IUserClassQuizCategoryModel[]> =>
    new Observable(
      (observer: Observer<IUserClassQuizCategoryModel[]>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: {
              userId: userId,
            },
          },
          method: HTTP_METHOD.GET,
          url: `${this.url}/SelectByUser`,
        })
    );

  public selectByCurrentUser = (): Observable<IUserClassQuizCategoryModel[]> =>
    new Observable(
      (observer: Observer<IUserClassQuizCategoryModel[]>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `${this.url}/SelectByCurrentUser`,
        })
    );

  public updateCollection = (
    data: IUserClassQuizCategoryModel[]
  ): Observable<IUserClassQuizCategoryModel[]> =>
    new Observable(
      (observer: Observer<IUserClassQuizCategoryModel[]>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.PUT,
          url: `${this.url}/UpdateCollection`,
        })
    );
}
