import { IResetPasswordModel, IUserModel } from "@bms/common-services";
import {
  Button,
  Col,
  Form,
  IFormInstance,
  IFormValues,
  Input,
  Row,
  useValidate,
} from "@bms/common-ui";
import { FormModal } from "../../../../components";
import React from "react";
import { useTranslation, WithTranslation } from "react-i18next";
import { Action, ActionCreator } from "redux";

const PasswordField: React.FC = () => {
  const [t] = useTranslation();
  const validate = useValidate();

  return (
    <Form.Item
      name="Password"
      label={t("USER_ACCOUNT_FORM_PASSWORD_NEW_LABEL", "New password")}
      rules={[...validate.required(), ...validate.password()]}
    >
      <Input
        type="password"
        placeholder={t(
          "USER_ACCOUNT_FORM_PASSWORD_NEW_PLACEHOLDER",
          "Enter new password"
        )}
      />
    </Form.Item>
  );
};

interface ConfirmPasswordFieldProps {
  formRef: React.RefObject<IFormInstance>;
}

const ConfirmPasswordField: React.FC<ConfirmPasswordFieldProps> = ({
  formRef,
}) => {
  const [t] = useTranslation();
  const validate = useValidate();

  return (
    <Form.Item
      name="ConfirmPassword"
      label={t("USER_ACCOUNT_FORM_PASSWORD_VERIFY_LABEL", "Verify password")}
      rules={[
        ...validate.required(),
        ...validate.passwordConfirm(formRef, "Password"),
      ]}
    >
      <Input
        type="password"
        placeholder={t(
          "USER_ACCOUNT_FORM_PASSWORD_VERIFY_PLACEHOLDER",
          "Enter verify password"
        )}
      />
    </Form.Item>
  );
};

export interface IUserResetPasswordModalProps extends WithTranslation {
  actionType?: string;
  user: IUserModel;
  resetUserPassword: ActionCreator<Action>;
  isVisible: boolean;
  isProcessingData: boolean;
  onCancel: () => void;
}

export class UserResetPasswordModal extends React.PureComponent<
  IUserResetPasswordModalProps
> {
  public static defaultProps = {
    isProcessingData: false,
  };

  formRef = React.createRef<IFormInstance>();

  public onFinish = (values: IFormValues) => {
    const { resetUserPassword, user } = this.props;

    const payload: IResetPasswordModel = {
      Id: user.Id,
      Password: values.Password,
      ConfirmPassword: values.ConfirmPassword,
    };

    resetUserPassword(payload);
  };

  public render() {
    const { isVisible, isProcessingData, onCancel, t } = this.props;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 12 },
        md: { span: 10 },
        lg: { span: 8 },
        xl: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 },
        md: { span: 14 },
        lg: { span: 16 },
        xl: { span: 16 },
      },
    };

    return (
      <FormModal
        isLoading={isProcessingData}
        isVisible={isVisible}
        isNewForm={true}
        isDeleteButtonEnabled={false}
        createFormTitle={t("USER_DETAILS_TAB_CHANGE_PASSWORD_TITLE")}
        editFormTitle={t("USER_DETAILS_TAB_CHANGE_PASSWORD_TITLE")}
        modalClassName="UserResetPasswordModal"
        submitFormName="UserResetPasswordForm"
        onCloseModal={onCancel}
        onDeleteButtonClick={()=>{}}
      >
        <Form
          name="UserResetPasswordForm"
          {...formItemLayout}
          className="UserResetPasswordForm"
          onFinish={this.onFinish}
          ref={this.formRef}
        >
          <Row
            direction="column"
            justify="space-between"
            className="full-height"
          >
            <Col>
              <PasswordField />
              <ConfirmPasswordField formRef={this.formRef} />
            </Col>
          </Row>
        </Form>
      </FormModal>
    );
  }
}
