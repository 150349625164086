import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  IBreadcrumbProps,
  IDateRangePickerProps,
  DatePicker,
  Button,
  Icon,
  PageHeader,
} from "@bms/common-ui";
import {
  IAnalyticsUserPlatformRatingFilterModel,
  TimeHelper,
} from "@bms/common-services";
import { ROUTES } from "../../../constants/routes";
import { generateBreadcrumb } from "../../../../../helpers/breadcrumb";
import { RatingAppAggregationTypeSelect } from "../RatingAppAggregationTypeSelect";
import { FilterCleanIcon } from "../../../../../resources/icons";
import { RatingAppPlatformSelect } from "../RatingAppPlatformSelect";
import { RatingAppRateSelect } from "../RatingAppRateSelect";
import { RatingAppExportModal } from "../../RatingAppExportModal";

import "./RatingAppHeader.scss";

interface IRatingAppHeaderProps {
  filter: IAnalyticsUserPlatformRatingFilterModel;
  setFilter: <T>(
    property: keyof IAnalyticsUserPlatformRatingFilterModel,
    value: T
  ) => void;
  refreshData: () => void;
  resetFilter: () => void;
}

export const RatingAppHeader = (props: IRatingAppHeaderProps) => {
  const { t } = useTranslation();
  const { filter, setFilter, refreshData, resetFilter } = props;
  const [isRatingAppExportVisible, setIsRatingAppExportVisible] = useState(
    false
  );

  const getBreadcrumbProps = (): IBreadcrumbProps => {
    return generateBreadcrumb([
      {
        path: `${ROUTES.APP_RATING}`,
        breadcrumbName: t("RATING_APP__TITLE"),
      },
    ]);
  };

  const disabledDateFrom: IDateRangePickerProps["disabledDate"] = (current) => {
    return filter.DateTo ? current && current.isAfter(filter.DateTo) : false;
  };

  const disabledDateTo: IDateRangePickerProps["disabledDate"] = (current) => {
    return filter.DateFrom ? current?.isBefore(filter.DateFrom) : false;
  };

  return (
    <PageHeader
      title={t("RATING_APP__TITLE")}
      breadcrumb={getBreadcrumbProps()}
      extra={
        <div className="RatingAppHeader__container">
          <div className="RatingAppHeader__container-row">
            <RatingAppAggregationTypeSelect
              value={filter.Period}
              onChange={(val) => setFilter("Period", val)}
            />
            <DatePicker
              showTime={false}
              placeholder={t("RATING_APP_DATE_FROM")}
              getPopupContainer={(trigger: any) => trigger.parentNode}
              style={{ width: "100%" }}
              onChange={(val) =>
                setFilter(
                  "DateFrom",
                  val
                    ? TimeHelper.toString(
                        TimeHelper.getDateStartOf(val.toDate(), "date")
                      )
                    : undefined
                )
              }
              value={filter.DateFrom}
              disabledDate={disabledDateFrom}
            />
            <DatePicker
              showTime={false}
              placeholder={t("RATING_APP_DATE_TO")}
              getPopupContainer={(trigger: any) => trigger.parentNode}
              style={{ width: "100%" }}
              onChange={(val) =>
                setFilter(
                  "DateTo",
                  val
                    ? TimeHelper.toString(
                        TimeHelper.getDateEndOf(val.toDate(), "date")
                      )
                    : undefined
                )
              }
              value={filter.DateTo}
              disabledDate={disabledDateTo}
            />
            <Button
              key="export"
              shape="circle"
              onClick={() => setIsRatingAppExportVisible(true)}
              icon={<Icon type="export" />}
              title={t("BUTTON_EXPORT")}
            />
            <Button
              shape="circle"
              icon={<Icon type="reload" />}
              onClick={refreshData}
              title={t("BUTTON_REFRESH_TITLE")}
            />
            <Button
              shape="circle"
              icon={<FilterCleanIcon />}
              onClick={resetFilter}
              title={t("BUTTON_RESET")}
            />
          </div>
          <div className="RatingAppHeader__container-row">
            <RatingAppPlatformSelect
              filter={filter}
              onChange={(val) => setFilter("Platforms", val)}
            />
          </div>
          <div className="RatingAppHeader__container-row">
            <RatingAppRateSelect
              filter={filter}
              onChange={(val) => setFilter("Ratings", val)}
            />
          </div>
          <RatingAppExportModal
            visible={isRatingAppExportVisible}
            filter={filter}
            onCancel={() => setIsRatingAppExportVisible(false)}
            onSuccess={() => setIsRatingAppExportVisible(false)}
          />
        </div>
      }
    />
  );
};
