import { AuthStore } from "@bms/common-services";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { IAppState } from "../../store";
import { Navbar as NavbarDefinition } from "./Navbar";

const mapStateToProps = (state: IAppState) => ({
    currentUser: state.auth.user,
  });

const mapDispatchToProps = (dispatch: Dispatch) => ({
  signOut: () => dispatch(AuthStore.Actions.signOut()),
});

export const Navbar = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(NavbarDefinition));
