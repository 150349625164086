import React from "react";
import { useTranslation } from "react-i18next";
import { Tag, TagProps, TAG_PALETTE } from "./Tag";

export interface TagUpToDateProps extends TagProps {
  value: boolean;
}

export const TagUpToDate: React.FC<TagUpToDateProps> = (props) => {
  const { value, ...rest } = props;
  const { t } = useTranslation();

  return (
    <Tag color={value ? TAG_PALETTE.GREEN : TAG_PALETTE.RED} {...rest}>
      {value
        ? t("CLASS_LIST_TABLE_UPTODATE_COLUMN")
        : t("CLASS_LIST_TABLE_OUTTODATE_COLUMN_FILTER")}
    </Tag>
  );
};
