import dayjs from "dayjs";
import { ANONYMOUS_ID } from "../constants";
import { ITokenModel } from "../models";
import { StorageManager } from "../services/StorageManager";

export function isAuthenticated(): boolean {
  return StorageManager.getValue("session")
    ? !!StorageManager.getValue("session").Token
    : false;
}

export function isAnonymous(): boolean {
  return (
    StorageManager.getValue("user") &&
    StorageManager.getValue("user").Id === ANONYMOUS_ID
  );
}

export function hasTokenExpired(token?: ITokenModel): boolean {
  if (!token) {
    return true;
  }

  const now = dayjs();
  const expireDate = dayjs(token.TokenExpires);

  return now.isAfter(expireDate);
}
