import {
  ICommonAppState,
  INTERNAL_USERS_PROFILE_CODES,
  IUserModel,
  ProfileService,
  TimeHelper,
  useDataLoader,
  UserService,
  UserStore,
} from "@bms/common-services";
import {
  Avatar,
  Button,
  Empty,
  IBreadcrumbProps,
  Icon,
  InputSearch,
  ITableColumnProps,
  ITableFilter,
  Link,
  PageContent,
  PageHeader,
  Pagination,
  Popconfirm,
  setTableColumnSearchProps,
  Table,
  Tag,
  Tooltip,
  useAppFeedback,
} from "@bms/common-ui";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { generateBreadcrumb, useTableDataProvider } from "../../../../helpers";
import { ROUTES } from "../../constants";
import { UserCreateModalForm } from "../UserCreateModalForm";
import { UserResendConfirmationEmailModal } from "../UserResendConfirmationEmailModal";
import "./UsersList.scss";
import { FilterCleanIcon } from "../../../../resources/icons";

const usersService = new UserService().promisify();
const profileService = new ProfileService().promisify();

const AVATAR_COLORS = [
  "#1abc9c",
  "#2ecc71",
  "#3498db",
  "#9b59b6",
  "#34495e",
  "#16a085",
  "#27ae60",
  "#2980b9",
  "#8e44ad",
  "#2c3e50",
  "#f1c40f",
  "#e67e22",
  "#e74c3c",
  "#95a5a6",
  "#f39c12",
  "#d35400",
  "#c0392b",
  "#bdc3c7",
  "#7f8c8d",
];

interface IUsersListProps {
  viewType: "customers" | "administrators" | "all";
}

export const UsersList: React.FC<IUsersListProps> = ({ viewType }) => {
  const [t] = useTranslation();
  const { notification } = useAppFeedback();
  const dispatch = useDispatch();

  const [createUserModalVisible, setCreateUserModalVisible] = useState(false);
  const [
    resendConfirmationEmailModalVisible,
    setResendConfirmationEmailModalVisible,
  ] = useState(false);

  const isCustomerView = viewType === "customers";
  const isAdministratorsView = viewType === "administrators";
  const isAllView = viewType === "all";

  const profilesLoader = useDataLoader({
    loader: () => profileService.select(),
    deps: [],
  });

  const profiles =
    profilesLoader.data?.Entities?.filter(({ Code }) =>
      isCustomerView ? !INTERNAL_USERS_PROFILE_CODES.includes(Code) : true
    ) || [];

  const ExcludeProfiles = isCustomerView
    ? INTERNAL_USERS_PROFILE_CODES
    : undefined;

  const ProfilesDefault = isAdministratorsView
    ? INTERNAL_USERS_PROFILE_CODES
    : undefined;

  const userCollectionSelector = (state: ICommonAppState) => state.user;

  const { actionType, error } = useSelector(userCollectionSelector);

  useEffect(() => {
    switch (actionType) {
      case UserStore.Consts.DELETE_USER_FAILURE:
      case UserStore.Consts.ANONYMIZE_USER_FAILURE:
        return notification.error({
          message: t("USER_DELETE_USER_FAILURE", "Delete user failed"),
          description: error?.Message,
        });
      case UserStore.Consts.DELETE_USER_SUCCESS:
        notification.success({
          message: t("USER_DELETE_USER_SUCCESS"),
          description: t("USER_DELETE_USER_SUCCESS_INFO"),
        });
        usersLoader.refresh();
        break;
      case UserStore.Consts.ANONYMIZE_USER_SUCCESS:
        notification.success({
          message: t("USER_DELETE_USER_SUCCESS"),
          description: t("USER_ANONYMIZE_USER_SUCCESS_INFO"),
        });
        usersLoader.refresh();
        break;
    }
  }, [actionType]);

  const {
    dataLoader: usersLoader,
    filters,
    pagination,
    fullTextSearch,
    setFullTextSearch,
  } = useTableDataProvider({
    filtersSchema: {
      FullTextSearch: "string",
      FullName: "string",
      Email: "string",
      Profiles: "strings",
      PhoneNumber: "string",
      Locked: "boolean",
      EmailConfirmed: "boolean",
    },
    loader: (filters, pagination) =>
      usersService.search({
        ...filters,
        ...pagination,
        Profiles: filters.Profiles || ProfilesDefault,
        ExcludeProfiles,
      }),
    deps: [profilesLoader.data],
    onError: (error) =>
      notification.error({
        message: t(
          "LOADING_DATA_ERROR_MESSAGE",
          "There was an error while loading data."
        ),
        description: error.Message,
      }),
  });
  const users: IUserModel[] = usersLoader.data?.Entities || [];

  const onResendConfirmationEmailClick = () =>
    setResendConfirmationEmailModalVisible(true);

  const onResendConfirmationEmailCancel = () =>
    setResendConfirmationEmailModalVisible(false);

  const onResendConfirmationEmailSuccess = () =>
    setResendConfirmationEmailModalVisible(false);

  const onAddUserClick = () => setCreateUserModalVisible(true);

  const getColumnsProps = (): Array<ITableColumnProps<IUserModel>> => {
    return [
      {
        render: (_: any, row: IUserModel) => {
          let initials: string | undefined;
          let icon: string | undefined = "user";
          const style: React.CSSProperties = {
            verticalAlign: "middle",
          };

          initials =
            row.Initials ||
            (row.FullName || row.Email)
              ?.split(" ")
              .slice(0, 2)
              .map((str) => str[0])
              .join("")
              .toUpperCase();

          if (initials && initials.length > 0) {
            const charIndex: number = Array.from(
              row.FullName || row.Email || row.Initials || []
            )
              .map((letter) => letter.charCodeAt(0))
              .reduce((a, b) => a + b, 0);
            style.backgroundColor =
              AVATAR_COLORS[charIndex % AVATAR_COLORS.length];
            icon = undefined;
          }

          return (
            <div className="UsersList-column-user">
              <div className="UsersList-column-user-avatar">
                <Avatar
                  src={row.AvatarUrl}
                  style={style}
                  icon={icon && <Icon type={icon} />}
                  className="UsersList__Avatar"
                >
                  {initials}
                </Avatar>
              </div>
              <div className="UsersList-column-user-name">
                <Link
                  to={`${
                    isCustomerView
                      ? ROUTES.CUSTOMER_DETAILS
                      : isAdministratorsView
                      ? ROUTES.ADMINISTRATOR_DETAILS
                      : ROUTES.USER_DETAILS
                  }/${row.Id}`}
                >
                  <p>{row.FullName || row.PhoneNumber}</p>
                </Link>
              </div>
            </div>
          );
        },
        key: "FullName",
        dataIndex: "FullName",
        title: t("USERS_LIST_TABLE_FULL_NAME_COLUMN", "User"),
        filteredValue: filters.asTableArray.FullName,
        ...setTableColumnSearchProps(
          "FullName",
          t("USERS_LIST_TABLE_FULL_NAME_COLUMN", "User")
        ),
      },
      {
        key: "Email",
        dataIndex: "Email",
        title: t("USERS_LIST_TABLE_EMAIL_COLUMN", "Email"),
        filteredValue: filters.asTableArray.Email,
        ...setTableColumnSearchProps(
          "Email",
          t("USERS_LIST_TABLE_EMAIL_COLUMN", "Email")
        ),
        render: (text: any, row: IUserModel) => (
          <Link to={`${ROUTES.USER_DETAILS}/${row.Id}`}>{text}</Link>
        ),
      },
      {
        key: "Profiles",
        dataIndex: "Profiles",
        title: t("USERS_LIST_TABLE_PROFILE_COLUMN", "Profile"),
        filters: profiles.map((profile) => ({
          text: profile.Name,
          value: profile.Code,
        })),
        filteredValue: isAdministratorsView
          ? undefined
          : filters.asTableArray.Profiles || null,
        render: (_: any, row: IUserModel) =>
          (row.Profiles || []).map((profile) => (
            <Tag
              key={profile.ProfileId}
              colorRotate={profile.ProfileId}
              style={{ margin: "2px 6px 2px 0", padding: "4px 8px" }}
            >
              {profile.ProfileName}
            </Tag>
          )),
      },

      {
        key: "CreationDateTime",
        dataIndex: "CreationDateTime",
        align: "center",
        width: "140px",
        title: t("MODEL_CREATION_DATE"),
        render: (created?: string) =>
          created ? TimeHelper.format(created) : null,
      },
      // {
      //   key: "PhoneNumber",
      //   dataIndex: "PhoneNumber",
      //   title: t("USERS_LIST_TABLE_PHONE_NUMBER_COLUMN", "Phone number"),
      //   filteredValue: filters.asTableArray.PhoneNumber,
      //   ...setTableColumnSearchProps(
      //     "PhoneNumber",
      //     t("USERS_LIST_TABLE_PHONE_NUMBER_COLUMN", "Phone number")
      //   ),
      // },
      {
        key: "Locked",
        dataIndex: "Locked",
        align: "center",
        width: "150px",
        title: t("USERS_LIST_TABLE_STATUS_COLUMN", "Status"),
        filters: [
          {
            text: t("USERS_LIST_TABLE_STATUS_COLUMN_LOCKED_FILTER", "Locked"),
            value: "true",
          },
          {
            text: t("USERS_LIST_TABLE_STATUS_COLUMN_ACTIVE_FILTER", "Active"),
            value: "false",
          },
        ],
        filteredValue: filters.asTableArray.Locked,
        render: (_: any, row: IUserModel) => (
          <Tag color={row.Locked ? "#c44" : "#418841"}>
            {row.Locked
              ? t("USERS_LIST_TABLE_STATUS_COLUMN_LOCKED_FILTER", "Locked")
              : t("USERS_LIST_TABLE_STATUS_COLUMN_ACTIVE_FILTER", "Active")}
          </Tag>
        ),
      },
      {
        key: "EmailConfirmed",
        dataIndex: "EmailConfirmed",
        align: "center",
        width: "170px",
        title: t("USERS_LIST_TABLE_EMAIL_CONFIRMED_COLUMN", "Email status"),
        filters: [
          {
            text: t("USERS_LIST_TABLE_CONFIRMED_FILTER", "Confirmed"),
            value: "true",
          },
          {
            text: t("USERS_LIST_TABLE_UNCONFIRMED_FILTER", "Unconfirmed"),
            value: "false",
          },
        ],
        filteredValue: filters.asTableArray.EmailConfirmed,
        render: (_: any, row: IUserModel) => (
          <Tag color={row.EmailConfirmed ? "#418841" : "#787878"}>
            {row.EmailConfirmed
              ? t("USERS_LIST_TABLE_CONFIRMED_FILTER", "Confirmed")
              : t("USERS_LIST_TABLE_UNCONFIRMED_FILTER", "Unconfirmed")}
          </Tag>
        ),
      },
      {
        key: "Actions",
        dataIndex: "Actions",
        align: "center",
        width: "100px",
        title: t("TABLE_ACTIONS_COLUMN", "Actions"),
        render: (_: any, row: IUserModel) => (
          <Popconfirm
            title={t(
              "DELETE_ELEMENT_DOUBLE_CONFIRMATION_QUESTION",
              "Are you sure you want to delete element?"
            )}
            onConfirm={async (e?: React.MouseEvent<HTMLElement>) => {
              e?.preventDefault();
              if (row.IsDeleted) {
                dispatch(UserStore.Actions.deleteUser(row));
              } else {
                dispatch(UserStore.Actions.anonymizeUser(row));
              }
            }}
            okText={t("BUTTON_YES", "Yes")}
            cancelText={t("BUTTON_NO", "No")}
          >
            <Button
              danger={true}
              icon={<Icon type="delete" />}
              title={t("DELETE_ELEMENT", "Delete element")}
            />
          </Popconfirm>
        ),
      },
    ];
  };

  const onTableChange = (_: any, incomingFilters: ITableFilter) =>
    filters.update((oldFilters) => ({
      ...oldFilters,
      ...incomingFilters,
    }));

  const closeModal = () => setCreateUserModalVisible(false);

  const onSearch = (value: string) =>
    filters.update((oldFilters) => ({
      ...oldFilters,
      FullTextSearch: value || undefined,
    }));

  const getBreadcrumbProps = (): IBreadcrumbProps => {
    return generateBreadcrumb([
      {
        path: isCustomerView
          ? `${ROUTES.CUSTOMER_LIST}`
          : isAdministratorsView
          ? `${ROUTES.ADMINISTRATOR_LIST}`
          : `${ROUTES.USER_LIST}`,
        breadcrumbName: isCustomerView
          ? t("CUSTOMERS_LIST_TITLE")
          : isAdministratorsView
          ? t("ADMINISTRATORS_LIST_TITLE")
          : t("USERS_LIST_TITLE"),
      },
    ]);
  };

  return (
    <>
      <UserCreateModalForm
        profiles={profiles}
        visible={createUserModalVisible}
        onCancel={closeModal}
        viewType={viewType}
      />
      <UserResendConfirmationEmailModal
        usersCount={usersLoader.data?.TotalCount}
        usersLoading={usersLoader.loading}
        filters={filters.current}
        visible={resendConfirmationEmailModalVisible}
        onCancel={onResendConfirmationEmailCancel}
        onSuccess={onResendConfirmationEmailSuccess}
      />
      <PageContent
        className="UsersList"
        footer={<Pagination {...pagination.props} />}
      >
        <PageHeader
          title={
            isCustomerView
              ? t("CUSTOMERS_LIST_TITLE")
              : isAdministratorsView
              ? t("ADMINISTRATORS_LIST_TITLE")
              : t("USERS_LIST_TITLE")
          }
          breadcrumb={getBreadcrumbProps()}
          extra={[
            <InputSearch
              key="search"
              placeholder={t("SEARCH_PLACEHOLDER", "Search...")}
              value={fullTextSearch}
              onSearch={onSearch}
              onChange={({ target: { value } }) => setFullTextSearch(value)}
              style={{ width: 250 }}
              allowClear
            />,
            isCustomerView || isAllView ? (
              <Tooltip
                overlay={t(
                  "MENU_OPTION_RESEND_CONFIRMATION_EMAIL",
                  "Resend confirmation email"
                )}
              >
                <Button
                  key="resend-confirmation-email"
                  shape="circle"
                  icon={<Icon type="send" />}
                  onClick={onResendConfirmationEmailClick}
                  title={t(
                    "MENU_OPTION_RESEND_CONFIRMATION_EMAIL",
                    "Resend confirmation email"
                  )}
                />
              </Tooltip>
            ) : null,
            <Tooltip overlay={t("MENU_OPTION_CLEAR_FILTERS", "Clear filters")}>
              <Button
                key="clear-filters"
                shape="circle"
                icon={<FilterCleanIcon />}
                onClick={filters.clear}
                disabled={!filters.anyActive}
                title={t("MENU_OPTION_CLEAR_FILTERS", "Clear filters")}
              />
            </Tooltip>,
            <Button
              key="reload"
              shape="circle"
              icon={<Icon type="reload" />}
              onClick={usersLoader.refresh}
              title={t("BUTTON_REFRESH_TITLE", "Refresh data")}
            />,
            <Button
              shape="circle"
              type="primary"
              key="add"
              icon={<Icon type="plus" />}
              onClick={onAddUserClick}
              title={t("USERS_LIST_ADD_USER_ACTION_TITLE", "Add user")}
            />,
          ]}
        />
        <Table<IUserModel>
          locale={{
            emptyText: (
              <>
                <Empty />
                {filters.anyActive && (
                  <Button
                    icon={<Icon component={FilterCleanIcon} />}
                    onClick={filters.clear}
                  >
                    {t("MENU_OPTION_CLEAR_FILTERS")}
                  </Button>
                )}
              </>
            ),
          }}
          rowKey="Id"
          columns={getColumnsProps()}
          dataSource={users}
          loading={usersLoader.loading}
          pagination={false}
          onChange={onTableChange}
        />
      </PageContent>
    </>
  );
};

UsersList.defaultProps = {
  viewType: "all",
};
