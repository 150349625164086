import React from "react";
import { useTranslation } from "react-i18next";
import { Choose, ChooseOption } from "@bms/common-ui";
import { IUserPlatformRatingAnalyticsSummaryFilterModel } from "@bms/common-services";

import { useUserPlatformRatingDataProvider } from "../../../hooks";

const RATING_VALUES = [1, 2, 3, 4, 5];
interface IRatingAppRateSelectProps {
  filter: IUserPlatformRatingAnalyticsSummaryFilterModel;
  onChange: (newValue: string) => void;
}

export const RatingAppRateSelect = (props: IRatingAppRateSelectProps) => {
  const { filter, onChange } = props;
  const { t } = useTranslation();
  const { loading } = useUserPlatformRatingDataProvider();

  const onValueChange = (newValue: string) => {
    onChange?.(newValue);
  };

  return (
    <Choose
      mode="multiple"
      defaultActiveFirstOption={false}
      placeholder={t("RATING_APP_SELECT_RATING")}
      value={filter.Ratings}
      loading={loading}
      disabled={loading}
      onChange={onValueChange}
      style={{ width: "100%" }}
    >
      {RATING_VALUES.map((rating) => (
        <ChooseOption key={rating} value={rating}>
          {rating}
        </ChooseOption>
      ))}
    </Choose>
  );
};
