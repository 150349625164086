import {
  ICommonAppState
} from "@bms/common-services";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
  ActionProperties as ActionPropertiesDefinition,
  IActionPropertiesDispatchProps,
  IActionPropertiesOwnProps,
  IActionPropertiesStateProps
} from "./ActionProperties";

const mapStateToProps = (
  state: ICommonAppState
): IActionPropertiesStateProps => {
  return {
    isProcessingData: state.applicationConfiguration.isProcessingData,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): IActionPropertiesDispatchProps => ({});

export const ActionProperties = connect<
  IActionPropertiesStateProps,
  IActionPropertiesDispatchProps,
  IActionPropertiesOwnProps,
  ICommonAppState
>(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ActionPropertiesDefinition));
