import { ICommonAppState, IUserModel, UserStore } from "@bms/common-services";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
  IUserPersonalInformationFormDispatchProps,
  IUserPersonalInformationFormOwnProps,
  IUserPersonalInformationFormStateProps,
  UserPersonalInformationForm as UserPersonalInformationFormDefinition,
} from "./UserPersonalInformationForm";

const mapStateToProps = (
  state: ICommonAppState
): IUserPersonalInformationFormStateProps => {
  return {
    actionType: state.user.actionType,
    isProcessingData: state.user.isProcessingData,
    error: state.user.error,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch
): IUserPersonalInformationFormDispatchProps => ({
  updateUser: (data: IUserModel) => {
    return dispatch(UserStore.Actions.updateUser(data));
  },
});

export const UserPersonalInformationForm = connect<
  IUserPersonalInformationFormStateProps,
  IUserPersonalInformationFormDispatchProps,
  IUserPersonalInformationFormOwnProps,
  ICommonAppState
>(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(UserPersonalInformationFormDefinition));
