import {
  IApplicationComponentModel,
  IApplicationScreenModel,
} from "@bms/common-services";
import React from "react";

export interface IApplicationScreenContext {
  screen?: IApplicationScreenModel;
  component?: IApplicationComponentModel;
  onScreenChange?: (screen: IApplicationScreenModel) => void;
  onComponentAdd?: (component: IApplicationComponentModel) => void;
  onComponentSelect?: (component?: IApplicationComponentModel) => void;
  onComponentChange?: (component: IApplicationComponentModel) => void;
  onComponentsChange?: (components: IApplicationComponentModel[]) => void;
  onComponentDelete?: (componentId: number) => void;
  onNewChanges?: (hasChanges: boolean) => void;
}

export const ApplicationScreenContext = React.createContext<
  IApplicationScreenContext
>({});
export const ApplicationScreenProvider = ApplicationScreenContext.Provider;
export const ApplicationScreenConsumer = ApplicationScreenContext.Consumer;

export function useApplicationScreenContext() {
  return React.useContext<IApplicationScreenContext>(ApplicationScreenContext);
}

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export interface WithScreenContextProps {
  screen?: IApplicationScreenModel;
  component?: IApplicationComponentModel;
  onScreenChange?: (screen: IApplicationScreenModel) => void;
  onComponentAdd?: (component: IApplicationComponentModel) => void;
  onComponentSelect?: (component?: IApplicationComponentModel) => void;
  onComponentChange?: (component: IApplicationComponentModel) => void;
  onComponentsChange?: (components: IApplicationComponentModel[]) => void;
  onComponentDelete?: (componentId: number) => void;
  onNewChanges?: (hasChanges: boolean) => void;
}

export function withScreenContext<P extends WithScreenContextProps>(
  WrappedComponent: React.ComponentType<P>
) {
  type Props = JSX.LibraryManagedAttributes<P, Omit<P, "screen">>;

  return class WithScreenContext extends React.Component<Props> {
    static displayName = `WithScreenContext(${WrappedComponent.displayName})`;

    render() {
      return (
        <ApplicationScreenConsumer>
          {(context: IApplicationScreenContext) => (
            <WrappedComponent
              {...(this.props as any)}
              screen={context.screen}
              component={context.component}
              onScreenChange={context.onScreenChange}
              onComponentAdd={context.onComponentAdd}
              onComponentSelect={context.onComponentSelect}
              onComponentChange={context.onComponentChange}
              onComponentsChange={context.onComponentsChange}
              onComponentDelete={context.onComponentDelete}
              onNewChanges={context.onNewChanges}
            />
          )}
        </ApplicationScreenConsumer>
      );
    }
  };
}
