import { ICommonAppState } from "@bms/common-services";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
  SettingsMenuItemProperties as SettingsMenuItemPropertiesDefinition,
  ISettingsMenuItemPropertiesDispatchProps,
  ISettingsMenuItemPropertiesOwnProps,
  ISettingsMenuItemPropertiesStateProps,
} from "./SettingsMenuItemProperties";

const mapStateToProps = (
  state: ICommonAppState
): ISettingsMenuItemPropertiesStateProps => {
  return {
    isProcessingData: state.applicationConfiguration.isProcessingData,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch
): ISettingsMenuItemPropertiesDispatchProps => ({});

export const SettingsMenuItemProperties = connect<
  ISettingsMenuItemPropertiesStateProps,
  ISettingsMenuItemPropertiesDispatchProps,
  ISettingsMenuItemPropertiesOwnProps,
  ICommonAppState
>(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(SettingsMenuItemPropertiesDefinition));
