import {
  CurrencyService,
  ICryptoCoinPriceListModel,
  useDataLoader,
} from "@bms/common-services";
import {
  Choose,
  ChooseOption,
  Col,
  DatePicker,
  Form,
  IFormValues,
  InputNumber,
  Row,
  Spin,
  IChooseValue,
} from "@bms/common-ui";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const currencyService = new CurrencyService().promisify();

export interface ICryptoCoinPricesFormProps {
  cryptoCoinPrice: ICryptoCoinPriceListModel;
  onCreate?: (data: ICryptoCoinPriceListModel) => void;
  onUpdate?: (data: ICryptoCoinPriceListModel) => void;
}

enum purchaseTypes {
  OneTime = "OneTime",
  Subscription = "Subscription",
}

export const CryptoCoinPricesForm: React.FC<ICryptoCoinPricesFormProps> = (
  props
) => {
  const { cryptoCoinPrice, onCreate, onUpdate } = props;

  const [form] = Form.useForm();
  const { t } = useTranslation();

  const [cryptoCoinMinPrice, setCryptoCoinMinPrice] = useState(0);

  const currenciesLoader = useDataLoader({
    loader: () => currencyService.select(),
    deps: [],
  });

  const onFinish = async (values: IFormValues) => {
    let updatedAssetPrice: ICryptoCoinPriceListModel = {
      ...cryptoCoinPrice,
      Price: values.Price,
      CurrencyId: values.CurrencyId,
      Amount: values.Amount,
      AvailableFrom: values.AvailableFrom
        ? values.AvailableFrom.toISOString()
        : null,
      AvailableTo: values.AvailableTo ? values.AvailableTo.toISOString() : null,
      Type: values.Type,
    };

    if (cryptoCoinPrice.Id !== -1) {
      onUpdate?.(updatedAssetPrice);
    } else {
      onCreate?.(updatedAssetPrice);
    }
  };

  const onCurrencyChange = (value: IChooseValue) => {
    const selectedCurrency = currenciesLoader.data?.find(
      ({ Id }) => Id === value
    );

    if (selectedCurrency?.MinPayment !== undefined) {
      setCryptoCoinMinPrice(selectedCurrency.MinPayment);
    }
  };

  return (
    <Spin spinning={currenciesLoader.loading}>
      <Form
        name="CryptoCoinPricesForm"
        className="CryptoCoinPricesForm"
        form={form}
        onFinish={onFinish}
        layout="vertical"
      >
        <Row direction="column" justify="space-between" className="full-height">
          <Col>
            <Form.Item
              name="Price"
              label={t("MODEL_PRICE")}
              style={{ display: "inline-block", width: "calc(50%)" }}
              initialValue={cryptoCoinPrice.Price}
              rules={[
                {
                  required: true,
                  message: t("REQUIRED_VALIDATION_MESSAGE"),
                },
              ]}
            >
              <InputNumber
                style={{ width: "100%" }}
                placeholder={t("MODEL_PRICE_PLACEHOLDER")}
                step={0.1}
                min={cryptoCoinMinPrice}
              />
            </Form.Item>
            <Form.Item
              name="CurrencyId"
              label={t("MODEL_CURRENCY")}
              initialValue={cryptoCoinPrice.CurrencyId}
              style={{
                display: "inline-block",
                width: "calc(50% - 8px)",
                margin: "0 0 0 8px",
              }}
              rules={[
                {
                  required: true,
                  message: t("REQUIRED_VALIDATION_MESSAGE"),
                },
              ]}
            >
              <Choose
                placeholder={t("MODEL_CURRENCY_PLACEHOLDER")}
                loading={currenciesLoader.loading}
                disabled={currenciesLoader.loading}
                onChange={onCurrencyChange}
              >
                {currenciesLoader.data?.map((currency) => (
                  <ChooseOption key={currency.Id} value={currency.Id}>
                    {currency.Name}
                  </ChooseOption>
                ))}
              </Choose>
            </Form.Item>
            <Form.Item
              name="Amount"
              label={t("MODEL_AMOUNT")}
              style={{ display: "inline-block", width: "calc(50%)" }}
              initialValue={cryptoCoinPrice.Amount}
              rules={[
                {
                  required: true,
                  message: t("REQUIRED_VALIDATION_MESSAGE"),
                },
              ]}
            >
              <InputNumber
                style={{ width: "100%" }}
                placeholder={t("MODEL_AMOUNT_PLACEHOLDER")}
                step={1}
              />
            </Form.Item>
            <Form.Item
              name="Type"
              label={t("MODEL_PURCHASE_TYPE")}
              style={{
                display: "inline-block",
                width: "calc(50% - 8px)",
                margin: "0 0 0 8px",
              }}
              initialValue={cryptoCoinPrice.Type}
            >
              <Choose placeholder={t("MODEL_PURCHASE_TYPE")}>
                {Object.values(purchaseTypes).map((type) => (
                  <ChooseOption key={type} value={type}>
                    {type}
                  </ChooseOption>
                ))}
              </Choose>
            </Form.Item>
            <Form.Item style={{ marginBottom: 0 }}>
              <Form.Item
                name="AvailableFrom"
                label={t("MODEL_AVAILABLE_FROM")}
                dependencies={["AvailableTo"]}
                rules={[
                  {
                    type: "object",
                    required: true,
                    message: t("REQUIRED_VALIDATION_MESSAGE"),
                  },
                  {
                    validator: (_, AvailableFrom) => {
                      const AvailableTo = form.getFieldValue("AvailableTo");
                      if (AvailableTo && AvailableFrom?.isAfter(AvailableTo)) {
                        return Promise.reject(
                          new Error(t("AVAILABLE_FROM_DATE_VALIDATION_MESSAGE"))
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
                style={{
                  display: "inline-block",
                  width: "calc(50% - 4px)",
                }}
                initialValue={
                  cryptoCoinPrice.AvailableFrom
                    ? dayjs(cryptoCoinPrice.AvailableFrom)
                    : dayjs()
                }
              >
                <DatePicker
                  showToday={true}
                  showTime
                  style={{ width: `100%` }}
                />
              </Form.Item>
              <Form.Item
                name="AvailableTo"
                label={t("MODEL_AVAILABLE_TO")}
                dependencies={["AvailableFrom"]}
                initialValue={
                  cryptoCoinPrice.AvailableTo
                    ? dayjs(cryptoCoinPrice.AvailableTo)
                    : undefined
                }
                style={{
                  display: "inline-block",
                  width: "calc(50% - 4px)",
                  marginLeft: 8,
                }}
                rules={[
                  {
                    validator: (_, AvailableTo) => {
                      const AvailableFrom = form.getFieldValue("AvailableFrom");
                      if (
                        AvailableFrom &&
                        AvailableTo?.isBefore(AvailableFrom)
                      ) {
                        return Promise.reject(
                          new Error(t("AVAILABLE_TO_DATE_VALIDATION_MESSAGE"))
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <DatePicker
                  showToday={true}
                  showTime
                  style={{ width: `100%` }}
                />
              </Form.Item>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};
