import {
  Button,
  Empty,
  IBreadcrumbProps,
  ITableColumnProps,
  ITableFilter,
  Icon,
  InputSearch,
  Link,
  PageContent,
  PageHeader,
  Pagination,
  Table,
  Tooltip,
  setTableColumnSearchProps,
  useAppFeedback,
} from "@bms/common-ui";
import { ROUTES as USER_ROUTES } from "../../../User/constants";
import { ROUTES } from "../../constants";
import { generateBreadcrumb, useTableDataProvider } from "../../../../helpers";
import React from "react";
import { useTranslation } from "react-i18next";
import { FilterCleanIcon } from "../../../../resources/icons";
import {
  INotificationRecipientModel,
  NotificationRecipientsService,
  TimeHelper,
} from "@bms/common-services";

const notificationRecipientsService = new NotificationRecipientsService().promisify();

export const NotificationRecipientsList = () => {
  const { t } = useTranslation();
  const { notification } = useAppFeedback();

  const {
    dataLoader: notificationRecipientsLoader,
    filters,
    pagination,
    fullTextSearch,
    setFullTextSearch,
  } = useTableDataProvider({
    filtersSchema: {
      FullTextSearch: "string",
      UserFullName: "string",
      UserEmail: "string",
    },
    loader: (filters, pagination) => {
      return notificationRecipientsService.search({
        ...filters,
        ...pagination,
      });
    },

    deps: [],
    onError: (error) =>
      notification.error({
        message: t(
          "LOADING_DATA_ERROR_MESSAGE",
          "There was an error while loading data."
        ),
        description: error.Message,
      }),
  });

  const notificationRecipients: INotificationRecipientModel[] =
    notificationRecipientsLoader.data?.Entities || [];

  const getColumnsProps = (): Array<
    ITableColumnProps<INotificationRecipientModel>
  > => {
    return [
      {
        key: "UserEmail",
        dataIndex: "UserEmail",
        width: 300,
        ellipsis: true,
        title: t("NOTIFICATION_RECIPIENTS_EMAIL"),
        filteredValue: filters.asTableArray.Email,
        ...setTableColumnSearchProps(
          "UserEmail",
          t("NOTIFICATION_RECIPIENTS_EMAIL")
        ),
        render: (text: any, row: INotificationRecipientModel) => (
          <Link to={`${USER_ROUTES.USER_DETAILS}/${row.UserId}`}>{text}</Link>
        ),
      },
      {
        key: "UserFullName",
        dataIndex: "UserFullName",
        width: 300,
        ellipsis: true,

        title: t("NOTIFICATION_RECIPIENTS_FULL_NAME"),
        filteredValue: filters.asTableArray.UserFullName,
        ...setTableColumnSearchProps(
          "UserFullName",
          t("NOTIFICATION_RECIPIENTS_FULL_NAME")
        ),
      },
      {
        key: "NotificationName",
        dataIndex: "NotificationName",
        width: 250,
        title: t("NOTIFICATION_RECIPIENTS_NOTIFICATION_NAME"),

        render: (text: any, row: INotificationRecipientModel) => {
          return (
            <Link to={`${ROUTES.NOTIFICATION_DETAILS}/${row.NotificationId}`}>
              {text}
            </Link>
          );
        },
      },
      {
        key: "SendingCounter",
        dataIndex: "SendingCounter",
        width: 100,
        title: t("NOTIFICATION_RECIPIENTS_SENDING_COUNTER"),
      },
      {
        key: "Provider",
        dataIndex: "Provider",
        width: 100,
        title: t("NOTIFICATION_PROVIDER_FIELD"),
      },
      {
        key: "FirstSendAttempt",
        dataIndex: "FirstSendAttempt",
        width: 140,
        title: t("NOTIFICATION_RECIPIENTS_FIRST_SEND_ATTEMPT"),
        render: (firstSentAttempt: Date) =>
          firstSentAttempt ? TimeHelper.format(firstSentAttempt) : null,
      },
      {
        key: "LastSendAttempt",
        dataIndex: "LastSendAttempt",
        width: 140,
        title: t("NOTIFICATION_RECIPIENTS_LAST_SEND_ATTEMPT"),
        render: (lastSentAttempt: Date) =>
          lastSentAttempt ? TimeHelper.format(lastSentAttempt) : null,
      },
      {
        key: "SentOn",
        dataIndex: "SentOn",
        width: 140,
        title: t("NOTIFICATION_RECIPIENTS_SENT_ON"),
        render: (sendOn: Date) => (sendOn ? TimeHelper.format(sendOn) : null),
      },
      {
        key: "ReadOn",
        dataIndex: "ReadOn",
        width: 140,
        title: t("NOTIFICATION_RECIPIENTS_READ_ON"),
        render: (readOn: Date) => (readOn ? TimeHelper.format(readOn) : null),
      },
      {
        key: "DeletedOn",
        dataIndex: "DeletedOn",
        width: 140,
        title: t("NOTIFICATION_RECIPIENTS_DELETED_ON"),
        render: (deletedOn: Date) =>
          deletedOn ? TimeHelper.format(deletedOn) : null,
      },
    ];
  };

  const onTableChange = (_: any, incomingFilters: ITableFilter) =>
    filters.update((oldFilters) => ({
      ...oldFilters,
      ...incomingFilters,
    }));

  const onSearch = (value: string) =>
    filters.update((oldFilters) => ({
      ...oldFilters,
      FullTextSearch: value || undefined,
    }));

  const getBreadcrumbProps = (): IBreadcrumbProps => {
    return generateBreadcrumb([
      {
        path: `${ROUTES.NOTIFICATION_RECIPIENTS}`,
        breadcrumbName: t("NOTIFICATION_RECIPIENTS_TITLE"),
      },
    ]);
  };

  return (
    <>
      <PageContent
        className="NotificationRecipientsList"
        footer={<Pagination {...pagination.props} />}
      >
        <PageHeader
          title={t("NOTIFICATION_RECIPIENTS_TITLE")}
          breadcrumb={getBreadcrumbProps()}
          extra={[
            <InputSearch
              key="search"
              placeholder={t("SEARCH_PLACEHOLDER", "Search...")}
              value={fullTextSearch}
              onSearch={onSearch}
              onChange={({ target: { value } }) => setFullTextSearch(value)}
              style={{ width: 250 }}
              allowClear
            />,
            <Tooltip overlay={t("MENU_OPTION_CLEAR_FILTERS", "Clear filters")}>
              <Button
                key="clear-filters"
                shape="circle"
                icon={<FilterCleanIcon />}
                onClick={filters.clear}
                disabled={!filters.anyActive}
                title={t("MENU_OPTION_CLEAR_FILTERS", "Clear filters")}
              />
            </Tooltip>,
            <Button
              key="reload"
              shape="circle"
              icon={<Icon type="reload" />}
              onClick={notificationRecipientsLoader.refresh}
              title={t("BUTTON_REFRESH_TITLE", "Refresh data")}
            />,
          ]}
        />
        <Table<INotificationRecipientModel>
          locale={{
            emptyText: (
              <>
                <Empty />
                {filters.anyActive && (
                  <Button
                    icon={<Icon component={FilterCleanIcon} />}
                    onClick={filters.clear}
                  >
                    {t("MENU_OPTION_CLEAR_FILTERS")}
                  </Button>
                )}
              </>
            ),
          }}
          rowKey="Id"
          columns={getColumnsProps()}
          dataSource={notificationRecipients}
          loading={notificationRecipientsLoader.loading}
          pagination={false}
          onChange={onTableChange}
        />
      </PageContent>
    </>
  );
};
