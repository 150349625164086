import { AdvertisementBoardType } from "../../../enums";

export class AdvertisementBoardTypeHelper {
  static getDescription(value?: AdvertisementBoardType): string {
    switch (value) {
      case AdvertisementBoardType.Image:
        return "Image";
      case AdvertisementBoardType.Video:
        return "Video";
      case AdvertisementBoardType.QR:
        return "QR";
      case AdvertisementBoardType.LeadPhone:
        return "Lead Phone";
      case AdvertisementBoardType.LeadEmail:
        return "Lead Email";
      default:
        return "Undefined";
    }
  }

  static getValue(value: string | undefined): AdvertisementBoardType | undefined {
    switch (value) {
      case "IMAGE":
        return AdvertisementBoardType.Image;
      case "VIDEO":
        return AdvertisementBoardType.Video;
      case "QR":
        return AdvertisementBoardType.QR;
      case "LEAD_PHONE":
        return AdvertisementBoardType.LeadPhone;
      case "LEAD_EMAIL":
        return AdvertisementBoardType.LeadEmail;
      default:
        return undefined;
    }
  }

  static getColor(value?: AdvertisementBoardType): string {
    let color: string;

    switch (value) {
      case AdvertisementBoardType.Image:
        color = "#3D01A4";
        break;
      case AdvertisementBoardType.Video:
        color = "#FABC02";
        break;
      case AdvertisementBoardType.QR:
        color = "#FE2712";
        break;
      case AdvertisementBoardType.LeadPhone:
        color = "#D500F9";
        break;
      case AdvertisementBoardType.LeadEmail:
        color = "#A7194B";
        break;
      default:
        color = "#2DB7F5";
        break;
    }

    return color;
  }
}
