import React from "react";
import { noop } from "lodash";
import { Button, Popconfirm, Icon } from "@bms/common-ui";
import "./ActionButtons.scss";

import { useTranslation } from "react-i18next";

interface IActionButtonsProps {
  brandExist: boolean;
  onCancel: () => void;
  onDelete?: () => void;
}

export const ActionButtons: React.FC<IActionButtonsProps> = ({
  brandExist,
  onCancel,
  onDelete,
}) => {
  const { t } = useTranslation();

  return (
    <div className="action-buttons-wrap">
      <Popconfirm
        title={t("CONFIGURATION_BUTTON__DELETE_ITEM_MESSAGE")}
        okText={t("BUTTON_YES")}
        cancelText={t("BUTTON_NO")}
        onCancel={noop}
        onConfirm={onDelete}
      >
        <Button
          shape="circle"
          disabled={!brandExist}
          danger={true}
          icon={<Icon type="delete" />}
          style={{ float: "left" }}
        />
      </Popconfirm>

      {brandExist ? (
        <Button key="cancel" type="link" onClick={onCancel}>
          {t("BUTTON_CANCEL")}
        </Button>
      ) : (
        <Popconfirm
          title={t("CONFIGURATION_BUTTON__LOST_DATA_MESSAGE")}
          okText={t("BUTTON_YES")}
          cancelText={t("BUTTON_NO")}
          onCancel={noop}
          onConfirm={onCancel}
        >
          <Button key="cancel" type="link">
            {t("BUTTON_CANCEL")}
          </Button>
        </Popconfirm>
      )}

      <Button type="primary" htmlType="submit">
        {t("BUTTON_SAVE")}
      </Button>
    </div>
  );
};
