export const SEARCH_NOTIFICATIONS = "SEARCH_NOTIFICATIONS";
export const SEARCH_NOTIFICATIONS_SUCCESS = "SEARCH_NOTIFICATIONS_SUCCESS";
export const SEARCH_NOTIFICATIONS_FAILURE = "SEARCH_NOTIFICATIONS_FAILURE";

export const SEARCH_USER_NOTIFICATIONS = "SEARCH_USER_NOTIFICATIONS";
export const SEARCH_USER_NOTIFICATIONS_SUCCESS =
  "SEARCH_USER_NOTIFICATIONS_SUCCESS";
export const SEARCH_USER_NOTIFICATIONS_FAILURE =
  "SEARCH_USER_NOTIFICATIONS_FAILURE";

export const NOTIFICATION_RECEIVED = "NOTIFICATION_RECEIVED";

export const NOTIFICATIONS_MARK_AS_READ = "NOTIFICATIONS_MARK_AS_READ";
export const NOTIFICATIONS_MARK_AS_READ_SUCCESS =
  "NOTIFICATIONS_MARK_AS_READ_SUCCESS";
export const NOTIFICATIONS_MARK_AS_READ_FAILURE =
  "NOTIFICATIONS_MARK_AS_READ_FAILURE";
