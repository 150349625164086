import {
  ITestDeviceModel,
  TestDevicesService,
  useServiceCaller,
} from "@bms/common-services";
import { useTranslation } from "react-i18next";
import { useTableDataProvider } from "../../../../helpers";
import { useAppFeedback } from "@bms/common-ui";

const testDevicesService = new TestDevicesService().promisify();

export const useTestDevices = () => {
  const { t } = useTranslation();
  const { notification } = useAppFeedback();

  const {
    dataLoader,
    filters,
    pagination,
    fullTextSearch,
    setFullTextSearch,
  } = useTableDataProvider({
    filtersSchema: {
      FullTextSearch: "string",
      Name: "string",
      Description: "string",
      Platforms: "strings",
      UpToDate: "boolean",
    },
    loader: (filters, pagination) =>
      testDevicesService.search({
        ...filters,
        ...pagination,
      }),
    deps: [],
    onError: (error) =>
      notification.error({
        message: t("LOADING_DATA_ERROR_MESSAGE"),
        description: error.Message,
      }),
  });

  const [createDevice, { processing: createProcessing }] = useServiceCaller(
    async (device: ITestDeviceModel) => {
      const result = await testDevicesService.insert(device);
      if (result.ok) {
        dataLoader.refresh();
        notification.success({
          message: t("TESTING_DEVICE_CREATE_SUCCESS"),
        });
      } else {
        notification.error({
          message: t("TESTING_DEVICE_CREATE_FAILURE"),
          description: result.error?.Message,
        });
      }
      return result.ok;
    },
    []
  );

  const [updateDevice, { processing: updateProcessing }] = useServiceCaller(
    async (device: ITestDeviceModel) => {
      const result = await testDevicesService.update(device);
      if (result.ok) {
        dataLoader.refresh();
        notification.success({
          message: t("TESTING_DEVICE_UPDATE_SUCCESS"),
        });
      } else {
        notification.error({
          message: t("TESTING_DEVICE_UPDATE_FAILURE"),
          description: result.error?.Message,
        });
      }
      return result.ok;
    },
    []
  );

  const [deleteDevice, { processing: deleteProcessing }] = useServiceCaller(
    async (device: ITestDeviceModel) => {
      const result = await testDevicesService.delete(device);
      if (result.ok) {
        dataLoader.refresh();
        notification.success({
          message: t("TESTING_DEVICE_DELETE_SUCCESS"),
        });
      } else {
        notification.error({
          message: t("TESTING_DEVICE_DELETE_ERROR"),
          description: result.error?.Message,
        });
      }
      return result.ok;
    },
    []
  );

  const isLoading =
    dataLoader.loading ||
    deleteProcessing ||
    updateProcessing ||
    createProcessing;

  return {
    isLoading,
    data: dataLoader.data,
    refreshDevices: dataLoader.refresh,
    filters,
    pagination,
    fullTextSearch,
    setFullTextSearch,
    createDevice,
    updateDevice,
    deleteDevice,
  };
};
