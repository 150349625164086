/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { Player } from "@mycujoo/mcls-components";
import React from "react";

import "./MycujooPlayer.scss";

interface IMycujooPlayerProps {
  eventId: string;
  publicKey: string;
  autoplay?: boolean;
  showPictureInPicture?: boolean;
}

export const MycujooPlayer = ({
  eventId,
  showPictureInPicture,
  publicKey,
  autoplay,
}: IMycujooPlayerProps) => {
  return (
    <div className="video-player-container">
      <Player
        eventId={eventId}
        publicKey={publicKey}
        autoplay={autoplay}
        showPictureInPicture={showPictureInPicture}
      />
    </div>
  );
};
