import {
  AssetImageType,
  IAssetImageModel,
  IAssetModel,
} from "@bms/common-services";
import { Col, Paragraph, Row } from "@bms/common-ui";
import React from "react";
import "./PlayListItemComponentPreview.scss";

export interface IPlayListItemComponentPreviewProps {
  asset?: IAssetModel;
  index?: number;
  isPlaceholder: boolean;
  placeholderSrc?: string;
  style?: React.CSSProperties;
}

/**
 * Component to generate current cell in horizontal list. For example a film from category list
 * @exports PlayListItemComponentPreview [default]
 */
export const PlayListItemComponentPreview: React.FC<IPlayListItemComponentPreviewProps> = (
  props
) => {
  const { asset, placeholderSrc } = props;
  let frameImageView: React.ReactElement | null = null;

  if (asset && asset.Images) {
    const assetFrameImage = asset.Images.find(
      (row: IAssetImageModel) => row.AssetImageTypeCode === AssetImageType.Frame
    );

    if (assetFrameImage) {
      frameImageView = <img src={assetFrameImage.Url} alt="" />;
    } else {
      frameImageView = <img src={placeholderSrc} alt="" />;
    }
  }

  const content = (
    <Row className="PlayListItemComponentPreview--content" wrapping="nowrap">
      <Col>
        <div className="PlayListItemComponentPreview--image-container">
          {frameImageView}
        </div>
      </Col>
      <Col
        className="PlayListItemComponentPreview--info"
        style={{ minWidth: 0 }}
      >
        <Paragraph
          className="PlayListItemComponentPreview--info-title"
          ellipsis={true}
        >
          {asset && asset.Title}
        </Paragraph>
        <Paragraph
          className="PlayListItemComponentPreview--info-description"
          style={{ minWidth: 0 }}
          ellipsis={{ rows: 2 }}
        >
          {asset && asset.Description}
        </Paragraph>
      </Col>
    </Row>
  );

  return (
    <div className="PlayListItemComponentPreview">
      <Row justify="space-between" align="middle" wrapping="nowrap">
        <Col style={{ minWidth: 0, flex: 1 }}>{content}</Col>
      </Row>
    </div>
  );
};
