import { SectionGrid, SectionGridItem } from "@bms/common-ui";
import React from "react";
import { NotificationForm } from "../../NotificationForm";
import { ITabProps } from "./ITabProps";
import { NotificationField } from "../../NotificationForm/NotificationFormUtils";

export const TabGeneral = ({ notification, onSubmit }: ITabProps) => {
  let hiddenFields: NotificationField[] = [
    "RecipientType",
    "ImageUrl",
    "UrlContent",
    "DataContent",
    "DataType",
    "TextContent",
    "UrlDescription",
  ];

  if (notification.Status == "DRAFT") {
    hiddenFields.push("ScheduledOn");
  }

  return (
    <SectionGrid style={{ maxWidth: "1200px" }}>
      <SectionGridItem>
        <NotificationForm
          notification={notification}
          onSubmit={onSubmit}
          isEditMode
          hiddenFields={hiddenFields}
        />
      </SectionGridItem>
    </SectionGrid>
  );
};
