import {
  ITestDefinitionModel,
  TestDefinitionService,
  useDataLoader,
  useServiceCaller,
} from "@bms/common-services";
import { useAppFeedback, useSyncedState } from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { ROUTES } from "../../constants";

interface useTestDefinitionParams {
  testId: number;
}

const testDefinitionService = new TestDefinitionService().promisify();

export const useTestDefinition = (params: useTestDefinitionParams) => {
  const { testId } = params;

  const { t } = useTranslation();
  const history = useHistory();
  const { notification } = useAppFeedback();

  const { data, loading, refresh } = useDataLoader({
    loader: () => testDefinitionService.get(testId),
    onError: (error) => {
      notification.error({
        message: t("LOADING_DATA_ERROR_MESSAGE"),
        description: error.Message,
      });
    },
    deps: [testId],
  });

  const [testDefinition, setTestDefinition] = useSyncedState(() => data, [
    data,
  ]);

  const [updateTestDefinition, updateState] = useServiceCaller(
    async (data: ITestDefinitionModel) => {
      const result = await testDefinitionService.update(data);

      if (result.ok) {
        setTestDefinition(result.data);

        notification.success({
          message: t("TESTING_TEST_DEFINITIONS_UPDATE_SUCCESS"),
        });
      } else {
        notification.error({
          message: t("TESTING_TEST_DEFINITIONS_UPDATE_FAILURE"),
          description: result.error.Message,
        });
      }
    },
    []
  );

  const [deleteTestDefinition] = useServiceCaller(
    async (application: ITestDefinitionModel) => {
      const result = await testDefinitionService.delete(application);

      if (result.ok) {
        notification.success({
          message: t("TESTING_TEST_DEFINITIONS_DELETE_SUCCESS"),
        });
        history.push(ROUTES.TEST_DEFINITIONS);
      } else {
        notification.error({
          message: t("TESTING_TEST_DEFINITIONS_DELETE_ERROR"),
          description: result.error?.Message,
        });
      }
      return result.ok;
    },
    []
  );

  const [startTestDefinition, startState] = useServiceCaller(
    async (id: number) => {
      const result = await testDefinitionService.start(id);

      await refresh();

      if (result.ok) {
        notification.success({
          message: t("TESTING_TEST_DEFINITIONS_START_SUCCESS"),
        });
      } else {
        notification.error({
          message: t("TESTING_TEST_DEFINITIONS_START_FAILURE"),
          description: result.error.Message,
        });
      }
    },
    []
  );

  return {
    testDefinition,
    loading,
    refresh,
    updateTestDefinition,
    updateState,
    deleteTestDefinition,
    startTestDefinition,
    startState,
  };
};
