import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  LogLevel,
} from "@microsoft/signalr";
import { HTTP_ERROR } from "../constants";
import { SignalRType } from "../enums";
import { AuthorizationHelper } from "../helpers";
import { IErrorModel, ITokenModel, SignalRErrorModel } from "../models";
import { dispatch } from "../store/actions";
import * as SignalRStore from "../store/signalR";
import { StorageManager } from "./StorageManager";

export interface ISignalRClientProps {
  url: string;
}

export class SignalRClient {
  public baseUrl: string =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_API_URL || ""
      : window?.location?.origin?.toString();
  public initConnectionReconnectTimeout: number = 15000;
  public initRefreshConnectionReconnectTimeout: number = 500;
  public initAutoReconnectTimeouts: number[] = [
    1000,
    5000,
    15000,
    15000,
    15000,
    15000,
    15000,
    15000,
    30000,
    30000,
  ];
  public session?: ITokenModel;
  public token: string = "";
  public connection?: HubConnection;
  public url = "";

  constructor(settings: ISignalRClientProps) {
    this.url = settings.url;
  }

  public initialize = () => {
    if (
      !AuthorizationHelper.isAuthenticated() ||
      AuthorizationHelper.isAnonymous()
    ) {
      return;
    }

    // Get session data from store
    this.setSessionData();

    const connectionURL = this.generateUrl();

    // Builder
    this.connection = new HubConnectionBuilder()
      .withUrl(connectionURL, {
        accessTokenFactory: () => this.token,
        transport: HttpTransportType.WebSockets,
      })
      .withAutomaticReconnect(this.initAutoReconnectTimeouts)
      .configureLogging(LogLevel.Information)
      .build();

    // Init events listeners
    this.onClose();
    this.onReconnecting();
    this.onReconnected();

    // Start SignalR
    return this.open();
  };

  public open = async () => {
    if (
      this.checkState(HubConnectionState.Disconnected) ||
      !this.checkState(HubConnectionState.Connecting)
    ) {
      Promise.resolve().then(this.onSignalRFetch);

      try {
        await this.connection?.start().then(this.onSignalRSuccess);
      } catch (error) {
        // TODO: cast the error to proper type
        // @ts-ignore
        if (error.statusCode === HTTP_ERROR.AUTHENTICATION_FAILED) {
          this.onSignalRError(SignalRType.Open, error);

          this.close();
          setTimeout(
            () => this.initialize(),
            this.checkTokenChanged()
              ? this.initRefreshConnectionReconnectTimeout
              : this.initConnectionReconnectTimeout
          );
        } else {
          setTimeout(() => this.open(), this.initConnectionReconnectTimeout);
        }
      }
    }
  };

  public close = async () => {
    if (
      !this.checkState(HubConnectionState.Disconnected) ||
      !this.checkState(HubConnectionState.Disconnecting)
    ) {
      try {
        await this.connection?.stop();
      } catch (error) {
        this.onSignalRError(SignalRType.Close, error);
      }
    }
  };

  private onClose = () => {
    this.connection?.onclose(() => {
      // Needed to overwrite default signalR method
    });
  };

  private onReconnecting = () => {
    this.connection?.onreconnecting((error) =>
      this.onSignalRError(SignalRType.Reconnecting, error)
    );
  };

  private onReconnected = () => {
    this.connection?.onreconnected(this.onSignalRSuccess);
  };

  private onBeforeUnload = () => {
    window.onbeforeunload = this.close;
  };

  public onSignalRFetch = () => {
    return dispatch(SignalRStore.Actions.signalR());
  };

  public onSignalRError = (type: SignalRType, error: any) => {
    const _error: SignalRErrorModel = {
      Error: error,
      Type: type,
    };
    return dispatch(SignalRStore.Actions.signalRFailure(_error));
  };

  public onSignalRSuccess = () => {
    return dispatch(SignalRStore.Actions.signalRSuccess());
  };

  public on = <T>(event: string, callback: (data: T) => void) => {
    this.connection?.on(event, callback);
  };

  private generateUrl = (): string => {
    return `${this.baseUrl}${this.url}`;
  };

  public checkState = (stateType: HubConnectionState): boolean => {
    return this.connection?.state === stateType;
  };

  private checkTokenChanged = (): boolean => {
    return this.token === this.session!.Token;
  };

  private setSessionData = () => {
    this.session = StorageManager.getValue("session");
    this.token = this.session ? `${this.session.Token}` : "";
  };
}

export default SignalRClient;
