import {
  AssetImageType,
  AssetType,
  CellType,
  IMediaImageModel,
  IMediaListModel,
  StreamType,
  TimeHelper,
} from "@bms/common-services";
import {
  Col,
  ImageWithPlaceholder,
  Row,
  Skeleton,
  Tag,
  Text,
  Title,
} from "@bms/common-ui";
import cx from "classnames";
import dayjs from "dayjs";
import React from "react";
import { IListItemComponentPreviewProps } from "./ListItemComponentPreview";
import "./ListItemFrameComponentPreview.scss";

export interface IListItemFrameComponentPreviewProps
  extends IListItemComponentPreviewProps {}

export const ListItemFrameComponentPreview = (
  props: IListItemComponentPreviewProps
) => {
  const { isHovered, asset, width, placeholderSrc, cellType } = props;

  const renderStatus = () => {
    if (!asset) {
      return null;
    }

    const isLiveBroadcast = asset.AssetTypeCode === AssetType.Live;
    let duration = "";

    switch (asset.AssetTypeCode) {
      case AssetType.Live:
      case AssetType.Channel: {
        if (asset.AvailableFrom) {
          const now = dayjs();
          const availableFrom = dayjs(asset.AvailableFrom);
          /* Some weird tree shaking (?) issue when using dayjs `from` method,
           * which - believe me - exists :P */
          duration = (availableFrom as any).from(now);
        } else {
          duration = TimeHelper.formatDurationMilliseconds(
            asset.DurationMiliseconds
              ? asset.DurationMiliseconds
              : asset.Contents?.find(
                  (c) => c.StreamTypeCode === StreamType.Main
                )?.DurationMiliseconds
          );
        }
        break;
      }
      default:
        duration = TimeHelper.formatDurationMilliseconds(
          asset.DurationMiliseconds
            ? asset.DurationMiliseconds
            : asset.Contents?.find((c) => c.StreamTypeCode === StreamType.Main)
                ?.DurationMiliseconds
        );
    }

    const liveIndicator = isLiveBroadcast && (
      <div className="ListItemFrameComponentPreview-status-online-indicator">
        <Tag color="#f50">Live</Tag>
      </div>
    );
    const durationIndicator = duration && (
      <div className="ListItemFrameComponentPreview-status-duration">
        <Tag color="#2D2D2D">{duration}</Tag>
      </div>
    );

    return (
      <div className="ListItemFrameComponentPreview-status">
        <Row justify="space-between">
          <Col>{liveIndicator}</Col>
          <Col>{durationIndicator}</Col>
        </Row>
      </div>
    );
  };

  let frameImageUrl: string | undefined = "";
  let title: string | undefined = "";
  let description: string | undefined = "";

  const sizeRecalculatedForSquare = Math.round(width * 0.5);

  if (asset) {
    description = asset.Description;
    title = asset.Title;

    if (asset.Images) {
      const assetFrameImage = asset.Images.find(
        (row: IMediaImageModel) => row.ImageTypeCode === AssetImageType.Frame
      );

      if (assetFrameImage) {
        frameImageUrl = assetFrameImage.Url;
      }
    }
  }

  const appListItemPaddingHorizontal = 20;

  const mainStyle: React.CSSProperties = {
    width:
      cellType === CellType.Square
        ? `${sizeRecalculatedForSquare}px`
        : `${width}px`,
    height:
      cellType === CellType.Square
        ? `${sizeRecalculatedForSquare}px`
        : undefined,
    marginRight: cellType === CellType.Square ? "25px" : undefined,
  };

  const containerStyle: React.CSSProperties = {
    width: `${width - appListItemPaddingHorizontal}px`,
    border: "2px solid #23232e",
    overflow: "hidden",
    position: "relative",
  };

  const imageContainerStyle: React.CSSProperties = {
    width:
      cellType === CellType.Square
        ? `${sizeRecalculatedForSquare}px`
        : `${width - appListItemPaddingHorizontal}px`,
    height:
      cellType === CellType.Square
        ? `${sizeRecalculatedForSquare}px`
        : `${Math.round((9 * (width - appListItemPaddingHorizontal)) / 16)}px`,
  };

  return (
    <div
      className={cx("ListItemFrameComponentPreview", {
        "ListItemFrameComponentPreview-active": isHovered,
      })}
      style={mainStyle}
    >
      <div style={containerStyle}>
        <div
          className="ListItemFrameComponentPreview-image-container"
          style={imageContainerStyle}
        >
          <ImageWithPlaceholder
            animated={true}
            imageSrc={frameImageUrl}
            imageContainerClassName="ListItemFrameComponentPreview-image"
            placeholderSrc={placeholderSrc}
          />
          {renderStatus()}
        </div>
        <div
          className={cx("ListItemFrameComponentPreview-info", {
            "ListItemFrameComponentPreview-info-active": isHovered,
          })}
        >
          <div className="ListItemFrameComponentPreview-info-title-container">
            <Skeleton active={true} loading={!title} paragraph={false}>
              <Row
                justify="space-between"
                align="middle"
                wrapping="nowrap"
                gutter={10}
              >
                <Col className="ellipsis">
                  <Title
                    className="ListItemFrameComponentPreview-info-title"
                    ellipsis={true}
                    level={4}
                    style={{ margin: 0 }}
                  >
                    {title}
                  </Title>
                </Col>
              </Row>
            </Skeleton>
          </div>
        </div>
      </div>
    </div>
  );
};
