import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { IErrorModel } from "../../models";
import { HttpClient } from "./HttpClient";
import { AppConfig } from "../App";

const CancelToken = axios.CancelToken;

export class HttpFactory {
  public baseUrl: string;

  private _requestInterceptors: {
    [key: string]: {
      onFulfilled?:
        | ((
            config: AxiosRequestConfig
          ) => AxiosRequestConfig | Promise<AxiosRequestConfig>)
        | undefined;
      onRejected?: ((error: IErrorModel) => any) | undefined;
    };
  } = {};

  private _responseInterceptors: {
    [key: string]: {
      onFulfilled?:
        | ((config: AxiosResponse) => AxiosResponse | Promise<AxiosResponse>)
        | undefined;
      onRejected?: ((error: IErrorModel) => any) | undefined;
    };
  } = {};

  private static _instance: { [key: string]: HttpFactory } = {};

  private constructor() {
    this.baseUrl = AppConfig.ApiUrl;
  }

  static getInstance(key: string = "default"): HttpFactory {
    if (!HttpFactory._instance[key]) {
      HttpFactory._instance[key] = new HttpFactory();
    }

    return HttpFactory._instance[key];
  }

  public getHttpClient(baseUrl?: string): HttpClient {
    const config: AxiosRequestConfig = {
      baseURL: baseUrl || this.baseUrl,
    };

    const axiosInstance = axios.create(config);

    for (const interceptorKey in this._requestInterceptors) {
      const { onFulfilled, onRejected } = this._requestInterceptors[
        interceptorKey
      ];
      axiosInstance.interceptors.request.use(onFulfilled, onRejected);
    }

    for (const interceptorKey in this._responseInterceptors) {
      const { onFulfilled, onRejected } = this._responseInterceptors[
        interceptorKey
      ];
      axiosInstance.interceptors.response.use(onFulfilled, onRejected);
    }

    return new HttpClient(axiosInstance, CancelToken.source());
  }

  public addRequestInterceptor(
    interceptorKey: string,
    onFulfilled?:
      | ((
          config: AxiosRequestConfig
        ) => AxiosRequestConfig | Promise<AxiosRequestConfig>)
      | undefined,
    onRejected?: (error: IErrorModel) => any
  ): void {
    this._requestInterceptors[interceptorKey] = { onFulfilled, onRejected };
  }

  public removeRequestInterceptor(interceptorKey: string): void {
    if (this._requestInterceptors[interceptorKey]) {
      delete this._requestInterceptors[interceptorKey];
    }
  }

  public addResponseInterceptor(
    interceptorKey: string,
    onFulfilled?:
      | ((config: AxiosResponse) => AxiosResponse | Promise<AxiosResponse>)
      | undefined,
    onRejected?: (error: IErrorModel) => any
  ): void {
    this._responseInterceptors[interceptorKey] = { onFulfilled, onRejected };
  }

  public removeResponseInterceptor(interceptorKey: string): void {
    if (this._responseInterceptors[interceptorKey]) {
      delete this._responseInterceptors[interceptorKey];
    }
  }
}
