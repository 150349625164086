import { UploadType } from "../../enums";
import {
  IAssetAgeRestrictionModel,
  IAssetCategoriesListModel,
  IAssetCategoriesSearchFilterModel,
  IAssetCategoryModel,
  IAssetCollectionListModel,
  IAssetCollectionModel,
  IAssetCollectionSearchFilterModel,
  IAssetCommentListModel,
  IAssetCommentModel,
  IAssetCommentSearchFilterModel,
  IAssetContentModel,
  IAssetContentStreamTypeModel,
  IAssetContentTypeModel,
  IAssetEventBuyModel,
  IAssetEventModel,
  IAssetEventsListModel,
  IAssetEventsSearchFilterModel,
  IAssetEventTypesModel,
  IAssetImageModel,
  IAssetImageTypeModel,
  IAssetInAssetModel,
  IAssetInCollectionListModel,
  IAssetInCollectionModel,
  IAssetListModel,
  IAssetModel,
  IAssetPeopleListModel,
  IAssetPeopleSearchFilterModel,
  IAssetSearchFilterModel,
  IAssetTypeModel,
  IErrorModel,
  OperationResult,
  UploadFileInfoModel,
} from "../../models";
import { IAssetInAssetListModel } from "../../models/Asset";
import * as Consts from "./consts";
import {
  AssetActionsTypes,
  IAddAssetCommentAction,
  IAddAssetCommentFailureAction,
  IAddAssetCommentSuccessAction,
  IAddAssetContentAction,
  IAddAssetContentFailureAction,
  IAddAssetContentSuccessAction,
  IBrowseAssetAction,
  IBrowseAssetFailureAction,
  IBrowseAssetSuccessAction,
  IBuyAssetAction,
  IBuyAssetFailureAction,
  IBuyAssetSuccessAction,
  IDeleteAssetCategoryAction,
  IDeleteAssetCategoryFailureAction,
  IDeleteAssetCategorySuccessAction,
  IDeleteAssetContentAction,
  IDeleteAssetContentFailureAction,
  IDeleteAssetContentSuccessAction,
  IGetAssetAction,
  IGetAssetContentUploadFileInfoAction,
  IGetAssetContentUploadFileInfoFailureAction,
  IGetAssetContentUploadFileInfoSuccessAction,
  IGetAssetFailureAction,
  IGetAssetSuccessAction,
  IInsertAssetCategoryAction,
  IInsertAssetCategoryFailureAction,
  IInsertAssetCategorySuccessAction,
  ISearchAssetCommentsMoreAction,
  ISearchAssetCommentsMoreFailureAction,
  ISearchAssetCommentsMoreSuccessAction,
  IUpdateAssetCategoryAction,
  IUpdateAssetCategoryFailureAction,
  IUpdateAssetCategorySuccessAction,
  IUpdateAssetContentAction,
  IUpdateAssetContentFailureAction,
  IUpdateAssetContentSuccessAction,
  IUpdateAssetContentUrlAction,
  IUpdateAssetContentUrlFailureAction,
  IUpdateAssetContentUrlSuccessAction,
  IUpdateAssetInCollectionAction,
  IUpdateAssetInCollectionFailureAction,
  IUpdateAssetInCollectionSuccessAction,
} from "./types";

export const getAsset = (id: number): IGetAssetAction => {
  return {
    id,
    type: Consts.GET_ASSET,
  };
};

export const getAssetSuccess = (data: IAssetModel): IGetAssetSuccessAction => {
  return {
    payload: data,
    type: Consts.GET_ASSET_SUCCESS,
  };
};

export const getAssetFailure = (
  error?: IErrorModel
): IGetAssetFailureAction => {
  return {
    error,
    type: Consts.GET_ASSET_FAILURE,
  };
};

export const createAsset = (
  data: IAssetModel,
  callback?: () => void
): AssetActionsTypes => {
  return {
    callback,
    data,
    type: Consts.CREATE_ASSET,
  };
};

export const createAssetSuccess = (data: IAssetModel): AssetActionsTypes => {
  return {
    payload: {
      data,
    },
    type: Consts.CREATE_ASSET_SUCCESS,
  };
};

export const createAssetFailure = (error?: IErrorModel): AssetActionsTypes => {
  return {
    error,
    type: Consts.CREATE_ASSET_FAILURE,
  };
};

export const updateAsset = (
  data: IAssetModel,
  callback?: () => void
): AssetActionsTypes => {
  return {
    callback,
    data,
    type: Consts.UPDATE_ASSET,
  };
};

export const updateAssetSuccess = (data: IAssetModel): AssetActionsTypes => {
  return {
    payload: {
      data,
    },
    type: Consts.UPDATE_ASSET_SUCCESS,
  };
};

export const updateAssetFailure = (error?: IErrorModel): AssetActionsTypes => {
  return {
    error,
    type: Consts.UPDATE_ASSET_FAILURE,
  };
};

export const deleteAsset = (data: IAssetModel): AssetActionsTypes => {
  return {
    data,
    type: Consts.DELETE_ASSET,
  };
};

export const deleteAssetSuccess = (): AssetActionsTypes => {
  return {
    type: Consts.DELETE_ASSET_SUCCESS,
  };
};

export const deleteAssetFailure = (error?: IErrorModel): AssetActionsTypes => {
  return {
    error,
    type: Consts.DELETE_ASSET_FAILURE,
  };
};

export const getAssetContentUploadUrl = (
  assetId: number
): AssetActionsTypes => {
  return {
    assetId,
    type: Consts.GET_ASSET_CONTENT_UPLOAD_URL,
  };
};

export const getAssetContentUploadUrlSuccess = (
  assetId: number,
  data: UploadFileInfoModel
): AssetActionsTypes => {
  return {
    payload: {
      assetId,
      data,
    },
    type: Consts.GET_ASSET_CONTENT_UPLOAD_URL_SUCCESS,
  };
};

export const getAssetContentUploadUrlFailure = (
  error?: IErrorModel
): AssetActionsTypes => {
  return {
    error,
    type: Consts.GET_ASSET_CONTENT_UPLOAD_URL_FAILURE,
  };
};

export const getUploadFileInfo = (assetId: number): AssetActionsTypes => {
  return {
    assetId,
    type: Consts.GET_UPLOAD_FILE_INFO,
  };
};

export const getUploadFileInfoSuccess = (
  data: UploadFileInfoModel
): AssetActionsTypes => {
  return {
    payload: data,
    type: Consts.GET_UPLOAD_FILE_INFO_SUCCESS,
  };
};

export const getUploadFileInfoFailure = (
  error?: IErrorModel
): AssetActionsTypes => {
  return {
    error,
    type: Consts.GET_UPLOAD_FILE_INFO_FAILURE,
  };
};

export const getContentUploadUrl = (assetId: number): AssetActionsTypes => {
  return {
    assetId,
    type: Consts.GET_CONTENT_UPLOAD_URL,
  };
};

export const getContentUploadUrlSuccess = (
  data: UploadFileInfoModel
): AssetActionsTypes => {
  return {
    payload: data,
    type: Consts.GET_CONTENT_UPLOAD_URL_SUCCESS,
  };
};

export const getContentUploadUrlFailure = (
  error?: IErrorModel
): AssetActionsTypes => {
  return {
    error,
    type: Consts.GET_CONTENT_UPLOAD_URL_FAILURE,
  };
};

export const uploadAssetContentFile = (
  uploadInfo: UploadFileInfoModel,
  file: File,
  onProgress: (e: { percent: number }) => void,
  onSuccess: () => void
): AssetActionsTypes => {
  return {
    file,
    onProgress,
    onSuccess,
    type: Consts.UPLOAD_ASSET_CONTENT_FILE,
    uploadInfo,
  };
};

export const uploadAssetContentFileCancel = (): AssetActionsTypes => {
  return {
    type: Consts.UPLOAD_ASSET_CONTENT_FILE_CANCEL,
  };
};

export const uploadAssetContentFileSuccess = (): AssetActionsTypes => {
  return {
    type: Consts.UPLOAD_ASSET_CONTENT_FILE_SUCCESS,
    payload: {},
  };
};

export const uploadAssetContentFileFailure = (
  error?: IErrorModel
): AssetActionsTypes => {
  return {
    error,
    type: Consts.UPLOAD_ASSET_CONTENT_FILE_FAILURE,
  };
};

export const browseAssets = (
  filter: IAssetSearchFilterModel
): IBrowseAssetAction => {
  return {
    filter,
    type: Consts.BROWSE_ASSET,
  };
};

export const browseAssetsSuccess = (
  data: IAssetListModel
): IBrowseAssetSuccessAction => {
  return {
    payload: data,
    type: Consts.BROWSE_ASSET_SUCCESS,
  };
};

export const browseAssetsFailure = (
  error?: IErrorModel
): IBrowseAssetFailureAction => {
  return {
    error,
    type: Consts.BROWSE_ASSET_FAILURE,
  };
};

export const searchAsset = (
  filter: IAssetSearchFilterModel
): AssetActionsTypes => {
  return {
    filter,
    type: Consts.SEARCH_ASSET,
  };
};

export const searchAssetsSuccess = (
  data: IAssetListModel
): AssetActionsTypes => {
  return {
    payload: data,
    type: Consts.SEARCH_ASSET_SUCCESS,
  };
};

export const searchAssetsFailure = (error?: IErrorModel): AssetActionsTypes => {
  return {
    error,
    type: Consts.SEARCH_ASSET_FAILURE,
  };
};

export const searchAssetMore = (
  filter: IAssetSearchFilterModel
): AssetActionsTypes => {
  return {
    filter,
    type: Consts.SEARCH_ASSET_MORE,
  };
};

export const uploadAssetImage = (
  fileUploadInfo: UploadFileInfoModel,
  file: File,
  onProgress: (event: { percent: number }) => void,
  onSuccess: () => void
): AssetActionsTypes => {
  return {
    file,
    onProgress,
    onSuccess,
    type: Consts.UPLOAD_ASSET_IMAGE,
    fileUploadInfo,
  };
};

export const uploadAssetImageSuccess = (
  data: OperationResult<UploadFileInfoModel>
): AssetActionsTypes => {
  return {
    payload: data,
    type: Consts.UPLOAD_ASSET_IMAGE_SUCCESS,
  };
};

export const uploadAssetImageFailure = (
  error?: IErrorModel
): AssetActionsTypes => {
  return {
    error,
    type: Consts.UPLOAD_ASSET_IMAGE_FAILURE,
  };
};

export const updateAssetImage = (data: IAssetImageModel): AssetActionsTypes => {
  return {
    payload: data,
    type: Consts.UPDATE_ASSET_IMAGE,
  };
};

export const updateAssetImageSuccess = (
  data: IAssetImageModel
): AssetActionsTypes => {
  return {
    payload: data,
    type: Consts.UPDATE_ASSET_IMAGE_SUCCESS,
  };
};

export const updateAssetImageFailure = (
  error?: IErrorModel
): AssetActionsTypes => {
  return {
    error,
    type: Consts.UPDATE_ASSET_IMAGE_FAILURE,
  };
};

export const deleteAssetImage = (data: IAssetImageModel): AssetActionsTypes => {
  return {
    payload: data,
    type: Consts.DELETE_ASSET_IMAGE,
  };
};

export const deleteAssetImageSuccess = (
  data: IAssetImageModel
): AssetActionsTypes => {
  return {
    payload: data,
    type: Consts.DELETE_ASSET_IMAGE_SUCCESS,
  };
};

export const deleteAssetImageFailure = (
  error?: IErrorModel
): AssetActionsTypes => {
  return {
    error,
    type: Consts.DELETE_ASSET_IMAGE_FAILURE,
  };
};

export const addAssetImage = (data: IAssetImageModel): AssetActionsTypes => {
  return {
    payload: data,
    type: Consts.ADD_ASSET_IMAGE,
  };
};

export const addAssetImageSuccess = (
  data: IAssetImageModel
): AssetActionsTypes => {
  return {
    payload: data,
    type: Consts.ADD_ASSET_IMAGE_SUCCESS,
  };
};

export const addAssetImageFailure = (
  error?: IErrorModel
): AssetActionsTypes => {
  return {
    error,
    type: Consts.ADD_ASSET_IMAGE_FAILURE,
  };
};

export const getAssetContentUploadFileInfo = (
  assetId: number,
  assetContentGuid: string,
  uploadType: UploadType,
  contentType: string
): IGetAssetContentUploadFileInfoAction => {
  return {
    assetId,
    assetContentGuid,
    uploadType,
    contentType,
    type: Consts.GET_ASSET_CONTENT_UPLOAD_FILE_INFO,
  };
};

export const getAssetContentUploadFileInfoSuccess = (
  assetId: number,
  data: UploadFileInfoModel
): IGetAssetContentUploadFileInfoSuccessAction => {
  return {
    payload: {
      assetId,
      data,
    },
    type: Consts.GET_ASSET_CONTENT_UPLOAD_FILE_INFO_SUCCESS,
  };
};

export const getAssetContentUploadFileInfoFailure = (
  error?: IErrorModel
): IGetAssetContentUploadFileInfoFailureAction => {
  return {
    error,
    type: Consts.GET_ASSET_CONTENT_UPLOAD_FILE_INFO_FAILURE,
  };
};

export const addAssetContent = (
  data: IAssetContentModel
): IAddAssetContentAction => {
  return {
    payload: data,
    type: Consts.ADD_ASSET_CONTENT,
  };
};

export const addAssetContentSuccess = (
  data: IAssetContentModel
): IAddAssetContentSuccessAction => {
  return {
    payload: data,
    type: Consts.ADD_ASSET_CONTENT_SUCCESS,
  };
};

export const addAssetContentFailure = (
  error?: IErrorModel
): IAddAssetContentFailureAction => {
  return {
    error,
    type: Consts.ADD_ASSET_CONTENT_FAILURE,
  };
};

export const updateAssetContentUrl = (
  id: number,
  contentUrl?: string,
  contentFile?: File
): IUpdateAssetContentUrlAction => {
  return {
    id,
    contentUrl,
    contentFile,
    type: Consts.UPDATE_ASSET_CONTENT_URL,
  };
};

export const updateAssetContentUrlSuccess = (
  data: IAssetModel
): IUpdateAssetContentUrlSuccessAction => {
  return {
    payload: data,
    type: Consts.UPDATE_ASSET_CONTENT_URL_SUCCESS,
  };
};

export const updateAssetContentUrlFailure = (
  error?: IErrorModel
): IUpdateAssetContentUrlFailureAction => {
  return {
    error,
    type: Consts.UPDATE_ASSET_CONTENT_URL_FAILURE,
  };
};

export const updateAssetContent = (
  data: IAssetContentModel
): IUpdateAssetContentAction => {
  return {
    payload: data,
    type: Consts.UPDATE_ASSET_CONTENT,
  };
};

export const updateAssetContentSuccess = (
  data: IAssetContentModel
): IUpdateAssetContentSuccessAction => {
  return {
    payload: data,
    type: Consts.UPDATE_ASSET_CONTENT_SUCCESS,
  };
};

export const updateAssetContentFailure = (
  error?: IErrorModel
): IUpdateAssetContentFailureAction => {
  return {
    error,
    type: Consts.UPDATE_ASSET_CONTENT_FAILURE,
  };
};

export const deleteAssetContent = (
  data: IAssetContentModel
): IDeleteAssetContentAction => {
  return {
    payload: data,
    type: Consts.DELETE_ASSET_CONTENT,
  };
};

export const deleteAssetContentSuccess = (
  data: IAssetContentModel
): IDeleteAssetContentSuccessAction => {
  return {
    payload: data,
    type: Consts.DELETE_ASSET_CONTENT_SUCCESS,
  };
};

export const deleteAssetContentFailure = (
  error?: IErrorModel
): IDeleteAssetContentFailureAction => {
  return {
    error,
    type: Consts.DELETE_ASSET_CONTENT_FAILURE,
  };
};

export const getAssetComment = (id: number): AssetActionsTypes => {
  return {
    id,
    type: Consts.GET_ASSET_COMMENT,
  };
};

export const getAssetCommentSuccess = (
  data: IAssetCommentModel
): AssetActionsTypes => {
  return {
    payload: data,
    type: Consts.GET_ASSET_COMMENT_SUCCESS,
  };
};

export const getAssetCommentFailure = (
  error?: IErrorModel
): AssetActionsTypes => {
  return {
    error,
    type: Consts.GET_ASSET_COMMENT_FAILURE,
  };
};

export const searchAssetComments = (
  filter: IAssetCommentSearchFilterModel
): AssetActionsTypes => {
  return {
    filter,
    type: Consts.SEARCH_ASSET_COMMENTS,
  };
};

export const searchAssetCommentsSuccess = (
  data: IAssetCommentListModel
): AssetActionsTypes => {
  return {
    payload: data,
    type: Consts.SEARCH_ASSET_COMMENTS_SUCCESS,
  };
};

export const searchAssetCommentsFailure = (
  error?: IErrorModel
): AssetActionsTypes => {
  return {
    error,
    type: Consts.SEARCH_ASSET_COMMENTS_FAILURE,
  };
};

export const searchAssetCommentsMore = (
  filter: IAssetCommentSearchFilterModel
): ISearchAssetCommentsMoreAction => {
  return {
    filter,
    type: Consts.SEARCH_ASSET_COMMENTS_MORE,
  };
};

export const searchAssetCommentsMoreSuccess = (
  data: IAssetCommentListModel
): ISearchAssetCommentsMoreSuccessAction => {
  return {
    payload: data,
    type: Consts.SEARCH_ASSET_COMMENTS_MORE_SUCCESS,
  };
};

export const searchAssetCommentsMoreFailure = (
  error?: IErrorModel
): ISearchAssetCommentsMoreFailureAction => {
  return {
    error,
    type: Consts.SEARCH_ASSET_COMMENTS_MORE_FAILURE,
  };
};

export const addAssetComment = (
  data: IAssetCommentModel,
  onSuccess?: (data: IAssetCommentModel) => void,
  onError?: (error: IErrorModel) => void
): IAddAssetCommentAction => {
  return {
    payload: data,
    type: Consts.ADD_ASSET_COMMENT,
    onSuccess,
    onError,
  };
};

export const addAssetCommentSuccess = (
  data: IAssetCommentModel
): IAddAssetCommentSuccessAction => {
  return {
    payload: data,
    type: Consts.ADD_ASSET_COMMENT_SUCCESS,
  };
};

export const addAssetCommentFailure = (
  data: IAssetCommentModel,
  error?: IErrorModel
): IAddAssetCommentFailureAction => {
  return {
    error,
    payload: data,
    type: Consts.ADD_ASSET_COMMENT_FAILURE,
  };
};

export const searchAssetSuccess = (
  data: IAssetListModel
): AssetActionsTypes => {
  return {
    payload: data,
    type: Consts.SEARCH_ASSET_SUCCESS,
  };
};

export const searchAssetFailure = (error?: IErrorModel): AssetActionsTypes => {
  return {
    error,
    type: Consts.SEARCH_ASSET_FAILURE,
  };
};

export const searchAssetMoreSuccess = (
  data: IAssetListModel
): AssetActionsTypes => {
  return {
    payload: data,
    type: Consts.SEARCH_ASSET_MORE_SUCCESS,
  };
};

export const searchAssetMoreFailure = (
  error?: IErrorModel
): AssetActionsTypes => {
  return {
    error,
    type: Consts.SEARCH_ASSET_MORE_FAILURE,
  };
};

export const createAssetCollection = (data: any): AssetActionsTypes => {
  return {
    data,
    type: Consts.CREATE_ASSET_COLLECTION,
  };
};

export const createAssetCollectionSuccess = (data: any): AssetActionsTypes => {
  return {
    data,
    type: Consts.CREATE_ASSET_COLLECTION_SUCCESS,
  };
};

export const createAssetCollectionFailure = (
  error?: IErrorModel
): AssetActionsTypes => {
  return {
    error,
    type: Consts.CREATE_ASSET_COLLECTION_FAILURE,
  };
};

export const saveAssetsInCollection = (
  collection: IAssetCollectionModel,
  assets: IAssetModel[]
): AssetActionsTypes => {
  return {
    payload: {
      assets,
      collection,
    },
    type: Consts.SAVE_ASSETS_IN_COLLECTION,
  };
};

export const saveAssetsInCollectionSuccess = (): AssetActionsTypes => {
  return {
    type: Consts.SAVE_ASSETS_IN_COLLECTION_SUCCESS,
  };
};

export const saveAssetsInCollectionFailure = (
  error?: IErrorModel
): AssetActionsTypes => {
  return {
    error,
    type: Consts.SAVE_ASSETS_IN_COLLECTION_FAILURE,
  };
};

export const createAssetCollectionAndSaveAssets = (
  collection: IAssetCollectionModel,
  assets: IAssetModel[],
  callback: (collection: IAssetCollectionModel) => void
): AssetActionsTypes => {
  return {
    payload: {
      assets,
      callback,
      collection,
    },
    type: Consts.CREATE_ASSET_COLLECTION_AND_SAVE_ASSETS,
  };
};

export const getAssetsCollection = (id: number): AssetActionsTypes => {
  return {
    id,
    type: Consts.GET_ASSETS_COLLECTION,
  };
};

export const getAssetsCollectionSuccess = (
  data: IAssetCollectionModel
): AssetActionsTypes => {
  return {
    payload: data,
    type: Consts.GET_ASSETS_COLLECTION_SUCCESS,
  };
};

export const getAssetsCollectionFailure = (
  error?: IErrorModel
): AssetActionsTypes => {
  return {
    error,
    type: Consts.GET_ASSETS_COLLECTION_FAILURE,
  };
};

export const updateAssetsCollection = (
  data: IAssetCollectionModel
): AssetActionsTypes => {
  return {
    payload: data,
    type: Consts.UPDATE_ASSETS_COLLECTION,
  };
};

export const updateAssetsCollectionSuccess = (
  data: IAssetCollectionModel
): AssetActionsTypes => {
  return {
    payload: data,
    type: Consts.UPDATE_ASSETS_COLLECTION_SUCCESS,
  };
};

export const updateAssetsCollectionFailure = (
  error?: IErrorModel
): AssetActionsTypes => {
  return {
    error,
    type: Consts.UPDATE_ASSETS_COLLECTION_FAILURE,
  };
};

export const createAssetsCollection = (
  data: IAssetCollectionModel
): AssetActionsTypes => {
  return {
    payload: data,
    type: Consts.CREATE_ASSETS_COLLECTION,
  };
};

export const createAssetsCollectionSuccess = (
  data: IAssetCollectionModel
): AssetActionsTypes => {
  return {
    payload: data,
    type: Consts.CREATE_ASSETS_COLLECTION_SUCCESS,
  };
};

export const createAssetsCollectionFailure = (
  error?: IErrorModel
): AssetActionsTypes => {
  return {
    error,
    type: Consts.CREATE_ASSETS_COLLECTION_FAILURE,
  };
};

export const selectAssetInCollection = (
  assetCollectionId: number
): AssetActionsTypes => {
  return {
    assetCollectionId,
    type: Consts.SELECT_ASSET_IN_COLLECTION,
  };
};

export const selectAssetInCollectionSuccess = (
  data: IAssetInCollectionListModel
): AssetActionsTypes => {
  return {
    payload: data,
    type: Consts.SELECT_ASSET_IN_COLLECTION_SUCCESS,
  };
};

export const selectAssetInCollectionFailure = (
  error?: IErrorModel
): AssetActionsTypes => {
  return {
    error,
    type: Consts.SELECT_ASSET_IN_COLLECTION_FAILURE,
  };
};

export const deleteAssetsCollection = (
  data: IAssetCollectionModel
): AssetActionsTypes => {
  return {
    payload: data,
    type: Consts.DELETE_ASSETS_COLLECTION,
  };
};

export const deleteAssetsCollectionSuccess = (): AssetActionsTypes => {
  return {
    type: Consts.DELETE_ASSETS_COLLECTION_SUCCESS,
  };
};

export const deleteAssetsCollectionFailure = (
  error?: IErrorModel
): AssetActionsTypes => {
  return {
    error,
    type: Consts.DELETE_ASSETS_COLLECTION_FAILURE,
  };
};

export const addAssetInCollection = (
  data: IAssetInCollectionModel
): AssetActionsTypes => {
  return {
    payload: data,
    type: Consts.ADD_ASSET_IN_COLLECTION,
  };
};

export const addAssetInCollectionSuccess = (
  data: IAssetInCollectionModel
): AssetActionsTypes => {
  return {
    payload: data,
    type: Consts.ADD_ASSET_IN_COLLECTION_SUCCESS,
  };
};

export const addAssetInCollectionFailure = (
  error?: IErrorModel
): AssetActionsTypes => {
  return {
    error,
    type: Consts.ADD_ASSET_IN_COLLECTION_FAILURE,
  };
};

export const updateAssetInCollection = (
  data: IAssetInCollectionModel
): IUpdateAssetInCollectionAction => {
  return {
    payload: data,
    type: Consts.UPDATE_ASSET_IN_COLLECTION,
  };
};

export const updateAssetInCollectionSuccess = (
  data: IAssetInCollectionModel
): IUpdateAssetInCollectionSuccessAction => {
  return {
    payload: data,
    type: Consts.UPDATE_ASSET_IN_COLLECTION_SUCCESS,
  };
};

export const updateAssetInCollectionFailure = (
  error?: IErrorModel
): IUpdateAssetInCollectionFailureAction => {
  return {
    error,
    type: Consts.UPDATE_ASSET_IN_COLLECTION_FAILURE,
  };
};

export const deleteAssetInCollection = (
  data: IAssetInCollectionModel
): AssetActionsTypes => {
  return {
    payload: data,
    type: Consts.DELETE_ASSET_IN_COLLECTION,
  };
};

export const deleteAssetInCollectionSuccess = (): AssetActionsTypes => {
  return {
    type: Consts.DELETE_ASSET_IN_COLLECTION_SUCCESS,
  };
};

export const deleteAssetInCollectionFailure = (
  error?: IErrorModel
): AssetActionsTypes => {
  return {
    error,
    type: Consts.DELETE_ASSET_IN_COLLECTION_FAILURE,
  };
};

export const searchAssetsByAssetCollectionId = (
  filter: IAssetCollectionSearchFilterModel
): AssetActionsTypes => {
  return {
    filter,
    type: Consts.SEARCH_ASSETS_BY_ASSET_COLLECTION_ID,
  };
};

export const searchAssetsByAssetCollectionIdSuccess = (
  data: IAssetListModel
): AssetActionsTypes => {
  return {
    payload: data,
    type: Consts.SEARCH_ASSETS_BY_ASSET_COLLECTION_ID_SUCCESS,
  };
};

export const searchAssetsByAssetCollectionIdFailure = (
  error?: IErrorModel
): AssetActionsTypes => {
  return {
    error,
    type: Consts.SEARCH_ASSETS_BY_ASSET_COLLECTION_ID_FAILURE,
  };
};

export const searchAssetCollection = (
  filter: IAssetCollectionSearchFilterModel
): AssetActionsTypes => {
  return {
    filter,
    type: Consts.SEARCH_ASSETS_COLLECTION,
  };
};

export const searchAssetCollectionSuccess = (
  data: IAssetCollectionListModel
): AssetActionsTypes => {
  return {
    payload: data,
    type: Consts.SEARCH_ASSETS_COLLECTION_SUCCESS,
  };
};

export const searchAssetCollectionFailure = (
  error?: IErrorModel
): AssetActionsTypes => {
  return {
    error,
    type: Consts.SEARCH_ASSETS_COLLECTION_FAILURE,
  };
};

export const browseAssetCollection = (
  filter: IAssetCollectionSearchFilterModel
): AssetActionsTypes => {
  return {
    filter,
    type: Consts.BROWSE_ASSETS_COLLECTION,
  };
};

export const browseAssetCollectionSuccess = (
  data: IAssetCollectionListModel
): AssetActionsTypes => {
  return {
    payload: data,
    type: Consts.BROWSE_ASSETS_COLLECTION_SUCCESS,
  };
};

export const browseAssetCollectionFailure = (
  error?: IErrorModel
): AssetActionsTypes => {
  return {
    error,
    type: Consts.BROWSE_ASSETS_COLLECTION_FAILURE,
  };
};

export const browseAssetCollectionCancel = (): AssetActionsTypes => {
  return {
    type: Consts.BROWSE_ASSETS_COLLECTION_CANCEL,
  };
};

export const getAssetTypes = (): AssetActionsTypes => {
  return {
    type: Consts.GET_ASSET_TYPES,
  };
};

export const getAssetTypesSuccess = (
  data: IAssetTypeModel[]
): AssetActionsTypes => {
  return {
    payload: {
      data,
    },
    type: Consts.GET_ASSET_TYPES_SUCCESS,
  };
};

export const getAssetTypesFailure = (
  error?: IErrorModel
): AssetActionsTypes => {
  return {
    payload: {
      error,
    },
    type: Consts.GET_ASSET_TYPES_FAILURE,
  };
};

export const getAssetEventTypes = (): AssetActionsTypes => {
  return {
    type: Consts.GET_ASSET_EVENT_TYPES,
  };
};

export const getAssetEventTypesSuccess = (
  data: IAssetEventTypesModel[]
): AssetActionsTypes => {
  return {
    payload: {
      data,
    },
    type: Consts.GET_ASSET_EVENT_TYPES_SUCCESS,
  };
};

export const getAssetEventTypesFailure = (
  error?: IErrorModel
): AssetActionsTypes => {
  return {
    payload: {
      error,
    },
    type: Consts.GET_ASSET_EVENT_TYPES_FAILURE,
  };
};

export const getAssetEvent = (id: number): AssetActionsTypes => {
  return {
    id,
    type: Consts.GET_ASSET_EVENT,
  };
};

export const getAssetEventSuccess = (
  data: IAssetEventModel
): AssetActionsTypes => {
  return {
    payload: data,
    type: Consts.GET_ASSET_EVENT_SUCCESS,
  };
};

export const getAssetEventFailure = (
  error?: IErrorModel
): AssetActionsTypes => {
  return {
    error,
    type: Consts.GET_ASSET_EVENT_FAILURE,
  };
};

export const addAssetEvent = (data: any): AssetActionsTypes => {
  return {
    payload: data,
    type: Consts.ADD_ASSET_EVENT,
  };
};

export const addAssetEventSuccess = (
  data: IAssetEventModel
): AssetActionsTypes => {
  return {
    payload: data,
    type: Consts.ADD_ASSET_EVENT_SUCCESS,
  };
};

export const addAssetEventFailure = (
  error?: IErrorModel
): AssetActionsTypes => {
  return {
    error,
    type: Consts.ADD_ASSET_EVENT_FAILURE,
  };
};

export const getAssetImageTypes = (): AssetActionsTypes => {
  return {
    type: Consts.GET_ASSET_IMAGE_TYPES,
  };
};

export const getAssetImageTypesSuccess = (
  data: IAssetImageTypeModel[]
): AssetActionsTypes => {
  return {
    payload: {
      data,
    },
    type: Consts.GET_ASSET_IMAGE_TYPES_SUCCESS,
  };
};

export const getAssetImageTypesFailure = (
  error?: IErrorModel
): AssetActionsTypes => {
  return {
    payload: {
      error,
    },
    type: Consts.GET_ASSET_IMAGE_TYPES_FAILURE,
  };
};

export const getAssetContentTypes = (): AssetActionsTypes => {
  return {
    type: Consts.GET_ASSET_CONTENT_TYPES,
  };
};

export const getAssetContentTypesSuccess = (
  data: IAssetContentTypeModel[]
): AssetActionsTypes => {
  return {
    payload: {
      data,
    },
    type: Consts.GET_ASSET_CONTENT_TYPES_SUCCESS,
  };
};

export const getAssetContentTypesFailure = (
  error?: IErrorModel
): AssetActionsTypes => {
  return {
    payload: {
      error,
    },
    type: Consts.GET_ASSET_CONTENT_TYPES_FAILURE,
  };
};

export const getAssetContentStreamTypes = (): AssetActionsTypes => {
  return {
    type: Consts.GET_ASSET_CONTENT_STREAM_TYPES,
  };
};

export const getAssetContentStreamTypesSuccess = (
  data: IAssetContentStreamTypeModel[]
): AssetActionsTypes => {
  return {
    payload: {
      data,
    },
    type: Consts.GET_ASSET_CONTENT_STREAM_TYPES_SUCCESS,
  };
};

export const getAssetContentStreamTypesFailure = (
  error?: IErrorModel
): AssetActionsTypes => {
  return {
    payload: {
      error,
    },
    type: Consts.GET_ASSET_CONTENT_STREAM_TYPES_FAILURE,
  };
};

export const getAssetAgeRestrictions = (): AssetActionsTypes => {
  return {
    type: Consts.GET_ASSET_AGE_RESTRICTIONS,
  };
};

export const getAssetAgeRestrictionsSuccess = (
  data: IAssetAgeRestrictionModel[]
): AssetActionsTypes => {
  return {
    payload: {
      data,
    },
    type: Consts.GET_ASSET_AGE_RESTRICTIONS_SUCCESS,
  };
};

export const getAssetAgeRestrictionsFailure = (
  error?: IErrorModel
): AssetActionsTypes => {
  return {
    payload: {
      error,
    },
    type: Consts.GET_ASSET_AGE_RESTRICTIONS_FAILURE,
  };
};

export const selectAssetParent = (id: number): AssetActionsTypes => {
  return {
    id,
    type: Consts.SELECT_ASSET_PARENT,
  };
};

export const selectAssetParentSuccess = (
  data: IAssetModel[]
): AssetActionsTypes => {
  return {
    payload: {
      data,
    },
    type: Consts.SELECT_ASSET_PARENT_SUCCESS,
  };
};

export const selectAssetParentFailure = (
  error?: IErrorModel
): AssetActionsTypes => {
  return {
    payload: {
      error,
    },
    type: Consts.SELECT_ASSET_PARENT_FAILURE,
  };
};

export const searchAssetCategories = (
  filter: IAssetCategoriesSearchFilterModel
): AssetActionsTypes => {
  return {
    filter,
    type: Consts.SEARCH_ASSET_CATEGORIES,
  };
};

export const searchAssetCategoriesSuccess = (
  data: IAssetCategoriesListModel
): AssetActionsTypes => {
  return {
    payload: data,
    type: Consts.SEARCH_ASSET_CATEGORIES_SUCCESS,
  };
};

export const searchAssetCategoriesFailure = (
  error?: IErrorModel
): AssetActionsTypes => {
  return {
    error,
    type: Consts.SEARCH_ASSET_CATEGORIES_FAILURE,
  };
};

export const searchAssetPeople = (
  filter?: IAssetPeopleSearchFilterModel
): AssetActionsTypes => {
  return {
    filter,
    type: Consts.SEARCH_ASSET_PEOPLE,
  };
};

export const searchAssetPeopleSuccess = (
  data: IAssetPeopleListModel
): AssetActionsTypes => {
  return {
    payload: data,
    type: Consts.SEARCH_ASSET_PEOPLE_SUCCESS,
  };
};

export const searchAssetPeopleFailure = (
  error?: IErrorModel
): AssetActionsTypes => {
  return {
    error,
    type: Consts.SEARCH_ASSET_PEOPLE_FAILURE,
  };
};

export const uploadVideo = (data: any): AssetActionsTypes => {
  return {
    payload: data,
    type: Consts.UPLOAD_VIDEO,
  };
};

export const uploadVideoSuccess = (
  data?: UploadFileInfoModel
): AssetActionsTypes => {
  return {
    payload: data,
    type: Consts.UPLOAD_VIDEO_SUCCESS,
  };
};

export const uploadVideoFailure = (error?: IErrorModel): AssetActionsTypes => {
  return {
    error,
    type: Consts.UPLOAD_VIDEO_FAILURE,
  };
};

export const searchAssetEvents = (
  pageSize: number,
  pageNumber: number,
  filter: IAssetEventsSearchFilterModel
): AssetActionsTypes => {
  return {
    pageNumber,
    pageSize,
    filter,
    type: Consts.SEARCH_ASSET_EVENTS,
  };
};

export const searchAssetEventsSuccess = (
  data: IAssetEventsListModel
): AssetActionsTypes => {
  return {
    payload: data,
    type: Consts.SEARCH_ASSET_EVENTS_SUCCESS,
  };
};

export const searchAssetEventsFailure = (
  error?: IErrorModel
): AssetActionsTypes => {
  return {
    error,
    type: Consts.SEARCH_ASSET_EVENTS_FAILURE,
  };
};

export const insertAssetCategory = (
  data: IAssetCategoryModel
): IInsertAssetCategoryAction => {
  return {
    payload: data,
    type: Consts.INSERT_ASSET_CATEGORY,
  };
};

export const insertAssetCategorySuccess = (
  data: IAssetCategoryModel
): IInsertAssetCategorySuccessAction => {
  return {
    payload: data,
    type: Consts.INSERT_ASSET_CATEGORY_SUCCESS,
  };
};

export const insertAssetCategoryFailure = (
  error?: IErrorModel
): IInsertAssetCategoryFailureAction => {
  return {
    error,
    type: Consts.INSERT_ASSET_CATEGORY_FAILURE,
  };
};

export const updateAssetCategory = (
  data: IAssetCategoryModel
): IUpdateAssetCategoryAction => {
  return {
    payload: data,
    type: Consts.UPDATE_ASSET_CATEGORY,
  };
};

export const updateAssetCategorySuccess = (
  data: IAssetCategoryModel
): IUpdateAssetCategorySuccessAction => {
  return {
    payload: data,
    type: Consts.UPDATE_ASSET_CATEGORY_SUCCESS,
  };
};

export const updateAssetCategoryFailure = (
  error?: IErrorModel
): IUpdateAssetCategoryFailureAction => {
  return {
    error,
    type: Consts.UPDATE_ASSET_CATEGORY_FAILURE,
  };
};

export const deleteAssetCategory = (
  data: IAssetCategoryModel
): IDeleteAssetCategoryAction => {
  return {
    payload: data,
    type: Consts.DELETE_ASSET_CATEGORY,
  };
};

export const deleteAssetCategorySuccess = (): IDeleteAssetCategorySuccessAction => {
  return {
    type: Consts.DELETE_ASSET_CATEGORY_SUCCESS,
  };
};

export const deleteAssetCategoryFailure = (
  error?: IErrorModel
): IDeleteAssetCategoryFailureAction => {
  return {
    error,
    type: Consts.DELETE_ASSET_CATEGORY_FAILURE,
  };
};

export const buyAsset = (assetId: number): IBuyAssetAction => {
  return {
    type: Consts.BUY_ASSET,
    assetId,
  };
};

export const buyAssetSuccess = (
  data: IAssetEventBuyModel
): IBuyAssetSuccessAction => {
  return {
    type: Consts.BUY_ASSET_SUCCESS,
    payload: data,
  };
};

export const buyAssetFailure = (
  error?: IErrorModel
): IBuyAssetFailureAction => {
  return {
    type: Consts.BUY_ASSET_FAILURE,
    error,
  };
};

export const selectAssetsInAsset = (
  AssetParentId: number
): AssetActionsTypes => {
  return {
    AssetParentId,
    type: Consts.SELECT_ASSETS_IN_ASSET,
  };
};

export const selectAssetsInAssetSuccess = (
  data: IAssetInAssetListModel
): AssetActionsTypes => {
  return {
    payload: data,
    type: Consts.SELECT_ASSETS_IN_ASSET_SUCCESS,
  };
};

export const selectAssetsInAssetFailure = (
  error?: IErrorModel
): AssetActionsTypes => {
  return {
    error,
    type: Consts.SELECT_ASSETS_IN_ASSET_FAILURE,
  };
};

export const deleteAssetInAsset = (
  data: IAssetInAssetModel
): AssetActionsTypes => {
  return {
    payload: data,
    type: Consts.DELETE_ASSET_IN_ASSET,
  };
};

export const deleteAssetInAssetSuccess = (): AssetActionsTypes => {
  return {
    type: Consts.DELETE_ASSET_IN_ASSET_SUCCESS,
  };
};

export const deleteAssetInAssetFailure = (
  error?: IErrorModel
): AssetActionsTypes => {
  return {
    error,
    type: Consts.DELETE_ASSET_IN_ASSET_FAILURE,
  };
};

export const insertAssetInAsset = (
  data: IAssetInAssetModel
): AssetActionsTypes => {
  return {
    payload: data,
    type: Consts.INSERT_ASSET_IN_ASSET,
  };
};

export const insertAssetInAssetSuccess = (
  data: IAssetInAssetModel
): AssetActionsTypes => {
  return {
    payload: data,
    type: Consts.INSERT_ASSET_IN_ASSET_SUCCESS,
  };
};

export const insertAssetInAssetFailure = (
  error?: IErrorModel
): AssetActionsTypes => {
  return {
    error,
    type: Consts.INSERT_ASSET_IN_ASSET_FAILURE,
  };
};

export const updateAssetInAsset = (
  data: IAssetInAssetModel
): AssetActionsTypes => {
  return {
    payload: data,
    type: Consts.UPDATE_ASSET_IN_ASSET,
  };
};

export const updateAssetInAssetSuccess = (
  data: IAssetInAssetModel
): AssetActionsTypes => {
  return {
    payload: data,
    type: Consts.UPDATE_ASSET_IN_ASSET_SUCCESS,
  };
};

export const updateAssetInAssetFailure = (
  error?: IErrorModel
): AssetActionsTypes => {
  return {
    error,
    type: Consts.UPDATE_ASSET_IN_ASSET_FAILURE,
  };
};

export const Actions = {
  createAsset,
  createAssetCollection,
  createAssetCollectionAndSaveAssets,
  deleteAsset,
  getAsset,
  getAssetContentUploadUrl,
  updateAsset,
  browseAssets,
  searchAsset,
  searchAssetMore,
  uploadAssetContentFile,
  uploadAssetContentFileCancel,
  getAssetsCollection,
  getAssetsCollectionSuccess,
  getAssetsCollectionFailure,
  updateAssetsCollection,
  updateAssetsCollectionSuccess,
  updateAssetsCollectionFailure,
  createAssetsCollection,
  createAssetsCollectionSuccess,
  createAssetsCollectionFailure,
  deleteAssetsCollection,
  deleteAssetsCollectionSuccess,
  deleteAssetsCollectionFailure,
  addAssetInCollection,
  addAssetInCollectionSuccess,
  addAssetInCollectionFailure,
  deleteAssetInCollection,
  deleteAssetInCollectionSuccess,
  deleteAssetInCollectionFailure,
  searchAssetsByAssetCollectionId,
  searchAssetsByAssetCollectionIdSuccess,
  searchAssetsByAssetCollectionIdFailure,
  searchAssetCollection,
  searchAssetCollectionSuccess,
  searchAssetCollectionFailure,
  browseAssetCollection,
  browseAssetCollectionCancel,
  browseAssetCollectionSuccess,
  browseAssetCollectionFailure,
  getAssetTypes,
  getAssetTypesSuccess,
  getAssetTypesFailure,
  getAssetImageTypes,
  getAssetImageTypesSuccess,
  getAssetImageTypesFailure,
  getAssetContentTypes,
  getAssetContentTypesSuccess,
  getAssetContentTypesFailure,
  getAssetContentStreamTypes,
  getAssetContentStreamTypesSuccess,
  getAssetContentStreamTypesFailure,
  getAssetAgeRestrictions,
  getAssetAgeRestrictionsSuccess,
  getAssetAgeRestrictionsFailure,
  searchAssetCategories,
  searchAssetCategoriesSuccess,
  searchAssetCategoriesFailure,
  insertAssetCategory,
  updateAssetCategory,
  deleteAssetCategory,
  searchAssetPeople,
  searchAssetPeopleSuccess,
  searchAssetPeopleFailure,
  getUploadFileInfo,
  getUploadFileInfoSuccess,
  getUploadFileInfoFailure,
  getContentUploadUrl,
  getContentUploadUrlSuccess,
  getContentUploadUrlFailure,
  uploadAssetImage,
  uploadAssetImageSuccess,
  uploadAssetImageFailure,
  updateAssetImage,
  updateAssetImageSuccess,
  updateAssetImageFailure,
  deleteAssetImage,
  deleteAssetImageSuccess,
  deleteAssetImageFailure,
  addAssetImage,
  addAssetImageSuccess,
  addAssetImageFailure,
  uploadVideo,
  uploadVideoSuccess,
  uploadVideoFailure,
  getAssetContentUploadFileInfo,
  getAssetContentUploadFileInfoSuccess,
  getAssetContentUploadFileInfoFailure,
  addAssetContent,
  addAssetContentSuccess,
  addAssetContentFailure,
  deleteAssetContent,
  deleteAssetContentSuccess,
  deleteAssetContentFailure,
  addAssetEvent,
  addAssetEventSuccess,
  addAssetEventFailure,
  getAssetComment,
  getAssetCommentSuccess,
  getAssetCommentFailure,
  searchAssetComments,
  searchAssetCommentsSuccess,
  searchAssetCommentsFailure,
  searchAssetCommentsMore,
  searchAssetCommentsMoreSuccess,
  searchAssetCommentsMoreFailure,
  addAssetComment,
  addAssetCommentSuccess,
  addAssetCommentFailure,
  getAssetEventTypes,
  getAssetEventTypesSuccess,
  getAssetEventTypesFailure,
  searchAssetEvents,
  searchAssetEventsSuccess,
  searchAssetEventsFailure,
  selectAssetInCollection,
  selectAssetInCollectionSuccess,
  selectAssetInCollectionFailure,
  buyAsset,
  selectAssetParent,
  selectAssetParentSuccess,
  selectAssetParentFailure,
  updateAssetContent,
  updateAssetContentUrl,
  updateAssetInCollection,
  selectAssetsInAsset,
  selectAssetsInAssetSuccess,
  selectAssetsInAssetFailure,
  deleteAssetInAsset,
  deleteAssetInAssetSuccess,
  deleteAssetInAssetFailure,
  insertAssetInAsset,
  insertAssetInAssetSuccess,
  insertAssetInAssetFailure,
  updateAssetInAsset,
  updateAssetInAssetSuccess,
  updateAssetInAssetFailure,
};
