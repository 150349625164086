/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import { IdentifierType } from "../../../enums";
import {
  IAssetCollectionListModel,
  IAssetCollectionModel,
  IAssetCollectionSearchFilterModel,
  IAssetListModel,
  IConfigModel,
  IErrorModel,
  IPlaylistSourceListModel,
  IPlaylistSourceModel,
  IPlaylistSourceSearchFilterModel,
} from "../../../models";
import { IDataProvider } from "../IDataProvider";
import { InternalDataProvider } from "../Internal";
import { SEARCH_COLLECTIONS_QUERY } from "./gql";
import { ModelsMapperHelper } from "./helpers";
import { IPlaylistListModel } from "./models";
import { AxinomService } from "./services";

const axinomService = new AxinomService();
const cmsUrl = process.env.REACT_APP_AXINOM_CMS_URL;

export class AxinomDataProvider implements IDataProvider {
  private _internalDataProvider = new InternalDataProvider();

  initHttpFactory() {
    this._internalDataProvider.initHttpFactory();
  }

  Config = {
    get(data: IConfigModel): Promise<IConfigModel> {
      const config: IConfigModel = {
        ...data,
        Sidebar: [
          {
            id: "axinom",
            title: "Axinom",
            iconUrl: "https://admin.service.eu.axinom.com/favicon.ico",
            url: "https://admin.service.eu.axinom.com",
          },
        ],
      };
      return Promise.resolve(config);
    },
  };

  ApplicationConfiguration = {
    Options: {
      IdentifierType: IdentifierType.String,
    },
    linkToPlaylist(data: IPlaylistSourceModel) {
      const id = data.Id;
      return { url: `${cmsUrl}/collections/${id}`, target: "_blank" };
    },
    async browsePlaylist(
      filter: IPlaylistSourceSearchFilterModel
    ): Promise<IPlaylistSourceListModel> {
      try {
        const offset = ((filter.PageNumber || 1) - 1) * (filter.PageSize || 10);
        const first = filter.PageSize || 10;
        const name = filter.Name || "";
        const description = filter.Description || "";

        const variables = {
          offset,
          first,
          name,
          description,
        };

        return await axinomService
          .search<IPlaylistListModel>(SEARCH_COLLECTIONS_QUERY, variables)
          .then((result) => {
            result.data.collections.edges.map((el) => el.node);

            if (result && result.data) {
              const response: IPlaylistSourceListModel = {
                Entities: [],
                TotalCount: result.data.collections.edges.length,
                Filter: filter,
              };

              response.Entities = result.data.collections.edges.map((el) =>
                ModelsMapperHelper.toPlaylistSourceModel(el.node)
              );

              return Promise.resolve(response);
            } else {
              return Promise.reject("Missing data");
            }
          });
      } catch (error) {
        return Promise.reject(error as IErrorModel);
      }
    },
    async searchAssetsByAssetCollectionId(
      _: IAssetCollectionSearchFilterModel
    ): Promise<IAssetListModel> {
      return Promise.reject("Not implemented");
    },
  };

  AssetCollection = {
    Options: {
      IdentifierType: IdentifierType.String,
      InsertEnabled: false,
      UpdateEnabled: false,
      DeleteEnabled: false,
    },
    link(data: IAssetCollectionModel) {
      const id = data.Id;
      return { url: `${cmsUrl}/collections/${id}`, target: "_blank" };
    },
    async get(_: string): Promise<IAssetCollectionModel> {
      return Promise.reject("Not implemented");
    },
    async insert(_: IAssetCollectionModel): Promise<IAssetCollectionModel> {
      return Promise.reject("Not implemented");
    },
    async update(_: IAssetCollectionModel): Promise<IAssetCollectionModel> {
      return Promise.reject("Not implemented");
    },
    async delete(_: IAssetCollectionModel): Promise<void> {
      return Promise.reject("Not implemented");
    },
    async search(
      filter: IAssetCollectionSearchFilterModel
    ): Promise<IAssetCollectionListModel> {
      try {
        const offset = ((filter.PageNumber || 1) - 1) * (filter.PageSize || 10);
        const first = filter.PageSize || 10;
        const name = filter.Name || "";
        const description = filter.Description || "";

        const variables = {
          offset,
          first,
          name,
          description,
        };

        return await axinomService
          .search<IPlaylistListModel>(SEARCH_COLLECTIONS_QUERY, variables)
          .then((result) => {
            result.data.collections.edges.map((el) => el.node);

            if (result && result.data) {
              const response: IAssetCollectionListModel = {
                Entities: [],
                TotalCount: result.data.collections.edges.length,
                Filter: filter,
              };

              response.Entities = result.data.collections.edges.map((el) =>
                ModelsMapperHelper.toAssetCollectionModel(el.node)
              );

              return Promise.resolve(response);
            } else {
              return Promise.reject("Missing data");
            }
          });
      } catch (error) {
        return Promise.reject(error as IErrorModel);
      }
    },
    async searchAssetsByAssetCollectionId(
      _: IAssetCollectionSearchFilterModel
    ): Promise<IAssetListModel> {
      return Promise.reject("Not implemented");
    },
  };
}
