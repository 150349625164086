import {
  ApplicationConfigurationStore,
  ICommonAppState,
} from "@bms/common-services";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { ApplicationScreenAddComponentModal as ApplicationScreenAddComponentModalDefinition } from "./ApplicationScreenAddComponentModal";
import { withScreenContext } from "../../context";

const mapStateToProps = (state: ICommonAppState) => {
  return {
    componentsTypes: state.applicationConfiguration.componentsTypes,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  selectComponentsTypes: () => {
    return dispatch(
      ApplicationConfigurationStore.Actions.selectComponentsTypes()
    );
  },
});

export const ApplicationScreenAddComponentModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withTranslation()(
    withScreenContext(ApplicationScreenAddComponentModalDefinition)
  )
);
