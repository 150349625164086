import { IApplicationComponentModel, PlatformType } from "@bms/common-services";
import React from "react";
import "./SettingsMenuItemPreview.scss";
import {
  LayoutOptionsModel,
  SettingsMenuItemPropertyModel,
} from "../../models";
import "./SettingsMenuItemPreview.scss";

export interface ISettingsMenuItemPreview {
  properties: SettingsMenuItemPropertyModel;
  key: string;
  component: IApplicationComponentModel;
  layoutOptions: LayoutOptionsModel;
}

export const SettingsMenuItemPreview = ({
  properties,
  component,
  layoutOptions,
}: ISettingsMenuItemPreview) => {
  const renderIcon = () => {
    const iconUrl = properties.IconAbsoluteUrl
      ? properties.IconAbsoluteUrl
      : properties.IconUrl;

    return (
      <img
        src={iconUrl}
        className={`SettingsMenuItemPreview__Icon ${classItemName}`}
      />
    );
  };

  const renderTitle = () => {
    return (
      <span className={`SettingsMenuItemPreview__Title ${classItemName}`}>
        {properties.Title}
      </span>
    );
  };

  let classItemName = "";
  switch (layoutOptions.Platform) {
    case PlatformType.AndroidPhone:
    case PlatformType.AndroidTablet:
    case PlatformType.iOSPhone:
    case PlatformType.iPad:
      classItemName = "Mobile";
      break;
    case PlatformType.AndroidTV:
    case PlatformType.AppleTV:
    case PlatformType.Tizen:
    case PlatformType.WebOS:
    case PlatformType.Roku:
      classItemName = "TV";
      break;
    case PlatformType.Any:
    case PlatformType.Web:
      classItemName = "Web";
      break;
    default:
      break;
  }

  return (
    <div
      key={component.Id}
      className={`SettingsMenuItemPreview ${classItemName}`}
      title={properties.Title}
    >
      {renderIcon()}
      {renderTitle()}
    </div>
  );
};
