/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import { gql } from "@apollo/client";

export const SEARCH_COLLECTIONS_QUERY = gql`
  query SearchCollections(
    $offset: Int
    $first: Int
    $name: String
    $description: String
  ) {
    collections(
      offset: $offset
      first: $first
      filter: {
        title: { includesInsensitive: $name }
        description: { includesInsensitive: $description }
      }
    ) {
      edges {
        node {
          id
          title
          createdDate
          createdUser
          description
        }
      }
    }
  }
`;
