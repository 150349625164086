import {
  IRatingCategoryFilterModel,
  IRatingCategoryModel,
  IRatingCategoryListModel,
} from "../../models";
import { SimpleServiceBase } from "../Base";

export class RatingCategoriesService extends SimpleServiceBase<
  IRatingCategoryModel,
  IRatingCategoryListModel,
  number,
  IRatingCategoryFilterModel
> {
  get url() {
    return "/RatingCategories";
  }
}
