import {
  AlignType,
  AlignTypeHelper,
  CellType,
  CellTypeHelper,
  ConvertHelper,
  DataProvider,
  ElevationLevel,
  ElevationLevelHelper,
  IApplicationComponentLayoutModel,
  IApplicationComponentModel,
  IApplicationComponentPropertyModel,
  Identifier,
  PlayListSourceType,
  Orientation,
  OrientationHelper,
  PropertyType,
  PropertyTypeHelper,
  RecordStatus,
  PlatformType,
} from "@bms/common-services";
import { ComponentLayoutPropertyModel } from "./ComponentLayoutPropertyModel";
import { ComponentPropertyModel } from "./ComponentPropertyModel";
import { LayoutOptionsModel } from "./LayoutOptionsModel";

export class ListComponentPropertyModel extends ComponentPropertyModel {
  Title?: string;

  TitleTranslationKey?: string;

  TitleUrl?: string;

  TitleAlign: AlignType = AlignType.Left;

  TitleElevation: ElevationLevel = ElevationLevel.Medium;

  VisibleItemsCount: number = 3;

  Orientation: Orientation = Orientation.Default;

  CellType: CellType = CellType.Default;

  SourceId?: Identifier;

  SourceName?: string;

  SourceType?: PlayListSourceType;

  Layout?: ComponentLayoutPropertyModel;

  constructor(component?: IApplicationComponentModel) {
    super();

    if (component) {
      this.init(component);
    }
  }

  public getLayoutProperty(
    component: IApplicationComponentModel,
    layoutOptions: LayoutOptionsModel
  ): ComponentLayoutPropertyModel {
    if (!component.Layouts) {
      component.Layouts = [];
    }

    let componentLayout = component.Layouts.find(
      (row: IApplicationComponentLayoutModel) =>
        row.PlatformCode === layoutOptions.Platform
    );

    let height = 1;

    if (!componentLayout) {
      componentLayout = {
        RecordStatus: RecordStatus.Inserted,
        ApplicationComponentId: component.Id,
        PlatformCode: layoutOptions.Platform,
        PositionX: 0,
        PositionY: layoutOptions.CurrentPositionY,
        Width: 12,
        Height: height,
      };

      component.Layouts.push(componentLayout);
    }

    componentLayout.Width = 12;

    switch (this.CellType) {
      case CellType.Highlights:
        const itemHighlightsMinHeight = 350;

        while (
          height * layoutOptions.RowHeight + (height - 1) * 10 <
          itemHighlightsMinHeight
        ) {
          height++;
        }
        break;
      case CellType.Cover:
        const itemCoverWidth =
          (layoutOptions.RowWidth * 0.9) / this.VisibleItemsCount - 20;
        const itemCoverHeight = (4 * itemCoverWidth) / 3;
        const itemCoverMinHeight =
          itemCoverHeight + 3 * layoutOptions.RowHeight;

        while (
          height * layoutOptions.RowHeight + (height - 1) * 10 <
          itemCoverMinHeight
        ) {
          height++;
        }

        break;
      default:
        const itemWidth =
          (layoutOptions.RowWidth * 0.9) / this.VisibleItemsCount - 20;
        const itemHeight = (9 * itemWidth) / 16;
        const itemMinHeight = itemHeight + 3 * layoutOptions.RowHeight;

        while (
          height * layoutOptions.RowHeight + (height - 1) * 10 <
          itemMinHeight
        ) {
          height++;
        }

        break;
    }

    if (componentLayout.Height !== height) {
      componentLayout.Height = height;
    }

    return {
      PositionX: componentLayout.PositionX,
      PositionY: componentLayout.PositionY,
      Width: componentLayout.Width,
      Height: componentLayout.Height,
      IsResizable: false,
      IsDraggable: true,
    };
  }

  setProperties(component: IApplicationComponentModel): void {
    let titleProperty = component.Properties?.find(
      (row: IApplicationComponentPropertyModel) =>
        row.Name === "Title" && row.PlatformCode === this.PlatformCode
    );

    if (!titleProperty && this.PlatformCode !== PlatformType.Any) {
      titleProperty = component.Properties?.find(
        (row: IApplicationComponentPropertyModel) =>
          row.Name === "Title" && row.PlatformCode === PlatformType.Any
      );
    }

    if (titleProperty && titleProperty.Value) {
      this.Title = titleProperty.Value.StringValue;
    }

    let titleTranslationKeyProperty = component.Properties?.find(
      (row: IApplicationComponentPropertyModel) =>
        row.Name === "TitleTranslationKey" &&
        row.PlatformCode === this.PlatformCode
    );

    if (
      !titleTranslationKeyProperty &&
      this.PlatformCode !== PlatformType.Any
    ) {
      titleTranslationKeyProperty = component.Properties?.find(
        (row: IApplicationComponentPropertyModel) =>
          row.Name === "TitleTranslationKey" &&
          row.PlatformCode === PlatformType.Any
      );
    }

    if (titleTranslationKeyProperty && titleTranslationKeyProperty.Value) {
      this.TitleTranslationKey = titleTranslationKeyProperty.Value.StringValue;
    }

    let titleUrlProperty = component.Properties?.find(
      (row: IApplicationComponentPropertyModel) =>
        row.Name === "TitleUrl" && row.PlatformCode === this.PlatformCode
    );

    if (!titleUrlProperty && this.PlatformCode !== PlatformType.Any) {
      titleUrlProperty = component.Properties?.find(
        (row: IApplicationComponentPropertyModel) =>
          row.Name === "TitleUrl" && row.PlatformCode === this.PlatformCode
      );
    }

    if (titleUrlProperty && titleUrlProperty.Value) {
      this.TitleUrl = titleUrlProperty.Value.StringValue;
    }

    let visibleItemsCountProperty = component.Properties?.find(
      (row: IApplicationComponentPropertyModel) =>
        row.Name === "VisibleItemsCount" &&
        row.PlatformCode === this.PlatformCode
    );

    if (!visibleItemsCountProperty && this.PlatformCode !== PlatformType.Any) {
      visibleItemsCountProperty = component.Properties?.find(
        (row: IApplicationComponentPropertyModel) =>
          row.Name === "VisibleItemsCount" &&
          row.PlatformCode === PlatformType.Any
      );
    }

    if (visibleItemsCountProperty && visibleItemsCountProperty.Value) {
      this.VisibleItemsCount =
        visibleItemsCountProperty.Value.IntegerValue || 0;
    } else {
      // set default value
      this.onPropertyChange("VisibleItemsCount", component);
    }

    let orientationProperty = component.Properties?.find(
      (row: IApplicationComponentPropertyModel) =>
        row.Name === "Orientation" && row.PlatformCode === this.PlatformCode
    );

    if (!orientationProperty && this.PlatformCode !== PlatformType.Any) {
      orientationProperty = component.Properties?.find(
        (row: IApplicationComponentPropertyModel) =>
          row.Name === "Orientation" && row.PlatformCode === PlatformType.Any
      );
    }

    if (orientationProperty && orientationProperty.Value) {
      this.Orientation = OrientationHelper.getValue(
        orientationProperty.Value.StringValue || "DEFAULT"
      );
    }

    let titleAlignProperty = component.Properties?.find(
      (row: IApplicationComponentPropertyModel) =>
        row.Name === "TitleAlign" && row.PlatformCode === this.PlatformCode
    );

    if (!titleAlignProperty && this.PlatformCode !== PlatformType.Any) {
      titleAlignProperty = component.Properties?.find(
        (row: IApplicationComponentPropertyModel) =>
          row.Name === "TitleAlign" && row.PlatformCode === PlatformType.Any
      );
    }

    if (titleAlignProperty && titleAlignProperty.Value) {
      this.TitleAlign =
        AlignTypeHelper.getValue(titleAlignProperty.Value.StringValue) ??
        AlignType.Left;
    }

    let titleElevationProperty = component.Properties?.find(
      (row: IApplicationComponentPropertyModel) =>
        row.Name === "TitleElevation" && row.PlatformCode === this.PlatformCode
    );

    if (!titleElevationProperty && this.PlatformCode !== PlatformType.Any) {
      titleElevationProperty = component.Properties?.find(
        (row: IApplicationComponentPropertyModel) =>
          row.Name === "TitleElevation" && row.PlatformCode === PlatformType.Any
      );
    }

    if (titleElevationProperty && titleElevationProperty.Value) {
      this.TitleElevation =
        ElevationLevelHelper.getValue(
          titleElevationProperty.Value.StringValue
        ) ?? ElevationLevel.Medium;
    }

    let cellTypeProperty = component.Properties?.find(
      (row: IApplicationComponentPropertyModel) =>
        row.Name === "CellType" && row.PlatformCode === this.PlatformCode
    );

    if (!cellTypeProperty && this.PlatformCode !== PlatformType.Any) {
      cellTypeProperty = component.Properties?.find(
        (row: IApplicationComponentPropertyModel) =>
          row.Name === "CellType" && row.PlatformCode === PlatformType.Any
      );
    }

    if (cellTypeProperty && cellTypeProperty.Value) {
      this.CellType = CellTypeHelper.getValue(
        cellTypeProperty.Value.StringValue || "DEFAULT"
      );
    }

    let sourceIdProperty = component.Properties?.find(
      (row: IApplicationComponentPropertyModel) =>
        row.Name === "SourceId" && row.PlatformCode === this.PlatformCode
    );

    if (!sourceIdProperty && this.PlatformCode !== PlatformType.Any) {
      sourceIdProperty = component.Properties?.find(
        (row: IApplicationComponentPropertyModel) =>
          row.Name === "SourceId" && row.PlatformCode === PlatformType.Any
      );
    }

    if (sourceIdProperty && sourceIdProperty.Value) {
      switch (PropertyTypeHelper.getValue(sourceIdProperty.PropertyType)) {
        case PropertyType.Integer:
          this.SourceId = sourceIdProperty.Value.IntegerValue;
          break;
        default:
          this.SourceId = sourceIdProperty.Value.StringValue;
          break;
      }
    }

    let sourceNameProperty = component.Properties?.find(
      (row: IApplicationComponentPropertyModel) =>
        row.Name === "SourceName" && row.PlatformCode === this.PlatformCode
    );

    if (!sourceNameProperty && this.PlatformCode !== PlatformType.Any) {
      sourceNameProperty = component.Properties?.find(
        (row: IApplicationComponentPropertyModel) =>
          row.Name === "SourceName" && row.PlatformCode === PlatformType.Any
      );
    }

    if (sourceNameProperty && sourceNameProperty.Value) {
      this.SourceName = sourceNameProperty.Value.StringValue;
    }

    let sourceTypeProperty = component.Properties?.find(
      (row: IApplicationComponentPropertyModel) =>
        row.Name === "SourceType" && row.PlatformCode === this.PlatformCode
    );

    if (!sourceTypeProperty && this.PlatformCode !== PlatformType.Any) {
      sourceTypeProperty = component.Properties?.find(
        (row: IApplicationComponentPropertyModel) =>
          row.Name === "SourceType" && row.PlatformCode === PlatformType.Any
      );
    }

    if (sourceTypeProperty && sourceTypeProperty.Value) {
      this.SourceType = sourceTypeProperty.Value
        .StringValue as PlayListSourceType;
    }
  }

  setProperty(property: IApplicationComponentPropertyModel) {
    switch (property.Name) {
      case "Title":
        property.PropertyType = PropertyType.String;
        property.IsEnumerable = false;
        break;
      case "TitleTranslationKey":
        property.PropertyType = PropertyType.String;
        property.IsEnumerable = false;
        break;
      case "TitleUrl":
        property.PropertyType = PropertyType.String;
        property.IsEnumerable = false;
        break;
      case "TitleAlign":
        property.PropertyType = PropertyType.String;
        property.IsEnumerable = false;
        break;
      case "TitleElevation":
        property.PropertyType = PropertyType.String;
        property.IsEnumerable = false;
        break;
      case "VisibleItemsCount":
        property.PropertyType = PropertyType.Integer;
        property.IsEnumerable = false;
        break;
      case "Orientation":
        property.PropertyType = PropertyType.String;
        property.IsEnumerable = false;
        break;
      case "CellType":
        property.PropertyType = PropertyType.String;
        property.IsEnumerable = false;
        break;
      case "SourceId":
        property.PropertyType =
          PropertyTypeHelper.getValue(
            DataProvider.ApplicationConfiguration?.Options.IdentifierType
          ) ?? PropertyType.Integer;
        property.IsEnumerable = false;
        break;
      case "SourceName":
        property.PropertyType = PropertyType.String;
        property.IsEnumerable = false;
        break;
      case "SourceType":
        property.PropertyType = PropertyType.String;
        property.IsEnumerable = false;
        break;
    }
  }

  setPropertyValue(property: IApplicationComponentPropertyModel) {
    switch (property.Name) {
      case "Title":
        if (property.Value) {
          property.Value.StringValue = this.Title;
        }
        break;
      case "TitleTranslationKey":
        if (property.Value) {
          property.Value.StringValue = this.TitleTranslationKey;
        }
        break;
      case "TitleUrl":
        if (property.Value) {
          property.Value.StringValue = this.TitleUrl;
        }
        break;
      case "TitleAlign":
        if (property.Value) {
          property.Value.StringValue = this.TitleAlign;
        }
        break;
      case "TitleElevation":
        if (property.Value) {
          property.Value.StringValue = this.TitleElevation;
        }
        break;
      case "VisibleItemsCount":
        if (property.Value) {
          property.Value.IntegerValue = this.VisibleItemsCount;
        }
        break;
      case "Orientation":
        if (property.Value) {
          property.Value.StringValue = OrientationHelper.getStringValue(
            this.Orientation
          );
        }
        break;
      case "CellType":
        if (property.Value) {
          property.Value.StringValue = CellTypeHelper.getStringValue(
            this.CellType
          );
        }
        break;
      case "SourceId":
        if (property.Value) {
          switch (PropertyTypeHelper.getValue(property.PropertyType)) {
            case PropertyType.Integer:
              property.Value.IntegerValue = ConvertHelper.toNumber(
                this.SourceId
              );
              break;
            default:
              property.Value.StringValue = ConvertHelper.toString(
                this.SourceId
              );
              break;
          }
        }
        break;
      case "SourceName":
        if (property.Value) {
          property.Value.StringValue = this.SourceName;
        }
        break;
      case "SourceType":
        if (property.Value) {
          property.Value.StringValue = this.SourceType;
        }
        break;
    }
  }
}
