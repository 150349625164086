import React from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { Spin } from "@bms/common-ui";

import "./ImagePreview.scss";

export interface IImagePreviewProps {
  imageSrc?: string;
  className?: string;
}

export const ImagePreview = ({ imageSrc, className }: IImagePreviewProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [imageUrl, setImageUrl] = React.useState("");

  React.useEffect(() => {
    const imageLoader = new Image();
    setImageUrl("");

    if (imageSrc) {
      setLoading(true);
      imageLoader.src = imageSrc;
      imageLoader.onload = () => {
        setLoading(false);
        setImageUrl(imageSrc);
      };
      imageLoader.onerror = () => {
        setLoading(false);
      };
    }

    return () => {
      imageLoader.src = "";
    };
  }, [imageSrc]);

  return (
    <div className={cx("ImagePreview", className)}>
      {loading && <Spin />}
      {imageUrl && <img src={imageUrl} />}
      {!loading &&
        !imageUrl &&
        t("CONFIGURATION_IMAGE__PREVIEW_NOT_AVAILABLE_MESSAGE")}
    </div>
  );
};
