import { Choose, ChooseOption } from "@bms/common-ui";
import { useUserPlatformRatingDataProvider } from "../../../hooks";
import React from "react";
import { useTranslation } from "react-i18next";
import { IUserPlatformRatingAnalyticsSummaryFilterModel } from "@bms/common-services";

interface IRatingAppPlatformSelectProps {
  filter: IUserPlatformRatingAnalyticsSummaryFilterModel;
  onChange: (newValue: string) => void;
}

export const RatingAppPlatformSelect = (
  props: IRatingAppPlatformSelectProps
) => {
  const { filter, onChange } = props;
  const { t } = useTranslation();
  const { data, loading } = useUserPlatformRatingDataProvider();

  const onValueChange = (newValue: string) => {
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <Choose
      mode="multiple"
      defaultActiveFirstOption={false}
      placeholder={t("RATING_APP_SELECT_PLATFORM")}
      value={filter.Platforms}
      loading={loading}
      disabled={loading}
      onChange={onValueChange}
      style={{ width: "100%" }}
    >
      {data?.map((rating) => (
        <ChooseOption key={rating.PlatformCode} value={rating.PlatformCode}>
          {rating.PlatformName}
        </ChooseOption>
      ))}
    </Choose>
  );
};
