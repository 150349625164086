import { IErrorModel, MessageModel } from "../../models";

export interface IChatState {
  connected?: {
    [key: string]: boolean;
  };
}

export const USER_CONNECTED = "USER_CONNECTED";
export const USER_DISCONNECTED = "USER_DISCONNECTED";

export const SEND_CHAT_MESSAGE = "SEND_CHAT_MESSAGE";
export const SEND_CHAT_MESSAGE_SUCCESS = "SEND_CHAT_MESSAGE_SUCCESS";
export const SEND_CHAT_MESSAGE_FAILURE = "SEND_CHAT_MESSAGE_FAILURE";

export const RECEIVE_CHAT_MESSAGE = "RECEIVE_CHAT_MESSAGE";

export interface IUserConnectedAction {
  id: string;
  type: typeof USER_CONNECTED;
}

export interface IUserDisconnectedAction {
  id: string;
  type: typeof USER_DISCONNECTED;
}

export interface ISendChatMessageAction {
  data: MessageModel;
  type: typeof SEND_CHAT_MESSAGE;
}

export interface ISendChatMessageSuccessAction {
  type: typeof SEND_CHAT_MESSAGE_SUCCESS;
  data: MessageModel;
}

export interface ISendChatMessageFailureAction {
  type: typeof SEND_CHAT_MESSAGE_FAILURE;
  error?: IErrorModel;
}

export interface IReceiveChatMessageAction {
  data: MessageModel;
  roomId: number;
  type: typeof RECEIVE_CHAT_MESSAGE;
}

export type ChatActionsTypes =
  | IUserConnectedAction
  | IUserDisconnectedAction
  | ISendChatMessageAction
  | ISendChatMessageSuccessAction
  | ISendChatMessageFailureAction
  | IReceiveChatMessageAction;
