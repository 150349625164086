import {
  ApplicationConfigurationStore,
  IApplicationConfigurationBrandingKeyTypeModel,
  IApplicationConfigurationBrandingModel,
  IApplicationConfigurationBrandingValueModel,
  RecordStatus,
} from "@bms/common-services";
import { Form, IFormValues } from "@bms/common-ui";
import { isEqual, noop } from "lodash";
import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { getDefaultValByKeyOrType, type2Label } from "../../../../../helpers";
import { ActionButtons } from "../Buttons";
import "./BrandingValuesForm.scss";
import { FieldsGroup } from "./FieldsGroup";
import { ImageModal } from "./ImageModal/ImageModal";
import { useTranslation } from "react-i18next";

export interface IHandleSubmit {
  expandableData: IApplicationConfigurationBrandingModel;
  hasChanged: boolean;
}

export interface IBrandingValuesFormProps {
  brandData: IApplicationConfigurationBrandingModel;
  brandingsKeys?: IApplicationConfigurationBrandingKeyTypeModel[];
  handleClose: (brandData: IApplicationConfigurationBrandingModel) => void;
}

const formItemLayout = {
  labelCol: {
    lg: { span: 10 },
    md: { span: 10 },
    sm: { span: 10 },
    xs: { span: 24 },
  },
  wrapperCol: {
    lg: { span: 14 },
    md: { span: 14 },
    sm: { span: 14 },
    xs: { span: 24 },
  },
};

export const BrandingValuesForm: React.FC<IBrandingValuesFormProps> = (
  props
) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [showImageModal, setShowImageModal] = useState<boolean>(false);
  const [imageModalData, setImageModalData] = useState<
    IApplicationConfigurationBrandingValueModel
  >();
  const { t } = useTranslation();
  const {
    brandData: propsBrandData,
    brandingsKeys: brandingsProps,
    handleClose,
  } = props;
  const { Values: fetchedValues = [] } = propsBrandData || {};

  const brandExist = propsBrandData?.RecordStatus !== RecordStatus.Inserted;

  const updateBranding = useCallback(
    (branding: IApplicationConfigurationBrandingModel) =>
      dispatch(ApplicationConfigurationStore.Actions.updateBranding(branding)),
    [dispatch]
  );

  const insertBranding = useCallback(
    (branding: IApplicationConfigurationBrandingModel) =>
      dispatch(ApplicationConfigurationStore.Actions.insertBranding(branding)),
    [dispatch]
  );

  const deleteBranding = useCallback(
    (branding: IApplicationConfigurationBrandingModel) =>
      dispatch(ApplicationConfigurationStore.Actions.deleteBranding(branding)),
    [dispatch]
  );

  const getFetchedValue = (Key: string) =>
    fetchedValues.find((brandProp) => {
      return brandProp.Key === Key;
    })?.Value;

  const getUrl = (key: string, type: string) => {
    if (type !== "URL") return undefined;

    return fetchedValues.find((brandProp) => {
      return brandProp.Key === key;
    })?.Url;
  };

  const handleReset = () => {
    form.resetFields();
  };

  const brandingsPropsWithValues = brandingsProps!.map(
    (brandingProp): IApplicationConfigurationBrandingValueModel => ({
      ...brandingProp,
      Value:
        getFetchedValue(brandingProp.Key) ||
        getDefaultValByKeyOrType({
          Key: brandingProp.Key,
          Type: brandingProp.Type,
        }),
      Url: getUrl(brandingProp.Key, brandingProp.Type),
    })
  );

  const displayedFields = brandingsPropsWithValues;
  const groupedFields: { [key: string]: any } = {};

  displayedFields.map((field) => {
    const { Type } = field;
    const group = groupedFields[Type as string];
    if (!group || !Array.isArray(group)) {
      groupedFields[Type] = [];
    }

    groupedFields[Type].push(field);
  });

  const handleSetImageModalData = (
    imageData: IApplicationConfigurationBrandingValueModel
  ) => {
    setImageModalData(imageData);
    setShowImageModal(true);
  };

  const handleModalCancel = () => {
    setImageModalData(undefined);
    setShowImageModal(false);
  };

  const onCancel = () => {
    handleReset();
    handleClose(propsBrandData);
  };

  const onDelete = () => {
    const brandToDelete = {
      ...propsBrandData,
      RecordStatus: RecordStatus.Deleted,
    };

    deleteBranding(brandToDelete);
  };

  const onFinish = (formValues: IFormValues) => {
    const updatedValues: IApplicationConfigurationBrandingValueModel[] = (brandingsProps as IApplicationConfigurationBrandingKeyTypeModel[]).map(
      (availableProp) => {
        // Tiny naming problem there. As "Values" is and property in branding that contains array of entities
        // that contain property value... I decided that Values entities
        // are called prop in there to avoid "value value".

        const fetchedProp = fetchedValues.find(
          (someFetchedProp) => someFetchedProp.Key === availableProp.Key
        );

        const currentProp = {
          ...availableProp,
          ...fetchedProp,
        };

        currentProp.Sequence = undefined; // It's been found it has to be removed

        const getHexColorValue = (color: any): string =>
          typeof color === "string" ? color : color.hex;

        const isColor = (type: string) => type === "COLOR";

        const guiHasEditedValue = formValues?.hasOwnProperty(availableProp.Key);

        const editedValue = formValues[availableProp.Key];

        const currentOrPreviousValue = guiHasEditedValue
          ? isColor(availableProp!.Type)
            ? getHexColorValue(editedValue)
            : editedValue
          : fetchedProp?.Value;

        const newValue =
          currentOrPreviousValue ||
          getDefaultValByKeyOrType({
            Key: availableProp.Key,
            Type: availableProp.Type,
          });

        const valueHasChanged =
          newValue && `${fetchedProp?.Value}` !== newValue;

        const valueRecordStatus = fetchedProp
          ? valueHasChanged
            ? RecordStatus.Updated
            : RecordStatus.NoChange
          : RecordStatus.Inserted;

        return {
          ...currentProp,
          RecordStatus: valueRecordStatus,
          Value: newValue,
        };
      }
    );

    const newBrandData: Partial<IApplicationConfigurationBrandingModel> = {
      ...propsBrandData,
      Values: updatedValues,
    };

    const hasChanged = !isEqual(newBrandData, propsBrandData);

    const brandingRecordStatus: RecordStatus = brandExist
      ? hasChanged
        ? RecordStatus.Updated
        : RecordStatus.NoChange
      : RecordStatus.Inserted;

    newBrandData.RecordStatus = brandingRecordStatus;

    try {
      const handleRecordOperation: { [key: string]: any } = {
        [RecordStatus.Updated]: updateBranding,
        [RecordStatus.Inserted]: insertBranding,
        [RecordStatus.NoChange]: noop,
        [RecordStatus.Deleted]: noop,
      };

      handleRecordOperation[brandingRecordStatus](newBrandData);
    } catch (e) {
      console.error("unsupported record status");
    }
  };

  const handleImageChange = (
    imageKey: string,
    imageUrl?: string,
    imageFile?: File
  ) => {
    const formValues = form.getFieldsValue();
    formValues[imageKey] = imageFile || imageUrl;

    onFinish(formValues);
  };

  return (
    <Form {...formItemLayout} form={form} onFinish={onFinish}>
      {Object.entries(groupedFields).map(([type, group]) => (
        <FieldsGroup
          key={type}
          label={t(`${type2Label(type)}`)}
          dataSource={group}
          setImageModalData={handleSetImageModalData}
        />
      ))}

      <ActionButtons
        brandExist={brandExist}
        onCancel={onCancel}
        onDelete={onDelete}
      />

      <ImageModal
        loading={false}
        imageData={imageModalData}
        visible={showImageModal}
        configurationId={propsBrandData.ApplicationConfigurationId as number}
        onCancel={handleModalCancel}
        onSubmit={handleImageChange}
      />
    </Form>
  );
};
