import {
  AssetStore,
  IAssetCollectionSearchFilterModel,
  ICommonAppState,
} from "@bms/common-services";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
  IPlayListComponentPropertiesDispatchProps,
  IPlayListComponentPropertiesOwnProps,
  IPlayListComponentPropertiesStateProps,
  PlayListComponentProperties as PlayListComponentPropertiesDefinition,
} from "./PlayListComponentProperties";

const mapStateToProps = (
  state: ICommonAppState
): IPlayListComponentPropertiesStateProps => {
  return {
    isProcessingData: state.applicationConfiguration.isProcessingData,
    assetCollectionsBrowser: state.asset.assetCollectionsBrowser,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch
): IPlayListComponentPropertiesDispatchProps => ({
  browseAssetCollection: (filter: IAssetCollectionSearchFilterModel) => {
    return dispatch(AssetStore.Actions.browseAssetCollection(filter));
  },
});

export const PlayListComponentProperties = connect<
  IPlayListComponentPropertiesStateProps,
  IPlayListComponentPropertiesDispatchProps,
  IPlayListComponentPropertiesOwnProps,
  ICommonAppState
>(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(PlayListComponentPropertiesDefinition));
