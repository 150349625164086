import {
  AssetContentService,
  IAssetContentModel,
  IAssetContentPlayInfoModel,
  IRestrictedContentCookieModel,
} from "@bms/common-services";
import { useEffect } from "react";
import Cookies, { CookieAttributes } from "js-cookie";

const assetContentService = new AssetContentService().promisify();

export const loadPlayInfo = async (assetContent: IAssetContentModel) => {
  const isDrmProtected = Boolean(assetContent.DrmProvider);

  if (!isDrmProtected) {
    return Promise.resolve(undefined);
  }

  try {
    const response = await assetContentService.getPlayInfo(assetContent.Id!);

    if (response.ok) {
      return response.data;
    } else {
      // noinspection ExceptionCaughtLocallyJS
      throw new Error(response.error.Message as string);
    }
  } catch (error) {
    throw error;
  }
};

export const getPlayerDRMConfig = async (
  playInfo: IAssetContentPlayInfoModel
) => {
  let config: Record<string, any> = {
    drm: {
      servers: {},
    },
  };

  if (playInfo.Widevine && playInfo.Widevine.LicenseUrl) {
    config.drm.servers["com.widevine.alpha"] = playInfo.Widevine.LicenseUrl;
  }

  if (playInfo.PlayReady && playInfo.PlayReady.LicenseUrl) {
    config.drm.servers["com.microsoft.playready"] =
      playInfo.PlayReady.LicenseUrl;
  }

  if (playInfo.FairPlay && playInfo.FairPlay.LicenseUrl) {
    config.drm.servers["com.apple.fps.1_0"] = playInfo.FairPlay.LicenseUrl;

    if (playInfo.FairPlay.CertificateUrl) {
      config = {
        drm: {
          ...config.drm,
          advanced: {
            "com.apple.fps.1_0": {
              serverCertificateUri: playInfo.FairPlay.CertificateUrl,
            },
          },
        },
      };
    }
  }

  return config;
};

export const setCookies = (assetContent: IAssetContentModel) => {
  if (assetContent.Cookies) {
    assetContent.Cookies.forEach((item: IRestrictedContentCookieModel) => {
      const options: CookieAttributes = {
        path: item.Path,
        domain: item.Domain,
        secure: item.IsSecure,
        sameSite: item.SameSite,
      };

      if (item.Expires) {
        options.expires = new Date(item.Expires);
      }

      Cookies.set(item.Name, item.Value || "", options);
    });
  }
};
