import { HubConnectionState } from "@microsoft/signalr";
import { MessageModel } from "../models";
import { SignalRType } from "../enums";
import { ChatActions } from "../store/chat";
import SignalRClient from "./SignalRClient";
import { dispatch } from "../store/actions";

export class ChatClient extends SignalRClient {
  public static instance: ChatClient;

  public static getInstance() {
    if (ChatClient.instance) {
      return ChatClient.instance;
    }

    ChatClient.instance = new ChatClient();

    return ChatClient.instance;
  }

  constructor() {
    super({
      url: "/chat/hub",
    });
  }

  public handleEvents = () => {
    this.connection?.on("receiveMessage", (data: MessageModel) => {
      dispatch && dispatch(ChatActions.receiveMessage(data.RoomId, data));
    });

    this.connection?.on("userConnected", (id: string) => {
      dispatch && dispatch(ChatActions.userConnected(id));
    });

    this.connection?.on("userDisconnected", (id: string) => {
      dispatch && dispatch(ChatActions.userDisconnected(id));
    });
  };

  public sendChatMessage = async (data: any) => {
    if (this.checkState(HubConnectionState.Connected)) {
      this.onSignalRFetch();

      try {
        await this.connection?.invoke("Send", data).then(this.onSignalRSuccess);
      } catch (error) {
        this.onSignalRError(SignalRType.SendMessage, error);
      }
    }
  };
}
