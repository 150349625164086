import {
  ITestApplicationModel,
  TestApplicationsService,
  useServiceCaller,
} from "@bms/common-services";
import { useTranslation } from "react-i18next";
import { useTableDataProvider } from "../../../../helpers";
import { useAppFeedback } from "@bms/common-ui";

const testApplicationsService = new TestApplicationsService().promisify();

export const useTestApplications = () => {
  const { t } = useTranslation();
  const { notification } = useAppFeedback();

  const {
    dataLoader,
    filters,
    pagination,
    fullTextSearch,
    setFullTextSearch,
  } = useTableDataProvider({
    filtersSchema: {
      FullTextSearch: "string",
      Name: "string",
      Description: "string",
      Platforms: "strings",
      UpToDate: "boolean",
    },
    loader: (filters, pagination) =>
      testApplicationsService.search({
        ...filters,
        ...pagination,
      }),
    deps: [],
    onError: (error) =>
      notification.error({
        message: t("LOADING_DATA_ERROR_MESSAGE"),
        description: error.Message,
      }),
  });

  const [
    createApplication,
    { processing: createProcessing },
  ] = useServiceCaller(async (application: ITestApplicationModel) => {
    const result = await testApplicationsService.insert(application);

    if (result.ok) {
      dataLoader.refresh();
      notification.success({
        message: t("TESTING_APPLICATIONS_CREATE_SUCCESS"),
      });
      return result.data;
    } else {
      notification.error({
        message: t("TESTING_APPLICATIONS_CREATE_FAILURE"),
        description: result.error?.Message,
      });
    }
    return undefined;
  }, []);

  const [
    deleteApplication,
    { processing: deleteProcessing },
  ] = useServiceCaller(async (application: ITestApplicationModel) => {
    const result = await testApplicationsService.delete(application);

    if (result.ok) {
      dataLoader.refresh();
      notification.success({
        message: t("TESTING_APPLICATIONS_DELETE_SUCCESS"),
      });
    } else {
      notification.error({
        message: t("TESTING_APPLICATIONS_DELETE_ERROR"),
        description: result.error?.Message,
      });
    }
    return result.ok;
  }, []);

  const isLoading = dataLoader.loading || deleteProcessing || createProcessing;

  return {
    isLoading,
    data: dataLoader.data,
    refreshApplications: dataLoader.refresh,
    createApplication,
    deleteApplication,
    filters,
    pagination,
    fullTextSearch,
    setFullTextSearch,
  };
};
