import { FormProps } from "antd/lib/form";
import { useCallback, useState } from "react";

type OnFieldsChangeType = FormProps["onFieldsChange"] extends
  | infer U
  | undefined
  ? U
  : any;

export const useSendable = () => {
  const [sendable, setSendable] = useState(false);

  const onFieldsChange = useCallback<OnFieldsChangeType>(
    (_, allFields) => {
      setSendable(
        allFields.some((field) => field.touched) &&
          allFields.every((field) => (field.errors || []).length === 0)
      );
    },
    [setSendable]
  );

  const resetSendable = useCallback(() => {
    setSendable(false);
  }, [setSendable]);

  const setDirty = useCallback(() => {
    setSendable(true);
  }, [setSendable]);

  return {
    onFieldsChange,
    sendable,
    resetSendable,
    setDirty,
  };
};
