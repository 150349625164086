import { AlignType } from "../enums";

export class AlignTypeHelper {
  static getDescription(value: AlignType): string {
    switch (value) {
      case "LEFT":
        return "Left";
      case "CENTER":
        return "Center";
      case "RIGHT":
        return "Right";
      default:
        return "Undefined";
    }
  }

  static getValue(value: string | undefined): AlignType | undefined {
    switch (value) {
      case "LEFT":
        return AlignType.Left;
      case "CENTER":
        return AlignType.Center;
      case "RIGHT":
        return AlignType.Right;
      default:
        return undefined;
    }
  }

  static getStringValue(value: AlignType): string {
    switch (value) {
      case AlignType.Left:
        return "LEFT";
      case AlignType.Center:
        return "CENTER";
      case AlignType.Right:
        return "RIGHT";
    }
  }
}
