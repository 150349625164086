import { Observable, Observer } from "rxjs";
import { SimpleServiceBase } from "../Base";
import {
  IAssetInAssetModel,
  IAssetInAssetSearchFilterModel,
} from "../../models/Asset";
import { IAssetInAssetListModel } from "../../models/Asset/IAssetInAssetListModel";
import { HTTP_METHOD } from "../../constants/http";
import { AxiosSubscriber } from "../AxiosSubscriber";

export class AssetInAssetService extends SimpleServiceBase<
  IAssetInAssetModel,
  IAssetInAssetListModel,
  number,
  IAssetInAssetSearchFilterModel
> {
  get url(): string {
    return "/AssetsInAssets";
  }

  public insertCollection = (
    data: IAssetInAssetModel[]
  ): Observable<IAssetInAssetModel[]> =>
    new Observable(
      (observer: Observer<IAssetInAssetModel[]>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: `${this.url}/InsertCollection`,
        })
    );

  public saveCollection = (
    data: IAssetInAssetModel[]
  ): Observable<IAssetInAssetModel[]> =>
    new Observable(
      (observer: Observer<IAssetInAssetModel[]>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.PUT,
          url: `${this.url}/SaveCollection`,
        })
    );
}
