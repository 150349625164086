import { useState } from "react";
import { useDataLoader, AssetService } from "@bms/common-services";
import { useAppFeedback } from "@bms/common-ui";

const assetService = new AssetService().promisify();

export const usePaymentAssetsDataProvider = () => {
  const { notification } = useAppFeedback();

  const [textSearch, setTextSearch] = useState("");

  const { data, loading } = useDataLoader({
    loader: () =>
      assetService.search({
        PageNumber: 1,
        PageSize: 100,
        IncludeCount: false,
        Title: textSearch,
      }),
    onError: (error) =>
      notification.error({
        message: error.Title,
        description: error.Message,
      }),
    debounce: 500,
    deps: [textSearch],
  });
  return {
    assets:
      data?.Entities.map((ent) => ({
        key: ent.Id,
        value: ent.Id,
        label: ent.Title,
      })) || [],
    loading,
    setTextSearch,
  };
};
