import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  CancelTokenSource,
} from "axios";
import { ITokenModel } from "../models";
import { StorageManager } from "./StorageManager";

const CancelToken = axios.CancelToken;

export class APIInterceptor {
  public api: AxiosInstance;
  public baseURL: string;
  public source: CancelTokenSource;

  constructor(baseURL?: string) {
    this.baseURL = baseURL || `${process.env.REACT_APP_API_URL}`;
    this.source = CancelToken.source();

    const settings = {
      baseURL: this.baseURL,
    };

    this.api = axios.create(settings);
    this.api.interceptors.request.use(
      (config: AxiosRequestConfig) => {
        const session: ITokenModel = StorageManager.getValue("session");

        if(!config.headers) {
          config.headers = {};
        }
        
        if (
          session &&
          session.Token &&
          !config.headers.hasOwnProperty("Authorization")
        ) {
          config.headers.Authorization = `Bearer ${session.Token}`;
        }

        if (
          config.headers.hasOwnProperty("Authorization") &&
          !config.headers.Authorization
        ) {
          delete config.headers.Authorization;
        }

        if (!config.headers["Content-Type"]) {
          config.headers["Content-Type"] = "application/json; charset=UTF-8";
        }

        return config;
      },
      (error) => {
        // Do something with request error
        return Promise.reject(error);
      }
    );
  }

  public async get(url: string, config?: AxiosRequestConfig) {
    const cfg = this.mergeConfig(config);
    return this.api.get(url, cfg);
  }

  public async post(url: string, data: any, config?: AxiosRequestConfig) {
    return this.api.post(url, data, this.mergeConfig(config));
  }

  public async put(url: string, data: any, config?: AxiosRequestConfig) {
    return this.api.put(url, data, this.mergeConfig(config));
  }

  public async patch(url: string, data: any, config?: AxiosRequestConfig) {
    return this.api.patch(url, data, this.mergeConfig(config));
  }

  public async delete(url: string, config?: AxiosRequestConfig) {
    return this.api.delete(url, this.mergeConfig(config));
  }

  public async head(url: string, config?: AxiosRequestConfig) {
    return this.api.head(url, this.mergeConfig(config));
  }

  public async cancel() {
    return this.source.cancel();
  }

  private mergeConfig(config: any = {}) {
    return {
      ...config,
      cancelToken: this.source.token,
      headers: {
        ...config.headers,
      },
    };
  }
}
