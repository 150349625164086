import { DataProvider } from "@bms/common-services";
import { BrowserHistoryBuildOptions, createBrowserHistory } from "history";
import React from "react";
import { Provider } from "react-redux";
import { createLogger } from "redux-logger";
import { createRoot } from "react-dom/client";
import App from "./modules";
import { ReduxStoreConfigurator } from "./store";

declare module "react-redux" {
  interface ProviderProps {
    children?: React.ReactNode;
  }
}

// Init HttpFactory
DataProvider.initHttpFactory();

const browserHistoryOptions: BrowserHistoryBuildOptions = {};

if (process.env.PUBLIC_URL) {
  browserHistoryOptions.basename = process.env.PUBLIC_URL;
}

const appHistory = createBrowserHistory(browserHistoryOptions);
const middlewares = [];

if (process.env.NODE_ENV === "development") {
  middlewares.push(createLogger({ diff: true, collapsed: true }));
}
const reduxStoreConfigurator = new ReduxStoreConfigurator(
  appHistory,
  middlewares
);
const appStore = reduxStoreConfigurator.initStore();

class InitApp extends React.Component {
  public render() {
    return (
      <Provider store={appStore}>
        <App history={appHistory} />
      </Provider>
    );
  }
}

window.onload = () => {
  const rootElement = document.getElementById("root");
  if (rootElement) {
    const root = createRoot(rootElement);

    root.render(<InitApp />);
  } else {
    throw Error("Did not find #root element in DOM!");
  }
};
