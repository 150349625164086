import { DatePicker as AntDatePicker } from "antd";
import {
  PickerBaseProps,
  PickerDateProps,
  PickerTimeProps,
} from "antd/lib/date-picker/generatePicker";
import dayjs from "dayjs";
import React from "react";

declare type DatePickerProps =
  | Omit<PickerBaseProps<dayjs.Dayjs>, "defaultValue" | "value">
  | Omit<PickerDateProps<dayjs.Dayjs>, "defaultValue" | "value">
  | Omit<PickerTimeProps<dayjs.Dayjs>, "defaultValue" | "value">;

export declare type IDatePickerProps = DatePickerProps & {
  defaultValue?: Date | string | undefined;
  value?: Date | string | undefined;
};

export const DatePicker: React.FC<IDatePickerProps> = (props) => {
  const { defaultValue, value, ...restProps } = props;
  return (
    <AntDatePicker
      defaultValue={defaultValue ? dayjs(defaultValue) : undefined}
      value={value ? dayjs(value) : undefined}
      {...restProps}
    />
  );
};
