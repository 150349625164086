import { ITestApplicationModel } from "@bms/common-services";
import {
  Choose,
  ChooseOption,
  Col,
  Form,
  IFormValues,
  Input,
  required,
  Row,
  Spin,
  Switch,
} from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { useTestingPlatforms } from "../../../../hooks";
import React from "react";
import { ROUTES } from "../../../../constants";
import { useHistory } from "react-router";

interface TestingApplicationFormProps {
  isLoading: boolean;
  setVisibilityStatus: (visible: boolean) => void;
  createApplication: (
    data: ITestApplicationModel
  ) => Promise<ITestApplicationModel | undefined>;
}

const defaultApplication: ITestApplicationModel = {
  Id: -1,
  UpToDate: true,
};

export const TestingApplicationForm = (props: TestingApplicationFormProps) => {
  const { isLoading, setVisibilityStatus, createApplication } = props;

  const history = useHistory();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { platforms } = useTestingPlatforms();

  const onFinish = async (values: IFormValues) => {
    const data: ITestApplicationModel = {
      ...defaultApplication,
      ...values,
    };

    const result = await createApplication(data);
    setVisibilityStatus(false);

    if (result) {
      history.push(`${ROUTES.APPLICATION_DETAILS}/${result.Id}`);
    }
  };

  const renderNameInput = () => {
    return (
      <Form.Item name="Name" label={t("MODEL_NAME")} rules={[required()]}>
        <Input placeholder={t("MODEL_NAME")} />
      </Form.Item>
    );
  };

  const renderPlatformField = () => {
    return (
      <Form.Item
        key="PlatformCode"
        name="PlatformCode"
        label={t("MODEL_PLATFORM")}
        rules={[required()]}
      >
        <Choose placeholder={t("MODEL_PLATFORM")} loading={!platforms.length}>
          {platforms.map((platform) => {
            return (
              <ChooseOption key={platform.Code} value={platform.Code}>
                {platform.DisplayName}
              </ChooseOption>
            );
          })}
        </Choose>
      </Form.Item>
    );
  };

  const renderBundleIdInput = () => {
    return (
      <Form.Item
        name="BundleID"
        label={t("TESTING_APPLICATIONS_BUNDLE_ID")}
        rules={[required()]}
      >
        <Input placeholder={t("TESTING_APPLICATIONS_BUNDLE_ID")} />
      </Form.Item>
    );
  };

  const renderMinOsVersionInput = () => {
    return (
      <Form.Item
        name="MinOSVersion"
        label={t("TESTING_APPLICATIONS_MIN_OS_VERSION")}
        rules={[required()]}
      >
        <Input placeholder={t("TESTING_APPLICATIONS_MIN_OS_VERSION")} />
      </Form.Item>
    );
  };

  const renderUpToDate = () => {
    return (
      <Form.Item>
        <Row gutter={8}>
          <Col>
            <Form.Item name="UpToDate" valuePropName="checked">
              <Switch />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="UpToDate">
              <label>{t("MODEL_UP_TO_DATE")}</label>
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>
    );
  };

  return (
    <Spin spinning={isLoading}>
      <Form
        name="TestingApplicationForm"
        form={form}
        onFinish={onFinish}
        initialValues={{
          ...defaultApplication,
        }}
        layout="vertical"
      >
        <Row direction="column" justify="space-between" className="full-height">
          <Col>
            {renderNameInput()}
            {renderPlatformField()}
            {renderBundleIdInput()}
            {renderMinOsVersionInput()}
            {renderUpToDate()}
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};
