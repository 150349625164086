import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  Form,
  IFormValues,
  Modal,
  Result,
  Row,
  Spin,
} from "@bms/common-ui";
import {
  AnalyticsService,
  IAnalyticsAssetImpressionsExportRequestModel,
  IAnalyticsAssetImpressionsFilterModel,
  IErrorModel,
} from "@bms/common-services";
import { useTranslation } from "react-i18next";

import "./AssetImpressionsExportModal.scss";

const analyticsService = new AnalyticsService();

export interface IAssetImpressionsExportModalProps {
  visible: boolean;
  filter: IAnalyticsAssetImpressionsFilterModel;
  onCancel?: () => void;
  onSuccess?: () => void;
}

export const AssetImpressionsExportModal: React.FC<IAssetImpressionsExportModalProps> = ({
  visible,
  filter,
  onCancel,
  onSuccess,
}: IAssetImpressionsExportModalProps) => {
  const { t } = useTranslation();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [result, setResult] = useState<Blob>();
  const [error, setError] = useState<IErrorModel>();
  const isFinished = !isProcessing && (result || error) ? true : false;

  const [form] = Form.useForm();

  const reset = () => {
    form?.resetFields([
      "IncludeAggregationByUser",
      "IncludeAggregationByAsset",
      "IncludeAggregationByPlatform",
      "IncludeAggregationByCreator",
    ]);
    setResult(undefined);
    setError(undefined);
  };

  const onFinish = async (values: IFormValues) => {
    setIsProcessing(true);

    try {
      const options: IAnalyticsAssetImpressionsExportRequestModel = {
        ...filter,
        ...values,
      };
      const result = await analyticsService
        .exportAssetImpressions(options)
        .toPromise();
      setResult(result);
      setIsProcessing(false);
    } catch (error) {
      setError(error as IErrorModel);
      setIsProcessing(false);
    }
  };

  const onDownloadResultsClick = () => {
    if (!result) {
      return;
    }

    const downloadAnchor = document.createElement("a");
    const urlObject = URL.createObjectURL(
      new Blob([result], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
    );
    downloadAnchor.href = urlObject;
    downloadAnchor.download = `media_impressions_report__${new Date().getTime()}.xlsx`;
    document.body.appendChild(downloadAnchor);
    downloadAnchor.click();
    onSuccess?.();
    reset();

    setTimeout(() => {
      document.body.removeChild(downloadAnchor);
      if (urlObject) {
        URL.revokeObjectURL(urlObject);
      }
    }, 1000);
  };

  const renderOptions = () => {
    if (isFinished) {
      return null;
    }

    if (isProcessing) {
      return (
        <div className="AssetImpressionsExportForm_SpinerContainer">
          <Spin tip={t("ASSET_IMPRESSIONS_EXPORT_MODAL_PROGRESS_MESSAGE")} />
        </div>
      );
    }

    return (
      <>
        <Row gutter={[16, 16]}>
          <Col>
            <h1>{t("ASSET_IMPRESSIONS_EXPORT_MODAL_OPTIONS_LABEL")}:</h1>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col>
            <Form.Item
              label={t(
                "ASSET_IMPRESSIONS_EXPORT_MODAL_INCLUDE_AGGREGATION_LABEL"
              )}
              labelCol={{
                xs: { span: 24 },
                sm: { span: 8 },
                md: { span: 8 },
                lg: { span: 8 },
              }}
              wrapperCol={{
                xs: { span: 24 },
                sm: { span: 16 },
                md: { span: 16 },
                lg: { span: 16 },
              }}
            >
              <Row>
                <Col span={12}>
                  <Form.Item
                    name="IncludeAggregationByUser"
                    valuePropName="checked"
                  >
                    <Checkbox>
                      {t(
                        "ASSET_IMPRESSIONS_EXPORT_MODAL_INCLUDE_AGGREGATION_BY_USER_LABEL"
                      )}
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="IncludeAggregationByCreator"
                    valuePropName="checked"
                  >
                    <Checkbox>
                      {t(
                        "ASSET_IMPRESSIONS_EXPORT_MODAL_INCLUDE_AGGREGATION_BY_CREATOR_LABEL"
                      )}
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="IncludeAggregationByAsset"
                    valuePropName="checked"
                  >
                    <Checkbox>
                      {t(
                        "ASSET_IMPRESSIONS_EXPORT_MODAL_INCLUDE_AGGREGATION_BY_ASSET_LABEL"
                      )}
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="IncludeAggregationByPlatform"
                    valuePropName="checked"
                  >
                    <Checkbox>
                      {t(
                        "ASSET_IMPRESSIONS_EXPORT_MODAL_INCLUDE_AGGREGATION_BY_PLATFORM_LABEL"
                      )}
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          </Col>
        </Row>
      </>
    );
  };

  const renderResult = () => {
    if (error) {
      return (
        <Form.Item>
          <Result
            status="error"
            title={t("ASSET_IMPRESSIONS_EXPORT_MODAL_ERROR_TITLE")}
            subTitle={error?.Message}
            extra={[
              <Button key="close" onClick={onCancelModal}>
                {t("BUTTON_CLOSE")}
              </Button>,
            ]}
          />
        </Form.Item>
      );
    }

    if (result) {
      return (
        <Form.Item>
          <Result
            status="success"
            title={t("ASSET_IMPRESSIONS_EXPORT_MODAL_SUCCESS_TITLE")}
            subTitle={t("ASSET_IMPRESSIONS_EXPORT_MODAL_SUCCESS_MESSAGE")}
            extra={[
              <Button key="close" onClick={onCancelModal}>
                {t("BUTTON_CLOSE")}
              </Button>,
              <Button type="primary" onClick={onDownloadResultsClick}>
                {t("BUTTON_DOWNLOAD")}
              </Button>,
            ]}
          />
        </Form.Item>
      );
    }

    return null;
  };

  const onCancelModal = () => {
    onCancel?.();
    reset();
  };

  const renderFooter = () => {
    if (isFinished) {
      return null;
    }

    return (
      <>
        <Button
          key="cancel"
          onClick={onCancelModal}
          type="link"
          disabled={isProcessing}
        >
          {t("BUTTON_CANCEL")}
        </Button>
        <Button
          form="AssetImpressionsExportForm"
          key="submit"
          htmlType="submit"
          type="primary"
          loading={isProcessing}
          disabled={isProcessing}
        >
          {t("BUTTON_EXPORT")}
        </Button>
      </>
    );
  };

  return (
    <Modal
      title={t("ASSET_IMPRESSIONS_EXPORT_MODAL_TITLE")}
      footer={renderFooter()}
      processing={isProcessing}
      width={650}
      visible={visible}
      destroyOnClose={true}
      maskClosable={false}
      onCancel={onCancelModal}
    >
      <Form
        form={form}
        name="AssetImpressionsExportForm"
        className="AssetImpressionsExportForm"
        onFinish={onFinish}
      >
        {renderOptions()}
        {renderResult()}
      </Form>
    </Modal>
  );
};
