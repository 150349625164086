import {
  IAnalyticsUserPlatformRatingFilterModel,
  IUserPlatformRatingAnalyticsSummaryFilterModel,
  UserPlatformRatingsService,
  useDataLoader,
} from "@bms/common-services";
import { useTableDataProvider } from "../../../helpers";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppFeedback } from "@bms/common-ui";

const userPlatformRatingsService = new UserPlatformRatingsService().promisify();

export const useUserPlatformRatingDataProvider = () => {
  const [filter, __setFilter] = useState<
    IUserPlatformRatingAnalyticsSummaryFilterModel
  >({});

  const { notification } = useAppFeedback();

  const {
    debounceAwaiting,
    refresh: refreshData,
    loading,
    data,
  } = useDataLoader({
    loader: () => userPlatformRatingsService.selectAnalyticsSummary(filter),
    onError: (error) =>
      notification.error({
        message: error.Title,
        description: error.Message,
      }),
    deps: [filter],
  });

  const setFilter = <T>(
    property: keyof IUserPlatformRatingAnalyticsSummaryFilterModel,
    value: T
  ) => __setFilter({ ...filter, [property]: value });

  const resetFilter = () => __setFilter({});

  return {
    data,
    filter,
    loading,
    debounceAwaiting,
    refreshData,
    setFilter,
    resetFilter,
  };
};

export const useUserPlatformRatingDataAnalyticsProvider = () => {
  const [filter, __setFilter] = useState<
    IAnalyticsUserPlatformRatingFilterModel
  >({});

  const { notification } = useAppFeedback();

  const {
    debounceAwaiting,
    refresh: refreshData,
    loading,
    data,
  } = useDataLoader({
    loader: () => userPlatformRatingsService.selectAnalytics(filter),
    onError: (error) =>
      notification.error({
        message: error.Title,
        description: error.Message,
      }),
    deps: [filter],
  });

  const setFilter = <T>(
    property: keyof IAnalyticsUserPlatformRatingFilterModel,
    value: T
  ) => __setFilter({ ...filter, [property]: value });

  const resetFilter = () => __setFilter({});

  return {
    data,
    filter,
    loading,
    debounceAwaiting,
    refreshData,
    setFilter,
    resetFilter,
  };
};

export const useSearchUserPlatformRatings = () => {
  const { t } = useTranslation();
  const { notification } = useAppFeedback();

  const {
    dataLoader: ratingSearchLoader,
    filters,
    pagination,
    fullTextSearch,
    setFullTextSearch,
  } = useTableDataProvider({
    filtersSchema: {
      FullTextSearch: "string",
      Categories: "numbers",
      Ratings: "numbers",
      Platforms: "strings",
    },
    loader: (filters, pagination) =>
      userPlatformRatingsService.search({
        ...filters,
        ...pagination,
        IncludeCategories: true,
      }),
    deps: [],
    onError: (error) =>
      notification.error({
        message: t(
          "LOADING_DATA_ERROR_MESSAGE",
          "There was an error while loading data."
        ),
        description: error.Message,
      }),
  });
  const isLoading = ratingSearchLoader.loading;

  const { data } = ratingSearchLoader;

  return {
    ratingData: data,
    ratingSearchLoader,
    isLoading,
    filters,
    pagination,
    fullTextSearch,
    setFullTextSearch,
  };
};
