import { Observable, Observer } from "rxjs";
import { HTTP_METHOD } from "../../constants";
import {
  IApplicationConfigurationModel,
  IApplicationsConfigurationsListModel,
  IApplicationsConfigurationsSearchFilterModel,
  UploadFileInfoModel,
} from "../../models";
import { ApplicationResourceType } from "../../enums";
import { AxiosSubscriber } from "../AxiosSubscriber";
import { SimpleServiceBase } from "../Base";

export class ApplicationConfigurationService extends SimpleServiceBase<
  IApplicationConfigurationModel,
  IApplicationsConfigurationsListModel,
  number,
  IApplicationsConfigurationsSearchFilterModel
> {
  get url(): string {
    return "/ApplicationsConfigurations";
  }

  public publish = (
    id: number,
    rowVersion: string
  ): Observable<IApplicationConfigurationModel> =>
    new Observable(
      (observer: Observer<IApplicationConfigurationModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            headers: {
              "Content-Type": "application/json; charset=UTF-8",
            },
            params: {
              id,
              rowVersion,
            },
          },
          data: null,
          method: HTTP_METHOD.PUT,
          url: `${this.url}/Publish`,
        })
    );

  public getImageUploadFileInfo = (
    id: number
  ): Observable<UploadFileInfoModel> =>
    new Observable(
      (observer: Observer<UploadFileInfoModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: { id },
          },
          method: HTTP_METHOD.GET,
          url: `${this.url}/GetImageUploadFileInfo`,
        })
    );

  public getResourceUploadFileInfo = (
    id: number,
    resourceType: ApplicationResourceType,
    resourceFileName?: string
  ): Observable<UploadFileInfoModel> =>
    new Observable(
      (observer: Observer<UploadFileInfoModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: { id, resourceType, resourceFileName },
          },
          method: HTTP_METHOD.GET,
          url: `${this.url}/GetResourceUploadFileInfo`,
        })
    );

  public exportTranslations = (): Observable<Blob> =>
    new Observable(
      (observer: Observer<Blob>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            responseType: "blob",
          },
          method: HTTP_METHOD.GET,
          url: `${this.url}/ExportResources`,
        })
    );

  public importTranslations = (file: File): Observable<any> => {
    const formData = new FormData();

    // set correct content type to file because when is empty then will be send as application/octet-stream
    formData.append(
      "file",
      file.slice(
        0,
        file.size,
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      )
    );

    return new Observable(
      (observer: Observer<Blob>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          },
          data: formData,
          method: HTTP_METHOD.PUT,
          url: `${this.url}/ImportResources`,
        })
    );
  };
}
