import {
  IApplicationComponentModel,
  IAdvertisementBoardModel,
} from "@bms/common-services";
import React from "react";

export interface IAdvertisementBoardContext {
  advertisementBoard?: IAdvertisementBoardModel;
  component?: IApplicationComponentModel;
  onAdvertisementBoardChange?: (advertisementBoard: IAdvertisementBoardModel) => void;
  onComponentAdd?: (component: IApplicationComponentModel) => void;
  onComponentSelect?: (component?: IApplicationComponentModel) => void;
  onComponentChange?: (component: IApplicationComponentModel) => void;
  onComponentsChange?: (components: IApplicationComponentModel[]) => void;
  onComponentDelete?: (componentId: number) => void;
}

export const AdvertisementBoardContext = React.createContext<
  IAdvertisementBoardContext
>({});
export const AdvertisementBoardProvider = AdvertisementBoardContext.Provider;
export const AdvertisementBoardConsumer = AdvertisementBoardContext.Consumer;

export function useAdvertisementBoardContext() {
  return React.useContext<IAdvertisementBoardContext>(AdvertisementBoardContext);
}

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export interface WithAdvertisementBoardContextProps {
  advertisementBoard?: IAdvertisementBoardModel;
  component?: IApplicationComponentModel;
  onAdvertisementBoardChange?: (advertisementBoard: IAdvertisementBoardModel) => void;
  onComponentAdd?: (component: IApplicationComponentModel) => void;
  onComponentSelect?: (component?: IApplicationComponentModel) => void;
  onComponentChange?: (component: IApplicationComponentModel) => void;
  onComponentsChange?: (components: IApplicationComponentModel[]) => void;
  onComponentDelete?: (componentId: number) => void;
}

export function withAdvertisementBoardContext<P extends WithAdvertisementBoardContextProps>(
  WrappedComponent: React.ComponentType<P>
) {
  type Props = JSX.LibraryManagedAttributes<P, Omit<P, "advertisementBoard">>;

  return class WithAdvertisementBoardContext extends React.Component<Props> {
    static displayName = `WithAdvertisementBoardContext(${WrappedComponent.displayName})`;

    render() {
      return (
        <AdvertisementBoardConsumer>
          {(context: IAdvertisementBoardContext) => (
            <WrappedComponent
              {...(this.props as any)}
              advertisementBoard={context.advertisementBoard}
              component={context.component}
              onAdvertisementBoardChange={context.onAdvertisementBoardChange}
              onComponentAdd={context.onComponentAdd}
              onComponentSelect={context.onComponentSelect}
              onComponentChange={context.onComponentChange}
              onComponentsChange={context.onComponentsChange}
              onComponentDelete={context.onComponentDelete}
            />
          )}
        </AdvertisementBoardConsumer>
      );
    }
  };
}
