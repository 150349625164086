import { IConfigModel } from "../../models/Config";
import { IErrorModel } from "../../models/ErrorModel";
import {
  ConfigurationActionsTypes,
  GET_CONFIGURATION,
  GET_CONFIGURATION_FAILURE,
  GET_CONFIGURATION_SUCCESS,
} from "./types";

export const getConfig = (): ConfigurationActionsTypes => {
  return {
    type: GET_CONFIGURATION,
  };
};

export const getConfigSuccess = (
  data: IConfigModel
): ConfigurationActionsTypes => {
  return {
    payload: data,
    type: GET_CONFIGURATION_SUCCESS,
  };
};

export const getConfigFailure = (
  error?: IErrorModel
): ConfigurationActionsTypes => {
  return {
    error,
    type: GET_CONFIGURATION_FAILURE,
  };
};

export const Actions = {
  getConfig,
};
