import { ItemsAlignmentType } from "../enums";

export class ItemsAlignmentTypeHelper {
  static getDescription(value: ItemsAlignmentType): string {
    switch (value) {
      case "JUSTIFY_CENTER":
        return "APPLICATION_CONFIGURATION_PROPERTIES__JUSTIFY_CENTER";
      case "FIT_TO_CONTENT":
        return "APPLICATION_CONFIGURATION_PROPERTIES__FIT_TO_CONTENT";
      default:
        return "Undefined";
    }
  }

  static getValue(value?: string): ItemsAlignmentType {
    switch (value) {
      case "JUSTIFY_CENTER":
        return ItemsAlignmentType.JustifyCenter;
      case "FIT_TO_CONTENT":
        return ItemsAlignmentType.FitToContent;
      default:
        return ItemsAlignmentType.FitToContent;
    }
  }

  static getStringValue(value?: ItemsAlignmentType): string {
    switch (value) {
      case ItemsAlignmentType.JustifyCenter:
        return "JUSTIFY_CENTER";
      case ItemsAlignmentType.FitToContent:
        return "FIT_TO_CONTENT";
      default:
        return "FIT_TO_CONTENT";
    }
  }
}
