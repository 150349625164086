import {
  IErrorModel,
  ILockUserModel,
  TimeHelper,
  UserService,
  useServiceCaller,
} from "@bms/common-services";
import {
  Alert,
  Button,
  DatePicker,
  Form,
  IDatePickerMoment,
  IFormValues,
  Modal,
  Spin,
  useAppFeedback,
} from "@bms/common-ui";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import "./UserLockModal.scss";

const userService = new UserService();

export interface IUserLockModalProps {
  userId: number;
  visible: boolean;
  onCancel?: () => void;
  onSuccess?: () => void;
}

export const UserLockModal: React.FC<IUserLockModalProps> = ({
  visible,
  userId,
  onCancel,
  onSuccess,
}) => {
  const [t] = useTranslation();
  const [form] = Form.useForm();
  const { notification } = useAppFeedback();

  const [error, setError] = useState<IErrorModel>();
  const defaultLockoutEnd = TimeHelper.getDateWithOffset(
    TimeHelper.getCurrentDateTime(),
    1,
    "year"
  );

  const [lockUser, lockUserState] = useServiceCaller(
    async (data: ILockUserModel) => {
      try {
        await userService.lockUser(data).toPromise();

        notification.success({
          message: t("USER_LOCK_MODAL_SUCCESS"),
        });

        form?.resetFields(["LockoutEnd"]);
        onSuccess?.();
      } catch (err) {
        const error = err as IErrorModel;
        setError(error);
        notification.error({
          message: t("USER_LOCK_MODAL_FAILURE"),
          description: error?.Message,
        });
      }
    },
    [userId]
  );

  const onFinish = useCallback(
    (values: IFormValues) => {
      const payload: ILockUserModel = {
        Id: userId,
        LockoutEnd: values.LockoutEnd
          ? values.LockoutEnd.toISOString()
          : TimeHelper.toString(defaultLockoutEnd),
      };

      setError(undefined);
      lockUser(payload);
    },
    [userId]
  );

  const onClose = () => {
    form?.resetFields(["LockoutEnd"]);
    onCancel?.();
  };

  const renderError = () => {
    if (error) {
      return (
        <Form.Item>
          <Alert
            message={t("USER_LOCK_MODAL_FAILURE")}
            description={error.Message}
            type="error"
            closable
            showIcon
          />
        </Form.Item>
      );
    }

    return null;
  };

  return (
    <Modal
      title={t("USER_LOCK_MODAL_TITLE")}
      className="UserLockModal"
      visible={visible}
      processing={lockUserState.processing}
      onCancel={onClose}
      footer={
        <div>
          <Button
            type="link"
            onClick={onClose}
            disabled={lockUserState.processing}
          >
            {t("BUTTON_CANCEL")}
          </Button>
          <Button
            type="primary"
            onClick={form.submit}
            loading={lockUserState.processing}
            disabled={lockUserState.processing}
          >
            {t("BUTTON_LOCK")}
          </Button>
        </div>
      }
    >
      <Spin
        spinning={lockUserState.processing}
        wrapperClassName="UserLockModal_Spin"
      >
        <Form
          form={form}
          name="UserLockForm"
          className="UserLockForm"
          onFinish={onFinish}
          style={{ textAlign: "left" }}
          labelCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
            lg: { span: 24 },
          }}
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
            lg: { span: 24 },
          }}
        >
          <Form.Item name="Information" labelAlign="left">
            <label>{t("USER_LOCK_MODAL_INFO")}</label>
          </Form.Item>
          {renderError()}
          <Form.Item
            name="LockoutEnd"
            label={t("MODEL_LOCKOUT_END")}
            labelAlign="left"
            initialValue={defaultLockoutEnd}
          >
            <DatePicker
              showToday
              showTime
              style={{ width: `100%` }}
              disabledDate={(date: IDatePickerMoment) => {
                return date && date.isBefore(TimeHelper.getCurrentDateTime());
              }}
            />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};
