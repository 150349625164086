import { Observable, Observer } from "rxjs";
import {
  IModelBase,
  IListModelBase,
  IDictionarySearchFilterModelBase,
} from "../../models";
import { AxiosSubscriber } from "../AxiosSubscriber";
import { HTTP_METHOD } from "../../constants/http";
import { map } from "rxjs/operators";
import { SimpleServiceBase } from "./SimpleServiceBase";

export abstract class DictionaryServiceBase<
  TEntity extends IModelBase,
  TKey,
  TListEntity extends IListModelBase<TEntity, TSearchFilter> = {
    Entities: [];
    TotalCount: 0;
  },
  TSearchFilter extends IDictionarySearchFilterModelBase = {}
> extends SimpleServiceBase<TEntity, TListEntity, TKey, TSearchFilter> {
  public select = (params = {}): Observable<TListEntity> =>
    new Observable(
      (observer: Observer<TEntity[]>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params,
          },
          method: HTTP_METHOD.GET,
          url: `${this.url}/Select`,
        })
    ).pipe(
      map((data: TEntity[]) => {
        const entities = data || [];

        const result: TListEntity = {
          Entities: entities,
          TotalCount: entities.length,
          Filter: {
            PageSize: entities.length,
            PageNumber: 1,
            IncludeCount: true,
          },
        } as TListEntity;

        return result;
      })
    );
}
