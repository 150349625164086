import React from "react";

export function Video() {
  return <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M18.1818 0.0238037H1.81818C0.809091 0.0238037 0 0.864359 0 1.91269V13.246C0 14.2849 0.809091 15.1349 1.81818 15.1349H6.36364V17.0238H13.6364V15.1349H18.1818C19.1818 15.1349 19.9909 14.2849 19.9909 13.246L20 1.91269C20 0.864359 19.1818 0.0238037 18.1818 0.0238037ZM18.1818 13.246H1.81818V1.91269H18.1818V13.246ZM7.27273 11.3571L13.6364 7.57936L7.27273 3.80158V11.3571Z" />
  </svg>
}
