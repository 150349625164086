/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  ICommonAppState,
  IPlatformModel,
  PlatformType,
  PlatformTypeHelper,
} from "@bms/common-services";
import {
  Button,
  ITableColumnProps,
  ITableRowSelection,
  Modal,
  Table,
} from "@bms/common-ui";

interface ISelectPlatformModalProps {
  visible: boolean;
  availablePlatforms?: PlatformType[];
  selectedPlatforms?: PlatformType[];
  onCancel?: () => void;
  onSelect?: (platform: IPlatformModel) => void;
}

export const SelectPlatformModal: React.FC<ISelectPlatformModalProps> = ({
  visible,
  availablePlatforms = [],
  selectedPlatforms = [],
  onCancel,
  onSelect,
}) => {
  const { t } = useTranslation();
  const [selectedRow, setSelectedRow] = useState<IPlatformModel>();
  const systemPlatforms = useSelector(
    (state: ICommonAppState) => state.common.platforms
  );

  const platforms = useMemo(() => {
    let data: IPlatformModel[] = systemPlatforms.filter(
      (row: IPlatformModel) => row.Code !== PlatformType.Any
    );

    if (availablePlatforms.length > 0) {
      const hasPlatformAny = availablePlatforms.includes(PlatformType.Any);

      if (!hasPlatformAny) {
        data = data.filter((row) =>
          availablePlatforms.includes(PlatformTypeHelper.getValue(row.Code))
        );
      }
    }

    if (selectedPlatforms.length > 0) {
      data = data.filter(
        (row) =>
          !selectedPlatforms.includes(PlatformTypeHelper.getValue(row.Code))
      );
    }

    return data;
  }, [availablePlatforms, selectedPlatforms, systemPlatforms, visible]);

  const onCancelClick = () => {
    onCancel?.();
  };

  const onSelectClick = () => {
    if (onSelect && selectedRow) {
      onSelect(selectedRow);
    }
  };

  const onTableRow = (row: IPlatformModel, index?: number) => {
    return {
      onClick: (event: React.MouseEvent) => {
        setSelectedRow(row);
      },
      onDoubleClick: (event: React.MouseEvent) => {
        if (onSelect) {
          onSelect(row);
        } else {
          setSelectedRow(row);
        }
      },
    };
  };

  const rowSelection: ITableRowSelection<IPlatformModel> = {
    onChange: (
      selectedRowKeys: React.Key[],
      selectedRows: IPlatformModel[]
    ) => {
      if (selectedRows.length > 0) {
        setSelectedRow(selectedRows[0]);
      }
    },
    type: "radio",
    selectedRowKeys: selectedRow?.Code ? [selectedRow.Code] : [],
  };

  const getColumns = (): Array<ITableColumnProps<IPlatformModel>> => {
    let columns = [
      {
        key: "Code",
        dataIndex: "Code",
        title: t("MODEL_CODE"),
      },
      {
        key: "DisplayName",
        dataIndex: "DisplayName",
        title: t("MODEL_NAME"),
      },
    ];

    return columns;
  };

  return (
    <Modal
      title={t("APPLICATION_CONFIGURATION_MODAL_SELECT_PLATFORM_TITLE")}
      onCancel={onCancelClick}
      open={visible}
      className="SelectPlatformModal"
      closable={false}
      centered={true}
      width="50%"
      footer={
        <>
          <Button key="cancel" onClick={onCancelClick}>
            {t("COMMON_CANCEL")}
          </Button>
          <Button key="cancel" disabled={!selectedRow} onClick={onSelectClick}>
            {t("BUTTON_SELECT")}
          </Button>
        </>
      }
    >
      <Table<IPlatformModel>
        rowKey="Code"
        style={{ minHeight: "20vh" }}
        columns={getColumns()}
        dataSource={platforms}
        rowSelection={rowSelection}
        onRow={onTableRow}
        pagination={false}
      />
    </Modal>
  );
};
