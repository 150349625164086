import {
  ITestDeviceModel,
  PlatformType,
  TestDevicesService,
} from "@bms/common-services";
import { useTranslation } from "react-i18next";
import { Form, useValidate } from "@bms/common-ui";
import { LazyChoose } from "../../../../../../components";
import React from "react";

interface TestDeviceFieldProps {
  name: string;
  label: string;
  placeholder?: string;
  platformCode?: string;
}

const testDevicesService = new TestDevicesService().promisify();

export const TestDeviceField = (props: TestDeviceFieldProps) => {
  const { name, label, placeholder, platformCode } = props;

  const { t } = useTranslation();
  const validate = useValidate();

  const loader = (search: string) =>
    testDevicesService.search({
      PageNumber: 1,
      PageSize: 100,
      FullTextSearch: search,
      Platforms: [platformCode as PlatformType],
    });

  const toOption = (item: ITestDeviceModel) => {
    return {
      label: item.Name!,
      value: item.Id.toString(),
      item,
    };
  };

  return (
    <Form.Item
      name={name}
      label={label}
      key={name}
      rules={validate.required({
        choiceField: true,
        message: t("REQUIRE_TEST_DEVICE_VALIDATION_MESSAGE"),
      })}
    >
      <LazyChoose<ITestDeviceModel, ITestDeviceModel>
        placeholder={placeholder}
        multiple
        disabled={!platformCode}
        loaderDeps={[platformCode]}
        loader={loader}
        candidateToOption={toOption}
        selectedToOption={toOption}
      />
    </Form.Item>
  );
};
