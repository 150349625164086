import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  IAnalyticsUserPlatformRatingFilterModel,
  IErrorModel,
  UserPlatformRatingsService,
} from "@bms/common-services";
import { Button, Form, Modal, Result } from "@bms/common-ui";

import "./RatingAppExportModal.scss";

export interface IRatingAppExportModalProps {
  visible: boolean;
  filter: IAnalyticsUserPlatformRatingFilterModel;
  onCancel?: () => void;
  onSuccess?: () => void;
}

const ratingService = new UserPlatformRatingsService();

export const RatingAppExportModal = (props: IRatingAppExportModalProps) => {
  const { t } = useTranslation();
  const { visible, filter, onCancel, onSuccess } = props;
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [result, setResult] = useState<Blob>();
  const [error, setError] = useState<IErrorModel>();
  const isFinished = !isProcessing && (result || error) ? true : false;
  const [form] = Form.useForm();

  const onFinish = async () => {
    setIsProcessing(true);

    try {
      const result = await ratingService
        .exportRatingAnalytics(filter)
        .toPromise();
      setResult(result);
    } catch (error) {
      setError(error as IErrorModel);
    } finally {
      setIsProcessing(false);
    }
  };

  const onDownloadResultsClick = () => {
    if (!result) {
      return;
    }

    const downloadAnchor = document.createElement("a");
    const urlObject = URL.createObjectURL(
      new Blob([result], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
    );
    downloadAnchor.href = urlObject;
    downloadAnchor.download = `rating_app_report__${new Date().getTime()}.xlsx`;
    document.body.appendChild(downloadAnchor);
    downloadAnchor.click();
    onSuccess?.();

    setTimeout(() => {
      document.body.removeChild(downloadAnchor);
      if (urlObject) {
        URL.revokeObjectURL(urlObject);
      }
    }, 1000);
  };

  const renderResult = () => {
    if (error) {
      return (
        <Form.Item>
          <Result
            status="error"
            title={t("RATING_APP_EXPORT_MODAL_ERROR_TITLE")}
            subTitle={error?.Message}
            extra={[
              <Button key="close" onClick={onCancelModal}>
                {t("BUTTON_CLOSE")}
              </Button>,
            ]}
          />
        </Form.Item>
      );
    }

    if (result) {
      return (
        <Form.Item>
          <Result
            status="success"
            title={t("RATING_APP_EXPORT_MODAL_SUCCESS_TITLE")}
            subTitle={t("RATING_APP_EXPORT_MODAL_SUCCESS_MESSAGE")}
            extra={[
              <Button key="close" onClick={onCancelModal}>
                {t("BUTTON_CLOSE")}
              </Button>,
              <Button type="primary" onClick={onDownloadResultsClick}>
                {t("BUTTON_DOWNLOAD")}
              </Button>,
            ]}
          />
        </Form.Item>
      );
    }

    return null;
  };

  const onCancelModal = () => {
    onCancel?.();
  };

  const renderFooter = () => {
    if (isFinished) {
      return null;
    }

    return (
      <>
        <Button
          key="cancel"
          onClick={onCancelModal}
          type="link"
          disabled={isProcessing}
        >
          {t("BUTTON_CANCEL")}
        </Button>
        <Button
          form="RatingAppExportForm"
          key="submit"
          htmlType="submit"
          type="primary"
          loading={isProcessing}
          disabled={isProcessing}
        >
          {t("BUTTON_EXPORT")}
        </Button>
      </>
    );
  };

  return (
    <Modal
      title={t("RATING_APP_EXPORT_MODAL_TITLE")}
      footer={renderFooter()}
      processing={isProcessing}
      width={650}
      visible={visible}
      destroyOnClose
      maskClosable={false}
      onCancel={onCancelModal}
    >
      <Form
        form={form}
        name="RatingAppExportForm"
        className="RatingAppExportForm"
        onFinish={onFinish}
      >
        {renderResult()}
      </Form>
    </Modal>
  );
};
