import React from "react";
import cx from "classnames";
import { Spin } from "../Spin";
import { Icon } from "../Icon";
import "./ImageWithPlaceholder.scss";

export interface ImageWithPlaceholderProps {
  alt?: string;
  imageContainerClassName?: string; // custom className for component
  imageContainerStyles?: React.CSSProperties; // custom styles for component
  imageClassName?: string; // custom styles for image element
  imageSrc?: string; // source for the image which will be rendered once it is loaded
  animated?: boolean;
  spinner?: boolean;
  gradient?: string;
  placeholderSrc?: string;
}

export interface ImageWithPlaceholderState {
  hasLoaded?: string; // flag that indicates whether or not image has been loaded
}

/*
 * Component which will display given image based od imageSrc. Until image is not loaded (ready to be rendered)
 * a placeholder image will be rendered
 */
export class ImageWithPlaceholder extends React.Component<
  ImageWithPlaceholderProps,
  ImageWithPlaceholderState
> {
  private _imageLoader: HTMLImageElement = new Image();

  public static defaultProps: ImageWithPlaceholderProps = {
    alt: "",
  };

  public state: Readonly<ImageWithPlaceholderState> = {
    hasLoaded: "",
  };

  public componentDidMount() {
    const { imageSrc } = this.props;
    this.loadImage(imageSrc);
  }

  public componentWillUnmount() {
    this._imageLoader.src = "";
    this._imageLoader.onload = null;
  }

  private onLoad = () => {
    this.setState({ hasLoaded: this.props.imageSrc });
  };

  private loadImage(imageSrc = "") {
    this._imageLoader.src = imageSrc;
    this._imageLoader.onload = this.onLoad;
  }

  public render() {
    const {
      alt,
      imageContainerClassName,
      imageContainerStyles = {},
      imageClassName,
      imageSrc,
      animated,
      gradient,
      spinner,
      placeholderSrc,
    } = this.props;
    const { hasLoaded } = this.state;

    let viewContent: React.ReactNode = "";
    const backgroundStyle = { ...imageContainerStyles };

    if (hasLoaded) {
      viewContent = (
        <img
          alt={alt}
          className={cx("ImageWithPlaceholder__img", imageClassName)}
          draggable={false}
          src={imageSrc}
        />
      );
    } else if (gradient) {
      backgroundStyle.backgroundImage = gradient;
    } else if (placeholderSrc) {
      backgroundStyle.backgroundImage = `url(${placeholderSrc})`;
      backgroundStyle.backgroundRepeat = "no-repeat";
      backgroundStyle.backgroundSize = "contain";
      backgroundStyle.backgroundPosition = "center center";
      backgroundStyle.opacity = 0.6;
    } else if (spinner) {
      viewContent = (
        <div className="ImageWithPlaceholder--spinner">
          <Spin indicator={<Icon type="loading" spin={true} />} size="large" />
        </div>
      );
    } else if (animated) {
      backgroundStyle.background = `linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%)`;
      backgroundStyle.backgroundSize = `400% 100%`;
      backgroundStyle.animation = `ant-skeleton-loading 1.4s ease infinite`;
    }

    return (
      <div
        className={cx("ImageWithPlaceholder", imageContainerClassName)}
        style={backgroundStyle}
      >
        {viewContent}
      </div>
    );
  }
}
