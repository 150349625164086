import { HubConnectionState } from "@microsoft/signalr";
import { Observable, Observer } from "rxjs";
import * as NotificationActions from "../../store/notifications/actions";
import { dispatch } from "../../store/actions";
import { IUserNotificationModel } from "../../models";
import SignalRClient from "../SignalRClient";

export class NotificationsClient extends SignalRClient {
  public static instance: NotificationsClient;

  public static getInstance() {
    if (NotificationsClient.instance) {
      return NotificationsClient.instance;
    }

    NotificationsClient.instance = new NotificationsClient();

    return NotificationsClient.instance;
  }

  constructor() {
    super({
      url: "/Notifications/hub",
    });
  }

  public handleEvents = () => {
    this.connection?.on(
      "NotificationReceived",
      (data: IUserNotificationModel) => {
        dispatch && dispatch(NotificationActions.notificationReceived(data));
      }
    );
  };

  public markAsRead = (ids: number[]) =>
    new Observable((observer: Observer<number[]>) => {
      if (this.checkState(HubConnectionState.Connected)) {
        this.connection
          ?.invoke("MarkAsRead", ids)
          .then((response) => {
            observer.next(response);
            observer.complete();
          })
          .catch((error) => {
            observer.error(error);
          });
      }
    });
}
