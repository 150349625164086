import { ActionsObservable, ofType } from "redux-observable";
import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { IPaymentErrorStatusModel } from "../../models";
import { PaymentsService } from "../../services/Payments";
import { Actions, Consts, Types } from ".";

const paymentService: PaymentsService = new PaymentsService();

const getPaymentStatusEpic = (
  action$: ActionsObservable<Types.IGetPaymentStatusAction>
) =>
  action$.pipe(
    ofType(Consts.GET_PAYMENT_STATUS),
    switchMap((action) =>
      paymentService.getByKey(action.key).pipe(
        map((data) => Actions.getPaymentStatusSuccess(data)),
        catchError((error: IPaymentErrorStatusModel) =>
          of(Actions.getPaymentsStatusFailure(error))
        )
      )
    )
  );

const searchPaymentEpic = (
  action$: ActionsObservable<Types.ISearchPaymentAction>
) =>
  action$.pipe(
    ofType(Consts.SEARCH_PAYMENT),
    switchMap((action) =>
      paymentService.search(action.filter).pipe(
        map((data) => {
          data.Filter = action.filter;
          return Actions.searchPaymentSuccess(data);
        }),
        catchError((error: IPaymentErrorStatusModel) =>
          of(Actions.searchPaymentFailure(error))
        )
      )
    )
  );

export const paymentsEpics = [getPaymentStatusEpic, searchPaymentEpic];
