import { IApplicationComponentModel, RecordStatus } from "@bms/common-services";
import React from "react";
import { ComponentPropertyModel } from "../../models";

export interface IComponentPropertiesBaseProps {
  component: IApplicationComponentModel;
  onComponentChange?: (component: IApplicationComponentModel) => void;
}

export interface IComponentPropertiesBaseState {}

export abstract class ComponentPropertiesBase<
  TProps extends IComponentPropertiesBaseProps,
  TState extends IComponentPropertiesBaseState,
  TModel extends ComponentPropertyModel
> extends React.PureComponent<TProps, TState> {
  properties: TModel;

  constructor(props: TProps, context?: any) {
    super(props, context);

    this.properties = this.getPropertiesModel();
  }

  abstract getPropertiesModel(): TModel;

  onChange() {
    const { component, onComponentChange } = this.props;

    if (component.RecordStatus === RecordStatus.NoChange) {
      component.RecordStatus = RecordStatus.Updated;
    }

    if (onComponentChange) {
      onComponentChange(component);
    }
  }

  onPropertyChange(propertyName: string) {
    const { component, onComponentChange } = this.props;

    this.properties.onPropertyChange(propertyName, component);

    if (onComponentChange) {
      onComponentChange(component);
    }
  }

  deleteProperty(propertyName: string) {
    const { component, onComponentChange } = this.props;

    this.properties.deleteProperty(propertyName, component);

    if (onComponentChange) {
      onComponentChange(component);
    }
  }
}
