import React from "react";
import { Input as AntInput, InputRef as AntInputRef } from "antd";
import cx from "classnames";
import { InputProps } from "./types";

export class Input extends React.PureComponent<InputProps> {
  private _inputRef = React.createRef<AntInputRef>();

  public setValue(value: string): void {
    if (this._inputRef.current && this._inputRef.current.input) {
      this._inputRef.current.input.value = value;
    }
  }

  public select(): void {
    if (this._inputRef.current) {
      this._inputRef.current.select();
    }
  }

  public render() {
    const { className, shape = "round", ...rest } = this.props;
    const inputClassName = cx(this.props.className, "gm-input", {
      [`gm-input-${shape}`]: shape,
    });

    return (
      <AntInput ref={this._inputRef} className={inputClassName} {...rest} />
    );
  }
}
