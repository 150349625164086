import React, { memo, useState } from "react";
import {
  IAnalyticsSalesRevenueFilterModel,
  IUserModel,
  UserService,
} from "@bms/common-services";
import { useTranslation } from "react-i18next";
import { LazyChoose } from "../../../../../components";
import { useAppFeedback } from "@bms/common-ui";

interface ISalesRevenueCreatorSelectProps {
  filter: IAnalyticsSalesRevenueFilterModel;
  onChange?: (newValue: number[]) => void;
}

const userService = new UserService().promisify();

export const SalesRevenueCreatorSelect = memo(
  ({ onChange }: ISalesRevenueCreatorSelectProps) => {
    const { t } = useTranslation();
    const { notification } = useAppFeedback();

    const [value, setValue] = useState<IUserModel[]>([]);

    const onValueChange = (value: IUserModel[] | IUserModel) => {
      const newValue = Array.isArray(value) ? value : [value];
      setValue(newValue);

      if (onChange) {
        onChange(newValue.map((row) => row.Id));
      }
    };

    return (
      <LazyChoose<IUserModel, IUserModel>
        style={{ width: "100%" }}
        value={value}
        loader={(search: string) =>
          userService.search({
            PageNumber: 1,
            PageSize: 100,
            Profiles: ["CREATOR"],
            FullTextSearch: search,
          })
        }
        candidateToOption={(user) => ({
          label: `${user.FullName}`,
          value: `${user.Id}`,
          item: user,
        })}
        selectedToOption={(user) => ({
          label: `${user.FullName || user.Email}`,
          value: `${user.Id}`,
          item: user,
        })}
        placeholder={t("ASSET_IMPRESSIONS_USERS_PLACEHOLDER")}
        onChange={onValueChange}
        onError={(error) =>
          notification.error({
            message: error.Title,
            description: error.Message,
          })
        }
        getPopupContainer={(trigger) => trigger.parentNode}
        multiple
      />
    );
  }
);
