import { ActionsObservable, ofType, StateObservable } from "redux-observable";
import { switchMap } from "rxjs/operators";
import { getConfigFailure, getConfigSuccess } from "./actions";
import { GET_CONFIGURATION, IGetConfigAction } from "./types";
import { ConfigService } from "../../services/Config";
import { IErrorModel } from "../../models/ErrorModel";
import { ICommonAppState } from "../types";
import { DataProvider } from "../../providers";

const configService: ConfigService = new ConfigService();

const configurationEpic = (
  action$: ActionsObservable<IGetConfigAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(GET_CONFIGURATION),
    switchMap(async () => {
      try {
        let config = await configService.get().toPromise();

        if (DataProvider.Config?.get) {
          try {
            config = await DataProvider.Config?.get(config);
          } catch (error) {
            console.error("DataProvider: error during getting config!", error);
          }
        }

        return getConfigSuccess(config);
      } catch (error) {
        return getConfigFailure(error as IErrorModel);
      }
    })
  );

export const configEpics = [configurationEpic];
