import * as React from "react";
import { IAssetSourceEntities } from "@bms/common-services/src/models/Asset/IAssetSourceEntities";
import { Button, Icon } from "@bms/common-ui";
import { IAssetContentModel, IAssetModel } from "@bms/common-services";
import { useTranslation } from "react-i18next";

interface Props {
  asset?: IAssetModel | IAssetContentModel;
  externalSource?: string;
  assetSourceEntities?: IAssetSourceEntities[];
  type: EditType;
}

export enum EditType {
  AssetDetails = "AssetDetails",
  StreamDetails = "StreamDetails",
}

export function EditExternalAsset({
  externalSource,
  assetSourceEntities,
  asset,
  type,
}: Props) {
  const { t } = useTranslation();

  const data = assetSourceEntities?.find(
    ({ SourceSystemAlias }) => SourceSystemAlias === externalSource
  );

  const handleClick = () => {
    if (data && asset) {
      const regexp = new RegExp("{(.*?)}");
      const baseUrl = data.SourceSystemConfig.BaseUri;
      const value = regexp.exec(data.SourceSystemConfig[type]);
      //@ts-ignore
      const configUrls = value && data.SourceSystemConfig[type].replace(value[0], asset[value[1]] || "");

      window.open(`${baseUrl}${configUrls}`);
    }
  };

  if (!data) {
    return null;
  }

  return (
    <Button
      key="edit"
      icon={<Icon type="edit" />}
      shape="circle"
      onClick={handleClick}
      title={t("EDIT_EXTERNAL_MEDIA")}
    />
  );
}
