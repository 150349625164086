import React, { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  AgeRestrictionService,
  IAssetAgeRestrictionModel,
  ICommonAppState,
  IErrorModel,
  OperationResultType,
  RecordStatus,
  StorageService,
  useDataLoader,
  useServiceCaller,
} from "@bms/common-services";
import {
  Button,
  IBreadcrumbProps,
  Icon,
  ITableColumnProps,
  PageContent,
  PageHeader,
  Popconfirm,
  Table,
  useAppFeedback,
} from "@bms/common-ui";
import "./DictionaryAgeRestrictionList.scss";

import { PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import { generateBreadcrumb } from "../../../../helpers";
import { ROUTES } from "../../constants";
import { useSelector } from "react-redux";
import { DictionaryAgeRestrictionForm } from "../DictionaryAgeRestrictionForm";
import { FormModal } from "../../../../components";

const storageService: StorageService = StorageService.getInstance();

const ageRestrictionService = new AgeRestrictionService().promisify();

const defaultAgeRestriction: IAssetAgeRestrictionModel = {
  ValueMin: 0,
};

export const DictionaryAgeRestrictionList = () => {
  const { notification } = useAppFeedback();

  const [showAgeRestrictionModal, setShowAgeRestrictionModal] = useState(false);

  const [editableAgeRestriction, setEditableAgeRestriction] = useState<
    IAssetAgeRestrictionModel
  >(defaultAgeRestriction);

  const isFetching = useSelector(
    (state: ICommonAppState) => state.asset.assetAgeRestrictions.isFetching
  );

  const AgeRestrictionBadge = ({
    alt,
    src,
  }: {
    alt: string;
    src: string | undefined;
  }) => <img alt={alt} src={src} className="AgeRestrictionBadge" />;

  const { t } = useTranslation();

  const ageRestrictionLoader = useDataLoader({
    loader: () => ageRestrictionService.select(),
    deps: [],
  });

  const [
    insertAgeRestriction,
    { processing: insertProcessing },
  ] = useServiceCaller(
    async (ageRestriction: IAssetAgeRestrictionModel, contentFiles?: File) => {
      try {
        const result = await ageRestrictionService.insert({
          ...ageRestriction,
          RecordStatus: RecordStatus.Inserted,
        });

        if (result.ok) {
          contentFiles
            ? await uploadAgeRestrictionImage(result.data, contentFiles)
            : closeModal();

          notification.success({
            message: t("DICTIONARY_AGE_RESTRICTION_INSERT_SUCCESS"),
          });
          await ageRestrictionLoader.refresh();
        }
      } catch (err) {
        const error = err as IErrorModel;

        notification.error({
          message: t(
            "DICTIONARY_AGE_RESTRICTION_INSERT_FAILURE",
            "There was an error while adding age restriction."
          ),
          description: error ? error.Message : undefined,
        });
      }
    },
    []
  );
  const abortControllerRef = useRef<AbortController>();

  const uploadAgeRestrictionImage = async (
    ageRestriction: IAssetAgeRestrictionModel,
    file?: File
  ) => {
    try {
      const uploadFileInfo = await ageRestrictionService.getUploadFileInfo(
        ageRestriction.ValueMin
      );

      if (uploadFileInfo.ok && file) {
        abortControllerRef.current = new AbortController();
        storageService
          .uploadFile(
            file,
            uploadFileInfo.data,
            undefined,
            undefined,
            abortControllerRef.current
          )
          .then((result) => {
            if (result.ResultType === OperationResultType.Ok) {
              ageRestriction.ImageUrl = uploadFileInfo.data.AbsolutePath;
              updateAgeRestriction(ageRestriction);
              return;
            }
          })
          .then(() => {
            onUploadSuccess();
          })
          .catch((error) => {
            onUploadFailed(error);
          });
      }
    } catch (error) {
      onUploadFailed(error);
    }
  };

  const [updateAgeRestriction] = useServiceCaller(
    async (ageRestriction: IAssetAgeRestrictionModel) => {
      await ageRestrictionService
        .update({
          ...ageRestriction,
          RecordStatus: RecordStatus.Updated,
        })
        .then(() => {
          ageRestrictionLoader.refresh();
          closeModal();
        })
        .catch((error) => {
          notification.error({
            message: t("DICTIONARY_AGE_RESTRICTION_UPDATE_FAILURE"),
            description: error?.Message,
          });
        });
    },
    []
  );

  const onUploadFailed = (error: any) => {
    const appError = error as IErrorModel;
    notification.error({
      message: t("DICTIONARY_AGE_RESTRICTION_UPLOAD_FAILURE"),
      description: appError?.Message,
    });
  };

  const onUploadSuccess = () => {
    notification.success({
      message: t("DICTIONARY_AGE_RESTRICTION_UPLOAD_SUCCESS"),
    });
  };

  const [deleteAgeRestriction] = useServiceCaller(
    async (ageRestriction: IAssetAgeRestrictionModel) => {
      const result = await ageRestrictionService.delete({
        ...ageRestriction,
        RecordStatus: RecordStatus.Deleted,
      });
      if (result.ok) {
        notification.success({
          message: t(
            "DICTIONARY_AGE_RESTRICTION_DELETE_SUCCESS",
            "Age restriction has been deleted successfully."
          ),
        });
        await ageRestrictionLoader.refresh();
        closeModal();
      } else {
        notification.error({
          message: t(
            "DICTIONARY_AGE_RESTRICTION_DELETE_FAILURE",
            "There was an error while deleting age restriction."
          ),
          description: result.error?.Message,
        });
      }
    },
    []
  );

  const getColumnsProps = (): Array<
    ITableColumnProps<IAssetAgeRestrictionModel>
  > => {
    return [
      {
        key: "Value",
        dataIndex: "ValueMin",
        title: t("MODEL_VALUE"),
        width: "500px",
        render: (_: any, row: IAssetAgeRestrictionModel) => {
          return (
            <a
              className="imageUrl"
              onClick={() => {
                setShowAgeRestrictionModal(true);
                setEditableAgeRestriction(row);
              }}
            >
              {row.ValueMin}
            </a>
          );
        },
      },
      {
        key: "Image",
        dataIndex: "ImageUrl",
        title: t("MODEL_IMAGE"),
        render: (text, record) => (
          <span
            onClick={() => {
              setShowAgeRestrictionModal(true);
              setEditableAgeRestriction(record);
            }}
          >
            <AgeRestrictionBadge alt={text} src={record.ImageUrl} />
          </span>
        ),
      },
      {
        key: "Actions",
        dataIndex: "Actions",
        align: "center",
        title: t("TABLE_ACTIONS_COLUMN", "Actions"),
        render: (_: any, row: IAssetAgeRestrictionModel) => (
          <Popconfirm
            title={t(
              "DELETE_ELEMENT_DOUBLE_CONFIRMATION_QUESTION",
              "Are you sure you want to delete element?"
            )}
            onConfirm={async (e?: React.MouseEvent<HTMLElement>) => {
              e?.preventDefault();
              deleteAgeRestriction(row);
            }}
            okText={t("BUTTON_YES", "Yes")}
            cancelText={t("BUTTON_NO", "No")}
          >
            <Button
              danger={true}
              icon={<Icon type="delete" />}
              title={t("DELETE_ELEMENT", "Delete element")}
            />
          </Popconfirm>
        ),
      },
    ];
  };

  const closeModal = () => {
    setShowAgeRestrictionModal(false);
    setEditableAgeRestriction({ ValueMin: 0 });
  };

  const onAddNewAgeRestrictionClick = () => {
    setShowAgeRestrictionModal(true);
  };

  const onRefreshClick = () => {
    ageRestrictionLoader.refresh();
  };

  const getBreadcrumbProps = (): IBreadcrumbProps => {
    return generateBreadcrumb([
      {
        path: `${ROUTES.DICTIONARY_BASE}`,
        breadcrumbName: t("MENU_ADMINISTRATION_DICTIONARIES"),
      },
      {
        path: `${ROUTES.DICTIONARY_VIDEO_AGE_RESTRICTION_LIST}`,
        breadcrumbName: t("MENU_ADMINISTRATION_DICTIONARIES_AGE_RESTRICTIONS"),
      },
    ]);
  };

  const getExtraButtons = () => {
    return (
      <>
        <Button
          key="add"
          shape="circle"
          type="primary"
          icon={<PlusOutlined />}
          onClick={onAddNewAgeRestrictionClick}
          title={t("DICTIONARY_AGE_RESTRICTION_ADD_NEW")}
        />
        <Button
          key="reload"
          shape="circle"
          icon={<ReloadOutlined />}
          onClick={onRefreshClick}
          title={t("BUTTON_REFRESH_TITLE")}
        />
      </>
    );
  };

  return (
    <div>
      <PageContent>
        <PageHeader
          title={t("DICTIONARY_AGE_RESTRICTION_TITLE", "Age Restrictions")}
          breadcrumb={getBreadcrumbProps()}
          extra={getExtraButtons()}
        />
        <Table<IAssetAgeRestrictionModel>
          columns={getColumnsProps()}
          dataSource={ageRestrictionLoader.data}
          loading={isFetching}
          pagination={false}
        />
      </PageContent>
      <FormModal
        isLoading={insertProcessing}
        isVisible={showAgeRestrictionModal}
        isNewForm={!editableAgeRestriction.RowVersion}
        isDeleteButtonEnabled={false}
        createFormTitle={t(
          "DICTIONARY_AGE_RESTRICTION_MODAL_NEW",
          "New age restriction"
        )}
        modalClassName="DictionaryAgeRestrictionModal"
        submitFormName="DictionaryAgeRestrictionForm"
        onCloseModal={closeModal}
        editFormTitle={t("DICTIONARY_AGE_RESTRICTION_MODAL_EDIT")}
      >
        <DictionaryAgeRestrictionForm
          ageRestriction={editableAgeRestriction}
          insertAgeRestriction={insertAgeRestriction}
          uploadAgeRestrictionImage={uploadAgeRestrictionImage}
          isProcessing={insertProcessing}
        />
      </FormModal>
    </div>
  );
};
