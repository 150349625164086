import { Observable, Observer } from "rxjs";
import { AxiosSubscriber } from "../AxiosSubscriber";
import { HTTP_METHOD } from "../../constants";
import { PromisifiableBase } from "../Base";
import { ICryptoCoinPriceListModel } from "../../models";

export class CryptoCoinPriceListsService extends PromisifiableBase {
  get url(): string {
    return "/CryptoCoinPriceLists";
  }

  public select = (): Observable<ICryptoCoinPriceListModel[]> =>
    new Observable(
      (observer: Observer<ICryptoCoinPriceListModel[]>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `${this.url}/Select`,
        })
    );

  public update = (data: ICryptoCoinPriceListModel): Observable<ICryptoCoinPriceListModel> =>
    new Observable(
      (observer: Observer<ICryptoCoinPriceListModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.PUT,
          url: `${this.url}/Update`,
        })
    );

  public insert = (data: ICryptoCoinPriceListModel): Observable<ICryptoCoinPriceListModel> =>
    new Observable(
      (observer: Observer<ICryptoCoinPriceListModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: `${this.url}/Insert`,
        })
    );

  public delete = (data: ICryptoCoinPriceListModel): Observable<undefined> =>
    new Observable(
      (observer: Observer<undefined>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.DELETE,
          url: `${this.url}/Delete?id=${data.Id}`,
        })
    );
}
