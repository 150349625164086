import { ICommonAppState } from "@bms/common-services";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
  IListComponentPropertiesDispatchProps,
  IListComponentPropertiesOwnProps,
  IListComponentPropertiesStateProps,
  ListComponentProperties as ListComponentPropertiesDefinition,
} from "./ListComponentProperties";

const mapStateToProps = (
  state: ICommonAppState
): IListComponentPropertiesStateProps => {
  return {
    isProcessingData: state.applicationConfiguration.isProcessingData,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch
): IListComponentPropertiesDispatchProps => ({});

export const ListComponentProperties = connect<
  IListComponentPropertiesStateProps,
  IListComponentPropertiesDispatchProps,
  IListComponentPropertiesOwnProps,
  ICommonAppState
>(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ListComponentPropertiesDefinition));
