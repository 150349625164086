import {
  IAdvertisementBlockModel,
  IAdvertisementBoardModel,
  IAdvertisementBoardTypeModel,
  IAdvertisementBlocksListModel,
  IAdvertisementBlocksSearchFilterModel,
  IErrorModel,
  IStateModel,
} from "../../models";
import * as Consts from "./consts";
import {
  AdvertisementActionsTypes,
  IGetAdvertisementBlockAction,
  IGetAdvertisementBlockFailureAction,
  IGetAdvertisementBlockSuccessAction,

  IUpdateAdvertisementBlockWithDetailsAction,
  IUpdateAdvertisementBlockWithDetailsFailureAction,
  IUpdateAdvertisementBlockWithDetailsSuccessAction,

  IPublishAdvertisementBlockAction,
  IPublishAdvertisementBlockFailureAction,
  IPublishAdvertisementBlockSuccessAction,

  IArchiveAdvertisementBlockAction,
  IArchiveAdvertisementBlockFailureAction,
  IArchiveAdvertisementBlockSuccessAction,

  IRestoreAdvertisementBlockAction,
  IRestoreAdvertisementBlockFailureAction,
  IRestoreAdvertisementBlockSuccessAction,

  IToggleAdvertisementBoardModalAction,

  IGetAdvertisementBoardAction,
  IGetAdvertisementBoardFailureAction,
  IGetAdvertisementBoardSuccessAction,

  IUpdateTemporaryAdvertisementBlockAction,
} from "./types";

export const updateAdvertisementBlock = (
    data: IStateModel<IAdvertisementBlockModel>
  ): AdvertisementActionsTypes => {
  return {
    type: Consts.UPDATE_ADVERTISEMENT_BLOCK,
    payload: data
  };
};

export const updateTemporaryAdvertisementBlock = (
  data: IStateModel<IAdvertisementBlockModel>
): IUpdateTemporaryAdvertisementBlockAction => {
  return {
    type: Consts.UPDATE_TEMPORARY_ADVERTISEMENT_BLOCK,
    payload: data
  };
};

export const toggleAdvertisementBlockModal = (
    visibility: boolean
  ): AdvertisementActionsTypes => {
  return {
    type: Consts.TOGGLE_ADVERTISEMENT_BLOCK_MODAL,
    visibility
  };
};

export const addAdvertisementBlock = (
    data: IAdvertisementBlockModel
  ): AdvertisementActionsTypes => {
  return {
    type: Consts.ADD_ADVERTISEMENT_BLOCK,
    payload: data
  };
};

export const addAdvertisementBlocks = (
): AdvertisementActionsTypes => {
  return {
    type: Consts.ADD_ADVERTISEMENT_BLOCKS,
  };
};

export const deleteAdvertisementBlock = (
  data: IAdvertisementBlockModel
): AdvertisementActionsTypes => {
  return {
    type: Consts.DELETE_ADVERTISEMENT_BLOCK,
    payload: data
  };
};

export const deleteAdvertisementBlockSuccess = (
): AdvertisementActionsTypes => {
  return {
    type: Consts.DELETE_ADVERTISEMENT_BLOCK_SUCCESS,
  };
};

export const deleteAdvertisementBlockFailure = (
  error?: IErrorModel
): AdvertisementActionsTypes => {
  return {
    error,
    type: Consts.DELETE_ADVERTISEMENT_BLOCK_FAILURE,
  };
};

export const addAdvertisementBlocksSuccess = (
  data: IAdvertisementBlockModel
): AdvertisementActionsTypes => {
  return {
    payload: data,
    type: Consts.ADD_ADVERTISEMENT_BLOCKS_SUCCESS,
  };
};

export const updateAdvertisementBlocksSuccess = (
): AdvertisementActionsTypes => {
  return {
    type: Consts.UPDATE_ADVERTISEMENT_BLOCK_SUCCESS,
  };
};

export const updateAdvertisementBlocksFailure = (
  error?: IErrorModel
): AdvertisementActionsTypes => {
  return {
    type: Consts.UPDATE_ADVERTISEMENT_BLOCK_FAILURE,
  };
};

export const updateAdvertisementBlockWithDetails = (
  data: IStateModel<IAdvertisementBlockModel>
): IUpdateAdvertisementBlockWithDetailsAction => {
  return {
    type: Consts.UPDATE_ADVERTISEMENT_BLOCK_WITH_DETAILS,
    payload: data,
  };
};

export const updateAdvertisementBlockWithDetailsSuccess = (
  data: IAdvertisementBlockModel
): IUpdateAdvertisementBlockWithDetailsSuccessAction => {
  return {
    payload: data,
    type: Consts.UPDATE_ADVERTISEMENT_BLOCK_WITH_DETAILS_SUCCESS,
  };
};

export const updateAdvertisementBlockWithDetailsFailure = (
  error?: IErrorModel
): IUpdateAdvertisementBlockWithDetailsFailureAction => {
  return {
    error,
    type: Consts.UPDATE_ADVERTISEMENT_BLOCK_WITH_DETAILS_FAILURE,
  };
};

export const publishAdvertisementBlock = (
  data: IAdvertisementBlockModel,
  html: string,
): IPublishAdvertisementBlockAction => {
  return {
    type: Consts.PUBLISH_ADVERTISEMENT_BLOCK,
    payload: {data, html},
  };
};

export const publishAdvertisementBlockSuccess = (
  data: IAdvertisementBlockModel
): IPublishAdvertisementBlockSuccessAction => {
  return {
    payload: data,
    type: Consts.PUBLISH_ADVERTISEMENT_BLOCK_SUCCESS,
  };
};

export const publishAdvertisementBlockFailure = (
  error?: IErrorModel
): IPublishAdvertisementBlockFailureAction => {
  return {
    error,
    type: Consts.PUBLISH_ADVERTISEMENT_BLOCK_FAILURE,
  };
};

export const archiveAdvertisementBlock = (
  data: IAdvertisementBlockModel,
): IArchiveAdvertisementBlockAction => {
  return {
    type: Consts.ARCHIVE_ADVERTISEMENT_BLOCK,
    payload: data,
  };
};

export const archiveAdvertisementBlockSuccess = (
  data: IAdvertisementBlockModel
): IArchiveAdvertisementBlockSuccessAction => {
  return {
    payload: data,
    type: Consts.ARCHIVE_ADVERTISEMENT_BLOCK_SUCCESS,
  };
};

export const archiveAdvertisementBlockFailure = (
  error?: IErrorModel
): IArchiveAdvertisementBlockFailureAction => {
  return {
    error,
    type: Consts.ARCHIVE_ADVERTISEMENT_BLOCK_FAILURE,
  };
};

export const restoreAdvertisementBlock = (
  data: IAdvertisementBlockModel,
): IRestoreAdvertisementBlockAction => {
  return {
    type: Consts.RESTORE_ADVERTISEMENT_BLOCK,
    payload: data,
  };
};

export const restoreAdvertisementBlockSuccess = (
  data: IAdvertisementBlockModel
): IRestoreAdvertisementBlockSuccessAction => {
  return {
    payload: data,
    type: Consts.RESTORE_ADVERTISEMENT_BLOCK_SUCCESS,
  };
};

export const restoreAdvertisementBlockFailure = (
  error?: IErrorModel
): IRestoreAdvertisementBlockFailureAction => {
  return {
    error,
    type: Consts.RESTORE_ADVERTISEMENT_BLOCK_FAILURE,
  };
};

export const addAdvertisementBlocksFailure = (
  error: IErrorModel
): AdvertisementActionsTypes => {
  return {
    type: Consts.ADD_ADVERTISEMENT_BLOCKS_FAILURE,
  };
};

export const searchAdvertisementBlocks = (
  filter: IAdvertisementBlocksSearchFilterModel
): AdvertisementActionsTypes => {
  return {
    filter,
    type: Consts.SEARCH_ADVERTISEMENT_BLOCK,
  };
};

export const searchAdvertisementBlocksSuccess = (
  data: IAdvertisementBlocksListModel
): AdvertisementActionsTypes => {
  return {
    payload: data,
    type: Consts.SEARCH_ADVERTISEMENT_BLOCK_SUCCESS,
  };
};

export const searchAdvertisementBlocksFailure = (
  error?: IErrorModel
): AdvertisementActionsTypes => {
  return {
    error,
    type: Consts.SEARCH_ADVERTISEMENT_BLOCK_FAILURE,
  };
};

export const getAdvertisementBlock = (
  id: number
): IGetAdvertisementBlockAction => {
  return {
    id,
    type: Consts.GET_ADVERTISEMENT_BLOCK,
  };
};

export const getAdvertisementBlockSuccess = (
  data: IAdvertisementBlockModel
): IGetAdvertisementBlockSuccessAction => {
  return {
    payload: data,
    type: Consts.GET_ADVERTISEMENT_BLOCK_SUCCESS,
  };
};

export const getAdvertisementBlockFailure = (
  error?: IErrorModel
): IGetAdvertisementBlockFailureAction => {
  return {
    error,
    type: Consts.GET_ADVERTISEMENT_BLOCK_FAILURE,
  };
};

export const toggleAdvertisementBoardModal = (
    visibility: boolean
  ): IToggleAdvertisementBoardModalAction => {
  return {
    type: Consts.TOGGLE_ADVERTISEMENT_BOARD_MODAL,
    visibility
  };
};

export const getAdvertisementBoard = (id: number): IGetAdvertisementBoardAction => {
  return {
    id,
    type: Consts.GET_ADVERTISEMENT_BOARD,
  };
};

export const getAdvertisementBoardSuccess = (
  data: IAdvertisementBoardModel
): IGetAdvertisementBoardSuccessAction => {
  return {
    payload: data,
    type: Consts.GET_ADVERTISEMENT_BOARD_SUCCESS,
  };
};

export const getAdvertisementBoardFailure = (
  error?: IErrorModel
): IGetAdvertisementBoardFailureAction => {
  return {
    error,
    type: Consts.GET_ADVERTISEMENT_BOARD_FAILURE,
  };
};

export const selectBoardsTypes = (): AdvertisementActionsTypes => {
  return {
    type: Consts.SELECT_ADVERTISEMENT_BOARD_TYPE,
  };
};

export const selectBoardsTypesSuccess = (
  data: IAdvertisementBoardTypeModel[]
): AdvertisementActionsTypes => {
  return {
    payload: data,
    type: Consts.SELECT_ADVERTISEMENT_BOARD_TYPE_SUCCESS,
  };
};

export const selectBoardsTypesFailure = (
  error?: IErrorModel
): AdvertisementActionsTypes => {
  return {
    error,
    type: Consts.SELECT_ADVERTISEMENT_BOARD_TYPE_FAILURE,
  };
};

export const Actions = {

  toggleAdvertisementBoardModal,
  getAdvertisementBoard,

  selectBoardsTypes,
  updateAdvertisementBlock,
  updateAdvertisementBlocksSuccess,
  updateAdvertisementBlocksFailure,
  updateAdvertisementBlockWithDetails,
  updateAdvertisementBlockWithDetailsSuccess,
  updateAdvertisementBlockWithDetailsFailure,
  updateTemporaryAdvertisementBlock,
  publishAdvertisementBlock,
  publishAdvertisementBlockSuccess,
  publishAdvertisementBlockFailure,
  archiveAdvertisementBlock,
  archiveAdvertisementBlockSuccess,
  archiveAdvertisementBlockFailure,
  restoreAdvertisementBlock,
  restoreAdvertisementBlockSuccess,
  restoreAdvertisementBlockFailure,
  toggleAdvertisementBlockModal,
  addAdvertisementBlock,

  addAdvertisementBlocks,
  addAdvertisementBlocksSuccess,
  addAdvertisementBlocksFailure,
  deleteAdvertisementBlock,

  getAdvertisementBlock,
  searchAdvertisementBlocks,
};
