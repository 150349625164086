import React, { useEffect, useState } from "react";

export const useIntervalEffect = (
  callback: () => void
): [number, React.Dispatch<React.SetStateAction<number>>] => {
  const [state, setState] = useState<number>(0);

  useEffect(() => {
    if (state && state > 0) {
      const interval = setInterval(callback, state);
      return () => clearInterval(interval);
    }
  }, [state]);

  return [state, setState];
};
