import {
  AssetInCollectionService,
  AssetService,
  IAssetInCollectionModel,
  IAssetModel,
  IAssetSearchFilterModel,
  RecordStatus,
  useDataLoader,
  useServiceCaller,
} from "@bms/common-services";
import {
  Col,
  InputSearch,
  ITableFilter,
  ITablePaginationConfig,
  ITransferItem,
  Row,
  TableTransfer,
  Tag,
  useAppFeedback,
} from "@bms/common-ui";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

const assetsService = new AssetService().promisify();
const assetsInCollectionService = new AssetInCollectionService().promisify();
const PAGE_SIZE = 15;

function sequenceMap<T>(array: T[], mapper: (item: T) => Promise<any>) {
  return array.reduce(
    (previous, next) => previous.then(() => mapper(next)),
    Promise.resolve()
  );
}

export const AssetCollectionAssetsTransfer: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [t] = useTranslation();
  const { notification } = useAppFeedback();

  const [assetFilter, setAssetFilter] = useState<IAssetSearchFilterModel>({
    PageSize: PAGE_SIZE,
    PageNumber: 1,
    IncludeCount: true,
    FullTextSearch: "",
    Types: [],
  });

  const assetsInCollectionLoader = useDataLoader({
    loader: () =>
      assetsInCollectionService.select({}, { assetCollectionId: +id }),
    deps: [],
  });

  const assetTypesLoader = useDataLoader({
    loader: () => assetsService.getAssetTypes(),
    deps: [],
  });

  const assetsLoader = useDataLoader({
    loader: () => assetsService.search(assetFilter),
    onError: (error) => {
      notification.error({
        message: error?.Message,
      });
    },
    deps: [assetFilter],
  });

  const assetsInCollection = assetsInCollectionLoader.data?.Entities ?? [];
  const assetTypes = assetTypesLoader.data ?? [];
  const assets = assetsLoader.data?.Entities ?? [];

  const [
    insertAssetInCollection,
    { processing: insertProcessing },
  ] = useServiceCaller(async (AssetIds: number[]) => {
    const result = await assetsService.saveAssetsInCollection(
      AssetIds.map((AssetId) => ({
        AssetCollectionId: +id,
        AssetId,
        AssetTitle: assets.find(({ Id }) => Id === +id)?.Title,
        RecordStatus: RecordStatus.Inserted,
      }))
    );
    if (!result.ok) {
      notification.error({
        message: t("PLAY_LIST_ASSET_INSERT_FAILURE_MESSAGE"),
        description: result.error?.Message,
      });
    }
    await assetsInCollectionLoader.refresh();
  }, []);

  const [
    deleteAssetInCollection,
    { processing: deleteProcessing },
  ] = useServiceCaller(async (AssetIds: number[]) => {
    let errorOccurred = false;
    await sequenceMap(AssetIds, async (AssetId: number) => {
      const result = await assetsInCollectionService.delete({
        AssetCollectionId: +id,
        AssetId,
        RecordStatus: RecordStatus.Deleted,
      });
      if (!result.ok) {
        errorOccurred = true;
      }
    });
    if (errorOccurred) {
      notification.error({
        message: t("PLAY_LIST_ASSET_DELETE_FAILURE_MESSAGE"),
      });
    }
    await assetsInCollectionLoader.refresh();
  }, []);

  const [onMoveRow, { processing: moveProcessing }] = useServiceCaller(
    async (dragIndex: number, hoverIndex: number) => {
      const draggedAsset: IAssetInCollectionModel =
        assetsInCollection[dragIndex];
      const hoveredAsset: IAssetInCollectionModel =
        assetsInCollection[hoverIndex];

      if (
        draggedAsset.Sequence === undefined ||
        hoveredAsset.Sequence === undefined
      ) {
        return;
      }

      const assetToUpdate: IAssetInCollectionModel = {
        ...draggedAsset,
        Sequence: hoveredAsset.Sequence ?? 1,
      };

      const sequenceChanged = draggedAsset.Sequence !== assetToUpdate.Sequence;
      const draggedToNewPosition =
        draggedAsset.AssetId !== hoveredAsset.AssetId;

      if (!draggedToNewPosition || !sequenceChanged) {
        return;
      }
      const result = await assetsInCollectionService.update(assetToUpdate);

      if (!result.ok) {
        notification.error({
          message: t("PLAY_LIST_ASSET_REORDER_FAILURE_MESSAGE"),
          description: result.error?.Message,
        });
      }

      await assetsInCollectionLoader.refresh();
    },
    [assetsInCollection]
  );

  // fake assets list is required to maintain proper pagination and items count in transfer component
  const availableAssets = React.useMemo<ITransferItem[]>(() => {
    const items = assets.map((entity) => ({
      key: entity.Id.toString(),
      disabled: !!assetsInCollection.find(
        ({ AssetId }) => AssetId === entity.Id
      ),
      ...entity,
    }));
    const fakeList = Array.from(
      Array(assetsLoader.data?.TotalCount ?? 0).keys()
    ).map((_, i) => ({
      key: `fake-${i}`,
      disabled: true,
    }));
    const offset = ((assetFilter.PageNumber ?? 1) - 1) * PAGE_SIZE;
    fakeList.splice(offset, PAGE_SIZE, ...items);
    return fakeList;
  }, [assets, assetsInCollection]);

  const selectedAssets = React.useMemo<ITransferItem[]>(
    () =>
      assetsInCollection.map((entity) => ({
        key: entity.AssetId.toString(),
        ...entity,
      })),
    [assets, assetsInCollection]
  );

  const onTableChange = (
    direction: "left" | "right",
    pagination: ITablePaginationConfig,
    filters: ITableFilter
  ) => {
    if (direction === "right") {
      return;
    }
    const filter = {
      ...assetFilter,
      Types: filters.AssetTypeCode?.length
        ? filters.AssetTypeCode.map((row) => `${row}`)
        : undefined,
      PageNumber: pagination.current,
    };
    setAssetFilter(filter);
  };

  const onSearchChanged = (value: string) => {
    const filter = {
      ...assetFilter,
      FullTextSearch: value,
    };
    setAssetFilter(filter);
  };

  const onChange = (_: any, direction: string, AssetsIds: string[]) => {
    if (direction === "right") {
      insertAssetInCollection(AssetsIds.map((AssetId) => +AssetId));
    }
    if (direction === "left") {
      deleteAssetInCollection(AssetsIds.map((AssetId) => +AssetId));
    }
  };

  const leftColumns = [
    {
      dataIndex: "Title",
      title: t("PLAY_LIST_ASSET_TITLE_COLUMN"),
    },
    {
      dataIndex: "AssetTypeCode",
      title: t("PLAY_LIST_ASSET_TYPE_COLUMN"),
      align: "center",
      width: "120px",
      filters: assetTypes.map((assetType) => ({
        text: assetType.DisplayName,
        value: assetType.Code,
      })),
      filteredValue: assetFilter.Types || null,
      render: (value: string, row: IAssetModel) => (
        <Tag colorRotate={assetTypes.findIndex(({ Code }) => Code === value)}>
          {row.AssetTypeDisplayName}
        </Tag>
      ),
    },
  ];

  const rightColumns = [
    {
      dataIndex: "AssetTitle",
      title: "Title",
      className: "drag-visible",
    },
  ];

  const titles = [
    <Row justify="space-between" align="middle">
      <Col>
        {assetsLoader.data
          ? `${assetsLoader.data?.TotalCount} assets available`
          : ""}
      </Col>
      <Col>
        <InputSearch
          allowClear
          style={{ width: "180px" }}
          onSearch={onSearchChanged}
        />
      </Col>
    </Row>,
    <Row align="middle">
      <Col>
        {assetsInCollectionLoader.data?.TotalCount
          ? `${assetsInCollectionLoader.data.TotalCount} assets in playlist`
          : ""}
      </Col>
    </Row>,
  ];

  const anyProcessing = insertProcessing || deleteProcessing || moveProcessing;

  return (
    <TableTransfer
      pageSize={PAGE_SIZE}
      draggable
      titles={titles as any}
      leftLoading={assetsLoader.loading || anyProcessing}
      leftColumns={leftColumns}
      rightLoading={assetsInCollectionLoader.loading || anyProcessing}
      rightColumns={rightColumns}
      onMoveRow={onMoveRow}
      onTableChange={onTableChange}
      onChange={onChange}
      dataSource={availableAssets}
      dataTarget={selectedAssets}
      listStyle={() => ({})}
    />
  );
};
