import {
  ComponentType,
  ComponentTypeHelper,
  GuidHelper,
  IApplicationComponentModel,
  IApplicationComponentTypeModel,
  IErrorModel,
  PlatformType,
  RecordStatus,
  ScreenType,
  ScreenTypeHelper,
} from "@bms/common-services";
import {
  Button,
  Choose,
  ChooseOption,
  Col,
  Form,
  IChooseValue,
  IFormValues,
  Modal,
  required,
  Row,
} from "@bms/common-ui";
import React from "react";
import { WithTranslation } from "react-i18next";
import { Action, ActionCreator } from "redux";
import { WithScreenContextProps } from "../../context";
import "./ApplicationScreenAddComponentModal.scss";

export interface IApplicationScreenAddComponentModalProps
  extends WithTranslation,
    WithScreenContextProps {
  screenType: ScreenType;
  componentsTypes: IApplicationComponentTypeModel[];
  selectComponentsTypes: ActionCreator<Action>;
  visible: boolean;
  onCancel?: () => void;
  onSuccess?: () => void;
}

export interface IApplicationScreenAddComponentModalState {
  selectedComponentTypeCode: string | undefined;
}

export class ApplicationScreenAddComponentModal extends React.PureComponent<
  IApplicationScreenAddComponentModalProps,
  IApplicationScreenAddComponentModalState
> {
  public static defaultProps = {
    componentsTypes: [],
    visible: false,
  };

  public componentDidMount() {
    const { componentsTypes, selectComponentsTypes } = this.props;

    if (!componentsTypes || componentsTypes.length === 0) {
      selectComponentsTypes();
    }
  }

  public onCancelClick = () => {
    const { onCancel } = this.props;

    if (onCancel) {
      onCancel();
    }
  };

  public onFinish = (values: IFormValues) => {
    const { onSuccess, componentsTypes, onComponentAdd } = this.props;
    const { selectedComponentTypeCode } = this.state;
    const componentType = componentsTypes.find(
      (row: IApplicationComponentTypeModel) =>
        row.Code === selectedComponentTypeCode
    );

    if (!componentType) {
      return;
    }

    return new Promise<IApplicationComponentModel>((resolve, reject) => {
      const component: IApplicationComponentModel = {
        Id: -1,
        Guid: GuidHelper.newGuid(),
        Name: componentType.Name,
        ComponentTypeCode: ComponentTypeHelper.getValue(componentType.Code),
        PlatformCode: PlatformType.Any,
        Sequence: 0,
        RecordStatus: RecordStatus.Inserted,
      };

      switch (component.ComponentTypeCode) {
        case ComponentType.TextWidget:
          // const textComponentModel: ITextComponentModel = {
          //   Id: -1,
          //   Name: 'TextWidget',
          //   Content: ''
          // };

          // return textComponentsService.insert(textComponentModel)
          //  .toPromise()
          //  .then((data: ITextComponentModel) => {
          //    component.
          //  })
          //  .catch((error: IErrorModel) => reject(error));

          resolve(component);
          break;
        default:
          resolve(component);
          break;
      }
    })
      .then((component: IApplicationComponentModel) => {
        if (onComponentAdd) {
          onComponentAdd(component);
        }

        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((error: IErrorModel) => {});
  };

  public onComponentTypeChange = (value: IChooseValue) => {
    var selectedComponentTypeCode = value ? (value as string) : undefined;
    this.setState({ selectedComponentTypeCode });
  };

  public renderComponentTypeField = () => {
    const { t, componentsTypes, screenType } = this.props;

    const options: React.ReactElement[] = [];

    const availableComponentsTypes = ScreenTypeHelper.getAvailableComponents(
      screenType
    );

    for (const componentType of componentsTypes.filter(
      (componentType: IApplicationComponentTypeModel) => {
        return availableComponentsTypes.includes(
          ComponentTypeHelper.getValue(componentType.Code)
        );
      }
    )) {
      options.push(
        <ChooseOption value={componentType.Code}>
          {componentType.Name}
        </ChooseOption>
      );
    }

    return (
      <Form.Item
        name="ComponentType"
        label={t("APPLICATION_SCREEN_ADD_MODAL__COMPONENT_TYPE")}
        rules={[required()]}
      >
        <Choose
          showSearch
          placeholder={t("APPLICATION_SCREEN_ADD_MODAL__SELECT_TYPE")}
          optionFilterProp="children"
          onChange={this.onComponentTypeChange}
          children={options}
        />
      </Form.Item>
    );
  };

  public render() {
    const { t, visible } = this.props;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 8 },
        lg: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 16 },
        lg: { span: 16 },
      },
    };

    return (
      <Modal
        title={t("ADD_NEW_COMPONENT")}
        onCancel={this.onCancelClick}
        visible={visible}
        footer={
          <>
            <Button key="cancel" onClick={this.onCancelClick}>
              {t("BUTTON_CANCEL")}
            </Button>
            <Button
              form="ApplicationScreenAddComponentForm"
              key="add"
              htmlType="submit"
              type="primary"
            >
              {t("BUTTON_ADD")}
            </Button>
          </>
        }
      >
        <Form
          name="ApplicationScreenAddComponentModal"
          id="ApplicationScreenAddComponentForm"
          {...formItemLayout}
          className="ApplicationScreenAddComponentModal"
          onFinish={this.onFinish}
        >
          <Row
            direction="column"
            justify="space-between"
            className="full-height"
          >
            <Col>{this.renderComponentTypeField()}</Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}
