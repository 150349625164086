import { ComponentType } from "../../../enums";

export class ComponentTypeHelper {
  static getDescription(value: ComponentType): string {
    switch (value) {
      case "CUSTOM":
        return "Custom";
      case "SCREEN_PROPERTIES":
        return "Screen Properties";
      case "APPLICATION_MENU_ITEM":
        return "Application Menu Item";
      case "APPLICATION_HEADER_ITEM":
        return "Application Header Item";
      case "APPLICATION_FOOTER_ITEM":
        return "Application Footer Item";
      case "SETTINGS_MENU_ITEM":
        return "Settings Menu Item";
      case "ACTION":
        return "Action";
      case "LIST":
        return "List";
      case "LIST_CELL":
        return "List Cell";
      case "IMAGE":
        return "Image";
      case "TABS":
        return "Tabs";
      case "TABS_ITEM":
        return "Tabs Item";
      case "EPG":
        return "Epg";
      case "BUTTON":
        return "Button";
      case "SOURCE_FILTER":
        return "Source Filter";
      case "SOURCE_FILTER_ITEM":
        return "Source Filter Item";
      case "WEB_VIEW":
        return "Web View";
      case "TEXT_WIDGET":
        return "Text Widget";
      case "PLAY_LIST_WIDGET":
        return "Play List Widget";
      case "TOP_FRIENDS_WIDGET":
        return "Top Friends Widget";
      case "CREATOR_ACTIVITIES_WIDGET":
        return "Creator Activities Widget";
      case "SECTION_MENU":
        return "Section Menu";
      case "SECTION_MENU_ITEM":
        return "Section Menu Item";
      default:
        return "Undefined";
    }
  }

  static getColor(value: ComponentType): string {
    let color: string;

    switch (value) {
      case "CUSTOM":
        color = "#1890ff";
        break;
      case "APPLICATION_MENU_ITEM":
        color = "#cf1322";
        break;
      case "APPLICATION_HEADER_ITEM":
        color = "#cf1322";
        break;
      case "APPLICATION_FOOTER_ITEM":
        color = "#aa00ff";
        break;
      case "SETTINGS_MENU_ITEM":
        color = "#1D267D";
        break;
      case "ACTION":
        color = "#ad6800";
        break;
      case "LIST":
        color = "#fadb14";
        break;
      case "LIST_CELL":
        color = "#fa8c16";
        break;
      case "IMAGE":
        color = "#13c2c2";
        break;
      case "TEXT_WIDGET":
        color = "#722ed1";
        break;
      case "PLAY_LIST_WIDGET":
        color = "#c41d7f";
        break;
      case "TOP_FRIENDS_WIDGET":
        color = "#8c8c8c";
        break;
      case "CREATOR_ACTIVITIES_WIDGET":
        color = "#8EE4FF";
        break;
      default:
        color = "#2db7f5";
        break;
    }

    return color;
  }

  static getValue(value: string): ComponentType {
    switch (value) {
      case "CUSTOM":
        return ComponentType.Custom;
      case "SCREEN_PROPERTIES":
        return ComponentType.ScreenProperties;
      case "APPLICATION_MENU_ITEM":
        return ComponentType.ApplicationMenuItem;
      case "APPLICATION_HEADER_ITEM":
        return ComponentType.ApplicationHeaderItem;
      case "APPLICATION_FOOTER_ITEM":
        return ComponentType.ApplicationFooterItem;
      case "SETTINGS_MENU_ITEM":
        return ComponentType.SettingsMenuItem;
      case "ACTION":
        return ComponentType.Action;
      case "LIST":
        return ComponentType.List;
      case "LIST_CELL":
        return ComponentType.ListCell;
      case "IMAGE":
        return ComponentType.Image;
      case "TABS":
        return ComponentType.Tabs;
      case "TABS_ITEM":
        return ComponentType.TabsItem;
      case "EPG":
        return ComponentType.Epg;
      case "BUTTON":
        return ComponentType.Button;
      case "SOURCE_FILTER":
        return ComponentType.SourceFilter;
      case "SOURCE_FILTER_ITEM":
        return ComponentType.SourceFilterItem;
      case "WEB_VIEW":
        return ComponentType.WebView;
      case "TEXT_WIDGET":
        return ComponentType.TextWidget;
      case "PLAY_LIST_WIDGET":
        return ComponentType.PlaylistWidget;
      case "TOP_FRIENDS_WIDGET":
        return ComponentType.TopFriendsWidget;
      case "CREATOR_ACTIVITIES_WIDGET":
        return ComponentType.CreatorActivitiesWidget;
      case "SECTION_MENU":
        return ComponentType.SectionMenu;
      case "SECTION_MENU_ITEM":
        return ComponentType.SectionMenuItem;
      default:
        return ComponentType.Custom;
    }
  }
}
