import { IUserModel } from "../models";
import { ProfileCode } from "../enums";
import { some } from "lodash";

const COLORS = [
  "#1abc9c",
  "#2ecc71",
  "#3498db",
  "#9b59b6",
  "#34495e",
  "#16a085",
  "#27ae60",
  "#2980b9",
  "#8e44ad",
  "#2c3e50",
  "#f1c40f",
  "#e67e22",
  "#e74c3c",
  "#95a5a6",
  "#f39c12",
  "#d35400",
  "#c0392b",
  "#bdc3c7",
  "#7f8c8d",
];

export class UserHelper {
  static getColor(userFullName: string): string {
    let initials: string | undefined;
    let color: string = "#3D01A4";

    if (userFullName && !userFullName.startsWith("+")) {
      const names = userFullName.split(" ");

      if (names.length > 1) {
        initials = `${names[0].charAt(0).toUpperCase()}${names[1]
          .charAt(0)
          .toUpperCase()}`;
      } else if (names.length === 1) {
        initials =
          names[0].length > 1
            ? names[0].substr(0, 2).toUpperCase()
            : names[0].charAt(0).toUpperCase();
      }
    }

    if (initials && initials.length > 0) {
      const charIndex: number = initials.charCodeAt(0) - 65;
      const colorIndex: number = charIndex % 19;
      color = COLORS[colorIndex];
    }

    return color;
  }

  static hasProfile = (user: IUserModel, profiles: ProfileCode[]) => {
    const userProfiles = user.Profiles?.map((profile) => profile.ProfileCode);

    if (userProfiles) {
      return some(profiles, (profile) => userProfiles.indexOf(profile) > -1);
    }

    return false;
  };
}
