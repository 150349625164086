import {
  AuthStore,
  IAuthRequestModel,
  isFieldError,
} from "@bms/common-services";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ROUTES } from "../../../../constants";
import { IAppState } from "../../../../store";
import { AuthContainer } from "../AuthContainer";
import { LoginForm } from "../LoginForm";

export function Login() {
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const onSubmit = useCallback(
    (data: IAuthRequestModel) => {
      dispatch(AuthStore.Actions.signIn(data));
    },
    [dispatch]
  );

  const error = useSelector<IAppState, string | undefined>((state) =>
    state.auth.error && !isFieldError(state.auth.error)
      ? state.auth.error.Message
      : undefined
  );

  useEffect(
    () => () => {
      dispatch(AuthStore.Actions.clearError());
    },
    [dispatch]
  );

  return (
    <AuthContainer
      error={error}
      footerLink={{
        href: ROUTES.RESET_PASSWORD,
        title: t("FORGOT_PASSWORD_LINK"),
      }}
    >
      <LoginForm onSubmit={onSubmit} />
    </AuthContainer>
  );
}
