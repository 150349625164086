import { Observable, Observer } from "rxjs";
import { AxiosSubscriber } from "../AxiosSubscriber";
import { HTTP_METHOD } from "../../constants";
import { OperationResult } from "../../models/OperationResult";

export class AssetSyncService {
  get url(): string {
    return "/AssetsSync";
  }

  public import = (file: File): Observable<OperationResult<void>> => {
    const formData = new FormData();
    formData.append("client", `${process.env.REACT_APP_CODE}`);
    // set correct content type to file because when is empty then will be send as application/octet-stream
    formData.append(
      "sourceFile",
      file.slice(
        0,
        file.size,
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      )
    );

    return new Observable(
      (observer: Observer<OperationResult<void>>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          },
          data: formData,
          method: HTTP_METHOD.POST,
          url: `${this.url}/Sync`,
        })
    );
  };
}
