import {
  RATING_CATEGORIES_SEARCH,
  RATING_CATEGORIES_SEARCH_FAILURE,
  RATING_CATEGORIES_SEARCH_SUCCESS,
  RATING_CATEGORY_DELETE,
  RATING_CATEGORY_DELETE_FAILURE,
  RATING_CATEGORY_DELETE_SUCCESS,
  RATING_CATEGORY_INSERT,
  RATING_CATEGORY_INSERT_FAILURE,
  RATING_CATEGORY_INSERT_SUCCESS,
  RATING_CATEGORY_UPDATE,
  RATING_CATEGORY_UPDATE_FAILURE,
  RATING_CATEGORY_UPDATE_SUCCESS,
} from "./consts";
import { RatingActionTypes, RatingPayloadTypes, RatingState } from "./types";
import { createReducers } from "../../helpers/redux.helper";
import { IErrorModel, IRatingCategoryListModel } from "../../models";

export const initialState: RatingState = {
  categories: {
    Entities: [],
    TotalCount: 0,
  },
};

export const ratingReducer = createReducers<
  RatingState,
  RatingPayloadTypes,
  RatingActionTypes
>(initialState, {
  [RATING_CATEGORIES_SEARCH]: (state) => ({
    ...state,
    categories: {
      ...state.categories,
      IsLoading: true,
    },
  }),
  [RATING_CATEGORIES_SEARCH_SUCCESS]: (state, action) => ({
    ...state,
    categories: {
      ...(action.payload as IRatingCategoryListModel),
      IsLoading: false,
    },
  }),
  [RATING_CATEGORIES_SEARCH_FAILURE]: (state, action) => ({
    ...state,
    categories: {
      ...state.categories,
      IsLoading: false,
      Error: action.payload as IErrorModel,
    },
  }),
  [RATING_CATEGORY_DELETE]: (state) => ({
    ...state,
    categories: {
      ...state.categories,
      IsLoading: true,
    },
  }),
  [RATING_CATEGORY_DELETE_SUCCESS]: (state) => ({
    ...state,
    categories: {
      ...state.categories,
      IsLoading: false,
    },
  }),
  [RATING_CATEGORY_DELETE_FAILURE]: (state, action) => ({
    ...state,
    categories: {
      ...state.categories,
      IsLoading: false,
      Error: action.payload as IErrorModel,
    },
  }),
  [RATING_CATEGORY_INSERT]: (state) => ({
    ...state,
    categories: {
      ...state.categories,
      IsLoading: true,
    },
  }),
  [RATING_CATEGORY_INSERT_SUCCESS]: (state) => ({
    ...state,
    categories: {
      ...state.categories,
      IsLoading: false,
    },
  }),
  [RATING_CATEGORY_INSERT_FAILURE]: (state, action) => ({
    ...state,
    categories: {
      ...state.categories,
      IsLoading: false,
      Error: action.payload as IErrorModel,
    },
  }),
  [RATING_CATEGORY_UPDATE]: (state) => ({
    ...state,
    categories: {
      ...state.categories,
      IsLoading: true,
    },
  }),
  [RATING_CATEGORY_UPDATE_SUCCESS]: (state) => ({
    ...state,
    categories: {
      ...state.categories,
      IsLoading: false,
    },
  }),
  [RATING_CATEGORY_UPDATE_FAILURE]: (state, action) => ({
    ...state,
    categories: {
      ...state.categories,
      IsLoading: false,
      Error: action.payload as IErrorModel,
    },
  }),
});
