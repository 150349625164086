import {
  INotificationRecipientModel,
  INotificationRecipientSearchFilterModel,
  IUserNotificationSearchFilterModel,
} from "../../models";
import { SimpleServiceBase } from "../Base";
import { INotificationRecipientListModel } from "../../models/Notifications/INotificationRecipientListModel";

export class NotificationRecipientsService extends SimpleServiceBase<
  INotificationRecipientModel,
  INotificationRecipientListModel,
  number,
  INotificationRecipientSearchFilterModel
> {
  get url(): string {
    return "/NotificationRecipients";
  }
}
