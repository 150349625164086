import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  Button,
  DatePicker,
  ITableColumnFilterDropdownProps,
} from "@bms/common-ui";
import { TimeHelper } from "@bms/common-services";

interface DateFilterDropdownParams {
  initialStartDateFrom: string | undefined;
  initialStartDateTo: string | undefined;
  onReset: () => void;
  onFilter: (startDateFrom?: string, startDateTo?: string) => void;
}

const smallControlsStyle = {
  width: "100%",
  marginTop: "5px",
  marginBottom: "5px",
};

export const useDateFilterDropdown = (params: DateFilterDropdownParams) => {
  const {
    initialStartDateFrom,
    initialStartDateTo,
    onReset,
    onFilter,
  } = params;

  const { t } = useTranslation();

  const filterDropdown = (event: ITableColumnFilterDropdownProps) => {
    const { confirm } = event;

    const [dateFrom, setDateFrom] = useState<any>(initialStartDateFrom);
    const [dateTo, setDateTo] = useState<any>(initialStartDateTo);

    return (
      <div style={{ padding: 8 }}>
        <DatePicker
          showTime={true}
          placeholder={t("DATE_FROM")}
          style={smallControlsStyle}
          value={dateFrom}
          onChange={setDateFrom}
        />
        <DatePicker
          showTime={true}
          placeholder={t("DATE_TO")}
          style={smallControlsStyle}
          value={dateTo}
          onChange={setDateTo}
        />

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            type="link"
            size="small"
            disabled={!dateFrom && !dateTo}
            onClick={() => {
              setDateFrom(undefined);
              setDateTo(undefined);
              onReset();
            }}
          >
            <Trans i18nKey="BUTTON_RESET">Reset</Trans>
          </Button>
          <Button
            type="primary"
            size="small"
            onClick={() => {
              const startDateFrom = dateFrom
                ? TimeHelper.toString(dateFrom.toDate())
                : undefined;

              const startDateTo = dateTo
                ? TimeHelper.toString(dateTo.toDate())
                : undefined;

              onFilter(startDateFrom, startDateTo);
              confirm();
            }}
          >
            <Trans i18nKey="BUTTON_OK">OK</Trans>
          </Button>
        </div>
      </div>
    );
  };

  return {
    filterDropdown,
  };
};
