import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  Button,
  IBreadcrumbProps,
  ITableColumnProps,
  ITableFilter,
  Icon,
  InputSearch,
  Link,
  PageContent,
  PageHeader,
  Pagination,
  Table,
  Tag,
  Tooltip,
} from "@bms/common-ui";
import {
  CommonStore,
  ICommonAppState,
  IUserPlatformRatingsModel,
  RatingCategoriesService,
  TimeHelper,
  dispatch,
  useDataLoader,
} from "@bms/common-services";
import { useSearchUserPlatformRatings } from "../../hooks";
import { PlatformTypeHelper, generateBreadcrumb } from "../../../../helpers";
import { ROUTES } from "../../constants";
import { ROUTES as USER_ROUTES } from "../../../User/constants";
import { FilterCleanIcon } from "../../../../resources/icons";

const ratingCategoriesService = new RatingCategoriesService().promisify();
const ratingRange = [1, 2, 3, 4, 5];
const commonSelector = (state: ICommonAppState) => state.common;

export const RatingsList = () => {
  const { t } = useTranslation();
  const {
    pagination,
    fullTextSearch,
    setFullTextSearch,
    filters,
    ratingData,
    ratingSearchLoader,
    isLoading,
  } = useSearchUserPlatformRatings();

  const { platforms } = useSelector(commonSelector);
  const selectPlatforms = useCallback(
    () => dispatch(CommonStore.Actions.selectPlatforms()),
    [dispatch]
  );

  useEffect(() => {
    if (platforms?.length === 0) {
      selectPlatforms();
    }
  }, []);

  const categoriesLoader = useDataLoader({
    loader: () =>
      ratingCategoriesService.search({
        ...pagination,
        PageNumber: 1,
      }),
    deps: [],
  });

  const getColumnsProps = (): Array<
    ITableColumnProps<IUserPlatformRatingsModel>
  > => {
    return [
      {
        key: "UserEmail",
        dataIndex: "UserEmail",
        ellipsis: true,
        title: t("MODEL_USER_FULLNAME"),

        render: (_, row: IUserPlatformRatingsModel) => (
          <Link to={`${USER_ROUTES.USER_DETAILS}/${row.UserId}`}>
            {row.UserFullName}
          </Link>
        ),
      },
      {
        key: "Ratings",
        dataIndex: "Ratings",
        title: t("MODEL_RATING"),
        filters: ratingRange.map((rate) => ({
          text: rate,
          value: rate,
        })),
        filteredValue: filters.asTableArray.Ratings,
        render: (_, record) => {
          return (<span>{record.Rating}</span>);
        },
      },
      {
        key: "Categories",
        dataIndex: "Categories",
        title: t("MODEL_CATEGORIES"),
        filters: categoriesLoader?.data?.Entities.map((category) => ({
          text: category.Name,
          value: category.Id,
        })),
        filteredValue: filters.asTableArray.Categories,
        render: (_, ratingData) => {
          const categoriesView: React.ReactNode[] = [];

          if (ratingData.Categories && ratingData.Categories.length > 0) {
            ratingData.Categories.map(
              (category) =>
                category.RatingCategoryDisplayName &&
                categoriesView.push(
                  <Tag
                    key={category.RatingCategoryId}
                    colorRotate={categoriesLoader?.data?.Entities?.findIndex(
                      ({ Name }) => {
                        return category.RatingCategoryName === Name;
                      }
                    )}
                  >
                    {category.RatingCategoryName}
                  </Tag>
                )
            );
          }

          return categoriesView;
        },
      },
      {
        key: "Comment",
        dataIndex: "Comment",
        title: t("MODEL_COMMENT"),
      },
      {
        key: "Platforms",
        dataIndex: "Platforms",
        title: t("MODEL_PLATFORMS"),
        filters: platforms.map((platform) => ({
          text: platform.DisplayName,
          value: platform.Code,
        })),
        filteredValue: filters.asTableArray.Platforms,
        render: (_, ratingData) => {
          const platformsView: React.ReactNode[] = [];

          ratingData.PlatformCode &&
            platformsView.push(
              PlatformTypeHelper.getTag(ratingData.PlatformCode)
            );

          return platformsView;
        },
      },
      {
        key: "CreationDateTime",
        dataIndex: "CreationDateTime",
        title: t("MODEL_CREATED"),
        render: (creationDate: Date) =>
          creationDate ? TimeHelper.format(creationDate) : null,
      },
    ];
  };

  const onTableChange = (_: any, incomingFilters: ITableFilter) =>
    filters.update((oldFilters) => ({
      ...oldFilters,
      ...incomingFilters,
    }));

  const onSearch = (value: string) =>
    filters.update((oldFilters) => ({
      ...oldFilters,
      FullTextSearch: value || undefined,
    }));

  const getBreadcrumbProps = (): IBreadcrumbProps => {
    return generateBreadcrumb([
      {
        path: `${ROUTES.RATING_LIST}`,
        breadcrumbName: t("RATING_APP__TITLE"),
      },
    ]);
  };

  return (
    <PageContent
      className="RatingsList"
      footer={<Pagination {...pagination.props} />}
    >
      <PageHeader
        title={t("RATING_APP__TITLE")}
        breadcrumb={getBreadcrumbProps()}
        extra={[
          <InputSearch
            key="search"
            placeholder={t("SEARCH_PLACEHOLDER", "Search...")}
            value={fullTextSearch}
            onSearch={onSearch}
            onChange={({ target: { value } }) => setFullTextSearch(value)}
            style={{ width: 250 }}
            allowClear
          />,
          <Tooltip
            overlay={t("MENU_OPTION_CLEAR_FILTERS", "Clear filters")}
            key="tooltip"
          >
            <Button
              key="clear-filters"
              shape="circle"
              icon={<FilterCleanIcon />}
              onClick={filters.clear}
              disabled={!filters.anyActive}
              title={t("MENU_OPTION_CLEAR_FILTERS", "Clear filters")}
            />
          </Tooltip>,
          <Button
            key="reload"
            shape="circle"
            icon={<Icon type="reload" />}
            onClick={ratingSearchLoader.refresh}
            title={t("BUTTON_REFRESH_TITLE", "Refresh data")}
          />,
        ]}
      />
      <Table<IUserPlatformRatingsModel>
        rowKey="Id"
        columns={getColumnsProps()}
        dataSource={ratingData?.Entities}
        loading={isLoading}
        pagination={false}
        onChange={onTableChange}
      />
    </PageContent>
  );
};
