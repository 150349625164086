import React, { useRef, useState } from "react";
import { getRandomColorHex, PageContent } from "@bms/common-ui";
import "./AssetImpressions.scss";
import { useAssetImpressionsDataProvider } from "../../hooks";
import { AssetImpressionsHeader } from "./AssetImpressionsHeader/AssetImpressionsHeader";
import { AssetImpressionsChart } from "./AssetImpressionsChart";
import { AssetImpressionsDataCard } from "./AssetImpressionsDataCard";
import { IAnalyticsAssetImpressionsAssetModel } from "@bms/common-services";

export interface IAnalyticsAssetImpressionsAssetWithColorsModel
  extends IAnalyticsAssetImpressionsAssetModel {
  Color: string;
}

export const AssetImpressions = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [impressionsListWithColors, setImpressionsListWithColors] = useState<
    IAnalyticsAssetImpressionsAssetWithColorsModel[]
  >([]);
  const {
    data,
    filter,
    loading,
    refreshData,
    setFilter,
    resetFilter,
  } = useAssetImpressionsDataProvider();

  if (data?.Results && impressionsListWithColors?.length === 0) {
    const datas = data.Results.map((o) => ({
      ...o,
      Color: getRandomColorHex(),
    }));
    setImpressionsListWithColors(datas);
  }

  return (
    <PageContent ref={ref} className="AssetImpressions">
      <AssetImpressionsHeader
        filter={filter}
        setFilter={setFilter}
        refreshData={refreshData}
        resetFilter={resetFilter}
      />
      <AssetImpressionsChart
        impressionsListWithColors={impressionsListWithColors}
        filter={filter}
        data={data?.Results}
        isLoading={loading}
        aggregationPeriod={filter.AggregationPeriod}
      />
      <div className="AssetImpressions__cards-container">
        <AssetImpressionsDataCard data={data} isLoading={loading} />
      </div>
    </PageContent>
  );
};
