import { ActionsObservable, ofType, StateObservable } from "redux-observable";
import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { ICommonAppState } from "..";
import {
  IAdvertisementBlockModel,
  IAdvertisementBoardTypeModel,
  IErrorModel,
  IAdvertisementBlocksListModel,
  IAdvertisementBoardModel,
  OperationResult,
  UploadFileInfoModel,
} from "../../models";
import {
  AdvertisementService,
  AdvertisementBoardService,
  AdvertisementBoardTypeService,
  StorageService,
} from "../../services";
import {
  getAdvertisementBlockSuccess,
  getAdvertisementBlockFailure,
  addAdvertisementBlocksSuccess,
  addAdvertisementBlocksFailure,
  getAdvertisementBoardSuccess,
  getAdvertisementBoardFailure,
  selectBoardsTypesSuccess,
  selectBoardsTypesFailure,

  updateAdvertisementBlocksSuccess,
  updateAdvertisementBlocksFailure,
  updateAdvertisementBlockWithDetailsSuccess,
  updateAdvertisementBlockWithDetailsFailure,
  publishAdvertisementBlockSuccess,
  publishAdvertisementBlockFailure,
  archiveAdvertisementBlockSuccess,
  archiveAdvertisementBlockFailure,
  restoreAdvertisementBlockSuccess,
  restoreAdvertisementBlockFailure,
  searchAdvertisementBlocksSuccess,
  searchAdvertisementBlocksFailure,
  deleteAdvertisementBlockFailure,
  deleteAdvertisementBlockSuccess,
} from "./actions";
import * as Consts from "./consts";
import {
  ISearchAdvertisementBlockAction,
  IGetAdvertisementBlockAction,
  IGetAdvertisementBoardAction,
  ISelectAdvertisementBoardTypeAction,

  IAddAdvertisementBlockAction,
  IUpdateAdvertisementBlockAction,
  IUpdateAdvertisementBlockWithDetailsAction,
  IPublishAdvertisementBlockAction,
  IArchiveAdvertisementBlockAction,
  IRestoreAdvertisementBlockAction,
  IDeleteAdvertisementBlocksAction,
} from "./types";

const advertisementService = new AdvertisementService();
const advertisementBoardService = new AdvertisementBoardService();
const advertisementBoardTypeService = new AdvertisementBoardTypeService();
const storageService: StorageService = StorageService.getInstance();

const getAdvertisementBlockEpic = (
  action$: ActionsObservable<IGetAdvertisementBlockAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.GET_ADVERTISEMENT_BLOCK),
    switchMap((action: IGetAdvertisementBlockAction) =>
      advertisementService.getDetails(action.id).pipe(
        map((data: IAdvertisementBlockModel) => {
          return getAdvertisementBlockSuccess(data);
        }),
        catchError((error: IErrorModel) =>
          of(getAdvertisementBlockFailure(error))
        )
      )
    )
  );

const insertAdvertisementBlockEpic = (
  action$: ActionsObservable<IAddAdvertisementBlockAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.ADD_ADVERTISEMENT_BLOCK),
    switchMap((action: IAddAdvertisementBlockAction) =>
      advertisementService.insert(action.payload).pipe(
        map((data: IAdvertisementBlockModel) => {
          return addAdvertisementBlocksSuccess(data);
        }),
        catchError((error: IErrorModel) =>
          of(addAdvertisementBlocksFailure(error))
        )
      )
    )
  );

const updateAdvertisementBlockEpic = (
  action$: ActionsObservable<IUpdateAdvertisementBlockAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.UPDATE_ADVERTISEMENT_BLOCK),
    switchMap((action: IUpdateAdvertisementBlockAction) =>{
      if(typeof action.payload.Data === "undefined"){
        action.payload.Data = {} as IAdvertisementBlockModel
      }
      return advertisementService.update(action.payload.Data).pipe(
        map(() => {
          return updateAdvertisementBlocksSuccess();
        }),
        catchError((error: IErrorModel) =>
          of(updateAdvertisementBlocksFailure(error))
        )
      )

    }
    )
  );

const updateAdvertisementBlockWithDetailsEpic = (
    action$: ActionsObservable<IUpdateAdvertisementBlockWithDetailsAction>,
    state: StateObservable<ICommonAppState>
) =>
    action$.pipe(
      ofType(Consts.UPDATE_ADVERTISEMENT_BLOCK_WITH_DETAILS),
      switchMap(async (action: IUpdateAdvertisementBlockWithDetailsAction) => {      
        if(typeof action.payload.Data === "undefined"){
          action.payload.Data = {} as IAdvertisementBlockModel
        }

        try {
          if(action.payload.Data.Boards) {
            for(let board of action.payload.Data.Boards) {
              if(board.Image1File) {
                const image1UploadInfo: UploadFileInfoModel = await advertisementBoardService.getUploadFileInfo(board.Id, 1).toPromise();

                await storageService.uploadFile(
                  board.Image1File,
                  image1UploadInfo
                )

                board.Image1Url = image1UploadInfo.Path;
              }

              if(board.Image2File) {
                const image2UploadInfo: UploadFileInfoModel = await advertisementBoardService.getUploadFileInfo(board.Id, 2).toPromise();

                await storageService.uploadFile(
                  board.Image2File,
                  image2UploadInfo
                )

                board.Image2Url = image2UploadInfo.Path;
              }
            }
          }

          const block = await advertisementService.saveWithDetails(action.payload.Data).toPromise();
  
          return updateAdvertisementBlockWithDetailsSuccess(block);
        } catch (error) {
          return updateAdvertisementBlockWithDetailsFailure(error as IErrorModel);
        }
      }
      )
    );

const publishAdvertisementBlockEpic = (
    action$: ActionsObservable<IPublishAdvertisementBlockAction>,
    state: StateObservable<ICommonAppState>
) =>
    action$.pipe(
      ofType(Consts.PUBLISH_ADVERTISEMENT_BLOCK),
      switchMap((action: IPublishAdvertisementBlockAction) => {
        return advertisementService.publish(action.payload.data, action.payload.html).pipe(
          map((data: IAdvertisementBlockModel) => {
            return publishAdvertisementBlockSuccess(data);
          }),
          catchError((error: IErrorModel) => of(publishAdvertisementBlockFailure(error)))
        )
      }
      )
    );

const archiveAdvertisementBlockEpic = (
    action$: ActionsObservable<IArchiveAdvertisementBlockAction>,
    state: StateObservable<ICommonAppState>
) =>
    action$.pipe(
      ofType(Consts.ARCHIVE_ADVERTISEMENT_BLOCK),
      switchMap((action: IArchiveAdvertisementBlockAction) => {
        return advertisementService.archive(action.payload).pipe(
          map((data: IAdvertisementBlockModel) => {
            return archiveAdvertisementBlockSuccess(data);
          }),
          catchError((error: IErrorModel) => of(archiveAdvertisementBlockFailure(error)))
        )
      }
      )
    );

const restoreAdvertisementBlockEpic = (
    action$: ActionsObservable<IRestoreAdvertisementBlockAction>,
    state: StateObservable<ICommonAppState>
) =>
    action$.pipe(
      ofType(Consts.RESTORE_ADVERTISEMENT_BLOCK),
      switchMap((action: IRestoreAdvertisementBlockAction) => {
        return advertisementService.restore(action.payload).pipe(
          map((data: IAdvertisementBlockModel) => {
            return restoreAdvertisementBlockSuccess(data);
          }),
          catchError((error: IErrorModel) => of(restoreAdvertisementBlockFailure(error)))
        )
      }
      )
    );

const deleteAdvertisementBlockEpic = (
  action$: ActionsObservable<IDeleteAdvertisementBlocksAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.DELETE_ADVERTISEMENT_BLOCK),
    switchMap((action: IDeleteAdvertisementBlocksAction) =>{
      return advertisementService.delete(action.payload).pipe(
        map(() => {
          return deleteAdvertisementBlockSuccess();
        }),
        catchError((error: IErrorModel) =>
          of(deleteAdvertisementBlockFailure(error))
        )
      )
    }
    )
  );

const searchAdvertisementBlocksEpic = (
  action$: ActionsObservable<ISearchAdvertisementBlockAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.SEARCH_ADVERTISEMENT_BLOCK),
    switchMap((action: ISearchAdvertisementBlockAction) =>
      advertisementService.search(action.filter).pipe(
        map((data: IAdvertisementBlocksListModel) => {
          data.Filter = action.filter;
          return searchAdvertisementBlocksSuccess(data);
        }),
        catchError((error: IErrorModel) =>
          of(searchAdvertisementBlocksFailure(error))
        )
      )
    )
  );

const getAdvertisementBoardEpic = (
  action$: ActionsObservable<IGetAdvertisementBoardAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.GET_ADVERTISEMENT_BOARD),
    switchMap((action: IGetAdvertisementBoardAction) =>
      advertisementBoardService.get(action.id).pipe(
        map((data: IAdvertisementBoardModel) => {
          return getAdvertisementBoardSuccess(data);
        }),
        catchError((error: IErrorModel) => of(getAdvertisementBoardFailure(error)))
      )
    )
  );

const selectBoardsTypesEpic = (
  action$: ActionsObservable<ISelectAdvertisementBoardTypeAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.SELECT_ADVERTISEMENT_BOARD_TYPE),
    switchMap((action: ISelectAdvertisementBoardTypeAction) =>
      advertisementBoardTypeService.select().pipe(
        map((data: IAdvertisementBoardTypeModel[]) => {
          return selectBoardsTypesSuccess(data);
        }),
        catchError((error: IErrorModel) => of(selectBoardsTypesFailure(error)))
      )
    )
  );

export const advertisementEpics = [
  getAdvertisementBoardEpic,
  selectBoardsTypesEpic,

  getAdvertisementBlockEpic,
  searchAdvertisementBlocksEpic,
  deleteAdvertisementBlockEpic,
  insertAdvertisementBlockEpic,
  updateAdvertisementBlockEpic,
  updateAdvertisementBlockWithDetailsEpic,
  publishAdvertisementBlockEpic,
  archiveAdvertisementBlockEpic,
  restoreAdvertisementBlockEpic,
];
