import {
  AdvertisementStore,
  IAdvertisementBlockModel,
  ICommonAppState,
  IStateModel,
} from "@bms/common-services";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { withAdvertisementBoardContext } from "../../context";
import { AdvertisementBoardDetailsForm as AdvertisementBoardDetailsFormDefinition } from "./AdvertisementBoardDetailsForm";

const mapStateToProps = (state: ICommonAppState) => {
  return {
    actionType: state.advertisement.actionType,
    advertisementBlock: state.advertisement.advertisementBlock,
    isProcessingData: state.advertisement.isProcessingData,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateTemporaryAdvertisementBlock: (data: IStateModel<IAdvertisementBlockModel>) => {
    return dispatch(AdvertisementStore.Actions.updateTemporaryAdvertisementBlock(data));
  },
});

export const AdvertisementBoardDetailsForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withAdvertisementBoardContext(AdvertisementBoardDetailsFormDefinition)));
