import React, { memo, useEffect, useState } from "react";
import {
  IAnalyticsAssetImpressionsFilterModel,
  AssetService,
  IAssetModel,
} from "@bms/common-services";
import { useTranslation } from "react-i18next";
import { LazyChoose } from "../../../../../components";
import { useAppFeedback } from "@bms/common-ui";

interface IAssetImpressionsSelectProps {
  filter: IAnalyticsAssetImpressionsFilterModel;
  onChange?: (newValue: number[]) => void;
}

const assetService = new AssetService().promisify();

export const AssetImpressionsAssetSelect = memo(
  ({ filter, onChange }: IAssetImpressionsSelectProps) => {
    const { t } = useTranslation();
    const { notification } = useAppFeedback();
    const [value, setValue] = useState<IAssetModel[]>([]);

    useEffect(() => {
      if (!filter.Assets) {
        setValue([]);
      }
    }, [filter.Assets]);

    const onValueChange = (value: IAssetModel[] | IAssetModel) => {
      const newValue = Array.isArray(value) ? value : [value];
      setValue(newValue);

      if (onChange) {
        onChange(newValue.map((row) => row.Id));
      }
    };

    return (
      <LazyChoose<IAssetModel, IAssetModel>
        style={{ width: "100%" }}
        value={value}
        loader={(search: string) =>
          assetService.search({
            PageNumber: 1,
            PageSize: 100,
            FullTextSearch: search,
          })
        }
        candidateToOption={(asset) => ({
          label: `${asset.Title}`,
          value: `${asset.Id}`,
          item: asset,
        })}
        selectedToOption={(asset) => ({
          label: `${asset.Title}`,
          value: `${asset.Id}`,
          item: asset,
        })}
        placeholder={t("ASSET_IMPRESSIONS_ASSETS_PLACEHOLDER")}
        onChange={onValueChange}
        onError={(error) =>
          notification.error({
            message: error.Title,
            description: error.Message,
          })
        }
        getPopupContainer={(trigger) => trigger.parentNode}
        multiple
      />
    );
  }
);
