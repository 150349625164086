import { Observable, Observer } from "rxjs";
import { AxiosSubscriber } from "../AxiosSubscriber";
import { HTTP_METHOD } from "../../constants";
import { PromisifiableBase } from "../Base";
import {
  IPaymentSearchFilterModel,
  IPaymentListModel,
  IPaymentModel,
  IPaymentOptionsModel,
} from "../../models/Payment";

export class PaymentsService extends PromisifiableBase {
  get url(): string {
    return "/Payments";
  }

  public get = (id: string): Observable<IPaymentModel> =>
    new Observable(
      (observer: Observer<IPaymentModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: {
              id,
            },
          },
          method: HTTP_METHOD.GET,
          url: `${this.url}/Get`,
        })
    );

  public getByKey = (key: string): Observable<IPaymentModel> =>
    new Observable(
      (observer: Observer<IPaymentModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: {
              key,
            },
          },
          method: HTTP_METHOD.GET,
          url: `${this.url}/GetByKey`,
        })
    );

  public search = (
    filter: IPaymentSearchFilterModel
  ): Observable<IPaymentListModel> =>
    new Observable(
      (observer: Observer<IPaymentListModel>) =>
        new AxiosSubscriber(observer, {
          data: filter,
          method: HTTP_METHOD.POST,
          url: `${this.url}/Search`,
        })
    );

  public options = (): Observable<IPaymentOptionsModel> =>
    new Observable(
      (observer: Observer<IPaymentOptionsModel>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `${this.url}/GetOptions`,
        })
    );

  public import = (file: File): Observable<Blob> => {
    const formData = new FormData();
    formData.append("client", `${process.env.REACT_APP_CODE}`);
    // set correct content type to file because when is empty then will be send as application/octet-stream
    formData.append(
      "file",
      file.slice(
        0,
        file.size,
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      )
    );

    return new Observable(
      (observer: Observer<Blob>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            responseType: "blob",
          },
          data: formData,
          method: HTTP_METHOD.PUT,
          url: `${this.url}/Import`,
        })
    );
  };
}
