import React, { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  AssetService,
  IAnalyticsSalesRevenueFilterModel,
  IAssetModel,
  useDataLoader,
} from "@bms/common-services";
import { LazyChoose } from "../../../../components";
import { useAppFeedback } from "@bms/common-ui";

interface IPaymentAssetSelectProps {
  onChange?: (newValue: number[]) => void;
  filter: IAnalyticsSalesRevenueFilterModel;
  style?: any;
}

const assetService = new AssetService().promisify();

const useSalesRevenueAssetsDataProvider = () => {
  const { notification } = useAppFeedback();

  const { data: assetsData, loading } = useDataLoader({
    loader: () =>
      assetService.search({
        PageNumber: 1,
        PageSize: 10000,
        IncludeCount: false,
      }),
    onError: (error) =>
      notification.error({
        message: error.Title,
        description: error.Message,
      }),
    deps: [],
  });
  return { assets: assetsData?.Entities || [], loading };
};

export const PaymentAssetSelect = memo(
  ({ filter, onChange }: IPaymentAssetSelectProps) => {
    const { t } = useTranslation();
    const { notification } = useAppFeedback();

    const [value, setValue] = useState<IAssetModel[]>([]);

    const onValueChange = (value: IAssetModel[] | IAssetModel) => {
      const newValue = Array.isArray(value) ? value : [value];
      setValue(newValue);

      if (onChange) {
        onChange(newValue.map((row) => row.Id));
      }
    };

    return (
      <LazyChoose<IAssetModel, IAssetModel>
        style={{ width: "100%" }}
        value={value}
        loader={(search: string) =>
          assetService.search({
            PageNumber: 1,
            PageSize: 100,
            FullTextSearch: search,
          })
        }
        candidateToOption={(asset) => ({
          label: `${asset.Title}`,
          value: `${asset.Id}`,
          item: asset,
        })}
        selectedToOption={(asset) => ({
          label: `${asset.Title}`,
          value: `${asset.Id}`,
          item: asset,
        })}
        placeholder={t("ASSET_IMPRESSIONS_ASSETS_PLACEHOLDER")}
        onChange={onValueChange}
        onError={(error) =>
          notification.error({
            message: error.Title,
            description: error.Message,
          })
        }
        getPopupContainer={(trigger) => trigger.parentNode}
        multiple
      />
    );
  }
);
