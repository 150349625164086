import { omit } from "lodash";
import {
  AgeRestrictionModel,
  IChangePasswordModel,
  IErrorModel,
  IProfilesListModel,
  IResendConfirmationModel,
  IResetPasswordModel,
  IUserBrandingModel,
  IUserBrandingSettingsModel,
  IUserModel,
  IUserRequestOptionsModel,
  IUsersListModel,
  IUsersSearchFilterModel,
} from "../../models";
import * as Consts from "./consts";
import {
  IAnonymizeUserAction,
  IAnonymizeUserFailureAction,
  IAnonymizeUserSuccessAction,
  IBrowseUsersAction,
  IBrowseUsersFailureAction,
  IBrowseUsersSuccessAction,
  IChangeUserPasswordAction,
  IChangeUserPasswordFailureAction,
  IChangeUserPasswordSuccessAction,
  IResendConfirmationEmailsAction,
  IResendConfirmationEmailsFailureAction,
  IResendConfirmationEmailsSuccessAction,
  UserActionsTypes,
} from "./types";

export const getProfile = (): UserActionsTypes => {
  return {
    type: Consts.GET_PROFILE,
  };
};

export const getProfileSuccess = (profile: IUserModel): UserActionsTypes => {
  return {
    payload: {
      profile,
    },
    type: Consts.GET_PROFILE_SUCCESS,
  };
};

export const getProfileFailure = (error?: IErrorModel): UserActionsTypes => {
  return {
    error,
    type: Consts.GET_PROFILE_FAILURE,
  };
};

export const getProfileDetails = (
  options: IUserRequestOptionsModel
): UserActionsTypes => {
  return {
    type: Consts.GET_PROFILE_DETAILS,
    payload: options,
  };
};

export const getProfileDetailsSuccess = (
  data: IUserModel
): UserActionsTypes => {
  return {
    payload: data,
    type: Consts.GET_PROFILE_DETAILS_SUCCESS,
  };
};

export const getProfileDetailsFailure = (
  error?: IErrorModel
): UserActionsTypes => {
  return {
    error,
    type: Consts.GET_PROFILE_DETAILS_FAILURE,
  };
};

export const getPublicProfile = (userId: number): UserActionsTypes => {
  return {
    type: Consts.GET_PUBLIC_PROFILE,
    userId,
  };
};

export const getPublicProfileSuccess = (
  userId: number,
  data: IUserModel
): UserActionsTypes => {
  return {
    payload: data,
    type: Consts.GET_PUBLIC_PROFILE_SUCCESS,
    userId,
  };
};

export const getPublicProfileFailure = (
  userId: number,
  error?: IErrorModel
): UserActionsTypes => {
  return {
    error,
    type: Consts.GET_PUBLIC_PROFILE_FAILURE,
    userId,
  };
};

export const getFamilyMembers = (): UserActionsTypes => {
  return {
    type: Consts.GET_FAMILY_MEMBERS,
  };
};

export const getFamilyMembersSuccess = (
  data: IUserModel[]
): UserActionsTypes => {
  return {
    payload: {
      data,
    },
    type: Consts.GET_FAMILY_MEMBERS_SUCCESS,
  };
};

export const getFamilyMembersFailure = (
  error?: IErrorModel
): UserActionsTypes => {
  return {
    error,
    type: Consts.GET_FAMILY_MEMBERS_FAILURE,
  };
};

export const addFamilyMember = (data: any): UserActionsTypes => {
  return {
    data,
    type: Consts.ADD_FAMILY_MEMBER,
  };
};

export const addFamilyMemberSuccess = (data: any): UserActionsTypes => {
  return {
    payload: {
      data,
    },
    type: Consts.ADD_FAMILY_MEMBER_SUCCESS,
  };
};

export const addFamilyMemberFailure = (
  error?: IErrorModel
): UserActionsTypes => {
  return {
    error,
    type: Consts.ADD_FAMILY_MEMBER_FAILURE,
  };
};

export const getAgeRestrictions = (): UserActionsTypes => {
  return {
    type: Consts.GET_AGE_RESTRICTIONS,
  };
};

export const getAgeRestrictionsSuccess = (
  data: AgeRestrictionModel[]
): UserActionsTypes => {
  return {
    payload: {
      data,
    },
    type: Consts.GET_AGE_RESTRICTIONS_SUCCESS,
  };
};

export const getAgeRestrictionsFailure = (
  error?: IErrorModel
): UserActionsTypes => {
  return {
    error,
    type: Consts.GET_AGE_RESTRICTIONS_FAILURE,
  };
};

export const updateProfile = (
  data: IUserModel,
  redirectUrl?: string
): UserActionsTypes => {
  return {
    payload: omit(data, "Branding"), // FIXME: Sending Branding causes API errors and should not be sent for now
    redirectUrl,
    type: Consts.UPDATE_PROFILE,
  };
};

export const updateProfileSuccess = (
  profile: IUserModel,
  redirectUrl?: string
): UserActionsTypes => {
  return {
    payload: {
      profile,
      redirectUrl,
    },
    type: Consts.UPDATE_PROFILE_SUCCESS,
  };
};

export const updateProfileFailure = (error?: IErrorModel): UserActionsTypes => {
  return {
    error,
    type: Consts.UPDATE_PROFILE_FAILURE,
  };
};

export const updateUserBranding = (
  data: IUserBrandingModel
): UserActionsTypes => {
  return {
    data,
    type: Consts.UPDATE_USER_BRANDING,
  };
};

export const updateUserBrandingSuccess = (
  data: IUserBrandingModel
): UserActionsTypes => {
  return {
    payload: data,
    type: Consts.UPDATE_USER_BRANDING_SUCCESS,
  };
};

export const updateUserBrandingFailure = (
  error?: IErrorModel
): UserActionsTypes => {
  return {
    error,
    type: Consts.UPDATE_USER_BRANDING_FAILURE,
  };
};

export const getUserBrandingSettings = (): UserActionsTypes => {
  return {
    type: Consts.GET_USER_BRANDING_SETTINGS,
  };
};

export const getUserBrandingSettingsSuccess = (
  data: IUserBrandingSettingsModel
): UserActionsTypes => {
  return {
    payload: data,
    type: Consts.GET_USER_BRANDING_SETTINGS_SUCCESS,
  };
};

export const getUserBrandingSettingsFailure = (
  error?: IErrorModel
): UserActionsTypes => {
  return {
    error,
    type: Consts.GET_USER_BRANDING_SETTINGS_FAILURE,
  };
};

export const searchUsers = (
  filter: IUsersSearchFilterModel
): UserActionsTypes => {
  return {
    filter,
    type: Consts.SEARCH_USER,
  };
};

export const searchUsersSuccess = (data: IUsersListModel): UserActionsTypes => {
  return {
    payload: data,
    type: Consts.SEARCH_USER_SUCCESS,
  };
};

export const searchUsersFailure = (error?: IErrorModel): UserActionsTypes => {
  return {
    error,
    type: Consts.SEARCH_USER_FAILURE,
  };
};

export const deleteUser = (data: IUserModel): UserActionsTypes => {
  return {
    payload: data,
    type: Consts.DELETE_USER,
  };
};

export const deleteUserSuccess = (): UserActionsTypes => {
  return {
    type: Consts.DELETE_USER_SUCCESS,
  };
};

export const deleteUserFailure = (error?: IErrorModel): UserActionsTypes => {
  return {
    error,
    type: Consts.DELETE_USER_FAILURE,
  };
};

export const anonymizeUser = (data: IUserModel): IAnonymizeUserAction => {
  return {
    payload: data,
    type: Consts.ANONYMIZE_USER,
  };
};

export const anonymizeUserSuccess = (): IAnonymizeUserSuccessAction => {
  return {
    type: Consts.ANONYMIZE_USER_SUCCESS,
  };
};

export const anonymizeUserFailure = (
  error?: IErrorModel
): IAnonymizeUserFailureAction => {
  return {
    error,
    type: Consts.ANONYMIZE_USER_FAILURE,
  };
};

export const getUser = (id: number): UserActionsTypes => {
  return {
    id,
    type: Consts.GET_USER,
  };
};

export const getUserSuccess = (data: IUserModel): UserActionsTypes => {
  return {
    payload: data,
    type: Consts.GET_USER_SUCCESS,
  };
};

export const getUserFailure = (error?: IErrorModel): UserActionsTypes => {
  return {
    error,
    type: Consts.GET_USER_FAILURE,
  };
};

export const getUserDetails = (
  id: number,
  options: IUserRequestOptionsModel
): UserActionsTypes => {
  return {
    type: Consts.GET_USER_DETAILS,
    id,
    payload: options,
  };
};

export const getUserDetailsSuccess = (data: IUserModel): UserActionsTypes => {
  return {
    payload: data,
    type: Consts.GET_USER_DETAILS_SUCCESS,
  };
};

export const getUserDetailsFailure = (
  error?: IErrorModel
): UserActionsTypes => {
  return {
    error,
    type: Consts.GET_USER_DETAILS_FAILURE,
  };
};

export const insertUser = (data: IUserModel): UserActionsTypes => {
  return {
    payload: data,
    type: Consts.INSERT_USER,
  };
};

export const insertUserSuccess = (data: IUserModel): UserActionsTypes => {
  return {
    payload: data,
    type: Consts.INSERT_USER_SUCCESS,
  };
};

export const insertUserFailure = (error?: IErrorModel): UserActionsTypes => {
  return {
    error,
    type: Consts.INSERT_USER_FAILURE,
  };
};

export const updateUser = (data: IUserModel): UserActionsTypes => {
  return {
    payload: data,
    type: Consts.UPDATE_USER,
  };
};

export const updateUserSuccess = (data: IUserModel): UserActionsTypes => {
  return {
    payload: data,
    type: Consts.UPDATE_USER_SUCCESS,
  };
};

export const updateUserFailure = (error?: IErrorModel): UserActionsTypes => {
  return {
    error,
    type: Consts.UPDATE_USER_FAILURE,
  };
};

export const resetUserPassword = (
  data: IResetPasswordModel
): UserActionsTypes => {
  return {
    type: Consts.RESET_USER_PASSWORD,
    payload: data,
  };
};

export const resetUserPasswordSuccess = (data: boolean): UserActionsTypes => {
  return {
    payload: data,
    type: Consts.RESET_USER_PASSWORD_SUCCESS,
  };
};

export const resetUserPasswordFailure = (
  error?: IErrorModel
): UserActionsTypes => {
  return {
    error,
    type: Consts.RESET_USER_PASSWORD_FAILURE,
  };
};

export const createUser = (): UserActionsTypes => {
  return {
    type: Consts.CREATE_USER,
  };
};

export const selectProfiles = (): UserActionsTypes => {
  return {
    type: Consts.SELECT_PROFILES,
  };
};

export const selectProfilesSuccess = (
  data: IProfilesListModel
): UserActionsTypes => {
  return {
    payload: data,
    type: Consts.SELECT_PROFILES_SUCCESS,
  };
};

export const selectProfilesFailure = (
  error?: IErrorModel
): UserActionsTypes => {
  return {
    error,
    type: Consts.SELECT_PROFILES_FAILURE,
  };
};

export const browseUsers = (
  filter: IUsersSearchFilterModel
): IBrowseUsersAction => {
  return {
    filter,
    type: Consts.BROWSE_USERS,
  };
};

export const browseUsersSuccess = (
  data: IUsersListModel
): IBrowseUsersSuccessAction => {
  return {
    payload: data,
    type: Consts.BROWSE_USERS_SUCCESS,
  };
};

export const browseUsersFailure = (
  error?: IErrorModel
): IBrowseUsersFailureAction => {
  return {
    error,
    type: Consts.BROWSE_USERS_FAILURE,
  };
};

export const changeUserPassword = (
  data: IChangePasswordModel
): IChangeUserPasswordAction => {
  return {
    type: Consts.CHANGE_USER_PASSWORD,
    payload: data,
  };
};

export const changeUserPasswordSuccess = (
  data: boolean
): IChangeUserPasswordSuccessAction => {
  return {
    payload: data,
    type: Consts.CHANGE_USER_PASSWORD_SUCCESS,
  };
};

export const changeUserPasswordFailure = (
  error?: IErrorModel
): IChangeUserPasswordFailureAction => {
  return {
    error,
    type: Consts.CHANGE_USER_PASSWORD_FAILURE,
  };
};

export const resendConfirmationEmails = (
  data: IResendConfirmationModel
): IResendConfirmationEmailsAction => {
  return {
    type: Consts.RESEND_CONFIRMATION_EMAILS,
    payload: data,
  };
};

export const resendConfirmationEmailsSuccess = (): IResendConfirmationEmailsSuccessAction => {
  return {
    type: Consts.RESEND_CONFIRMATION_EMAILS_SUCCESS,
  };
};

export const resendConfirmationEmailsFailure = (
  error?: IErrorModel
): IResendConfirmationEmailsFailureAction => {
  return {
    error,
    type: Consts.RESEND_CONFIRMATION_EMAILS_FAILURE,
  };
};

export const Actions = {
  addFamilyMember,
  browseUsers,
  createUser,
  getAgeRestrictions,
  getFamilyMembers,
  getProfile,
  getProfileDetails,
  getPublicProfile,
  updateProfile,
  updateUserBranding,
  getUserBrandingSettings,
  searchUsers,
  getUser,
  getUserDetails,
  deleteUser,
  anonymizeUser,
  insertUser,
  updateUser,
  resetUserPassword,
  selectProfiles,
  changeUserPassword,
  resendConfirmationEmails,
};
