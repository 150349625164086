import React, { useState } from "react";
import {
  Button,
  Dragger,
  Form,
  Icon,
  IUploadChangeEvent,
  IUploadFile,
  Modal,
  required,
  Result,
  Spin,
} from "@bms/common-ui";
import { IErrorModel, PaymentsService } from "@bms/common-services";
import { useTranslation } from "react-i18next";
import { takeRight } from "lodash";

const paymentsService = new PaymentsService();

export interface IPaymantImportModalProps {
  visible: boolean;
  onCancel?: () => void;
  onSuccess?: () => void;
}

export const PaymantImportModal: React.FC<IPaymantImportModalProps> = ({
  visible,
  onCancel,
  onSuccess,
}: IPaymantImportModalProps) => {
  const { t } = useTranslation();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [fileList, setFileList] = useState<IUploadFile[]>([]);
  const [file, setFile] = useState<File>();
  const [result, setResult] = useState<Blob>();
  const [error, setError] = useState<IErrorModel>();
  const isFinished = !isProcessing && (result || error) ? true : false;

  const [form] = Form.useForm();

  const onBeforeUpload = (file: File) => {
    setFile(file);

    return true;
  };

  const onChangeContent = (event: IUploadChangeEvent) => {
    const { fileList } = event;
    const latestFiles = takeRight(fileList);

    if (latestFiles.length) {
      latestFiles[0].status = "done";
    }

    setFileList(latestFiles);
  };

  const reset = () => {
    form?.resetFields(["Upload"]);
    setFileList([]);
    setFile(undefined);
    setResult(undefined);
    setError(undefined);
  };

  const onFinish = async () => {
    if (!file) {
      return;
    }

    setIsProcessing(true);

    try {
      const result = await paymentsService.import(file).toPromise();
      setResult(result);
      setIsProcessing(false);
    } catch (error) {
      setError(error as IErrorModel);
      setIsProcessing(false);
    }
  };

  const onDownloadResultsClick = () => {
    if (!result) {
      return;
    }

    const downloadAnchor = document.createElement("a");
    const urlObject = URL.createObjectURL(
      new Blob([result], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
    );
    downloadAnchor.href = urlObject;
    downloadAnchor.download = `payments_import_report__${new Date().getTime()}.xlsx`;
    document.body.appendChild(downloadAnchor);
    downloadAnchor.click();
    onSuccess?.();
    reset();

    setTimeout(() => {
      document.body.removeChild(downloadAnchor);
      if (urlObject) {
        URL.revokeObjectURL(urlObject);
      }
    }, 1000);
  };

  const renderInfo = () => {
    if (isProcessing) {
      return <Spin tip={t("PAYMENT_IMPORT_MODAL_PROGRESS_MESSAGE")} />;
    }

    return (
      <>
        <p className="ant-upload-drag-icon">
          <Icon type="Inbox" />
        </p>
        <p className="ant-upload-text">{t("DRAG_AND_DROP_INFO")}</p>
      </>
    );
  };

  const renderDagger = () => {
    if (isFinished) {
      return null;
    }

    return (
      <Form.Item rules={[required()]}>
        <Dragger
          name="Upload"
          multiple={false}
          showUploadList={{
            showRemoveIcon: true,
            showPreviewIcon: false,
            showDownloadIcon: false,
          }}
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          beforeUpload={onBeforeUpload}
          fileList={fileList}
          onChange={onChangeContent}
          disabled={isProcessing}
        >
          {renderInfo()}
        </Dragger>
      </Form.Item>
    );
  };

  const renderResult = () => {
    if (error) {
      return (
        <Form.Item>
          <Result
            status="error"
            title={t("PAYMENT_IMPORT_MODAL_ERROR_TITLE")}
            subTitle={error?.Message || t("PAYMENT_IMPORT_MODAL_ERROR_MESSAGE")}
            extra={[
              <Button key="close" onClick={onCancelModal}>
                {t("BUTTON_CLOSE")}
              </Button>,
            ]}
          />
        </Form.Item>
      );
    }

    if (result) {
      return (
        <Form.Item>
          <Result
            status="success"
            title={t("PAYMENT_IMPORT_MODAL_SUCCESS_TITLE")}
            subTitle={t("PAYMENT_IMPORT_MODAL_SUCCESS_MESSAGE")}
            extra={[
              <Button key="close" onClick={onCancelModal}>
                {t("BUTTON_CLOSE")}
              </Button>,
              <Button type="primary" onClick={onDownloadResultsClick}>
                {t("BUTTON_DOWNLOAD")}
              </Button>,
            ]}
          />
        </Form.Item>
      );
    }

    return null;
  };

  const onCancelModal = () => {
    onCancel?.();
    reset();
  };

  const renderFooter = () => {
    if (isFinished) {
      return null;
    }

    return (
      <>
        <Button
          key="cancel"
          onClick={onCancelModal}
          type="link"
          disabled={isProcessing}
        >
          {t("BUTTON_CANCEL")}
        </Button>
        <Button
          form="PaymantImportForm"
          key="submit"
          htmlType="submit"
          type="primary"
          loading={isProcessing}
          disabled={isProcessing || !file}
        >
          {t("BUTTON_IMPORT")}
        </Button>
      </>
    );
  };

  return (
    <Modal
      title={t("PAYMENT_IMPORT_MODAL_TITLE")}
      footer={renderFooter()}
      processing={isProcessing}
      width={600}
      visible={visible}
      destroyOnClose={true}
      maskClosable={false}
      onCancel={onCancelModal}
    >
      <Form
        form={form}
        name="PaymantImportForm"
        className="PaymantImportForm"
        onFinish={onFinish}
      >
        {renderDagger()}
        {renderResult()}
      </Form>
    </Modal>
  );
};
