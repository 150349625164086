import { Divider, Title } from "@bms/common-ui";
import React from "react";
import { WithScreenDesignerContextProps } from "../../context";
import { TextWidgetComponentPropertyModel } from "../../models";
import {
  ComponentPreviewBase,
  IComponentPreviewBaseProps,
  IComponentPreviewBaseState,
} from "../ComponentPreviewBase";
import "./TextWidgetComponentPreview.scss";
import { TextWidgetMediaComponentPreview } from "./TextWidgetMediaComponentPreview";

export interface ITextWidgetComponentPreviewStateProps {}

export interface ITextWidgetComponentPreviewDispatchProps {}

export interface ITextWidgetComponentPreviewOwnProps
  extends IComponentPreviewBaseProps {
  properties: TextWidgetComponentPropertyModel;
}

export interface ITextWidgetComponentPreviewProps
  extends ITextWidgetComponentPreviewStateProps,
    ITextWidgetComponentPreviewDispatchProps,
    ITextWidgetComponentPreviewOwnProps,
    WithScreenDesignerContextProps {}

export interface ITextWidgetComponentPreviewState
  extends IComponentPreviewBaseState {}

export class TextWidgetComponentPreview extends ComponentPreviewBase<
  ITextWidgetComponentPreviewProps,
  ITextWidgetComponentPreviewState
> {
  public render() {
    const { properties } = this.props;

    return (
      <div className="TextWidgetComponentPreview">
        <header>
          <div className="TextWidgetComponentPreview__Header--content">
            <Title
              level={2}
              className="TextWidgetComponentPreview__Title"
              ellipsis={true}
            >
              {properties.Title}
            </Title>
          </div>
          {properties.Title && <Divider />}
        </header>
        <div
          className="TextWidgetComponentPreview__content"
          dangerouslySetInnerHTML={{ __html: properties.Content || "" }}
        />
        <TextWidgetMediaComponentPreview properties={properties} />
      </div>
    );
  }
}
