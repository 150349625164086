import { TestExecutionStatus } from "../enums";

export class TestExecutionStatusHelper {
  static getDescription(value: TestExecutionStatus): string {
    switch (value) {
      case TestExecutionStatus.Draft:
        return "Draft";
      case TestExecutionStatus.Queued:
        return "Queued";
      case TestExecutionStatus.Running:
        return "Running";
      case TestExecutionStatus.Error:
        return "Error";
      case TestExecutionStatus.Finished:
        return "Finished";
      default:
        return "Undefined";
    }
  }

  static getColor(value: TestExecutionStatus): string {
    let color: string;

    switch (value) {
      case TestExecutionStatus.Finished:
        color = "#418841";
        break;
      case TestExecutionStatus.Queued:
        color = "#1890ff";
        break;
      case TestExecutionStatus.Running:
        color = "#2db7f5";
        break;
      case TestExecutionStatus.Error:
        color = "#cc4444";
        break;
      case TestExecutionStatus.Draft:
      default:
        color = "#555";
        break;
    }

    return color;
  }

  static getValue(value: string): TestExecutionStatus {
    switch (value) {
      case "DRAFT":
        return TestExecutionStatus.Draft;
      case "QUEUED":
        return TestExecutionStatus.Queued;
      case "RUNNING":
        return TestExecutionStatus.Running;
      case "ERROR":
        return TestExecutionStatus.Error;
      case "FINISHED":
        return TestExecutionStatus.Finished;
      default:
        return TestExecutionStatus.Draft;
    }
  }

  static getFilterOptions() {
    return [
      {
        text: TestExecutionStatusHelper.getDescription(
          TestExecutionStatus.Draft
        ),
        value: TestExecutionStatus.Draft,
      },
      {
        text: TestExecutionStatusHelper.getDescription(
          TestExecutionStatus.Queued
        ),
        value: TestExecutionStatus.Queued,
      },
      {
        text: TestExecutionStatusHelper.getDescription(
          TestExecutionStatus.Running
        ),
        value: TestExecutionStatus.Running,
      },
      {
        text: TestExecutionStatusHelper.getDescription(
          TestExecutionStatus.Error
        ),
        value: TestExecutionStatus.Error,
      },
      {
        text: TestExecutionStatusHelper.getDescription(
          TestExecutionStatus.Finished
        ),
        value: TestExecutionStatus.Finished,
      },
    ];
  }
}
