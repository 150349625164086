import React from "react";
import { Trans } from "react-i18next";
import { FormFieldRule } from "../types";

export const required = (): FormFieldRule => {
  const messageRequired: React.ReactElement = (
    <Trans i18nKey="REQUIRED_VALIDATION_MESSAGE">This field is required</Trans>
  );

  return {
    message: messageRequired,
    required: true,
    whitespace: true,
  };
};

export const requiredValue = (errorMessage: string) => ({
  validator: (_rule: any, value: any, callback: (error?: string) => void) => {
    if (!value) {
      callback(errorMessage);
      return;
    }
    callback();
  },
});
