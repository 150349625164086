import { Observable, Observer } from "rxjs";
import { HTTP_METHOD } from "../../constants/http";
import { AxiosSubscriber } from "../AxiosSubscriber";
import { IApplicationModel } from "../../models";

export class ApplicationService {
  get url(): string {
    return "/Applications";
  }

  public select = (): Observable<IApplicationModel[]> =>
    new Observable(
      (observer: Observer<IApplicationModel[]>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `${this.url}/Select`,
        })
    );
}
