import React from "react";
import { SectionGrid, SectionGridItem } from "@bms/common-ui";
import { AssetType } from "@bms/common-services";
import { AssetContentList } from "../../AssetContentList";
import { ICommonAssetDetailsTabProps } from "./ICommonAssetDetailsTabProps";
import { AssetContentHtml } from "../../AssetContentHtml";

interface IContentTabProps extends ICommonAssetDetailsTabProps {
  refresh: () => Promise<void>;
}

export const ContentTab = ({ refresh, asset }: IContentTabProps) => {
  return (
    <>
      {asset?.AssetTypeCode === AssetType.Article ? (
        <SectionGrid>
          <SectionGridItem>
            <AssetContentHtml
              key={`AssetContentHtml-${asset?.Id}`}
              asset={asset}
              refreshAsset={refresh}
            />
          </SectionGridItem>
        </SectionGrid>
      ) : (
        <AssetContentList
          key={`AssetContent-${asset?.Id}`}
          asset={asset}
          refreshAsset={refresh}
        />
      )}
    </>
  );
};
