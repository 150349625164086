import React, { useMemo } from "react";
import { useDataLoader, PaymentsService } from "@bms/common-services";
import {
  Button,
  Icon,
  PageContent,
  PageHeader,
  Spin,
  IBreadcrumbProps,
} from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { useHistory, useParams, useLocation } from "react-router";
import { PaymentForm } from "../PaymentForm";
import { generateBreadcrumb } from "../../../../helpers";
import { ROUTES } from "../../constants";

const paymentsService = new PaymentsService().promisify();

export const PaymentDetails: React.FC = () => {
  const id = useParams<{ id: string }>()["id"];
  const { search, hash } = useLocation();
  const searchParams = new URLSearchParams(search);
  const paymentKey = useMemo(() => `${searchParams.get("paymentKey")}${hash}`, [
    searchParams,
    hash,
  ]);
  const { t } = useTranslation();
  const history = useHistory();

  const { data, loading, refresh } = useDataLoader({
    loader: () => paymentsService.getByKey(paymentKey),
    deps: [paymentKey],
  });

  const getBreadcrumbProps = (): IBreadcrumbProps => {
    return generateBreadcrumb([
      {
        path: `${ROUTES.PAYMENT_LIST}`,
        breadcrumbName: t("PAYMENT_LIST_TITLE"),
      },
      {
        path: `${ROUTES.PAYMENT_DETAILS}/${paymentKey}`,
        breadcrumbName: t("PAYMENT_DETAILS"),
      },
    ]);
  };

  return (
    <Spin spinning={loading}>
      <PageContent>
        <PageHeader
          title={t("PAYMENT_DETAILS")}
          onBack={history.goBack}
          breadcrumb={getBreadcrumbProps()}
          extra={
            <>
              <Button
                key="action-reload"
                icon={<Icon type="reload" />}
                onClick={refresh}
                title={t("BUTTON_REFRESH_TITLE")}
                shape="circle"
              />
            </>
          }
        />
        <PaymentForm payment={data} />
      </PageContent>
    </Spin>
  );
};
