import { cloneDeep } from "lodash";
import { RecordStatus } from "../../enums";
import { IUserModel } from "../../models";
import { StorageManager } from "../../services";
import * as AuthStore from "../auth";
import * as Consts from "./consts";
import { IUserState, UserActionsTypes } from "./types";

export const initialState: IUserState = {
  actionType: undefined,
  isLoadingData: false,
  isProcessingData: false,
  profile: StorageManager.getValue("user") || {},
  users: {
    Entities: [],
    TotalCount: 0,
    Filter: {
      PageNumber: 1,
      PageSize: 10,
      IncludeCount: true,
    },
  },
  usersBrowser: {
    Entities: [],
    TotalCount: 0,
    Filter: {
      PageNumber: 1,
      PageSize: 5,
      IncludeCount: true,
    },
  },
  profiles: {
    Entities: [],
    TotalCount: 0,
    Filter: {
      PageNumber: 1,
      PageSize: 10,
      IncludeCount: true,
    },
  },
};

export const userReducer = (
  state = initialState,
  action: UserActionsTypes | AuthStore.Types.AuthActionsTypes
): IUserState => {
  state.actionType = action.type;

  switch (action.type) {
    case Consts.GET_PROFILE:
    case Consts.GET_PROFILE_DETAILS:
    case Consts.UPDATE_PROFILE: {
      return {
        ...state,
        isLoadingData: true,
      };
    }
    case Consts.UPDATE_USER_BRANDING: {
      return state;
    }
    case Consts.GET_PROFILE_SUCCESS:
    case Consts.UPDATE_PROFILE_SUCCESS: {
      return {
        ...state,
        isLoadingData: false,
        profile: {
          ...action.payload.profile,
        },
      };
    }
    case Consts.GET_PROFILE_DETAILS_SUCCESS: {
      return {
        ...state,
        isLoadingData: false,
        profile: {
          ...action.payload,
        },
      };
    }
    case Consts.GET_PROFILE_DETAILS_FAILURE: {
      return {
        ...state,
        error: action.error,
      };
    }
    case Consts.GET_PUBLIC_PROFILE: {
      return {
        ...state,
        publicProfiles: {
          ...state.publicProfiles,
          [action.userId]: {
            ...(state?.publicProfiles?.[action.userId] || {}),
            isFetching: true,
          },
        },
      };
    }
    case Consts.GET_PUBLIC_PROFILE_SUCCESS: {
      return {
        ...state,
        publicProfiles: {
          ...state.publicProfiles,
          [action.userId]: {
            ...(state?.publicProfiles?.[action.userId] || {}),
            data: action.payload,
            isFetching: false,
          },
        },
      };
    }
    case Consts.GET_PUBLIC_PROFILE_FAILURE: {
      return {
        ...state,
        publicProfiles: {
          ...state.publicProfiles,
          [action.userId]: {
            ...(state?.publicProfiles?.[action.userId] || {}),
            error: action.error,
            isFetching: false,
          },
        },
      };
    }
    case Consts.UPDATE_USER_BRANDING_SUCCESS: {
      const newState = cloneDeep(state);

      if (!newState.profile) {
        newState.profile = { Id: -1 };
      }

      newState.profile.Branding = action.payload;

      return newState;
    }
    case Consts.GET_PROFILE_FAILURE:
    case Consts.UPDATE_PROFILE_FAILURE:
    case Consts.UPDATE_USER_BRANDING_FAILURE: {
      return {
        ...state,
      };
    }
    case AuthStore.Consts.SIGN_IN_ANONYMOUS_SUCCESS:
    case AuthStore.Consts.SIGN_IN_SUCCESS:
    case AuthStore.Consts.REGISTER_CONFIRM_EMAIL_SUCCESS: {
      return {
        ...state,
        profile: action.payload.user as IUserModel,
      };
    }
    case AuthStore.Consts.SIGN_OUT_SUCCESS:
      return {
        ...state,
        profile: undefined,
      };
    case Consts.GET_FAMILY_MEMBERS:
      return {
        ...state,
        children: {
          ...state.children,
          isFetching: true,
        },
      };
    case Consts.GET_FAMILY_MEMBERS_SUCCESS:
      return {
        ...state,
        children: {
          ...state.children,
          ...action.payload,
          isFetching: false,
        },
      };
    case Consts.GET_FAMILY_MEMBERS_FAILURE:
      return {
        ...state,
        children: {
          ...state.children,
          error: action.error,
          isFetching: false,
        },
      };
    case Consts.GET_AGE_RESTRICTIONS:
      return {
        ...state,
        ageRestrictions: {
          ...state.ageRestrictions,
          isFetching: true,
        },
      };
    case Consts.GET_AGE_RESTRICTIONS_SUCCESS:
      return {
        ...state,
        ageRestrictions: {
          ...state.ageRestrictions,
          data: action.payload.data,
          isFetching: false,
        },
      };
    case Consts.GET_AGE_RESTRICTIONS_FAILURE:
      return {
        ...state,
        ageRestrictions: {
          ...state.ageRestrictions,
          error: action.error,
          isFetching: false,
        },
      };
    case Consts.GET_USER_BRANDING_SETTINGS_SUCCESS: {
      return {
        ...state,
        brandingSettings: action.payload,
      };
    }
    case Consts.SEARCH_USER: {
      return {
        ...state,
        users: {
          ...state.users,
          IsLoading: true,
          Error: undefined,
        },
      };
    }
    case Consts.SEARCH_USER_SUCCESS: {
      return {
        ...state,
        users: {
          ...action.payload,
          IsLoading: false,
        },
      };
    }
    case Consts.SEARCH_USER_FAILURE: {
      return {
        ...state,
        users: {
          ...state.users,
          IsLoading: false,
          Error: action.error,
        },
      };
    }
    case Consts.GET_USER: {
      return {
        ...state,
        isLoadingData: true,
        user: undefined,
      };
    }
    case Consts.GET_USER_SUCCESS: {
      return {
        ...state,
        isLoadingData: false,
        user: action.payload,
      };
    }
    case Consts.GET_USER_FAILURE: {
      return {
        ...state,
        error: action.error,
        isLoadingData: false,
      };
    }
    case Consts.GET_USER_DETAILS: {
      return {
        ...state,
        isLoadingData: true,
        user: undefined,
      };
    }
    case Consts.GET_USER_DETAILS_SUCCESS: {
      return {
        ...state,
        isLoadingData: false,
        user: action.payload,
      };
    }
    case Consts.GET_USER_DETAILS_FAILURE: {
      return {
        ...state,
        error: action.error,
        isLoadingData: false,
      };
    }
    case Consts.DELETE_USER:
    case Consts.ANONYMIZE_USER: {
      return {
        ...state,
        isProcessingData: true,
      };
    }
    case Consts.DELETE_USER_SUCCESS:
    case Consts.ANONYMIZE_USER_SUCCESS: {
      return {
        ...state,
        user: undefined,
        isProcessingData: false,
      };
    }
    case Consts.DELETE_USER_FAILURE:
    case Consts.ANONYMIZE_USER_FAILURE: {
      return {
        ...state,
        error: action.error,
        isProcessingData: false,
      };
    }
    case Consts.INSERT_USER: {
      return {
        ...state,
        isProcessingData: true,
      };
    }
    case Consts.INSERT_USER_SUCCESS: {
      return {
        ...state,
        user: action.payload,
        isProcessingData: false,
      };
    }
    case Consts.INSERT_USER_FAILURE: {
      return {
        ...state,
        error: action.error,
        isProcessingData: false,
      };
    }
    case Consts.UPDATE_USER: {
      return {
        ...state,
        isProcessingData: true,
      };
    }
    case Consts.UPDATE_USER_SUCCESS: {
      return {
        ...state,
        user: action.payload,
        isProcessingData: false,
      };
    }
    case Consts.UPDATE_USER_FAILURE: {
      return {
        ...state,
        error: action.error,
        isProcessingData: false,
      };
    }
    case Consts.RESET_USER_PASSWORD: {
      return {
        ...state,
        resetUserPasswordResult: undefined,
        isProcessingData: true,
      };
    }
    case Consts.RESET_USER_PASSWORD_SUCCESS: {
      return {
        ...state,
        resetUserPasswordResult: action.payload,
        isProcessingData: false,
      };
    }
    case Consts.RESET_USER_PASSWORD_FAILURE: {
      return {
        ...state,
        error: action.error,
        isProcessingData: false,
      };
    }
    case Consts.CREATE_USER: {
      return {
        ...state,
        user: {
          Id: -1,
          RecordStatus: RecordStatus.Inserted,
        },
      };
    }
    case Consts.SELECT_PROFILES: {
      return {
        ...state,
        isLoadingData: true,
      };
    }
    case Consts.SELECT_PROFILES_SUCCESS: {
      return {
        ...state,
        isLoadingData: false,
        profiles: action.payload,
      };
    }
    case Consts.SELECT_PROFILES_FAILURE: {
      return {
        ...state,
        error: action.error,
        isLoadingData: false,
      };
    }

    case Consts.BROWSE_USERS: {
      return {
        ...state,
        usersBrowser: {
          ...state.usersBrowser,
          Entities: [],
          IsLoading: true,
        },
      };
    }
    case Consts.BROWSE_USERS_SUCCESS: {
      return {
        ...state,
        usersBrowser: {
          ...action.payload,
          IsLoading: false,
        },
      };
    }
    case Consts.BROWSE_USERS_FAILURE: {
      return {
        ...state,
        usersBrowser: {
          ...state.usersBrowser,
          IsLoading: false,
          Error: action.error,
        },
      };
    }
    case Consts.CHANGE_USER_PASSWORD: {
      return {
        ...state,
        resetUserPasswordResult: undefined,
        isProcessingData: true,
      };
    }
    case Consts.CHANGE_USER_PASSWORD_SUCCESS: {
      return {
        ...state,
        resetUserPasswordResult: action.payload,
        isProcessingData: false,
      };
    }
    case Consts.CHANGE_USER_PASSWORD_FAILURE: {
      return {
        ...state,
        error: action.error,
        isProcessingData: false,
      };
    }
    case Consts.RESEND_CONFIRMATION_EMAILS: {
      return {
        ...state,
        isProcessingData: true,
      };
    }
    case Consts.RESEND_CONFIRMATION_EMAILS_SUCCESS: {
      return {
        ...state,
        isProcessingData: false,
      };
    }
    case Consts.RESEND_CONFIRMATION_EMAILS_FAILURE: {
      return {
        ...state,
        error: action.error,
        isProcessingData: false,
      };
    }
    default:
      return state;
  }
};
