import { IAssetCollectionModel } from "@bms/common-services";
import {
  Button,
  Col,
  Form,
  Icon,
  IFormValues,
  Link,
  Row,
} from "@bms/common-ui";
import React from "react";
import { WithTranslation } from "react-i18next";
import { ROUTES } from "../../../../../../constants";
import { AssetCollectionBrowserModal } from "../../../../../Asset/components/AssetCollectionBrowserModal";
import { EpgComponentPropertyModel } from "../../models";
import {
  ComponentPropertiesBase,
  IComponentPropertiesBaseProps,
  IComponentPropertiesBaseState,
} from "../ComponentPropertiesBase";
import "./EpgComponentProperties.scss";

export interface IEpgComponentPropertiesStateProps {
  isProcessingData: boolean;
}

export interface IEpgComponentPropertiesDispatchProps {}

export interface IEpgComponentPropertiesOwnProps {}

export interface IEpgComponentPropertiesProps
  extends IEpgComponentPropertiesStateProps,
    IEpgComponentPropertiesDispatchProps,
    IEpgComponentPropertiesOwnProps,
    IComponentPropertiesBaseProps,
    WithTranslation {}

export interface IEpgComponentPropertiesState
  extends IComponentPropertiesBaseState {
  assetCollectionsBrowserVisible: boolean;
  translationsBrowserVisible: boolean;
}

export class EpgComponentProperties extends ComponentPropertiesBase<
  IEpgComponentPropertiesProps,
  IEpgComponentPropertiesState,
  EpgComponentPropertyModel
> {
  public static defaultProps = {
    isProcessingData: false,
  };

  public state: Readonly<IEpgComponentPropertiesState> = {
    assetCollectionsBrowserVisible: false,
    translationsBrowserVisible: false,
  };

  getPropertiesModel(): EpgComponentPropertyModel {
    const { component } = this.props;
    const properties = new EpgComponentPropertyModel();
    properties.init(component);

    return properties;
  }

  public onFinish = (values: IFormValues) => {
    const { component } = this.props;

    if (!component) {
      return;
    }

    component.Name = values.Name;
  };

  public onSourcePropertyCancel = () => {
    this.setState({ assetCollectionsBrowserVisible: false });
  };

  public onSourcePropertySelect = (row: IAssetCollectionModel) => {
    this.properties.SourceId = row.Id;
    this.properties.SourceName = row.Name;
    this.onPropertyChange("SourceId");
    this.onPropertyChange("SourceName");
    this.setState({ assetCollectionsBrowserVisible: false });
  };

  public onSourcePropertyClear = () => {
    const { component, onComponentChange } = this.props;

    this.properties.SourceId = undefined;
    this.properties.SourceName = undefined;
    this.properties.deleteProperty("SourceId", component);
    this.properties.deleteProperty("SourceName", component);
    this.properties.deleteProperty("SourceType", component);

    if (onComponentChange) {
      onComponentChange(component);
    }
  };

  public renderSourceProperty = () => {
    const { t } = this.props;
    const { assetCollectionsBrowserVisible } = this.state;

    let sourceValueView: React.ReactElement = this.properties.SourceId ? (
      <label>
        <Link
          to={`${ROUTES.ASSET_COLLECTION_DETAILS}/${this.properties.SourceId}`}
        >
          {this.properties.SourceName || this.properties.SourceId}
        </Link>
      </label>
    ) : (
      <label>{t("COMPONENT_PROPERTIES__SOURCE_PLACEHOLDER")}</label>
    );

    return (
      <Form.Item
        name="SourceId"
        label={t("COMPONENT_PROPERTIES__SOURCE")}
        initialValue={{
          key: `${this.properties.SourceId}`,
          label: this.properties.SourceName,
        }}
      >
        <Row gutter={8}>
          <Col span={18}>
            <Form.Item>{sourceValueView}</Form.Item>
            <AssetCollectionBrowserModal
              visible={assetCollectionsBrowserVisible}
              onCancel={this.onSourcePropertyCancel}
              onSelect={this.onSourcePropertySelect}
            />
          </Col>
          <Col span={6} style={{ textAlign: "right" }}>
            {this.properties.SourceId && (
              <Button
                icon={<Icon type="delete" />}
                onClick={this.onSourcePropertyClear}
                style={{ marginRight: "8px" }}
              />
            )}
            <Button
              icon={<Icon type="edit" />}
              onClick={() => {
                this.setState({ assetCollectionsBrowserVisible: true });
              }}
            />
          </Col>
        </Row>
      </Form.Item>
    );
  };

  public render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 8 },
        lg: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 16 },
        lg: { span: 18 },
      },
    };

    return (
      <Form
        name="EpgComponentProperties"
        {...formItemLayout}
        onFinish={this.onFinish}
      >
        <Row direction="column" justify="space-between" className="full-height">
          <Col>{this.renderSourceProperty()}</Col>
        </Row>
      </Form>
    );
  }
}
