import {
  IAuthRequestModel,
  IAuthResponseModel,
  IChangePasswordModel,
  IConfirmAccountWithPasswordModel,
  IErrorModel,
  IForgotPasswordModel,
  IRegisterConfirmEmailModel,
  IRegisterRequestEmailModel,
  IResetForgotPasswordModel,
  IResetPasswordModel,
  ISetPasswordModel,
  ITokenModel,
  IUserInfoModel,
} from "../../models";
import * as Consts from "./consts";
import {
  AuthActionsTypes,
  IChangePasswordAction,
  IChangePasswordFailureAction,
  IChangePasswordSuccessAction,
  IClearError,
  IConfirmAccountWithPasswordAction,
  IForgotPasswordAction,
  IForgotPasswordFailureAction,
  IForgotPasswordSuccessAction,
  IRegisterConfirmEmailAction,
  IRegisterConfirmEmailFailureAction,
  IRegisterConfirmEmailSuccessAction,
  IRegisterEmailAction,
  IRegisterEmailFailureAction,
  IRegisterEmailSuccessAction,
  IResetForgotPasswordAction,
  IResetForgotPasswordFailureAction,
  IResetForgotPasswordSuccessAction,
  ISignInAction,
  ISignInAnonymousAction,
  ISignInAnonymousFailureAction,
  ISignInAnonymousSuccessAction,
  ISignInFailureAction,
  ISignInSuccessAction,
  ISignOutAction,
  ISignOutFailureAction,
  ISignOutSuccessAction,
  IValidateTokenAction,
  IValidateTokenFailureAction,
  IValidateTokenSuccessAction,
} from "./types";

export const signIn = (data: IAuthRequestModel): ISignInAction => {
  return {
    data,
    type: Consts.SIGN_IN,
  };
};

export const signInSuccess = (
  user?: IUserInfoModel,
  session?: ITokenModel
): ISignInSuccessAction => {
  return {
    payload: {
      session,
      user,
    },
    type: Consts.SIGN_IN_SUCCESS,
  };
};

export const signInFailure = (error?: IErrorModel): ISignInFailureAction => {
  return {
    error,
    type: Consts.SIGN_IN_FAILURE,
  };
};

export const signInAnonymous = (
  data: IAuthRequestModel = {}
): ISignInAnonymousAction => {
  return {
    data,
    type: Consts.SIGN_IN_ANONYMOUS,
  };
};

export const signInAnonymousSuccess = (
  user?: IUserInfoModel,
  session?: ITokenModel
): ISignInAnonymousSuccessAction => {
  return {
    payload: {
      session,
      user,
    },
    type: Consts.SIGN_IN_ANONYMOUS_SUCCESS,
  };
};

export const signInAnonymousFailure = (
  error?: IErrorModel
): ISignInAnonymousFailureAction => {
  return {
    error,
    type: Consts.SIGN_IN_ANONYMOUS_FAILURE,
  };
};

export const registerEmail = (
  data: IRegisterRequestEmailModel,
  redirectUrl?: string
): IRegisterEmailAction => {
  return {
    data,
    redirectUrl,
    type: Consts.REGISTER_EMAIL,
  };
};

export const registerEmailSuccess = (
  redirectUrl?: string
): IRegisterEmailSuccessAction => {
  return {
    payload: {
      redirectUrl,
    },
    type: Consts.REGISTER_EMAIL_SUCCESS,
  };
};

export const registerEmailFailure = (
  error?: IErrorModel
): IRegisterEmailFailureAction => {
  return {
    error,
    type: Consts.REGISTER_EMAIL_FAILURE,
  };
};

export const registerConfirmEmail = (
  data: IRegisterConfirmEmailModel,
  redirectUrl?: string
): IRegisterConfirmEmailAction => {
  return {
    data,
    redirectUrl,
    type: Consts.REGISTER_CONFIRM_EMAIL,
  };
};

export const registerConfirmAccountWithPassword = (
  data: IConfirmAccountWithPasswordModel,
  redirectUrl?: string
): IConfirmAccountWithPasswordAction => {
  return {
    data,
    redirectUrl,
    type: Consts.CONFIRM_ACCOUNT_WITH_PASSWORD,
  };
};

export const registerConfirmEmailSuccess = (
  user?: IUserInfoModel,
  session?: ITokenModel,
  redirectUrl?: string
): IRegisterConfirmEmailSuccessAction => {
  return {
    payload: {
      user,
      session,
      redirectUrl,
    },
    type: Consts.REGISTER_CONFIRM_EMAIL_SUCCESS,
  };
};

export const registerConfirmEmailFailure = (
  error?: IErrorModel
): IRegisterConfirmEmailFailureAction => {
  return {
    error,
    type: Consts.REGISTER_CONFIRM_EMAIL_FAILURE,
  };
};

export const signOut = (): ISignOutAction => {
  return {
    type: Consts.SIGN_OUT,
  };
};

export const signOutSuccess = (): ISignOutSuccessAction => {
  return {
    type: Consts.SIGN_OUT_SUCCESS,
  };
};

export const signOutFailure = (error?: IErrorModel): ISignOutFailureAction => {
  return {
    error,
    type: Consts.SIGN_OUT_FAILURE,
  };
};

export const refreshToken = (): AuthActionsTypes => {
  return {
    type: Consts.REFRESH_TOKEN,
  };
};

export const refreshTokenSuccess = (
  session?: ITokenModel
): AuthActionsTypes => {
  return {
    payload: {
      session,
    },
    type: Consts.REFRESH_TOKEN_SUCCESS,
  };
};

export const refreshTokenFailure = (error?: IErrorModel): AuthActionsTypes => {
  return {
    error,
    type: Consts.REFRESH_TOKEN_FAILURE,
  };
};

export const setPassword = (data: ISetPasswordModel): AuthActionsTypes => {
  return {
    type: Consts.SET_PASSWORD,
    payload: data,
  };
};

export const setPasswordSuccess = (session?: ITokenModel): AuthActionsTypes => {
  return {
    payload: {
      session,
    },
    type: Consts.SET_PASSWORD_SUCCESS,
  };
};

export const setPasswordFailure = (error?: IErrorModel): AuthActionsTypes => {
  return {
    error,
    type: Consts.SET_PASSWORD_FAILURE,
  };
};

export const resetPassword = (data: IResetPasswordModel): AuthActionsTypes => {
  return {
    payload: data,
    type: Consts.RESET_PASSWORD,
  };
};

export const resetPasswordSuccess = (data: boolean): AuthActionsTypes => {
  return {
    payload: data,
    type: Consts.RESET_PASSWORD_SUCCESS,
  };
};

export const resetPasswordFailure = (error?: IErrorModel): AuthActionsTypes => {
  return {
    error,
    type: Consts.RESET_PASSWORD_FAILURE,
  };
};

export const changePassword = (
  data: IChangePasswordModel
): IChangePasswordAction => {
  return {
    payload: data,
    type: Consts.CHANGE_PASSWORD,
  };
};

export const changePasswordSuccess = (
  data: IAuthResponseModel
): IChangePasswordSuccessAction => {
  return {
    payload: data,
    type: Consts.CHANGE_PASSWORD_SUCCESS,
  };
};

export const changePasswordFailure = (
  error?: IErrorModel
): IChangePasswordFailureAction => {
  return {
    error,
    type: Consts.CHANGE_PASSWORD_FAILURE,
  };
};

export const validateToken = (token: string): IValidateTokenAction => {
  return {
    type: Consts.VALIDATE_TOKEN,
    payload: {
      token,
    },
  };
};

export const validateTokenSuccess = (): IValidateTokenSuccessAction => {
  return {
    type: Consts.VALIDATE_TOKEN_SUCCESS,
  };
};

export const validateTokenFailure = (
  error: IErrorModel
): IValidateTokenFailureAction => {
  return {
    type: Consts.VALIDATE_TOKEN_FAILURE,
    error,
  };
};

export const forgotPassword = (
  data: IForgotPasswordModel
): IForgotPasswordAction => {
  return {
    type: Consts.RESET_PASSWORD_LINK,
    payload: data,
  };
};

export const forgotPasswordSuccess = (
  data: boolean
): IForgotPasswordSuccessAction => {
  return {
    type: Consts.RESET_PASSWORD_LINK_SUCCESS,
    payload: data,
  };
};

export const forgotPasswordFailure = (
  error: IErrorModel
): IForgotPasswordFailureAction => {
  return {
    type: Consts.RESET_PASSWORD_LINK_FAILURE,
    error,
  };
};

export const resetForgotPassword = (
  data: IResetForgotPasswordModel
): IResetForgotPasswordAction => {
  return {
    type: Consts.RESET_PASSWORD,
    payload: data,
  };
};

export const resetForgotPasswordSuccess = (
  data: boolean
): IResetForgotPasswordSuccessAction => {
  return {
    type: Consts.RESET_PASSWORD_SUCCESS,
    payload: data,
  };
};

export const resetForgotPasswordFailure = (
  error?: IErrorModel
): IResetForgotPasswordFailureAction => {
  return {
    type: Consts.RESET_PASSWORD_FAILURE,
    error,
  };
};

export const clearError = (): IClearError => {
  return {
    type: Consts.CLEAR_ERROR,
  };
};

export const Actions = {
  signInAnonymous,
  signInAnonymousSuccess,
  signInAnonymousFailure,
  resetPassword,
  setPassword,
  signIn,
  signInSuccess,
  signInFailure,
  signOut,
  signOutSuccess,
  signOutFailure,
  registerEmail,
  registerEmailSuccess,
  registerEmailFailure,
  registerConfirmEmail,
  registerConfirmEmailSuccess,
  registerConfirmEmailFailure,
  registerConfirmAccountWithPassword,
  refreshToken,
  refreshTokenSuccess,
  refreshTokenFailure,
  changePassword,
  changePasswordSuccess,
  changePasswordFailure,
  forgotPassword,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  resetForgotPassword,
  resetForgotPasswordSuccess,
  resetForgotPasswordFailure,
  validateToken,
  validateTokenSuccess,
  validateTokenFailure,
  clearError,
};
