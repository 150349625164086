import { Observable, Observer } from "rxjs";
import { HTTP_METHOD } from "../../constants/http";
import {
  IUserAssetPurchasesListModel,
  IUserAssetPurchasesSearchFilterModel,
} from "../../models";
import { AxiosSubscriber } from "../AxiosSubscriber";
import { PromisifiableBase } from "../Base";

export class UserAssetPurchasesService extends PromisifiableBase {
  get url(): string {
    return "/UserAssetPurchases";
  }

  public search = (
    filter: IUserAssetPurchasesSearchFilterModel
  ): Observable<IUserAssetPurchasesListModel> =>
    new Observable(
      (observer: Observer<IUserAssetPurchasesListModel>) =>
        new AxiosSubscriber(observer, {
          data: filter,
          method: HTTP_METHOD.POST,
          url: `${this.url}/Search`,
        })
    );

  public cancelSubscription = (
    userSubscriptionId: number
  ): Observable<IUserAssetPurchasesListModel> =>
    new Observable(
      (observer: Observer<IUserAssetPurchasesListModel>) =>
        new AxiosSubscriber(observer, {
          data: {
            UserSubscriptionId: userSubscriptionId,
          },
          method: HTTP_METHOD.POST,
          url: `${this.url}/CancelSubscription`,
        })
    );

  public reactivateSubscription = (
    userSubscriptionId: number
  ): Observable<IUserAssetPurchasesListModel> =>
    new Observable(
      (observer: Observer<IUserAssetPurchasesListModel>) =>
        new AxiosSubscriber(observer, {
          data: {
            UserSubscriptionId: userSubscriptionId,
          },
          method: HTTP_METHOD.POST,
          url: `${this.url}/ReactivateSubscription`,
        })
    );
}
