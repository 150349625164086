import {
  AnalyticsService,
  IAnalyticsSalesRevenueFilterModel,
  useDataLoader,
} from "@bms/common-services";
import { useState } from "react";
import { useAppFeedback } from "@bms/common-ui";

const analyticsService = new AnalyticsService().promisify();

export const useSalesRevenueDataProvider = () => {
  const { notification } = useAppFeedback();

  const [filter, __setFilter] = useState<IAnalyticsSalesRevenueFilterModel>({});

  const {
    debounceAwaiting,
    refresh: refreshData,
    loading,
    data,
  } = useDataLoader({
    loader: () => analyticsService.getSalesRevenue(filter),
    onError: (error) =>
      notification.error({
        message: error.Title,
        description: error.Message,
      }),
    deps: [filter],
  });

  const setFilter = <T>(
    property: keyof IAnalyticsSalesRevenueFilterModel,
    value: T
  ) => __setFilter({ ...filter, [property]: value });

  const resetFilter = () => __setFilter({});

  return {
    data,
    filter,
    loading,
    debounceAwaiting,
    refreshData,
    setFilter,
    resetFilter,
  };
};
