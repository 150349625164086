import { Observable, Observer } from "rxjs";
import { HTTP_METHOD } from "../constants/http";
import {
  ResetPasswordLinkRequestModel,
  ResetPasswordRequestModel,
} from "../models";
import { AxiosSubscriber } from "./AxiosSubscriber";

export class ResetPasswordService {
  public resetPassword = (
    data: ResetPasswordRequestModel
  ): Observable<boolean> =>
    new Observable(
      (observer: Observer<boolean>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: `/Authorization/ResetPassword`,
        })
    );

  public resetPasswordLink = (
    data: ResetPasswordLinkRequestModel
  ): Observable<boolean> =>
    new Observable(
      (observer: Observer<boolean>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: `/Authorization/ForgotPassword`,
        })
    );
}
