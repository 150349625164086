import {
  AnalyticsAggregationPeriodType,
  IAnalyticsUserPlatformRatingModel,
  IAnalyticsUserPlatformRatingResultsModel,
} from "@bms/common-services";
import { IStackedBarChart2DataSet } from "@bms/common-ui";
import { IRatingAppWithColorsModel } from "../RatingApp";

export interface IRatingGroupedData {
  [key: string]: IAnalyticsUserPlatformRatingResultsModel[];
}

export type TGroupBy = "platform";
export type TUnit = "count" | "rating";

export const getLabels = (data: IAnalyticsUserPlatformRatingModel) => {
  return Array.from(new Set(data.Results.map((res) => res.Period)));
};

export const groupDataBy = (
  data: IAnalyticsUserPlatformRatingModel,
  groupBy: keyof IAnalyticsUserPlatformRatingResultsModel
) => {
  const result: IRatingGroupedData = {};
  data.Results?.forEach((res) => {
    if (!result[res[groupBy]]) {
      result[res[groupBy]] = [];
    }
    result[res[groupBy]].push(res);
  });
  return result;
};

export const colorOfRating = (
  rating: IAnalyticsUserPlatformRatingResultsModel,
  ratingsListWithColors: IRatingAppWithColorsModel[] | undefined
) =>
  ratingsListWithColors?.find((obj) => {
    return obj.PlatformName === rating?.PlatformName;
  });

export const prepareChartData = (
  data: IAnalyticsUserPlatformRatingModel,
  unit: TUnit = "rating",
  ratingsListWithColors: IRatingAppWithColorsModel[] | undefined,
  aggregationPeriod: AnalyticsAggregationPeriodType = AnalyticsAggregationPeriodType.YEAR
) => {
  const labels = getLabels(data);
  const groupedBy = groupDataBy(data, "PlatformName");
  const datasets: IStackedBarChart2DataSet<number>[] = [];

  for (const key in groupedBy) {
    const dataset: IStackedBarChart2DataSet<number> = {
      data: [],
      backgroundColor: undefined,
    };

    labels.forEach((label, index) => {
      const ratingApp = groupedBy[key].find((val) => val.Period === label);
      if (ratingApp) {
        const ratingWithColor = colorOfRating(ratingApp, ratingsListWithColors);
        if (!dataset.label) {
          dataset.label = ratingApp.PlatformName;
        }
        dataset.data[index] = groupedBy[key]
          .filter((row) => row.Period === label)
          .reduce(
            (previousValue, currentValue) =>
              (previousValue =
                previousValue +
                currentValue[unit === "count" ? "Count" : "Rating"]),
            0
          );

        dataset.backgroundColor = ratingWithColor?.Color;
      } else {
        dataset.data[index] = null;
      }
    });
    datasets.push(dataset);
  }

  return {
    labels: labels,
    datasets,
  };
};
