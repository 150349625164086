import { Observable, Observer } from "rxjs";
import { HTTP_METHOD } from "../../constants/http";
import { AxiosSubscriber } from "../AxiosSubscriber";
import { INotificationRecipientTypeModel } from "../../models";
import { PromisifiableBase } from "../Base/PromisifiableBase";

export class NotificationRecipientTypesService extends PromisifiableBase {
  get url(): string {
    return "/NotificationRecipientTypes";
  }

  public select = (): Observable<INotificationRecipientTypeModel[]> =>
    new Observable(
      (observer: Observer<INotificationRecipientTypeModel[]>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `${this.url}/Select`,
        })
    );
}
