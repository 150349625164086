import {
  useDataLoader,
  AssetPurchasePeriodTypeService,
} from "@bms/common-services";
import { useAppFeedback } from "@bms/common-ui";

const assetService = new AssetPurchasePeriodTypeService().promisify();

export const useAssetPurchasePeriodTypeDataProvider = () => {
  const { notification } = useAppFeedback();

  const { data, loading } = useDataLoader({
    loader: () => assetService.select(),
    onError: (error) =>
      notification.error({
        message: error.Title,
        description: error.Message,
      }),
    debounce: 500,
    deps: [],
  });
  return {
    purchasePeriodTypes:
      data?.map((asset) => ({
        key: asset.Code,
        value: asset.Code,
        label: asset.DisplayName,
      })) || [],
    loading,
  };
};
