import { Tag } from "@bms/common-ui";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AssetStore } from "@bms/common-services";

interface IAssetTypeTagProps {
  type?: string;
}

export const AssetTypeTag: React.FC<IAssetTypeTagProps> = ({ type }) => {
  const dispatch = useDispatch();
  const {
    assetTypes: { data, isFetching },
  } = useSelector(AssetStore.Selectors.assetSelector);

  useEffect(() => {
    if (!data && !isFetching) {
      dispatch(AssetStore.Actions.getAssetTypes());
    }
  }, [dispatch, data, isFetching]);

  return (
    <Tag colorRotate={data?.findIndex(({ Code }) => Code === type)}>{type}</Tag>
  );
};
