import * as Consts from "./consts";
import { SourceEntitiesActionsTypes, ISourceEntitiesState } from "./types";

export const initialState: ISourceEntitiesState = [];

export const sourceEntitiesReducer = (
  state = initialState,
  action: SourceEntitiesActionsTypes
): ISourceEntitiesState => {
  switch (action.type) {
    case Consts.GET_SOURCE_ENTITIES_SUCCESS: {
      return [
        ...state,
        ...action.payload 
      ];
    }
    default:
      return state;
  }
};
