import { ICommonAppState } from "@bms/common-services";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
  ApplicationSectionMenuItemProperties as ApplicationSectionMenuItemPropertiesDefinition,
  IApplicationSectionMenuItemPropertiesDispatchProps,
  IApplicationSectionMenuItemPropertiesOwnProps,
  IApplicationSectionMenuItemPropertiesStateProps,
  IApplicationSectionMenuItemPropertiesState,
} from "./ApplicationSectionMenuItemProperties";

const mapStateToProps = (
  state: ICommonAppState
): IApplicationSectionMenuItemPropertiesStateProps => {
  return {
    isProcessingData: state.applicationConfiguration.isProcessingData,
    categories: state.asset.assetCategories,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch
): IApplicationSectionMenuItemPropertiesDispatchProps => ({});

export const ApplicationSectionMenuItemProperties = connect<
  IApplicationSectionMenuItemPropertiesStateProps,
  IApplicationSectionMenuItemPropertiesDispatchProps,
  IApplicationSectionMenuItemPropertiesOwnProps,
  ICommonAppState
>(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ApplicationSectionMenuItemPropertiesDefinition));
