import {
  Button,
  Form,
  Icon,
  PageContent,
  PageHeader,
  Spin,
  TabPane,
  Tabs,
  useAppFeedback,
  useSearchParamsTabs,
  useSyncedState,
} from "@bms/common-ui";
import { BreadcrumbProps } from "antd/lib/breadcrumb";
import { generateBreadcrumb } from "../../../../helpers";
import { ROUTES } from "../../constants";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TabGeneral, TabContent, TabRecipients } from "./tabs";
import { useHistory, useParams } from "react-router";
import {
  INotificationModel,
  NotificationsService,
  RecordStatus,
  useDataLoader,
  useServiceCaller,
} from "@bms/common-services";
import { NotificationSendModal } from "../NotificationSendModal";

const userNotificationService = new NotificationsService().promisify();

export const NotificationDetails = () => {
  const { t } = useTranslation();
  const { modal, notification } = useAppFeedback();
  const [form] = Form.useForm();
  const history = useHistory();

  const [activeTabKey] = useSearchParamsTabs("GENERAL");
  const { id } = useParams<{ id: string }>();

  const [isSendModalVisible, setSendModalVisible] = useState<boolean>(false);

  useEffect(() => {
    form.resetFields();
  }, []);

  const { data, loading, refresh } = useDataLoader({
    loader: () => userNotificationService.get(+id),
    deps: [id],
  });

  const [notificationData, setNotification] = useSyncedState(() => data, [
    data,
  ]);

  const [updateNotification] = useServiceCaller(
    async (data: INotificationModel) => {
      const result = await userNotificationService.update({
        ...data,
        RecordStatus: RecordStatus.Updated,
        Id: +id,
        Guid: notificationData?.Guid,
      });
      if (result.ok) {
        setNotification(result.data);
        notification.success({
          message: t("NOTIFICATION_UPDATE_SUCCESSFULLY"),
        });
      } else {
        notification.error({
          message: t("NOTIFICATION_UPDATE_FAILURE"),
        });
      }
      return result.ok;
    },
    [notificationData?.RowVersion]
  );

  const [deleteNotification] = useServiceCaller(async () => {
    if (!notificationData) {
      return null;
    }

    const result = await userNotificationService.delete(notificationData);
    if (result.ok) {
      notification.success({
        message: t("NOTIFICATION_DELETE_SUCCESSFULLY"),
      });
      history.push(ROUTES.NOTIFICATION_LIST);
    } else {
      notification.error({
        message: t("NOTIFICATION_DELETE_FAILURE"),
      });
    }
    return result.ok;
  }, [notificationData?.RowVersion]);

  const getBreadcrumbProps = (): BreadcrumbProps => {
    return generateBreadcrumb([
      {
        path: `${ROUTES.NOTIFICATION_LIST}`,
        breadcrumbName: t("NOTIFICATIONS"),
      },
      {
        path: `${ROUTES.NOTIFICATION_DETAILS}/${id}`,
        breadcrumbName: notificationData?.NamePreview ?? t("NOTIFICATIONS"),
      },
    ]);
  };

  const getExtraButtons = (
    <>
      {notificationData?.Status === "DRAFT" && (
        <Button
          key="import"
          shape="circle"
          type="primary"
          icon={<Icon type="send" />}
          onClick={() => setSendModalVisible(true)}
          title={t("BUTTON_SEND")}
        />
      )}
      <Button
        key="action-reload"
        icon={<Icon type="reload" />}
        onClick={refresh}
        title={t("BUTTON_REFRESH_TITLE")}
        shape="circle"
      />
      <Button
        onClick={() => onDeleteClick()}
        title={t("NOTIFICATION_DELETE")}
        shape="circle"
        disabled={notificationData?.Status !== "DRAFT"}
        icon={<Icon type="delete" />}
        danger
      />
    </>
  );

  const onDeleteClick = () => {
    if (!notificationData) {
      return;
    }

    modal.confirm({
      title: t("COMMON_DELETE"),
      content: t("DELETE_NOTIFICATION_MESSAGE", {
        notificationName: notificationData.Name,
      }),
      okText: t("COMMON_DELETE"),
      cancelText: t("COMMON_CANCEL"),
      onOk: deleteNotification,
    });
  };

  const onSendSuccess = async () => {
    setSendModalVisible(false);
    await refresh();
  };

  return (
    <Spin spinning={loading}>
      <PageContent>
        <PageHeader
          breadcrumb={getBreadcrumbProps()}
          title={t("NOTIFICATION_DETAILS")}
          extra={getExtraButtons}
        />
        <Tabs activeKey={activeTabKey} destroyInactiveTabPane={false}>
          <TabPane key="GENERAL" tab={t("NOTIFICATION_GENERAL")}>
            {notificationData && (
              <TabGeneral
                notification={notificationData}
                onSubmit={updateNotification}
              />
            )}
          </TabPane>
          <TabPane key="CONTENT" tab={t("NOTIFICATION_CONTENT")}>
            {notificationData && (
              <TabContent
                notification={notificationData}
                onSubmit={updateNotification}
              />
            )}
          </TabPane>
          <TabPane key="RECIPIENTS" tab={t("NOTIFICATION_RECIPIENTS")}>
            {notificationData && (
              <TabRecipients
                notification={notificationData}
                onSubmit={updateNotification}
              />
            )}
          </TabPane>
        </Tabs>
        <NotificationSendModal
          visible={isSendModalVisible}
          notification={notificationData}
          onCancel={() => setSendModalVisible(false)}
          onSuccess={onSendSuccess}
        />
      </PageContent>
    </Spin>
  );
};
