import React from "react";
import { Dropdown as AntDropdown } from "antd";
import { DropDownProps as AntDropdownProps } from "antd/lib/dropdown";

export interface IDropdownProps extends AntDropdownProps {
  children: React.ReactNode;
}

export class Dropdown extends React.PureComponent<IDropdownProps> {
  public render() {
    const { children, ...rest } = this.props;
    return <AntDropdown {...rest}>{children}</AntDropdown>;
  }
}
