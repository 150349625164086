import { ICommonAppState } from "@bms/common-services";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
  IEpgComponentPropertiesDispatchProps,
  IEpgComponentPropertiesOwnProps,
  IEpgComponentPropertiesStateProps,
  EpgComponentProperties as EpgComponentPropertiesDefinition,
} from "./EpgComponentProperties";

const mapStateToProps = (
  state: ICommonAppState
): IEpgComponentPropertiesStateProps => {
  return {
    isProcessingData: state.applicationConfiguration.isProcessingData,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch
): IEpgComponentPropertiesDispatchProps => ({});

export const EpgComponentProperties = connect<
  IEpgComponentPropertiesStateProps,
  IEpgComponentPropertiesDispatchProps,
  IEpgComponentPropertiesOwnProps,
  ICommonAppState
>(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(EpgComponentPropertiesDefinition));
