import {AssetImageType, CellType, IMediaImageModel} from "@bms/common-services";
import { ImageWithPlaceholder, Title } from "@bms/common-ui";
import cx from "classnames";
import React from "react";
import { IListItemComponentPreviewProps } from "./ListItemComponentPreview";
import "./ListItemHighlightComponentPreview.scss";

export interface IListItemHighlightComponentPreviewProps
  extends IListItemComponentPreviewProps {}

export class ListItemHighlightComponentPreview extends React.Component<
  IListItemHighlightComponentPreviewProps
> {
  public static defaultProps: IListItemHighlightComponentPreviewProps = {
    isPlaceholder: false,
    width: 0,
  };

  public render() {
    const { asset, isHovered, placeholderSrc } = this.props;

    let frameImageUrl: string | undefined = "";
    let title: string | undefined = "";

    if (asset && asset.Images) {
      let assetFrameImage = asset.Images.find(
        (row: IMediaImageModel) =>
          row.ImageTypeCode === AssetImageType.Highlights
      );

      if (!assetFrameImage) {
        assetFrameImage = asset.Images.find(
          (row: IMediaImageModel) =>
            row.ImageTypeCode === AssetImageType.Frame
        );
      }

      if (assetFrameImage) {
        frameImageUrl = assetFrameImage.Url;
        title = asset.Title;
      }
    }


    // width from props is calculated by visibleItems amount.
    // In this case width is harcdoded
    const width = 1260

    const imageContainerStyle: React.CSSProperties = {
      width: `${width}px`,
      height: `${Math.round((width) / 3.75)}px`,
    };

    return (
      <div
        className={cx("ListItemHighlightComponentPreview", {
          "ListItemHighlightComponentPreview--active": isHovered,
        })}
      >
        <div style={imageContainerStyle}>
        <ImageWithPlaceholder
          animated={true}
          imageSrc={frameImageUrl}
          imageContainerClassName="ListItemHighlightComponentPreview--image"
          placeholderSrc={placeholderSrc}
        />
        </div>

        <div className="ListItemHighlightComponentPreview--info">
          <Title className="title" level={2}>
            {title}
          </Title>
        </div>
      </div>
    );
  }
}
