import { ROUTES_COMMON } from "@bms/common-services";

export const ROUTES = {
  ...ROUTES_COMMON,
  DASHBOARD: "/dashboard",
  ANALYTICS: "/analytics",
  USER: "/user",
  USER_LIST: "/user/list",
  USER_DETAILS: "/user/details",
  USER_CREATE: "/user/create",
  PROFILE: "/management/profile",
  QUIZ: "/quiz",
  QUIZ_LIST: "/quiz/list",
  QUIZ_DETAILS: "/quiz/details",
  QUIZ_STATUS_LIST: "/quiz/status/list",
  QUIZ_CLASSES_LIST: "/quiz/classes/list",
  QUIZ_CATEGORIES_LIST: "/quiz/categories/list",
  ASSET_COLLECTION_DETAILS: "/asset-collection/details",
  ASSET_CATEGORIES: "/dictionaries",
  SETTINGS: "/settings",
  DICTIONARIES: "/dictionaries",
  DICTIONARY_APPLICATIONS: "/dictionary/applications",
  DICTIONARY_LANGUAGES: "/dictionary/languages",
  DICTIONARY_PLATFORMS: "/dictionary/platforms",
  DICTIONARY_VIDEO_AGE_RESTRICTIONS: "/dictionary/video-age-restrictions",
  DICTIONARY_COUNTRIES_LIST: "/dictionary/countries",
};
