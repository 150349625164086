import React from "react";
import { Radio as AntRadio } from "antd";
import {
  RadioGroupProps as AntRadioGroupProps,
  RadioProps,
} from "antd/lib/radio";
import { Trans } from "react-i18next";
import { map } from "lodash";

import "./RadioGroup.scss";

export interface IRadioProps extends RadioProps {
  name: string;
}

export interface IRadioGroupProps extends AntRadioGroupProps {
  data: ArrayLike<IRadioProps>;
  buttons?: boolean;
}

export const RadioGroup = React.forwardRef((props: IRadioGroupProps, ref) => {
  const { data, ...rest } = props;
  const Component = props.buttons ? AntRadio.Button : AntRadio;
  const renderRadio = () => {
    return map(data, (radio) => (
      <Component key={radio.value} {...radio}>
        <Trans i18nKey={`radioGroup.${radio.name}`}>{radio.name}</Trans>
      </Component>
    ));
  };

  return <AntRadio.Group {...rest}>{renderRadio()}</AntRadio.Group>;
});
