import React from "react";
import { Modal as AntModal } from "antd";
import { ModalProps as AntModalProps } from "antd/lib/modal";
import cx from "classnames";

import "./Modal.scss";

export interface IModalProps extends AntModalProps {
  children: React.ReactNode;
  processing?: boolean;
  preview?: boolean;
}

export class Modal extends React.PureComponent<IModalProps> {
  public render() {
    const {
      children,
      processing,
      visible,
      preview,
      centered,
      style,
      width,
      maskStyle,
      ...rest
    } = this.props;
    return (
      <>
        {visible && processing && <div className="processing-overlay-mask" />}
        <AntModal
          {...rest}
          visible={visible}
          className={cx(
            processing && "modal-processing-overlay",
            preview && "modal-preview"
          )}
          maskStyle={{
            ...(preview ? { backgroundColor: "#000000dd" } : {}),
            ...maskStyle,
          }}
          width={preview ? "auto" : width}
          style={{ ...(preview ? { padding: "8px" } : {}), ...style }}
          bodyStyle={{ ...(preview ? { padding: 0 } : {}), ...style }}
          centered={preview ? true : centered}
        >
          {children}
        </AntModal>
      </>
    );
  }
}
