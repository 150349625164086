import {
  IAssetCategoriesListModel,
  IAssetCategoryModel,
  IAssetCategoriesSearchFilterModel,
} from "../../models";
import { SimpleServiceBase } from "../Base";

export class AssetCategoryService extends SimpleServiceBase<
  IAssetCategoryModel,
  IAssetCategoriesListModel,
  number,
  IAssetCategoriesSearchFilterModel
> {
  get url(): string {
    return "/AssetCategories";
  }
}
