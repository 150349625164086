import { Observable, Observer, of } from "rxjs";
import { HTTP_METHOD } from "../../constants/http";
import {
  IListModelBase,
  ILessonModel,
  ILessonsSearchFilterModel,
  ILessonsListModel,
} from "../../models";
import { AxiosSubscriber } from "../AxiosSubscriber";
import { SimpleServiceBase } from "../Base";

export class LessonService extends SimpleServiceBase<
  ILessonModel,
  ILessonsListModel,
  number,
  ILessonsSearchFilterModel
> {
  get url(): string {
    return "/Lessons";
  }
}
