import React from "react";
import { Tag as AntTag } from "antd";
import { TagProps as AntTagProps } from "antd/lib/tag";
import cx from "classnames";
import "./Tag.scss";

export type TagType = "exclusive" | "movie-night" | "premiere";

export const TAG_PALETTE = {
  GREY: "#555",
  GREEN: "#393",
  BLUE: "#2d77ff",
  TEAL: "#2d7f7f",
  YELLOW: "#bf7c2a",
  FUCHSIA: "#949",
  VIOLET: "#8b43ff",
  RED: "#b33",
} as const;

export const DEFAULT_TAG_ROTATE = [
  TAG_PALETTE.GREY,
  TAG_PALETTE.GREEN,
  TAG_PALETTE.BLUE,
  TAG_PALETTE.TEAL,
  TAG_PALETTE.YELLOW,
  TAG_PALETTE.FUCHSIA,
  TAG_PALETTE.VIOLET,
  TAG_PALETTE.RED,
];

export interface TagProps extends AntTagProps {
  type?: TagType;
  colorRotate?: string | number;
  noMargin?: boolean;
  palette?: string[];
  children?: React.ReactNode;
}

export const Tag: React.FC<TagProps> = (props) => {
  const {
    children,
    className: classNameProp,
    type,
    colorRotate,
    palette = DEFAULT_TAG_ROTATE,
    noMargin,
    color,
    ...rest
  } = props;
  const className = cx(
    classNameProp,
    "gm-tag",
    noMargin && "gm-tag-no-margin",
    {
      [`gm-tag--${type}`]: type,
    }
  );
  let tagColor = color;

  if (colorRotate !== undefined) {
    const charIndex =
      typeof colorRotate === "string"
        ? Array.from(colorRotate)
            .map((letter) => letter.charCodeAt(0))
            .reduce((a, b) => a + b, 0)
        : colorRotate;
    tagColor = palette[charIndex % palette.length];
  }

  return (
    <AntTag className={className} color={tagColor} {...rest}>
      {children}
    </AntTag>
  );
};
