import {
  IServiceCallerState,
  ITestDefinitionModel,
} from "@bms/common-services";
import { SectionGrid, SectionGridItem } from "@bms/common-ui";
import { TestDefinitionEditForm } from "./TestDefinitionEditForm";

interface TestDefinitionDetailsTabProps {
  testDefinition?: ITestDefinitionModel;
  updateState?: IServiceCallerState<void>;
  updateTestDefinition: (testDefinition: ITestDefinitionModel) => Promise<void>;
}

export const TestDefinitionDetailsTab = (
  props: TestDefinitionDetailsTabProps
) => {
  const { testDefinition, updateState, updateTestDefinition } = props;

  return (
    <SectionGrid>
      <SectionGridItem processing={updateState?.processing}>
        {testDefinition && (
          <TestDefinitionEditForm
            key={testDefinition.RowVersion}
            testDefinition={testDefinition}
            updateTestDefinition={updateTestDefinition}
          />
        )}
      </SectionGridItem>
    </SectionGrid>
  );
};
