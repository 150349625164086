import { ITestCaseModel, PlatformType } from "@bms/common-services";
import {
  Choose,
  ChooseOption,
  Col,
  Form,
  IFormValues,
  Input,
  required,
  Row,
  Spin,
  Switch,
  TextArea,
  useValidate,
} from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { useTestingPlatforms } from "../../../../hooks";
import React from "react";
import { PlatformTypeHelper } from "../../../../../../helpers";

interface TestingTestCaseFormProps {
  testCase: ITestCaseModel;
  isLoading: boolean;
  setVisibilityStatus: (visible: boolean) => void;
  createTestCase: (data: ITestCaseModel) => Promise<boolean>;
  updateTestCase: (data: ITestCaseModel) => Promise<boolean>;
}

export const TestingTestCaseForm = (props: TestingTestCaseFormProps) => {
  const {
    testCase,
    isLoading,
    setVisibilityStatus,
    createTestCase,
    updateTestCase,
  } = props;

  const [form] = Form.useForm();
  const validate = useValidate();
  const { t } = useTranslation();
  const { platforms } = useTestingPlatforms();

  const isNewTestCase = testCase.Id < 0;

  const onFinish = async (values: IFormValues) => {
    const data: ITestCaseModel = {
      ...testCase,
      ...values,
      Platforms: values.Platforms?.map((platformCode: PlatformType) => ({
        PlatformCode: platformCode,
      })),
    };

    isNewTestCase ? await createTestCase(data) : await updateTestCase(data);
    setVisibilityStatus(false);
  };

  const renderCodeInput = () => {
    return (
      <Form.Item
        name="Code"
        label={t("MODEL_CODE", "Code")}
        rules={[required()]}
      >
        <Input placeholder={t("MODEL_CODE", "Code")} />
      </Form.Item>
    );
  };

  const renderNameInput = () => {
    return (
      <Form.Item name="Name" label={t("MODEL_NAME")} rules={[required()]}>
        <Input placeholder={t("MODEL_NAME")} />
      </Form.Item>
    );
  };

  const renderDescriptionInput = () => {
    return (
      <Form.Item name="Description" label={t("MODEL_DESCRIPTION")}>
        <TextArea maxLength={250} placeholder={t("MODEL_DESCRIPTION")} />
      </Form.Item>
    );
  };

  const renderPlatformField = () => {
    return (
      <Form.Item
        key="Platforms"
        name="Platforms"
        label={t("MODEL_PLATFORMS")}
        rules={validate.required({
          choiceField: true,
          message: t("REQUIRE_PLATFORM_VALIDATION_MESSAGE"),
        })}
      >
        <Choose
          mode="multiple"
          placeholder={t("MODEL_PLATFORMS_PLACEHOLDER")}
          loading={!platforms.length}
          tagRender={PlatformTypeHelper.getTagOption}
        >
          {platforms.map((platform) => {
            return (
              <ChooseOption key={platform.Code} value={platform.Code}>
                {platform.DisplayName}
              </ChooseOption>
            );
          })}
        </Choose>
      </Form.Item>
    );
  };

  const renderUpToDate = () => {
    return (
      <Form.Item>
        <Row gutter={8}>
          <Col>
            <Form.Item name="UpToDate" valuePropName="checked">
              <Switch />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="UpToDate">
              <label>{t("MODEL_UP_TO_DATE")}</label>
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>
    );
  };

  return (
    <Spin spinning={isLoading}>
      <Form
        name="TestingTestCaseForm"
        form={form}
        initialValues={{
          ...testCase,
          Platforms: testCase.Platforms?.map(
            (platform) => platform.PlatformCode
          ),
        }}
        onFinish={onFinish}
        layout="vertical"
      >
        <Row direction="column" justify="space-between" className="full-height">
          <Col>
            {renderCodeInput()}
            {renderNameInput()}
            {renderPlatformField()}
            {renderDescriptionInput()}
            {renderUpToDate()}
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};
