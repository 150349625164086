import { Observable, Observer } from "rxjs";
import { HTTP_METHOD } from "../../constants/http";
import {
  IAssetEventModel,
  IAssetEventsListModel,
  IAssetEventsSearchFilterModel,
  IAssetEventTypesModel,
} from "../../models";
import { AxiosSubscriber } from "../AxiosSubscriber";
import { SimpleServiceBase } from "../Base";

export class AssetEventsService extends SimpleServiceBase<
  IAssetEventModel,
  IAssetEventsListModel,
  number,
  IAssetEventsSearchFilterModel
> {
  get url(): string {
    return "/AssetEvents";
  }

  public searchAssetEvents = (
    assetId: number,
    pageSize: number = 10,
    pageNumber: number = 1,
    filter: IAssetEventsSearchFilterModel
  ): Observable<IAssetEventsListModel> =>
    new Observable(
      (observer: Observer<IAssetEventsListModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: {
              pageSize,
              pageNumber,
              includeCount: true,
            },
          },
          data: filter,
          method: HTTP_METHOD.POST,
          url: "/AssetEvents/Search",
        })
    );

  public getAssetEventTypes = (): Observable<IAssetEventTypesModel[]> =>
    new Observable(
      (observer: Observer<IAssetEventTypesModel[]>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: "/AssetEventTypes/Select",
        })
    );

  public publish = (
    assetEvent: IAssetEventModel
  ): Observable<IAssetEventModel> =>
    new Observable(
      (observer: Observer<IAssetEventModel>) =>
        new AxiosSubscriber(observer, {
          data: {
            AssetEventId: assetEvent.Id,
            RowVersion: assetEvent.RowVersion,
          },
          method: HTTP_METHOD.PUT,
          url: `${this.url}/Publish`,
        })
    );
}
