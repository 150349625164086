/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export enum AppKey {
  Studio = "SCMT",
  StudioStandalone = "STUDIO_STANDALONE",
  StudioManagement = "STUDIO_MANAGEMENT",
}
