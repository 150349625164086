export const RATING_CATEGORIES_SEARCH = "RATING_CATEGORIES_SEARCH";
export const RATING_CATEGORIES_SEARCH_SUCCESS =
  "RATING_CATEGORIES_SEARCH_SUCCESS";
export const RATING_CATEGORIES_SEARCH_FAILURE =
  "RATING_CATEGORIES_SEARCH_FAILURE";

export const RATING_CATEGORY_DELETE = "RATING_CATEGORY_DELETE";
export const RATING_CATEGORY_DELETE_SUCCESS = "RATING_CATEGORY_DELETE_SUCCESS";
export const RATING_CATEGORY_DELETE_FAILURE = "RATING_CATEGORY_DELETE_FAILURE";

export const RATING_CATEGORY_INSERT = "RATING_CATEGORY_INSERT";
export const RATING_CATEGORY_INSERT_SUCCESS = "RATING_CATEGORY_INSERT_SUCCESS";
export const RATING_CATEGORY_INSERT_FAILURE = "RATING_CATEGORY_INSERT_FAILURE";

export const RATING_CATEGORY_UPDATE = "RATING_CATEGORY_UPDATE";
export const RATING_CATEGORY_UPDATE_SUCCESS = "RATING_CATEGORY_UPDATE_SUCCESS";
export const RATING_CATEGORY_UPDATE_FAILURE =
  "RATING_CATEGORIES_UPDATE_FAILURE";
