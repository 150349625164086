import { AppKey } from "../enums/AppKey";

export class AppKeyHelper {
  static getValue(
    value?: string,
    defaultValue: AppKey = AppKey.Studio
  ): AppKey {
    switch (value) {
      case "SCMT":
        return AppKey.Studio;
      case "STUDIO_STANDALONE":
        return AppKey.StudioStandalone;
      case "STUDIO_MANAGEMENT":
        return AppKey.StudioManagement;
      default:
        return defaultValue;
    }
  }
}
