import {
  Button,
  ITableColumnProps,
  Link,
  PageContent,
  PageHeader,
  Pagination,
  TableWithDraggableSorter,
  TagUpToDate,
} from "@bms/common-ui";
import {
  CommonStore,
  ICommonAppState,
  IRatingCategoryModel,
  RecordStatus,
} from "@bms/common-services";
import { PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { ROUTES as TRANSLATIONS_ROUTES } from "../../../Translations/constants";
import { ROUTES } from "../../constants";
import { FormModal } from "../../../../components/FormModal";
import { generateBreadcrumb, PlatformTypeHelper } from "../../../../helpers";
import { RatingCategoryForm } from "../RatingCategoryForm";
import { useRatingCategories } from "../../hooks";
import _ from "lodash";

const defaultCategory: IRatingCategoryModel = {
  Id: -1,
  Code: "",
  Name: "",
  RecordStatus: RecordStatus.NoChange,
  RowVersion: "",
  TranslationKey: "",
  DisplayName: "",
  Sequence: 0,
  UpToDate: false,
  Platforms: [],
};

export const RatingCategoriesList = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<
    IRatingCategoryModel
  >(defaultCategory);
  const platforms = useSelector(
    (state: ICommonAppState) => state.common.platforms
  );

  const {
    ratingCategoriesData,
    isLoading,
    refreshCategories,
    deleteCategory,
    pagination,
    updateCategory,
    createCategory,
  } = useRatingCategories();

  const ratingCategories = ratingCategoriesData?.Entities;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isNewCategory = selectedCategory.Id < 0;

  const getBreadcrumbProps = () => {
    return generateBreadcrumb([
      {
        path: ROUTES.RATING_CATEGORIES,
        breadcrumbName: t("RATING_CATEGORIES"),
      },
    ]);
  };

  const showAddCategoryModal = () => {
    setSelectedCategory(defaultCategory);
    setModalVisible(true);
  };

  const getExtraButtons = () => {
    return (
      <>
        <Button
          key="add"
          shape="circle"
          type="primary"
          icon={<PlusOutlined />}
          onClick={showAddCategoryModal}
          title={t("RATING_CATEGORY_BUTTON_ADD")}
        />
        <Button
          key="reload"
          shape="circle"
          icon={<ReloadOutlined />}
          onClick={refreshCategories}
          title={t("BUTTON_REFRESH_TITLE")}
        />
      </>
    );
  };

  const handleDeleteCategory = async () => {
    await deleteCategory(selectedCategory);
    setModalVisible(false);
  };

  const getColumnsProps = (): Array<
    ITableColumnProps<IRatingCategoryModel>
  > => {
    return [
      {
        key: "Code",
        dataIndex: "Code",
        title: t("MODEL_CODE"),
        render: (_, categoryData) => {
          return (
            <a
              title={categoryData.Code}
              className="imageUrl"
              onClick={() => {
                setModalVisible(true);
                setSelectedCategory(categoryData);
              }}
            >
              {categoryData.Code}
            </a>
          );
        },
      },
      {
        key: "NameTranslationKey",
        dataIndex: "NameTranslationKey",
        className: "drag-visible",
        title: t("MODEL_TRANSLATION_KEY"),
        render: (_, categoryData: IRatingCategoryModel) => {
          return categoryData.TranslationKey ? (
            <label>
              <Link
                to={`${TRANSLATIONS_ROUTES.CONFIGURATION_TRANSLATION_DETAILS}/${categoryData.TranslationKey}`}
              >
                {categoryData.TranslationKey}
              </Link>
            </label>
          ) : (
            ""
          );
        },
      },
      {
        key: "Platforms",
        dataIndex: "Platforms",
        title: t("MODEL_PLATFORMS"),
        render: (_, categoryData) => {
          const platformsView: React.ReactNode[] = [];

          if (categoryData.Platforms && categoryData.Platforms.length > 0) {
            for (const platform of categoryData.Platforms) {
              platformsView.push(
                PlatformTypeHelper.getTag(platform.PlatformCode)
              );
            }
          }

          return platformsView;
        },
      },
      {
        key: "Sequence",
        dataIndex: "Sequence",
        title: t("MODEL_SEQUENCE"),
      },
      {
        key: "UpToDate",
        dataIndex: "UpToDate",
        title: t("MODEL_UP_TO_DATE"),
        render: (upToDate: boolean) => <TagUpToDate value={upToDate} />,
      },
    ];
  };

  const onMoveRow = (dragIndex: number, hoverIndex: number) => {
    const draggedRatingCategory = ratingCategories?.[dragIndex];
    const hoveredRatingCategory = ratingCategories?.[hoverIndex];

    if (!draggedRatingCategory || !hoveredRatingCategory) {
      return;
    }

    const ratingCategoryToUpdate: IRatingCategoryModel = {
      ...draggedRatingCategory,
      Sequence: hoveredRatingCategory.Sequence ?? 1,
      RecordStatus: RecordStatus.Updated,
    };

    const sequenceChanged =
      draggedRatingCategory.Sequence !== hoveredRatingCategory.Sequence;
    const draggedToNewPosition =
      draggedRatingCategory.Id !== hoveredRatingCategory.Id;

    if (draggedToNewPosition && sequenceChanged) {
      updateCategory(ratingCategoryToUpdate);
    }
  };

  useEffect(() => {
    if (!platforms || platforms.length === 0) {
      dispatch(CommonStore.Actions.selectPlatforms());
    }
  }, []);

  return (
    <>
      <FormModal
        isLoading={isLoading}
        isVisible={modalVisible}
        isNewForm={isNewCategory}
        isDeleteButtonEnabled={true}
        createFormTitle={t("RATING_CATEGORY_ADD_NEW")}
        editFormTitle={t("RATING_CATEGORY_EDIT")}
        modalClassName="RatingCategoryModal"
        submitFormName="RatingCategoryForm"
        onCloseModal={() => setModalVisible(false)}
        onDeleteButtonClick={handleDeleteCategory}
      >
        <RatingCategoryForm
          ratingCategory={selectedCategory}
          setModalVisible={setModalVisible}
          createCategory={createCategory}
          updateCategory={updateCategory}
          isLoading={isLoading}
        />
      </FormModal>
      <PageContent footer={<Pagination {...pagination.props} />}>
        <PageHeader
          title={t("RATING_CATEGORIES")}
          breadcrumb={getBreadcrumbProps()}
          extra={getExtraButtons()}
        />
        <TableWithDraggableSorter<IRatingCategoryModel>
          dragType="handler"
          columns={getColumnsProps()}
          dataSource={ratingCategories}
          loading={isLoading}
          pagination={false}
          onMoveRow={onMoveRow}
        />
      </PageContent>
    </>
  );
};
