import React, { useState } from "react";
import { Choose, ChooseOption, Form, Input } from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { ActionType } from "@bms/common-services";
import { IAssetFormFieldCommonProps } from "./IAssetFormFieldCommonProps";
import { ActionTypeHelper } from "../../../../../modules/ApplicationConfiguration/helpers";
import { CUSTOM_METADATA_FILEDS } from "../AssetFormUtils";

interface IActionProps extends IAssetFormFieldCommonProps {}

export const AssetActionField = ({
  isHidden,
  isEditMode,
  asset,
}: IActionProps) => {
  const { t } = useTranslation();
  const [actionType, setActionType] = useState(
    asset?.CustomMetadata?.Action?.ActionType
  );

  if (isHidden) {
    return null;
  }

  const renderActionTypeProperty = () => {
    if (asset === undefined) {
      return null;
    }

    return (
      <Form.Item
        name={CUSTOM_METADATA_FILEDS.ACTION_TYPE}
        label={t("APPLICATION_CONFIGURATION__ACTION_TYPE_LABEL")}
        initialValue={
          isEditMode ? asset?.CustomMetadata?.Action?.ActionType : undefined
        }
      >
        <Choose
          placeholder={t("APPLICATION_CONFIGURATION__ACTION_TYPE_PLACEHOLDER")}
          onChange={(value) => setActionType(ActionTypeHelper.getValue(value))}
          allowClear
        >
          <ChooseOption key="OPEN_URL" value={ActionType.OpenUrl}>
            {t("APPLICATION_CONFIGURATION__ACTION_TYPE_OPTION_OPEN_URL_LABEL")}
          </ChooseOption>
        </Choose>
      </Form.Item>
    );
  };

  const renderUrlProperty = () => {
    if (
      actionType !== ActionType.OpenUrl &&
      asset?.CustomMetadata?.Action?.ActionType !== ActionType.OpenUrl
    ) {
      return null;
    }

    return (
      <Form.Item
        name={CUSTOM_METADATA_FILEDS.ACTION_URL}
        label={t("COMMON_URL")}
        initialValue={
          isEditMode ? asset?.CustomMetadata?.Action?.Url : undefined
        }
      >
        <Input placeholder="//" />
      </Form.Item>
    );
  };

  return (
    <>
      {renderActionTypeProperty()}
      {renderUrlProperty()}
    </>
  );
};
