import { isUndefined } from "lodash";
import { IStorageContent } from "../models";

export class StorageManager {
  static instance: StorageManager;

  _appKey: string = process.env.REACT_APP_KEY || "default";

  static getInstance() {
    if (StorageManager.instance) {
      return StorageManager.instance;
    }

    StorageManager.instance = new StorageManager();

    return StorageManager.instance;
  }

  static init(appKey: string): void {
    this.getInstance().setAppKey(appKey);
  }

  setAppKey(prefix: string) {
    this._appKey = prefix;
  }

  getAppKey(): string | undefined {
    return this._appKey;
  }

  static setValue(key: string, val: any): void {
    if (!localStorage) {
      console.warn("StorageManager: localStorage is not defined");
      return;
    }

    const appKey = this.getInstance().getAppKey();
    const _key = `${appKey}.${key}`;

    // Let's convert `undefined` values to `null` to get the value consistent
    val = isUndefined(val) ? null : JSON.stringify(val);

    localStorage.setItem(_key, val);
  }

  static getValue(key: keyof IStorageContent): any {
    if (!localStorage) {
      console.warn("StorageManager: localStorage is not defined");
      return undefined;
    }

    const appKey = this.getInstance().getAppKey();
    const _key = `${appKey}.${key}`;
    const item = localStorage ? localStorage.getItem(_key) : null;

    if (!item || item === "null") {
      return undefined;
    }

    try {
      return JSON.parse(item);
    } catch (e) {
      return undefined;
    }
  }

  static deleteValue(key: keyof IStorageContent): void {
    if (!localStorage) {
      console.warn("StorageManager: localStorage is not defined");
      return;
    }

    const appKey = this.getInstance().getAppKey();
    const _key = `${appKey}.${key}`;

    localStorage.removeItem(_key);
  }
}
