import {
    AdvertisementStore,
    IAdvertisementBlockModel,
    ICommonAppState,
    IStateModel,
} from "@bms/common-services";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AdvertisementBoardsList as AdvertisementBoardsListDefinition } from "./AdvertisementBoardsList";

const mapStateToProps = (state: ICommonAppState) => {
  return {
    actionType: state.advertisement.actionType,
    error: state.advertisement.error,
    advertisementBlock: state.advertisement.advertisementBlock,
    addBoardModalVisible: state.advertisement.addBoardModalVisible,
    isLoadingData: state.advertisement.isLoadingData,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    toggleAdvertisementBoardModal: (
      visibility: boolean
    ) => {
      return dispatch(
        AdvertisementStore.Actions.toggleAdvertisementBoardModal(visibility)
      );
    },
    updateAdvertisementBlockWithDetails: (data: IStateModel<IAdvertisementBlockModel>) => {
      return dispatch(AdvertisementStore.Actions.updateAdvertisementBlockWithDetails(data));
    },
    updateTemporaryAdvertisementBlock: (data: IStateModel<IAdvertisementBlockModel>) => {
      return dispatch(AdvertisementStore.Actions.updateTemporaryAdvertisementBlock(data));
    },
});

export const AdvertisementBoardsList = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(AdvertisementBoardsListDefinition));
