import { ActionsObservable, ofType, StateObservable } from "redux-observable";
import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { IErrorModel } from "../../../models";
import { ICommonAppState } from "../../../store";
import { ConfigurationService } from "../services";
import { getConfigurationFailure, getConfigurationSuccess } from "./actions";
import * as Consts from "./consts";
import { IGetConfigurationAction } from "./types";

const configurationService = new ConfigurationService();

const getConfigurationEpic = (
  action$: ActionsObservable<IGetConfigurationAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.GET_CONFIGURATION),
    switchMap((action: IGetConfigurationAction) =>
      configurationService.getConfiguration().pipe(
        map((data) => getConfigurationSuccess(data)),
        catchError((error: IErrorModel) => of(getConfigurationFailure(error)))
      )
    )
  );

export const configurationEpics = [getConfigurationEpic];
