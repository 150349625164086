import React from "react";
import { DatePicker, Form } from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { IAssetFormFieldCommonProps } from "./IAssetFormFieldCommonProps";
interface IYearFieldProps extends IAssetFormFieldCommonProps {}

export const YearField = ({
  isEditMode,
  asset,
  isHidden = false,
}: IYearFieldProps) => {
  const { t } = useTranslation();
  if (isHidden) {
    return null;
  }
  return (
    <Form.Item
      name="Year"
      label={t("MODEL_YEAR")}
      key="year"
      initialValue={
        isEditMode && asset && asset.Year ? dayjs().year(asset.Year) : undefined
      }
    >
      <DatePicker
        picker="year"
        placeholder={t("MODEL_YEAR_PLACEHOLDER")}
        style={{ width: "100%" }}
      />
    </Form.Item>
  );
};
