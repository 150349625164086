import {
  IUserModel,
  IUsersSearchFilterModel,
  UserStore,
} from "@bms/common-services";
import {
  Button,
  Heading,
  Icon,
  InputSearch,
  ITableFilter,
  ITablePaginationConfig,
  ITableRowSelection,
  Modal,
  Table,
} from "@bms/common-ui";
import React, { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { FilterCleanIcon } from "../../../../resources/icons";
import "./UserBrowserModal.scss";
import { useUserBrowserTableColumns } from "./useUserBrowserTableColumns";

export interface IUserBrowserModalProps {
  visible: boolean;
  onCancel?: () => void;
  onSelect?: (row: IUserModel) => void;
  profiles?: string[];
}

export const UserBrowserModal = memo((props: IUserBrowserModalProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedRow, setSelectedRow] = useState<IUserModel>();
  const {
    columns,
    usersBrowser,
    filter,
    isProfilesFilterProvided,
  } = useUserBrowserTableColumns({ profiles: props.profiles });

  const browseUsers = useCallback(
    (newFilter: IUsersSearchFilterModel) => {
      dispatch(UserStore.Actions.browseUsers(newFilter));
    },
    [dispatch]
  );

  const selectProfiles = useCallback(
    () => dispatch(UserStore.Actions.selectProfiles()),
    [dispatch]
  );

  useEffect(() => {
    if (props.visible) {
      browseUsers(filter);
      selectProfiles();
    }
  }, [props.visible]);

  const onRefreshClick = () => browseUsers(filter);

  const onClearFiltersClick = () => {
    const newFilter: IUsersSearchFilterModel = {
      PageSize: usersBrowser.Filter?.PageSize || 5,
      PageNumber: 1,
      IncludeCount: true,
      Profiles: isProfilesFilterProvided() ? props.profiles : undefined,
    };
    browseUsers(newFilter);
  };

  const onTableChange = (
    pagination: ITablePaginationConfig,
    filters: ITableFilter
  ) => {
    const newFilter: IUsersSearchFilterModel = {
      ...filter,
      FullName:
        filters.FullName && filters.FullName.length > 0
          ? `${filters.FullName[0]}`
          : undefined,
      Locked:
        filters.Locked && filters.Locked.length > 0
          ? filters.Locked[0] === "true"
          : undefined,
      Email:
        filters.Email && filters.Email.length > 0
          ? `${filters.Email[0]}`
          : undefined,
      EmailConfirmed:
        filters.EmailConfirmed && filters.EmailConfirmed.length > 0
          ? filters.EmailConfirmed[0] === "true"
          : undefined,
      PhoneNumber:
        filters.PhoneNumber && filters.PhoneNumber.length > 0
          ? `${filters.PhoneNumber[0]}`
          : undefined,
      PhoneNumberConfirmed:
        filters.PhoneNumberConfirmed && filters.PhoneNumberConfirmed.length > 0
          ? filters.PhoneNumberConfirmed[0] === "true"
          : undefined,
      Profiles: isProfilesFilterProvided()
        ? props.profiles
        : filters.Profiles && filters.Profiles.length
        ? filters.Profiles.map((row) => `${row}`)
        : undefined,
      PageSize: pagination.pageSize,
      PageNumber: pagination.current,
    };
    browseUsers(newFilter);
  };

  const onSearch = (value: string) => {
    const newFilter: IUsersSearchFilterModel = {
      ...filter,
      FullTextSearch: value,
      PageNumber: 1,
    };
    browseUsers(newFilter);
  };

  const tablePagination: ITablePaginationConfig = {
    pageSize: filter?.PageSize,
    total: usersBrowser.TotalCount,
    current: filter?.PageNumber,
    showTotal: (total, range) =>
      t("TABLE_PAGINATION_TOTAL", {
        rangeFrom: range[0],
        rangeTo: range[1],
        total: total,
      }),
    showSizeChanger: true,
    defaultPageSize: 5,
    pageSizeOptions: ["5", "10", "50", "100"],
  };

  const onCancelClick = () => {
    const { onCancel } = props;

    if (onCancel) {
      onCancel();
    }
  };

  const onSelectClick = () => {
    const { onSelect } = props;

    if (onSelect && selectedRow) {
      onSelect(selectedRow);
    }
  };

  const onTableRow = (row: IUserModel, index?: number) => {
    return {
      onClick: (event: React.MouseEvent) => {
        setSelectedRow(row);
      },
      onDoubleClick: (event: React.MouseEvent) => {
        const { onSelect } = props;

        if (onSelect) {
          onSelect(row);
        } else {
          setSelectedRow(row);
        }
      },
    };
  };

  const rowSelection: ITableRowSelection<IUserModel> = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: IUserModel[]) => {
      if (selectedRows.length > 0) {
        setSelectedRow(selectedRows[0]);
      }
    },
    type: "radio",
    selectedRowKeys: selectedRow?.Id ? [selectedRow.Id] : [],
  };

  const hasSelectedRow = selectedRow ? true : false;

  return (
    <>
      <Modal
        title={
          <Heading
            title={t("USER_MODAL_SELECT_USER")}
            actions={[
              <InputSearch
                key="search"
                placeholder={t("SEARCH_PLACEHOLDER")}
                defaultValue={filter?.FullTextSearch}
                onSearch={onSearch}
                style={{ width: 250 }}
                allowClear
              />,
              <Button
                key="reload"
                icon={<Icon type="reload" />}
                onClick={onRefreshClick}
                title={t("BUTTON_REFRESH_TITLE")}
              />,
              <Button
                key="clear"
                icon={<Icon component={FilterCleanIcon} />}
                onClick={onClearFiltersClick}
                title={t("MENU_OPTION_CLEAR_FILTERS")}
              />,
            ]}
          />
        }
        onCancel={onCancelClick}
        visible={props.visible}
        className="UserBrowserModal"
        closable={false}
        centered={true}
        width="70%"
        footer={
          <>
            <Button key="cancel" onClick={onCancelClick}>
              {t("COMMON_CANCEL")}
            </Button>
            <Button
              key="cancel"
              disabled={!hasSelectedRow}
              onClick={onSelectClick}
            >
              {t("BUTTON_SELECT")}
            </Button>
          </>
        }
      >
        <Table<IUserModel>
          rowKey="Id"
          style={{ minHeight: "40vh" }}
          columns={columns}
          dataSource={usersBrowser.Entities}
          loading={usersBrowser.IsLoading}
          pagination={tablePagination}
          onChange={onTableChange}
          rowSelection={rowSelection}
          onRow={onTableRow}
        />
      </Modal>
    </>
  );
});
