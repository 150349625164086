import React from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { TestStatus } from "@bms/common-services";
import {
  Button,
  IBreadcrumbProps,
  Icon,
  PageContent,
  PageHeader,
  TabPane,
  Tabs,
  Tooltip,
  useAppFeedback,
  useSearchParamsTabs,
} from "@bms/common-ui";
import { generateBreadcrumb } from "../../../../helpers";
import { ROUTES } from "../../constants";
import {
  TestDefinitionDetailsTab,
  TestDefinitionExecutionsTab,
} from "./components";
import { useTestDefinition } from "./useTestDefinition";

export const TestingTestDefinitionDetails = () => {
  const [activeTabKey] = useSearchParamsTabs("DETAILS");
  const { id } = useParams<{ id: string }>();

  const { modal } = useAppFeedback();

  const {
    testDefinition,
    refresh,
    deleteTestDefinition,
    updateTestDefinition,
    updateState,
    startTestDefinition,
    startState,
  } = useTestDefinition({
    testId: Number(id),
  });

  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const title =
    testDefinition?.Name ?? t("TESTING_TEST_DEFINITIONS_DETAILS_TITLE");

  const isExecutable = testDefinition?.Status === TestStatus.Draft;

  const platforms =
    testDefinition?.Platforms?.map((platform) => platform.PlatformCode) || [];

  const onBackClick = () => {
    if (location.state && location.state.hasOwnProperty("from")) {
      const state = location.state as { from: Location };
      history.push(state.from);
    } else {
      history.goBack();
    }
  };

  const onDeleteClick = () => {
    if (!testDefinition) {
      return;
    }

    modal.confirm({
      title: t("COMMON_DELETE"),
      content: t("TESTING_TEST_DEFINITIONS_DELETE_MESSAGE", {
        testName: testDefinition.Name,
      }),
      okText: t("BUTTON_DELETE"),
      cancelText: t("BUTTON_CANCEL"),
      onOk: () => deleteTestDefinition(testDefinition),
    });
  };

  const onStartClick = () => {
    if (!testDefinition) {
      return;
    }

    modal.confirm({
      title: t("BUTTON_START_TITLE"),
      content: t("TESTING_TEST_DEFINITIONS_START_MESSAGE", {
        testName: testDefinition.Name,
      }),
      okText: t("BUTTON_OK"),
      cancelText: t("BUTTON_CANCEL"),
      onOk: () => startTestDefinition(Number(id)),
    });
  };

  const getBreadcrumbProps = (): IBreadcrumbProps => {
    return generateBreadcrumb([
      {
        path: `${ROUTES.TEST_DEFINITIONS}`,
        breadcrumbName: t("TESTING_TEST_DEFINITIONS"),
      },
      {
        path: `${ROUTES.TEST_DEFINITION_DETAILS}/${id}`,
        breadcrumbName: title,
      },
    ]);
  };

  return (
    <PageContent>
      <PageHeader
        title={title}
        onBack={onBackClick}
        breadcrumb={getBreadcrumbProps()}
        extra={
          <>
            <Tooltip key="start-tooltip" overlay={t("BUTTON_START_TITLE")}>
              <Button
                key="action-start"
                type="primary"
                icon={<Icon type="playCircle" />}
                onClick={onStartClick}
                title={t("BUTTON_START_TITLE")}
                shape="round"
                disabled={!isExecutable || startState.processing}
              >
                {t("BUTTON_START_TITLE")}
              </Button>
            </Tooltip>
            <Tooltip key="reload-tooltip" overlay={t("BUTTON_REFRESH_TITLE")}>
              <Button
                key="action-reload"
                icon={<Icon type="reload" />}
                onClick={refresh}
                title={t("BUTTON_REFRESH_TITLE")}
                shape="circle"
              />
            </Tooltip>
            <Tooltip key="delete-tooltip" overlay={t("DELETE_ELEMENT")}>
              <Button
                danger
                key="action-delete"
                icon={<Icon type="delete" />}
                title={t("BUTTON_DELETE")}
                onClick={onDeleteClick}
                disabled={!Boolean(testDefinition)}
                shape="circle"
              />
            </Tooltip>
          </>
        }
      />
      <Tabs activeKey={activeTabKey} destroyInactiveTabPane={true}>
        <TabPane key="DETAILS" tab={t("TESTING_TEST_DEFINITIONS_DETAILS_TAB")}>
          <TestDefinitionDetailsTab
            key={testDefinition?.RowVersion}
            testDefinition={testDefinition}
            updateState={updateState}
            updateTestDefinition={updateTestDefinition}
          />
        </TabPane>
        <TabPane
          key="EXECUTIONS"
          tab={t("TESTING_TEST_DEFINITIONS_EXECUTIONS_TAB")}
        >
          <TestDefinitionExecutionsTab
            key={testDefinition?.RowVersion}
            testDefinitionId={Number(id)}
            platforms={platforms}
            isExecutable={isExecutable}
          />
        </TabPane>
      </Tabs>
    </PageContent>
  );
};
