import { osName } from "react-device-detect";
import { PlatformType } from "../enums";
import { IUserDeviceModel } from "../models";
import { StorageManager } from "../services/StorageManager";
import { GuidHelper } from "./guid.helper";
import { PlatformTypeHelper } from "./platformTypeHelper";

export class DeviceHelper {
  static deviceName = () => {
    let osid = StorageManager.getValue("osid");

    if (!osid) {
      osid = GuidHelper.newGuid();
      StorageManager.setValue("osid", osid);
    }

    return `${osName} (${osid})`;
  };

  static platformCode = (): PlatformType => {
    return PlatformTypeHelper.getValue(
      `${process.env.REACT_APP_PLATFORM_CODE}`,
      PlatformType.Web
    );
  };

  static deviceInfo = (): IUserDeviceModel => {
    return {
      Name: DeviceHelper.deviceName(),
      PlatformCode: DeviceHelper.platformCode(),
    };
  };
}
