import {
  ApplicationConfigurationStore,
  IApplicationConfigurationModel,
  IApplicationsConfigurationsSearchFilterModel,
  ICommonAppState,
} from "@bms/common-services";
import { push } from "connected-react-router";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { ROUTES } from "../../constants";
import { ApplicationsConfigurationsList as ApplicationsConfigurationsListDefinition } from "./ApplicationsConfigurationsList";

const mapStateToProps = (state: ICommonAppState) => {
  return {
    configurations: state.applicationConfiguration.configurations,
    isLoadingData: state.applicationConfiguration.isLoadingData,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  deleteConfiguration: (data: IApplicationConfigurationModel) => {
    return dispatch(
      ApplicationConfigurationStore.Actions.deleteConfiguration(data)
    );
  },
  goToDetails: (id: number) => {
    return dispatch(push(`${ROUTES.CONFIGURATION_DETAILS}/${id}`));
  },
  searchConfigurations: (
    filter: IApplicationsConfigurationsSearchFilterModel
  ) => {
    return dispatch(
      ApplicationConfigurationStore.Actions.searchConfigurations(filter)
    );
  },
});

export const ApplicationsConfigurationsList = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ApplicationsConfigurationsListDefinition));
