import { CellType, ScreenType } from "@bms/common-services";
import {
  Choose,
  Col,
  Form,
  IChooseValue,
  IFormInstance,
  IFormValues,
  Row,
} from "@bms/common-ui";
import React from "react";
import { WithTranslation } from "react-i18next";

import { CellTypeHelper } from "../../../../helpers";
import { ApplicationSearchPropertyModel } from "../../models";
import {
  ComponentPropertiesBase,
  IComponentPropertiesBaseProps,
  IComponentPropertiesBaseState,
} from "../ComponentPropertiesBase";

import "./ApplicationSearchProperties.scss";

export interface ApplicationSearchPropertiesStateProps {
  isProcessingData: boolean;
}

export interface ApplicationSearchPropertiesDispatchProps {}

export interface ApplicationSearchPropertiesOwnProps {}

export interface ApplicationSearchPropertiesProps
  extends ApplicationSearchPropertiesStateProps,
    ApplicationSearchPropertiesDispatchProps,
    ApplicationSearchPropertiesOwnProps,
    IComponentPropertiesBaseProps,
    WithTranslation {}

export interface ApplicationSearchPropertiesState
  extends IComponentPropertiesBaseState {}

export class ApplicationSearchProperties extends ComponentPropertiesBase<
  ApplicationSearchPropertiesProps,
  ApplicationSearchPropertiesState,
  ApplicationSearchPropertyModel
> {
  public static defaultProps = {
    isProcessingData: false,
  };

  formRef = React.createRef<IFormInstance>();

  public state: Readonly<ApplicationSearchPropertiesState> = {};

  getPropertiesModel(): ApplicationSearchPropertyModel {
    const { component } = this.props;
    const properties = new ApplicationSearchPropertyModel();
    properties.init(component);

    return properties;
  }

  public onFinish = (values: IFormValues) => {
    const { component } = this.props;

    if (!component) {
      return;
    }

    component.Name = values.Name;
  };

  public onCellTypePropertyChange = (value: IChooseValue) => {
    this.properties.CellType = CellTypeHelper.getValue(value as string);
    this.onPropertyChange("CellType");
  };

  public renderCellTypeProperty = () => {
    const { isProcessingData, t } = this.props;

    return (
      <Form.Item
        name="CellType"
        label={t("COMPONENT_PROPERTIES__CELL_TYPE")}
        initialValue={this.properties.CellType}
        wrapperCol={{
          xs: {
            span: 24,
            offset: 0,
          },
          sm: {
            span: 16,
            offset: 0,
          },
          md: {
            span: 8,
            offset: 0,
          },
          lg: {
            span: 6,
            offset: 0,
          },
        }}
      >
        <Choose
          disabled={isProcessingData}
          placeholder={t("COMPONENT_PROPERTIES__CELL_TYPE_PLACEHOLDER")}
          defaultValue={CellType.Default}
          onChange={this.onCellTypePropertyChange}
        >
          {CellTypeHelper.getOptions(ScreenType.Search)}
        </Choose>
      </Form.Item>
    );
  };

  public render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 8 },
        lg: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 16 },
        lg: { span: 20 },
      },
    };

    return (
      <Form
        name="ApplicationSearchProperties"
        ref={this.formRef}
        {...formItemLayout}
        onFinish={this.onFinish}
      >
        <Row direction="column" justify="space-between" className="full-height">
          <Col>{this.renderCellTypeProperty()}</Col>
        </Row>
      </Form>
    );
  }
}
