import {
  AppConfig,
  AppKey,
  IApplicationConfigurationModel,
  IStateModel,
  TimeHelper,
} from "@bms/common-services";
import {
  Button,
  Col,
  Form,
  IFormValues,
  Input,
  Link,
  Row,
  SectionGrid,
  SectionGridItem,
  Text,
} from "@bms/common-ui";
import React from "react";
import { WithTranslation } from "react-i18next";
import { Action, ActionCreator } from "redux";
import "./ApplicationConfigurationDetailsForm.scss";

export interface IApplicationConfigurationDetailsFormProps
  extends WithTranslation {
  actionType?: string;
  configuration: IStateModel<IApplicationConfigurationModel>;
  updateConfiguration: ActionCreator<Action>;
  isActive: boolean;
}

export class ApplicationConfigurationDetailsForm extends React.PureComponent<
  IApplicationConfigurationDetailsFormProps
> {
  public static defaultProps = {
    isActive: false,
  };

  public onFinish = (values: IFormValues) => {
    const { configuration, updateConfiguration } = this.props;

    if (!configuration?.Data) {
      return;
    }

    configuration.Data.Name = values.Name;

    updateConfiguration(configuration.Data);
  };

  public renderNameField = () => {
    const { t, configuration } = this.props;

    return (
      <Form.Item
        name="Name"
        label={t("MODEL_NAME")}
        initialValue={configuration?.Data?.Name}
      >
        <Input
          disabled={configuration.IsProcessing}
          placeholder={t(
            "APPLICATION_CONFIGURATION_DETAILS_FORM__NAME_PLACEHOLDER"
          )}
        />
      </Form.Item>
    );
  };

  public render() {
    const { configuration, t } = this.props;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 12 },
        md: { span: 10 },
        lg: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 },
        md: { span: 14 },
        lg: { span: 18 },
      },
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
        md: {
          span: 12,
          offset: 12,
        },
        lg: {
          span: 12,
          offset: 12,
        },
      },
    };

    return (
      <SectionGrid>
        <SectionGridItem
          header={t(
            "APPLICATION_CONFIGURATION_DETAILS_FORM__GENERAL_INFORMATION"
          )}
        >
          <Form
            name="ApplicationConfigurationDetailsForm"
            {...formItemLayout}
            className="ApplicationConfigurationDetailsForm"
            onFinish={this.onFinish}
          >
            <Row
              direction="column"
              justify="space-between"
              className="full-height"
            >
              <Col>
                <Form.Item
                  label={t("APPLICATION_CONFIGURATION_DETAILS_FORM__APP_KEY")}
                >
                  <label>{configuration?.Data?.ApplicationKey}</label>
                </Form.Item>
                {this.renderNameField()}
                <Form.Item
                  label={t("APPLICATION_CONFIGURATION_DETAILS_FORM__VERSION")}
                >
                  <label>{configuration?.Data?.VersionNumber}</label>
                </Form.Item>
                <Form.Item {...tailFormItemLayout}>
                  <Button
                    style={{ float: "right" }}
                    type="primary"
                    htmlType="submit"
                    loading={configuration.IsProcessing}
                  >
                    {t("BUTTON_SAVE")}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </SectionGridItem>
        <SectionGridItem
          header={t("APPLICATION_CONFIGURATION_DETAILS_FORM__PUBLICATION")}
        >
          <Form
            name="ApplicationConfigurationDetailsForm"
            {...formItemLayout}
            className="ApplicationConfigurationDetailsForm"
            onFinish={this.onFinish}
          >
            <Row
              direction="column"
              justify="space-between"
              className="full-height"
            >
              <Col>
                <Form.Item
                  label={t(
                    "APPLICATION_CONFIGURATION_DETAILS_FORM__PUBLICATION_DATE"
                  )}
                >
                  <label>
                    {configuration?.Data?.PublicationDate
                      ? TimeHelper.format(configuration?.Data?.PublicationDate)
                      : null}
                  </label>
                </Form.Item>
                <Form.Item
                  label={t(
                    "APPLICATION_CONFIGURATION_DETAILS_FORM__PUBLISHED_BY"
                  )}
                >
                  <label>{configuration?.Data?.PublishedByFullName}</label>
                </Form.Item>
                {AppConfig.AppKey === AppKey.StudioStandalone &&
                  configuration?.Data?.Url && (
                    <Form.Item
                      label={t("APPLICATION_CONFIGURATION_DETAILS_FORM__URL")}
                    >
                      <Link target="_blank" to={configuration?.Data?.Url}>
                        <Text copyable className="url">{configuration?.Data?.Url}</Text>
                      </Link>
                    </Form.Item>
                  )}
              </Col>
            </Row>
          </Form>
        </SectionGridItem>
      </SectionGrid>
    );
  }
}
