import { useTranslation } from "react-i18next";
import {
  Button,
  IBreadcrumbProps,
  Icon,
  PageContent,
  PageHeader,
  TabPane,
  Tabs,
  Tooltip,
  useAppFeedback,
  useSearchParamsTabs,
} from "@bms/common-ui";
import { useHistory, useLocation, useParams } from "react-router";
import React from "react";
import { ROUTES } from "../../constants";
import { useTestApplication } from "./useTestApplication";
import {
  TestApplicationDetailsTab,
  TestApplicationVersionsTab,
} from "./components";
import { generateBreadcrumb } from "../../../../helpers";

export const TestingApplicationDetails = () => {
  const [activeTabKey] = useSearchParamsTabs("DETAILS");
  const { id } = useParams<{ id: string }>();

  const {
    testApplication,
    refresh,
    deleteApplication,
    updateTestApplication,
    updateState,
  } = useTestApplication({
    applicationId: Number(id),
  });

  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { modal } = useAppFeedback();

  const title =
    testApplication?.Name ?? t("TESTING_APPLICATIONS_DETAILS_TITLE");

  const onBackClick = () => {
    if (location.state && location.state.hasOwnProperty("from")) {
      const state = location.state as { from: Location };
      history.push(state.from);
    } else {
      history.goBack();
    }
  };

  const onDeleteClick = () => {
    if (!testApplication) {
      return;
    }

    modal.confirm({
      title: t("COMMON_DELETE"),
      content: t("TESTING_APPLICATIONS_DELETE_MESSAGE", {
        applicationName: testApplication.Name,
      }),
      okText: t("BUTTON_DELETE"),
      cancelText: t("BUTTON_CANCEL"),
      onOk: () => deleteApplication(testApplication),
    });
  };

  const getBreadcrumbProps = (): IBreadcrumbProps => {
    return generateBreadcrumb([
      {
        path: `${ROUTES.APPLICATIONS}`,
        breadcrumbName: t("TESTING_APPLICATIONS"),
      },
      {
        path: `${ROUTES.APPLICATION_DETAILS}/${id}`,
        breadcrumbName: title,
      },
    ]);
  };

  return (
    <PageContent>
      <PageHeader
        title={title}
        onBack={onBackClick}
        breadcrumb={getBreadcrumbProps()}
        extra={
          <>
            <Tooltip key="reload-tooltip" overlay={t("BUTTON_REFRESH_TITLE")}>
              <Button
                key="action-reload"
                icon={<Icon type="reload" />}
                onClick={refresh}
                title={t("BUTTON_REFRESH_TITLE")}
                shape="circle"
              />
            </Tooltip>
            <Tooltip key="delete-tooltip" overlay={t("DELETE_ELEMENT")}>
              <Button
                danger
                key="action-delete"
                icon={<Icon type="delete" />}
                title={t("BUTTON_DELETE")}
                onClick={onDeleteClick}
                disabled={!Boolean(testApplication)}
                shape="circle"
              />
            </Tooltip>
          </>
        }
      />
      <Tabs activeKey={activeTabKey} destroyInactiveTabPane={true}>
        <TabPane key="DETAILS" tab={t("TESTING_APPLICATIONS_DETAILS_TAB")}>
          <TestApplicationDetailsTab
            testApplication={testApplication}
            updateState={updateState}
            updateTestApplication={updateTestApplication}
          />
        </TabPane>
        <TabPane key="VERSIONS" tab={t("TESTING_APPLICATIONS_VERSIONS_TAB")}>
          <TestApplicationVersionsTab applicationId={Number(id)} />
        </TabPane>
      </Tabs>
    </PageContent>
  );
};
