import { SimpleServiceBase } from "../Base";
import {
  IAssetPriceListModel,
  IAssetPriceModel,
  IAssetPriceSearchFilterModel,
} from "../../models/Asset";
import { Observable, Observer } from "rxjs";
import { HTTP_METHOD } from "../../constants/http";
import { IAssetPaymentModel } from "../../models";
import { AxiosSubscriber } from "../AxiosSubscriber";

export class AssetPriceService extends SimpleServiceBase<
  IAssetPriceModel,
  IAssetPriceListModel,
  number,
  IAssetPriceSearchFilterModel
> {
  get url(): string {
    return "/AssetPrices";
  }

  public saveCollection = (
    data: Partial<IAssetPaymentModel>
  ): Observable<IAssetPaymentModel> =>
    new Observable(
      (observer: Observer<IAssetPaymentModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.PUT,
          url: `${this.url}/SaveCollection`,
        })
    );
}


