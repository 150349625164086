import React from "react";
import { Input as AntInput } from "antd";
import { InputPasswordProps } from "./types";

export class InputPassword extends React.PureComponent<InputPasswordProps> {
  public render() {
    const { className, ...props } = this.props;
    return <AntInput.Password {...props} className="form-field" />;
  }
}
