import { CommonStore, ICommonAppState } from "@bms/common-services";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { TranslationDetails as TranslationDetailsDefinition } from "./TranslationDetails";

const mapStateToProps = (state: ICommonAppState) => ({
  actionType: state.common.actionType,
  isLoadingData: state.common.isLoadingData,
  applications: state.common.applications,
  languages: state.common.languages,
  platforms: state.common.platforms,
  resources: state.common.resourcesByKey,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  selectLanguages: () => {
    return dispatch(CommonStore.Actions.selectLanguages());
  },
  selectPlatforms: () => {
    return dispatch(CommonStore.Actions.selectPlatforms());
  },
  selectApplications: () => {
    return dispatch(CommonStore.Actions.selectApplications());
  },
  selectResource: (resourceKey: string) => {
    return dispatch(CommonStore.Actions.selectResource(resourceKey));
  },
  deleteResourcesByKey: (resourceKey: string) => {
    return dispatch(CommonStore.Actions.deleteResourcesByKey(resourceKey));
  },
});

export const TranslationDetails = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(TranslationDetailsDefinition));
