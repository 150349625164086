import React from "react";
import { useTranslation } from "react-i18next";
import { Action, ActionCreator } from "redux";
import {
  APPLICATION_MAIN_KEY,
  IApplicationModel,
  IErrorModel,
  ILanguageModel,
  IPlatformModel,
  IResourceModel,
} from "@bms/common-services";
import {
  Col,
  Choose,
  ChooseOption,
  Input,
  Row,
  Spin,
  Form,
  IFormValues,
} from "@bms/common-ui";

import "./TranslationDetailsForm.scss";

export interface ITranslationDetailsFormProps {
  actionType?: string;
  languages: ILanguageModel[];
  platforms: IPlatformModel[];
  applications: IApplicationModel[];
  isProcessingData?: boolean;
  error?: IErrorModel;
  updateResource: ActionCreator<Action>;
  insertResource: ActionCreator<Action>;
  isNewTranslation: boolean;
  translation: IResourceModel;
}

export const TranslationDetailsForm: React.FC<ITranslationDetailsFormProps> = (
  props
) => {
  const {
    translation,
    languages,
    platforms,
    applications,
    isNewTranslation,
  } = props;
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
      md: { span: 6 },
      lg: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
      md: { span: 18 },
      lg: { span: 18 },
    },
  };

  const onSubmit = async (values: IFormValues) => {
    const { updateResource, insertResource } = props;

    if (isNewTranslation) {
      await insertResource(values);
    } else {
      await updateResource(values);
    }

    form.resetFields();
  };

  return (
    <Spin spinning={props.isProcessingData}>
      <Form
        id="TranslationDetailsForm"
        form={form}
        {...formItemLayout}
        onFinish={onSubmit}
      >
        <Row direction="column" justify="space-between" className="full-height">
          <Col>
            <Form.Item
              name="ResourceKey"
              label={t("MODEL_KEY")}
              initialValue={translation.ResourceKey || null}
            >
              <Input
                placeholder={t("MODEL_KEY")}
                disabled={!isNewTranslation || !!translation.ResourceKey}
              />
            </Form.Item>
            <Form.Item
              name="LanguageCode"
              label={t("MODEL_LANGUAGE")}
              initialValue={translation.LanguageCode || null}
              rules={[
                {
                  message: t("TRANSLATION_PROVIDE_LANGUAGE"),
                  required: isNewTranslation,
                },
              ]}
            >
              <Choose placeholder={t("MODEL_KEY")} disabled={!isNewTranslation}>
                {languages.map((language) => (
                  <ChooseOption key={language.Code} value={language.Code}>
                    {`${language.Name} (${language.Code})`}
                  </ChooseOption>
                ))}
              </Choose>
            </Form.Item>
            <Form.Item
              name="PlatformCode"
              label={t("MODEL_PLATFORM")}
              initialValue={translation.PlatformCode || null}
              rules={[
                {
                  message: t("TRANSLATION_PROVIDE_VALUE"),
                  required: isNewTranslation,
                },
              ]}
            >
              <Choose
                placeholder={t("MODEL_PLATFORM")}
                disabled={!isNewTranslation}
              >
                {platforms.map((platform) => (
                  <ChooseOption key={platform.Code} value={platform.Code}>
                    {platform.DisplayName}
                  </ChooseOption>
                ))}
              </Choose>
            </Form.Item>
            <Form.Item
              name="ApplicationKey"
              label={t("MODEL_APPLICATION")}
              initialValue={translation.ApplicationKey || APPLICATION_MAIN_KEY}
              rules={[
                {
                  message: t("TRANSLATION_PROVIDE_VALUE"),
                  required: isNewTranslation,
                },
              ]}
            >
              <Choose
                placeholder={t("MODEL_APPLICATION")}
                disabled={!isNewTranslation}
              >
                {applications.map((application) => (
                  <ChooseOption key={application.Key} value={application.Key}>
                    {application.DisplayName}
                  </ChooseOption>
                ))}
              </Choose>
            </Form.Item>
            <Form.Item
              name="ResourceValue"
              label={t("MODEL_VALUE")}
              initialValue={translation.ResourceValue}
              rules={[
                {
                  message: t("TRANSLATION_PROVIDE_VALUE"),
                  required: isNewTranslation,
                },
              ]}
            >
              <Input placeholder={t("MODEL_VALUE")} />
            </Form.Item>
            <Form.Item
              name="UrlLayoutDefinition"
              label={t("COMMON_URL")}
              initialValue={translation.UrlLayoutDefinition}
            >
              <Input placeholder={t("MODEL_VALUE")} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};
