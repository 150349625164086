import React, { useState } from "react";
import {
  Dragger,
  Form,
  Icon,
  IFormValues,
  InputNumber,
  IUploadChangeEvent,
  Spin,
  useAppFeedback,
} from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { IAssetAgeRestrictionModel } from "@bms/common-services";
import { takeRight } from "lodash";

export interface IDictionaryageRestrictionFormProps {
  ageRestriction: IAssetAgeRestrictionModel;
  isProcessing: boolean;
  insertAgeRestriction: (
    ageRestriction: IAssetAgeRestrictionModel,
    contentFiles?: File
  ) => void;

  uploadAgeRestrictionImage: (
    ageRestriction: IAssetAgeRestrictionModel,
    file?: File
  ) => void;
}
interface IAssetContentUploadModalState {
  contentFile?: File;
}

export const DictionaryAgeRestrictionForm = ({
  ageRestriction,
  isProcessing,
  insertAgeRestriction,
  uploadAgeRestrictionImage,
}: IDictionaryageRestrictionFormProps) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { notification } = useAppFeedback();

  const [processing, setProcessing] = useState<boolean>(false);

  const [state, setState] = useState<IAssetContentUploadModalState>({
    contentFile: undefined,
  });

  const isNewAgeRestriction = ageRestriction?.RowVersion === undefined;

  const onFinish = async (values: IFormValues) => {
    const { contentFile } = state;

    const newAgeRestriction = values.ValueMin;
    const newAgeRestrictionModel: IAssetAgeRestrictionModel = {
      ValueMin: newAgeRestriction,
    };

    const data: IAssetAgeRestrictionModel = {
      ...ageRestriction,
      ...newAgeRestrictionModel,
    };

    if (!isNewAgeRestriction) {
      uploadAgeRestrictionImage(data, state.contentFile);
      return;
    }
    if (!contentFile) {
      insertAgeRestriction(data);
      return;
    }
    insertAgeRestriction(data, contentFile);
  };

  const onBeforeContentFileUpload = (file: File) => {
    setState((oldState) => ({
      ...oldState,
      contentFile: file,
    }));

    return true;
  };

  const onChangeContent = (event: IUploadChangeEvent) => {
    const { fileList } = event;
    const latestFiles = takeRight(fileList);

    const fileType = "image";
    const regex = new RegExp(`${fileType}\/.*`);
    if (latestFiles[0]?.type && !regex.test(latestFiles[0].type)) {
      notification.error({
        message: t("UPLOAD_ASSET_CONTENT_INVALID_TYPE"),
      });
      return;
    }

    if (latestFiles?.length > 0) {
      latestFiles[0].status = "done";
    }
  };

  const renderAgeField = () => (
    <Form.Item
      name="ValueMin"
      label={t("MODEL_VALUE", "Value")}
      initialValue={ageRestriction.ValueMin}
    >
      <InputNumber
        disabled={!isNewAgeRestriction}
        parser={(value?: string) =>
          parseInt(value?.replace(/[^\d]/, "") ?? "0", 10)
        }
        min={0}
        max={99}
        placeholder={t("MODEL_VALUE", "Value")}
      />
    </Form.Item>
  );

  const renderUpload = () => (
    <Form.Item key="ContentFile" name="ContentFile" valuePropName="ContentFile">
      <Dragger
        className="AssetContentModal__Dragger"
        name="Upload"
        multiple={false}
        accept={"image/*"}
        beforeUpload={onBeforeContentFileUpload}
        onChange={onChangeContent}
        disabled={processing}
      >
        <p className="ant-upload-drag-icon">
          <Icon type="Inbox" />
        </p>
        <p className="ant-upload-text">{t("DRAG_AND_DROP_INFO")}</p>
      </Dragger>
    </Form.Item>
  );

  return (
    <Spin spinning={isProcessing}>
      <Form
        name="DictionaryAgeRestrictionForm"
        className="DictionaryAgeRestrictionForm"
        form={form}
        onFinish={onFinish}
        layout="vertical"
      >
        {renderAgeField()}
        {renderUpload()}
      </Form>
    </Spin>
  );
};
