import { ICurrenciesState, CurrenciesActionsTypes } from "./types";
import * as Consts from "./consts";

export const initialState: ICurrenciesState = {
  isFetching: false,
};

export const currenciesReducer = (
  state = initialState,
  action: CurrenciesActionsTypes
): ICurrenciesState => {
  switch (action.type) {
    case Consts.SELECT_CURRENCIES: {
      return {
        ...state,
        error: initialState.error,
        isFetching: true,
      };
    }
    case Consts.SELECT_CURRENCIES_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        ...initialState,
      };
    }
    case Consts.SELECT_CURRENCIES_FAILURE: {
      return {
        ...state,
        error: action.error,
        isFetching: initialState.isFetching,
      };
    }
    default:
      return state;
  }
};
