import { AssetModule } from "@bms/common-cms";
import { IAssetReportModelRequest, IErrorModel, ServiceResponse, useDataLoader } from "@bms/common-services";
import { CardGridItem, TileListItem, TileList, CountExtra } from "@bms/common-ui";
import React, { useEffect } from "react";
import "./AnalyticsTile.scss";

interface Props {
  service: () => Promise<ServiceResponse<IAssetReportModelRequest, IErrorModel>>
  title: string;
  subtitle: string;

  // In seconds
  autoRefreshTime?: number;
}

export function AnalyticsTile({title, subtitle, service, autoRefreshTime}: Props) {
  const { data, loading, refresh } = useDataLoader({
    loader: service,
    deps: [],
  });

  useEffect(() => {
    let interval: NodeJS.Timeout;
    
    if (autoRefreshTime) {
      interval = setInterval(() => {
        refresh();
      }, autoRefreshTime * 1000);

    }

    return () => clearInterval(interval);
  }, [autoRefreshTime, refresh])
 
  return (
    <CardGridItem>
    <TileList
      header={title}
      subheader={subtitle}
      headerValue={data?.TotalCount}
      refresh={refresh}
    >
      {!loading ? data?.Entities.map((item) => (
        <TileListItem
          key={item.AssetId}
          title={item.Title}
          cover={item.ImageUrl}
          extra={<CountExtra count={item.Count} />}
          linkTo={
            item.AssetId
              ? `${AssetModule.ROUTES.ASSET_DETAILS}/${item.AssetId}`
              : undefined
          }
        />
      )) : null}
    </TileList>
  </CardGridItem>
  );
}
