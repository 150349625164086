import {
  ApplicationConfigurationStore,
  IErrorModel,
} from "@bms/common-services";
import { NotificationInstance } from "antd/es/notification/interface";

interface IActionHandler {
  triggerListRefresh: () => void;
  notification: NotificationInstance;
  t: any;
  additionalHandlers?: any;
}

export const createActionHandler = ({
  triggerListRefresh,
  notification,
  t,
  additionalHandlers,
}: IActionHandler) => {
  const actionsHandlers: { [key: string]: any } = {
    [ApplicationConfigurationStore.Consts
      .SEARCH_APPLICATION_BRANDING_SUCCESS]: {},
    [ApplicationConfigurationStore.Consts
      .SEARCH_APPLICATION_BRANDING_FAILURE]: {},
    [ApplicationConfigurationStore.Consts
      .INSERT_APPLICATION_BRANDING_SUCCESS]: () => {
      notification.success({
        message: t("BRANDING_ADD_SUCCESS"),
      });
      triggerListRefresh();
      additionalHandlers.closeModal();
    },
    [ApplicationConfigurationStore.Consts
      .UPDATE_APPLICATION_BRANDING_SUCCESS]: () => {
      notification.success({
        message: t("BRANDING_UPDATE_SUCCESS"),
      });
      triggerListRefresh();
      additionalHandlers.closeModal();
    },
    [ApplicationConfigurationStore.Consts
      .UPDATE_APPLICATION_BRANDING_FAILURE]: (
      error: IErrorModel | undefined
    ) => {
      notification.error({
        message: t("BRANDING_UPDATE_FAILURE"),
        description: error?.Message,
      });
    },
    [ApplicationConfigurationStore.Consts
      .DELETE_APPLICATION_BRANDING_SUCCESS]: () => {
      notification.success({
        message: t("BRANDING_DELETE_SUCCESS"),
      });
      triggerListRefresh();
      additionalHandlers.closeModal();
    },
    [ApplicationConfigurationStore.Consts
      .DELETE_APPLICATION_BRANDING_FAILURE]: (
      error: IErrorModel | undefined
    ) => {
      notification.error({
        message: t("BRANDING_DELETE_FAILURE"),
        description: error?.Message,
      });
    },
    default: () => {},
  };

  return (actionType: string, error: IErrorModel | undefined) => {
    try {
      actionsHandlers[actionType as string](error);
      if (typeof additionalHandlers[actionType] === "function") {
        additionalHandlers[actionType](error);
      }
    } catch (err) {
      actionsHandlers.default();
    }
  };
};
