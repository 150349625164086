import { IApplicationComponentModel } from "@bms/common-services";
import React from "react";

export interface IComponentPreviewBaseProps {
  component: IApplicationComponentModel;
  style?: React.CSSProperties;
}

export interface IComponentPreviewBaseState {}

export abstract class ComponentPreviewBase<
  TProps extends IComponentPreviewBaseProps,
  TState extends IComponentPreviewBaseState
> extends React.PureComponent<TProps, TState> {}
