import { Observable, Observer } from "rxjs";
import { HTTP_METHOD } from "../../constants/http";
import {
  UploadFileInfoModel,
  IUploadFileInfoHeaderModel,
  IAssetContentModel,
  IInitMultipartUploadFileModel,
  IUploadMultipartFileInfoModel,
  ICompleteMultipartUploadFileModel,
  IAbortMultipartUploadFileModel,
  IAssetContentUploadFileOptionsModel,
  IAssetContentTranscodeRequestModel,
  IAssetContentPlayInfoModel,
} from "../../models";
import { AxiosSubscriber } from "../AxiosSubscriber";
import { ServiceBase } from "../Base";

export class AssetContentService extends ServiceBase<
  IAssetContentModel,
  number
> {
  get url(): string {
    return "/AssetContents";
  }

  public getPlayInfo = (id: number): Observable<IAssetContentPlayInfoModel> =>
    new Observable(
      (observer: Observer<IAssetContentPlayInfoModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: {
              id,
            },
          },
          method: HTTP_METHOD.GET,
          url: `${this.url}/GetPlayInfo`,
        })
    );

  public getContentMultipartUploadFileInfo = (
    data: IInitMultipartUploadFileModel
  ): Observable<IUploadMultipartFileInfoModel> =>
    new Observable(
      (observer: Observer<IUploadMultipartFileInfoModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: `${this.url}/InitMultipartUploadFile`,
        })
    );

  public completeMultipartUploadFile = (
    data: ICompleteMultipartUploadFileModel
  ): Observable<void> =>
    new Observable(
      (observer: Observer<void>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: `${this.url}/CompleteMultipartUploadFile`,
        })
    );

  public abortMultipartUploadFile = (
    data: IAbortMultipartUploadFileModel
  ): Observable<void> =>
    new Observable(
      (observer: Observer<void>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: `${this.url}/AbortMultipartUploadFile`,
        })
    );

  public uploadFile = (
    uploadInfo: UploadFileInfoModel,
    file: File,
    onProgress?: (e: { percent: number }) => void
  ): Observable<UploadFileInfoModel> =>
    new Observable((observer: Observer<UploadFileInfoModel>) => {
      const _headers =
        uploadInfo.Headers &&
        uploadInfo.Headers.reduce(
          (
            headers: Record<string, string>,
            header: IUploadFileInfoHeaderModel
          ) => {
            headers[header.Key] = header.Value;
            return headers;
          },
          {}
        );

      return new AxiosSubscriber(observer, {
        axiosConfig: {
          headers: {
            ..._headers,
            Authorization: "",
            "Content-Type": file.type,
          },
          onUploadProgress: (e: ProgressEvent) =>
            onProgress?.({ percent: (e.loaded / e.total) * 100 }),
        },
        data: file,
        method: uploadInfo.Method,
        url: uploadInfo.Url,
      });
    });

  public transcode = (
    data: IAssetContentTranscodeRequestModel
  ): Observable<void> =>
    new Observable(
      (observer: Observer<void>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: `${this.url}/Transcode`,
        })
    );

  public checkTranscodingStatusByAssetId = (
    assetId: number
  ): Observable<void> =>
    new Observable(
      (observer: Observer<void>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `${this.url}/CheckTranscodingStatusByAssetId?assetId=${assetId}`,
        })
    );

  public getUploadFileInfo = (
    data: IAssetContentUploadFileOptionsModel
  ): Observable<UploadFileInfoModel> =>
    new Observable(
      (observer: Observer<UploadFileInfoModel>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.POST,
          url: `${this.url}/GetUploadFileInfo`,
          data,
        })
    );
}
