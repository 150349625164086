import React from "react";
import { Popconfirm as AntPopconfirm } from "antd";
import { PopconfirmProps as AntPopconfirmProps } from "antd/lib/popconfirm";

export interface IPopconfirmProps extends AntPopconfirmProps {}

export const Popconfirm = (props: IPopconfirmProps) => {
  const { children, ...rest } = props;
  return <AntPopconfirm {...rest}>{children}</AntPopconfirm>;
};
