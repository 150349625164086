import {
  GuidHelper,
  StorageService,
  OperationResultType,
  IErrorModel,
  ApplicationConfigurationService,
  ApplicationResourceType,
} from "@bms/common-services";
import {
  Dragger,
  Form,
  Icon,
  IUploadChangeEvent,
  IUploadFile,
  useAppFeedback,
} from "@bms/common-ui";
import { takeRight } from "lodash";
import React, { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormModal } from "../../../../../../components";

const applicationConfigurationService = new ApplicationConfigurationService();
const storageService: StorageService = StorageService.getInstance();

interface IApplicationResourceUploadModalProps {
  visible: boolean;
  applicationConfigurationId?: number;
  resourceType?: ApplicationResourceType;
  titleTransKey?: string;
  onCancel?: () => void;
  onSuccess?: (filePath?: string, fileUrl?: string) => void;
}

interface IProgressEvent {
  percent: number;
}

export const ApplicationResourceUploadModal = ({
  visible,
  applicationConfigurationId,
  resourceType = ApplicationResourceType.Image,
  titleTransKey = "CONFIGURATION_RESOURCE_UPLOAD_MODAL__TITLE",
  onCancel,
  onSuccess,
}: IApplicationResourceUploadModalProps) => {
  const { t } = useTranslation();
  const { modal, notification } = useAppFeedback();
  const [form] = Form.useForm();

  const [processing, setProcessing] = useState<boolean>(false);
  const [resourceFile, setResourceFile] = useState<File>();
  const [resourceFiles, setResourceFiles] = useState<IUploadFile[]>([]);

  const abortControllerRef = useRef<AbortController>();

  const resourceKey = useMemo(() => GuidHelper.newGuid(), []);

  const acceptFileTypes = useMemo(() => {
    switch (resourceType) {
      case ApplicationResourceType.Image:
        return "image/*";
      case ApplicationResourceType.Video:
        return "video/*";
      default:
        return "*";
    }
  }, [resourceType]);

  const onChangeContent = (event: IUploadChangeEvent) => {
    const { file, fileList } = event;

    switch (resourceType) {
      case ApplicationResourceType.Image:
        const imageRegex = new RegExp(`image\/.*`);
        if (file.type && !imageRegex.test(file.type)) {
          notification.error({
            message: t("UPLOAD_ASSET_CONTENT_INVALID_TYPE"),
          });
          return;
        }
        break;
      case ApplicationResourceType.Video:
        const videoRegex = new RegExp(`video\/.*`);
        if (file.type && !videoRegex.test(file.type)) {
          notification.error({
            message: t("UPLOAD_ASSET_CONTENT_INVALID_TYPE"),
          });
          return;
        }
        break;
    }

    const files = takeRight(fileList);
    setResourceFiles(files);
  };

  const onUploadProgress = (event: IProgressEvent) => {
    const fileList = takeRight(resourceFiles);

    if (fileList?.length > 0) {
      fileList[0].percent = event.percent || 0;
      fileList[0].status = fileList[0].percent === 100 ? "done" : "uploading";
    }

    setResourceFiles(fileList);
  };

  const onUploadFailed = (error: any) => {
    const appError = error as IErrorModel;
    notification.error({
      message: t("CONFIGURATION_RESOURCE_UPLOAD_MODAL__UPLOAD_FAILURE"),
      description: appError?.Message,
    });
    setProcessing(false);
  };

  const onUploadSuccess = (filePath?: string, fileUrl?: string) => {
    notification.success({
      message: t("CONFIGURATION_RESOURCE_UPLOAD_MODAL__UPLOAD_SUCCESS"),
    });
    setProcessing(false);
    setResourceFiles([]);
    onSuccess?.(filePath, fileUrl);
  };

  const uploadResource = async (file: File) => {
    try {
      if (!applicationConfigurationId) {
        return;
      }

      const uploadFileInfo = await applicationConfigurationService
        .getResourceUploadFileInfo(
          applicationConfigurationId,
          resourceType,
          file.name
        )
        .toPromise();

      abortControllerRef.current = new AbortController();

      storageService
        .uploadFile(
          file,
          uploadFileInfo,
          resourceKey,
          onUploadProgress,
          abortControllerRef.current
        )
        .then((result) => {
          if (result.ResultType === OperationResultType.Ok) {
            onUploadSuccess(uploadFileInfo.Path, uploadFileInfo.AbsolutePath);
          }
        })
        .catch((error) => {
          onUploadFailed(error);
        });
    } catch (error) {
      onUploadFailed(error);
    }
  };

  const onFinish = async () => {
    if (!applicationConfigurationId || !resourceFile) {
      return;
    }

    setProcessing(true);
    await uploadResource(resourceFile);
  };

  const onBeforeContentFileUpload = (file: File) => {
    setResourceFile(file);
    return false;
  };

  const onContentFileChange = (e: IUploadChangeEvent) => {
    return e && e.file && e.file.originFileObj;
  };

  const renderUpload = () => {
    return (
      <Form.Item
        key="ContentFile"
        name="ContentFile"
        valuePropName="ContentFile"
        getValueFromEvent={onContentFileChange}
      >
        <Dragger
          className="AssetContentModal__Dragger"
          name="Upload"
          multiple={false}
          showUploadList={{
            showRemoveIcon: true,
            showPreviewIcon: false,
            showDownloadIcon: false,
          }}
          accept={acceptFileTypes}
          beforeUpload={onBeforeContentFileUpload}
          fileList={resourceFiles}
          onChange={onChangeContent}
          disabled={processing}
        >
          <p className="ant-upload-drag-icon">
            <Icon type="Inbox" />
          </p>
          <p className="ant-upload-text">{t("DRAG_AND_DROP_INFO")}</p>
        </Dragger>
      </Form.Item>
    );
  };

  const onCancelUploading = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    setResourceFile(undefined);
    setResourceFiles([]);
    onCancel?.();
  };

  const onCloseModal = () => {
    if (processing) {
      modal.confirm({
        title: t("CONFIGURATION_RESOURCE_UPLOAD_MODAL__CANCEL_UPLOADING_TITLE"),
        content: t(
          "CONFIGURATION_RESOURCE_UPLOAD_MODAL__CANCEL_UPLOADING_MESSAGE"
        ),
        okText: t("BUTTON_YES"),
        cancelText: t("BUTTON_NO"),
        onOk: onCancelUploading,
      });
    } else {
      onCancelUploading();
    }
  };

  return (
    <FormModal
      isVisible={visible}
      isLoading={false}
      isNewForm={true}
      isDisabled={processing}
      isDeleteButtonEnabled={false}
      createFormTitle={t(titleTransKey)}
      editFormTitle={t(titleTransKey)}
      modalClassName="ApplicationResourceUploadModal"
      submitFormName="ApplicationResourceUploadForm"
      confirmButtonTransKey="BUTTON_UPLOAD"
      onCloseModal={onCloseModal}
    >
      <Form
        form={form}
        name="ApplicationResourceUploadForm"
        className="ApplicationResourceUploadForm"
        onFinish={onFinish}
      >
        {renderUpload()}
      </Form>
    </FormModal>
  );
};
