import {
  GuidHelper,
  IErrorModel,
  ITestApplicationVersionModel,
  OperationResultType,
  StorageService,
  TestApplicationVersionsService,
} from "@bms/common-services";
import {
  Col,
  Dragger,
  Form,
  Icon,
  IFormValues,
  Input,
  IUploadChangeEvent,
  IUploadFile,
  required,
  Row,
  Switch,
  useAppFeedback,
} from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import React, { useRef, useState } from "react";
import { takeRight } from "lodash";

import { ACCEPT_UPLOAD_EXTENSIONS } from "./consts";
import { FormModal } from "../../../../../../components";

interface TestApplicationVersionUploadModalProps {
  applicationId: number;
  createApplicationVersion: (
    data: ITestApplicationVersionModel
  ) => Promise<boolean>;
  visible: boolean;
  onCancel?: () => void;
  onSuccess?: () => void;
}

interface ApplicationVersionState {
  TestApplicationId: number;
  contentFile?: File;
  fileList: IUploadFile[];
  Guid?: string;
}

interface ProgressEvent {
  percent: number;
}

const storageService: StorageService = StorageService.getInstance();
const testApplicationVersionsService = new TestApplicationVersionsService();

export const TestApplicationVersionUploadModal = (
  props: TestApplicationVersionUploadModalProps
) => {
  const {
    applicationId,
    createApplicationVersion,
    visible,
    onCancel,
    onSuccess,
  } = props;

  const [processing, setProcessing] = useState<boolean>(false);
  const [state, setState] = useState<ApplicationVersionState>({
    TestApplicationId: applicationId,
    contentFile: undefined,
    fileList: [],
    Guid: undefined,
  });

  const abortControllerRef = useRef<AbortController>();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { modal, notification } = useAppFeedback();

  const onChangeContent = (event: IUploadChangeEvent) => {
    const { fileList } = event;
    const latestFiles = takeRight(fileList);

    if (latestFiles?.length > 0) {
      latestFiles[0].status = "done";
    }

    setState((oldState) => ({
      ...oldState,
      fileList: latestFiles,
    }));
  };

  const onUploadProgress = (event: ProgressEvent) => {
    const { fileList } = state;
    const latestFiles = takeRight(fileList);

    if (latestFiles?.length > 0) {
      latestFiles[0].percent = event.percent || 0;
      latestFiles[0].status =
        latestFiles[0].percent === 100 ? "done" : "uploading";
    }

    setState((oldState) => ({
      ...oldState,
      fileList: latestFiles,
    }));
  };

  const onUploadFailed = (error: IErrorModel) => {
    notification.error({
      message: t("TESTING_APPLICATIONS_VERSIONS_UPLOAD_FAILURE"),
      description: error?.Message,
    });

    setProcessing(false);
  };

  const onUploadSuccess = () => {
    notification.success({
      message: t("TESTING_APPLICATIONS_VERSIONS_UPLOAD_SUCCESS"),
    });

    setProcessing(false);
    onSuccess?.();
  };

  const onBeforeContentFileUpload = (file: File) => {
    setState((oldState) => ({
      ...oldState,
      contentFile: file,
      Guid: GuidHelper.newGuid(),
    }));

    return true;
  };

  const onContentFileChange = (e: IUploadChangeEvent) => {
    return e && e.file && e.file.originFileObj;
  };

  const upload = async (
    file: File,
    applicationVersion: ITestApplicationVersionModel
  ) => {
    try {
      if (!applicationVersion.Guid) {
        return;
      }

      const uploadFileInfo = await testApplicationVersionsService
        .getUploadFileInfo({
          TestApplicationId: applicationVersion.TestApplicationId,
          TestApplicationVersionGuid: applicationVersion.Guid,
        })
        .toPromise();

      applicationVersion.Url = uploadFileInfo.AbsolutePath;
      abortControllerRef.current = new AbortController();

      storageService
        .uploadFile(
          file,
          uploadFileInfo,
          applicationVersion.Guid,
          onUploadProgress,
          abortControllerRef.current
        )
        .then((result) => {
          if (result.ResultType === OperationResultType.Ok) {
            return createApplicationVersion(applicationVersion);
          }
        })
        .then(() => {
          onUploadSuccess();
        })
        .catch((error) => {
          onUploadFailed(error);
        });
    } catch (error) {
      onUploadFailed(error as IErrorModel);
    }
  };

  const onCancelUploading = () => {
    abortControllerRef.current?.abort();

    setState((oldState) => ({
      ...oldState,
      fileList: [],
      contentFile: undefined,
      Guid: undefined,
    }));
    onCancel?.();
  };

  const onCloseModal = () => {
    if (processing) {
      modal.confirm({
        title: t("TESTING_APPLICATIONS_VERSIONS_CANCEL_UPLOADING_TITLE"),
        content: t("TESTING_APPLICATIONS_VERSIONS_CANCEL_UPLOADING_MESSAGE"),
        okText: t("BUTTON_YES"),
        cancelText: t("BUTTON_NO"),
        onOk: onCancelUploading,
      });
    } else {
      onCancelUploading();
    }
  };

  const onFinish = async (values: IFormValues) => {
    const { contentFile, Guid, TestApplicationId } = state;
    const { Version, UpToDate } = values;

    if (!contentFile) {
      return notification.error({
        message: t("TESTING_APPLICATIONS_VERSIONS_VALIDATION_PROVIDE_FILE"),
      });
    }

    if (!Guid) {
      return;
    }

    setProcessing(true);
    await upload(contentFile, {
      Id: -1,
      TestApplicationId,
      Guid,
      Version,
      UpToDate,
    });
  };

  const renderVersionInput = () => {
    return (
      <Form.Item
        name="Version"
        label={t("TESTING_APPLICATIONS_VERSIONS_VERSION")}
        rules={[required()]}
      >
        <Input placeholder={t("TESTING_APPLICATIONS_VERSIONS_VERSION")} />
      </Form.Item>
    );
  };

  const renderUpload = () => {
    return (
      <Form.Item
        key="ContentFile"
        name="ContentFile"
        valuePropName="ContentFile"
        getValueFromEvent={onContentFileChange}
      >
        <Dragger
          name="Upload"
          multiple={false}
          showUploadList={{
            showRemoveIcon: true,
            showPreviewIcon: false,
            showDownloadIcon: false,
          }}
          beforeUpload={onBeforeContentFileUpload}
          fileList={state.fileList}
          onChange={onChangeContent}
          disabled={processing}
          accept={ACCEPT_UPLOAD_EXTENSIONS}
          progress={{
            strokeWidth: 10,
            format: (percent) =>
              percent && `${parseFloat(percent.toFixed(2))}%`,
          }}
        >
          <p className="ant-upload-drag-icon">
            <Icon type="Inbox" />
          </p>
          <p className="ant-upload-text">{t("DRAG_AND_DROP_INFO")}</p>
        </Dragger>
      </Form.Item>
    );
  };

  const renderUpToDate = () => {
    return (
      <Form.Item>
        <Row gutter={8}>
          <Col>
            <Form.Item name="UpToDate" valuePropName="checked">
              <Switch />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="UpToDate">
              <label>{t("MODEL_UP_TO_DATE")}</label>
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>
    );
  };

  return (
    <FormModal
      isVisible={visible}
      isLoading={false}
      isNewForm={true}
      isDisabled={processing}
      isDeleteButtonEnabled={false}
      createFormTitle={t("TESTING_APPLICATIONS_VERSIONS_ADD_HEADER")}
      modalClassName="TestApplicationVersionUploadModal"
      submitFormName="TestApplicationVersionUploadForm"
      onCloseModal={onCloseModal}
    >
      <Form
        name="TestApplicationVersionUploadForm"
        form={form}
        onFinish={onFinish}
        initialValues={{
          UpToDate: true,
        }}
        layout="vertical"
        preserve={false}
      >
        <Row direction="column" justify="space-between" className="full-height">
          <Col>
            {renderVersionInput()}
            {renderUpload()}
            {renderUpToDate()}
          </Col>
        </Row>
      </Form>
    </FormModal>
  );
};
