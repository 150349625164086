import { cloneDeep, findIndex } from "lodash";
import { IAssetModel } from "../../models";
import * as Consts from "./consts";
import { AssetActionsTypes, IAssetState } from "./types";

export const initialState: IAssetState = {
  assetInAssets: {},
  assetsInAssets: {
    Entities: [],
    TotalCount: 0,
    Filter: {
      PageNumber: 1,
      PageSize: 10,
      IncludeCount: true,
    },
  },
  assetCollection: {},
  assetCollections: {
    Entities: [],
    TotalCount: 0,
    Filter: {
      PageNumber: 1,
      PageSize: 10,
      IncludeCount: true,
    },
  },
  assetCollectionsBrowser: {
    Entities: [],
    TotalCount: 0,
    Filter: {
      PageNumber: 1,
      PageSize: 10,
      IncludeCount: true,
    },
  },
  assetCollectionAssets: {
    Entities: [],
    TotalCount: 0,
    Filter: {
      PageNumber: 1,
      PageSize: 12,
      IncludeCount: true,
    },
  },
  assetsInCollection: {
    Entities: [],
    TotalCount: 0,
    Filter: {
      PageNumber: 1,
      PageSize: 10,
      IncludeCount: true,
    },
  },
  asset: {},
  assets: {
    Entities: [],
    TotalCount: 0,
    Filter: {
      PageNumber: 1,
      PageSize: 10,
      IncludeCount: true,
    },
  },
  assetsBrowser: {
    Entities: [],
    TotalCount: 0,
    Filter: {
      PageNumber: 1,
      PageSize: 10,
      IncludeCount: true,
    },
  },
  details: {},
  upload: {},
  uploadContentFile: {},
  uploadVideo: {
    isProcessing: false,
  },
  assetTypes: {
    isFetching: false,
  },
  assetEventTypes: {
    isFetching: false,
  },
  assetEvent: {
    isFetching: false,
    isProcessing: false,
  },
  assetEvents: {
    isFetching: false,
  },
  assetImageTypes: {
    isFetching: false,
  },
  assetContentTypes: {
    isFetching: false,
  },
  assetContentStreamTypes: {
    isFetching: false,
  },
  assetImages: {
    isProcessing: false,
  },
  assetComments: {
    Entities: [],
    TotalCount: 0,
    Filter: {
      PageNumber: 1,
      PageSize: 10,
      IncludeCount: true,
      AssetId: -1,
    },
  },
  assetComment: {
    isFetching: false,
    isProcessing: false,
  },
  uploadFileInfo: {
    isFetching: false,
  },
  uploadContentUrl: {
    isFetching: false,
  },
  assetAgeRestrictions: {
    isFetching: false,
  },
  assetCategories: {
    Entities: [],
    TotalCount: 0,
    Filter: {
      PageNumber: 1,
      PageSize: 10,
      IncludeCount: true,
    },
  },
  assetParent: {
    IsProcessing: false,
  },
  assetPeople: {
    data: {
      Entities: [],
      TotalCount: 0,
      Filter: {
        PageNumber: 1,
        PageSize: 10,
        IncludeCount: true,
      },
    },
    isFetching: false,
  },
  isFetching: false,
  isProcessing: false,
  actionType: undefined,
  action: undefined,
};

export const assetReducer = (
  state = initialState,
  action: AssetActionsTypes
): IAssetState => {
  state.actionType = action.type;
  state.action = action;

  switch (action.type) {
    case Consts.BROWSE_ASSET: {
      return {
        ...state,
        assetsBrowser: {
          ...state.assetsBrowser,
          IsLoading: true,
          Error: undefined,
        },
      };
    }
    case Consts.BROWSE_ASSET_SUCCESS: {
      return {
        ...state,
        assetsBrowser: {
          ...action.payload,
          IsLoading: false,
        },
      };
    }
    case Consts.BROWSE_ASSET_FAILURE: {
      return {
        ...state,
        assetsBrowser: {
          ...state.assetsBrowser,
          IsLoading: false,
          Error: action.error,
        },
      };
    }
    case Consts.SEARCH_ASSET: {
      const { assets } = state;
      assets.IsLoading = true;
      assets.Error = undefined;

      return {
        ...state,
        assets: {
          ...state.assets,
          IsLoading: true,
          Error: undefined,
        },
      };
    }
    case Consts.SEARCH_ASSET_SUCCESS: {
      return {
        ...state,
        assets: {
          ...action.payload,
          IsLoading: false,
        },
      };
    }
    case Consts.SEARCH_ASSET_FAILURE: {
      return {
        ...state,
        assets: {
          ...state.assets,
          IsLoading: false,
          Error: action.error,
        },
      };
    }
    case Consts.SEARCH_ASSET_MORE: {
      return {
        ...state,
        assets: {
          ...state.assets,
          Error: undefined,
          IsLoading: true,
        },
      };
    }
    case Consts.SEARCH_ASSET_MORE_SUCCESS: {
      return {
        ...state,
        assets: {
          ...action.payload,
          Entities: [...state.assets.Entities, ...action.payload.Entities],
          IsLoading: false,
        },
      };
    }
    case Consts.SEARCH_ASSET_MORE_FAILURE: {
      return {
        ...state,
        assets: {
          ...state.assets,
          IsLoading: false,
          Error: action.error,
        },
      };
    }
    case Consts.GET_ASSET: {
      return {
        ...state,
        asset: {
          Data: undefined,
          Error: undefined,
          IsProcessing: true,
        },
      };
    }
    case Consts.GET_ASSET_SUCCESS: {
      return {
        ...state,
        asset: {
          Data: action.payload,
          IsProcessing: false,
        },
      };
    }
    case Consts.GET_ASSET_FAILURE: {
      return {
        ...state,
        asset: {
          Error: action.error,
          IsProcessing: false,
        },
      };
    }
    case Consts.DELETE_ASSET_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
      };
    }
    case Consts.DELETE_ASSET_FAILURE: {
      return {
        ...state,
        error: action.error,
        isProcessing: false,
      };
    }
    case Consts.CREATE_ASSET: {
      return {
        ...state,
        isProcessing: true,
      };
    }
    case Consts.CREATE_ASSET_SUCCESS: {
      return {
        ...state,
        assets: {
          ...state.assets,
          Entities: [...(state.assets.Entities || []), action.payload.data],
        },
        isProcessing: false,
      };
    }
    case Consts.CREATE_ASSET_FAILURE: {
      return {
        ...state,
        error: action.error,
        isProcessing: false,
      };
    }
    case Consts.UPDATE_ASSET: {
      return {
        ...state,
        isProcessing: true,
      };
    }
    case Consts.UPDATE_ASSET_SUCCESS: {
      const newState = cloneDeep(state);
      const entityIndex = findIndex(newState.assets.Entities, {
        Id: action.payload.data.Id,
      });
      newState.assets.Entities[entityIndex] = action.payload
        .data as IAssetModel;
      newState.details[action.payload.data.Id!] = {
        data: action.payload.data,
        isFetching: false,
      };
      newState.isProcessing = false;

      return newState;
    }
    case Consts.UPDATE_ASSET_FAILURE: {
      return {
        ...state,
        isProcessing: false,
      };
    }
    case Consts.GET_ASSET_CONTENT_UPLOAD_URL_SUCCESS: {
      return {
        ...state,
        upload: {
          ...state.upload,
          [action.payload.assetId]: action.payload.data,
        },
      };
    }
    case Consts.UPLOAD_ASSET_CONTENT_FILE_SUCCESS: {
      return {
        ...state,
        uploadContentFile: {
          ...state.uploadContentFile,
          data: action.payload,
        },
      };
    }
    case Consts.UPLOAD_ASSET_CONTENT_FILE_FAILURE: {
      return {
        ...state,
        uploadContentFile: {
          ...state.uploadContentFile,
          data: {},
        },
      };
    }
    case Consts.GET_ASSETS_COLLECTION: {
      return {
        ...state,
        assetCollection: {
          Data: undefined,
          Error: undefined,
          IsProcessing: true,
        },
      };
    }
    case Consts.GET_ASSETS_COLLECTION_SUCCESS: {
      return {
        ...state,
        assetCollection: {
          Data: action.payload,
          IsProcessing: false,
        },
      };
    }
    case Consts.GET_ASSETS_COLLECTION_FAILURE: {
      return {
        ...state,
        assetCollection: {
          Error: action.error,
          IsProcessing: false,
        },
      };
    }
    case Consts.CREATE_ASSETS_COLLECTION: {
      return {
        ...state,
        assetCollection: {
          ...state.assetCollection,
          Error: undefined,
          IsProcessing: true,
        },
      };
    }
    case Consts.CREATE_ASSETS_COLLECTION_SUCCESS: {
      return {
        ...state,
        assetCollection: {
          Data: action.payload,
          IsProcessing: false,
        },
      };
    }
    case Consts.CREATE_ASSETS_COLLECTION_FAILURE: {
      return {
        ...state,
        assetCollection: {
          ...state.assetCollection,
          Error: action.error,
          IsProcessing: false,
        },
      };
    }
    case Consts.UPDATE_ASSETS_COLLECTION: {
      return {
        ...state,
        assetCollection: {
          ...state.assetCollection,
          Error: undefined,
          IsProcessing: true,
        },
      };
    }
    case Consts.UPDATE_ASSETS_COLLECTION_SUCCESS: {
      return {
        ...state,
        assetCollection: {
          Data: action.payload,
          IsProcessing: false,
        },
      };
    }
    case Consts.UPDATE_ASSETS_COLLECTION_FAILURE: {
      return {
        ...state,
        assetCollection: {
          ...state.assetCollection,
          Error: action.error,
          IsProcessing: false,
        },
      };
    }
    case Consts.DELETE_ASSETS_COLLECTION: {
      return {
        ...state,
        assetCollection: {
          ...state.assetCollection,
          Error: undefined,
          IsProcessing: true,
        },
      };
    }
    case Consts.DELETE_ASSETS_COLLECTION_SUCCESS: {
      return {
        ...state,
        assetCollection: {
          Data: undefined,
          IsProcessing: false,
        },
      };
    }
    case Consts.DELETE_ASSETS_COLLECTION_FAILURE: {
      return {
        ...state,
        assetCollection: {
          ...state.assetCollection,
          Error: action.error,
          IsProcessing: false,
        },
      };
    }
    case Consts.SELECT_ASSET_IN_COLLECTION: {
      return {
        ...state,
        assetsInCollection: {
          ...state.assetsInCollection,
          IsLoading: true,
        },
      };
    }
    case Consts.SELECT_ASSET_IN_COLLECTION_SUCCESS: {
      return {
        ...state,
        assetsInCollection: {
          ...state.assetsInCollection,
          ...action.payload,
          IsLoading: false,
        },
      };
    }
    case Consts.SELECT_ASSET_IN_COLLECTION_FAILURE: {
      return {
        ...state,
        assetsInCollection: {
          ...state.assetsInCollection,
          Error: action.error,
          IsLoading: false,
        },
      };
    }
    case Consts.ADD_ASSET_IN_COLLECTION: {
      return {
        ...state,
        isProcessing: true,
      };
    }
    case Consts.ADD_ASSET_IN_COLLECTION_SUCCESS: {
      return {
        ...state,
        assetInCollection: action.payload,
        isProcessing: false,
      };
    }
    case Consts.ADD_ASSET_IN_COLLECTION_FAILURE: {
      return {
        ...state,
        error: action.error,
        isProcessing: false,
      };
    }
    case Consts.UPDATE_ASSET_IN_COLLECTION: {
      return {
        ...state,
        isProcessing: true,
      };
    }
    case Consts.UPDATE_ASSET_IN_COLLECTION_SUCCESS: {
      return {
        ...state,
        assetInCollection: action.payload,
        isProcessing: false,
      };
    }
    case Consts.UPDATE_ASSET_IN_COLLECTION_FAILURE: {
      return {
        ...state,
        error: action.error,
        isProcessing: false,
      };
    }
    case Consts.DELETE_ASSET_IN_COLLECTION: {
      return {
        ...state,
        isProcessing: true,
      };
    }
    case Consts.DELETE_ASSET_IN_COLLECTION_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
      };
    }
    case Consts.DELETE_ASSET_IN_COLLECTION_FAILURE: {
      return {
        ...state,
        error: action.error,
        isProcessing: false,
      };
    }
    case Consts.SEARCH_ASSETS_COLLECTION: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case Consts.SEARCH_ASSETS_COLLECTION_SUCCESS: {
      return {
        ...state,
        assetCollections: action.payload,
        isFetching: false,
      };
    }
    case Consts.SEARCH_ASSETS_COLLECTION_FAILURE: {
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    }
    case Consts.BROWSE_ASSETS_COLLECTION: {
      return {
        ...state,
        assetCollectionsBrowser: {
          ...state.assetCollectionsBrowser,
          Entities: [],
          IsLoading: true,
        },
      };
    }
    case Consts.BROWSE_ASSETS_COLLECTION_SUCCESS: {
      return {
        ...state,
        assetCollectionsBrowser: {
          ...action.payload,
          IsLoading: false,
        },
      };
    }
    case Consts.BROWSE_ASSETS_COLLECTION_FAILURE: {
      return {
        ...state,
        assetCollectionsBrowser: {
          ...state.assetCollectionsBrowser,
          IsLoading: false,
          Error: action.error,
        },
      };
    }
    case Consts.BROWSE_ASSETS_COLLECTION_CANCEL: {
      return {
        ...state,
        assetCollectionsBrowser: {
          ...state.assetCollectionsBrowser,
          IsLoading: false,
        },
      };
    }
    case Consts.SEARCH_ASSETS_BY_ASSET_COLLECTION_ID: {
      return {
        ...state,
        assetCollectionAssets: {
          ...state.assetCollectionAssets,
          Entities: [],
          IsLoading: true,
        },
      };
    }
    case Consts.SEARCH_ASSETS_BY_ASSET_COLLECTION_ID_SUCCESS: {
      return {
        ...state,
        assetCollectionAssets: {
          ...state.assetCollectionAssets,
          ...action.payload,
          IsLoading: false,
        },
      };
    }
    case Consts.SEARCH_ASSETS_BY_ASSET_COLLECTION_ID_FAILURE: {
      return {
        ...state,
        assetCollectionAssets: {
          ...state.assetCollectionAssets,
          Error: action.error,
          IsLoading: false,
        },
      };
    }
    case Consts.GET_ASSET_TYPES: {
      return {
        ...state,
        assetTypes: {
          data: [],
          isFetching: true,
        },
      };
    }
    case Consts.GET_ASSET_TYPES_SUCCESS: {
      return {
        ...state,
        assetTypes: {
          data: action.payload.data,
          isFetching: false,
        },
      };
    }
    case Consts.GET_ASSET_TYPES_FAILURE: {
      return {
        ...state,
        assetTypes: {
          error: action.payload.error,
          isFetching: false,
        },
      };
    }
    case Consts.GET_ASSET_IMAGE_TYPES: {
      return {
        ...state,
        assetImageTypes: {
          data: [],
          isFetching: true,
        },
      };
    }
    case Consts.GET_ASSET_IMAGE_TYPES_SUCCESS: {
      return {
        ...state,
        assetImageTypes: {
          data: action.payload.data,
          isFetching: false,
        },
      };
    }
    case Consts.GET_ASSET_IMAGE_TYPES_FAILURE: {
      return {
        ...state,
        assetImageTypes: {
          error: action.payload.error,
          isFetching: false,
        },
      };
    }
    case Consts.GET_ASSET_CONTENT_TYPES: {
      return {
        ...state,
        assetContentTypes: {
          data: [],
          isFetching: true,
        },
      };
    }
    case Consts.GET_ASSET_CONTENT_TYPES_SUCCESS: {
      return {
        ...state,
        assetContentTypes: {
          data: action.payload.data,
          isFetching: false,
        },
      };
    }
    case Consts.GET_ASSET_CONTENT_TYPES_FAILURE: {
      return {
        ...state,
        assetContentTypes: {
          error: action.payload.error,
          isFetching: false,
        },
      };
    }
    case Consts.GET_ASSET_CONTENT_STREAM_TYPES: {
      return {
        ...state,
        assetContentStreamTypes: {
          data: [],
          isFetching: true,
        },
      };
    }
    case Consts.GET_ASSET_CONTENT_STREAM_TYPES_SUCCESS: {
      return {
        ...state,
        assetContentStreamTypes: {
          data: action.payload.data,
          isFetching: false,
        },
      };
    }
    case Consts.GET_ASSET_CONTENT_STREAM_TYPES_FAILURE: {
      return {
        ...state,
        assetContentStreamTypes: {
          error: action.payload.error,
          isFetching: false,
        },
      };
    }
    case Consts.GET_ASSET_AGE_RESTRICTIONS: {
      return {
        ...state,
        assetAgeRestrictions: {
          data: [],
          isFetching: true,
        },
      };
    }
    case Consts.GET_ASSET_AGE_RESTRICTIONS_SUCCESS: {
      return {
        ...state,
        assetAgeRestrictions: {
          data: action.payload.data,
          isFetching: false,
        },
      };
    }
    case Consts.GET_ASSET_AGE_RESTRICTIONS_FAILURE: {
      return {
        ...state,
        assetAgeRestrictions: {
          error: action.payload.error,
          isFetching: false,
        },
      };
    }
    case Consts.SELECT_ASSET_PARENT: {
      return {
        ...state,
        assetParent: {
          Data: [],
          IsProcessing: true,
        },
      };
    }
    case Consts.SELECT_ASSET_PARENT_SUCCESS: {
      return {
        ...state,
        assetParent: {
          Data: action.payload.data,
          IsProcessing: false,
        },
      };
    }
    case Consts.SELECT_ASSET_PARENT_FAILURE: {
      return {
        ...state,
        assetParent: {
          Error: action.payload.error,
          IsProcessing: false,
        },
      };
    }
    case Consts.SEARCH_ASSET_CATEGORIES: {
      return {
        ...state,
        assetCategories: {
          ...state.assetCategories,
          IsLoading: true,
        },
      };
    }
    case Consts.SEARCH_ASSET_CATEGORIES_SUCCESS: {
      return {
        ...state,
        assetCategories: {
          ...action.payload,
        },
      };
    }
    case Consts.SEARCH_ASSET_CATEGORIES_FAILURE: {
      return {
        ...state,
        assetCategories: {
          Entities: [],
          TotalCount: 0,
          Error: action.error,
          IsLoading: false,
        },
      };
    }
    case Consts.SEARCH_ASSET_PEOPLE: {
      return {
        ...state,
        assetPeople: {
          isFetching: true,
        },
      };
    }
    case Consts.SEARCH_ASSET_PEOPLE_SUCCESS: {
      return {
        ...state,
        assetPeople: {
          data: action.payload,
          isFetching: false,
        },
      };
    }
    case Consts.SEARCH_ASSET_PEOPLE_FAILURE: {
      return {
        ...state,
        assetPeople: {
          error: action.error,
          isFetching: false,
        },
      };
    }
    case Consts.GET_UPLOAD_FILE_INFO: {
      return {
        ...state,
        uploadFileInfo: {
          isFetching: true,
        },
      };
    }
    case Consts.GET_UPLOAD_FILE_INFO_SUCCESS: {
      return {
        ...state,
        uploadFileInfo: {
          data: action.payload,
          isFetching: false,
        },
      };
    }
    case Consts.GET_UPLOAD_FILE_INFO_FAILURE: {
      return {
        ...state,
        uploadFileInfo: {
          error: action.error,
          isFetching: false,
        },
      };
    }
    case Consts.GET_CONTENT_UPLOAD_URL: {
      return {
        ...state,
        uploadContentUrl: {
          isFetching: true,
        },
      };
    }
    case Consts.GET_CONTENT_UPLOAD_URL_SUCCESS: {
      return {
        ...state,
        uploadContentUrl: {
          data: action.payload,
          isFetching: false,
        },
      };
    }
    case Consts.GET_CONTENT_UPLOAD_URL_FAILURE: {
      return {
        ...state,
        uploadContentUrl: {
          error: action.error,
          isFetching: false,
        },
      };
    }
    case Consts.UPDATE_ASSET_IMAGE: {
      return {
        ...state,
        assetImages: {
          isProcessing: true,
        },
      };
    }
    case Consts.UPDATE_ASSET_IMAGE_SUCCESS: {
      return {
        ...state,
        assetImages: {
          isProcessing: false,
          data: action.payload,
        },
      };
    }
    case Consts.UPDATE_ASSET_IMAGE_FAILURE: {
      return {
        ...state,
        assetImages: {
          isProcessing: false,
          error: action.error,
        },
      };
    }
    case Consts.DELETE_ASSET_IMAGE: {
      return {
        ...state,
        assetImages: {
          isProcessing: true,
        },
      };
    }
    case Consts.DELETE_ASSET_IMAGE_SUCCESS: {
      return {
        ...state,
        assetImages: {
          isProcessing: false,
          data: action.payload,
        },
      };
    }
    case Consts.DELETE_ASSET_IMAGE_FAILURE: {
      return {
        ...state,
        assetImages: {
          isProcessing: false,
          error: action.error,
        },
      };
    }
    case Consts.ADD_ASSET_IMAGE: {
      return {
        ...state,
        assetImages: {
          isProcessing: true,
        },
      };
    }
    case Consts.ADD_ASSET_IMAGE_SUCCESS: {
      return {
        ...state,
        assetImages: {
          isProcessing: false,
          data: action.payload,
        },
      };
    }
    case Consts.ADD_ASSET_IMAGE_FAILURE: {
      return {
        ...state,
        assetImages: {
          isProcessing: false,
          error: action.error,
        },
      };
    }
    case Consts.ADD_ASSET_CONTENT: {
      return {
        ...state,
        isProcessing: true,
      };
    }
    case Consts.ADD_ASSET_CONTENT_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
      };
    }
    case Consts.ADD_ASSET_CONTENT_FAILURE: {
      return {
        ...state,
        error: action.error,
        isProcessing: false,
      };
    }
    case Consts.UPDATE_ASSET_CONTENT_URL: {
      return {
        ...state,
        asset: {
          ...state.asset,
          IsProcessing: true,
        },
      };
    }
    case Consts.UPDATE_ASSET_CONTENT_URL_SUCCESS: {
      return {
        ...state,
        asset: {
          Data: action.payload,
          IsProcessing: false,
        },
      };
    }
    case Consts.UPDATE_ASSET_CONTENT_URL_FAILURE: {
      return {
        ...state,
        asset: {
          Error: action.error,
          IsProcessing: false,
        },
      };
    }
    case Consts.UPDATE_ASSET_CONTENT: {
      return {
        ...state,
        isProcessing: true,
      };
    }
    case Consts.UPDATE_ASSET_CONTENT_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
      };
    }
    case Consts.UPDATE_ASSET_CONTENT_FAILURE: {
      return {
        ...state,
        error: action.error,
        isProcessing: false,
      };
    }
    case Consts.DELETE_ASSET_CONTENT: {
      return {
        ...state,
        isProcessing: true,
      };
    }
    case Consts.DELETE_ASSET_CONTENT_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
      };
    }
    case Consts.DELETE_ASSET_CONTENT_FAILURE: {
      return {
        ...state,
        error: action.error,
        isProcessing: false,
      };
    }
    case Consts.GET_ASSET_CONTENT_UPLOAD_FILE_INFO: {
      return {
        ...state,
        uploadContentUrl: {
          isFetching: true,
        },
      };
    }
    case Consts.GET_ASSET_CONTENT_UPLOAD_FILE_INFO_SUCCESS: {
      return {
        ...state,
        uploadContentUrl: {
          data: action.payload.data,
          isFetching: false,
        },
      };
    }
    case Consts.GET_ASSET_CONTENT_UPLOAD_FILE_INFO_FAILURE: {
      return {
        ...state,
        uploadContentUrl: {
          error: action.error,
          isFetching: false,
        },
      };
    }
    case Consts.GET_ASSET_COMMENT: {
      return {
        ...state,
        assetComment: {
          isFetching: true,
        },
      };
    }
    case Consts.GET_ASSET_COMMENT_SUCCESS: {
      return {
        ...state,
        assetComment: {
          data: action.payload,
          isFetching: false,
        },
      };
    }
    case Consts.GET_ASSET_COMMENT_FAILURE: {
      return {
        ...state,
        assetComment: {
          error: action.error,
          isFetching: false,
        },
      };
    }
    case Consts.SEARCH_ASSET_COMMENTS: {
      return {
        ...state,
        assetComments: {
          ...state.assetComments,
          Entities: [],
          IsLoading: true,
        },
      };
    }
    case Consts.SEARCH_ASSET_COMMENTS_SUCCESS: {
      return {
        ...state,
        assetComments: {
          ...action.payload,
          IsLoading: false,
        },
      };
    }
    case Consts.SEARCH_ASSET_COMMENTS_FAILURE: {
      return {
        ...state,
        assetComments: {
          ...state.assetComments,
          IsLoading: false,
          Error: action.error,
        },
      };
    }
    case Consts.SEARCH_ASSET_COMMENTS_MORE: {
      return {
        ...state,
        assetComments: {
          ...state.assetComments,
        },
      };
    }
    case Consts.SEARCH_ASSET_COMMENTS_MORE_SUCCESS: {
      return {
        ...state,
        assetComments: {
          ...action.payload,
          IsLoading: false,
        },
      };
    }
    case Consts.SEARCH_ASSET_COMMENTS_MORE_FAILURE: {
      return {
        ...state,
        assetComments: {
          ...state.assetComments,
          IsLoading: false,
          Error: action.error,
        },
      };
    }
    case Consts.ADD_ASSET_COMMENT: {
      return {
        ...state,
        assetComment: {
          isProcessing: true,
        },
      };
    }
    case Consts.ADD_ASSET_COMMENT_SUCCESS: {
      return {
        ...state,
        assetComment: {
          isProcessing: false,
          data: action.payload,
        },
      };
    }
    case Consts.ADD_ASSET_COMMENT_FAILURE: {
      return {
        ...state,
        assetComment: {
          isProcessing: false,
          error: action.error,
        },
      };
    }
    case Consts.UPLOAD_VIDEO: {
      return {
        ...state,
        uploadVideo: {
          isProcessing: true,
        },
      };
    }
    case Consts.UPLOAD_VIDEO_SUCCESS: {
      return {
        ...state,
        uploadVideo: {
          isProcessing: false,
          // data: action.payload,
        },
      };
    }
    case Consts.UPLOAD_VIDEO_FAILURE: {
      return {
        ...state,
        uploadVideo: {
          isProcessing: false,
          // error: action.error,
        },
      };
    }
    case Consts.GET_ASSET_EVENT_TYPES: {
      return {
        ...state,
        assetEventTypes: {
          data: [],
          isFetching: true,
        },
      };
    }
    case Consts.GET_ASSET_EVENT_TYPES_SUCCESS: {
      return {
        ...state,
        assetEventTypes: {
          data: action.payload.data,
          isFetching: false,
        },
      };
    }
    case Consts.GET_ASSET_EVENT_TYPES_FAILURE: {
      return {
        ...state,
        assetEventTypes: {
          error: action.payload.error,
          isFetching: false,
        },
      };
    }
    case Consts.GET_ASSET_EVENT: {
      return {
        ...state,
        assetEvent: {
          isProcessing: false,
          isFetching: true,
        },
      };
    }
    case Consts.GET_ASSET_EVENT_SUCCESS: {
      return {
        ...state,
        assetEvent: {
          isProcessing: false,
          isFetching: false,
          data: action.payload,
        },
      };
    }
    case Consts.GET_ASSET_EVENT_FAILURE: {
      return {
        ...state,
        assetEvent: {
          isProcessing: false,
          isFetching: false,
          error: action.error,
        },
      };
    }
    case Consts.ADD_ASSET_EVENT: {
      return {
        ...state,
        assetEvent: {
          isFetching: false,
          isProcessing: true,
        },
      };
    }
    case Consts.ADD_ASSET_EVENT_SUCCESS: {
      return {
        ...state,
        assetEvent: {
          isFetching: false,
          isProcessing: false,
          data: action.payload,
        },
      };
    }
    case Consts.ADD_ASSET_EVENT_FAILURE: {
      return {
        ...state,
        assetEvent: {
          isFetching: false,
          isProcessing: false,
          error: action.error,
        },
      };
    }
    case Consts.SEARCH_ASSET_EVENTS: {
      return {
        ...state,
        assetEvents: {
          isFetching: true,
        },
      };
    }
    case Consts.SEARCH_ASSET_EVENTS_SUCCESS: {
      return {
        ...state,
        assetEvents: {
          data: action.payload,
          isFetching: false,
        },
      };
    }
    case Consts.SEARCH_ASSET_EVENTS_FAILURE: {
      return {
        ...state,
        assetEvents: {
          error: action.error,
          isFetching: false,
        },
      };
    }
    case Consts.INSERT_ASSET_CATEGORY: {
      return {
        ...state,
        isProcessing: true,
      };
    }
    case Consts.INSERT_ASSET_CATEGORY_SUCCESS: {
      return {
        ...state,
        assetCategory: { ...action.payload },
        isProcessing: false,
      };
    }
    case Consts.INSERT_ASSET_CATEGORY_FAILURE: {
      return {
        ...state,
        error: action.error,
        isProcessing: false,
      };
    }
    case Consts.UPDATE_ASSET_CATEGORY: {
      return {
        ...state,
        isProcessing: true,
      };
    }
    case Consts.UPDATE_ASSET_CATEGORY_SUCCESS: {
      return {
        ...state,
        assetCategory: action.payload,
        isProcessing: false,
      };
    }
    case Consts.UPDATE_ASSET_CATEGORY_FAILURE: {
      return {
        ...state,
        error: action.error,
        isProcessing: false,
      };
    }
    case Consts.DELETE_ASSET_CATEGORY: {
      return {
        ...state,
        isProcessing: true,
      };
    }
    case Consts.DELETE_ASSET_CATEGORY_SUCCESS: {
      return {
        ...state,
        assetCategory: undefined,
        isProcessing: false,
      };
    }
    case Consts.DELETE_ASSET_CATEGORY_FAILURE: {
      return {
        ...state,
        error: action.error,
        isProcessing: false,
      };
    }

    case Consts.SELECT_ASSETS_IN_ASSET: {
      return {
        ...state,
        assetsInAssets: {
          ...state.assetsInAssets,
          IsLoading: true,
          Error: undefined,
        },
      };
    }

    case Consts.SELECT_ASSETS_IN_ASSET_SUCCESS:
      return {
        ...state,
        assetsInAssets: {
          ...action.payload,
          IsLoading: false,
        },
      };

    case Consts.SELECT_ASSETS_IN_ASSET_FAILURE:
      return {
        ...state,
        assetsInAssets: {
          ...state.assetsInAssets,
          IsLoading: false,
          Error: action.error,
        },
      };

    case Consts.DELETE_ASSET_IN_ASSET:
    case Consts.UPDATE_ASSET_IN_ASSET:
    case Consts.INSERT_ASSET_IN_ASSET: {
      return {
        ...state,
        assetInAssets: {
          ...state.assetInAssets,
          IsProcessing: true,
          Error: undefined,
        },
      };
    }

    case Consts.DELETE_ASSET_IN_ASSET_SUCCESS: {
      return {
        ...state,
        assetInAssets: {
          ...state.assetInAssets,
          IsProcessing: false,
        },
      };
    }

    case Consts.UPDATE_ASSET_IN_ASSET_SUCCESS:
    case Consts.INSERT_ASSET_IN_ASSET_SUCCESS: {
      return {
        ...state,
        assetInAssets: {
          Data: action.payload,
          IsProcessing: false,
        },
      };
    }

    case Consts.DELETE_ASSET_IN_ASSET_FAILURE:
    case Consts.UPDATE_ASSET_IN_ASSET_FAILURE:
    case Consts.INSERT_ASSET_IN_ASSET_FAILURE: {
      return {
        ...state,
        assetInAssets: {
          IsProcessing: false,
          Error: action.error,
        },
      };
    }

    default:
      return state;
  }
};
