import React, { PropsWithChildren, useMemo, useCallback } from "react";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { ITableColumnProps, ITableProps, Table } from "../Table";
import "./TableWithDraggableSorter.scss";
import { ArrowDownOutlined, MenuOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { Tooltip } from "../Tooltip";

export interface ITableWithDraggableSorter<T> extends ITableProps<T> {
  dragType: "handler" | "row";
  dragDisabled?: boolean;
  handlerRight?: boolean;
  renderHandleWrapper?: (handle: React.ReactNode) => React.ReactNode;
  onMoveRow: (dragIndex: number, hoverIndex: number) => void;
}

interface ISortResult {
  oldIndex: number;
  newIndex: number;
}

const TableSortableItem = SortableElement((props: any) => <tr {...props} />);

const TableSortableContainer = SortableContainer((props: any) => (
  <tbody {...props} />
));

const SortableDragHandle = SortableHandle(() => (
  <MenuOutlined style={{ cursor: "move" }} />
));

const DragHandle = ({ disabled }: { disabled?: boolean }) => {
  const handle = disabled ? (
    <MenuOutlined style={{ cursor: "not-allowed", opacity: "0.3" }} />
  ) : (
    <SortableDragHandle />
  );
  return <span>{handle}</span>;
};

export const TableWithDraggableSorter = <T,>(
  props: PropsWithChildren<ITableWithDraggableSorter<T>>
) => {
  const {
    onMoveRow,
    dragType,
    dataSource,
    columns,
    dragDisabled,
    renderHandleWrapper,
    handlerRight,
    ...restProps
  } = props;
  const isHandlerDragType = dragType === "handler";
  const { t } = useTranslation();

  const getColumns = useMemo(() => {
    if (!isHandlerDragType) {
      return columns;
    }

    const sortColumn: ITableColumnProps<T> = {
      title: (
        <Tooltip overlay={t("TABLE_COLUMN_REORDER_TOOLTIP")}>
          <ArrowDownOutlined />
        </Tooltip>
      ),
      dataIndex: "sort",
      className: handlerRight ? undefined : "drag-visible",
      align: "center",
      width: 48,
      render: () => {
        const handle = <DragHandle disabled={dragDisabled} />;
        return renderHandleWrapper
          ? renderHandleWrapper(<span>{handle}</span>)
          : handle;
      },
    };

    return handlerRight
      ? [...(columns || []), sortColumn]
      : [sortColumn, ...(columns || [])];
  }, [
    t,
    columns,
    dragDisabled,
    handlerRight,
    isHandlerDragType,
    renderHandleWrapper,
  ]);

  const onSortEnd = useCallback(
    (data: ISortResult) => {
      if (data.oldIndex !== data.newIndex) {
        onMoveRow(data.oldIndex, data.newIndex);
      }
    },
    [onMoveRow]
  );

  const DraggableBodyRow = useCallback(
    (props: any) => {
      const { style, ...restProps } = props;

      const dragStyle = !isHandlerDragType
        ? {
            cursor: "move",
            ...style,
          }
        : style;

      return (
        <TableSortableItem
          {...restProps}
          index={restProps["data-row-key"] ?? 0}
          style={dragStyle}
        />
      );
    },
    [isHandlerDragType]
  );

  const DraggableContainer = useCallback(
    (props: any) => (
      <TableSortableContainer
        useDragHandle={isHandlerDragType}
        helperClass="row-dragging"
        onSortEnd={onSortEnd}
        lockAxis="y"
        transitionDuration={100}
        distance={10}
        lockToContainerEdges
        disableAutoscroll={false}
        useWindowAsScrollContainer={false}
        pressDelay={isHandlerDragType ? 0 : 200}
        {...props}
      />
    ),
    [isHandlerDragType, onSortEnd]
  );

  return (
    <div className="TableWithDraggableSorter">
      <Table<T>
        {...restProps}
        /// change rowKey to index and populate index attribute in dataSource
        rowKey="index"
        dataSource={dataSource?.map((item, index) => {
          return { ...item, index };
        })}
        columns={getColumns}
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
      />
    </div>
  );
};
