/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  AssetContentService,
  AssetContentType,
  ContentStatus,
  IAssetContentModel,
  IAssetContentTranscodeRequestModel,
  IAssetModel,
  IErrorModel,
  TranscodingFormat,
  useConfig,
} from "@bms/common-services";
import { Alert, CheckboxGroup, Form, IFormValues } from "@bms/common-ui";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { FormModal } from "../../../../components";

const formLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};

const assetContentService = new AssetContentService();

interface IAssetContentTranscodeModalProps {
  visible: boolean;
  asset?: IAssetModel;
  assetContent?: IAssetContentModel;
  onCancel?: () => void;
  onSuccess?: () => void;
}

export const AssetContentTranscodeModal = ({
  visible,
  asset,
  assetContent,
  onCancel,
  onSuccess,
}: IAssetContentTranscodeModalProps) => {
  const { t } = useTranslation();
  const { config } = useConfig();
  const [form] = Form.useForm();

  const [processing, setProcessing] = useState<boolean>(false);
  const [error, setError] = useState<IErrorModel>();

  const onFinish = async (values: IFormValues) => {
    const payload: IAssetContentTranscodeRequestModel = {
      AssetContentId: assetContent?.Id,
      TranscodingFormats: values.TranscodingFormats,
    };

    setProcessing(true);
    setError(undefined);

    try {
      await assetContentService.transcode(payload).toPromise();
      setProcessing(false);
      form.resetFields();
      onSuccess?.();
    } catch (error) {
      setError(error as IErrorModel);
      setProcessing(false);
    }
  };

  const onCloseModal = () => {
    form.resetFields();
    onCancel?.();
  };

  const onTranscodingFormatsChange = () => {};

  const renderInfo = () => {
    return (
      <Form.Item>
        <Alert
          message={t("ASSET_CONTENT_TRANSCODE_BEFORE_START_TITLE")}
          description={t("ASSET_CONTENT_TRANSCODE_BEFORE_START_MESSAGE")}
          type="warning"
          showIcon
        />
      </Form.Item>
    );
  };
  const renderTranscodingFormatsField = () => {
    if (!config?.Asset?.TranscodingFormats) {
      return null;
    }

    var availableFormats = config.Asset.TranscodingFormats.filter(
      (format: string) => {
        switch (format) {
          case TranscodingFormat.MPD:
            return asset?.Contents
              ? asset?.Contents?.findIndex(
                  (row) =>
                    row.ContentTypeCode === AssetContentType.MPD &&
                    row.ContentStatusCode === ContentStatus.Processing
                ) < 0
              : true;
          case TranscodingFormat.M3U8:
            return asset?.Contents
              ? asset?.Contents?.findIndex(
                  (row) =>
                    row.ContentTypeCode === AssetContentType.HLS &&
                    row.ContentStatusCode === ContentStatus.Processing
                ) < 0
              : true;
        }

        return true;
      }
    );

    var options = config.Asset.TranscodingFormats.map((format: string) => {
      const isDisabled = !availableFormats.includes(format);

      return {
        label: format,
        value: format,
        disabled: isDisabled,
        id: format,
      };
    });

    return (
      <Form.Item
        name="TranscodingFormats"
        label={t("ASSET_CONTENT_TRANSCODE_TRANSCODING_FORMATS_LABEL")}
        key="TranscodingFormats"
        rules={[
          {
            validator: (_, value: string[] | undefined) => {
              if (!value || value.length === 0) {
                return Promise.reject(
                  new Error(t("REQUIRED_VALIDATION_MESSAGE"))
                );
              }

              return Promise.resolve();
            },
          },
        ]}
      >
        <CheckboxGroup
          options={options}
          onChange={onTranscodingFormatsChange}
        />
      </Form.Item>
    );
  };

  const renderError = () => {
    if (error) {
      return (
        <Form.Item>
          <Alert
            message={t("ASSET_CONTENT_TRANSCODE_FAILURE_MESSAGE")}
            description={error.Message}
            type="error"
            closable
            showIcon
          />
        </Form.Item>
      );
    }

    return null;
  };

  return (
    <FormModal
      isVisible={visible}
      isLoading={false}
      isNewForm={true}
      isDisabled={processing}
      isDeleteButtonEnabled={false}
      createFormTitle={t("ASSET_CONTENT_TRANSCODE_FORM_TITLE")}
      editFormTitle={t("ASSET_CONTENT_TRANSCODE_FORM_TITLE")}
      modalClassName="AssetContentTrancodeModal"
      submitFormName="AssetContentTrancodeForm"
      confirmButtonTransKey="BUTTON_TRANSCODE"
      onCloseModal={onCloseModal}
    >
      <Form
        form={form}
        name="AssetContentTrancodeForm"
        className="AssetContentTrancodeForm"
        layout="vertical"
        onFinish={onFinish}
        {...formLayout}
      >
        {renderInfo()}
        {renderError()}
        {renderTranscodingFormatsField()}
      </Form>
    </FormModal>
  );
};
