import { SimpleServiceBase } from "../Base";
import {
  ITestDefinitionModel,
  ITestDefinitionsFilterModel,
  ITestDefinitionsListModel,
} from "../../models";
import { Observable, Observer } from "rxjs";
import { AxiosSubscriber } from "../AxiosSubscriber";
import { HTTP_METHOD } from "../../constants";

export class TestDefinitionService extends SimpleServiceBase<
  ITestDefinitionModel,
  ITestDefinitionsListModel,
  number,
  ITestDefinitionsFilterModel
> {
  get url() {
    return "/Tests";
  }

  public start = (id: number): Observable<void> =>
    new Observable(
      (observer: Observer<void>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: {
              id,
            },
          },
          method: HTTP_METHOD.GET,
          url: `${this.url}/Start`,
        })
    );
}
