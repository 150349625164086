import {
  IAssetCollectionListModel,
  IAssetCollectionModel,
  IResourceModel,
} from "@bms/common-services";
import {
  Button,
  Col,
  Form,
  Icon,
  IFormValues,
  Input,
  Link,
  Row,
} from "@bms/common-ui";
import React from "react";
import { WithTranslation } from "react-i18next";
import { Action, ActionCreator } from "redux";
import { ROUTES } from "../../../../../../constants";
import { AssetCollectionBrowserModal } from "../../../../../Asset/components/AssetCollectionBrowserModal";
import * as TranslationsModule from "../../../../../Translations";
import { PlayListComponentPropertyModel } from "../../models";
import {
  ComponentPropertiesBase,
  IComponentPropertiesBaseProps,
  IComponentPropertiesBaseState,
} from "../ComponentPropertiesBase";
import "./PlayListComponentProperties.scss";

export interface IPlayListComponentPropertiesStateProps {
  isProcessingData: boolean;
  assetCollectionsBrowser: IAssetCollectionListModel;
}

export interface IPlayListComponentPropertiesDispatchProps {
  browseAssetCollection: ActionCreator<Action>;
}

export interface IPlayListComponentPropertiesOwnProps {}

export interface IPlayListComponentPropertiesProps
  extends IPlayListComponentPropertiesStateProps,
    IPlayListComponentPropertiesDispatchProps,
    IPlayListComponentPropertiesOwnProps,
    IComponentPropertiesBaseProps,
    WithTranslation {}

export interface IPlayListComponentPropertiesState
  extends IComponentPropertiesBaseState {
  assetCollectionsBrowserVisible: boolean;
  translationsBrowserVisible: boolean;
}

export class PlayListComponentProperties extends ComponentPropertiesBase<
  IPlayListComponentPropertiesProps,
  IPlayListComponentPropertiesState,
  PlayListComponentPropertyModel
> {
  public static defaultProps = {
    isProcessingData: false,
  };

  public state: Readonly<IPlayListComponentPropertiesState> = {
    assetCollectionsBrowserVisible: false,
    translationsBrowserVisible: false,
  };

  getPropertiesModel(): PlayListComponentPropertyModel {
    const { component } = this.props;
    const properties = new PlayListComponentPropertyModel();
    properties.init(component);

    return properties;
  }

  public componentDidMount() {
    const { assetCollectionsBrowser, browseAssetCollection } = this.props;

    if (
      !assetCollectionsBrowser.Entities ||
      assetCollectionsBrowser.Entities.length === 0
    ) {
      browseAssetCollection(assetCollectionsBrowser.Filter);
    }
  }

  public onFinish = (values: IFormValues) => {
    const { component } = this.props;

    if (!component) {
      return;
    }

    component.Name = values.Name;
  };

  public onTitleTranslationKeyCancel = () => {
    this.setState({ translationsBrowserVisible: false });
  };

  public onTitleTranslationKeySelect = (row: IResourceModel) => {
    this.properties.TitleTranslationKey = row.ResourceKey;
    this.onPropertyChange("TitleTranslationKey");

    this.setState({ translationsBrowserVisible: false });
  };

  public onTitleTranslationKeyClear = () => {
    const { component, onComponentChange } = this.props;

    this.properties.TitleTranslationKey = undefined;
    this.properties.deleteProperty("TitleTranslationKey", component);

    if (onComponentChange) {
      onComponentChange(component);
    }
  };

  public renderTitleTranslationKeyProperty = () => {
    const { component, t } = this.props;
    const { translationsBrowserVisible } = this.state;

    let titleTranslationKeyView: React.ReactElement = this.properties
      .TitleTranslationKey ? (
      <label>
        <Link
          to={`${TranslationsModule.ROUTES.CONFIGURATION_TRANSLATION_DETAILS}/${this.properties.TitleTranslationKey}`}
        >
          {this.properties.TitleTranslationKey}
        </Link>
      </label>
    ) : (
      <label>{t("MODEL_TRANSLATION_KEY_PLACEHOLDER")}</label>
    );

    return (
      <Form.Item
        name="TitleTranslationKey"
        label={t("MODEL_TITLE_KEY")}
        style={{ marginBottom: 0 }}
      >
        <Row gutter={8}>
          <Col span={18}>
            <Form.Item>{titleTranslationKeyView}</Form.Item>
            <TranslationsModule.Components.TranslationsBrowserModal
              key={`TranslationsBrowser-${component.Id}`}
              visible={translationsBrowserVisible}
              onCancel={this.onTitleTranslationKeyCancel}
              onSelect={this.onTitleTranslationKeySelect}
            />
          </Col>
          <Col span={6} style={{ textAlign: "right" }}>
            {this.properties.TitleTranslationKey && (
              <Button
                icon={<Icon type="delete" />}
                onClick={this.onTitleTranslationKeyClear}
                style={{ marginRight: "8px" }}
              />
            )}
            <Button
              icon={<Icon type="edit" />}
              onClick={() => {
                this.setState({ translationsBrowserVisible: true });
              }}
            />
          </Col>
        </Row>
      </Form.Item>
    );
  };

  public renderTitleProperty = () => {
    const { isProcessingData, t } = this.props;

    return (
      <Form.Item
        name="Title"
        label={t("MODEL_TITLE")}
        initialValue={this.properties.Title}
      >
        <Input
          disabled={isProcessingData}
          placeholder={t("MODEL_TITLE_PLACEHOLDER")}
          onChange={(e) => {
            this.properties.Title = e.target.value;
            this.onPropertyChange("Title");
          }}
        />
      </Form.Item>
    );
  };

  public onSourcePropertyCancel = () => {
    this.setState({ assetCollectionsBrowserVisible: false });
  };

  public onSourcePropertySelect = (row: IAssetCollectionModel) => {
    this.properties.SourceId = row.Id;
    this.properties.SourceName = row.Name;
    this.onPropertyChange("SourceId");
    this.onPropertyChange("SourceName");

    this.setState({ assetCollectionsBrowserVisible: false });
  };

  public onSourcePropertyClear = () => {
    const { component, onComponentChange } = this.props;

    this.properties.SourceId = undefined;
    this.properties.SourceName = undefined;
    this.properties.deleteProperty("SourceId", component);
    this.properties.deleteProperty("SourceName", component);

    if (onComponentChange) {
      onComponentChange(component);
    }
  };

  public renderSourceProperty = () => {
    const { t } = this.props;
    const { assetCollectionsBrowserVisible } = this.state;

    let sourceValueView: React.ReactElement = this.properties.SourceId ? (
      <label>
        <Link
          to={`${ROUTES.ASSET_COLLECTION_DETAILS}/${this.properties.SourceId}`}
        >
          {this.properties.SourceName || this.properties.SourceId}
        </Link>
      </label>
    ) : (
      <label>{t("COMMON_UNDEFINED")}</label>
    );

    return (
      <Form.Item
        name="SourceId"
        label={t("COMPONENT_PROPERTIES__SOURCE")}
        initialValue={{
          key: `${this.properties.SourceId}`,
          label: this.properties.SourceName,
        }}
      >
        <Row gutter={8}>
          <Col span={18}>
            <Form.Item>{sourceValueView}</Form.Item>
            <AssetCollectionBrowserModal
              visible={assetCollectionsBrowserVisible}
              onCancel={this.onSourcePropertyCancel}
              onSelect={this.onSourcePropertySelect}
            />
          </Col>
          <Col span={6} style={{ textAlign: "right" }}>
            {this.properties.SourceId && (
              <Button
                icon={<Icon type="delete" />}
                onClick={this.onSourcePropertyClear}
                style={{ marginRight: "8px" }}
              />
            )}
            <Button
              icon={<Icon type="edit" />}
              onClick={() => {
                this.setState({ assetCollectionsBrowserVisible: true });
              }}
            />
          </Col>
        </Row>
      </Form.Item>
    );
  };

  public render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 8 },
        lg: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 16 },
        lg: { span: 18 },
      },
    };

    return (
      <Form
        name="PlayListComponentProperties"
        {...formItemLayout}
        onFinish={this.onFinish}
      >
        <Row direction="column" justify="space-between" className="full-height">
          <Col>
            {this.renderTitleProperty()}
            {this.renderTitleTranslationKeyProperty()}
            {this.renderSourceProperty()}
          </Col>
        </Row>
      </Form>
    );
  }
}
