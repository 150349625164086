import { AnalyticsAggregationPeriodType } from "../enums";

export class AggregationPeriodTypeHelper {
  static getValue(
    value?: string,
    defaultValue: AnalyticsAggregationPeriodType = AnalyticsAggregationPeriodType.YEAR
  ): AnalyticsAggregationPeriodType {
    switch (value) {
      case "Year":
        return AnalyticsAggregationPeriodType.YEAR;
      case "Month":
        return AnalyticsAggregationPeriodType.MONTH;
      case "Day":
        return AnalyticsAggregationPeriodType.DAY;
      default:
        return defaultValue;
    }
  }
}
