import {
  IResourceModel,
  IResourcesListModel,
  IResourcesSearchFilterModel,
} from "../../models";
import { SimpleServiceBase } from "../Base";
import { Observable, Observer } from "rxjs";
import { AxiosSubscriber } from "../AxiosSubscriber";
import { HTTP_METHOD } from "../../constants/http";
import { map } from "rxjs/operators";

export class ResourceService extends SimpleServiceBase<
  IResourceModel,
  IResourcesListModel,
  number,
  IResourcesSearchFilterModel
> {
  get url(): string {
    return "/Resources";
  }

  public selectByResourceKey = (
    resourceKey: string
  ): Observable<IResourcesListModel> =>
    new Observable(
      (observer: Observer<IResourceModel[]>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: {
              resourceKey,
            },
          },
          data: {},
          method: HTTP_METHOD.POST,
          url: `${this.url}/SelectByResourceKey`,
        })
    ).pipe(
      map((data: IResourceModel[]) => {
        const entities = data || [];

        // @ts-ignore
        const result: IResourcesListModel = {
          Entities: entities,
          TotalCount: entities.length,
          Filter: {
            PageSize: entities.length,
            PageNumber: 1,
            IncludeCount: true,
          },
        };

        return result;
      })
    );

  public deleteByResourceKey = (resourceKey: string): Observable<void> =>
    new Observable(
      (observer: Observer<void>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: {
              resourceKey,
            },
          },
          method: HTTP_METHOD.DELETE,
          url: `${this.url}/Delete`,
        })
    );
}
