import {
  RatingCategoriesService,
  IRatingCategoryModel,
  RecordStatus,
  useServiceCaller,
} from "@bms/common-services";
import { useTranslation } from "react-i18next";
import { useTableDataProvider } from "../../../helpers";
import { useAppFeedback } from "@bms/common-ui";

const ratingCategoriesService = new RatingCategoriesService().promisify();

export const useRatingCategories = () => {
  const { t } = useTranslation();
  const { notification } = useAppFeedback();

  const {
    dataLoader: ratingCategoriesLoader,
    pagination,
  } = useTableDataProvider({
    filtersSchema: {
      Name: "string",
      UpToDate: "boolean",
    },
    loader: (filters, pagination) =>
      ratingCategoriesService.search({
        ...filters,
        ...pagination,
        IncludePlatforms: true,
      }),
    deps: [],
    onError: (error) =>
      notification.error({
        message: t("LOADING_DATA_ERROR_MESSAGE"),
        description: error.Message,
      }),
  });

  const [createCategory, { processing: createProcessing }] = useServiceCaller(
    async (data: IRatingCategoryModel) => {
      const result = await ratingCategoriesService.insert({
        ...data,
        RecordStatus: RecordStatus.Inserted,
      });
      if (result.ok) {
        ratingCategoriesLoader.refresh();
        notification.success({
          message: t("RATING_CATEGORY_CREATE_SUCCESS"),
        });
      } else {
        notification.error({
          message: t("RATING_CATEGORY_CREATE_FAILURE"),
          description: result.error?.Message,
        });
      }
      return result.ok;
    },
    []
  );

  const [updateCategory, { processing: updateProcessing }] = useServiceCaller(
    async (data: IRatingCategoryModel) => {
      const result = await ratingCategoriesService.update({
        ...data,
        RecordStatus: RecordStatus.Updated,
      });
      if (result.ok) {
        ratingCategoriesLoader.refresh();
        notification.success({
          message: t("RATING_CATEGORY_UPDATE_SUCCESS"),
        });
      } else {
        notification.error({
          message: t("RATING_CATEGORY_UPDATE_FAILURE"),
          description: result.error?.Message,
        });
      }
      return result.ok;
    },
    []
  );

  const [deleteCategory, { processing: deleteProcessing }] = useServiceCaller(
    async (currency: IRatingCategoryModel) => {
      const result = await ratingCategoriesService.delete({
        ...currency,
        RecordStatus: RecordStatus.Deleted,
      });
      if (result.ok) {
        ratingCategoriesLoader.refresh();
        notification.success({
          message: t("RATING_CATEGORY_DELETE_SUCCESS"),
        });
      } else {
        notification.error({
          message: t("RATING_CATEGORY_DELETE_ERROR"),
          description: result.error?.Message,
        });
      }
      return result.ok;
    },
    []
  );

  const isLoading =
    ratingCategoriesLoader.loading ||
    deleteProcessing ||
    updateProcessing ||
    createProcessing;

  const { data } = ratingCategoriesLoader;

  return {
    isLoading,
    ratingCategoriesData: data,
    refreshCategories: ratingCategoriesLoader.refresh,
    deleteCategory,
    createCategory,
    updateCategory,
    pagination,
  };
};
