import React, {
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { IAssetContentModel } from "@bms/common-services";
import { useShakaPlayer } from "../../helpers";

import "./Player.scss";
import { loadPlayInfo, setCookies } from "./utils";

export interface IPlayerRef {
  play: () => void;
  pause: () => void;
}

type IBasePlayerProps = {
  allowCrossSiteCredentials?: boolean;
};

type IAssetContentPlayerProps = {
  assetContent: IAssetContentModel;
  contentUrl?: never;
  contentType?: never;
};

type IUrlPlayerProps = {
  contentUrl?: string;
  contentType?: string;
  assetContent?: never;
};

export type IPlayerProps = IBasePlayerProps &
  (IAssetContentPlayerProps | IUrlPlayerProps);

const DEFAULT_CONTENT_URL =
  "https://docs.evostream.com/sample_content/assets/hls-bunny-166/playlist.m3u8";

export const Player = forwardRef<IPlayerRef, IPlayerProps>((props, vsRef) => {
  const {
    contentUrl = DEFAULT_CONTENT_URL,
    contentType,
    assetContent,
    allowCrossSiteCredentials = false,
  } = props;
  const videoContainerRef = useRef<HTMLDivElement>(null);
  const videoComponentRef = useRef<HTMLVideoElement>(null);
  const {
    initPlayer,
    loadContent,
    clearPlayer,
    isInitialized,
  } = useShakaPlayer();

  const initialize = async () => {
    if (videoComponentRef.current && videoContainerRef.current) {
      if (assetContent && assetContent.Id) {
        try {
          const playInfo = await loadPlayInfo(assetContent);

          initPlayer(videoComponentRef.current, videoContainerRef.current, {
            assetContent,
            playInfo,
            allowCrossSiteCredentials,
          });
        } catch (error) {
          console.error("Error fetching play info:", error);
        }
      } else {
        initPlayer(videoComponentRef.current, videoContainerRef.current, {
          allowCrossSiteCredentials,
        });
      }
    }
  };

  useImperativeHandle(
    vsRef,
    (): IPlayerRef => {
      return {
        play: () => {
          videoComponentRef.current?.play().then(() => {});
        },
        pause: () => {
          videoComponentRef.current?.pause();
        },
      };
    },
    [videoComponentRef.current]
  );

  const onClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const onDoubleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
  };

  useEffect(() => {
    initialize();

    return () => clearPlayer();
  }, []);

  useEffect(() => {
    if (isInitialized) {
      if (assetContent) {
        setCookies(assetContent);
      }

      loadContent(
        assetContent?.Url ? assetContent.Url : contentUrl,
        contentType
      );
    }
  }, [contentUrl, isInitialized]);

  return (
    <div className="video-player-container">
      <div
        data-shaka-player-container
        ref={videoContainerRef}
        className="video-container"
        onClick={onClick}
        onDoubleClick={onDoubleClick}
      >
        <video
          data-shaka-player
          ref={videoComponentRef}
          id="video"
          style={{ maxWidth: "100vw", maxHeight: "100vh" }}
          width="100%"
          height="100%"
          autoPlay
        />
      </div>
    </div>
  );
});
