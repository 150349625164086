import {
  ApplicationConfigurationActionsTypes,
  IApplicationConfigurationState,
} from "./types";
import * as Consts from "./consts";
import { cloneDeep, findIndex } from "lodash";

export const initialState: IApplicationConfigurationState = {
  action: undefined,
  actionType: undefined,
  brandings: {
    Entities: [],
    Filter: {
      IncludeCount: true,
      PageNumber: 1,
      PageSize: 10,
    },
    TotalCount: 0,
  },
  brandingsKeys: {
    Entities: [],
    TotalCount: 0,
    Filter: {
      PageNumber: 1,
      PageSize: 10,
      IncludeCount: true,
    },
  },
  configuration: {
    IsProcessing: false,
  },
  configurations: {
    Entities: [],
    TotalCount: 0,
    Filter: {
      PageNumber: 1,
      PageSize: 10,
      IncludeCount: true,
    },
  },
  screens: {
    Entities: [],
    TotalCount: 0,
    Filter: {
      PageNumber: 1,
      PageSize: 10,
      IncludeCount: true,
    },
  },

  screensBrowser: {
    Entities: [],
    TotalCount: 0,
    Filter: {
      PageNumber: 1,
      PageSize: 10,
      IncludeCount: true,
    },
  },
  playlistBrowser: {
    Entities: [],
    TotalCount: 0,
    Filter: {
      PageNumber: 1,
      PageSize: 10,
      IncludeCount: true,
    },
  },
  screensTypes: [],
  componentsTypes: [],
  sources: {},
  translationsExport: {
    Data: undefined,
    IsProcessing: false,
  },
  isLoadingData: false,
  isProcessingData: false,
};

export const applicationConfigurationReducer = (
  state = initialState,
  action: ApplicationConfigurationActionsTypes
): IApplicationConfigurationState => {
  state.actionType = action.type;
  state.action = action;

  switch (action.type) {
    case Consts.SEARCH_APPLICATION_CONFIGURATION: {
      return {
        ...state,
        isLoadingData: true,
      };
    }
    case Consts.SEARCH_APPLICATION_CONFIGURATION_SUCCESS: {
      return {
        ...state,
        configurations: action.payload,
        isLoadingData: false,
      };
    }
    case Consts.SEARCH_APPLICATION_CONFIGURATION_FAILURE: {
      return {
        ...state,
        error: action.error,
        isLoadingData: false,
      };
    }
    case Consts.GET_APPLICATION_CONFIGURATION: {
      return {
        ...state,
        configuration: {
          Data: undefined,
          Error: undefined,
          IsProcessing: true,
        },
      };
    }
    case Consts.GET_APPLICATION_CONFIGURATION_SUCCESS: {
      return {
        ...state,
        configuration: {
          Data: action.payload,
          IsProcessing: false,
        },
      };
    }
    case Consts.GET_APPLICATION_CONFIGURATION_FAILURE: {
      return {
        ...state,
        configuration: {
          Error: action.error,
          IsProcessing: false,
        },
      };
    }
    case Consts.UPDATE_APPLICATION_CONFIGURATION: {
      return {
        ...state,
        configuration: {
          ...state.configuration,
          Error: undefined,
          IsProcessing: true,
        },
      };
    }
    case Consts.UPDATE_APPLICATION_CONFIGURATION_SUCCESS: {
      return {
        ...state,
        configuration: {
          Data: action.payload,
          IsProcessing: false,
        },
      };
    }
    case Consts.UPDATE_APPLICATION_CONFIGURATION_FAILURE: {
      return {
        ...state,
        configuration: {
          ...state.configuration,
          Error: action.error,
          IsProcessing: false,
        },
      };
    }
    case Consts.COPY_APPLICATION_SCREEN: {
      return {
        ...state,
        configuration: {
          ...state.configuration,
          Error: undefined,
          IsProcessing: true,
        },
      };
    }
    case Consts.COPY_APPLICATION_SCREEN_SUCCESS: {
      return {
        ...state,
        configuration: {
          ...state.configuration,
          IsProcessing: false,
        },
      };
    }
    case Consts.COPY_APPLICATION_SCREEN_FAILURE: {
      return {
        ...state,
        configuration: {
          ...state.configuration,
          Error: action.error,
          IsProcessing: false,
        },
      };
    }
    case Consts.PUBLISH_APPLICATION_CONFIGURATION: {
      return {
        ...state,
        configuration: {
          ...state.configuration,
          Error: undefined,
          IsProcessing: true,
        },
      };
    }
    case Consts.PUBLISH_APPLICATION_CONFIGURATION_SUCCESS: {
      return {
        ...state,
        configuration: {
          ...state.configuration,
          Data: {
            ...state.configuration.Data,
            ...action.payload,
          },
          IsProcessing: false,
        },
      };
    }
    case Consts.PUBLISH_APPLICATION_CONFIGURATION_FAILURE: {
      return {
        ...state,
        configuration: {
          ...state.configuration,
          Error: action.error,
          IsProcessing: false,
        },
      };
    }
    case Consts.DELETE_APPLICATION_CONFIGURATION: {
      return {
        ...state,
        configuration: {
          ...state.configuration,
          Error: undefined,
          IsProcessing: true,
        },
      };
    }
    case Consts.DELETE_APPLICATION_CONFIGURATION_SUCCESS: {
      return {
        ...state,
        configuration: {
          Data: undefined,
          IsProcessing: false,
        },
      };
    }
    case Consts.DELETE_APPLICATION_CONFIGURATION_FAILURE: {
      return {
        ...state,
        configuration: {
          ...state.configuration,
          Error: action.error,
          IsProcessing: false,
        },
      };
    }
    case Consts.GET_APPLICATION_SCREEN: {
      return {
        ...state,
        screen: undefined,
        isLoadingData: true,
      };
    }
    case Consts.GET_APPLICATION_SCREEN_SUCCESS: {
      return {
        ...state,
        screen: action.payload,
        isLoadingData: false,
      };
    }
    case Consts.GET_APPLICATION_SCREEN_FAILURE: {
      return {
        ...state,
        error: action.error,
        isLoadingData: false,
      };
    }
    case Consts.SEARCH_APPLICATION_SCREEN: {
      return {
        ...state,
        isLoadingData: true,
      };
    }
    case Consts.SEARCH_APPLICATION_SCREEN_SUCCESS: {
      return {
        ...state,
        screens: action.payload,
        isLoadingData: false,
      };
    }
    case Consts.SEARCH_APPLICATION_SCREEN_FAILURE: {
      return {
        ...state,
        error: action.error,
        isLoadingData: false,
      };
    }
    case Consts.BROWSE_APPLICATION_SCREEN: {
      return {
        ...state,
        screensBrowser: {
          ...state.screensBrowser,
          Entities: [],
          IsLoading: true,
        },
      };
    }
    case Consts.BROWSE_APPLICATION_SCREEN_SUCCESS: {
      return {
        ...state,
        screensBrowser: {
          ...action.payload,
          IsLoading: false,
        },
      };
    }
    case Consts.BROWSE_APPLICATION_SCREEN_FAILURE: {
      return {
        ...state,
        screensBrowser: {
          ...state.screensBrowser,
          IsLoading: false,
          Error: action.error,
        },
      };
    }
    case Consts.BROWSE_APPLICATION_SCREEN_CANCEL: {
      return {
        ...state,
        screensBrowser: {
          ...state.screensBrowser,
          IsLoading: false,
        },
      };
    }
    case Consts.INSERT_APPLICATION_SCREEN: {
      return {
        ...state,
        isProcessingData: true,
      };
    }
    case Consts.INSERT_APPLICATION_SCREEN_SUCCESS: {
      return {
        ...state,
        screen: { ...action.payload },
        isProcessingData: false,
      };
    }
    case Consts.INSERT_APPLICATION_SCREEN_FAILURE: {
      return {
        ...state,
        error: action.error,
        isProcessingData: false,
      };
    }
    case Consts.UPDATE_APPLICATION_SCREEN: {
      return {
        ...state,
        isProcessingData: true,
      };
    }
    case Consts.UPDATE_APPLICATION_SCREEN_SUCCESS: {
      return {
        ...state,
        screen: action.payload,
        isProcessingData: false,
      };
    }
    case Consts.UPDATE_APPLICATION_SCREEN_FAILURE: {
      return {
        ...state,
        error: action.error,
        isProcessingData: false,
      };
    }
    case Consts.DELETE_APPLICATION_SCREEN: {
      return {
        ...state,
        isProcessingData: true,
      };
    }
    case Consts.DELETE_APPLICATION_SCREEN_SUCCESS: {
      return {
        ...state,
        screen: undefined,
        isProcessingData: false,
      };
    }
    case Consts.DELETE_APPLICATION_SCREEN_FAILURE: {
      return {
        ...state,
        error: action.error,
        isProcessingData: false,
      };
    }
    case Consts.SELECT_APPLICATION_SCREEN_TYPE: {
      return {
        ...state,
        screensTypes: [],
        isLoadingData: true,
      };
    }
    case Consts.SELECT_APPLICATION_SCREEN_TYPE_SUCCESS: {
      return {
        ...state,
        screensTypes: action.payload,
        isLoadingData: false,
      };
    }
    case Consts.SELECT_APPLICATION_SCREEN_TYPE_FAILURE: {
      return {
        ...state,
        error: action.error,
        isLoadingData: false,
      };
    }
    case Consts.SELECT_APPLICATION_COMPONENT_TYPE: {
      return {
        ...state,
        componentsTypes: [],
        isLoadingData: true,
      };
    }
    case Consts.SELECT_APPLICATION_COMPONENT_TYPE_SUCCESS: {
      return {
        ...state,
        componentsTypes: action.payload,
        isLoadingData: false,
      };
    }
    case Consts.SELECT_APPLICATION_COMPONENT_TYPE_FAILURE: {
      return {
        ...state,
        error: action.error,
        isLoadingData: false,
      };
    }
    //case Consts.GET_DATA_SOURCE: {
    //  const sources = state.sources;
    //  let source = sources[action.filter.SourceId];

    //  if (!source) {
    //    source = {
    //      Entities: [],
    //      TotalCount: 0,
    //      IsLoading: true,
    //      Filter: {
    //        PageSize: action.filter.PageSize,
    //        PageNumber: action.filter.PageNumber,
    //        IncludeCount: true
    //      }
    //    };
    //  }

    //  sources[action.filter.SourceId] = source;

    //  return {
    //    ...state,
    //    sources
    //  }
    //}
    //case Consts.GET_DATA_SOURCE_SUCCESS: {
    //  const sources = state.sources;

    //  let source = {
    //    Entities: action.data.Entities,
    //    TotalCount: action.data.TotalCount,
    //    IsLoading: false,
    //    Filter: {
    //      PageSize: action.data.Filter?.PageSize,
    //      PageNumber: action.data.Filter?.PageNumber,
    //      IncludeCount: true
    //    }
    //  }

    //  sources[action.sourceId] = source;

    //  return {
    //    ...state,
    //    sources
    //  };
    //}
    //case Consts.GET_DATA_SOURCE_FAILURE: {
    //  const sources = state.sources;
    //  const source = sources[action.sourceId];
    //  source.IsLoading = false;
    //  source.Error = action.error;

    //  sources[action.sourceId] = source;

    //  return {
    //    ...state,
    //    sources
    //  }
    //}
    case Consts.SEARCH_APPLICATION_BRANDING: {
      return {
        ...state,
        isLoadingData: true,
      };
    }
    case Consts.SEARCH_APPLICATION_BRANDING_SUCCESS: {
      return {
        ...state,
        brandings: action.payload,
        isLoadingData: false,
      };
    }
    case Consts.SEARCH_APPLICATION_BRANDING_FAILURE: {
      return {
        ...state,
        error: action.error,
        isLoadingData: false,
      };
    }

    case Consts.INSERT_APPLICATION_BRANDING: {
      return {
        ...state,
        isProcessingData: true,
      };
    }
    case Consts.INSERT_APPLICATION_BRANDING_SUCCESS: {
      return {
        ...state,
        isProcessingData: false,
      };
    }
    case Consts.INSERT_APPLICATION_BRANDING_FAILURE: {
      return {
        ...state,
        error: action.error,
        isProcessingData: false,
      };
    }
    case Consts.UPDATE_APPLICATION_BRANDING: {
      return {
        ...state,
        isProcessingData: true,
      };
    }
    case Consts.UPDATE_APPLICATION_BRANDING_SUCCESS: {
      const newState = cloneDeep(state);
      const entityIndex = findIndex(newState.brandings.Entities, {
        Id: action.payload.Id,
      });
      newState.brandings.Entities[entityIndex] = action.payload;
      return {
        ...newState,
        isProcessingData: false,
      };
    }
    case Consts.UPDATE_APPLICATION_BRANDING_FAILURE: {
      return {
        ...state,
        error: action.error,
        isProcessingData: false,
      };
    }
    case Consts.DELETE_APPLICATION_BRANDING: {
      return {
        ...state,
        isProcessingData: true,
      };
    }
    case Consts.DELETE_APPLICATION_BRANDING_SUCCESS: {
      return {
        ...state,
        isProcessingData: false,
      };
    }
    case Consts.DELETE_APPLICATION_BRANDING_FAILURE: {
      return {
        ...state,
        error: action.error,
        isProcessingData: false,
      };
    }

    case Consts.SELECT_KEYS_APPLICATION_BRANDING: {
      return {
        ...state,
        brandingsKeys: {
          ...state.brandingsKeys,
          Entities: [],
          IsLoading: true,
        },
      };
    }
    case Consts.SELECT_KEYS_APPLICATION_BRANDING_SUCCESS: {
      return {
        ...state,
        brandingsKeys: {
          ...action.payload,
          IsLoading: false,
        },
      };
    }
    case Consts.SELECT_KEYS_APPLICATION_BRANDING_FAILURE: {
      return {
        ...state,
        brandingsKeys: {
          ...state.brandingsKeys,
          IsLoading: false,
          Error: action.error,
        },
      };
    }
    case Consts.GET_IMAGE_UPLOAD_FILE_INFO: {
      return {
        ...state,
        imageUploadInfo: {
          Data: undefined,
          Error: undefined,
          IsProcessing: true,
        },
      };
    }
    case Consts.GET_IMAGE_UPLOAD_FILE_INFO_SUCCESS: {
      return {
        ...state,
        imageUploadInfo: {
          Data: action.payload,
          IsProcessing: false,
        },
      };
    }
    case Consts.GET_IMAGE_UPLOAD_FILE_INFO_FAILURE: {
      return {
        ...state,
        imageUploadInfo: {
          Error: action.error,
          IsProcessing: false,
        },
      };
    }
    case Consts.UPLOAD_BRANDING_IMAGE:
    case Consts.UPLOAD_BRANDING_IMAGE_SUCCESS:
    case Consts.UPLOAD_BRANDING_IMAGE_FAILURE: {
      return {
        ...state,
      };
    }
    case Consts.EXPORT_TRANSLATIONS: {
      return {
        ...state,
        translationsExport: {
          Data: undefined,
          Error: undefined,
          IsProcessing: true,
        },
      };
    }
    case Consts.EXPORT_TRANSLATIONS_SUCCESS: {
      return {
        ...state,
        translationsExport: {
          Data: action.payload,
          IsProcessing: false,
        },
      };
    }
    case Consts.EXPORT_TRANSLATIONS_FAILURE: {
      return {
        ...state,
        translationsExport: {
          Error: action.error,
          IsProcessing: false,
        },
      };
    }
    case Consts.IMPORT_TRANSLATIONS:
    case Consts.IMPORT_TRANSLATIONS_SUCCESS:
    case Consts.IMPORT_TRANSLATIONS_FAILURE: {
      return {
        ...state,
      };
    }
    case Consts.BROWSE_PLAYLIST: {
      return {
        ...state,
        playlistBrowser: {
          ...state.playlistBrowser,
          Entities: [],
          IsLoading: true,
        },
      };
    }
    case Consts.BROWSE_PLAYLIST_SUCCESS: {
      return {
        ...state,
        playlistBrowser: {
          ...action.payload,
          IsLoading: false,
        },
      };
    }
    case Consts.BROWSE_PLAYLIST_FAILURE: {
      return {
        ...state,
        playlistBrowser: {
          ...state.playlistBrowser,
          IsLoading: false,
          Error: action.error,
        },
      };
    }
    case Consts.BROWSE_PLAYLIST_CANCEL: {
      return {
        ...state,
        playlistBrowser: {
          ...state.playlistBrowser,
          IsLoading: false,
        },
      };
    }
    default:
      return state;
  }
};
