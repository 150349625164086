import { filter } from "lodash";
import { TextComponentsActionsTypes, ITextComponentsState } from "./types";
import * as Consts from "./consts";

export const initialState: ITextComponentsState = {
  fileInfo: {},
  uploadInfo: {},
};

export const textComponentsReducer = (
  state = initialState,
  action: TextComponentsActionsTypes
): ITextComponentsState => {
  switch (action.type) {
    case Consts.GET_TEXT_COMPONENT: {
      return {
        ...state,
        isFetching: true,
        [action.textComponentId]: {
          ...state[action.textComponentId],
          isFetching: true,
        },
      };
    }
    case Consts.INSERT_TEXT_COMPONENT:
    case Consts.UPDATE_TEXT_COMPONENT: {
      const data = action.data;

      if (data.Id) {
        return {
          ...state,
          isFetching: true,
          [data.Id]: {
            ...state[data.Id],
            isFetching: true,
          },
        };
      }

      return {
        ...state,
        isFetching: true,
      };
    }
    case Consts.GET_TEXT_COMPONENT_SUCCESS:
    case Consts.INSERT_TEXT_COMPONENT_SUCCESS: {
      const data = action.payload;

      if (data.Id) {
        return {
          ...state,
          isFetching: false,
          [data.Id]: {
            ...state[data.Id],
            data,
            isFetching: false,
          },
        };
      }

      return {
        ...state,
        isFetching: false,
      };
    }
    case Consts.UPDATE_TEXT_COMPONENT_SUCCESS: {
      const data = action.payload;

      if (data.Id) {
        const hadAttachment = !!state[data.Id].data?.AttachmentGuid;
        const hasAttachment = data.AttachmentGuid;

        // Attachment has been removed
        if (hadAttachment && !hasAttachment) {
          const previousAttachmentGuid = state[data.Id].data?.AttachmentGuid;

          if (previousAttachmentGuid) {
            return {
              ...state,
              fileInfo: {
                ...state.fileInfo,
                [previousAttachmentGuid]: {},
              },
              isFetching: false,
              [data.Id]: {
                ...state[data.Id],
                data,
                isFetching: false,
              },
            };
          }
        }

        return {
          ...state,
          isFetching: false,
          [data.Id]: {
            ...state[data.Id],
            data,
            isFetching: false,
          },
        };
      }

      return {
        ...state,
        isFetching: false,
      };
    }
    case Consts.GET_TEXT_COMPONENT_FAILURE:
    case Consts.INSERT_TEXT_COMPONENT_FAILURE:
    case Consts.UPDATE_TEXT_COMPONENT_FAILURE: {
      if (action.textComponentId) {
        return {
          ...state,
          isFetching: false,
          [action.textComponentId]: {
            ...state[action.textComponentId],
            error: action.error,
            isFetching: false,
          },
        };
      }

      return {
        ...state,
        isFetching: false,
      };
    }
    case Consts.GET_TEXT_COMPONENT_ATTACHEMENT_UPLOAD_URL: {
      return {
        ...state,
        uploadInfo: {
          ...state.uploadInfo,
          [action.attachmentGuid]: {
            ...state.uploadInfo[action.attachmentGuid],
            isFetching: true,
          },
        },
      };
    }
    case Consts.GET_TEXT_COMPONENT_ATTACHEMENT_UPLOAD_URL_SUCCESS: {
      const data = action.payload;

      if (action.attachmentGuid) {
        return {
          ...state,
          uploadInfo: {
            ...state.uploadInfo,
            [action.attachmentGuid]: {
              ...state.uploadInfo[action.attachmentGuid],
              data,
              isFetching: false,
            },
          },
        };
      }

      return state;
    }
    case Consts.GET_TEXT_COMPONENT_ATTACHEMENT_UPLOAD_URL_FAILURE: {
      return {
        ...state,
        uploadInfo: {
          ...state.uploadInfo,
          [action.attachmentGuid]: {
            ...state.uploadInfo[action.attachmentGuid],
            error: action.error,
            isFetching: false,
          },
        },
      };
    }
    case Consts.GET_TEXT_COMPONENT_ATTACHEMENT_URL: {
      return {
        ...state,
        fileInfo: {
          ...state.fileInfo,
          [action.attachmentGuid]: {
            ...state.fileInfo[action.attachmentGuid],
            isFetching: true,
          },
        },
      };
    }
    case Consts.GET_TEXT_COMPONENT_ATTACHEMENT_URL_SUCCESS: {
      const data = action.payload;

      if (action.attachmentGuid) {
        return {
          ...state,
          fileInfo: {
            ...state.fileInfo,
            [action.attachmentGuid]: {
              ...state.fileInfo[action.attachmentGuid],
              data,
              isFetching: false,
            },
          },
        };
      }

      return state;
    }
    case Consts.GET_TEXT_COMPONENT_ATTACHEMENT_URL_FAILURE: {
      return {
        ...state,
        fileInfo: {
          ...state.fileInfo,
          [action.attachmentGuid]: {
            ...state.fileInfo[action.attachmentGuid],
            error: action.error,
            isFetching: false,
          },
        },
      };
    }
    default:
      return state;
  }
};
