import {
  AssetInAssetService,
  AssetService,
  AssetType,
  IAssetModel,
  IAssetSearchFilterModel,
  RecordStatus,
  useDataLoader,
  useServiceCaller,
} from "@bms/common-services";
import {
  Col,
  InputSearch,
  ITableFilter,
  ITablePaginationConfig,
  ITransferItem,
  Row,
  SectionGrid,
  SectionGridItem,
  TableTransfer,
  Tag,
  useAppFeedback,
} from "@bms/common-ui";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

const assetsService = new AssetService().promisify();
const assetInAssetService = new AssetInAssetService().promisify();

const PAGE_SIZE = 15;

function sequenceMap<T>(array: T[], mapper: (item: T) => Promise<any>) {
  return array.reduce(
    (previous, next) => previous.then(() => mapper(next)),
    Promise.resolve()
  );
}

export const AssetPackagesTransfer: React.FC = () => {
  const { notification } = useAppFeedback();
  const [t] = useTranslation();

  const { id } = useParams<{ id: string }>();

  const [assetFilter, setAssetFilter] = useState<IAssetSearchFilterModel>({
    PageSize: PAGE_SIZE,
    PageNumber: 1,
    IncludeCount: true,
    FullTextSearch: "",
    Types: [AssetType.Package],
  });

  const assetTypesLoader = useDataLoader({
    loader: () => assetsService.getAssetTypes(),
    deps: [],
  });

  const packagesInAssetLoader = useDataLoader({
    loader: () => assetInAssetService.select({ AssetId: +id }),
    deps: [],
  });

  const packagesLoader = useDataLoader({
    loader: () => assetsService.search(assetFilter),
    onError: (error) => {
      notification.error({
        message: error?.Message,
      });
    },
    deps: [assetFilter],
  });

  const assetTypes = assetTypesLoader.data ?? [];
  const packagesInAsset = packagesInAssetLoader.data?.Entities ?? [];
  const packages = packagesLoader.data?.Entities ?? [];

  const [
    insertAssetInCollection,
    { processing: insertProcessing },
  ] = useServiceCaller(
    async (AssetIds: number[]) => {
      const result = await assetInAssetService.insertCollection(
        AssetIds.map((AssetId) => ({
          AssetParentId: AssetId,
          AssetId: +id,
          RecordStatus: RecordStatus.Inserted,
        }))
      );
      if (!result.ok) {
        notification.error({
          message: t("ASSET_PACKAGES_INSERT_FAILURE_MESSAGE"),
          description: result.error?.Message,
        });
      }
      await packagesInAssetLoader.refresh();
    },
    [packages]
  );

  const [
    deleteAssetInCollection,
    { processing: deleteProcessing },
  ] = useServiceCaller(async (AssetIds: number[]) => {
    let errorOccurred = false;
    await sequenceMap(AssetIds, async (AssetId: number) => {
      const result = await assetInAssetService.delete({
        AssetParentId: AssetId,
        AssetId: +id,
        RecordStatus: RecordStatus.Deleted,
      });
      if (!result.ok) {
        errorOccurred = true;
      }
    });
    if (errorOccurred) {
      notification.error({
        message: t("ASSET_PACKAGES_PACKAGE_DELETE_FAILURE_MESSAGE"),
      });
    }
    await packagesInAssetLoader.refresh();
  }, []);

  const [onMoveRow, { processing: moveProcessing }] = useServiceCaller(
    async (dragIndex: number, hoverIndex: number) => {
      const draggedAsset = packagesInAsset[dragIndex];
      const hoveredAsset = packagesInAsset[hoverIndex];

      if (
        draggedAsset.Sequence === undefined ||
        hoveredAsset.Sequence === undefined
      ) {
        return;
      }

      const assetToUpdate = {
        ...draggedAsset,
        Sequence: hoveredAsset.Sequence ?? 1,
      };

      const sequenceChanged = draggedAsset.Sequence !== assetToUpdate.Sequence;
      const draggedToNewPosition =
        draggedAsset.AssetParentId !== hoveredAsset.AssetParentId;

      if (!draggedToNewPosition || !sequenceChanged) {
        return;
      }
      const result = await assetInAssetService.update(assetToUpdate);

      if (!result.ok) {
        notification.error({
          message: t("ASSET_PACKAGES_PACKAGE_REORDER_FAILURE_MESSAGE"),
          description: result.error?.Message,
        });
      }

      await packagesInAssetLoader.refresh();
    },
    [packagesInAsset]
  );

  // fake assets list is required to maintain proper pagination and items count in in transfer component
  const availablePackages = React.useMemo<ITransferItem[]>(() => {
    const items = packages.map((entity) => ({
      key: entity.Id.toString(),
      disabled: !!packagesInAsset.find(
        ({ AssetParentId }) => AssetParentId === entity.Id
      ),
      ...entity,
    }));

    const fakeList = Array.from(
      Array(packagesLoader.data?.TotalCount ?? 0).keys()
    ).map((_, i) => ({
      key: `fake-${i}`,
      disabled: true,
    }));
    const offset = ((assetFilter.PageNumber ?? 1) - 1) * PAGE_SIZE;
    fakeList.splice(offset, PAGE_SIZE, ...items);
    return items;
  }, [packages, packagesInAsset]);

  const selectedAssets = React.useMemo<ITransferItem[]>(
    () =>
      packagesInAsset.map((entity) => ({
        key: entity.AssetParentId.toString(),
        ...entity,
      })),
    [packages, packagesInAsset]
  );

  const onTableChange = (
    direction: "left" | "right",
    pagination: ITablePaginationConfig,
    filters: ITableFilter
  ) => {
    if (direction === "right") {
      return;
    }
    const filter = {
      ...assetFilter,
      Types: filters.AssetTypeCode?.length
        ? filters.AssetTypeCode.map((row) => `${row}`)
        : undefined,
      PageNumber: pagination.current,
    };
    setAssetFilter(filter);
  };

  const onSearchChanged = (value: string) => {
    const filter = {
      ...assetFilter,
      FullTextSearch: value,
    };
    setAssetFilter(filter);
  };

  const onChange = (_: any, direction: string, AssetsIds: string[]) => {
    if (direction === "right") {
      insertAssetInCollection(AssetsIds.map((AssetId) => +AssetId));
    }
    if (direction === "left") {
      deleteAssetInCollection(AssetsIds.map((AssetId) => +AssetId));
    }
  };

  const leftColumns = [
    {
      dataIndex: "Title",
      title: t("ASSET_PACKAGES_PACKAGE_TITLE_COLUMN"),
    },
    {
      dataIndex: "AssetTypeCode",
      title: t("ASSET_PACKAGES_PACKAGE_TYPE_COLUMN"),
      align: "center",
      width: "120px",
      render: (value: string, row: IAssetModel) => (
        <Tag colorRotate={assetTypes.findIndex(({ Code }) => Code === value)}>
          {row.AssetTypeDisplayName}
        </Tag>
      ),
    },
  ];

  const rightColumns = [
    {
      dataIndex: "AssetParentTitle",
      title: t("ASSET_PACKAGES_PACKAGE_TITLE_COLUMN"),
      className: "drag-visible",
    },
  ];

  const titles = [
    <Row justify="space-between" align="middle">
      <Col>
        {packagesLoader.data
          ? t("ASSET_PACKAGES_AVAILABLE_PACKAGES", {
              count: packagesLoader.data?.TotalCount,
            })
          : ""}
      </Col>
      <Col>
        <InputSearch
          allowClear
          style={{ width: "180px" }}
          onSearch={onSearchChanged}
        />
      </Col>
    </Row>,
    <Row align="middle">
      <Col>
        {packagesInAssetLoader.data?.TotalCount
          ? t("ASSET_PACKAGES_PACKAGES_IN_ASSET", {
              count: packagesInAssetLoader.data.TotalCount,
            })
          : ""}
      </Col>
    </Row>,
  ];

  const anyProcessing = insertProcessing || deleteProcessing || moveProcessing;

  return (
    <SectionGrid>
      <SectionGridItem>
        <TableTransfer
          pageSize={PAGE_SIZE}
          draggable
          titles={titles as any}
          leftLoading={packagesLoader.loading || anyProcessing}
          leftColumns={leftColumns}
          rightLoading={packagesInAssetLoader.loading || anyProcessing}
          rightColumns={rightColumns}
          onMoveRow={onMoveRow}
          onTableChange={onTableChange}
          onChange={onChange}
          dataSource={availablePackages}
          dataTarget={selectedAssets}
          listStyle={() => ({})}
        />
      </SectionGridItem>
    </SectionGrid>
  );
};
