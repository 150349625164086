import { ICommonAppState } from "@bms/common-services";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { withScreenContext } from "../../../../context";
import { withScreenDesignerContext } from "../../context";
import {
  ApplicationMenuPreview as ApplicationMenuPreviewDefinition,
  IApplicationMenuPreviewDispatchProps,
  IApplicationMenuPreviewOwnProps,
  IApplicationMenuPreviewStateProps,
} from "./ApplicationMenuPreview";

const mapStateToProps = (
  state: ICommonAppState
): IApplicationMenuPreviewStateProps => {
  return {};
};

const mapDispatchToProps = (
  dispatch: Dispatch
): IApplicationMenuPreviewDispatchProps => ({});
export const ApplicationMenuPreview = connect<
  IApplicationMenuPreviewStateProps,
  IApplicationMenuPreviewDispatchProps,
  IApplicationMenuPreviewOwnProps,
  ICommonAppState
>(
  mapStateToProps,
  mapDispatchToProps
)(
  withTranslation()(
    withScreenDesignerContext(
      withScreenContext(ApplicationMenuPreviewDefinition)
    )
  )
);
