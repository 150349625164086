export const BROWSE_ASSET = "BROWSE_ASSET";
export const BROWSE_ASSET_SUCCESS = "BROWSE_ASSET_SUCCESS";
export const BROWSE_ASSET_FAILURE = "BROWSE_ASSET_FAILURE";

export const SEARCH_ASSET = "SEARCH_ASSET";
export const SEARCH_ASSET_SUCCESS = "SEARCH_ASSET_SUCCESS";
export const SEARCH_ASSET_FAILURE = "SEARCH_ASSET_FAILURE";

export const SEARCH_ASSET_MORE = "SEARCH_ASSET_MORE";
export const SEARCH_ASSET_MORE_SUCCESS = "SEARCH_ASSET_MORE_SUCCESS";
export const SEARCH_ASSET_MORE_FAILURE = "SEARCH_ASSET_MORE_FAILURE";

export const GET_ASSET = "GET_ASSET";
export const GET_ASSET_SUCCESS = "GET_ASSET_SUCCESS";
export const GET_ASSET_FAILURE = "GET_ASSET_FAILURE";

export const CREATE_ASSET = "CREATE_ASSET";
export const CREATE_ASSET_SUCCESS = "CREATE_ASSET_SUCCESS";
export const CREATE_ASSET_FAILURE = "CREATE_ASSET_FAILURE";

export const UPDATE_ASSET = "UPDATE_ASSET";
export const UPDATE_ASSET_SUCCESS = "UPDATE_ASSET_SUCCESS";
export const UPDATE_ASSET_FAILURE = "UPDATE_ASSET_FAILURE";

export const UPDATE_ASSET_CONTENT_URL = "UPDATE_ASSET_CONTENT_URL";
export const UPDATE_ASSET_CONTENT_URL_SUCCESS =
  "UPDATE_ASSET_CONTENT_URL_SUCCESS";
export const UPDATE_ASSET_CONTENT_URL_FAILURE =
  "UPDATE_ASSET_CONTENT_URL_FAILURE";

export const UPDATE_ASSET_CONTENT = "UPDATE_ASSET_CONTENT";
export const UPDATE_ASSET_CONTENT_SUCCESS = "UPDATE_ASSET_CONTENT_SUCCESS";
export const UPDATE_ASSET_CONTENT_FAILURE = "UPDATE_ASSET_CONTENT_FAILURE";

export const DELETE_ASSET = "DELETE_ASSET";
export const DELETE_ASSET_SUCCESS = "DELETE_ASSET_SUCCESS";
export const DELETE_ASSET_FAILURE = "DELETE_ASSET_FAILURE";

export const GET_ASSET_CONTENT_UPLOAD_URL = "GET_ASSET_CONTENT_UPLOAD_URL";
export const GET_ASSET_CONTENT_UPLOAD_URL_SUCCESS =
  "GET_ASSET_CONTENT_UPLOAD_URL_SUCCESS";
export const GET_ASSET_CONTENT_UPLOAD_URL_FAILURE =
  "GET_ASSET_CONTENT_UPLOAD_URL_FAILURE";

export const UPLOAD_ASSET_CONTENT_FILE = "UPLOAD_ASSET_CONTENT_FILE";
export const UPLOAD_ASSET_CONTENT_FILE_CANCEL =
  "UPLOAD_ASSET_CONTENT_FILE_CANCEL";
export const UPLOAD_ASSET_CONTENT_FILE_SUCCESS =
  "UPLOAD_ASSET_CONTENT_FILE_SUCCESS";
export const UPLOAD_ASSET_CONTENT_FILE_FAILURE =
  "UPLOAD_ASSET_CONTENT_FILE_FAILURE";

export const CREATE_ASSET_COLLECTION = "CREATE_ASSET_COLLECTION";
export const CREATE_ASSET_COLLECTION_SUCCESS =
  "CREATE_ASSET_COLLECTION_SUCCESS";
export const CREATE_ASSET_COLLECTION_FAILURE =
  "CREATE_ASSET_COLLECTION_FAILURE";

export const SAVE_ASSETS_IN_COLLECTION = "SAVE_ASSETS_IN_COLLECTION";
export const SAVE_ASSETS_IN_COLLECTION_SUCCESS =
  "SAVE_ASSETS_IN_COLLECTION_SUCCESS";
export const SAVE_ASSETS_IN_COLLECTION_FAILURE =
  "SAVE_ASSETS_IN_COLLECTION_FAILURE";

export const CREATE_ASSET_COLLECTION_AND_SAVE_ASSETS =
  "CREATE_ASSET_COLLECTION_AND_SAVE_ASSETS";

export const GET_ASSETS_COLLECTION = "GET_ASSETS_COLLECTION";
export const GET_ASSETS_COLLECTION_SUCCESS = "GET_ASSETS_COLLECTION_SUCCESS";
export const GET_ASSETS_COLLECTION_FAILURE = "GET_ASSETS_COLLECTION_FAILURE";

export const UPDATE_ASSETS_COLLECTION = "UPDATE_ASSETS_COLLECTION";
export const UPDATE_ASSETS_COLLECTION_SUCCESS =
  "UPDATE_ASSETS_COLLECTION_SUCCESS";
export const UPDATE_ASSETS_COLLECTION_FAILURE =
  "UPDATE_ASSETS_COLLECTION_FAILURE";

export const CREATE_ASSETS_COLLECTION = "CREATE_ASSETS_COLLECTION";
export const CREATE_ASSETS_COLLECTION_SUCCESS =
  "CREATE_ASSETS_COLLECTION_SUCCESS";
export const CREATE_ASSETS_COLLECTION_FAILURE =
  "CREATE_ASSETS_COLLECTION_FAILURE";

export const DELETE_ASSETS_COLLECTION = "DELETE_ASSETS_COLLECTION";
export const DELETE_ASSETS_COLLECTION_SUCCESS =
  "DELETE_ASSETS_COLLECTION_SUCCESS";
export const DELETE_ASSETS_COLLECTION_FAILURE =
  "DELETE_ASSETS_COLLECTION_FAILURE";

export const SEARCH_ASSETS_COLLECTION = "SEARCH_ASSETS_COLLECTION";
export const SEARCH_ASSETS_COLLECTION_SUCCESS =
  "SEARCH_ASSETS_COLLECTION_SUCCESS";
export const SEARCH_ASSETS_COLLECTION_FAILURE =
  "SEARCH_ASSETS_COLLECTION_FAILURE";

export const BROWSE_ASSETS_COLLECTION = "BROWSE_ASSETS_COLLECTION";
export const BROWSE_ASSETS_COLLECTION_SUCCESS =
  "BROWSE_ASSETS_COLLECTION_SUCCESS";
export const BROWSE_ASSETS_COLLECTION_FAILURE =
  "BROWSE_ASSETS_COLLECTION_FAILURE";
export const BROWSE_ASSETS_COLLECTION_CANCEL =
  "BROWSE_ASSETS_COLLECTION_CANCEL";

export const SEARCH_ASSETS_BY_ASSET_COLLECTION_ID =
  "SEARCH_ASSETS_BY_ASSET_COLLECTION_ID";
export const SEARCH_ASSETS_BY_ASSET_COLLECTION_ID_SUCCESS =
  "SEARCH_ASSETS_BY_ASSET_COLLECTION_ID_SUCCESS";
export const SEARCH_ASSETS_BY_ASSET_COLLECTION_ID_FAILURE =
  "SEARCH_ASSETS_BY_ASSET_COLLECTION_ID_FAILURE";

export const GET_ASSET_TYPES = "GET_ASSET_TYPES";
export const GET_ASSET_TYPES_SUCCESS = "GET_ASSET_TYPES_SUCCESS";
export const GET_ASSET_TYPES_FAILURE = "GET_ASSET_TYPES_FAILURE";

export const GET_ASSET_EVENT_TYPES = "GET_ASSET_EVENT_TYPES";
export const GET_ASSET_EVENT_TYPES_SUCCESS = "GET_ASSET_EVENT_TYPES_SUCCESS";
export const GET_ASSET_EVENT_TYPES_FAILURE = "GET_ASSET_EVENT_TYPES_FAILURE";

export const GET_ASSET_AGE_RESTRICTIONS = "GET_ASSET_AGE_RESTRICTIONS";
export const GET_ASSET_AGE_RESTRICTIONS_SUCCESS =
  "GET_ASSET_AGE_RESTRICTIONS_SUCCESS";
export const GET_ASSET_AGE_RESTRICTIONS_FAILURE =
  "GET_ASSET_AGE_RESTRICTIONS_FAILURE";

export const SEARCH_ASSET_CATEGORIES = "SEARCH_ASSET_CATEGORIES";
export const SEARCH_ASSET_CATEGORIES_SUCCESS =
  "SEARCH_ASSET_CATEGORIES_SUCCESS";
export const SEARCH_ASSET_CATEGORIES_FAILURE =
  "SEARCH_ASSET_CATEGORIES_FAILURE";

export const INSERT_ASSET_CATEGORY = "INSERT_ASSET_CATEGORY";
export const INSERT_ASSET_CATEGORY_SUCCESS = "INSERT_ASSET_CATEGORY_SUCCESS";
export const INSERT_ASSET_CATEGORY_FAILURE = "INSERT_ASSET_CATEGORY_FAILURE";

export const UPDATE_ASSET_CATEGORY = "UPDATE_ASSET_CATEGORY";
export const UPDATE_ASSET_CATEGORY_SUCCESS = "UPDATE_ASSET_CATEGORY_SUCCESS";
export const UPDATE_ASSET_CATEGORY_FAILURE = "UPDATE_ASSET_CATEGORY_FAILURE";

export const DELETE_ASSET_CATEGORY = "DELETE_ASSET_CATEGORY";
export const DELETE_ASSET_CATEGORY_SUCCESS = "DELETE_ASSET_CATEGORY_SUCCESS";
export const DELETE_ASSET_CATEGORY_FAILURE = "DELETE_ASSET_CATEGORY_FAILURE";

export const SEARCH_ASSET_PEOPLE = "SEARCH_ASSET_PEOPLE";
export const SEARCH_ASSET_PEOPLE_SUCCESS = "SEARCH_ASSET_PEOPLE_SUCCESS";
export const SEARCH_ASSET_PEOPLE_FAILURE = "SEARCH_ASSET_PEOPLE_FAILURE";

export const GET_UPLOAD_FILE_INFO = "GET_UPLOAD_FILE_INFO";
export const GET_UPLOAD_FILE_INFO_SUCCESS = "GET_UPLOAD_FILE_INFO_SUCCESS";
export const GET_UPLOAD_FILE_INFO_FAILURE = "GET_UPLOAD_FILE_INFO_FAILURE";

export const GET_CONTENT_UPLOAD_URL = "GET_CONTENT_UPLOAD_URL";
export const GET_CONTENT_UPLOAD_URL_SUCCESS = "GET_CONTENT_UPLOAD_URL_SUCCESS";
export const GET_CONTENT_UPLOAD_URL_FAILURE = "GET_CONTENT_UPLOAD_URL_FAILURE";

export const UPLOAD_ASSET_IMAGE = "UPLOAD_ASSET_IMAGE";
export const UPLOAD_ASSET_IMAGE_SUCCESS = "UPLOAD_ASSET_IMAGE_SUCCESS";
export const UPLOAD_ASSET_IMAGE_FAILURE = "UPLOAD_ASSET_IMAGE_FAILURE";

export const UPDATE_ASSET_IMAGE = "UPDATE_ASSET_IMAGE";
export const UPDATE_ASSET_IMAGE_SUCCESS = "UPDATE_ASSET_IMAGE_SUCCESS";
export const UPDATE_ASSET_IMAGE_FAILURE = "UPDATE_ASSET_IMAGE_FAILURE";

export const DELETE_ASSET_IMAGE = "DELETE_ASSET_IMAGE";
export const DELETE_ASSET_IMAGE_SUCCESS = "DELETE_ASSET_IMAGE_SUCCESS";
export const DELETE_ASSET_IMAGE_FAILURE = "DELETE_ASSET_IMAGE_FAILURE";

export const ADD_ASSET_IMAGE = "ADD_ASSET_IMAGE";
export const ADD_ASSET_IMAGE_SUCCESS = "ADD_ASSET_IMAGE_SUCCESS";
export const ADD_ASSET_IMAGE_FAILURE = "ADD_ASSET_IMAGE_FAILURE";

export const GET_ASSET_IMAGE_TYPES = "GET_ASSET_IMAGE_TYPES";
export const GET_ASSET_IMAGE_TYPES_SUCCESS = "GET_ASSET_IMAGE_TYPES_SUCCESS";
export const GET_ASSET_IMAGE_TYPES_FAILURE = "GET_ASSET_IMAGE_TYPES_FAILURE";

export const GET_ASSET_CONTENT_UPLOAD_FILE_INFO =
  "GET_ASSET_CONTENT_UPLOAD_FILE_INFO";
export const GET_ASSET_CONTENT_UPLOAD_FILE_INFO_SUCCESS =
  "GET_ASSET_CONTENT_UPLOAD_FILE_INFO_SUCCESS";
export const GET_ASSET_CONTENT_UPLOAD_FILE_INFO_FAILURE =
  "GET_ASSET_CONTENT_UPLOAD_FILE_INFO_FAILURE";

export const ADD_ASSET_CONTENT = "ADD_ASSET_CONTENT";
export const ADD_ASSET_CONTENT_SUCCESS = "ADD_ASSET_CONTENT_SUCCESS";
export const ADD_ASSET_CONTENT_FAILURE = "ADD_ASSET_CONTENT_FAILURE";

export const DELETE_ASSET_CONTENT = "DELETE_ASSET_CONTENT";
export const DELETE_ASSET_CONTENT_SUCCESS = "DELETE_ASSET_CONTENT_SUCCESS";
export const DELETE_ASSET_CONTENT_FAILURE = "DELETE_ASSET_CONTENT_FAILURE";

export const GET_ASSET_CONTENT_TYPES = "GET_ASSET_CONTENT_TYPES";
export const GET_ASSET_CONTENT_TYPES_SUCCESS =
  "GET_ASSET_CONTENT_TYPES_SUCCESS";
export const GET_ASSET_CONTENT_TYPES_FAILURE =
  "GET_ASSET_CONTENT_TYPES_FAILURE";

export const GET_ASSET_CONTENT_STREAM_TYPES = "GET_ASSET_CONTENT_STREAM_TYPES";
export const GET_ASSET_CONTENT_STREAM_TYPES_SUCCESS =
  "GET_ASSET_CONTENT_STREAM_TYPES_SUCCESS";
export const GET_ASSET_CONTENT_STREAM_TYPES_FAILURE =
  "GET_ASSET_CONTENT_STREAM_TYPES_FAILURE";

export const SELECT_ASSET_IN_COLLECTION = "SELECT_ASSET_IN_COLLECTION";
export const SELECT_ASSET_IN_COLLECTION_SUCCESS =
  "SELECT_ASSET_IN_COLLECTION_SUCCESS";
export const SELECT_ASSET_IN_COLLECTION_FAILURE =
  "SELECT_ASSET_IN_COLLECTION_FAILURE";

export const ADD_ASSET_IN_COLLECTION = "ADD_ASSET_IN_COLLECTION";
export const ADD_ASSET_IN_COLLECTION_SUCCESS =
  "ADD_ASSET_IN_COLLECTION_SUCCESS";
export const ADD_ASSET_IN_COLLECTION_FAILURE =
  "ADD_ASSET_IN_COLLECTION_FAILURE";

export const UPDATE_ASSET_IN_COLLECTION = "UPDATE_ASSET_IN_COLLECTION";
export const UPDATE_ASSET_IN_COLLECTION_SUCCESS =
  "UPDATE_ASSET_IN_COLLECTION_SUCCESS";
export const UPDATE_ASSET_IN_COLLECTION_FAILURE =
  "UPDATE_ASSET_IN_COLLECTION_FAILURE";

export const DELETE_ASSET_IN_COLLECTION = "DELETE_ASSET_IN_COLLECTION";
export const DELETE_ASSET_IN_COLLECTION_SUCCESS =
  "DELETE_ASSET_IN_COLLECTION_SUCCESS";
export const DELETE_ASSET_IN_COLLECTION_FAILURE =
  "DELETE_ASSET_IN_COLLECTION_FAILURE";

export const UPLOAD_VIDEO = "UPLOAD_VIDEO";
export const UPLOAD_VIDEO_SUCCESS = "UPLOAD_VIDEO_SUCCESS";
export const UPLOAD_VIDEO_FAILURE = "UPLOAD_VIDEO_FAILURE";

export const GET_ASSET_COMMENT = "GET_ASSET_COMMENT";
export const GET_ASSET_COMMENT_SUCCESS = "GET_ASSET_COMMENT_SUCCESS";
export const GET_ASSET_COMMENT_FAILURE = "GET_ASSET_COMMENT_FAILURE";

export const SEARCH_ASSET_COMMENTS = "SEARCH_ASSET_COMMENTS";
export const SEARCH_ASSET_COMMENTS_SUCCESS = "SEARCH_ASSET_COMMENTS_SUCCESS";
export const SEARCH_ASSET_COMMENTS_FAILURE = "SEARCH_ASSET_COMMENTS_FAILURE";

export const SEARCH_ASSET_COMMENTS_MORE = "SEARCH_ASSET_COMMENTS_MORE";
export const SEARCH_ASSET_COMMENTS_MORE_SUCCESS =
  "SEARCH_ASSET_COMMENTS_MORE_SUCCESS";
export const SEARCH_ASSET_COMMENTS_MORE_FAILURE =
  "SEARCH_ASSET_COMMENTS_MORE_FAILURE";

export const ADD_ASSET_COMMENT = "ADD_ASSET_COMMENT";
export const ADD_ASSET_COMMENT_SUCCESS = "ADD_ASSET_COMMENT_SUCCESS";
export const ADD_ASSET_COMMENT_FAILURE = "ADD_ASSET_COMMENT_FAILURE";

export const GET_ASSET_EVENT = "GET_ASSET_EVENT";
export const GET_ASSET_EVENT_SUCCESS = "GET_ASSET_EVENT_SUCCESS";
export const GET_ASSET_EVENT_FAILURE = "GET_ASSET_EVENT_FAILURE";

export const ADD_ASSET_EVENT = "ADD_ASSET_EVENT";
export const ADD_ASSET_EVENT_SUCCESS = "ADD_ASSET_EVENT_SUCCESS";
export const ADD_ASSET_EVENT_FAILURE = "ADD_ASSET_EVENT_FAILURE";

export const SEARCH_ASSET_EVENTS = "SEARCH_ASSET_EVENTS";
export const SEARCH_ASSET_EVENTS_SUCCESS = "SEARCH_ASSET_EVENTS_SUCCESS";
export const SEARCH_ASSET_EVENTS_FAILURE = "SEARCH_ASSET_EVENTS_FAILURE";

export const BUY_ASSET = "BUY_ASSET";
export const BUY_ASSET_SUCCESS = "BUY_ASSET_SUCCESS";
export const BUY_ASSET_FAILURE = "BUY_ASSET_FAILURE";

export const SELECT_ASSET_PARENT = "SELECT_ASSET_PARENT";
export const SELECT_ASSET_PARENT_SUCCESS = "SELECT_ASSET_PARENT_SUCCESS";
export const SELECT_ASSET_PARENT_FAILURE = "SELECT_ASSET_PARENT_FAILURE";

export const SELECT_ASSETS_IN_ASSET = "SELECT_ASSETS_IN_ASSET";
export const SELECT_ASSETS_IN_ASSET_SUCCESS = "SELECT_ASSETS_IN_ASSET_SUCCESS";
export const SELECT_ASSETS_IN_ASSET_FAILURE = "SELECT_ASSETS_IN_ASSET_FAILURE";

export const DELETE_ASSET_IN_ASSET = "DELETE_ASSET_IN_ASSET";
export const DELETE_ASSET_IN_ASSET_SUCCESS = "DELETE_ASSET_IN_ASSET_SUCCESS";
export const DELETE_ASSET_IN_ASSET_FAILURE = "DELETE_ASSET_IN_ASSET_FAILURE";

export const INSERT_ASSET_IN_ASSET = "INSERT_ASSET_IN_ASSET";
export const INSERT_ASSET_IN_ASSET_SUCCESS = "INSERT_ASSET_IN_ASSET_SUCCESS";
export const INSERT_ASSET_IN_ASSET_FAILURE = "INSERT_ASSET_IN_ASSET_FAILURE";

export const UPDATE_ASSET_IN_ASSET = "UPDATE_ASSET_IN_ASSET";
export const UPDATE_ASSET_IN_ASSET_SUCCESS = "UPDATE_ASSET_IN_ASSET_SUCCESS";
export const UPDATE_ASSET_IN_ASSET_FAILURE = "UPDATE_ASSET_IN_ASSET_FAILURE";
