import { AssetStore, ICommonAppState } from "@bms/common-services";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { DictionaryAgeRestrictionList as DictionaryAgeRestrictionListDefinition } from "./DictionaryAgeRestrictionList";

const mapStateToProps = (state: ICommonAppState) => {
  return {
    assetAgeRestrictions: state.asset.assetAgeRestrictions.data ?? [],
    isFetching: state.asset.assetAgeRestrictions.isFetching,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getAssetAgeRestrictions: () => {
    return dispatch(AssetStore.Actions.getAssetAgeRestrictions());
  },
});

export const DictionaryAgeRestrictionList = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(DictionaryAgeRestrictionListDefinition));
