import { AnalyticsAggregationPeriodType } from "@bms/common-services";
import { Choose, ChooseOption } from "@bms/common-ui";
import React from "react";
import { useTranslation } from "react-i18next";

interface IRatingAppAggregationTypeSelect {
  value?: string;
  onChange?: (newValue: string) => void;
}

export const RatingAppAggregationTypeSelect = (
  props: IRatingAppAggregationTypeSelect
) => {
  const { value = AnalyticsAggregationPeriodType.YEAR, onChange } = props;
  const { t } = useTranslation();

  const onValueChange = (newValue: string) => {
    onChange?.(newValue);
  };

  return (
    <Choose
      value={value}
      onChange={onValueChange}
      getPopupContainer={(trigger) => trigger.parentNode}
    >
      {Object.keys(AnalyticsAggregationPeriodType).map((key, index) => {
        return (
          <ChooseOption key={`select--${key}--${index}`} value={key}>
            {t(key)}
          </ChooseOption>
        );
      })}
    </Choose>
  );
};
