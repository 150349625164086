import { AssetType } from "@bms/common-services";
import { intersection } from "lodash";

export type FieldType =
  | "AssetTypeCode"
  | "ChildAssetId"
  | "ParentAssetId"
  | "OrderInParent"
  | "Title"
  | "AssetAgeRestrictionValueMin"
  | "Categories"
  | "CountriesAllowed"
  | "CountriesBlocked"
  | "Description"
  | "ShortDescription"
  | "LongDescription"
  | "IsFree"
  | "Director"
  | "Writer"
  | "Cast"
  | "DurationMiliseconds"
  | "Year"
  | "AvailableDate"
  | "StartEndDates"
  | "CreatorUserId"
  | "AssetAction"
  | "AdUrl";

interface IFieldsSections {
  restriction: FieldType[];
  general: FieldType[];
  people: FieldType[];
  custom: FieldType[];
}

export type ISection = keyof IFieldsSections;

export const baseCommonFields: FieldType[] = [
  "AssetTypeCode",
  "Title",
  "AssetAgeRestrictionValueMin",
  "Categories",
  "IsFree",
  "AvailableDate",
  "CreatorUserId",
  "CountriesAllowed",
  "CountriesBlocked",
];

export const descriptionCommonFields: FieldType[] = [
  "Description",
  "ShortDescription",
  "LongDescription",
];

export const commonFields: FieldType[] = [
  ...baseCommonFields,
  ...descriptionCommonFields,
];

const advertisementCommonFields: FieldType[] = [
  "AssetTypeCode",
  "Title",
  "AvailableDate",
  "CreatorUserId",
];
const peopleFields: FieldType[] = ["Director", "Writer", "Cast"];
export const childFields: FieldType[] = ["ParentAssetId", "OrderInParent"];

export const getFieldNamesByParams = (
  assetType: AssetType,
  hasParent: boolean
): FieldType[] => {
  switch (assetType) {
    case AssetType.Video:
      // CATCHUP asset is of type VOD with LIVE asset defined as the parent
      const catchupFields: FieldType[] = hasParent ? ["ParentAssetId"] : [];

      return [
        ...commonFields,
        ...peopleFields,
        ...catchupFields,
        "DurationMiliseconds",
        "Year",
        "AdUrl",
      ];
    case AssetType.Series:
      return [...commonFields, ...peopleFields, "Year"];
    case AssetType.Season:
      return [...commonFields, ...childFields, "Year"];
    case AssetType.Episode:
      return [
        ...commonFields,
        ...childFields,
        ...peopleFields,
        "DurationMiliseconds",
        "Year",
        "AdUrl",
      ];
    case AssetType.Channel:
      return [...commonFields, "OrderInParent"];
    case AssetType.Program:
      return [
        ...commonFields,
        "StartEndDates",
        ...childFields,
        ...peopleFields,
        "DurationMiliseconds",
        "Year",
      ];
    case AssetType.Live:
      return [
        ...commonFields,
        "ChildAssetId",
        "StartEndDates",
        ...peopleFields,
        "DurationMiliseconds",
        "AdUrl",
      ];
    case AssetType.Intro:
      return [...commonFields, ...peopleFields, "DurationMiliseconds", "Year"];
    case AssetType.Package:
      return [...commonFields];
    case AssetType.Premiere:
      return [...commonFields, "StartEndDates"];
    case AssetType.Article:
      return [...commonFields, "StartEndDates"];
    case AssetType.Event:
      return [...commonFields, "StartEndDates"];
    case AssetType.Podcast:
      return [
        ...commonFields,
        ...childFields,
        ...peopleFields,
        "DurationMiliseconds",
        "Year",
      ];
    case AssetType.Album:
      return [...commonFields, "Year"];
    case AssetType.Advertisement:
      return [
        ...advertisementCommonFields,
        ...descriptionCommonFields,
        "AssetAction",
        "Year",
      ];
    default:
      return [];
  }
};

const restrictionSectionFields: FieldType[] = [
  "Categories",
  "AssetAgeRestrictionValueMin",
  "IsFree",
  "AvailableDate",
  "StartEndDates",
  "CountriesAllowed",
  "CountriesBlocked",
];
const generalSectionFields: FieldType[] = [
  "AssetTypeCode",
  "ChildAssetId",
  "ParentAssetId",
  "OrderInParent",
  "Title",
  "Description",
  "LongDescription",
  "ShortDescription",
  "DurationMiliseconds",
  "Year",
  "CreatorUserId",
];

export const CUSTOM_METADATA_FILEDS = {
  ACTION_TYPE: "CustomMetadata.Action.ActionType",
  ACTION_URL: "CustomMetadata.Action.Url",
  AD_URL: "CustomMetadata.AdUrl",
};

const customSectionFields: FieldType[] = ["AssetAction", "AdUrl"];
const peopleSectionFields: FieldType[] = peopleFields;

export const getSectionsFields = (
  assetType: AssetType,
  hasParent: boolean = false
): IFieldsSections => {
  const fieldNames = getFieldNamesByParams(assetType, hasParent);

  return {
    restriction: intersection(fieldNames, restrictionSectionFields),
    general: intersection(fieldNames, generalSectionFields),
    people: intersection(fieldNames, peopleSectionFields),
    custom: intersection(fieldNames, customSectionFields),
  };
};

export const formLayouts = {
  formItem: {
    labelCol: {
      xs: { span: 8 },
      lg: { span: 6 },
      xl: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 16 },
      lg: { span: 18 },
      xl: { span: 20 },
    },
  },
  modalFormItem: {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
      md: { span: 6 },
      lg: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
      md: { span: 18 },
      lg: { span: 18 },
    },
  },
  tailFormItem: {
    wrapperCol: {
      style: {
        textAlign: "right",
        marginBottom: 0,
      } as React.CSSProperties,
    },
  },
};

export interface AssetCurrentDescriptionFields {
  [key: string]: {
    value: string;
  };
}

export interface AssetDescriptionMode {
  name: string;
  label: string;
  value: string;
  maxLength?: number;
  initialValue?: string;
}
