import SignalRClient from "../SignalRClient";

export class AssetEventProgressClient extends SignalRClient {
  public static instance: AssetEventProgressClient;

  public static getInstance() {
    if (AssetEventProgressClient.instance) {
      return AssetEventProgressClient.instance;
    }

    AssetEventProgressClient.instance = new AssetEventProgressClient();

    return AssetEventProgressClient.instance;
  }

  constructor() {
    super({
      url: "/AssetEventProgress/hub",
    });
  }
}
