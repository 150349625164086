import {ICommonAppState, MediaListType, MediaStore,} from "@bms/common-services";
import {connect} from "react-redux";
import {Dispatch} from "redux";
import {withScreenDesignerContext} from "../../context";
import {
  IListComponentPreviewDispatchProps,
  IListComponentPreviewOwnProps,
  IListComponentPreviewStateProps,
  ListComponentPreview as ListComponentPreviewDefinition,
} from "./ListComponentPreview";

const mapStateToProps = (
  state: ICommonAppState,
): IListComponentPreviewStateProps => {
  return {
    sources: state.applicationConfiguration.sources,
    media: state.media.mediaList
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch
): IListComponentPreviewDispatchProps => ({
  getDataSource: (sourceId: number) => {
    return dispatch({ type: "nothing" });
  },
  getMediaList: (listId: number) => {
    return dispatch(MediaStore.Actions.getMediaList({
      MediaListId: listId,
      IncludeImages: true,
      IncludeMedia: true
    }));
  },
});

export const ListComponentPreview = connect<
  IListComponentPreviewStateProps,
  IListComponentPreviewDispatchProps,
  IListComponentPreviewOwnProps,
  ICommonAppState
>(
  mapStateToProps,
  mapDispatchToProps
)(withScreenDesignerContext(ListComponentPreviewDefinition));
