import React from "react";
import { Trans } from "react-i18next";

import { REGEX } from "../../../";

export const ipValid = () => {
  const messageIpValid = (
    <Trans i18nKey="IP_VALIDATION_MESSAGE">IP address is not valid</Trans>
  );

  return {
    message: messageIpValid,
    pattern: REGEX.IP,
  };
};
