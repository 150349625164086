import React from "react";
import { Collapse as AntCollapse } from "antd";
import { CollapseProps as AntCollapseProps } from "antd/lib/collapse";

export interface CollapseProps extends AntCollapseProps {}

export class Collapse extends React.PureComponent<CollapseProps> {
  public render() {
    const { children, ...rest } = this.props;
    return <AntCollapse {...rest}>{children}</AntCollapse>;
  }
}
