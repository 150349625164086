import {
  IAssetCommentListModel,
  IAssetCommentModel,
  IAssetCommentSearchFilterModel,
} from "../../models";
import { SimpleServiceBase } from "../Base";

export class AssetCommentService extends SimpleServiceBase<
  IAssetCommentModel,
  IAssetCommentListModel,
  number,
  IAssetCommentSearchFilterModel
> {
  get url(): string {
    return "/AssetComments";
  }
}
