import { ITextComponentModel, UploadFileInfoModel } from "../models";
import { SimpleServiceBase } from "./Base";
import { Observable, Observer } from "rxjs";
import { HTTP_METHOD } from "../constants/http";
import { AxiosSubscriber } from "./AxiosSubscriber";

export class TextComponentsService extends SimpleServiceBase<
  ITextComponentModel,
  any,
  number,
  any
> {
  get url(): string {
    return "/TextComponents";
  }

  public getAttachmentUploadUrl = (
    textComponentId: number,
    attachmentGuid: string
  ) =>
    new Observable(
      (observer: Observer<UploadFileInfoModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: {
              attachmentGuid,
              textComponentId,
            },
          },
          method: HTTP_METHOD.GET,
          url: `${this.url}/GetAttachmentUploadUrl`,
        })
    );

  public getAttachmentUrl = (textComponentId: number, attachmentGuid: string) =>
    new Observable(
      (observer: Observer<UploadFileInfoModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: {
              attachmentGuid,
              textComponentId,
            },
          },
          method: HTTP_METHOD.GET,
          url: `${this.url}/GetAttachmentUrl`,
        })
    );
}
