import { ThemeConfig, theme as AntTheme } from "antd";
import Color from "color";

const primary = Color("#0094FF");
const light = Color("#FFFFFF");
const dark100 = Color("#121212");
const dark200 = Color("#1e1e1e");
const dark300 = Color("#2e2e2e");
const dark400 = Color("#3e3e3e");
const dark500 = Color("#4e4e4e");

const globalTokens: ThemeConfig["token"] = {
  colorText: light.fade(0.35).toString(),
  colorTextSecondary: light.fade(0.55).toString(),
  colorTextHeading: light.fade(0.15).toString(),
  colorTextDisabled: light.fade(0.75).toString(),
  colorTextTertiary: light.fade(0.9).toString(),
  colorIcon: light.fade(0.35).toString(),
  colorIconHover: light.fade(0.15).toString(),
  colorBorder: dark300.toString(),
  colorBgContainer: dark300.toString(),
  colorBgBase: dark300.toString(),
  colorPrimary: primary.toString(),
  colorPrimaryBgHover: dark500.toString(),
  controlItemBgActive: dark400.toString(),
  controlItemBgHover: dark400.toString(),
};

export const theme: ThemeConfig = {
  algorithm: AntTheme.darkAlgorithm,
  token: globalTokens,
  components: {
    Card: {
      colorBgContainer: dark100.toString(),
    },
    Button: {
      colorText: globalTokens.colorTextHeading,
      colorBgBase: globalTokens.colorBgContainer,
      primaryShadow: "none",
    },
    Input: {
      colorTextPlaceholder: globalTokens.colorTextSecondary,
      colorBgContainerDisabled: dark500.toString(),
      hoverBorderColor: light.fade(0.9).toString(),
      colorIconHover: globalTokens.colorTextSecondary,
      controlOutline: "none",
    },
    InputNumber: {
      colorTextPlaceholder: globalTokens.colorTextSecondary,
      colorBgContainerDisabled: dark500.toString(),
      hoverBorderColor: light.fade(0.9).toString(),
      colorIconHover: globalTokens.colorTextSecondary,
      controlOutline: "none",
    },
    Checkbox: {
      colorWhite: globalTokens.colorBgContainer,
      colorText: primary.toString(),
    },
    Select: {
      colorTextPlaceholder: globalTokens.colorTextSecondary,
      colorBgContainerDisabled: dark500.toString(),
      colorIconHover: globalTokens.colorTextSecondary,
      optionSelectedBg: primary.toString(),
      multipleItemBg: primary.toString(),
      controlOutline: "none",
    },
    DatePicker: {
      colorTextPlaceholder: globalTokens.colorTextSecondary,
      colorBgContainerDisabled: dark500.toString(),
      colorIconHover: globalTokens.colorTextSecondary,
      controlOutline: "none",
      controlItemBgActive: primary.toString(),
      controlItemBgHover: dark400.toString(),
    },
    Menu: {
      itemBg: dark100.toString(),
      popupBg: dark500.toString(),
      itemActiveBg: light.fade(0.95).toString(),
      colorHighlight: light.toString(),
      darkItemBg: dark100.toString(),
      darkItemSelectedBg: dark200.toString(),
      itemHoverBg: dark200.toString(),
      darkSubMenuItemBg: dark100.toString(),
    },
    Table: {
      colorBgContainer: dark100.toString(),
      rowHoverBg: dark300.toString(),
      rowSelectedHoverBg: dark200.toString(),
    },
    Breadcrumb: {
      linkColor: globalTokens.colorText,
      linkHoverColor: globalTokens.colorTextSecondary,
      separatorColor: globalTokens.colorText,
    },
    Layout: {
      siderBg: "transparent",
    },
    Modal: {
      contentBg: dark400.toString(),
      headerBg: dark400.toString(),
    },
    Notification: {
      colorText: light.toString(),
      colorTextHeading: light.toString(),
    },
  },
};
