import React from "react";
import { Rate as AntRate } from "antd";
import { RateProps as AntRateProps } from "antd/lib/rate";

export interface IRateProps extends AntRateProps {
  children?: React.ReactNode;
}

export class Rate extends React.PureComponent<IRateProps> {
  public render() {
    const { children, ...rest } = this.props;
    return <AntRate {...rest}>{children}</AntRate>;
  }
}
