import { ActionsObservable, ofType, StateObservable } from "redux-observable";
import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { ICommonAppState } from "..";
import { IErrorModel } from "../../models";
import { ICurrencyModel } from "../../models/Currencies/CurrencyModel";
import { CurrencyService } from "../../services";
import {
  selectCurrenciesFailure,
  selectCurrenciesSuccess,
  updateCurrencySuccess,
  updateCurrencyFailure,
  insertCurrencySuccess,
  insertCurrencyFailure,
  deleteCurrencySuccess,
  deleteCurrencyFailure,
} from "./actions";
import {
  IDeleteCurrencyAction,
  IInsertCurrencyAction,
  ISelectCurrenciesAction,
  IUpdateCurrencyAction,
} from "./types";
import * as Consts from "./consts";

const currencyService: CurrencyService = new CurrencyService();

const selectCurrenciesEpic = (
  action$: ActionsObservable<ISelectCurrenciesAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.SELECT_CURRENCIES),
    switchMap(() =>
      currencyService.select().pipe(
        map((response: ICurrencyModel[]) => {
          return selectCurrenciesSuccess(response);
        }),
        catchError((error: IErrorModel) => of(selectCurrenciesFailure(error)))
      )
    )
  );

const updateCurrencyEpic = (
  action$: ActionsObservable<IUpdateCurrencyAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.UPDATE_CURRENCY),
    switchMap((action: IUpdateCurrencyAction) =>
      currencyService.update(action.payload).pipe(
        map((data: ICurrencyModel) => {
          return updateCurrencySuccess(data);
        }),
        catchError((error: IErrorModel) => of(updateCurrencyFailure(error)))
      )
    )
  );

const insertCurrencyEpic = (
  action$: ActionsObservable<IInsertCurrencyAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.INSERT_CURRENCY),
    switchMap((action: IInsertCurrencyAction) =>
      currencyService.insert(action.payload).pipe(
        map((data: ICurrencyModel) => {
          return insertCurrencySuccess(data);
        }),
        catchError((error: IErrorModel) => of(insertCurrencyFailure(error)))
      )
    )
  );

const deleteCurrencyEpic = (
  action$: ActionsObservable<IDeleteCurrencyAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.DELETE_CURRENCY),
    switchMap((action: IDeleteCurrencyAction) =>
      currencyService.delete(action.payload).pipe(
        map(() => {
          return deleteCurrencySuccess();
        }),
        catchError((error: IErrorModel) => of(deleteCurrencyFailure(error)))
      )
    )
  );

export const currenciesEpics = [
  selectCurrenciesEpic,
  updateCurrencyEpic,
  insertCurrencyEpic,
  deleteCurrencyEpic,
];
