import { IAssetContentTypeModel, IErrorModel } from "@bms/common-services";
import { filter } from "lodash";

interface IAssetContentTypes {
  data?: IAssetContentTypeModel[];
  error?: IErrorModel;
  isFetching: boolean;
}

export class assetContentTypeHelper {
  static audioType = (assetContentTypes: IAssetContentTypes) => {
    const regex = /audio\/.*/;
    const audioData = filter(assetContentTypes?.data, (type) =>
      type.Code.match(regex)
    ) as IAssetContentTypeModel[];
    return { ...assetContentTypes, data: audioData };
  };
}
