import React, { useEffect, useMemo, useState } from "react";
import { RadialChart, RadialChartPoint } from "react-vis";
import "../../../../../node_modules/react-vis/dist/style.css";
import { getColor } from "./colors";

export interface DonutChartPoint extends RadialChartPoint {}

export interface IDonutChartProps {
  height: number;
  width: number;
  data: DonutChartPoint[];
  forceHighlightByLabel?: string;
  showLabels?: boolean | undefined;
  labelsRadiusMultiplier?: number | undefined;
  onValueClick?: (point: DonutChartPoint) => void;
  onHighlight?: (point: DonutChartPoint | undefined) => void;
}

export const DonutChart: React.FC<IDonutChartProps> = ({
  height,
  width,
  data,
  forceHighlightByLabel,
  showLabels,
  labelsRadiusMultiplier,
  onHighlight,
  onValueClick,
}) => {
  const [highlightedLabel, setHighlightedLabel] = useState<string | undefined>(
    undefined
  );

  const highlighted = useMemo(
    () =>
      data.find(
        ({ label }) => label === (forceHighlightByLabel ?? highlightedLabel)
      ),
    [data, forceHighlightByLabel, highlightedLabel]
  );

  useEffect(() => {
    onHighlight?.(highlighted);
  }, [highlighted]);

  const radius = Math.min(width, height) / 2 / 1.2;

  return (
    <RadialChart
      onValueMouseOver={(point) => setHighlightedLabel(point.label)}
      onValueMouseOut={() => setHighlightedLabel(undefined)}
      innerRadius={radius / 3}
      radius={radius}
      onValueClick={onValueClick}
      data={data.map((point, index) => ({
        ...point,
        radius: point.label === highlighted?.label ? 1.2 : 1,
        style: {
          cursor: onValueClick ? 'pointer' : undefined,
          fill: point.color ?? getColor(index),
          stroke: point.color ?? getColor(index),
          opacity: !highlighted
            ? 0.8
            : point.label === highlighted?.label
            ? 1
            : 0.3,
        },
      }))}
      showLabels={showLabels}
      labelsRadiusMultiplier={labelsRadiusMultiplier}
      width={width}
      height={height}
    />
  );
};
