import { AssetImageType } from "../enums";
import { ImageModel } from "../models";

export class ImageHelper {
  static getImageByType(images?: ImageModel[], imageType?: AssetImageType) {
    return images?.find((img) => img.AssetImageTypeCode === imageType);
  }

  static getHighlightsImage(images?: ImageModel[]) {
    let image = this.getImageByType(images, AssetImageType.Highlights);

    if (!image) {
      image = this.getImageByType(images, AssetImageType.Frame);
    }

    return image;
  }

  static getHighlightsImageUrl(images?: ImageModel[]) {
    let image = this.getHighlightsImage(images);
    if (image) {
      return image.Url;
    }
  }

  static getFrameImage(images?: ImageModel[]) {
    let image = this.getImageByType(images, AssetImageType.Frame);

    if (!image) {
      image = this.getImageByType(images, AssetImageType.Highlights);
    }

    return image;
  }

  static getFrameImageUrl(images?: ImageModel[]) {
    let image = this.getFrameImage(images);
    if (image) {
      return image.Url;
    }
  }
}
