import { useMemo } from "react";
import { useHistory, useLocation } from "react-router";
import { PAGE_PARAM_NAME } from "./useSearchParamsPagination";

type IFilterSchemaType =
  | "string"
  | "strings"
  | "number"
  | "numbers"
  | "boolean";

export interface IFiltersSchema {
  [key: string]: IFilterSchemaType;
}

export type ICurrentFilters<T extends IFiltersSchema> = {
  [P in keyof T]?: T[P] extends "string"
    ? string
    : T[P] extends "strings"
    ? string[]
    : T[P] extends "number"
    ? number
    : T[P] extends "numbers"
    ? number[]
    : T[P] extends "boolean"
    ? boolean
    : never;
};

export type ICurrentArrayFilters<T extends IFiltersSchema> = {
  [P in keyof T]?: T[P] extends "string" | "strings" | "number" | "numbers"
    ? string[] | null
    : T[P] extends "boolean"
    ? ("true" | "false")[] | null
    : never;
};

export type ICurrentOrArrrayFilters<T extends IFiltersSchema> = {
  [P in keyof T]?: ICurrentFilters<T>[P] | ICurrentArrayFilters<T>[P];
};

export type IFiltersUpdater<T extends IFiltersSchema> = (
  data: Partial<ICurrentFilters<T>>
) => ICurrentOrArrrayFilters<T>;

const parseParams = <T extends IFiltersSchema>(
  searchString: string,
  schema: T
) => {
  const searchParams = new URLSearchParams(searchString);
  const result: ICurrentFilters<T> = {};
  for (let key in schema) {
    const rawValues = searchParams.getAll(key);
    const rawValue = rawValues[0];
    let value;

    switch (schema[key]) {
      case "boolean":
        if (rawValue !== undefined) {
          if (rawValue === "true" || rawValue === "") {
            value = true;
          } else if (rawValues[0] === "false") {
            value = false;
          }
        }
        break;
      case "string":
        if (rawValue !== undefined) {
          value = rawValue;
        }
        break;
      case "strings":
        value = rawValues.length > 0 ? rawValues : undefined;
        break;
      case "number":
        if (rawValue !== undefined) {
          value = parseInt(rawValue);
          if (!Number.isSafeInteger(value)) {
            value === undefined;
          }
        }
        break;
      case "numbers":
        value = rawValues
          .map((raw) => parseInt(raw))
          .filter((parsed) => Number.isSafeInteger(parsed));
        if (value.length === 0) {
          value = undefined;
        }
        break;
    }
    result[key] = value as any;
  }
  return result;
};

const applyParams = <T extends IFiltersSchema>(
  schema: T,
  data: ICurrentOrArrrayFilters<T>,
  searchParamsInstance: URLSearchParams
) => {
  for (let key in schema) {
    searchParamsInstance.delete(key);
    const value = data[key];
    if (value === undefined || value === null) {
      continue;
    }
    switch (schema[key]) {
      case "boolean":
        if (Array.isArray(value)) {
          const hasTrue = (value as any[]).includes("true");
          const hasFalse = (value as any[]).includes("false");
          if ((hasTrue && !hasFalse) || (!hasTrue && hasFalse)) {
            searchParamsInstance.append(key, hasTrue ? "true" : "false");
          }
        } else {
          searchParamsInstance.append(key, value ? "true" : "false");
        }
        break;
      case "string":
      case "number":
        searchParamsInstance.append(
          key,
          `${Array.isArray(value) ? value[0] : value}`
        );
        break;
      case "strings":
      case "numbers":
        (value as string[]).forEach((item) =>
          searchParamsInstance.append(key, `${item}`)
        );
        break;
    }
  }
};

export const useSearchParamsFilters = <T extends IFiltersSchema>(schema: T) => {
  const location = useLocation();
  const history = useHistory();

  const current = parseParams(location.search, schema);

  return useMemo(() => {
    const update = (updater: IFiltersUpdater<T>) => {
      const data = parseParams(location.search, schema);
      const updatedData = updater(data);
      const searchParamsInstance = new URLSearchParams(location.search);
      applyParams(schema, updatedData, searchParamsInstance);
      const search = searchParamsInstance.toString();
      if (search !== location.search) {
        searchParamsInstance.delete(PAGE_PARAM_NAME);
        history.push({
          ...history.location,
          search: searchParamsInstance.toString(),
        });
      }
    };

    const clear = () => update(() => ({}));

    return {
      current,
      update,
      clear,
      get asTableArray(): ICurrentArrayFilters<IFiltersSchema> {
        let result: any = {};
        for (let key in schema) {
          const value = current[key];
          if (value === undefined || value === null) {
            result[key] = null;
          } else if (Array.isArray(current[key])) {
            result[key] = (current[key] as any[]).map((item: any) => `${item}`);
          } else {
            result[key] = [`${current[key]}`];
          }
        }
        return result as ICurrentArrayFilters<IFiltersSchema>;
      },
      get anyActive() {
        return Object.values(current).some((item) =>
          Array.isArray(item) ? item.length > 0 : item !== undefined
        );
      },
    };
  }, [JSON.stringify(current), history]);
};
