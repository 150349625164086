import {
  IProfileModel,
  IProfilesListModel,
  IProfilesSearchFilterModel,
} from "../models";
import { SimpleServiceBase } from "./Base";

export class ProfileService extends SimpleServiceBase<
  IProfileModel,
  IProfilesListModel,
  number,
  IProfilesSearchFilterModel
> {
  get url(): string {
    return "/Profiles";
  }
}
