import React from "react";
import {
  TestStatus,
  TestStatusHelper as CommonTestStatusHelper,
} from "@bms/common-services";
import { Tag, IChooseTagProps } from "@bms/common-ui";

export class TestStatusHelper extends CommonTestStatusHelper {
  static getTag(value: TestStatus | string): React.ReactElement {
    let color: string = TestStatusHelper.getColor(value as TestStatus);

    return (
      <Tag key={`${value}`} color={color}>
        {TestStatusHelper.getDescription(value as TestStatus)}
      </Tag>
    );
  }

  static getTagOption = (props: IChooseTagProps): React.ReactElement => {
    const { label, value, closable, onClose } = props;
    const status = TestStatusHelper.getValue(value as string);

    return (
      <Tag
        color={TestStatusHelper.getColor(status)}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  };
}
