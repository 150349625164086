import { ITestDeviceModel, TimeHelper } from "@bms/common-services";
import {
  Button,
  Icon,
  InputSearch,
  ITableColumnProps,
  ITableFilter,
  PageContent,
  PageHeader,
  Pagination,
  Popconfirm,
  setTableColumnSearchProps,
  Table,
  Tag,
  TAG_PALETTE,
  TagUpToDate,
  Tooltip,
} from "@bms/common-ui";
import { PlusOutlined, ReloadOutlined } from "@ant-design/icons";

import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { generateBreadcrumb, PlatformTypeHelper } from "../../../../helpers";
import { ROUTES } from "../../constants";
import { FilterCleanIcon } from "../../../../resources/icons";

import { useTestDevices } from "./useTestDevices";
import { FormModal } from "../../../../components";
import { TestingDeviceForm } from "./components";
import { useTestingPlatforms } from "../../hooks";

const defaultDevice: ITestDeviceModel = {
  Id: -1,
  Name: "",
  Description: "",
  UpToDate: true,
};

export const TestingDevicesList = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [selected, setSelected] = useState<ITestDeviceModel>(defaultDevice);

  const { t } = useTranslation();
  const { platforms } = useTestingPlatforms();

  const {
    isLoading,
    data,
    pagination,
    fullTextSearch,
    setFullTextSearch,
    filters,
    refreshDevices,
    createDevice,
    updateDevice,
    deleteDevice,
  } = useTestDevices();

  const isNewDevice = selected.Id < 0;

  const showAddModal = () => {
    setSelected({ ...defaultDevice });
    setModalVisible(true);
  };

  const onSearch = (value: string) =>
    filters.update((currentFilters) => ({
      ...currentFilters,
      FullTextSearch: value || undefined,
    }));

  const onTableChange = (_: any, incomingFilters: ITableFilter) =>
    filters.update((currentFilters) => ({
      ...currentFilters,
      ...incomingFilters,
    }));

  const onDeleteDevice = async (device: ITestDeviceModel) => {
    await deleteDevice(device);
  };

  const handleDeleteDevice = async () => {
    await deleteDevice(selected);
    setModalVisible(false);
  };

  const getBreadcrumbProps = () => {
    return generateBreadcrumb([
      {
        path: ROUTES.DEVICES,
        breadcrumbName: t("TESTING_DEVICES"),
      },
    ]);
  };

  const getExtraButtons = () => {
    return (
      <>
        <InputSearch
          key="search"
          placeholder={t("SEARCH_PLACEHOLDER")}
          value={fullTextSearch}
          onSearch={onSearch}
          onChange={({ target: { value } }) => setFullTextSearch(value)}
          style={{ width: 250 }}
          allowClear
        />
        <Tooltip key="filters-tooltip" overlay={t("MENU_OPTION_CLEAR_FILTERS")}>
          <Button
            key="clear-filters"
            shape="circle"
            icon={<FilterCleanIcon />}
            onClick={filters.clear}
            disabled={!filters.anyActive}
            title={t("MENU_OPTION_CLEAR_FILTERS")}
          />
        </Tooltip>
        <Tooltip key="reload-tooltip" overlay={t("BUTTON_REFRESH_TITLE")}>
          <Button
            key="reload"
            shape="circle"
            icon={<ReloadOutlined />}
            onClick={refreshDevices}
            title={t("BUTTON_REFRESH_TITLE")}
          />
        </Tooltip>
        <Tooltip key="add-tooltip" overlay={t("TESTING_DEVICES_ADD_BUTTON")}>
          <Button
            key="add"
            shape="circle"
            type="primary"
            icon={<PlusOutlined />}
            title={t("TESTING_DEVICES_ADD_BUTTON")}
            onClick={showAddModal}
          />
        </Tooltip>
      </>
    );
  };

  const getColumnsProps = (): Array<ITableColumnProps<ITestDeviceModel>> => {
    return [
      {
        key: "Name",
        dataIndex: "Name",
        title: t("MODEL_NAME"),
        filteredValue: filters.asTableArray.Name,
        ...setTableColumnSearchProps("Name", t("MODEL_NAME")),
        render: (_, device) => {
          return (
            <a
              title={device.Name}
              onClick={() => {
                setModalVisible(true);
                setSelected(device);
              }}
            >
              {device.Name}
            </a>
          );
        },
      },
      {
        key: "Description",
        dataIndex: "Description",
        title: t("MODEL_DESCRIPTION"),
        filteredValue: filters.asTableArray.Description,
        ...setTableColumnSearchProps("Description", t("MODEL_DESCRIPTION")),
      },
      {
        key: "OSVersion",
        dataIndex: "OSVersion",
        title: t("TESTING_DEVICES_OS_VERSION"),
        align: "center",
        width: "120px",
        render: (osVersion: string) =>
          osVersion && <Tag color={TAG_PALETTE.GREY}>{osVersion}</Tag>,
      },
      {
        key: "IP",
        dataIndex: "IP",
        title: t("TESTING_DEVICES_IP"),
        width: "150px",
      },
      {
        key: "Platforms",
        dataIndex: "Platforms",
        title: t("MODEL_PLATFORM"),
        filters: platforms.map((platform) => ({
          text: platform.DisplayName,
          value: platform.Code,
        })),
        filteredValue: filters.asTableArray.Platforms,
        render: (_, device) =>
          PlatformTypeHelper.getTag(device.PlatformCode as string),
      },
      {
        key: "Created",
        dataIndex: "Created",
        title: t("MODEL_CREATION_DATE"),
        align: "center",
        width: "140px",
        render: (creationDate: Date) => TimeHelper.format(creationDate),
      },
      {
        key: "UpToDate",
        dataIndex: "UpToDate",
        title: t("MODEL_UP_TO_DATE"),
        align: "center",
        filters: [
          { text: t("CLASS_LIST_TABLE_UPTODATE_COLUMN_FILTER"), value: "true" },
          {
            text: t("CLASS_LIST_TABLE_OUTTODATE_COLUMN_FILTER"),
            value: "false",
          },
        ],
        filteredValue: filters.asTableArray.UpToDate,
        render: (upToDate: boolean) => <TagUpToDate value={upToDate} />,
      },
      {
        key: "Actions",
        dataIndex: "Actions",
        title: t("TABLE_ACTIONS_COLUMN"),
        align: "center",
        width: "100px",
        render: (_: any, device: ITestDeviceModel) => (
          <Popconfirm
            title={t("DELETE_ELEMENT_DOUBLE_CONFIRMATION_QUESTION")}
            onConfirm={async (event?: React.MouseEvent<HTMLElement>) => {
              event?.preventDefault();
              await onDeleteDevice(device);
            }}
            okText={t("BUTTON_YES")}
            cancelText={t("BUTTON_NO")}
          >
            <Button
              danger={true}
              icon={<Icon type="delete" />}
              title={t("DELETE_ELEMENT")}
            />
          </Popconfirm>
        ),
      },
    ];
  };

  return (
    <>
      <PageContent footer={<Pagination {...pagination.props} />}>
        <PageHeader
          title={t("TESTING_DEVICES")}
          breadcrumb={getBreadcrumbProps()}
          extra={getExtraButtons()}
        />
        <Table<ITestDeviceModel>
          rowKey="Id"
          columns={getColumnsProps()}
          dataSource={data?.Entities}
          loading={isLoading}
          pagination={false}
          onChange={onTableChange}
        />
      </PageContent>
      <FormModal
        isLoading={isLoading}
        isVisible={modalVisible}
        isNewForm={isNewDevice}
        createFormTitle={t("TESTING_DEVICES_ADD_HEADER")}
        editFormTitle={t("TESTING_DEVICES_EDIT_HEADER")}
        modalClassName="TestingDeviceModal"
        submitFormName="TestingDeviceForm"
        onCloseModal={() => setModalVisible(false)}
        onDeleteButtonClick={handleDeleteDevice}
        isDeleteButtonEnabled
      >
        <TestingDeviceForm
          device={selected}
          setVisibilityStatus={setModalVisible}
          createDevice={createDevice}
          updateDevice={updateDevice}
          isLoading={isLoading}
        />
      </FormModal>
    </>
  );
};
