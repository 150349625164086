export function fileToBase64(file: File | Blob) {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
}

export const blobToFile = (
  theBlob: Blob,
  fileName: string,
  type?: string
): File => {
  return new File([theBlob], fileName, { type });
};
