import React from "react";

export function Users() {
  return <svg
    width="1em"
    height="1em"
    viewBox="0 0 21 21"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10.5 3.12933C11.9017 3.12933 13.0375 4.26516 13.0375 5.66683C13.0375 7.0685 11.9017 8.20433 10.5 8.20433C9.09837 8.20433 7.96254 7.0685 7.96254 5.66683C7.96254 4.26516 9.09837 3.12933 10.5 3.12933ZM10.5 14.0043C14.0888 14.0043 17.8709 15.7685 17.8709 16.5418V17.871H3.12921V16.5418C3.12921 15.7685 6.91129 14.0043 10.5 14.0043ZM10.5 0.833496C7.82962 0.833496 5.66671 2.99641 5.66671 5.66683C5.66671 8.33725 7.82962 10.5002 10.5 10.5002C13.1705 10.5002 15.3334 8.33725 15.3334 5.66683C15.3334 2.99641 13.1705 0.833496 10.5 0.833496ZM10.5 11.7085C7.27379 11.7085 0.833374 13.3277 0.833374 16.5418V20.1668H20.1667V16.5418C20.1667 13.3277 13.7263 11.7085 10.5 11.7085Z" />
  </svg>
}
