import { Menu as AntMenu } from "antd";
import { MenuProps as AntMenuProps } from "antd/lib/menu";
import React from "react";
import { MenuItemClickEvent } from "./MenuItem";

export interface MenuProps extends AntMenuProps {
  onClick?: (param: MenuItemClickEvent) => void;
}

export class Menu extends React.PureComponent<MenuProps> {
  public render() {
    const { children, ...rest } = this.props;
    return <AntMenu {...rest}>{children}</AntMenu>;
  }
}
