import React, { useMemo } from "react";
import cx from "classnames";
import { Col } from "../Col";
import { Row } from "../Row";
import "./CrosshairContent.scss";

interface ICrosshairContentProps {
  values: { x: any; y: any }[];
  seriesNames?: string[];
  highlighted?: number;
  xDate?: boolean;
}

export const CrosshairContent: React.FC<ICrosshairContentProps> = ({
  seriesNames,
  values,
  highlighted,
  xDate,
}) => {
  const reversedSeriesNames = useMemo(
    () => seriesNames && [...seriesNames].reverse(),
    [seriesNames]
  );
  const reversedValues = useMemo(() => [...values].reverse(), [values]);
  const reversedHighlighted =
    typeof highlighted === "undefined"
      ? undefined
      : reversedValues.length - 1 - highlighted;

  return (
    <div className="crosshair-content">
      <h3 className="crosshair-header">
        {xDate
          ? new Date(reversedValues[0]?.x).toLocaleDateString()
          : reversedValues[0]?.x}
      </h3>
      {reversedValues.map(({ y }, index) => (
        <Row
          key={y}
          gutter={[16, 4]}
          justify="space-between"
          className={cx(
            "crosshair-content-item",
            reversedHighlighted === index &&
              "crosshair-content-item-highlighted"
          )}
        >
          <Col>{reversedSeriesNames?.[index] ?? index}</Col>
          <Col>{y}</Col>
        </Row>
      ))}
    </div>
  );
};
