import React from "react";
import { Checkbox as AntCheckbox } from "antd";
import { CheckboxGroupProps as AntCheckboxGroupProps } from "antd/lib/checkbox";

const AntCheckboxGroup = AntCheckbox.Group;

export interface ICheckboxGroupProps extends AntCheckboxGroupProps {}

export const CheckboxGroup: React.FC<ICheckboxGroupProps> = (props) => {
  return <AntCheckboxGroup {...props} />;
};
