import React from "react";
import { useTranslation } from "react-i18next";
import {
  Dropdown,
  Icon,
  Menu,
  MenuItem,
  MenuItemClickEvent,
} from "@bms/common-ui";
import { IconEN, IconPL } from "../../resources/languages";

import "./LanguageSelect.scss";

export function LanguageSelect() {
  const { i18n } = useTranslation();

  const onValueChange = (param: MenuItemClickEvent) => {
    i18n.changeLanguage(`${param.key}`);
  };

  return (
    <Dropdown
      key="language"
      overlay={
        <Menu onClick={onValueChange}>
          <MenuItem
            key="en"
            className="LanguageSelect__MenuItem"
            icon={<IconEN />}
          >
            English
          </MenuItem>
          <MenuItem
            key="pl"
            className="LanguageSelect__MenuItem"
            icon={<IconPL />}
          >
            Polski
          </MenuItem>
        </Menu>
      }
    >
      <div className="LanguageSelect__CurrentLang">
        <Icon type="translation" />
        <span>{i18n.language}</span>
      </div>
    </Dropdown>
  );
}
