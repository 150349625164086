import { ICommonAppState } from "@bms/common-services";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
  ApplicationIntroProperties as ApplicationIntroPropertiesDefinition,
  ApplicationIntroPropertiesDispatchProps,
  ApplicationIntroPropertiesOwnProps,
  ApplicationIntroPropertiesStateProps,
} from "./ApplicationIntroProperties";

const mapStateToProps = (
  state: ICommonAppState
): ApplicationIntroPropertiesStateProps => {
  return {
    isProcessingData: state.applicationConfiguration.isProcessingData,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch
): ApplicationIntroPropertiesDispatchProps => ({});

export const ApplicationIntroProperties = connect<
  ApplicationIntroPropertiesStateProps,
  ApplicationIntroPropertiesDispatchProps,
  ApplicationIntroPropertiesOwnProps,
  ICommonAppState
>(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ApplicationIntroPropertiesDefinition));
