import { ICommonAppState } from "@bms/common-services";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
  ApplicationHeaderItemProperties as ApplicationHeaderItemPropertiesDefinition,
  IApplicationHeaderItemPropertiesDispatchProps,
  IApplicationHeaderItemPropertiesOwnProps,
  IApplicationHeaderItemPropertiesStateProps,
} from "./ApplicationHeaderItemProperties";

const mapStateToProps = (
  state: ICommonAppState
): IApplicationHeaderItemPropertiesStateProps => {
  return {
    isProcessingData: state.applicationConfiguration.isProcessingData,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch
): IApplicationHeaderItemPropertiesDispatchProps => ({});

export const ApplicationHeaderItemProperties = connect<
  IApplicationHeaderItemPropertiesStateProps,
  IApplicationHeaderItemPropertiesDispatchProps,
  IApplicationHeaderItemPropertiesOwnProps,
  ICommonAppState
>(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ApplicationHeaderItemPropertiesDefinition));
