/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  IAssetCollectionListModel,
  IAssetCollectionModel,
  IPlaylistSourceListModel,
  IPlaylistSourceModel,
} from "../../../../models";
import { PlayListSourceType } from "../../../../modules/configuration/enums";

export class ModelsMapperHelper {
  static toPlaylistSourceModel(
    entity: IAssetCollectionModel
  ): IPlaylistSourceModel {
    let result: IPlaylistSourceModel = {
      Id: entity.Id,
      Name: entity.Name,
      Description: entity.Description,
    };

    if (entity.FilterRecentlyWatched && !entity.FilterMyFavourites) {
      result.Type = PlayListSourceType.RecentlyWatched;
    } else if (!entity.FilterRecentlyWatched && entity.FilterMyFavourites) {
      result.Type = PlayListSourceType.MyFavorites;
    } else if (
      !entity.FilterRecentlyWatched &&
      !entity.FilterMyFavourites &&
      entity.FilterPurchased
    ) {
      result.Type = PlayListSourceType.Purchased;
    } else {
      result.Type = PlayListSourceType.Standard;
    }

    return result;
  }

  static toPlaylistSourceListModel(
    entity: IAssetCollectionListModel
  ): IPlaylistSourceListModel {
    let result: IPlaylistSourceListModel = {
      Entities: entity.Entities
        ? entity.Entities.map((entity) =>
            ModelsMapperHelper.toPlaylistSourceModel(entity)
          )
        : [],
      TotalCount: entity.TotalCount,
      Error: entity.Error,
      Filter: entity.Filter,
      IsLoading: entity.IsLoading,
      PageNumber: entity.PageNumber,
      PageSize: entity.PageSize,
    };

    return result;
  }
}
