import React from "react";
import { Input as AntInput } from "antd";
import { GroupProps as AntGroupProps } from "antd/lib/input";

export interface InputGroupProps extends AntGroupProps {}

export class InputGroup extends React.PureComponent<InputGroupProps> {
  public render() {
    return <AntInput.Group {...this.props} />;
  }
}
