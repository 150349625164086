import { cloneDeep } from "lodash";
import { IMediaListModel, IMediaModel } from "..";
import { TimeHelper } from "../../..";
import { SourceType } from "../../configuration";
import * as Consts from "./consts";
import { IMediaState, MediaActionsTypes } from "./types";

export const initialState: IMediaState = {
  action: undefined,
  media: {},
  mediaPlayInfo: {},
  mediaList: {},
  mediaCategories: {
    Entities: [],
    TotalCount: 0,
  },
  mediaPurchaseOffers: {},
  mediaSearch: {
      Entities: [],
      TotalCount: 0
  }
};

export const mediaReducer = (
  state = initialState,
  action: MediaActionsTypes
): IMediaState => {
  state.action = action;

  switch (action.type) {
    case Consts.GET_MEDIA: {
      return {
        ...state,
        media: {
          ...state.media,
          [action.options.MediaId]: {
            Media: {
              ...state.media[action.options.MediaId]?.Media,
              Id: action.options.MediaId,
            },
            IsLoading: true,
          },
        },
      };
    }
    case Consts.GET_MEDIA_SUCCESS: {
      return {
        ...state,
        media: {
          ...state.media,
          [action.payload.Id]: {
            ...state.media[action.payload.Id],
            Media: action.payload,
            Error: undefined,
            IsLoading: false,
          },
        },
      };
    }
    case Consts.GET_MEDIA_FAILURE: {
      return {
        ...state,
        media: {
          ...state.media,
          [action.mediaId]: {
            ...state.media[action.mediaId],
            Media: undefined,
            Error: action.error,
            IsLoading: false,
          },
        },
      };
    }
    case Consts.GET_MEDIA_PLAY_INFO: {
      return {
        ...state,
        mediaPlayInfo: {
          ...state.mediaPlayInfo,
          [action.options.MediaId]: {
            Data: undefined,
            IsProcessing: true,
          },
        },
      };
    }
    case Consts.GET_MEDIA_PLAY_INFO_SUCCESS: {
      return {
        ...state,
        mediaPlayInfo: {
          ...state.mediaPlayInfo,
          [action.options.MediaId]: {
            ...state.mediaPlayInfo[action.options.MediaId],
            Data: action.payload,
            Error: undefined,
            IsProcessing: false,
          },
        },
      };
    }
    case Consts.GET_MEDIA_PLAY_INFO_FAILURE: {
      return {
        ...state,
        mediaPlayInfo: {
          ...state.mediaPlayInfo,
          [action.options.MediaId]: {
            ...state.mediaPlayInfo[action.options.MediaId],
            Data: undefined,
            Error: action.error,
            IsProcessing: false,
          },
        },
      };
    }
    case Consts.SEARCH_MEDIA: {
      let mediaList = state.mediaList[Consts.MEDIA_LIST_SEARCH_KEY];

      if (!mediaList) {
        mediaList = {
          Entities: [],
          TotalCount: 0,
          SourceType: SourceType.MediaList,
        };
      }

      return {
        ...state,
        mediaList: {
          ...state.mediaList,
          [Consts.MEDIA_LIST_SEARCH_KEY]: {
            ...mediaList,
            Filter: action.filter,
            IsLoading: true,
          },
        },
      };
    }
    case Consts.SEARCH_MEDIA_SUCCESS: {
      const pageNumber = action.payload?.Filter?.PageNumber;
      let entities: IMediaModel[] = [];

      if (pageNumber === 1) {
        entities = action.payload.Entities;
      } else if (pageNumber && pageNumber > 1) {
        entities = [
          ...(state.mediaList[Consts.MEDIA_LIST_SEARCH_KEY]?.Entities || []),
          ...action.payload.Entities,
        ];
      }

      return {
        ...state,
        mediaList: {
          ...state.mediaList,
          [Consts.MEDIA_LIST_SEARCH_KEY]: {
            ...state.media[Consts.MEDIA_LIST_SEARCH_KEY],
            SourceType: SourceType.MediaList,
            Filter: action.payload.Filter,
            TotalCount: action.payload.TotalCount,
            Entities: entities,
            Error: undefined,
            IsLoading: false,
          },
        },
      };
    }
    case Consts.SEARCH_MEDIA_FAILURE: {
      return {
        ...state,
        mediaList: {
          ...state.mediaList,
          [Consts.MEDIA_LIST_SEARCH_KEY]: {
            ...state.media[Consts.MEDIA_LIST_SEARCH_KEY],
            SourceType: SourceType.MediaList,
            Entities: [],
            TotalCount: 0,
            Error: action.error,
            IsLoading: false,
          },
        },
      };
    }
    case Consts.SEARCH_MEDIA_IN_MEDIA: {
      if (!action.filter.MediaParentId) {
        return state;
      }

      let mediaList = state.mediaList[action.filter.MediaParentId];

      if (!mediaList) {
        mediaList = {
          Entities: [],
          TotalCount: 0,
          SourceType: SourceType.MediaList,
        };
      }

      return {
        ...state,
        mediaList: {
          ...state.mediaList,
          [action.filter.MediaParentId]: {
            ...mediaList,
            Filter: action.filter,
            IsLoading: true,
          },
        },
      };
    }
    case Consts.SEARCH_MEDIA_IN_MEDIA_SUCCESS: {
      const mediaListId = action.filter?.MediaParentId;
      const pageNumber = action.payload?.Filter?.PageNumber;
      let entities: IMediaModel[] = [];

      if (!mediaListId) {
        return state;
      }

      if (pageNumber === 1) {
        entities = action.payload.Entities;
      } else if (pageNumber && pageNumber > 1) {
        entities = [
          ...(state.mediaList[mediaListId]?.Entities || []),
          ...action.payload.Entities,
        ];
      }

      return {
        ...state,
        mediaList: {
          ...state.mediaList,
          [mediaListId]: {
            ...state.mediaList[mediaListId],
            ...action.payload,
            Entities: entities,
            Error: undefined,
            IsLoading: false,
          },
        },
      };
    }
    case Consts.SEARCH_MEDIA_IN_MEDIA_FAILURE: {
      const mediaListId = action.filter?.MediaParentId;

      if (!mediaListId) {
        return state;
      }

      return {
        ...state,
        mediaList: {
          ...state.mediaList,
          [mediaListId]: {
            ...state.media[mediaListId],
            SourceType: SourceType.MediaList,
            Entities: [],
            TotalCount: 0,
            Error: action.error,
            IsLoading: false,
          },
        },
      };
    }
    case Consts.GET_MEDIA_LIST:
    case Consts.GET_MEDIA_LIST_FROM_CACHE: {
      let mediaList = state.mediaList[action.options.MediaListId];

      if (!mediaList) {
        mediaList = {
          Entities: [],
          TotalCount: 0,
          SourceType: SourceType.MediaList,
        };
      }

      return {
        ...state,
        mediaList: {
          ...state.mediaList,
          [action.options.MediaListId]: {
            ...mediaList,
            Filter: action.options,
            IsLoading: true,
          },
        },
      };
    }
    case Consts.GET_MEDIA_LIST_SUCCESS:
    case Consts.GET_MEDIA_LIST_FROM_CACHE_SUCCESS: {
      // const pageNumber = action.payload?.Filter?.PageNumber;
      // let entities: IMediaModel[] = [];
      //
      // if (pageNumber === 1) {
      //   entities = action.payload.Entities;
      // } else if (pageNumber && pageNumber > 1) {
      //   entities = [
      //     ...(state.mediaList[action.mediaListId]?.Entities || []),
      //     ...action.payload.Entities,
      //   ];
      // }

      return {
        ...state,
        mediaList: {
          ...state.mediaList,
          [action.mediaListId]: {
            ...state.media[action.mediaListId],
            ...action.payload,
            Error: undefined,
            IsLoading: false,
          },
        },
      };
    }
    case Consts.GET_MEDIA_LIST_FAILURE:
    case Consts.GET_MEDIA_LIST_FROM_CACHE_FAILURE: {
      return {
        ...state,
        mediaList: {
          ...state.mediaList,
          [action.mediaListId]: {
            ...state.media[action.mediaListId],
            Entities: [],
            TotalCount: 0,
            Error: action.error,
            IsLoading: false,
          },
        },
      };
    }
    case Consts.GET_MEDIA_LIST_FOR_EPG: {
      const mediaList = cloneDeep(state.mediaList);
      const source: IMediaListModel = mediaList[action.options.MediaListId]
        ? mediaList[action.options.MediaListId]
        : {
            SourceType: SourceType.MediaList,
            TotalCount: 0,
            Entities: [],
            IsLoading: false,
            Sources: {},
          };

      if (!source.Sources) {
        source.Sources = {};
      }

      const dateKey = action.options?.MediaOptions?.DateFrom
        ? TimeHelper.format(action.options.MediaOptions.DateFrom, "YYYY-MM-DD")
        : TimeHelper.getCurrentDateKey();

      source.Sources[dateKey] = {
        ...source.Sources[dateKey],
        IsLoading: true,
      };

      mediaList[action.options.MediaListId] = source;

      return {
        ...state,
        mediaList,
      };
    }
    case Consts.GET_MEDIA_LIST_FOR_EPG_SUCCESS: {
      const mediaList = cloneDeep(state.mediaList);
      const source: IMediaListModel = mediaList[action.mediaListId]
        ? mediaList[action.mediaListId]
        : {
            SourceType: SourceType.MediaList,
            TotalCount: 0,
            Entities: [],
            IsLoading: false,
            Sources: {},
          };

      if (!source.Sources) {
        source.Sources = {};
      }

      const dateKey = action.options?.MediaOptions?.DateFrom
        ? TimeHelper.format(action.options.MediaOptions.DateFrom, "YYYY-MM-DD")
        : TimeHelper.getCurrentDateKey();

      if (!source.Sources[dateKey]) {
        source.Sources[dateKey] = {
          SourceType: SourceType.MediaList,
          TotalCount: 0,
          Entities: [],
          IsLoading: false,
        };
      } else {
        source.Sources[dateKey] = {
          ...action.payload,
          IsLoading: false,
        };
      }

      mediaList[action.mediaListId] = source;

      return {
        ...state,
        mediaList,
      };
    }
    case Consts.GET_MEDIA_LIST_FOR_EPG_FAILURE: {
      const mediaList = cloneDeep(state.mediaList);
      const source = mediaList[action.mediaListId]
        ? mediaList[action.mediaListId]
        : {
            SourceType: SourceType.MediaList,
            TotalCount: 0,
            Entities: [],
            IsLoading: false,
            Sources: {},
          };

      if (!source.Sources) {
        source.Sources = {};
      }

      const dateKey = action.options?.MediaOptions?.DateFrom
        ? TimeHelper.format(action.options.MediaOptions.DateFrom, "YYYY-MM-DD")
        : TimeHelper.getCurrentDateKey();

      if (!source.Sources[dateKey]) {
        source.Sources[dateKey] = {
          SourceType: SourceType.MediaList,
          TotalCount: 0,
          Entities: [],
          IsLoading: false,
          Error: action.error,
        };
      } else {
        source.Sources[dateKey] = {
          ...source.Sources[dateKey],
          IsLoading: false,
          Error: action.error,
        };
      }

      mediaList[action.mediaListId] = source;

      return {
        ...state,
        mediaList,
      };
    }
    case Consts.GET_MEDIA_CATEGORIES: {
      return {
        ...state,
        mediaCategories: {
          ...state.mediaCategories,
          IsLoading: true,
          Error: undefined,
        },
      };
    }
    case Consts.GET_MEDIA_CATEGORIES_SUCCESS: {
      return {
        ...state,
        mediaCategories: {
          ...action.payload,
          IsLoading: false,
        },
      };
    }
    case Consts.GET_MEDIA_CATEGORIES_FAILURE: {
      return {
        ...state,
        mediaCategories: {
          ...state.mediaCategories,
          IsLoading: false,
          Error: action.error,
        },
      };
    }
    case Consts.SELECT_MEDIA_PURCHASE_OFFERS: {
      return {
        ...state,
        mediaPurchaseOffers: {
          ...state.mediaPurchaseOffers,
          [action.mediaId]: {
            ...(state.mediaPurchaseOffers[action.mediaId] || []),
            IsProcessing: true,
            Error: undefined,
          },
        },
      };
    }
    case Consts.SELECT_MEDIA_PURCHASE_OFFERS_SUCCESS: {
      return {
        ...state,
        mediaPurchaseOffers: {
          ...state.mediaPurchaseOffers,
          [action.mediaId]: {
            ...(state.mediaPurchaseOffers[action.mediaId] || []),
            Data: action.payload,
            IsProcessing: false,
            Error: undefined,
          },
        },
      };
    }
    case Consts.SELECT_MEDIA_PURCHASE_OFFERS_FAILURE: {
      return {
        ...state,
        mediaPurchaseOffers: {
          ...state.mediaPurchaseOffers,
          [action.mediaId]: {
            ...(state.mediaPurchaseOffers[action.mediaId] || []),
            IsProcessing: false,
            Error: action.error,
          },
        },
      };
    }
    default:
      return state;
  }
};
