import queryString, { StringifyOptions } from "query-string";

const urlRegex = /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;

export interface IQueryParams {
  [key: string]: any;
}
type Query = string | IQueryParams;

export const stringify = (
  queryParams: IQueryParams,
  options?: StringifyOptions
) => {
  return queryString.stringify(queryParams, options);
};

export const parse = (query: string, options?: StringifyOptions) => {
  return queryString.parse(query);
};

export const parametrize = (
  url: string,
  queryParams?: IQueryParams,
  options?: StringifyOptions
) => {
  if (!queryParams) {
    return url;
  }

  return `${url}?${stringify(queryParams, options)}`;
};

/**
 * Converts query strings and query params into a single query string.
 *
 * @returns {string} Returns the joined query string.
 * @example
 *
 * joinQueries('?a=foo&b=bar', ?c=foo);
 * // => '?a=foo&b=bar&c=foo'
 * joinQueries('?a=foo&b=bar', { c: foo });
 * // => '?a=foo&b=bar&c=foo'
 */
export const joinQueries = (...queries: Query[]): string => {
  const query = queries.map((q: Query) => {
    if (typeof q === "string") {
      return parse(q);
    }
    return q;
  });
  // get rid of duplicated keys
  const queryParams = Object.assign({}, ...query);

  return parametrize("", queryParams);
};

export const isUrl = (path: string): boolean => urlRegex.test(path);
