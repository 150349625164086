import {
  AuthStore,
  IAuthState,
  IErrorModel,
  IFieldErrorModel,
} from "@bms/common-services";
import { Button, Input, Form, IFormValues, useValidate } from "@bms/common-ui";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../../store";

function EmailField() {
  const [t] = useTranslation();
  const validate = useValidate();

  return (
    <Form.Item
      name="Email"
      label={t("EMAIL_LABEL")}
      rules={[
        ...validate.required(t("LOGIN_USERNAME_VALIDATION_REQUIRED_MESSAGE")),
        ...validate.email(),
      ]}
    >
      <Input
        autoFocus
        className="form-field"
        placeholder={t("EMAIL_PLACEHOLDER")}
      />
    </Form.Item>
  );
}

interface IForgotPasswordFormValues {
  Email: string;
}

export interface IForgotPasswordFormProps {
  onSubmit: (values: IForgotPasswordFormValues) => void;
  error?: IErrorModel;
  isFetching?: boolean;
}

export function ForgotPasswordForm() {
  const [t] = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const authState = useSelector<IAppState, IAuthState>((state) => state.auth);
  useEffect(() => {
    form.setFields([
      {
        name: "Email",
        errors: (authState.error as IFieldErrorModel)?.Email || undefined,
      },
    ]);
  }, [authState.error, form]);

  const onFinish = useCallback(
    (values: IFormValues) => {
      dispatch(AuthStore.Actions.forgotPassword({ Email: values.Email }));
    },
    [dispatch]
  );

  return (
    <Form
      form={form}
      name="ForgotPasswordForm"
      onFinish={onFinish}
      labelCol={{ span: 7 }}
    >
      <EmailField />
      <Form.Item style={{ textAlign: "right" }}>
        <Button
          loading={authState.isFetching}
          type="primary"
          htmlType="submit"
          block={false}
        >
          {t("FORGOT_PASSWORD_RESET_PASSWORD_BUTTON")}
        </Button>
      </Form.Item>
    </Form>
  );
}
