import React, { createContext, useContext } from "react";

import { App as AntApp, Modal } from "antd";

import type { MessageInstance } from "antd/es/message/interface";
import type { ModalStaticFunctions } from "antd/es/modal/confirm";
import type { NotificationInstance } from "antd/es/notification/interface";

type AppFeedbackContextValue = {
  message: MessageInstance;
  modal: Omit<ModalStaticFunctions, "warn">;
  notification: NotificationInstance;
  destroyAllModals: () => void;
};

interface AppFeedbackProviderProps {
  children: React.ReactNode;
}

export const AppFeedbackContext = createContext<AppFeedbackContextValue>(
  {} as AppFeedbackContextValue
);

export const AppFeedbackProviderInnerWrapper = (
  props: AppFeedbackProviderProps
) => {
  const { children } = props;

  const { modal, message, notification } = AntApp.useApp();

  const destroyAllModals = () => Modal.destroyAll();

  return (
    <AppFeedbackContext.Provider
      value={{ message, modal, notification, destroyAllModals }}
    >
      {children}
    </AppFeedbackContext.Provider>
  );
};

export const AppFeedbackProvider = (props: AppFeedbackProviderProps) => {
  const { children } = props;

  return (
    <AntApp
      notification={{
        placement: "bottomLeft",
        bottom: 8,
        stack: { threshold: 5 },
      }}
    >
      <AppFeedbackProviderInnerWrapper>
        {children}
      </AppFeedbackProviderInnerWrapper>
    </AntApp>
  );
};

export const useAppFeedback = (): AppFeedbackContextValue => {
  const context = useContext(AppFeedbackContext);

  if (!context) {
    throw new Error("Component beyond AppFeedbackContext");
  }

  return context;
};
