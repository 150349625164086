import { ITestApplicationVersionModel } from "@bms/common-services";
import {
  Col,
  Form,
  IFormValues,
  Input,
  required,
  Row,
  Spin,
  Switch,
} from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import React from "react";

interface TestApplicationVersionEditFormProps {
  applicationVersion: ITestApplicationVersionModel;
  isLoading: boolean;
  setVisibilityStatus: (visible: boolean) => void;
  updateApplicationVersion: (
    data: ITestApplicationVersionModel
  ) => Promise<boolean>;
}

export const TestApplicationVersionEditForm = (
  props: TestApplicationVersionEditFormProps
) => {
  const {
    applicationVersion,
    isLoading,
    setVisibilityStatus,
    updateApplicationVersion,
  } = props;

  const [form] = Form.useForm();
  const { t } = useTranslation();

  const onFinish = async (values: IFormValues) => {
    await updateApplicationVersion({ ...applicationVersion, ...values });
    setVisibilityStatus(false);
  };

  const renderVersionInput = () => {
    return (
      <Form.Item
        name="Version"
        label={t("TESTING_APPLICATIONS_VERSIONS_VERSION")}
        rules={[required()]}
      >
        <Input
          placeholder={t("TESTING_APPLICATIONS_VERSIONS_VERSION")}
          disabled
        />
      </Form.Item>
    );
  };

  const renderUrlInput = () => {
    return (
      <Form.Item
        name="Url"
        label={t("TESTING_APPLICATIONS_VERSIONS_URL")}
        rules={[required()]}
      >
        <Input placeholder={t("TESTING_APPLICATIONS_VERSIONS_URL")} disabled />
      </Form.Item>
    );
  };

  const renderUpToDate = () => {
    return (
      <Form.Item>
        <Row gutter={8}>
          <Col>
            <Form.Item name="UpToDate" valuePropName="checked">
              <Switch />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="UpToDate">
              <label>{t("MODEL_UP_TO_DATE")}</label>
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>
    );
  };

  return (
    <Spin spinning={isLoading}>
      <Form
        name="TestApplicationVersionEditForm"
        form={form}
        initialValues={applicationVersion}
        onFinish={onFinish}
        layout="vertical"
      >
        <Row direction="column" justify="space-between" className="full-height">
          <Col>
            {renderVersionInput()}
            {renderUrlInput()}
            {renderUpToDate()}
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};
