/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { Observable, Observer } from "rxjs";
import { HTTP_METHOD } from "../../constants/http";
import {
  IAnalyticsUserPlatformRatingFilterModel,
  IAnalyticsUserPlatformRatingModel,
  IUserPlatformRatingAnalyticsSummaryFilterModel,
  IUserPlatformRatingAnalyticsSummaryModel,
} from "../../models";
import { AxiosSubscriber } from "../AxiosSubscriber";
import { PromisifiableBase } from "../Base";
import { IUserPlatformRatingsSearchFilterModel } from "../../models/Rating/IUserPlatformRatingsSearchFilterModel";
import { IUserPlatformRatingListModel } from "../../models/Rating/IUserPlatformRatingsListModel";

export class UserPlatformRatingsService extends PromisifiableBase {
  get url(): string {
    return "/UserPlatformRatings";
  }

  public selectAnalyticsSummary = (
    filter?: IUserPlatformRatingAnalyticsSummaryFilterModel
  ): Observable<IUserPlatformRatingAnalyticsSummaryModel[]> =>
    new Observable(
      (observer: Observer<IUserPlatformRatingAnalyticsSummaryModel[]>) =>
        new AxiosSubscriber(observer, {
          data: filter || {},
          method: HTTP_METHOD.POST,
          url: `${this.url}/SelectAnalyticsSummary`,
        })
    );

  public selectAnalytics = (
    filter?: IAnalyticsUserPlatformRatingFilterModel
  ): Observable<IAnalyticsUserPlatformRatingModel> =>
    new Observable(
      (observer: Observer<IAnalyticsUserPlatformRatingModel>) =>
        new AxiosSubscriber(observer, {
          data: filter || {},
          method: HTTP_METHOD.POST,
          url: `${this.url}/SelectAnalytics`,
        })
    );

  public exportRatingAnalytics = (
    options: IUserPlatformRatingAnalyticsSummaryFilterModel
  ): Observable<Blob> => {
    return new Observable(
      (observer: Observer<Blob>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            responseType: "blob",
          },
          data: options,
          method: HTTP_METHOD.POST,
          url: `${this.url}/ExportAnalytics`,
        })
    );
  };

  public search = (
    filter: IUserPlatformRatingsSearchFilterModel
  ): Observable<IUserPlatformRatingListModel> =>
    new Observable(
      (observer: Observer<IUserPlatformRatingListModel>) =>
        new AxiosSubscriber(observer, {
          data: filter,
          method: HTTP_METHOD.POST,
          url: `${this.url}/Search`,
        })
    );
}
