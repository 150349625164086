import { PixelCrop } from "react-image-crop";
import { ImageConversionResult } from "./imageOptimizer";

export const cropImageBlob = async (
  crop: PixelCrop,
  source: Blob,
  imageElement: HTMLImageElement
): Promise<ImageConversionResult> => {
  let image = await createImageBitmap(source);

  const scaleX = imageElement.naturalWidth / imageElement.width;
  const scaleY = imageElement.naturalHeight / imageElement.height;

  const width = Math.round(crop.width * scaleX);
  const height = Math.round(crop.height * scaleY);

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  let result = await cropOnCanvas(
    width,
    height,
    cropX,
    cropY,
    image,
    source.type
  );

  const croppedFile = new File([result], `cropped`, {
    type: result.type,
  });

  return { file: croppedFile, width, height };
};

export const cropImage = async (
  crop: PixelCrop,
  image: HTMLImageElement,
  type?: string | undefined,
  name?: string
): Promise<ImageConversionResult> => {
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;

  const width = Math.round(crop.width * scaleX);
  const height = Math.round(crop.height * scaleY);

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  let result = await cropOnCanvas(width, height, cropX, cropY, image, type);

  const croppedFile = new File([result], `cropped-${name}`, {
    type: result.type,
  });

  return { file: croppedFile, width, height };
};

const cropOnCanvas = async (
  width: number,
  height: number,
  cropX: number,
  cropY: number,
  image: HTMLImageElement | ImageBitmap,
  type: string | undefined
) => {
  let canvas = new OffscreenCanvas(width, height);
  let context = canvas.getContext("2d");

  if (!context) {
    throw new Error("Can't convert file, unable to get 2D context");
  }

  context.imageSmoothingQuality = "high";
  context.drawImage(image, cropX, cropY, width, height, 0, 0, width, height);

  return await canvas.convertToBlob({ type });
};
