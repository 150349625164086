import { ActionsObservable, ofType, StateObservable } from "redux-observable";
import { of } from "rxjs";
import { catchError, map, mergeMap, switchMap } from "rxjs/operators";
import {
  IErrorModel,
  ITextComponentModel,
  UploadFileInfoModel,
} from "../../models";
import { TextComponentsService } from "../../services/TextComponentsService";
import { ICommonAppState } from "../types";
import {
  getTextComponentAttachmentUploadUrlFailure,
  getTextComponentAttachmentUploadUrlSuccess,
  getTextComponentAttachmentUrlFailure,
  getTextComponentAttachmentUrlSuccess,
  getTextComponentFailure,
  getTextComponentSuccess,
  insertTextComponentFailure,
  insertTextComponentSuccess,
  updateTextComponentFailure,
  updateTextComponentSuccess,
} from "./actions";
import * as Consts from "./consts";
import {
  IGetTextComponentAction,
  IGetTextComponentAttachmentUploadUrlAction,
  IGetTextComponentAttachmentUrlAction,
  IInsertTextComponentAction,
  IUpdateTextComponentAction,
} from "./types";

const textComponentsService = new TextComponentsService();

const getTextComponentEpic = (
  action$: ActionsObservable<IGetTextComponentAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.GET_TEXT_COMPONENT),
    mergeMap((action: IGetTextComponentAction) =>
      textComponentsService.get(action.textComponentId).pipe(
        map((data: ITextComponentModel) => {
          action.onSuccess?.(data);

          return getTextComponentSuccess(data);
        }),
        catchError((error: IErrorModel) =>
          of(getTextComponentFailure(action.textComponentId, error))
        )
      )
    )
  );

const getTextComponentAttachmentUploadUrlEpic = (
  action$: ActionsObservable<IGetTextComponentAttachmentUploadUrlAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.GET_TEXT_COMPONENT_ATTACHEMENT_UPLOAD_URL),
    mergeMap((action: IGetTextComponentAttachmentUploadUrlAction) =>
      textComponentsService
        .getAttachmentUploadUrl(action.textComponentId, action.attachmentGuid)
        .pipe(
          map((data: UploadFileInfoModel) => {
            action.onSuccess?.(data);
            return getTextComponentAttachmentUploadUrlSuccess(
              action.textComponentId,
              action.attachmentGuid,
              data
            );
          }),
          catchError((error: IErrorModel) =>
            of(
              getTextComponentAttachmentUploadUrlFailure(
                action.textComponentId,
                action.attachmentGuid,
                error
              )
            )
          )
        )
    )
  );

const getTextComponentAttachmentUrlEpic = (
  action$: ActionsObservable<IGetTextComponentAttachmentUrlAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.GET_TEXT_COMPONENT_ATTACHEMENT_URL),
    mergeMap((action: IGetTextComponentAttachmentUrlAction) =>
      textComponentsService
        .getAttachmentUrl(action.textComponentId, action.attachmentGuid)
        .pipe(
          map((data: UploadFileInfoModel) => {
            action.onSuccess?.(data);

            return getTextComponentAttachmentUrlSuccess(
              action.textComponentId,
              action.attachmentGuid,
              data
            );
          }),
          catchError((error: IErrorModel) =>
            of(
              getTextComponentAttachmentUrlFailure(
                action.textComponentId,
                action.attachmentGuid,
                error
              )
            )
          )
        )
    )
  );

const insertTextComponentEpic = (
  action$: ActionsObservable<IInsertTextComponentAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.INSERT_TEXT_COMPONENT),
    switchMap((action: IInsertTextComponentAction) =>
      textComponentsService.insert(action.data).pipe(
        map((data: ITextComponentModel) => {
          action.onSuccess?.(data);
          return insertTextComponentSuccess(data);
        }),
        catchError((error: IErrorModel) =>
          of(insertTextComponentFailure(action.data.Id, error))
        )
      )
    )
  );

const updateTextComponentEpic = (
  action$: ActionsObservable<IUpdateTextComponentAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.UPDATE_TEXT_COMPONENT),
    switchMap((action: IUpdateTextComponentAction) =>
      textComponentsService.update(action.data).pipe(
        map((data: ITextComponentModel) => {
          action.onSuccess?.(data);
          return updateTextComponentSuccess(data);
        }),
        catchError((error: IErrorModel) =>
          of(updateTextComponentFailure(action.data.Id, error))
        )
      )
    )
  );

export const textComponentsEpics = [
  getTextComponentEpic,
  getTextComponentAttachmentUploadUrlEpic,
  getTextComponentAttachmentUrlEpic,
  insertTextComponentEpic,
  updateTextComponentEpic,
];
