import {
  ITestCaseRelationModel,
  ITestDefinitionModel,
  ITestPlatformRelationModel,
} from "@bms/common-services";
import {
  Choose,
  ChooseOption,
  Col,
  Form,
  IFormValues,
  Input,
  required,
  Row,
  Spin,
  TextArea,
  useValidate,
} from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { useTestingPlatforms } from "../../../../hooks";
import React, { useState } from "react";
import { PlatformTypeHelper } from "../../../../../../helpers";
import { TestCaseField } from "./TestCaseField";

interface TestingTestDefinitionFormProps {
  isLoading: boolean;
  setVisibilityStatus: (visible: boolean) => void;
  createTestDefinition: (data: ITestDefinitionModel) => Promise<boolean>;
}

export const TestingTestDefinitionForm = (
  props: TestingTestDefinitionFormProps
) => {
  const { isLoading, setVisibilityStatus, createTestDefinition } = props;

  const [selectedPlatforms, setSelectedPlatforms] = useState<string[]>();

  const validate = useValidate();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { platforms } = useTestingPlatforms();

  const onFinish = async (values: IFormValues) => {
    const { SelectedPlatforms, SelectedTestCase, ...rest } = values;

    const Platforms: ITestPlatformRelationModel[] = SelectedPlatforms.map(
      (platformCode: string) => ({
        PlatformCode: platformCode,
      })
    );

    const TestCases: ITestCaseRelationModel[] = [
      {
        TestCaseId: Number(SelectedTestCase.value),
      },
    ];

    const data: ITestDefinitionModel = {
      Id: -1,
      ...rest,
      Platforms,
      TestCases,
    };

    await createTestDefinition(data);
    setVisibilityStatus(false);
  };

  const renderNameInput = () => {
    return (
      <Form.Item name="Name" label={t("MODEL_NAME")} rules={[required()]}>
        <Input placeholder={t("MODEL_NAME")} />
      </Form.Item>
    );
  };

  const renderDescriptionInput = () => {
    return (
      <Form.Item name="Description" label={t("MODEL_DESCRIPTION")}>
        <TextArea maxLength={250} placeholder={t("MODEL_DESCRIPTION")} />
      </Form.Item>
    );
  };

  const renderPlatformField = () => {
    return (
      <Form.Item
        key="SelectedPlatforms"
        name="SelectedPlatforms"
        label={t("MODEL_PLATFORMS")}
        rules={validate.required({
          choiceField: true,
          message: t("REQUIRE_PLATFORM_VALIDATION_MESSAGE"),
        })}
      >
        <Choose<string[]>
          mode="multiple"
          placeholder={t("MODEL_PLATFORMS_PLACEHOLDER")}
          loading={!platforms.length}
          tagRender={PlatformTypeHelper.getTagOption}
          onChange={(value) => setSelectedPlatforms(value)}
        >
          {platforms.map((platform) => {
            return (
              <ChooseOption key={platform.Code} value={platform.Code}>
                {platform.DisplayName}
              </ChooseOption>
            );
          })}
        </Choose>
      </Form.Item>
    );
  };

  return (
    <Spin spinning={isLoading}>
      <Form
        name="TestingTestDefinitionForm"
        form={form}
        onFinish={onFinish}
        layout="vertical"
      >
        <Row direction="column" justify="space-between" className="full-height">
          <Col>
            {renderNameInput()}
            {renderPlatformField()}
            <TestCaseField
              label={t("TESTING_TEST_CASE")}
              placeholder={t("TESTING_TEST_CASE_PLACEHOLDER")}
              name="SelectedTestCase"
              platforms={selectedPlatforms}
            />
            {renderDescriptionInput()}
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};
