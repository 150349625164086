import { ICommonAppState } from "@bms/common-services";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
  ApplicationRegisterSuccessProperties as ApplicationRegisterSuccessPropertiesDefinition,
  ApplicationRegisterSuccessPropertiesDispatchProps,
  ApplicationRegisterSuccessPropertiesOwnProps,
  ApplicationRegisterSuccessPropertiesStateProps,
} from "./ApplicationRegisterSuccessProperties";

const mapStateToProps = (
  state: ICommonAppState
): ApplicationRegisterSuccessPropertiesStateProps => {
  return {
    isProcessingData: state.applicationConfiguration.isProcessingData,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch
): ApplicationRegisterSuccessPropertiesDispatchProps => ({});

export const ApplicationRegisterSuccessProperties = connect<
  ApplicationRegisterSuccessPropertiesStateProps,
  ApplicationRegisterSuccessPropertiesDispatchProps,
  ApplicationRegisterSuccessPropertiesOwnProps,
  ICommonAppState
>(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ApplicationRegisterSuccessPropertiesDefinition));
