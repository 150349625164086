import {
  ApplicationConfigurationStore,
  ICommonAppState,
} from "@bms/common-services";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
  ApplicationConfigurationDetails as ApplicationConfigurationDetailsDefinition,
  IApplicationConfigurationDetailsDispatchProps,
  IApplicationConfigurationDetailsOwnProps,
  IApplicationConfigurationDetailsStateProps,
} from "./ApplicationConfigurationDetails";

const mapStateToProps = (state: ICommonAppState) => ({
  configuration: state.applicationConfiguration.configuration,
  action: state.applicationConfiguration.action,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getConfiguration: (id: number) => {
    return dispatch(ApplicationConfigurationStore.Actions.getConfiguration(id));
  },
  publishConfiguration: (id: number, rowVersion: string) => {
    return dispatch(
      ApplicationConfigurationStore.Actions.publishConfiguration(id, rowVersion)
    );
  },
});

export const ApplicationConfigurationDetails = connect<
  IApplicationConfigurationDetailsStateProps,
  IApplicationConfigurationDetailsDispatchProps,
  IApplicationConfigurationDetailsOwnProps,
  ICommonAppState
>(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ApplicationConfigurationDetailsDefinition));
