import React from "react";
import { useTranslation } from "react-i18next";
import {
  ITestApplicationVersionModel,
  TestApplicationVersionsService,
} from "@bms/common-services";
import { Form, useValidate } from "@bms/common-ui";
import { LazyChoose } from "../../../../../../components";

interface TestApplicationVersionFieldProps {
  name: string;
  label: string;
  placeholder?: string;
  applicationId?: number;
}

const testApplicationVersionsService = new TestApplicationVersionsService().promisify();

export const TestApplicationVersionField = (
  props: TestApplicationVersionFieldProps
) => {
  const { name, label, placeholder, applicationId } = props;

  const { t } = useTranslation();
  const validate = useValidate();

  const loader = (search: string) =>
    testApplicationVersionsService.search({
      PageNumber: 1,
      PageSize: 100,
      FullTextSearch: search,
      TestApplicationId: applicationId || 0,
    });

  const toOption = (item: ITestApplicationVersionModel) => {
    return {
      label: item.Version!,
      value: item.Id.toString(),
      item,
    };
  };

  return (
    <Form.Item
      name={name}
      label={label}
      key={name}
      rules={validate.required({
        choiceField: true,
        message: t("REQUIRE_TEST_APPLICATION_VERSION_VALIDATION_MESSAGE"),
      })}
    >
      <LazyChoose<ITestApplicationVersionModel, ITestApplicationVersionModel>
        placeholder={placeholder}
        multiple={false}
        disabled={!applicationId}
        loaderDeps={[applicationId]}
        loader={loader}
        candidateToOption={toOption}
        selectedToOption={toOption}
      />
    </Form.Item>
  );
};
