import React from "react";
import {
  AssetService,
  IAssetPeopleModel,
  IPersonInAssetModel,
  PersonInAssetType,
} from "@bms/common-services";
import { Form } from "@bms/common-ui";
import { IAssetFormFieldCommonProps } from "./IAssetFormFieldCommonProps";
import { LazyChoose } from "../../../../../components";

interface IPeopleFieldProps extends IAssetFormFieldCommonProps {
  name: string;
  label: string;
  roleCode: PersonInAssetType;
}

const assetService = new AssetService().promisify();

export const PeopleField = ({
  name,
  label,
  roleCode,
  isEditMode,
  asset,
  isHidden,
}: IPeopleFieldProps) => {
  if (isHidden) {
    return null;
  }
  return (
    <Form.Item
      name={name}
      label={label}
      key={name}
      initialValue={
        (isEditMode &&
          asset?.People?.filter?.(
            (p) => p.PersonInAssetRoleCode === roleCode
          )) ||
        []
      }
    >
      <LazyChoose<IAssetPeopleModel, IPersonInAssetModel>
        loader={(search: string) =>
          assetService.searchAssetPeople({
            PageNumber: 1,
            PageSize: 100,
            FullTextSearch: search,
          })
        }
        candidateToOption={(item) => ({
          label: item.FullName,
          value: `${item.Id}`,
          item: {
            AssetId: asset?.Id,
            AssetPersonFullName: item.FullName,
            AssetPersonId: item.Id,
            PersonInAssetRoleCode: roleCode,
            RowVersion: item.RowVersion,
          },
        })}
        selectedToOption={(item) => ({
          label: item.AssetPersonFullName!,
          value: `${item.AssetPersonId}`,
          item,
        })}
      />
    </Form.Item>
  );
};
