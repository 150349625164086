import {
  IProductModel,
  ProductsService,
  useDataLoader
} from "@bms/common-services";
import { CardGrid, PageContent, Spin } from "@bms/common-ui";
import React from "react";
import { useTranslation } from "react-i18next";
import { ProductCard } from "../ProductCard";

interface IProductCardProps {
  product: IProductModel;
}

const productsService = new ProductsService().promisify();

export const ProductRelatedProductsList: React.FC<IProductCardProps> = (
  props
) => {
  const { product } = props;
  const { t } = useTranslation();

  const { data, loading } = useDataLoader({
    loader: () =>
      productsService.search({
        ParentId: product.Id,
      }),
    deps: [product.Id],
  });

  return (
    <div className="ProductCardList">
      <PageContent>
        <Spin spinning={loading}>
          <CardGrid cardHeight={400} cardWidth={350}>
            {data?.Entities && data?.Entities.map((product) => (
              <ProductCard product={product} />
            ))}
          </CardGrid>
          {!loading && !data?.Entities?.length && (
            <h1>{t("PRODUCT_NO_RELATED_PRODUCTS_MESSAGE")}</h1>
          )}
        </Spin>
      </PageContent>
    </div>
  );
};
