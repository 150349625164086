import {
  IAdvertisementBlockModel,
  IErrorModel,
} from "@bms/common-services";
import {
  Alert,
  Button,
  Col,
  Form,
  IFormInstance,
  IFormValues,
  Input,
  Modal,
  required,
  Row,
  TextArea,
} from "@bms/common-ui";
import React from "react";
import { WithTranslation } from "react-i18next";
import "./AdvertisementBlockAddModal.scss";


export interface IAdvertisementBlockAddModalStateProps {
  actionType?: string;
  error?: IErrorModel;
  isProcessingData: boolean;
  temporaryAdvertisementBlock: IAdvertisementBlockModel;
}

export interface IAdvertisementBlockAddModalDispatchProps {
}

export interface IAdvertisementBlockAddModalOwnProps {
  visible: boolean;
  onCancel: () => void;
  onSuccess: (date:IAdvertisementBlockModel) => void;
}

export interface IAdvertisementBlockAddModalProps
  extends IAdvertisementBlockAddModalStateProps,
    IAdvertisementBlockAddModalDispatchProps,
    IAdvertisementBlockAddModalOwnProps,
    WithTranslation {}

export class AdvertisementBlockAddModal extends React.PureComponent<
  IAdvertisementBlockAddModalProps
> {
  public static defaultProps = {
    visible: false
  };

  formRef = React.createRef<IFormInstance>();

  public onCancelClick = () => {
    const { onCancel } = this.props;

    this.formRef?.current?.resetFields();

    onCancel();
  };

  public onUpdateDescription = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    const data = this.props.temporaryAdvertisementBlock;

    data.Description = e.currentTarget.value;
  }

  public onUpdateName = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const data = this.props.temporaryAdvertisementBlock;

    data.Name = e.currentTarget.value;
  }

  public onFinish = (values: IFormValues) => {
    const { onSuccess } = this.props;
    const data = this.props.temporaryAdvertisementBlock;
    onSuccess({...data});
  };

  public renderNameField = () => {
    const { isProcessingData, t } = this.props;

    return (
      <Form.Item name="Name" label={t("NAME")} rules={[required()]}>
        <Input
          className="name"
          disabled={isProcessingData}
          placeholder={t("ENTER_NAME")}
          value={this.props.temporaryAdvertisementBlock.Name}
          onChange={this.onUpdateName}
          />
      </Form.Item>
    );
  };

  public renderDescriptionField = () => {
    const { isProcessingData, t } = this.props;

    return (
      <Form.Item name="Description" label={t("DESCRIPTION")}>
        <TextArea
          disabled={isProcessingData}
          className="description"
          placeholder={t("ENTER_DESCRIPTION")}
          value={this.props.temporaryAdvertisementBlock.Description}
          onChange={this.onUpdateDescription}
        />
      </Form.Item>
    );
  };

  public renderError = () => {
    const { error, t } = this.props;

    const formItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 24,
          offset: 0,
        },
        md: {
          span: 16,
          offset: 8,
        },
        lg: {
          span: 16,
          offset: 8,
        },
      },
    };

    if ( error ) {
      return (
        <Form.Item {...formItemLayout}>
          <Alert
            message={t("ADD_NEW_ADVERTISEMENT_BLOCK_ERROR")}
            description={error.Message}
            type="error"
            closable
            showIcon
          />
        </Form.Item>
      );
    }

    return null;
  };

  public render() {
    const { t, visible, actionType, onSuccess } = this.props;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 8 },
        lg: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 16 },
        lg: { span: 16 },
      },
    };

    return (
      <Modal
        title={t("ADD_NEW_ADVERTISEMENT_BLOCK")}
        onCancel={this.onCancelClick}
        visible={visible}
        footer={
          <>
            <Button key="cancel" onClick={this.onCancelClick}>
              {t("CANCEL")}
            </Button>
            <Button
              form="AdvertisementBlockAddForm"
              key="submit"
              htmlType="submit"
              type="primary"
            >
              {t("SAVE")}
            </Button>
          </>
        }
      >
        <Form
          name="AdvertisementBlockAddModal"
          id="AdvertisementBlockAddForm"
          {...formItemLayout}
          className="AdvertisementBlockAddModal"
          onFinish={this.onFinish}
          ref={this.formRef}
        >
          <Row
            direction="column"
            justify="space-between"
            className="full-height"
          >
            <Col>
              {this.renderNameField()}
              {this.renderDescriptionField()}
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}
