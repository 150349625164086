import {
  ITestApplicationVersionModel,
  TestApplicationVersionsService,
  useServiceCaller,
} from "@bms/common-services";
import { useTranslation } from "react-i18next";
import { useTableDataProvider } from "../../../../../../helpers";
import { useAppFeedback } from "@bms/common-ui";

interface TestApplicationVersionsParams {
  applicationId: number;
}

const testApplicationVersionsService = new TestApplicationVersionsService().promisify();

export const useTestApplicationVersions = (
  params: TestApplicationVersionsParams
) => {
  const { applicationId } = params;

  const { t } = useTranslation();
  const { notification } = useAppFeedback();

  const {
    dataLoader,
    filters,
    pagination,
    fullTextSearch,
    setFullTextSearch,
  } = useTableDataProvider({
    filtersSchema: {
      FullTextSearch: "string",
      Version: "string",
      UpToDate: "boolean",
    },
    loader: (filters, pagination) =>
      testApplicationVersionsService.search({
        TestApplicationId: applicationId,
        ...filters,
        ...pagination,
      }),
    deps: [],
    onError: (error) =>
      notification.error({
        message: t("LOADING_DATA_ERROR_MESSAGE"),
        description: error.Message,
      }),
  });

  const [
    createApplicationVersion,
    { processing: createProcessing },
  ] = useServiceCaller(
    async (applicationVersion: ITestApplicationVersionModel) => {
      const result = await testApplicationVersionsService.insert(
        applicationVersion
      );

      if (result.ok) {
        dataLoader.refresh();
        notification.success({
          message: t("TESTING_APPLICATIONS_VERSIONS_CREATE_SUCCESS"),
        });
      } else {
        notification.error({
          message: t("TESTING_APPLICATIONS_VERSIONS_CREATE_FAILURE"),
          description: result.error?.Message,
        });
      }
      return result.ok;
    },
    []
  );

  const [
    updateApplicationVersion,
    { processing: updateProcessing },
  ] = useServiceCaller(
    async (applicationVersion: ITestApplicationVersionModel) => {
      const result = await testApplicationVersionsService.update(
        applicationVersion
      );

      if (result.ok) {
        dataLoader.refresh();
        notification.success({
          message: t("TESTING_APPLICATIONS_VERSIONS_UPDATE_SUCCESS"),
        });
      } else {
        notification.error({
          message: t("TESTING_APPLICATIONS_VERSIONS_UPDATE_FAILURE"),
          description: result.error?.Message,
        });
      }
      return result.ok;
    },
    []
  );

  const [
    deleteApplicationVersion,
    { processing: deleteProcessing },
  ] = useServiceCaller(
    async (applicationVersion: ITestApplicationVersionModel) => {
      const result = await testApplicationVersionsService.delete(
        applicationVersion
      );

      if (result.ok) {
        dataLoader.refresh();
        notification.success({
          message: t("TESTING_APPLICATIONS_VERSIONS_DELETE_SUCCESS"),
        });
      } else {
        notification.error({
          message: t("TESTING_APPLICATIONS_VERSIONS_DELETE_ERROR"),
          description: result.error?.Message,
        });
      }
      return result.ok;
    },
    []
  );

  const isLoading =
    dataLoader.loading ||
    deleteProcessing ||
    updateProcessing ||
    createProcessing;

  return {
    isLoading,
    data: dataLoader.data,
    refreshApplicationVersions: dataLoader.refresh,
    createApplicationVersion,
    updateApplicationVersion,
    deleteApplicationVersion,
    filters,
    pagination,
    fullTextSearch,
    setFullTextSearch,
  };
};
