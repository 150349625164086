import { ICommonAppState } from "@bms/common-services";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
  ApplicationRegisterProperties as ApplicationRegisterPropertiesDefinition,
  ApplicationRegisterPropertiesDispatchProps,
  ApplicationRegisterPropertiesOwnProps,
  ApplicationRegisterPropertiesStateProps,
} from "./ApplicationRegisterProperties";

const mapStateToProps = (
  state: ICommonAppState
): ApplicationRegisterPropertiesStateProps => {
  return {
    isProcessingData: state.applicationConfiguration.isProcessingData,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch
): ApplicationRegisterPropertiesDispatchProps => ({});

export const ApplicationRegisterProperties = connect<
  ApplicationRegisterPropertiesStateProps,
  ApplicationRegisterPropertiesDispatchProps,
  ApplicationRegisterPropertiesOwnProps,
  ICommonAppState
>(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ApplicationRegisterPropertiesDefinition));
