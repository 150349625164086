import { useTranslation } from "react-i18next";
import { useTestingPlatforms } from "../../hooks";
import { useTestDefinitions } from "./useTestDefinitions";
import {
  Button,
  Icon,
  InputSearch,
  ITableColumnProps,
  ITableFilter,
  Link,
  PageContent,
  PageHeader,
  Pagination,
  Popconfirm,
  setTableColumnSearchProps,
  Table,
  Tooltip,
} from "@bms/common-ui";
import { ITestDefinitionModel, TimeHelper } from "@bms/common-services";
import {
  generateBreadcrumb,
  PlatformTypeHelper,
  TestStatusHelper,
} from "../../../../helpers";
import { ROUTES as TESTING_ROUTES } from "../../constants";
import { FilterCleanIcon } from "../../../../resources/icons";
import { PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { FormModal } from "../../../../components";
import { TestingTestDefinitionForm } from "./components";

export const TestingTestDefinitionsList = () => {
  const [modalVisible, setModalVisible] = useState(false);

  const { t } = useTranslation();
  const { platforms } = useTestingPlatforms();

  const {
    isLoading,
    data,
    pagination,
    fullTextSearch,
    setFullTextSearch,
    filters,
    refreshTestDefinitions,
    createTestDefinition,
    deleteTestDefinition,
  } = useTestDefinitions();

  const showAddModal = () => {
    setModalVisible(true);
  };

  const onSearch = (value: string) =>
    filters.update((currentFilters) => ({
      ...currentFilters,
      FullTextSearch: value || undefined,
    }));

  const onTableChange = (_: any, incomingFilters: ITableFilter) =>
    filters.update((currentFilters) => ({
      ...currentFilters,
      ...incomingFilters,
    }));

  const onDeleteTestDefinition = async (
    testDefinition: ITestDefinitionModel
  ) => {
    await deleteTestDefinition(testDefinition);
  };

  const getBreadcrumbProps = () => {
    return generateBreadcrumb([
      {
        path: TESTING_ROUTES.TEST_DEFINITIONS,
        breadcrumbName: t("TESTING_TEST_DEFINITIONS"),
      },
    ]);
  };

  const getExtraButtons = () => {
    return (
      <>
        <InputSearch
          key="search"
          placeholder={t("SEARCH_PLACEHOLDER")}
          value={fullTextSearch}
          onSearch={onSearch}
          onChange={({ target: { value } }) => setFullTextSearch(value)}
          style={{ width: 250 }}
          allowClear
        />
        <Tooltip key="filters-tooltip" overlay={t("MENU_OPTION_CLEAR_FILTERS")}>
          <Button
            key="clear-filters"
            shape="circle"
            icon={<FilterCleanIcon />}
            onClick={filters.clear}
            disabled={!filters.anyActive}
            title={t("MENU_OPTION_CLEAR_FILTERS")}
          />
        </Tooltip>
        <Tooltip key="reload-tooltip" overlay={t("BUTTON_REFRESH_TITLE")}>
          <Button
            key="reload"
            shape="circle"
            icon={<ReloadOutlined />}
            onClick={refreshTestDefinitions}
            title={t("BUTTON_REFRESH_TITLE")}
          />
        </Tooltip>
        <Tooltip
          key="add-tooltip"
          overlay={t("TESTING_TEST_DEFINITIONS_ADD_BUTTON")}
        >
          <Button
            key="add"
            shape="circle"
            type="primary"
            icon={<PlusOutlined />}
            title={t("TESTING_TEST_DEFINITIONS_ADD_BUTTON")}
            onClick={showAddModal}
          />
        </Tooltip>
      </>
    );
  };

  const getColumnsProps = (): Array<
    ITableColumnProps<ITestDefinitionModel>
  > => {
    return [
      {
        key: "Name",
        dataIndex: "Name",
        title: t("MODEL_NAME"),
        filteredValue: filters.asTableArray.Name,
        ...setTableColumnSearchProps("Name", t("MODEL_NAME")),
        render: (_, testDefinition) => {
          return (
            <Link
              to={`${TESTING_ROUTES.TEST_DEFINITION_DETAILS}/${testDefinition.Id}`}
            >
              {testDefinition.Name}
            </Link>
          );
        },
      },
      {
        key: "Description",
        dataIndex: "Description",
        ellipsis: true,
        title: t("MODEL_DESCRIPTION"),
        filteredValue: filters.asTableArray.Description,
        ...setTableColumnSearchProps("Description", t("MODEL_DESCRIPTION")),
      },
      {
        key: "Platforms",
        dataIndex: "Platforms",
        title: t("MODEL_PLATFORMS"),
        filters: platforms.map((platform) => ({
          text: platform.DisplayName,
          value: platform.Code,
        })),
        filteredValue: filters.asTableArray.Platforms,
        render: (_, testDefinition) =>
          testDefinition.Platforms?.map((platform) =>
            PlatformTypeHelper.getTag(platform.PlatformCode)
          ),
      },
      {
        key: "Status",
        dataIndex: "Status",
        title: t("TESTING_TEST_DEFINITIONS_STATUS"),
        align: "center",
        width: "120px",
        render: (status: string) => status && TestStatusHelper.getTag(status),
      },
      {
        key: "Created",
        dataIndex: "Created",
        title: t("MODEL_CREATION_DATE"),
        align: "center",
        width: "140px",
        render: (creationDate: Date) => TimeHelper.format(creationDate),
      },
      {
        key: "CreatedByFullName",
        dataIndex: "CreatedByFullName",
        title: t("MODEL_CREATOR"),
        align: "center",
      },
      {
        key: "Actions",
        dataIndex: "Actions",
        title: t("TABLE_ACTIONS_COLUMN"),
        align: "center",
        width: "100px",
        render: (_: any, testDefinition: ITestDefinitionModel) => (
          <Popconfirm
            title={t("DELETE_ELEMENT_DOUBLE_CONFIRMATION_QUESTION")}
            onConfirm={async (event?: React.MouseEvent<HTMLElement>) => {
              event?.preventDefault();
              await onDeleteTestDefinition(testDefinition);
            }}
            okText={t("BUTTON_YES")}
            cancelText={t("BUTTON_NO")}
          >
            <Button
              danger={true}
              icon={<Icon type="delete" />}
              title={t("DELETE_ELEMENT")}
            />
          </Popconfirm>
        ),
      },
    ];
  };

  return (
    <>
      <PageContent footer={<Pagination {...pagination.props} />}>
        <PageHeader
          title={t("TESTING_TEST_DEFINITIONS")}
          breadcrumb={getBreadcrumbProps()}
          extra={getExtraButtons()}
        />
        <Table<ITestDefinitionModel>
          rowKey="Id"
          columns={getColumnsProps()}
          dataSource={data?.Entities}
          loading={isLoading}
          pagination={false}
          onChange={onTableChange}
        />
      </PageContent>
      <FormModal
        isLoading={isLoading}
        isVisible={modalVisible}
        isNewForm={true}
        createFormTitle={t("TESTING_TEST_DEFINITIONS_ADD_HEADER")}
        modalClassName="TestingTestDefinitionModal"
        submitFormName="TestingTestDefinitionForm"
        onCloseModal={() => setModalVisible(false)}
        isDeleteButtonEnabled={false}
      >
        <TestingTestDefinitionForm
          isLoading={isLoading}
          setVisibilityStatus={setModalVisible}
          createTestDefinition={createTestDefinition}
        />
      </FormModal>
    </>
  );
};
