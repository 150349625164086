import { useCallback } from "react";
import { useHistory, useLocation } from "react-router";

export const useSearchParamsTabs = (
  defaultKey: string
): [string, (key: string) => void] => {
  const location = useLocation();
  const { push } = useHistory();
  const activeKey =
    new URLSearchParams(location.search).get("tab") ?? defaultKey;
  const setActiveKey = useCallback(
    (key: string) => {
      const searchParams = new URLSearchParams("");
      searchParams.set("tab", key);
      push({
        ...location,
        search: searchParams.toString(),
      });
    },
    [location]
  );
  return [activeKey, setActiveKey];
};
