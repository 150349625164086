import {
  ApplicationConfigurationStore,
  ICommonAppState,
  UploadFileInfoModel,
} from "@bms/common-services";
import {
  Col,
  Dragger,
  Form,
  IFormValues,
  Input,
  IUploadChangeEvent,
  IUploadFile,
  PreviewImage,
  Row,
  useAppFeedback,
} from "@bms/common-ui";
import { RcFile } from "antd/lib/upload/interface";
import { noop } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IBrandingImageModal } from "./index";

const applicationConfigurationSelector = (state: ICommonAppState) =>
  state.applicationConfiguration;

export const BrandingImageForm: React.FC<IBrandingImageModal> = (props) => {
  const { t } = useTranslation();
  const { notification } = useAppFeedback();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [fileList, setFileList] = useState<Array<IUploadFile<any>>>([]);
  const [file, setFile] = useState<RcFile>();
  const { imageData, configurationId, closeModal, onSubmit } = props;

  const { imageUploadInfo, actionType } = useSelector(
    applicationConfigurationSelector
  );

  const getImageUploadFileInfo = useCallback(
    (id: number) =>
      dispatch(
        ApplicationConfigurationStore.Actions.getImageUploadFileInfo(id)
      ),
    [dispatch]
  );

  const uploadAssetImage = useCallback(
    (
      fileUploadInfo: UploadFileInfoModel,
      file: File,
      onProgress: any,
      onSuccess: () => void
    ) => {
      dispatch(
        ApplicationConfigurationStore.Actions.uploadBrandingImage(
          fileUploadInfo,
          file,
          onProgress,
          onSuccess
        )
      );
    },
    [dispatch]
  );

  useEffect(() => {
    switch (actionType) {
      case "GET_IMAGE_UPLOAD_FILE_INFO_SUCCESS":
        uploadAssetImage(imageUploadInfo!.Data!, file!, noop, noop);

        break;
      case "UPLOAD_BRANDING_IMAGE_SUCCESS":
        // onSubmit(
        //   imageData.Key,
        //   UrlHelper.parseUrl(imageUploadInfo!.Data?.Url || "").url
        // );
        notification.success({
          message: t("IMAGE_UPLOAD_SUCCESS"),
        });
        closeModal();
        break;
      case "UPDATE_APPLICATION_BRANDING_SUCCESS":
        notification.success({
          message: t("IMAGE_UPDATE_SUCCESS"),
        });
        closeModal();
        break;
    }
  }, [actionType]);

  const formItemLayout = {
    labelCol: {
      lg: { span: 6 },
      md: { span: 6 },
      sm: { span: 6 },
      xs: { span: 24 },
    },
    wrapperCol: {
      lg: { span: 18 },
      md: { span: 18 },
      sm: { span: 18 },
      xs: { span: 24 },
    },
  };

  const onImageChange = (e: IUploadChangeEvent) => {
    return e && e.file && e.file.originFileObj;
  };

  const beforeUpload = (file: RcFile) => {
    setFile(file);
    return true;
  };

  const onFinish = (values: IFormValues) => {
    const imageUrl = values.Value as string;
    const imageFile = values.FileValue;

    // if (fileList.length === 0 && !Value) {
    //   return notificationService.error({
    //     message: t(
    //       "AddAssetImageValidation",
    //       "Please provide image url or upload new image."
    //     ),
    //   });
    // }

    // if (fileList.length) {
    //  return getImageUploadFileInfo(configurationId);
    // }

    return onSubmit(imageData.Key, imageUrl, imageFile);
  };

  return (
    <Form
      {...formItemLayout}
      id="BrandingImageModalForm"
      form={form}
      initialValues={imageData}
      onFinish={onFinish}
    >
      <Row direction="column" justify="space-between" className="full-height">
        <Col>
          <Form.Item key="Name" name="Name" label={t("MODEL_NAME")}>
            <Input
              placeholder={t("ASSET_IMAGE_FORM_PATH_PLACEHOLDER")}
              disabled={true}
            />
          </Form.Item>
          <Form.Item key="Key" name="Key" label={t("MODEL_KEY")}>
            <Input
              placeholder={t("BRANDING_IMAGE_FORM_KEY_PLACEHOLDER")}
              disabled={true}
            />
          </Form.Item>
          <Form.Item
            key="Value"
            name="Value"
            label={t("ASSET_IMAGE_FORM_PATH")}
          >
            <Input placeholder={t("ASSET_IMAGE_FORM_PATH_PLACEHOLDER")} />
          </Form.Item>
          <Form.Item
            key="FileValue"
            name="FileValue"
            label={t("ASSET_IMAGE_FORM_UPLOAD")}
            valuePropName="FileValue"
            getValueFromEvent={onImageChange}
          >
            <Dragger
              name="FileValue"
              accept="image/*"
              multiple={false}
              showUploadList={true}
              beforeUpload={beforeUpload}
              fileList={fileList}
              withPreview={true}
              maxCount={1}
            >
              {(previewSrc: string) => (
                <>
                  <p className="ant-upload-drag-icon">
                    <PreviewImage
                      src={previewSrc}
                      height={PREVIEW_IMAGE_DIMENSION}
                      width={PREVIEW_IMAGE_DIMENSION}
                      placeholderIconType={"inbox"}
                    />
                  </p>
                  <p className="ant-upload-text">{t("DRAG_AND_DROP_INFO")}</p>
                </>
              )}
            </Dragger>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

const PREVIEW_IMAGE_DIMENSION = 64;
