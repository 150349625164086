import { push } from "connected-react-router";
import { get } from "lodash";
import { ActionsObservable, ofType, StateObservable } from "redux-observable";
import { empty, never, of } from "rxjs";
import {
  catchError,
  exhaustMap,
  filter,
  map,
  mapTo,
  switchMap
} from "rxjs/operators";
import { ROUTES_COMMON } from "../../constants";
import { AuthResult } from "../../enums";
import {
  IAuthResponseModel,
  IErrorModel,
  stringToErrorModel,
  toErrorModel
} from "../../models";
import { 
  AuthService,
  RegisterService
} from "../../services";
import { StorageManager } from "../../services/StorageManager";
import {
  resetPasswordLinkFailureEpic,
  resetPasswordLinkSuccessEpic,
  resetPasswordSuccessEpic
} from "../resetPassword";
import { ICommonAppState } from "../types";
import {
  changePasswordFailure,
  changePasswordSuccess,
  forgotPasswordFailure,
  forgotPasswordSuccess,
  refreshTokenFailure,
  refreshTokenSuccess,
  registerConfirmEmailFailure,
  registerConfirmEmailSuccess,
  registerEmailFailure,
  registerEmailSuccess,
  resetForgotPasswordFailure,
  resetForgotPasswordSuccess,
  resetPasswordFailure,
  resetPasswordSuccess,
  signInAnonymousFailure,
  signInAnonymousSuccess,
  signInFailure,
  signInSuccess,
  signOutFailure,
  signOutSuccess,
  validateTokenFailure,
  validateTokenSuccess
} from "./actions";
import * as Consts from "./consts";
import {
  IChangePasswordAction,
  IConfirmAccountWithPasswordAction,
  IForgotPasswordAction,
  IRefreshTokenAction,
  IRefreshTokenFailureAction,
  IRefreshTokenSuccessAction,
  IRegisterConfirmEmailAction,
  IRegisterConfirmEmailSuccessAction,
  IRegisterEmailAction,
  IRegisterEmailSuccessAction,
  IResetForgotPasswordAction,
  IResetPasswordAction,
  ISignInAction,
  ISignInAnonymousAction,
  ISignInAnonymousSuccessAction,
  ISignInSuccessAction,
  ISignOutAction,
  ISignOutSuccessAction,
  IValidateTokenAction
} from "./types";

const authService: AuthService = new AuthService();
const registerService: RegisterService = new RegisterService();

export {
  resetPasswordLinkFailureEpic,
  resetPasswordLinkSuccessEpic,
  resetPasswordSuccessEpic
} from "../resetPassword";

export const signInEpic = (action$: ActionsObservable<ISignInAction>) =>
  action$.pipe(
    ofType(Consts.SIGN_IN),
    switchMap((action: ISignInAction) =>
      authService.signIn(action.data).pipe(
        map((response: IAuthResponseModel) => {
          return signInSuccess(response.User, response.AuthorizationToken);
        }),
        catchError((error: IErrorModel) => of(signInFailure(error)))
      )
    )
  );

const signInSuccessEpic = (action$: ActionsObservable<ISignInSuccessAction>) =>
  action$.pipe(
    ofType(Consts.SIGN_IN_SUCCESS),
    map((action: ISignInSuccessAction) => {
      const payload = action.payload;
      StorageManager.setValue("user", payload.user);
      StorageManager.setValue("session", payload.session);

      return push(ROUTES_COMMON.BASE);
    })
  );

export const signInAnonymousEpic = (
  action$: ActionsObservable<ISignInAnonymousAction>
) =>
  action$.pipe(
    ofType(Consts.SIGN_IN_ANONYMOUS),
    switchMap((action: ISignInAnonymousAction) => {
      return authService.signIn(action.data).pipe(
        map((response: IAuthResponseModel) => {
          return signInAnonymousSuccess(
            response.User,
            response.AuthorizationToken
          );
        }),
        catchError((error: IErrorModel) => of(signInAnonymousFailure(error)))
      );
    })
  );

const signInAnonymousSuccessEpic = (
  action$: ActionsObservable<ISignInAnonymousSuccessAction>
) =>
  action$.pipe(
    ofType(Consts.SIGN_IN_ANONYMOUS_SUCCESS),
    switchMap((action: ISignInAnonymousSuccessAction) => {
      const payload = action.payload;
      StorageManager.setValue("user", payload.user);
      StorageManager.setValue("session", payload.session);
      return empty();
    })
  );

export const registerEmailEpic = (
  action$: ActionsObservable<IRegisterEmailAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.REGISTER_EMAIL),
    switchMap((action: IRegisterEmailAction) =>
      registerService.registerEmail(action.data).pipe(
        map(() => registerEmailSuccess(action.redirectUrl)),
        catchError((error: IErrorModel) => of(registerEmailFailure(error)))
      )
    )
  );

export const registerEmailSuccessRedirectEpic = (
  action$: ActionsObservable<IRegisterEmailSuccessAction>
) =>
  action$.pipe(
    ofType(Consts.REGISTER_EMAIL_SUCCESS),
    filter(
      (action: IRegisterEmailSuccessAction) => !!action.payload.redirectUrl
    ),
    map((action: IRegisterEmailSuccessAction) => {
      return push(action.payload.redirectUrl || ROUTES_COMMON.BASE);
    })
  );

export const registerConfirmAccountWithPasswordEpic = (
  action$: ActionsObservable<IConfirmAccountWithPasswordAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.CONFIRM_ACCOUNT_WITH_PASSWORD),
    switchMap((action: IConfirmAccountWithPasswordAction) =>
      registerService.registerConfirmAccountWithPassword(action.data).pipe(
        map((response) =>
          registerConfirmEmailSuccess(
            response.User,
            response.AuthorizationToken,
            action.redirectUrl
          )
        ),
        catchError((error: string) =>
          of(registerConfirmEmailFailure(stringToErrorModel(error)))
        )
      )
    )
  );

export const registerConfirmEmailEpic = (
  action$: ActionsObservable<IRegisterConfirmEmailAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.REGISTER_CONFIRM_EMAIL),
    switchMap((action: IRegisterConfirmEmailAction) =>
      registerService.registerConfirmEmail(action.data).pipe(
        map((response) =>
          registerConfirmEmailSuccess(
            response.User,
            response.AuthorizationToken,
            action.redirectUrl
          )
        ),
        catchError((error: string) =>
          of(registerConfirmEmailFailure(stringToErrorModel(error)))
        )
      )
    )
  );

export const registerConfirmEmailSuccessEpic = (
  action$: ActionsObservable<IRegisterConfirmEmailSuccessAction>
) =>
  action$.pipe(
    ofType(Consts.REGISTER_CONFIRM_EMAIL_SUCCESS),
    map((action: IRegisterConfirmEmailSuccessAction) => {
      const payload = action.payload;
      const redirectUrl = payload.user?.FullName // Has registered before, there is no other way to check it
        ? ROUTES_COMMON.BASE
        : payload.redirectUrl || ROUTES_COMMON.BASE;

      StorageManager.setValue("user", payload.user);
      StorageManager.setValue("session", payload.session);

      return push(redirectUrl);
    })
  );

export const signOutEpic = (
  action$: ActionsObservable<ISignOutAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.SIGN_OUT),
    switchMap(() =>
      authService.signOut().pipe(
        map(signOutSuccess),
        catchError((error: IErrorModel) => of(signOutFailure(error)))
      )
    )
  );

const signOutSuccessEpic = (
  action$: ActionsObservable<ISignOutSuccessAction>
) =>
  action$.pipe(
    ofType(Consts.SIGN_OUT_SUCCESS),
    map((action: ISignOutSuccessAction) => {
      StorageManager.deleteValue("user");
      StorageManager.deleteValue("session");
      return action;
    }),
    mapTo(push(ROUTES_COMMON.BASE))
  );

export const refreshTokenEpic = (
  action$: ActionsObservable<IRefreshTokenAction>,
  state: StateObservable<ICommonAppState>
) => {
  return action$.pipe(
    ofType(Consts.REFRESH_TOKEN),
    exhaustMap(() => {
      const token = state.value.auth.session?.RefreshToken;
      return authService.refreshToken(token as string).pipe(
        map((response: IAuthResponseModel) => {
          if (
            response.AuthorizationToken &&
            response.AuthorizationToken.AuthResult === AuthResult.OK
          ) {
            return refreshTokenSuccess(response.AuthorizationToken);
          }
          const error = toErrorModel({
            Message: get(response, "AuthorizationToken.Message", ""),
          });
          return refreshTokenFailure(error);
        }),
        catchError((error: IErrorModel) => {
          return of(refreshTokenFailure(error));
        })
      );
    })
  );
};

const refreshTokenSuccessEpic = (
  action$: ActionsObservable<IRefreshTokenSuccessAction>
) =>
  action$.pipe(
    ofType(Consts.REFRESH_TOKEN_SUCCESS),
    switchMap((action: IRefreshTokenSuccessAction) => {
      const payload = action.payload;
      StorageManager.setValue("session", payload.session);
      return never();
    })
  );

const refreshTokenFailureEpic = (
  action$: ActionsObservable<IRefreshTokenFailureAction>
) =>
  action$.pipe(
    ofType(Consts.REFRESH_TOKEN_FAILURE),
    map((action: IRefreshTokenFailureAction) => {
      StorageManager.deleteValue("user");
      StorageManager.deleteValue("session");
      return action;
    }),
    mapTo(push(ROUTES_COMMON.BASE))
  );

const resetPasswordEpic = (
  action$: ActionsObservable<IResetPasswordAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.RESET_PASSWORD),
    switchMap((action: IResetPasswordAction) =>
      registerService.resetPassword(action.payload).pipe(
        map((data: boolean) => {
          return resetPasswordSuccess(data);
        }),
        catchError((error: IErrorModel) => of(resetPasswordFailure(error)))
      )
    )
  );

export const changePasswordEpic = (
  action$: ActionsObservable<IChangePasswordAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.CHANGE_PASSWORD),
    switchMap((action: IChangePasswordAction) =>
      authService.changePassword(action.payload).pipe(
        map((data: IAuthResponseModel) => {
          return changePasswordSuccess(data);
        }),
        catchError((error: IErrorModel) => of(changePasswordFailure(error)))
      )
    )
  );

export const validateTokenEpic = (
  action$: ActionsObservable<any>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.VALIDATE_TOKEN),
    switchMap((action: IValidateTokenAction) =>
      registerService.validateConfirmationToken(action.payload.token).pipe(
        map(() => validateTokenSuccess()),
        catchError((error: IErrorModel) => of(validateTokenFailure(error)))
      )
    )
  );

export const forgotPasswordEpic = (
  action$: ActionsObservable<IForgotPasswordAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.RESET_PASSWORD_LINK),
    switchMap((action: IForgotPasswordAction) =>
      registerService.forgotPassword(action.payload).pipe(
        map((response: boolean) => forgotPasswordSuccess(response)),
        catchError((error: IErrorModel) => of(forgotPasswordFailure(error)))
      )
    )
  );

const resetForgotPasswordEpic = (
  action$: ActionsObservable<IResetForgotPasswordAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.RESET_PASSWORD),
    switchMap((action: IResetForgotPasswordAction) =>
      registerService.resetForgotPassword(action.payload).pipe(
        map((response: boolean) => resetForgotPasswordSuccess(response)),
        catchError((error: IErrorModel) => {
          return of(resetForgotPasswordFailure(error));
        })
      )
    )
  );

export const authEpics = [
  signInEpic,
  signInSuccessEpic,
  signInAnonymousEpic,
  signInAnonymousSuccessEpic,
  registerEmailEpic,
  registerEmailSuccessRedirectEpic,
  registerConfirmEmailEpic,
  registerConfirmAccountWithPasswordEpic,
  registerConfirmEmailSuccessEpic,
  signOutEpic,
  signOutSuccessEpic,
  refreshTokenEpic,
  refreshTokenSuccessEpic,
  refreshTokenFailureEpic,
  changePasswordEpic,
  validateTokenEpic,
  forgotPasswordEpic,
  resetPasswordLinkSuccessEpic,
  resetPasswordLinkFailureEpic,
  resetForgotPasswordEpic,
  resetPasswordSuccessEpic
];
