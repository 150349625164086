import React from "react";
import { CardGrid, PageHeader, PageContent } from "@bms/common-ui";
import { Trans, useTranslation } from "react-i18next";
import { AssetsReportsService, useConfig } from "@bms/common-services";
import { AnalyticsTile } from "./components/AnalyticsTile";
import { RatingTile } from "./components/RatingTile";

import "./Dashboard.scss";

const assetsReportsService = new AssetsReportsService().promisify();

const params = {
  PageSize: 11,
  PageNumber: 0,
  IncludeCount: true,
};

export function Dashboard() {
  const { t } = useTranslation();
  const { config } = useConfig();

  return (
    <div className="Dashboard">
      <PageHeader title={<Trans i18nKey="MENU_DASHBOARD">Dashboard</Trans>} />
      <PageContent>
        <CardGrid cardHeight={800} cardWidth={400}>
          <AnalyticsTile
            title={t("DASHBOARD_TILE_MOST_PLAYED_HEADER")}
            subtitle={t("DASHBOARD_TILE_MOST_PLAYED_SUBHEADER")}
            service={() => assetsReportsService.getViewCount(params)}
          />
          <AnalyticsTile
            title={t("DASHBOARD_TILE_MOST_PURCHASED_HEADER")}
            subtitle={t("DASHBOARD_TILE_MOST_PURCHASED_SUBHEADER")}
            service={() => assetsReportsService.getPurchasedCount(params)}
          />
          <AnalyticsTile
            title={t("DASHBOARD_TILE_LIVE_HEADER")}
            subtitle={t("DASHBOARD_TILE_LIVE_SUBHEADER")}
            service={() =>
              assetsReportsService.getActiveLiveWatchersCount(params)
            }
            autoRefreshTime={60}
          />
          {config?.Rating?.Enabled && <RatingTile />}
        </CardGrid>
      </PageContent>
    </div>
  );
}
