import {
  IAdvertisementBlockModel,
  IAdvertisementBlocksListModel,
  IAdvertisementBlocksSearchFilterModel,
  TimeHelper,
} from "@bms/common-services";
import {
  Button,
  CardGrid,
  CardGridItem,
  Col,
  Icon,
  IPaginationProps,
  Link,
  PageContent,
  PageHeader,
  Pagination,
  Row,
  Spin,
} from "@bms/common-ui";
import React from "react";
import { useTranslation, WithTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";
import { ROUTES as USER_ROUTES } from "../../../User/constants/routes";
import { ROUTES } from "../../constants";
import { AdvertisementBlockAddModal } from "../AdvertisementBlockAddModal";
import "./AdvertisementBlocksList.scss";

export interface IAdvertisementBlocksListProps
  extends WithTranslation,
    RouteComponentProps {
  advertisementBlocks: IAdvertisementBlocksListModel;
  isLoadingData: boolean;
  searchAdvertisementBlocks: any;
  toggleAdvertisementBlockModal: any;
  addAdvertisementBlock: any;
  addModalVisible: boolean;
}

interface IAdvertisementBlockCardProps {
  advertisementBlock: IAdvertisementBlockModel;
}

const AdvertisementBlockCard: React.FC<IAdvertisementBlockCardProps> = ({
  advertisementBlock,
}) => {
  const [t] = useTranslation();

  return (
    <CardGridItem
      header={advertisementBlock.Name}
      footerLink={{
        href: `${ROUTES.ADVERTISEMENT_BLOCK_DETAILS}/${advertisementBlock.Id}`,
        title: (
          <>
            {t("BUTTON_SEE_DETAILS")}
            <Icon style={{ marginLeft: "8px" }} type="double_right" />
          </>
        ),
      }}
    >
      <Row gutter={[16, 16]}>
        <Col xs={12}>
          <h4>
            {t("LAST_MODIFICATION_DATE")} <Icon type="edit" />
          </h4>
          <div>{TimeHelper.format(advertisementBlock.Modified)}</div>
          <div>
            <Link
              to={`${USER_ROUTES.USER_DETAILS}/${advertisementBlock.ModifiedBy}`}
            >
              {advertisementBlock.ModifiedByFullName}
            </Link>
          </div>
        </Col>
        <Col xs={12}>
          <h4>
            {t("LAST_PUBLICATION_DATE")} <Icon type="upload" />
          </h4>
          <div>{TimeHelper.format(advertisementBlock.Created)}</div>
          <div>
            <Link
              to={`${USER_ROUTES.USER_DETAILS}/${advertisementBlock.CreatedBy}`}
            >
              {advertisementBlock.CreatedByFullName}
            </Link>
          </div>
        </Col>
        <Col xs={12}>
          <h4>{t("NAME")}</h4>
          <strong>{advertisementBlock.Name}</strong>
        </Col>
        <Col xs={12}>
          <Col xs={12}>
            <h4>{t("URL")}</h4>
            {advertisementBlock.Url}
          </Col>
        </Col>
        <Col xs={12}>
          <h4>{t("STATUS")}</h4>
          <strong>{advertisementBlock.Status}</strong>
        </Col>
      </Row>
    </CardGridItem>
  );
};

export class AdvertisementBlocksList extends React.PureComponent<
  IAdvertisementBlocksListProps
> {
  pageSize: number = 10;

  public componentDidMount() {
    this.props.searchAdvertisementBlocks({
      IncludeCount: true,
      PageNumber: 1,
      PageSize: this.pageSize,
    });
  }

  private onAddClick = () => {
    this.props.toggleAdvertisementBlockModal(true);
  };

  private onAddCancel = () => {
    this.props.toggleAdvertisementBlockModal(false);
  };

  private onAddSuccess = (data: IAdvertisementBlockModel) => {
    const { addAdvertisementBlock } = this.props;
    addAdvertisementBlock(data);
  };

  onPageChange = (page: number, pageSize?: number) => {
    const { advertisementBlocks } = this.props;

    const filter: IAdvertisementBlocksSearchFilterModel =
      advertisementBlocks.Filter || {};

    this.props.searchAdvertisementBlocks({
      IncludeCount: true,
      ...filter,
      PageNumber: page,
      PageSize: pageSize,
    });
  };

  public render() {
    const {
      advertisementBlocks,
      isLoadingData,
      addModalVisible,
      t,
    } = this.props;

    const filter: IAdvertisementBlocksSearchFilterModel =
      advertisementBlocks.Filter || {};

    const pagination: IPaginationProps = {
      current: filter.PageNumber ?? 1,
      defaultPageSize: 10,
      pageSize: filter.PageSize ?? 10,
      pageSizeOptions: ["10", "30", "50", "100"],
      showSizeChanger: true,
      showTotal: (total, range) =>
        t("TABLE_PAGINATION_TOTAL", {
          rangeFrom: range[0],
          rangeTo: range[1],
          total: total,
        }),
      total: advertisementBlocks.TotalCount,
      onChange: this.onPageChange,
    };

    return (
      <PageContent footer={<Pagination {...pagination} />}>
        <PageHeader
          title={t("ADVERTISEMENT_BLOCKS")}
          extra={
            <>
              <Button
                key="action-add"
                icon={<Icon type="plus" />}
                title={t("ADD_NEW_ADVERTISEMENT_BLOCK")}
                loading={advertisementBlocks.IsLoading}
                disabled={advertisementBlocks.IsLoading}
                onClick={this.onAddClick}
              ></Button>
            </>
          }
        ></PageHeader>
        <Spin spinning={isLoadingData}>
          <CardGrid cardHeight={400} cardWidth={350}>
            {advertisementBlocks.Entities.map((advertisementBlock) => (
              <AdvertisementBlockCard
                advertisementBlock={advertisementBlock}
                key={advertisementBlock.Id}
              />
            ))}
          </CardGrid>
        </Spin>
        <AdvertisementBlockAddModal
          visible={addModalVisible}
          onCancel={this.onAddCancel}
          onSuccess={this.onAddSuccess}
        />
      </PageContent>
    );
  }
}
