export type ImageConversionResult = {
  file: File;
  width: number;
  height: number;
};
export const optimizeImage = async (
  source: File | Blob,
  maxWidth: number,
  maxHeight: number
): Promise<ImageConversionResult> => {
  let image = await createImageBitmap(source);

  const ratio = Math.min(maxWidth / image.width, maxHeight / image.height);
  const scaledWidth = Math.floor(image.width * ratio);
  const scaledHeight = Math.floor(image.height * ratio);

  let canvas = new OffscreenCanvas(scaledWidth, scaledHeight);
  let context = canvas.getContext("2d");

  if (!context) {
    throw new Error("Can't convert file, unable to get 2D context");
  }

  context.drawImage(image, 0, 0, scaledWidth, scaledHeight);

  let result = await canvas.convertToBlob({
    type: "image/webp",
    quality: 0.95,
  });

  image.close();

  const convertedFile = new File([result], "optimized.webp", {
    type: result.type,
  });

  return { file: convertedFile, width: scaledWidth, height: scaledHeight };
};
