import React from "react";
import { Icon, Dragger, IDraggerProps, Avatar } from "@bms/common-ui";
import "./UserAvatarDragger.scss";

interface IUserAvatarDraggerProps
  extends Omit<
    IDraggerProps,
    "multiple" | "showUploadList" | "withPreview" | "children"
  > {
  src?: string;
}

export const UserAvatarDragger: React.FC<IUserAvatarDraggerProps> = ({
  src,
  ...props
}) => (
  <Dragger
    className="user-avatar-dragger"
    multiple={false}
    showUploadList={false}
    withPreview={true}
    {...props}
  >
    {(preview: string) => (
      <Avatar
        src={preview || src}
        size={100}
        icon={<Icon type="user" />}
        style={{ backgroundColor: "#2e2e2e" }}
      />
    )}
  </Dragger>
);
