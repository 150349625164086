import {
  IAdvertisementBoardModel,
  IAdvertisementBoardsListModel,
  IAdvertisementBoardsSearchFilterModel,
  IAssetReportModelRequest,
  IModelBase,
  UploadFileInfoModel,
} from "../../models";
import { Observable, Observer } from "rxjs";
import { AxiosSubscriber } from "../AxiosSubscriber";
import { HTTP_METHOD } from "../../constants";
import { PromisifiableBase } from "../Base/PromisifiableBase";

export interface AssetsReportsServiceParams {
  PageSize?: number,
  PageNumber?: number,
  IncludeCount?: boolean,
}

export class AssetsReportsService extends PromisifiableBase {
  get url(): string {
    return "/AssetsReports";
  }

  public getViewCount = (
    data: AssetsReportsServiceParams
  ): Observable<IAssetReportModelRequest> =>
    new Observable(
      (observer: Observer<IAssetReportModelRequest>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.POST,
          url: `${this.url}/GetViewCount`,
          data,
        })
    );

  public getPurchasedCount = (
    data: AssetsReportsServiceParams
  ): Observable<IAssetReportModelRequest> =>
    new Observable(
      (observer: Observer<IAssetReportModelRequest>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.POST,
          url: `${this.url}/GetPurchasedCount`,
          data,
        })
    );
    
  public getActiveLiveWatchersCount = (
    data: AssetsReportsServiceParams
  ): Observable<IAssetReportModelRequest> =>
    new Observable(
      (observer: Observer<IAssetReportModelRequest>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.POST,
          url: `${this.url}/GetActiveLiveWatchersCount`,
          data,
        })
    );
}
