import {
  IClassModel,
  IClassesListModel,
  IClassesSearchFilterModel,
} from "../../models";
import { DictionaryServiceBase } from "../Base";

export class ClassService extends DictionaryServiceBase<
  IClassModel,
  number,
  IClassesListModel,
  IClassesSearchFilterModel
> {
  get url(): string {
    return "/Classes";
  }
}
