import { Observable, Observer } from "rxjs";
import { HTTP_METHOD } from "../../constants/http";
import { AxiosSubscriber } from "../AxiosSubscriber";
import { ILanguageModel } from "../../models";
import { PromisifiableBase } from "..";

export class LanguageService extends PromisifiableBase {
  get url(): string {
    return "/Languages";
  }

  public select = (): Observable<ILanguageModel[]> =>
    new Observable(
      (observer: Observer<ILanguageModel[]>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `${this.url}/Select`,
        })
    );

  public update = (data: ILanguageModel): Observable<ILanguageModel> =>
    new Observable(
      (observer: Observer<ILanguageModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.PUT,
          url: `${this.url}/Update`,
        })
    );

  public insert = (data: ILanguageModel): Observable<ILanguageModel> =>
    new Observable(
      (observer: Observer<ILanguageModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: `${this.url}/Insert`,
        })
    );

  public delete = (data: ILanguageModel): Observable<undefined> =>
    new Observable(
      (observer: Observer<undefined>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: `${this.url}/Delete`,
        })
    );
}
