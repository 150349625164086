import { AssetStore, IAssetCategoryModel } from "@bms/common-services";
import React from "react";
import { useTranslation } from "react-i18next";
import { AssetCategoryForm } from "../AssetCategoryForm";
import { useDispatch } from "react-redux";
import { FormModal } from "../../../../components";

interface AssetCategoryModalProps {
  loading: boolean;
  visible: boolean;
  isNewCategory: boolean;
  selectedCategory: IAssetCategoryModel;
  closeModal: () => void;
}

export const AssetCategoryModal: React.FC<AssetCategoryModalProps> = (
  props
) => {
  const {
    visible,
    isNewCategory,
    selectedCategory,
    closeModal,
    loading,
  } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onDeleteAssetCategory = () => {
    dispatch(AssetStore.Actions.deleteAssetCategory(selectedCategory));
  };

  return (
    <FormModal
      createFormTitle={t("ASSET_CATEGORY__ADD_NEW_CATEGORY")}
      editFormTitle={t("ASSET_CATEGORY__EDIT_CATEGORY")}
      modalClassName="AssetCategoryForm"
      submitFormName="AssetCategoryForm"
      isLoading={loading}
      isVisible={visible}
      isNewForm={isNewCategory}
      isDeleteButtonEnabled={!isNewCategory}
      onCloseModal={() => closeModal()}
      onDeleteButtonClick={onDeleteAssetCategory}
    >
      <AssetCategoryForm
        category={selectedCategory}
        isNewCategory={isNewCategory}
      />
    </FormModal>
  );
};
