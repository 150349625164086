import React from "react";
import { SectionGrid, SectionGridItem } from "@bms/common-ui";
import { AssetForm } from "../../AssetForm";
import { baseCommonFields, childFields } from "../../AssetForm/AssetFormUtils";
import { ICommonAssetDetailsTabProps } from "./ICommonAssetDetailsTabProps";

export const DescriptionTab = ({
  asset,
  updateAssetState,
  updateAsset,
}: ICommonAssetDetailsTabProps) => {
  return (
    <SectionGrid style={{ maxWidth: "1200px" }}>
      <SectionGridItem processing={updateAssetState?.processing}>
        <AssetForm
          key={`AssetForm-${asset?.Id}`}
          isEditMode={true}
          asset={asset}
          section="general"
          onSubmit={updateAsset}
          hiddenFields={[
            ...baseCommonFields,
            ...childFields,
            "DurationMiliseconds",
            "Year",
          ]}
        />
      </SectionGridItem>
    </SectionGrid>
  );
};
