import {
  Dragger,
  Form,
  Icon,
  IUploadChangeEvent,
  IUploadFile,
} from "@bms/common-ui";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { takeRight } from "lodash";
import { useDispatch } from "react-redux";
import { ApplicationConfigurationStore } from "@bms/common-services";

interface TranslationImportFormProps {}

export const TranslationImportForm: React.FC<TranslationImportFormProps> = () => {
  const [fileList, setFileList] = useState<IUploadFile[]>([]);
  const [file, setFile] = useState<File>();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const importTranslations = useCallback(
    (file: File) =>
      dispatch(ApplicationConfigurationStore.Actions.importTranslations(file)),
    [dispatch]
  );

  const onBeforeUpload = (file: File) => {
    setFile(file);

    return true;
  };

  const onChangeContent = (event: IUploadChangeEvent) => {
    const { fileList } = event;
    const latestFiles = takeRight(fileList);

    if (latestFiles.length) {
      latestFiles[0].status = "done";
    }

    setFileList(latestFiles);
  };

  const onFinish = () => {
    if (file && fileList.length) {
      importTranslations(file);
    }
  };

  return (
    <Form
      form={form}
      name="TranslationImportForm"
      className="TranslationImportForm"
      onFinish={onFinish}
    >
      <Form.Item>
        <Dragger
          className="TranslationImportForm__Dragger"
          name="Upload"
          multiple={false}
          showUploadList={{
            showRemoveIcon: true,
            showPreviewIcon: false,
            showDownloadIcon: false,
          }}
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          beforeUpload={onBeforeUpload}
          fileList={fileList}
          onChange={onChangeContent}
        >
          <p className="ant-upload-drag-icon">
            <Icon type="Inbox" />
          </p>
          <p className="ant-upload-text">{t("DRAG_AND_DROP_INFO")}</p>
        </Dragger>
      </Form.Item>
    </Form>
  );
};
