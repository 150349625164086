import { IErrorModel } from "../../models";
import { CurrenciesActionsTypes } from "./types";
import * as Consts from "./consts";
import { ICurrencyModel } from "../../models/Currencies/CurrencyModel";

export const selectCurrencies = (): CurrenciesActionsTypes => {
  return {
    type: Consts.SELECT_CURRENCIES,
  };
};

export const selectCurrenciesSuccess = (
  data: ICurrencyModel[]
): CurrenciesActionsTypes => {
  return {
    payload: {
      data,
    },
    type: Consts.SELECT_CURRENCIES_SUCCESS,
  };
};

export const selectCurrenciesFailure = (
  error?: IErrorModel
): CurrenciesActionsTypes => {
  return {
    error,
    type: Consts.SELECT_CURRENCIES_FAILURE,
  };
};

export const updateCurrency = (
  data: ICurrencyModel
): CurrenciesActionsTypes => {
  return {
    payload: data,
    type: Consts.UPDATE_CURRENCY,
  };
};

export const updateCurrencySuccess = (
  data: ICurrencyModel
): CurrenciesActionsTypes => {
  return {
    payload: data,
    type: Consts.UPDATE_CURRENCY_SUCCESS,
  };
};

export const updateCurrencyFailure = (
  error?: IErrorModel
): CurrenciesActionsTypes => {
  return {
    error,
    type: Consts.UPDATE_CURRENCY_FAILURE,
  };
};

export const insertCurrency = (
  data: ICurrencyModel
): CurrenciesActionsTypes => {
  return {
    payload: data,
    type: Consts.INSERT_CURRENCY,
  };
};

export const insertCurrencySuccess = (
  data: ICurrencyModel
): CurrenciesActionsTypes => {
  return {
    payload: data,
    type: Consts.INSERT_CURRENCY_SUCCESS,
  };
};

export const insertCurrencyFailure = (
  error?: IErrorModel
): CurrenciesActionsTypes => {
  return {
    error,
    type: Consts.INSERT_CURRENCY_FAILURE,
  };
};

export const deleteCurrency = (
  data: ICurrencyModel
): CurrenciesActionsTypes => {
  return {
    payload: data,
    type: Consts.DELETE_CURRENCY,
  };
};

export const deleteCurrencySuccess = (): CurrenciesActionsTypes => {
  return {
    type: Consts.DELETE_CURRENCY_SUCCESS,
  };
};

export const deleteCurrencyFailure = (
  error?: IErrorModel
): CurrenciesActionsTypes => {
  return {
    error,
    type: Consts.DELETE_CURRENCY_FAILURE,
  };
};

export const CurrenciesActions = {
  selectCurrenciesFailure,
  selectCurrenciesSuccess,
  selectCurrencies,
  updateCurrency,
  updateCurrencySuccess,
  updateCurrencyFailure,
  insertCurrency,
  insertCurrencySuccess,
  insertCurrencyFailure,
  deleteCurrency,
  deleteCurrencySuccess,
  deleteCurrencyFailure,
};
