import React from "react";
import { DatePicker, Form, IFormInstance, useValidate } from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { IAssetFormFieldCommonProps } from "./IAssetFormFieldCommonProps";

interface IAvailableDateFieldsProps extends IAssetFormFieldCommonProps {
  form: IFormInstance;
}

export const AvailableDateFields = ({
  isHidden,
  isEditMode,
  asset,
  form,
}: IAvailableDateFieldsProps) => {
  const { t } = useTranslation();
  const { isBefore, isAfter } = useValidate();

  if (isHidden) {
    return null;
  }

  return (
    <React.Fragment key="AvailableDate">
      <Form.Item
        name="AvailableFrom"
        label={t("MODEL_AVAILABLE_FROM")}
        key="AvailableFrom"
        dependencies={["StartDateTime", "AvailableTo"]}
        initialValue={
          isEditMode && asset?.AvailableFrom
            ? new Date(asset?.AvailableFrom)
            : undefined
        }
        rules={[
          ...isBefore(
            form,
            "AvailableFrom",
            "AvailableTo",
            "AVAILABLE_START_DATE_MESSAGE"
          ),
          {
            type: "object",
            required: true,
            message: t("REQUIRED_VALIDATION_MESSAGE"),
          },
        ]}
      >
        <DatePicker
          showTime={true}
          placeholder={t("MODEL_AVAILABLE_FROM_PLACEHOLDER")}
          style={{ width: "100%" }}
        />
      </Form.Item>
      <Form.Item
        name="AvailableTo"
        label={t("MODEL_AVAILABLE_TO")}
        key="AvailableTo"
        dependencies={["AvailableFrom"]}
        initialValue={
          isEditMode && asset?.AvailableTo
            ? new Date(asset?.AvailableTo)
            : undefined
        }
        rules={[
          ...isAfter(
            form,
            "AvailableFrom",
            "AvailableTo",
            "AVAILABLE_END_DATE_MESSAGE"
          ),
        ]}
      >
        <DatePicker
          showTime={true}
          placeholder={t("MODEL_AVAILABLE_TO_PLACEHOLDER")}
          style={{ width: "100%" }}
        />
      </Form.Item>
    </React.Fragment>
  );
};
