import React from "react";
import { SectionGrid, SectionGridItem } from "@bms/common-ui";
import { ICommonAssetDetailsTabProps } from "./ICommonAssetDetailsTabProps";
import { AssetForm } from "../../AssetForm";
import { useTranslation } from "react-i18next";

export const CustomTab = ({
  asset,
  updateAssetState,
  updateAsset,
}: ICommonAssetDetailsTabProps) => {
  const { t } = useTranslation();

  return (
    <SectionGrid>
      <SectionGridItem
        header={t("ASSET_DETAILS_SECTION_CUSTOM_FIELDS")}
        processing={updateAssetState?.processing}
      >
        <AssetForm
          key={`AssetForm-${asset?.Id}`}
          isEditMode={true}
          asset={asset}
          section="custom"
          onSubmit={updateAsset}
        />
      </SectionGridItem>
    </SectionGrid>
  );
};
