import { IClassQuizCategoryModel } from "../../models";
import { ServiceBase } from "../Base";

export class ClassQuizCategoryService extends ServiceBase<
  IClassQuizCategoryModel,
  number
> {
  get url(): string {
    return "/ClassesQuizCategories";
  }
}
