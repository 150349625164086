import { Select } from "antd";
import {
  SelectProps as AntSelectProps,
  SelectValue as AntSelectValue,
} from "antd/lib/select";
import React from "react";

const { Option } = Select;

export const ChooseOption = Option;

declare type ChooseRawValue = string | number;

export interface IChooseLabeledValue {
  key?: string;
  value: ChooseRawValue;
  label: React.ReactNode;
}

export declare type ChooseValue =
  | ChooseRawValue
  | ChooseRawValue[]
  | IChooseLabeledValue
  | IChooseLabeledValue[];

export declare type IChooseValue = AntSelectValue;

export declare type IChooseTagProps = {
  label: string;
  value: ChooseValue;
  disabled: boolean;
  onClose: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  closable: boolean;
};

export interface IChooseProps<ValueType = string>
  extends Omit<AntSelectProps<IChooseValue>, "tagRender" | "onChange"> {
  tagRender?: (props: IChooseTagProps) => React.ReactElement;
  onChange?: (value: ValueType) => void;
}

export class Choose<ValueType = string> extends React.PureComponent<
  IChooseProps<ValueType>
> {
  public render() {
    const { tagRender, ...rest } = this.props;
    const props = rest as AntSelectProps<IChooseValue>;

    if (tagRender) {
      props.tagRender = (props) => tagRender(props as IChooseTagProps);
    }

    return <Select<IChooseValue> {...props} />;
  }
}
