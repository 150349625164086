export const ROUTES_COMMON = {
  BASE: "/",
  FAQ: "/faq",
  LOGIN: "/login",
  REGISTER: "/register",
  RESET_PASSWORD: "/forgot-password",
  RESET_PASSWORD_SUCCESS: "/forgot-password-success",
  RESET_PASSWORD_LINK: "/forgot-password-link",
  AUTH_CONFIRM_EMAIL: "/auth/confirmAccount",
  AUTH_RESET_PASSWORD: "/resetPassword",
};
