import {IAssetListModel, IAssetModel, IMediaListModel, IMediaModel} from "@bms/common-services";
import { Title } from "@bms/common-ui";
import React from "react";
import { Action, ActionCreator } from "redux";
import { WithScreenDesignerContextProps } from "../../context";
import { ListComponentPropertyModel } from "../../models";
import {
  ComponentPreviewBase,
  IComponentPreviewBaseProps,
  IComponentPreviewBaseState,
} from "../ComponentPreviewBase";
import "./ListComponentPreview.scss";
import { ListItemComponentPreview } from "./ListItemComponentPreview";

export interface IListComponentPreviewStateProps {
  sources: { [key: string]: IAssetListModel };
  media: { [key: string]: IMediaListModel };
}

export interface IListComponentPreviewDispatchProps {
  getDataSource: ActionCreator<Action>;
  getMediaList?: ActionCreator<Action>;
}

export interface IListComponentPreviewOwnProps
  extends IComponentPreviewBaseProps {
  properties: ListComponentPropertyModel;
}

export interface IListComponentPreviewProps
  extends IListComponentPreviewStateProps,
    IListComponentPreviewDispatchProps,
    IListComponentPreviewOwnProps,
    WithScreenDesignerContextProps {}

export interface IListComponentPreviewState
  extends IComponentPreviewBaseState {}

export class ListComponentPreview extends ComponentPreviewBase<
  IListComponentPreviewProps,
  IListComponentPreviewState
> {
  public static defaultProps = {
    sources: {},
  };

  public state: Readonly<IListComponentPreviewState> = {};

  private _elementRef: React.RefObject<HTMLDivElement>;

  constructor(props: IListComponentPreviewProps) {
    super(props);
    this._elementRef = React.createRef<HTMLDivElement>();
  }

  public componentDidMount() {
    const { getDataSource, sources, properties, getMediaList } = this.props;


    if (properties.SourceId) {
      const source = sources[properties.SourceId];

      if (getMediaList) {
       return getMediaList(properties.SourceId)
      }

      if (!source) {
        getDataSource(properties.SourceId);
      }
    }
  }

  componentDidUpdate(prevProps: IListComponentPreviewProps) {
    const { getDataSource, sources, properties } = this.props;
    // Typical usage (don't forget to compare props):
    if (
      properties?.SourceId !== prevProps.properties?.SourceId &&
      properties?.SourceId
    ) {
      const source = sources[properties.SourceId];

      if (!source) {
        getDataSource(properties.SourceId);
      }
    }
  }

  private getSource = (): IAssetListModel => {
    const { properties, sources } = this.props;

    let source: IAssetListModel | undefined;

    if (properties.SourceId) {
      source = sources[properties.SourceId];
    }

    if (!source || source.IsLoading || source.Error) {
      source = {
        Entities: [],
        TotalCount: properties.VisibleItemsCount + 1,
        Filter: {
          PageNumber: 1,
          PageSize: properties.VisibleItemsCount + 1,
          IncludeCount: true,
        },
      };

      for (let i = 0; i <= properties.VisibleItemsCount; i++) {
        source.Entities.push({
          Id: -i,
          Title: `Asset ${i} title...`,
          Description: `Asset ${i} description...`,
        });
      }
    }

    return source;
  };

  private renderItems = () => {
    const { layoutOptions, properties, media } = this.props;

    const itemWidth = Math.floor(
      (layoutOptions.RowWidth * 0.9) / properties.VisibleItemsCount
    );
    const source = this.getSource();

    if (!source || !source.Entities) {
      return [];
    }


    let itemsView

    if(properties.SourceId &&
        media[properties.SourceId]?.Entities &&
        media[properties.SourceId]?.Entities.length > 0) {
      //todo ListItemComponentPreview should contain type IMediaModel as asset
      itemsView = media[properties.SourceId].Entities.map(
          (asset: any, index: number) => (
              <ListItemComponentPreview
                  key={`${asset.Id}_${index}`}
                  cellType={properties.CellType}
                  width={itemWidth}
                  asset={asset}
                  isPlaceholder={false}
              />
          )
      )
    } else {
      itemsView = source.Entities.map(
          (asset: IAssetModel, index: number) => (
              <ListItemComponentPreview
                  key={`${asset.Id}_${index}`}
                  cellType={properties.CellType}
                  width={itemWidth}
                  asset={asset}
                  isPlaceholder={false}
              />
          )
      )
    }

    return itemsView;
  };

  public renderTitle = () => {
    const { properties } = this.props;

    return (
      <div className="ListComponentPreview--title">
        {properties.Title && <Title level={4}>{properties.Title}</Title>}
      </div>
    );
  };

  public render() {
    const { component } = this.props;

    return (
      <div
        key={component.Id}
        className="ListComponentPreview"
        ref={this._elementRef}
      >
        {this.renderTitle()}
        <div className="ListComponentPreview--content">
          {this.renderItems()}
        </div>
      </div>
    );
  }
}
