import { ActionsObservable, ofType, StateObservable } from "redux-observable";
import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { ICommonAppState } from "../";
import {
  IErrorModel,
  INotificationListModel,
  IUserNotificationListModel,
} from "../../models";
import { NotificationsClient, NotificationsService } from "../../services";
import {
  markAsReadFailure,
  markAsReadSuccess,
  searchNotificationsFailure,
  searchNotificationsSuccess,
  searchUserNotificationsFailure,
  searchUserNotificationsSuccess,
} from "./actions";
import * as Consts from "./consts";
import {
  INotificationsMarkAsReadAction,
  ISearchNotificationsAction,
  ISearchUserNotificationsAction,
} from "./types";

const notificationsService: NotificationsService = new NotificationsService();
const notificationsClient: NotificationsClient = NotificationsClient.getInstance();

const searchNotificationsEpic = (
  action$: ActionsObservable<ISearchNotificationsAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.SEARCH_NOTIFICATIONS),
    switchMap((action) =>
      notificationsService.search(action.filter).pipe(
        map((data: INotificationListModel) => searchNotificationsSuccess(data)),
        catchError((error: IErrorModel) =>
          of(searchNotificationsFailure(error))
        )
      )
    )
  );

const searchUserNotificationsEpic = (
  action$: ActionsObservable<ISearchUserNotificationsAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.SEARCH_USER_NOTIFICATIONS),
    switchMap((action) =>
      notificationsService.searchUserNotifications(action.filter).pipe(
        map((data: IUserNotificationListModel) =>
          searchUserNotificationsSuccess(data)
        ),
        catchError((error: IErrorModel) =>
          of(searchUserNotificationsFailure(error))
        )
      )
    )
  );

const markAsReadEpic = (
  action$: ActionsObservable<INotificationsMarkAsReadAction>
) =>
  action$.pipe(
    ofType(Consts.NOTIFICATIONS_MARK_AS_READ),
    switchMap((action) =>
      notificationsClient.markAsRead(action.payload).pipe(
        map(() => markAsReadSuccess(action.payload)),
        catchError((error: IErrorModel) => of(markAsReadFailure(error)))
      )
    )
  );

export const notificationEpics = [
  markAsReadEpic,
  searchNotificationsEpic,
  searchUserNotificationsEpic,
];
