import {
  CommonStore,
  ICommonAppState,
  IResourceModel,
  IResourcesSearchFilterModel,
} from "@bms/common-services";
import {
  Button,
  Dropdown,
  Heading,
  Icon,
  InputSearch,
  ITableColumnProps,
  ITableFilter,
  ITablePaginationConfig,
  ITableRowSelection,
  Menu,
  MenuItem,
  MenuItemClickEvent,
  Modal,
  setTableColumnSearchProps,
  Table,
  Tag,
} from "@bms/common-ui";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import "./TranslationsBrowserModal.scss";

export interface ITranslationsBrowserModalProps {
  visible: boolean;
  onCancel?: () => void;
  onSelect?: (row: IResourceModel) => void;
}

const commonSelector = (state: ICommonAppState) => state.common;

export const TranslationsBrowserModal: React.FC<ITranslationsBrowserModalProps> = (
  props
) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedRow, setSelectedRow] = useState<IResourceModel>();
  const { resourcesBrowser, languages, applications, platforms } = useSelector(
    commonSelector
  );
  const filter: IResourcesSearchFilterModel = resourcesBrowser.Filter || {
    PageNumber: 1,
    PageSize: 10,
    IncludeCount: true,
  };

  const browseResource = useCallback(
    (newFilter: IResourcesSearchFilterModel) =>
      dispatch(CommonStore.Actions.browseResource(newFilter)),
    [dispatch]
  );

  const selectLanguages = useCallback(
    () => dispatch(CommonStore.Actions.selectLanguages()),
    [dispatch]
  );
  const selectPlatforms = useCallback(
    () => dispatch(CommonStore.Actions.selectPlatforms()),
    [dispatch]
  );
  const selectApplications = useCallback(
    () => dispatch(CommonStore.Actions.selectApplications()),
    [dispatch]
  );

  useEffect(() => {
    browseResource(filter);

    if (!languages || languages.length === 0) {
      selectLanguages();
    }

    if (!platforms || platforms.length === 0) {
      selectPlatforms();
    }

    if (!applications || applications.length === 0) {
      selectApplications();
    }
  }, []);

  const onAdditionalOptionsMenuItemClick = (e: MenuItemClickEvent) => {
    switch (e.key) {
      case "OPTION_CLEAR_FILTERS":
        onClearFiltersClick();
        break;
    }
  };

  const _additionalOptionsMenu: React.ReactElement = (
    <Menu onClick={onAdditionalOptionsMenuItemClick}>
      <MenuItem key="OPTION_CLEAR_FILTERS">
        {t("MENU_OPTION_CLEAR_FILTERS")}
      </MenuItem>
    </Menu>
  );

  const onRefreshClick = () => browseResource(filter);

  const onClearFiltersClick = () => {
    const newFilter: IResourcesSearchFilterModel = {
      PageSize: filter?.PageSize || 10,
      PageNumber: 1,
      IncludeCount: true,
      LanguageCodes: ["en"],
    };
    browseResource(newFilter);
  };

  const getColumnsProps = (): Array<ITableColumnProps<IResourceModel>> => {
    return [
      {
        dataIndex: "ResourceKey",
        filteredValue: filter.ResourceKey ? [filter.ResourceKey] : null,
        key: "ResourceKey",
        title: t("MODEL_TRANSLATION_KEY"),
        ...setTableColumnSearchProps("ResourceKey"),
      },
      {
        dataIndex: "ResourceValue",
        filteredValue: filter.ResourceValue ? [filter.ResourceValue] : null,
        key: "ResourceValue",
        title: t("TRANSLATION__TRANSLATION_VALUE"),
        ...setTableColumnSearchProps("ResourceValue"),
      },
      {
        dataIndex: "LanguageCode",
        filteredValue: filter.LanguageCodes || null,
        filters: languages.map((language) => ({
          text: language.Name,
          value: language.Code,
        })),
        key: "LanguageCode",
        title: t("MODEL_LANGUAGE"),
        render: (text: any, row: IResourceModel) => {
          return (
            <Tag color={row.LanguageCode ? "#f50" : "#1890ff"}>
              {row.LanguageCode}
            </Tag>
          );
        },
      },
      {
        dataIndex: "ApplicationKey",
        filteredValue: filter.ApplicationKeys || null,
        filters: applications.map((application) => ({
          text: application.DisplayName,
          value: application.Key,
        })),
        key: "ApplicationKey",
        title: t("MODEL_APPLICATION"),
        render: (text: any, row: IResourceModel) => {
          return (
            <Tag color={row.ApplicationKey ? "#f50" : "#1890ff"}>
              {row.ApplicationKey}
            </Tag>
          );
        },
      },
      {
        dataIndex: "PlatformCode",
        filteredValue: filter.PlatformCodes || null,
        filters: platforms.map((platform) => ({
          text: platform.DisplayName,
          value: platform.Code,
        })),
        key: "PlatformCode",
        title: t("MODEL_PLATFORM"),
        render: (text: any, row: IResourceModel) => {
          return (
            <Tag color={row.PlatformCode ? "#1890ff" : "#f50"}>
              {row.PlatformCode}
            </Tag>
          );
        },
      },
    ];
  };

  const onTableChange = (
    pagination: ITablePaginationConfig,
    filters: ITableFilter
  ) => {
    const newFilter: IResourcesSearchFilterModel = {
      ...filter,
      ApplicationKeys: filters.ApplicationKey?.length
        ? filters.ApplicationKey.map((row) => `${row}`)
        : undefined,
      ResourceKey: filters.ResourceKey?.length
        ? `${filters.ResourceKey[0]}`
        : undefined,
      ResourceValue: filters.ResourceValue?.length
        ? `${filters.ResourceValue[0]}`
        : undefined,
      LanguageCodes: filters.LanguageCode?.length
        ? filters.LanguageCode.map((row) => `${row}`)
        : undefined,
      PlatformCodes: filters.PlatformCode?.length
        ? filters.PlatformCode.map((row) => `${row}`)
        : undefined,
      PageSize: pagination.pageSize,
      PageNumber: pagination.current,
    };

    browseResource(newFilter);
  };

  const onSearch = (value: string) => {
    const newFilter: IResourcesSearchFilterModel = {
      ...filter,
      FullTextSearch: value,
      PageNumber: 1,
    };

    browseResource(newFilter);
  };

  const tablePagination: ITablePaginationConfig = {
    pageSize: filter?.PageSize,
    total: resourcesBrowser.TotalCount,
    current: filter?.PageNumber,
    showTotal: (total, range) =>
      t("TABLE_PAGINATION_TOTAL", {
        rangeFrom: range[0],
        rangeTo: range[1],
        total: total,
      }),
    showSizeChanger: true,
    defaultPageSize: 10,
    pageSizeOptions: ["10", "30", "50", "100"],
  };
  const columns = getColumnsProps();

  const onCancelClick = () => {
    const { onCancel } = props;

    if (onCancel) {
      onCancel();
    }
  };

  const onSelectClick = () => {
    const { onSelect } = props;

    if (onSelect && selectedRow) {
      onSelect(selectedRow);
    }
  };

  const onTableRow = (row: IResourceModel) => {
    return {
      onClick: () => {
        setSelectedRow(row);
      },
      onDoubleClick: () => {
        const { onSelect } = props;

        if (onSelect) {
          onSelect(row);
        } else {
          setSelectedRow(row);
        }
      },
    };
  };

  const rowSelection: ITableRowSelection<IResourceModel> = {
    onChange: (
      selectedRowKeys: React.Key[],
      selectedRows: IResourceModel[]
    ) => {
      if (selectedRows.length > 0) {
        setSelectedRow(selectedRows[0]);
      }
    },
    type: "radio",
    selectedRowKeys: selectedRow
      ? [
          `${selectedRow.ResourceKey}${selectedRow.ApplicationKey}${selectedRow.PlatformCode}${selectedRow.LanguageCode}`,
        ]
      : [],
  };

  const hasSelectedRow = !!selectedRow;

  const rowKey = (row: IResourceModel) =>
    `${row.ResourceKey}${row.ApplicationKey}${row.PlatformCode}${row.LanguageCode}`;

  return (
    <>
      <Modal
        title={
          <Heading
            title={t("MODEL_TRANSLATION_KEY_PLACEHOLDER")}
            actions={[
              <InputSearch
                key="search"
                placeholder={t("SEARCH_PLACEHOLDER")}
                defaultValue={filter?.FullTextSearch}
                onSearch={onSearch}
                style={{ width: 250 }}
                allowClear
              />,
              <Button
                key="reload"
                icon={<Icon type="reload" />}
                onClick={onRefreshClick}
                title={t("BUTTON_REFRESH_TITLE")}
              />,
              <Dropdown key="more-options" overlay={_additionalOptionsMenu}>
                <Button key="option-more" icon={<Icon type="more" />} />
              </Dropdown>,
            ]}
          />
        }
        onCancel={onCancelClick}
        visible={props.visible}
        className="TranslationsBrowserModal"
        closable={false}
        centered={true}
        width="70%"
        footer={
          <>
            <Button key="cancel" onClick={onCancelClick}>
              {t("BUTTON_CANCEL")}
            </Button>
            <Button
              key="select"
              disabled={!hasSelectedRow}
              onClick={onSelectClick}
            >
              {t("BUTTON_SELECT")}
            </Button>
          </>
        }
      >
        <Table<IResourceModel>
          rowKey={rowKey}
          style={{ minHeight: "40vh" }}
          columns={columns}
          dataSource={resourcesBrowser.Entities}
          loading={resourcesBrowser.IsLoading}
          pagination={tablePagination}
          onChange={onTableChange}
          rowSelection={rowSelection}
          onRow={onTableRow}
        />
      </Modal>
    </>
  );
};
