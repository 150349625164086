import { SignalRErrorModel } from "../../models";
import * as Consts from "./consts";
import { SignalRTypes } from "./types";

export const signalR = (data?: any): SignalRTypes => {
  return {
    data,
    type: Consts.SIGNALR,
  };
};

export const signalRSuccess = (data?: any): SignalRTypes => {
  return {
    data,
    type: Consts.SIGNALR_SUCCESS,
  };
};

export const signalRFailure = (error: SignalRErrorModel): SignalRTypes => {
  return {
    error,
    type: Consts.SIGNALR_ERROR,
  };
};

export const Actions = {
  signalR,
  signalRFailure,
  signalRSuccess,
};
