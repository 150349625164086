import {
  IPlaylistSourceModel,
  IAssetCollectionSearchFilterModel,
  IPlaylistSourceListModel,
  IErrorModel,
  useDataLoader,
  DataProvider,
  ServiceResponse,
  PlayListSourceTypeHelper,
} from "@bms/common-services";
import {
  ITableColumnProps,
  ITableFilter,
  ITablePaginationConfig,
  ITableRowSelection,
  Table,
} from "@bms/common-ui";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export interface IListComponentSourcePredefinedTableProps {
  isActive: boolean;
  onSelect?: (row: IPlaylistSourceModel) => void;
  onChoose?: (row: IPlaylistSourceModel) => void;
}

export const ListComponentSourcePredefinedTable: React.FC<IListComponentSourcePredefinedTableProps> = ({
  isActive,
  onChoose,
  onSelect,
}: IListComponentSourcePredefinedTableProps) => {
  const { t } = useTranslation();
  const [selectedRow, setSelectedRow] = useState<IPlaylistSourceModel | null>();
  const [filter, setFilter] = useState<IAssetCollectionSearchFilterModel>({
    PageNumber: 1,
    PageSize: 10,
    IncludeCount: true,
  });

  const dataLoader = useDataLoader<IPlaylistSourceListModel, IErrorModel>({
    loader: async (): Promise<
      ServiceResponse<IPlaylistSourceListModel, IErrorModel>
    > => {
      if (!DataProvider.ApplicationConfiguration?.browsePredefinedPlaylist) {
        return Promise.resolve({
          ok: true,
          data: {
            Entities: [],
            TotalCount: 0,
          },
        });
      }

      try {
        const data = await DataProvider.ApplicationConfiguration?.browsePredefinedPlaylist(
          filter
        );

        return Promise.resolve({
          ok: true,
          data,
        });
      } catch (err) {
        return Promise.reject({
          ok: false,
          error: err,
        });
      }
    },
    deps: [filter],
  });

  useEffect(() => {
    if (!isActive && selectedRow) {
      setSelectedRow(null);
    }
  }, [isActive]);

  const getColumnsProps = (): Array<
    ITableColumnProps<IPlaylistSourceModel>
  > => {
    return [
      {
        key: "Name",
        dataIndex: "Name",
        ellipsis: true,
        title: t("MODEL_NAME"),
      },
      {
        key: "Description",
        dataIndex: "Description",
        ellipsis: true,
        title: t("MODEL_DESCRIPTION"),
      },
      {
        key: "Type",
        dataIndex: "Type",
        width: "150px",
        title: t("MODEL_TYPE"),
        render: (_: any, row: IPlaylistSourceModel) => {
          return (
            <span>{PlayListSourceTypeHelper.getDescription(row.Type)}</span>
          );
        },
      },
    ];
  };

  const onTableChange = (
    pagination: ITablePaginationConfig,
    filters: ITableFilter
  ) => {
    const newFilter: IAssetCollectionSearchFilterModel = {
      ...filter,
      Name: filters.Name?.length ? `${filters.Name[0]}` : undefined,
      Description: filters.Description?.length
        ? `${filters.Description[0]}`
        : undefined,
      PageSize: pagination.pageSize,
      PageNumber: pagination.current,
    };

    setFilter(newFilter);
  };

  const tablePagination: ITablePaginationConfig = {
    pageSize: filter?.PageSize,
    total: dataLoader.data?.TotalCount,
    current: filter?.PageNumber,
    showTotal: (total, range) =>
      t("TABLE_PAGINATION_TOTAL", {
        rangeFrom: range[0],
        rangeTo: range[1],
        total: total,
      }),
    showSizeChanger: true,
    defaultPageSize: 10,
    pageSizeOptions: ["10", "30", "50", "100"],
  };
  const columns = getColumnsProps();

  const onTableRow = (row: IPlaylistSourceModel) => {
    return {
      onClick: (_: React.MouseEvent) => {
        setSelectedRow(row);
        onSelect?.(row);
      },
      onDoubleClick: (_: React.MouseEvent) => {
        setSelectedRow(row);
        onSelect?.(row);
        onChoose?.(row);
      },
    };
  };

  const rowSelection: ITableRowSelection<IPlaylistSourceModel> = {
    onChange: (_: React.Key[], selectedRows: IPlaylistSourceModel[]) => {
      if (selectedRows.length > 0) {
        setSelectedRow(selectedRows[0]);
      }
    },
    type: "radio",
    selectedRowKeys: selectedRow?.Id ? [selectedRow.Id] : [],
  };

  return (
    <Table<IPlaylistSourceModel>
      rowKey="Id"
      columns={columns}
      dataSource={dataLoader.data?.Entities}
      loading={dataLoader.loading}
      pagination={tablePagination}
      onChange={onTableChange}
      rowSelection={rowSelection}
      onRow={onTableRow}
      style={{ height: 400 }}
      scroll={{ y: 300 }}
    />
  );
};
