import { SectionGrid, SectionGridItem } from "@bms/common-ui";
import {
  IServiceCallerState,
  ITestApplicationModel,
} from "@bms/common-services";
import { TestApplicationEditForm } from "./TestApplicationEditForm";

interface TestApplicationDetailsTabProps {
  testApplication?: ITestApplicationModel;
  updateState?: IServiceCallerState<void>;
  updateTestApplication: (
    testApplication: ITestApplicationModel
  ) => Promise<void>;
}
export const TestApplicationDetailsTab = (
  props: TestApplicationDetailsTabProps
) => {
  const { testApplication, updateTestApplication, updateState } = props;

  return (
    <SectionGrid>
      <SectionGridItem processing={updateState?.processing}>
        {testApplication && (
          <TestApplicationEditForm
            key={testApplication.RowVersion}
            testApplication={testApplication}
            updateTestApplication={updateTestApplication}
          />
        )}
      </SectionGridItem>
    </SectionGrid>
  );
};
