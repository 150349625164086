import React from "react";
import cx from "classnames";
import "./PageContent.scss";
import { forwardRef } from "react";

export interface IPageContentProps {
  children?: React.ReactNode;
  footer?: React.ReactNode;
  className?: string;
}

export const PageContent = forwardRef<HTMLDivElement, IPageContentProps>(
  ({ children, footer, className }, ref) => {
    return (
      <div ref={ref} className={cx("PageContent", className)}>
        <div>{children}</div>
        {footer}
      </div>
    );
  }
);
