import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ITestApplicationModel,
  ITestDeviceModel,
  ITestExecutionModel,
  PlatformType,
} from "@bms/common-services";
import { Col, Form, IFormValues, Row } from "@bms/common-ui";
import { TestApplicationField } from "./TestApplicationField";
import { TestApplicationVersionField } from "./TestApplicationVersionField";
import { TestDeviceField } from "./TestDeviceField";

interface TestDefinitionExecutionFormProps {
  testId: number;
  platforms: PlatformType[];
  setVisibilityStatus: (visible: boolean) => void;
  createExecutions: (data: ITestExecutionModel[]) => Promise<boolean>;
}

const APPLICATION_FIELD_NAME = "SelectedApplication";
const VERSION_FIELD_NAME = "SelectedApplicationVersion";
const DEVICES_FIELD_NAME = "SelectedDevices";

export const TestDefinitionExecutionForm = (
  props: TestDefinitionExecutionFormProps
) => {
  const { testId, platforms, setVisibilityStatus, createExecutions } = props;

  const [selectedApplication, setSelectedApplication] = useState<
    ITestApplicationModel
  >();

  const [form] = Form.useForm();
  const { t } = useTranslation();

  const selectedApplicationId = selectedApplication?.Id;
  const selectedApplicationPlatform = selectedApplication?.PlatformCode;

  useEffect(() => {
    // We want to reset selected application version when application change
    selectedApplicationId && form.resetFields([VERSION_FIELD_NAME]);
  }, [selectedApplicationId]);

  useEffect(() => {
    // We want to reset selected devices when selected application change
    // to different platform
    // TODO: If device minimum os version will be validated, we also want to
    //  check and reset for that case
    selectedApplicationPlatform && form.resetFields([DEVICES_FIELD_NAME]);
  }, [selectedApplicationPlatform]);

  const onFinish = async (values: IFormValues) => {
    const SelectedApplication = values[APPLICATION_FIELD_NAME];
    const SelectedApplicationVersion = values[VERSION_FIELD_NAME];
    const SelectedDevices = values[DEVICES_FIELD_NAME];

    const commonData: Pick<
      ITestExecutionModel,
      "TestId" | "TestApplicationId" | "TestApplicationVersionId"
    > = {
      TestId: testId,
      TestApplicationId: Number(SelectedApplication.value),
      TestApplicationVersionId: Number(SelectedApplicationVersion.value),
    };

    // Create test execution for each device as separate entry
    const data = SelectedDevices.map(
      (device: ITestDeviceModel): ITestExecutionModel => ({
        Id: -1,
        TestDeviceId: device.Id,
        ...commonData,
      })
    );

    await createExecutions(data);

    setVisibilityStatus(false);
  };

  return (
    <Form
      name="TestDefinitionExecutionForm"
      form={form}
      onFinish={onFinish}
      layout="vertical"
    >
      <Row direction="column" justify="space-between" className="full-height">
        <Col>
          <TestApplicationField
            name={APPLICATION_FIELD_NAME}
            label={t("TESTING_TEST_DEFINITIONS_EXECUTION_APPLICATION")}
            placeholder={t(
              "TESTING_TEST_DEFINITIONS_EXECUTION_APPLICATION_PLACEHOLDER"
            )}
            platforms={platforms}
            onChange={setSelectedApplication}
          />
          <TestApplicationVersionField
            name={VERSION_FIELD_NAME}
            label={t("TESTING_TEST_DEFINITIONS_EXECUTION_APPLICATION_VERSION")}
            placeholder={t(
              "TESTING_TEST_DEFINITIONS_EXECUTION_APPLICATION_VERSION_PLACEHOLDER"
            )}
            applicationId={selectedApplicationId}
          />
          <TestDeviceField
            name={DEVICES_FIELD_NAME}
            label={t("TESTING_TEST_DEFINITIONS_EXECUTION_DEVICE")}
            placeholder={t(
              "TESTING_TEST_DEFINITIONS_EXECUTION_DEVICE_PLACEHOLDER"
            )}
            platformCode={selectedApplicationPlatform}
          />
        </Col>
      </Row>
    </Form>
  );
};
