import React from "react";
import { Modal } from "@bms/common-ui";
import { IApplicationScreenModel, ScreenType } from "@bms/common-services";
import { ApplicationRegisterPreview } from "../ApplicationRegisterPreview";
import { ApplicationRegisterSuccessPreview } from "../ApplicationRegisterSuccessPreview";
import { LayoutOptionsModel } from "../../models";
import { ApplicationIntroPreview } from "../ApplicationIntroPreview";

export interface IScreenPreviewProps {
  screen: IApplicationScreenModel;
  layoutOptions: LayoutOptionsModel;
  children?: React.ReactNode;
}

export const ScreenPreview: React.FC<IScreenPreviewProps> = ({
  screen,
  layoutOptions,
  children,
}: IScreenPreviewProps) => {
  switch (screen.ScreenTypeCode) {
    case ScreenType.Intro:
      return (
        <ApplicationIntroPreview screen={screen} layoutOptions={layoutOptions}>
          {children}
        </ApplicationIntroPreview>
      );
    case ScreenType.Register:
      return (
        <ApplicationRegisterPreview
          screen={screen}
          layoutOptions={layoutOptions}
        >
          {children}
        </ApplicationRegisterPreview>
      );
    case ScreenType.RegisterSuccess:
      return (
        <ApplicationRegisterSuccessPreview
          screen={screen}
          layoutOptions={layoutOptions}
        >
          {children}
        </ApplicationRegisterSuccessPreview>
      );
    default:
      return <>{children}</>;
  }
};
