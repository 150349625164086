import {
  ITestApplicationModel,
  TestApplicationsService,
  useDataLoader,
  useServiceCaller,
} from "@bms/common-services";
import { useAppFeedback, useSyncedState } from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { ROUTES } from "../../constants";

interface TestApplicationParams {
  applicationId: number;
}

const testApplicationsService = new TestApplicationsService().promisify();

export const useTestApplication = (params: TestApplicationParams) => {
  const { applicationId } = params;

  const { t } = useTranslation();
  const history = useHistory();
  const { notification } = useAppFeedback();

  const { data, loading, refresh } = useDataLoader({
    loader: () => testApplicationsService.get(applicationId),
    onError: (error) => {
      notification.error({
        message: t("LOADING_DATA_ERROR_MESSAGE"),
        description: error.Message,
      });
    },
    deps: [applicationId],
  });

  const [testApplication, setTestApplication] = useSyncedState(() => data, [
    data,
  ]);

  const [updateTestApplication, updateState] = useServiceCaller(
    async (data: ITestApplicationModel) => {
      const result = await testApplicationsService.update(data);

      if (result.ok) {
        setTestApplication(result.data);

        notification.success({
          message: t("TESTING_APPLICATIONS_UPDATE_SUCCESS"),
        });
      } else {
        notification.error({
          message: t("TESTING_APPLICATIONS_UPDATE_FAILURE"),
          description: result.error.Message,
        });
      }
    },
    []
  );

  const [deleteApplication] = useServiceCaller(
    async (application: ITestApplicationModel) => {
      const result = await testApplicationsService.delete(application);

      if (result.ok) {
        notification.success({
          message: t("TESTING_APPLICATIONS_DELETE_SUCCESS"),
        });
        history.push(ROUTES.APPLICATIONS);
      } else {
        notification.error({
          message: t("TESTING_APPLICATIONS_DELETE_ERROR"),
          description: result.error?.Message,
        });
      }
      return result.ok;
    },
    []
  );

  return {
    testApplication,
    loading,
    refresh,
    updateTestApplication,
    updateState,
    deleteApplication,
  };
};
