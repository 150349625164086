import React from "react";
import { Trans } from "react-i18next";

import { REGEX } from "../../../";
import { FormFieldRule } from "../types";

export const urlValid = (): FormFieldRule => {
  const messageError: React.ReactElement = (
    <Trans i18nKey="URL_VALIDATION_MESSAGE">The url is invalid</Trans>
  );

  return {
    message: messageError,
    pattern: new RegExp(REGEX.URL),
  };
};
