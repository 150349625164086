import { Observable, Observer } from "rxjs";
import { HTTP_METHOD } from "../../constants/http";
import {
  IUserInAssetEventInsertManyModel,
  IUserInAssetEventModel,
  IUsersInAssetEventListModel,
  IUsersInAssetEventSearchFilterModel,
} from "../../models";
import { AxiosSubscriber } from "../AxiosSubscriber";
import { SimpleServiceBase } from "../Base";

export class UsersInAssetEventService extends SimpleServiceBase<
  IUserInAssetEventModel,
  IUsersInAssetEventListModel,
  number,
  IUsersInAssetEventSearchFilterModel
> {
  get url(): string {
    return "/UsersInAssetEvent";
  }

  public inviteMany = (
    friends: IUserInAssetEventInsertManyModel
  ): Observable<IUserInAssetEventModel[]> =>
    new Observable(
      (observer: Observer<IUserInAssetEventModel[]>) =>
        new AxiosSubscriber(observer, {
          data: friends,
          method: HTTP_METHOD.POST,
          url: `${this.url}/InviteMany`,
        })
    );

  public inviteFriends = (
    assetEventId: number
  ): Observable<IUserInAssetEventModel[]> =>
    new Observable(
      (observer: Observer<IUserInAssetEventModel[]>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: {
              assetEventId,
            },
          },
          method: HTTP_METHOD.POST,
          url: `${this.url}/InviteFriends`,
        })
    );

  public join = (assetEventId: number): Observable<any> =>
    new Observable(
      (observer: Observer<any>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: { assetEventId },
          },
          method: HTTP_METHOD.GET,
          url: `${this.url}/Join`,
        })
    );

  public abandonEvent = (assetEventId: number): Observable<undefined> =>
    new Observable(
      (observer: Observer<undefined>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: { assetEventId },
          },
          method: HTTP_METHOD.DELETE,
          url: `${this.url}/AbandonEvent`,
        })
    );
}
