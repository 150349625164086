import { IApplicationScreenTypeModel } from "@bms/common-services";
import {
  IBreadcrumbProps,
  ITableColumnProps,
  ITableFilter,
  ITablePaginationConfig,
  PageContent,
  PageHeader,
  Table,
} from "@bms/common-ui";
import { ROUTES } from "../../../../constants";
import { generateBreadcrumb } from "../../../../helpers";
import React from "react";
import { WithTranslation } from "react-i18next";
import { Action, ActionCreator } from "redux";
import "./ApplicationScreensTypesList.scss";

export interface IApplicationScreensTypesListProps extends WithTranslation {
  screensTypes: IApplicationScreenTypeModel[];
  isLoadingData: boolean;
  selectScreensTypes: ActionCreator<Action>;
}

interface IApplicationScreensTypesListState {
  filters?: ITableFilter;
}

export class ApplicationScreensTypesList extends React.PureComponent<
  IApplicationScreensTypesListProps,
  IApplicationScreensTypesListState
> {
  public state: Readonly<IApplicationScreensTypesListState> = {};

  public componentDidMount() {
    const { selectScreensTypes } = this.props;

    selectScreensTypes();
  }

  private getColumnsProps(): Array<
    ITableColumnProps<IApplicationScreenTypeModel>
  > {
    const { t } = this.props;
    const { filters } = this.state;

    return [
      {
        key: "Code",
        dataIndex: "Code",
        title: t("MODEL_CODE"),
        filteredValue: filters?.Code,
        onFilter: (value, record) => record.Code.includes(`${value}`),
      },
      {
        key: "Name",
        dataIndex: "Name",
        title: t("MODEL_NAME"),
        filteredValue: filters?.Name,
        onFilter: (value, record) => record.Name.includes(`${value}`),
      },
      {
        key: "Description",
        dataIndex: "Description",
        title: t("MODEL_DESCRIPTION"),
        filteredValue: filters?.Description,
        onFilter: (value, record) =>
          record.Description?.includes(`${value}`) || false,
        ellipsis: true,
      },
    ];
  }

  public onTableChange = (
    pagination: ITablePaginationConfig,
    filters: ITableFilter
  ) => {
    this.setState({ filters });
  };

  public getBreadcrumbProps(): IBreadcrumbProps {
    const { t } = this.props;
    return generateBreadcrumb([
      {
        path: `${ROUTES.CONFIGURATION}`,
        breadcrumbName: t("APPLICATION_CONFIGURATION_TITLE"),
      },
      {
        path: `${ROUTES.CONFIGURATION_SCREEN_TYPES_LIST}`,
        breadcrumbName: t("APPLICATION_SCREENSTYPES__TITLE"),
      },
    ]);
  }

  public render() {
    const { screensTypes, isLoadingData, t } = this.props;

    const columns = this.getColumnsProps();

    return (
      <PageContent>
        <PageHeader
          title={t("APPLICATION_SCREENSTYPES__TITLE")}
          breadcrumb={this.getBreadcrumbProps()}
        />
        <Table<IApplicationScreenTypeModel>
          rowKey={(row) => row.Code}
          columns={columns}
          dataSource={screensTypes}
          loading={isLoadingData}
          pagination={false}
          onChange={this.onTableChange}
        />
      </PageContent>
    );
  }
}
