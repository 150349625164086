import {
  ApplicationConfigurationStore,
  IApplicationConfigurationBrandingKeyTypeModel,
  IApplicationConfigurationBrandingModel,
  RecordStatus,
} from "@bms/common-services";
import { Checkbox, Form, IFormValues, Input, required } from "@bms/common-ui";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ActionButtons } from "../Buttons";

export interface IBrandingFormProps {
  brandData: IApplicationConfigurationBrandingModel;
  brandingsKeys?: IApplicationConfigurationBrandingKeyTypeModel[];
  handleClose: () => void;
}

const formItemLayout = {
  labelCol: {
    lg: { span: 10 },
    md: { span: 10 },
    sm: { span: 10 },
    xs: { span: 24 },
  },
  wrapperCol: {
    lg: { span: 14 },
    md: { span: 14 },
    sm: { span: 14 },
    xs: { span: 24 },
  },
};

export const BrandingForm: React.FC<IBrandingFormProps> = (props) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { brandData, handleClose } = props;
  const brandExist = brandData?.RecordStatus !== RecordStatus.Inserted;

  const updateBranding = useCallback(
    (branding: IApplicationConfigurationBrandingModel) =>
      dispatch(ApplicationConfigurationStore.Actions.updateBranding(branding)),
    [dispatch]
  );

  const insertBranding = useCallback(
    (branding: IApplicationConfigurationBrandingModel) =>
      dispatch(ApplicationConfigurationStore.Actions.insertBranding(branding)),
    [dispatch]
  );

  const deleteBranding = useCallback(
    (branding: IApplicationConfigurationBrandingModel) =>
      dispatch(ApplicationConfigurationStore.Actions.deleteBranding(branding)),
    [dispatch]
  );

  const onDelete = () => {
    const brandToDelete = {
      ...brandData,
      RecordStatus: RecordStatus.Deleted,
    };

    deleteBranding(brandToDelete);
  };

  const onFinish = (formValues: IFormValues) => {
    const brandToSubmit = {
      ...brandData,
      ...formValues,
      RecordStatus: brandExist ? RecordStatus.Updated : RecordStatus.Inserted,
    };

    brandExist ? updateBranding(brandToSubmit) : insertBranding(brandToSubmit);
  };

  return (
    <Form
      {...formItemLayout}
      form={form}
      initialValues={{
        ...brandData,
        Sequence: `${brandData.Sequence}`,
      }}
      onFinish={onFinish}
    >
      <Form.Item
        name="Name"
        label={t("BRANDING_FORM_NAME")}
        rules={[required()]}
      >
        <Input placeholder={t("BRANDING_FORM_ENTER_VALUE")} />
      </Form.Item>
      <Form.Item
        name="IsDefault"
        label={t("BRANDING_IS_DEFAULT")}
        valuePropName="checked"
      >
        <Checkbox />
      </Form.Item>
      <ActionButtons
        brandExist={brandExist}
        onCancel={handleClose}
        onDelete={onDelete}
      />
    </Form>
  );
};
