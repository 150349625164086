import React from "react";

export const Filter = () => (
  <svg
    width="15"
    height="9"
    viewBox="0 0 15 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 8.125C5 7.95924 5.06585 7.80027 5.18306 7.68306C5.30027 7.56585 5.45924 7.5 5.625 7.5H9.375C9.54076 7.5 9.69973 7.56585 9.81694 7.68306C9.93415 7.80027 10 7.95924 10 8.125C10 8.29076 9.93415 8.44973 9.81694 8.56694C9.69973 8.68415 9.54076 8.75 9.375 8.75H5.625C5.45924 8.75 5.30027 8.68415 5.18306 8.56694C5.06585 8.44973 5 8.29076 5 8.125ZM2.5 4.375C2.5 4.20924 2.56585 4.05027 2.68306 3.93306C2.80027 3.81585 2.95924 3.75 3.125 3.75H11.875C12.0408 3.75 12.1997 3.81585 12.3169 3.93306C12.4342 4.05027 12.5 4.20924 12.5 4.375C12.5 4.54076 12.4342 4.69973 12.3169 4.81694C12.1997 4.93415 12.0408 5 11.875 5H3.125C2.95924 5 2.80027 4.93415 2.68306 4.81694C2.56585 4.69973 2.5 4.54076 2.5 4.375ZM0 0.625C0 0.45924 0.0658481 0.300269 0.183058 0.183058C0.300269 0.0658481 0.45924 0 0.625 0H14.375C14.5408 0 14.6997 0.0658481 14.8169 0.183058C14.9342 0.300269 15 0.45924 15 0.625C15 0.79076 14.9342 0.949731 14.8169 1.06694C14.6997 1.18415 14.5408 1.25 14.375 1.25H0.625C0.45924 1.25 0.300269 1.18415 0.183058 1.06694C0.0658481 0.949731 0 0.79076 0 0.625Z"
      fill="white"
    />
  </svg>
);
