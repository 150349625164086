import { AxiosError, AxiosResponse } from "axios";
import { Observer, Subscriber } from "rxjs";
import { HTTP_METHOD, HTTP_RESPONSE_TYPE, IRequestConfig } from "../constants";
import { IErrorModel, toErrorModel } from "../models";
import { HttpClient, HttpFactory } from "./Http";

export class AxiosSubscriber extends Subscriber<any> {
  public httpClient: HttpClient;

  constructor(observer: Observer<any>, requestConfig: IRequestConfig) {
    super(observer);
    this.httpClient = this.httpFactory.getHttpClient(requestConfig.baseURL);

    switch (requestConfig.method) {
      case HTTP_METHOD.GET:
        this.makeGetRequest(observer, requestConfig);
        break;
      case HTTP_METHOD.POST:
        this.makePostRequest(observer, requestConfig);
        break;
      case HTTP_METHOD.PUT:
        this.makePutRequest(observer, requestConfig);
        break;
      case HTTP_METHOD.PATCH:
        this.makePatchRequest(observer, requestConfig);
        break;
      case HTTP_METHOD.DELETE:
        this.makeDeleteRequest(observer, requestConfig);
        break;
      case HTTP_METHOD.HEAD:
        this.makeHeadRequest(observer, requestConfig);
        break;
    }
  }

  get httpFactory(): HttpFactory {
    return HttpFactory.getInstance();
  }

  public unsubscribe() {
    super.unsubscribe();
    this.httpClient
      .cancel()
      .then(() => null)
      .catch((err: any) => console.warn(err));
  }

  private makeGetRequest(
    observer: Observer<any>,
    { axiosConfig = {}, url, entireResponse }: IRequestConfig<void>
  ) {
    this.httpClient
      .get(url, axiosConfig)
      .then((response: AxiosResponse<any>) => {
        if (this._checkResponseError(response)) {
          observer.error(toErrorModel(response.data));
        } else {
          observer.next(entireResponse ? response : response.data);
          observer.complete();
        }
      })
      .catch((error: any) => {
        return this.getError(error).then((err) => observer.error(err));
      });
  }

  private makePostRequest(
    observer: Observer<any>,
    { axiosConfig = {}, data, url, entireResponse }: IRequestConfig<any>
  ) {
    this.httpClient
      .post(url, data, axiosConfig)
      .then((response: AxiosResponse<any>) => {
        if (this._checkResponseError(response)) {
          observer.error(toErrorModel(response.data));
        } else {
          observer.next(entireResponse ? response : response.data);
          observer.complete();
        }
      })
      .catch((error: AxiosError<any>) => {
        return this.getError(error).then((err) => observer.error(err));
      });
  }

  private makePutRequest(
    observer: Observer<any>,
    { axiosConfig = {}, data, url, entireResponse }: IRequestConfig<any>
  ) {
    this.httpClient
      .put(url, data, axiosConfig)
      .then((response: AxiosResponse<any>) => {
        if (this._checkResponseError(response)) {
          observer.error(toErrorModel(response.data));
        } else {
          observer.next(entireResponse ? response : response.data);
          observer.complete();
        }
      })
      .catch((error: AxiosError<any>) => {
        return this.getError(error).then((err) => observer.error(err));
      });
  }

  private makePatchRequest(
    observer: Observer<any>,
    { axiosConfig = {}, data, url, entireResponse }: IRequestConfig<any>
  ) {
    this.httpClient
      .patch(url, data, axiosConfig)
      .then((response: AxiosResponse<any>) => {
        observer.next(entireResponse ? response : response.data);
        observer.complete();
      })
      .catch((error: AxiosError<any>) => {
        return this.getError(error).then((err) => observer.error(err));
      });
  }

  private makeDeleteRequest(
    observer: Observer<any>,
    { axiosConfig = {}, url, entireResponse }: IRequestConfig<void>
  ) {
    this.httpClient
      .delete(url, axiosConfig)
      .then((response: AxiosResponse<any>) => {
        if (this._checkResponseError(response)) {
          observer.error(toErrorModel(response.data));
        } else {
          observer.next(entireResponse ? response : response.data);
          observer.complete();
        }
      })
      .catch((error: AxiosError<any>) => {
        return this.getError(error).then((err) => observer.error(err));
      });
  }

  private makeHeadRequest(
    observer: Observer<any>,
    { axiosConfig = {}, url, entireResponse }: IRequestConfig<any>
  ) {
    this.httpClient
      .head(url, axiosConfig)
      .then((response: AxiosResponse<any>) => {
        observer.next(entireResponse ? response : response.data);
        observer.complete();
      })
      .catch((error: AxiosError<any>) => {
        return this.getError(error).then((err) => observer.error(err));
      });
  }

  private _checkResponseError(response: AxiosResponse<any>): boolean {
    return response.data.ResultType === HTTP_RESPONSE_TYPE.ERROR;
  }

  private async getError(axiosError: AxiosError<any>): Promise<IErrorModel> {
    const { config, response } = axiosError;
    let error: IErrorModel = {};

    if (response) {
      if (config.responseType === "blob") {
        const responseText = await response.data.text();
        const responseJson = JSON.parse(responseText);
        error = {
          ...responseJson,
        };
      } else {
        error = {
          ...response.data,
        };
      }

      switch (response.status) {
        case 401:
          if (!error.Message) {
            error.Message = `Unauthorized access.`;
          }
          break;
        case 403:
          if (!error.Message) {
            error.Message = `You don't have permissions to access.`;
          }
          break;
      }

      return Promise.resolve(error);
    }

    return Promise.resolve({
      Message: axiosError.message,
    });
  }
}
