import { createPayloadAction } from "../../helpers";
import {
  IErrorModel,
  IRatingCategoryFilterModel,
  IRatingCategoryListModel,
  IRatingCategoryModel,
} from "../../models";
import {
  RATING_CATEGORIES_SEARCH,
  RATING_CATEGORIES_SEARCH_FAILURE,
  RATING_CATEGORIES_SEARCH_SUCCESS,
  RATING_CATEGORY_DELETE,
  RATING_CATEGORY_DELETE_FAILURE,
  RATING_CATEGORY_DELETE_SUCCESS,
  RATING_CATEGORY_INSERT,
  RATING_CATEGORY_INSERT_FAILURE,
  RATING_CATEGORY_INSERT_SUCCESS,
  RATING_CATEGORY_UPDATE,
  RATING_CATEGORY_UPDATE_FAILURE,
  RATING_CATEGORY_UPDATE_SUCCESS,
} from "./consts";

export const searchRatingCategories = createPayloadAction<
  IRatingCategoryFilterModel
>(RATING_CATEGORIES_SEARCH);

export const searchRatingCategoriesSuccess = createPayloadAction<
  IRatingCategoryListModel
>(RATING_CATEGORIES_SEARCH_SUCCESS);

export const searchRatingCategoriesFailure = createPayloadAction<IErrorModel>(
  RATING_CATEGORIES_SEARCH_FAILURE
);

export const deleteRatingCategory = createPayloadAction<IRatingCategoryModel>(
  RATING_CATEGORY_DELETE
);

export const deleteRatingCategorySuccess = createPayloadAction<void>(
  RATING_CATEGORY_DELETE_SUCCESS
);

export const deleteRatingCategoryFailure = createPayloadAction<IErrorModel>(
  RATING_CATEGORY_DELETE_FAILURE
);

export const insertRatingCategory = createPayloadAction<IRatingCategoryModel>(
  RATING_CATEGORY_INSERT
);

export const insertRatingCategorySuccess = createPayloadAction<void>(
  RATING_CATEGORY_INSERT_SUCCESS
);

export const insertRatingCategoryFailure = createPayloadAction<IErrorModel>(
  RATING_CATEGORY_INSERT_FAILURE
);

export const updateRatingCategory = createPayloadAction<IRatingCategoryModel>(
  RATING_CATEGORY_UPDATE
);

export const updateRatingCategorySuccess = createPayloadAction<void>(
  RATING_CATEGORY_UPDATE_SUCCESS
);

export const updateRatingCategoryFailure = createPayloadAction<IErrorModel>(
  RATING_CATEGORY_UPDATE_FAILURE
);
