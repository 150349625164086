import {
  isAnonymous,
  isAuthenticated,
} from "../../helpers/authorization.helper";
import { StorageManager } from "../../services/StorageManager";
import * as Consts from "./consts";
import { AuthActionsTypes, IAuthState } from "./types";

export const initialState: IAuthState = {
  isAnonymous: isAnonymous(),
  isAuthenticated: isAuthenticated(),
  isFetching: false,
  isProcessing: false,
  isTokenValid: false,
  session: StorageManager.getValue("session"),
  user: StorageManager.getValue("user"),
};

export const authReducer = (
  state = initialState,
  action: AuthActionsTypes
): IAuthState => {
  state.actionType = action.type;

  switch (action.type) {
    case Consts.SIGN_IN:
    case Consts.SIGN_IN_ANONYMOUS:
    case Consts.REGISTER_EMAIL:
    case Consts.REGISTER_CONFIRM_EMAIL: {
      return {
        ...state,
        error: initialState.error,
        isFetching: true,
      };
    }
    case Consts.SIGN_IN_SUCCESS:
    case Consts.REGISTER_CONFIRM_EMAIL_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        error: initialState.error,
        isAnonymous: false,
        isAuthenticated: true,
        isFetching: initialState.isFetching,
      };
    }
    case Consts.SIGN_IN_ANONYMOUS_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        error: initialState.error,
        isAnonymous: true,
        isAuthenticated: false,
        isFetching: initialState.isFetching,
      };
    }
    case Consts.REGISTER_EMAIL_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        error: initialState.error,
        isFetching: initialState.isFetching,
      };
    }
    case Consts.SIGN_IN_FAILURE:
    case Consts.SIGN_IN_ANONYMOUS_FAILURE:
    case Consts.RESET_PASSWORD_LINK_FAILURE:
    case Consts.REGISTER_EMAIL_FAILURE:
    case Consts.REGISTER_CONFIRM_EMAIL_FAILURE: {
      return {
        ...state,
        error: action.error,
        isFetching: initialState.isFetching,
        isProcessing: initialState.isProcessing,
      };
    }
    case Consts.SIGN_OUT_SUCCESS:
    case Consts.REFRESH_TOKEN_FAILURE: {
      return {
        ...state,
        isAuthenticated: false,
        session: undefined,
        user: undefined,
      };
    }
    case Consts.REFRESH_TOKEN_SUCCESS: {
      return {
        ...state,
        session: action.payload.session,
      };
    }
    case Consts.SET_PASSWORD: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case Consts.SET_PASSWORD_SUCCESS: {
      return {
        ...state,
        isFetching: false,
      };
    }
    case Consts.SET_PASSWORD_FAILURE: {
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    }
    case Consts.RESET_PASSWORD: {
      return {
        ...state,
        isFetching: true,
        resetPasswordResult: undefined,
      };
    }
    case Consts.RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        resetPasswordResult: action.payload,
      };
    }
    case Consts.RESET_PASSWORD_FAILURE: {
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    }

    case Consts.CHANGE_PASSWORD: {
      return {
        ...state,
        changePasswordResult: undefined,
        isFetching: true,
      };
    }
    case Consts.CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        changePasswordResult: action.payload,
        isFetching: false,
      };
    }
    case Consts.CHANGE_PASSWORD_FAILURE: {
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    }
    case Consts.VALIDATE_TOKEN: {
      return {
        ...state,
        isTokenValid: false,
        isProcessing: true,
      };
    }
    case Consts.VALIDATE_TOKEN_SUCCESS: {
      return {
        ...state,
        isTokenValid: true,
        isProcessing: false,
      };
    }
    case Consts.VALIDATE_TOKEN_FAILURE: {
      return {
        ...state,
        isTokenValid: false,
        isProcessing: false,
        error: action.error,
      };
    }
    case Consts.CLEAR_ERROR:
      return {
        ...state,
        error: initialState.error,
      };
    default:
      return state;
  }
};
