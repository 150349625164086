import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { AuthStore, IAuthState } from "@bms/common-services";
import { Button, Link, Spin } from "@bms/common-ui";
import { AuthContainer } from "../AuthContainer";
import { ChangeForgottenPasswordForm } from "../ChangeForgottenPasswordForm";
import { IAppState } from "../../../../store";
import { ROUTES } from "../../../../constants";

export function ChangeForgottenPassword() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [t] = useTranslation();
  const { isTokenValid, isProcessing } = useSelector<IAppState, IAuthState>(
    (state) => state.auth
  );

  const token = useMemo(
    () => new URLSearchParams(location.search).get("token"),
    [location.search]
  );
  const email = useMemo(
    () => new URLSearchParams(location.search).get("email"),
    [location.search]
  );

  useEffect(
    () => () => {
      dispatch(AuthStore.Actions.clearError());
    },
    [dispatch]
  );

  useEffect(() => {
    if (token) {
      dispatch(AuthStore.Actions.validateToken(token));
    }
  }, [token, dispatch]);

  let error: string | undefined;
  if (!token) {
    error = "Missing token";
  } else if (!email) {
    error = "Missing email";
  } else if (!isTokenValid) {
    error = "Token expired";
  }

  if (!isTokenValid || !email || !token) {
    return (
      <AuthContainer
        error={error}
        footerLink={{
          href: ROUTES.LOGIN,
          title: t("FORGOT_PASSWORD_BACK_TO_LOGIN_LINK"),
        }}
      >
        <div style={{ textAlign: "center" }}>
          <Link to={ROUTES.RESET_PASSWORD}>
            <Button type="primary">
              {t("FORGOT_PASSWORD_RESET_TRY_AGAIN")}
            </Button>
          </Link>
        </div>
      </AuthContainer>
    );
  }

  if (isProcessing) {
    return (
      <AuthContainer>
        <Spin />
      </AuthContainer>
    );
  }

  return (
    <AuthContainer
      error={error}
      footerLink={{
        href: ROUTES.LOGIN,
        title: t("FORGOT_PASSWORD_BACK_TO_LOGIN_LINK"),
      }}
    >
      <ChangeForgottenPasswordForm email={email} token={token} />
    </AuthContainer>
  );
}
