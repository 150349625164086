import { Card as AntCard } from "antd";
import { CardProps as AntCardProps } from "antd/lib/card";
import React from "react";

export interface ICardProps extends AntCardProps {}

interface ICardComponent extends React.FC<ICardProps> {
  Grid: typeof AntCard.Grid;
  Meta: typeof AntCard.Meta;
}

const CardComponent: ICardComponent = (props: ICardProps) => {
  const { children, ...rest } = props;
  return <AntCard {...rest}>{children}</AntCard>;
};

CardComponent.Grid = AntCard.Grid;
CardComponent.Meta = AntCard.Meta;

export const Card = CardComponent;
