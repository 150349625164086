import React from "react";
import { Form, IFormInstance } from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { IAssetFormFieldCommonProps } from "./IAssetFormFieldCommonProps";
import { LazyChoose } from "../../../../../components";
import {
  CountriesService,
  CountryModel,
  IAssetCountryModel,
  IListModelBase,
  useConfig,
} from "@bms/common-services";

interface IGeoAllowFieldProps extends IAssetFormFieldCommonProps {
  form: IFormInstance;
}

const countriesService = new CountriesService();

let countries: CountryModel[];

export const GeoAllowField = ({
  asset,
  isHidden,
  isEditMode,
  form,
}: IGeoAllowFieldProps) => {
  const { t } = useTranslation();
  const { config } = useConfig();

  if (isHidden || !config?.Geolocation?.Enabled) {
    return null;
  }

  const onChange = () => {
    const countriesBlockedValue = form.getFieldsValue(["CountriesBlocked"]);
    if (countriesBlockedValue.CountriesBlocked.length > 0) {
      form.setFieldsValue({ CountriesBlocked: [] });
    }
  };

  return (
    <Form.Item
      name="CountriesAllowed"
      label={t("ASSET_FORM_GEO_ALLOWED_LABEL")}
      key="CountriesAllowed"
      initialValue={(isEditMode && asset?.CountriesAllowed) || []}
    >
      <LazyChoose<CountryModel, IAssetCountryModel>
        disableRecordStatus
        onChange={onChange}
        loader={async (search: string) => {
          if (!countries) {
            countries = await countriesService.select().toPromise();
          }
          const data: IListModelBase<CountryModel, {}> = {
            TotalCount: countries.length,
            Entities: countries.filter((country) =>
              country.Name?.toLowerCase().startsWith(search)
            ),
          };
          return {
            data,
            ok: true,
          };
        }}
        candidateToOption={(item) => ({
          label: item.Name,
          value: `${item.Id}`,
          item: {
            AssetId: asset?.Id!,
            CountryId: item.Id,
            CountryCode: item.Code,
            CountryName: item.Name,
            RowVersion: item.RowVersion,
          },
        })}
        selectedToOption={(item) => ({
          label: item.CountryName!,
          value: `${item.CountryId!}`,
          item,
        })}
      />
    </Form.Item>
  );
};
