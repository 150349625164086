import {
  ConfigurationActionsTypes,
  GET_CONFIGURATION,
  GET_CONFIGURATION_FAILURE,
  GET_CONFIGURATION_SUCCESS,
  IConfigState,
} from "./types";

export const initialState: IConfigState = {
  config: {},
  isLoading: false,
};

export const configReducer = (
  state = initialState,
  action: ConfigurationActionsTypes
): IConfigState => {
  switch (action.type) {
    case GET_CONFIGURATION: {
      return {
        ...state,
        error: initialState.error,
        isLoading: true,
      };
    }
    case GET_CONFIGURATION_SUCCESS: {
      return {
        ...state,
        config: action.payload,
        isLoading: false,
      };
    }
    case GET_CONFIGURATION_FAILURE: {
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};
