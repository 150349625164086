import {
  IErrorModel,
  ResetPasswordResponseModel,
  ResetPasswordRequestModel,
  ResetPasswordLinkResponseModel,
  ResetPasswordLinkRequestModel,
} from "../../models";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const RESET_PASSWORD_LINK = "RESET_PASSWORD_LINK";
export const RESET_PASSWORD_LINK_SUCCESS = "RESET_PASSWORD_LINK_SUCCESS";
export const RESET_PASSWORD_LINK_FAILURE = "RESET_PASSWORD_LINK_FAILURE";

export const RESET_PASSWORD_CLEAR = "RESET_PASSWORD_CLEAR";

export interface IResetPasswordState {
  response?: ResetPasswordResponseModel | ResetPasswordLinkResponseModel;
  error?: IErrorModel;
  isFetching?: boolean;
  email: any;
}

export interface IResetPasswordClearAction {
  type: typeof RESET_PASSWORD_CLEAR;
}

// Reset password
export interface IResetPasswordAction {
  type: typeof RESET_PASSWORD;
  data: ResetPasswordRequestModel;
  redirectUrl?: string;
}

export interface IResetPasswordSuccessAction {
  type: typeof RESET_PASSWORD_SUCCESS;
  payload: {
    redirectUrl?: string;
    response?: ResetPasswordResponseModel;
  };
}

export interface IResetPasswordFailureAction {
  type: typeof RESET_PASSWORD_FAILURE;
  error?: IErrorModel;
}

// Reset password link
export interface IResetPasswordLinkAction {
  type: typeof RESET_PASSWORD_LINK;
  data: ResetPasswordLinkRequestModel;
}

export interface IResetPasswordLinkSuccessAction {
  type: typeof RESET_PASSWORD_LINK_SUCCESS;
  payload: {
    response?: ResetPasswordLinkResponseModel;
  };
}

export interface IResetPasswordLinkFailureAction {
  type: typeof RESET_PASSWORD_LINK_FAILURE;
  error?: IErrorModel;
}

export type ResetPasswordActionsTypes =
  | IResetPasswordClearAction
  | IResetPasswordAction
  | IResetPasswordSuccessAction
  | IResetPasswordFailureAction
  | IResetPasswordLinkAction
  | IResetPasswordLinkSuccessAction
  | IResetPasswordLinkFailureAction;
