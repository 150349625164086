import { Observable, Observer } from "rxjs";
import { HTTP_METHOD } from "../../constants/http";
import {
  IQuizChangeStatusModel,
  IQuizModel,
  IQuizzesListModel,
  IQuizzesSearchFilterModel,
  ISimpleKeyModelBase,
} from "../../models";
import { AxiosSubscriber } from "../AxiosSubscriber";
import { SimpleServiceBase } from "../Base";

export class QuizService extends SimpleServiceBase<
  IQuizModel,
  IQuizzesListModel,
  number,
  IQuizzesSearchFilterModel
> {
  get url(): string {
    return "/Quizes";
  }

  public sendToReview = (data: IQuizChangeStatusModel): Observable<any> =>
    new Observable(
      (observer: Observer<any>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.PUT,
          url: `${this.url}/SendToReview`,
        })
    );

  public cancelReview = (data: ISimpleKeyModelBase): Observable<any> =>
    new Observable(
      (observer: Observer<any>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.PUT,
          url: `${this.url}/CancelReview`,
        })
    );

  public block = (data: ISimpleKeyModelBase): Observable<any> =>
    new Observable(
      (observer: Observer<any>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.PUT,
          url: `${this.url}/Block`,
        })
    );

  public unblock = (data: ISimpleKeyModelBase): Observable<any> =>
    new Observable(
      (observer: Observer<any>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.PUT,
          url: `${this.url}/Unblock`,
        })
    );

  public startReview = (data: ISimpleKeyModelBase): Observable<any> =>
    new Observable(
      (observer: Observer<any>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.PUT,
          url: `${this.url}/StartReview`,
        })
    );

  public approve = (data: IQuizChangeStatusModel): Observable<any> =>
    new Observable(
      (observer: Observer<any>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.PUT,
          url: `${this.url}/Approve`,
        })
    );

  public reject = (data: IQuizChangeStatusModel): Observable<any> =>
    new Observable(
      (observer: Observer<any>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.PUT,
          url: `${this.url}/Reject`,
        })
    );
}
