import {
  ICommonAppState,
  IResourceModel,
  PlatformType,
  IRatingCategoryModel,
} from "@bms/common-services";
import {
  Button,
  Choose,
  ChooseOption,
  Col,
  Form,
  IFormValues,
  Icon,
  Input,
  Link,
  Row,
  Spin,
  Switch,
  required,
} from "@bms/common-ui";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { ROUTES as TRANSLATIONS_ROUTES } from "../../../Translations/constants";
import { TranslationsBrowserModal } from "../../..//Translations/components/TranslationsBrowserModal";
import { PlatformTypeHelper } from "../../../../helpers";

interface RatingCategoryFormProps {
  ratingCategory: IRatingCategoryModel;
  setModalVisible: (visible: boolean) => void;
  createCategory: (data: IRatingCategoryModel) => Promise<boolean>;
  updateCategory: (data: IRatingCategoryModel) => Promise<boolean>;
  isLoading: boolean;
}

export const RatingCategoryForm = ({
  ratingCategory,
  setModalVisible,
  createCategory,
  updateCategory,
  isLoading,
}: RatingCategoryFormProps) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [translationKey, setTranslationKey] = useState(
    ratingCategory.TranslationKey || ""
  );
  const [translationsBrowserVisible, setTranslationsBrowserVisible] = useState(
    false
  );
  const platforms = useSelector(
    (state: ICommonAppState) => state.common.platforms
  );

  const isNewCategory = ratingCategory.Id < 0;
  const onFinish = async (values: IFormValues) => {
    const selectedPlatforms = platforms
      .filter((platformData) => values.Platforms.includes(platformData.Code))
      .map((selectedPlatform) => ({
        RowVersion: ratingCategory.RowVersion,
        PlatformCode: selectedPlatform.Code as PlatformType,
        PlatformDisplayName: selectedPlatform.DisplayName,
      }));
    const data: IRatingCategoryModel = {
      ...ratingCategory,
      ...values,
      TranslationKey: translationKey,
      Platforms: selectedPlatforms,
    };

    if (isNewCategory) {
      await createCategory(data);
    } else {
      await updateCategory(data);
    }

    setModalVisible(false);
  };

  const onTranslationsBrowserModalCancel = () =>
    setTranslationsBrowserVisible(false);

  const onTranslationsBrowserModalSelect = (resource: IResourceModel) => {
    setTranslationKey(resource.ResourceKey);
    setTranslationsBrowserVisible(false);
  };

  const renderNameTranslationKey = () => {
    const titleTranslationKeyView = translationKey ? (
      <label>
        <Link
          to={`${TRANSLATIONS_ROUTES.CONFIGURATION_TRANSLATION_DETAILS}/${translationKey}`}
        >
          {translationKey}
        </Link>
      </label>
    ) : (
      <label>{t("MODEL_TRANSLATION_KEY_PLACEHOLDER")}</label>
    );

    return (
      <Form.Item
        name="NameTranslationKey"
        label={t("RATING_CATEGORY_FORM__NAME_TRANSLATION_KEY")} //TODO: Add translation
        style={{ marginBottom: 0 }}
        required
        rules={[
          {
            validator: (_) => {
              const requiredValue = translationKey;
              if (!requiredValue) {
                return Promise.reject(
                  new Error(t("REQUIRED_VALIDATION_MESSAGE"))
                );
              }

              return Promise.resolve();
            },
          },
        ]}
      >
        <Row gutter={8}>
          <Col span={18}>
            <Form.Item>
              {titleTranslationKeyView}
              <Input
                hidden
                value={translationKey}
                placeholder={t(
                  "RATING_CATEGORY_FORM__TRANSLATION_KEY_PLACEHOLDER" //TODO: Add translation
                )}
              />
            </Form.Item>

            <TranslationsBrowserModal
              key={`TranslationsBrowser-${ratingCategory.Id}`}
              visible={translationsBrowserVisible}
              onCancel={onTranslationsBrowserModalCancel}
              onSelect={onTranslationsBrowserModalSelect}
            />
          </Col>
          <Col span={6} style={{ textAlign: "right" }}>
            {translationKey && (
              <Button
                icon={<Icon type="delete" />}
                onClick={() => {
                  setTranslationKey("");
                }}
                style={{ marginRight: "8px" }}
              />
            )}
            <Button
              icon={<Icon type="edit" />}
              onClick={() => setTranslationsBrowserVisible(true)}
            />
          </Col>
        </Row>
      </Form.Item>
    );
  };

  const renderPlatformsField = () => {
    const options: React.ReactNode[] = [];
    const platformOptions = platforms?.filter(
      (platform) => platform.IsRatingEnabled || platform.Code === "ANY"
    );

    for (const platform of platformOptions) {
      options.push(
        <ChooseOption key={platform.Code} value={platform.Code}>
          {platform.DisplayName}
        </ChooseOption>
      );
    }

    return (
      <Form.Item name="Platforms" label={t("MODEL_PLATFORMS")} key="Director">
        <Choose
          mode="multiple"
          tagRender={PlatformTypeHelper.getTagOption}
          placeholder={t("MODEL_PLATFORMS_PLACEHOLDER")}
          loading={isLoading}
        >
          {options}
        </Choose>
      </Form.Item>
    );
  };

  const renderUpToDate = () => {
    return (
      <Form.Item>
        <Row gutter={8}>
          <Col>
            <Form.Item name="UpToDate" valuePropName="checked">
              <Switch />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="UpToDate">
              <label>{t("MODEL_UP_TO_DATE")}</label>
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>
    );
  };

  const renderCodeInput = () => {
    return (
      <Form.Item
        name="Code"
        label={t("MODEL_CODE", "Code")}
        rules={[required()]}
      >
        <Input placeholder={t("MODEL_CODE", "Code")} />
      </Form.Item>
    );
  };

  const renderNameInput = () => {
    return (
      <Form.Item
        name="Name"
        label={t("MODEL_NAME", "Name")}
        rules={[required()]}
      >
        <Input placeholder={t("MODEL_Name", "Name")} />
      </Form.Item>
    );
  };

  return (
    <Spin spinning={isLoading}>
      <Form
        name="RatingCategoryForm"
        form={form}
        initialValues={{
          ...ratingCategory,
          Platforms: ratingCategory.Platforms?.map(
            (platform) => platform.PlatformCode
          ),
        }}
        onFinish={onFinish}
        layout="vertical"
      >
        <Row direction="column" justify="space-between" className="full-height">
          <Col>
            {renderCodeInput()}
            {renderNameInput()}
            {renderNameTranslationKey()}
            {renderPlatformsField()}
            {renderUpToDate()}
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};
