import { ITestDeviceModel } from "@bms/common-services";
import {
  Choose,
  ChooseOption,
  Col,
  Form,
  IFormValues,
  Input,
  ipValid,
  required,
  Row,
  Spin,
  Switch,
  TextArea,
} from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { useTestingPlatforms } from "../../../../hooks";
import React from "react";

interface TestingDeviceFormProps {
  device: ITestDeviceModel;
  isLoading: boolean;
  setVisibilityStatus: (visible: boolean) => void;
  createDevice: (data: ITestDeviceModel) => Promise<boolean>;
  updateDevice: (data: ITestDeviceModel) => Promise<boolean>;
}

export const TestingDeviceForm = (props: TestingDeviceFormProps) => {
  const {
    device,
    isLoading,
    setVisibilityStatus,
    createDevice,
    updateDevice,
  } = props;

  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { platforms } = useTestingPlatforms();

  const isNewDevice = device.Id < 0;

  const onFinish = async (values: IFormValues) => {
    const data: ITestDeviceModel = {
      ...device,
      ...values,
    };

    isNewDevice ? await createDevice(data) : await updateDevice(data);
    setVisibilityStatus(false);
  };

  const renderNameInput = () => {
    return (
      <Form.Item name="Name" label={t("MODEL_NAME")} rules={[required()]}>
        <Input placeholder={t("MODEL_NAME")} />
      </Form.Item>
    );
  };

  const renderDescriptionInput = () => {
    return (
      <Form.Item name="Description" label={t("MODEL_DESCRIPTION")}>
        <TextArea maxLength={250} placeholder={t("MODEL_DESCRIPTION")} />
      </Form.Item>
    );
  };

  const renderPlatformField = () => {
    return (
      <Form.Item
        key="PlatformCode"
        name="PlatformCode"
        label={t("MODEL_PLATFORM")}
        rules={[required()]}
      >
        <Choose placeholder={t("MODEL_PLATFORM")} loading={!platforms.length}>
          {platforms.map((platform) => {
            return (
              <ChooseOption key={platform.Code} value={platform.Code}>
                {platform.DisplayName}
              </ChooseOption>
            );
          })}
        </Choose>
      </Form.Item>
    );
  };

  const renderOsVersionInput = () => {
    return (
      <Form.Item name="OSVersion" label={t("TESTING_DEVICES_OS_VERSION")}>
        <Input placeholder={t("TESTING_DEVICES_OS_VERSION")} />
      </Form.Item>
    );
  };

  const renderModelInput = () => {
    return (
      <Form.Item name="Model" label={t("TESTING_DEVICES_MODEL")}>
        <Input placeholder={t("TESTING_DEVICES_MODEL")} />
      </Form.Item>
    );
  };

  const renderSerialNumberInput = () => {
    return (
      <Form.Item name="SerialNumber" label={t("TESTING_DEVICES_SERIAL_NUMBER")}>
        <Input placeholder={t("TESTING_DEVICES_SERIAL_NUMBER")} />
      </Form.Item>
    );
  };

  const renderIpInput = () => {
    return (
      <Form.Item
        name="IP"
        label={t("TESTING_DEVICES_IP")}
        rules={[required(), ipValid()]}
      >
        <Input placeholder={t("TESTING_DEVICES_IP")} />
      </Form.Item>
    );
  };

  const renderUpToDate = () => {
    return (
      <Form.Item>
        <Row gutter={8}>
          <Col>
            <Form.Item name="UpToDate" valuePropName="checked">
              <Switch />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="UpToDate">
              <label>{t("MODEL_UP_TO_DATE")}</label>
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>
    );
  };

  return (
    <Spin spinning={isLoading}>
      <Form
        name="TestingDeviceForm"
        form={form}
        initialValues={device}
        onFinish={onFinish}
        layout="vertical"
      >
        <Row direction="column" justify="space-between" className="full-height">
          <Col>
            {renderNameInput()}
            {renderPlatformField()}
            {renderIpInput()}
            {renderOsVersionInput()}
            {renderModelInput()}
            {renderSerialNumberInput()}
            {renderDescriptionInput()}
            {renderUpToDate()}
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};
