import React from "react";
import { Form, IFormInstance } from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { IAssetFormFieldCommonProps } from "./IAssetFormFieldCommonProps";
import { LazyChoose } from "../../../../../components";
import {
  CountriesService,
  CountryModel,
  IAssetCountryModel,
  IListModelBase,
  useConfig,
} from "@bms/common-services";

interface IGeoBlockFieldProps extends IAssetFormFieldCommonProps {
  form: IFormInstance;
}

const countriesService = new CountriesService();

let countries: CountryModel[];

export const GeoBlockField = ({
  asset,
  isHidden,
  isEditMode,
  form,
}: IGeoBlockFieldProps) => {
  const { t } = useTranslation();
  const { config } = useConfig();

  if (isHidden || !config?.Geolocation?.Enabled) {
    return null;
  }

  const onChange = () => {
    const countriesAllowedValue = form.getFieldsValue(["CountriesAllowed"]);
    if (countriesAllowedValue.CountriesAllowed.length > 0) {
      form.setFieldsValue({ CountriesAllowed: [] });
    }
  };

  return (
    <Form.Item
      name="CountriesBlocked"
      label={t("ASSET_FORM_GEO_BLOCKED_LABEL")}
      key="CountriesBlocked"
      initialValue={(isEditMode && asset?.CountriesBlocked) || []}
    >
      <LazyChoose<CountryModel, IAssetCountryModel>
        disableRecordStatus
        onChange={onChange}
        loader={async (search: string) => {
          if (!countries) {
            countries = await countriesService.select().toPromise();
          }
          const data: IListModelBase<CountryModel, {}> = {
            TotalCount: countries.length,
            Entities: countries.filter((country) =>
              country.Name?.toLowerCase().startsWith(search)
            ),
          };
          return {
            data,
            ok: true,
          };
        }}
        candidateToOption={(item) => ({
          label: item.Name,
          value: `${item.Id}`,
          item: {
            AssetId: asset?.Id!,
            CountryId: item.Id,
            CountryCode: item.Code,
            CountryName: item.Name,
            RowVersion: item.RowVersion,
          },
        })}
        selectedToOption={(item) => ({
          label: item.CountryName!,
          value: `${item.CountryId!}`,
          item,
        })}
      />
    </Form.Item>
  );
};
