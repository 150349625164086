/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  IApplicationComponentPropertyModel,
  IPlatformModel,
  PlatformType,
  RecordStatus,
} from "@bms/common-services";
import { Icon, Popconfirm, TabPane, Tabs } from "@bms/common-ui";

import { PlatformTypeHelper } from "../../../../../../helpers";
import { ApplicationScreenContext } from "../../../../context/ApplicationScreenContext";

import { IComponentPropertiesBaseProps } from "../ComponentPropertiesBase";
import { SelectPlatformModal } from "../SelectPlatformModal";
import { ListComponentProperties } from "../ListComponentProperties";

export interface IListComponentPlatformPropertiesProps
  extends IComponentPropertiesBaseProps {}

export const ListComponentPlatformProperties = ({
  component,
  onComponentChange,
}: IListComponentPlatformPropertiesProps) => {
  const { t } = useTranslation();
  const [activeKey, setActiveKey] = useState(`${PlatformType.Any}`);
  const [platformsBrowserVisible, setPlatformsBrowserVisible] = useState<
    boolean
  >(false);
  const [platforms, setPlatforms] = useState<PlatformType[]>([
    PlatformType.Any,
  ]);
  const { screen } = useContext(ApplicationScreenContext);

  const availablePlatforms = useMemo(() => {
    if (screen && screen.Platforms) {
      return screen.Platforms.map((row) =>
        PlatformTypeHelper.getValue(row.PlatformCode)
      );
    }
    return [];
  }, [screen]);

  useEffect(() => {
    const platformsCodes = Array.from(
      new Set(
        component.Properties?.filter(
          (property: IApplicationComponentPropertyModel) =>
            property.RecordStatus !== RecordStatus.Deleted
        )?.map(
          (property: IApplicationComponentPropertyModel) =>
            property.PlatformCode
        )
      )
    );

    if (platformsCodes.length === 0) {
      platformsCodes.push(PlatformType.Any);
    }

    setPlatforms(platformsCodes);
  }, [component?.Id]);

  const onPlatformEdit = (
    _: React.MouseEvent | React.KeyboardEvent | string,
    action: string
  ) => {
    if (action === "add") {
      setPlatformsBrowserVisible(true);
    }
  };

  const onPlatformDelete = (platformType: PlatformType) => {
    if (!component.Properties || platformType === PlatformType.Any) {
      return;
    }

    const properties = component.Properties.filter(
      (row: IApplicationComponentPropertyModel) =>
        row.PlatformCode === platformType
    );

    for (const property of properties) {
      let propertyRecordStatus = property.RecordStatus || RecordStatus.NoChange;

      if (
        propertyRecordStatus === RecordStatus.NoChange ||
        propertyRecordStatus === RecordStatus.Updated
      ) {
        property.RecordStatus = RecordStatus.Deleted;
      } else {
        let index = component.Properties.findIndex(
          (row: IApplicationComponentPropertyModel) =>
            row.Name === property.Name && row.PlatformCode == platformType
        );

        component.Properties.splice(index, 1);
      }
    }

    onComponentChange?.(component);

    const newPlatforms = [...platforms].filter(
      (row: PlatformType) => row !== platformType
    );
    setPlatforms(newPlatforms);
    setActiveKey(`${PlatformType.Any}`);
  };

  const onPlatformSelect = (platform: IPlatformModel) => {
    setPlatformsBrowserVisible(false);

    if (component.Properties) {
      const properties = component.Properties.filter(
        (row: IApplicationComponentPropertyModel) =>
          row.PlatformCode === platform.Code
      );

      for (const property of properties) {
        if ((property.RecordStatus = RecordStatus.Deleted)) {
          property.RecordStatus = RecordStatus.NoChange;
        }
      }
    }

    const newPlatforms = [...platforms];
    newPlatforms.push(PlatformTypeHelper.getValue(platform.Code));
    setPlatforms(newPlatforms);
    setActiveKey(platform.Code);
  };

  const onChange = (newActiveKey: string) => {
    setActiveKey(newActiveKey);
  };

  const renderTabPanes = () => {
    return platforms.map((platformType: PlatformType) => (
      <TabPane
        key={`${platformType}`}
        tab={
          platformType === PlatformType.Any
            ? t("APPLICATION_CONFIGURATION_PROPERTIES_GENERAL")
            : `${PlatformTypeHelper.getDescription(platformType)}`
        }
        closable={platformType !== PlatformType.Any}
        closeIcon={
          <Popconfirm
            title={t(
              "APPLICATION_CONFIGURATION_PROPERTIES__PLATFOTM_CONFIGURATION_DELETE_MESSAGE"
            )}
            onConfirm={(e?: React.MouseEvent<HTMLElement>) => {
              e?.preventDefault();
              onPlatformDelete(platformType);
            }}
            okText={t("BUTTON_YES")}
            cancelText={t("BUTTON_NO")}
          >
            <Icon
              type="close"
              title={t(
                "APPLICATION_CONFIGURATION_PROPERTIES__PLATFOTM_CONFIGURATION_DELETE"
              )}
            />
          </Popconfirm>
        }
      >
        <ListComponentProperties
          key={`ListComponentProperties-${component.Id}-${platformType}`}
          platformType={platformType}
          component={component}
          onComponentChange={onComponentChange}
        />
      </TabPane>
    ));
  };

  return (
    <>
      <Tabs
        key={`tabs-${component?.Id}`}
        type={"editable-card"}
        activeKey={activeKey}
        onEdit={onPlatformEdit}
        onChange={onChange}
      >
        {renderTabPanes()}
      </Tabs>
      <SelectPlatformModal
        key={`select-platform-${component.Id}-${platforms?.length}`}
        visible={platformsBrowserVisible}
        selectedPlatforms={platforms}
        availablePlatforms={availablePlatforms}
        onCancel={() => setPlatformsBrowserVisible(false)}
        onSelect={onPlatformSelect}
      />
    </>
  );
};
