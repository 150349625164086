export enum AssetType {
  Series = "SERIES",
  Video = "VOD",
  Episode = "EPISODE",
  Season = "SEASON",
  Live = "LIVE",
  Intro = "INTRO",
  Channel = "CHANNEL",
  Program = "PROGRAM",
  Package = "PACKAGE",
  Premiere = "VIDEO_PREMIERE",
  Category = "CATEGORY",
  Article = "ARTICLE",
  Event = "EVENT",
  Podcast = "PODCAST",
  Album = "ALBUM",
  Advertisement = "ADVERTISEMENT",
}
