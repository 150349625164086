import {
  ApplicationConfigurationStore,
  GuidHelper,
  IApplicationComponentModel,
  IApplicationConfigurationModel,
  IApplicationScreenModel,
  IErrorModel,
  IPlatformModel,
  IStateModel,
  PlatformType,
  RecordStatus,
  ScreenTypeHelper,
} from "@bms/common-services";
import {
  Button,
  Heading,
  Content,
  Icon,
  Layout,
  PageContent,
  PageHeader,
  SectionGrid,
  SectionGridItem,
  Sider,
  Spin,
  TabPane,
  Tabs,
  IBreadcrumbProps,
  AppFeedbackContext,
} from "@bms/common-ui";
import { ROUTES } from "../../constants";
import React from "react";
import { WithTranslation } from "react-i18next";
import { RouteComponentProps, StaticContext } from "react-router";
import { Action, ActionCreator } from "redux";
import { ComponentTypeHelper, generateBreadcrumb } from "../../../../helpers";
import {
  ApplicationScreenProvider,
  IApplicationScreenContext,
} from "../../context";
import { DesignerModule } from "../../modules";
import { ApplicationComponentProperties } from "../ApplicationComponentProperties";
import { ApplicationScreenComponents } from "../ApplicationScreenComponents";
import { ApplicationScreenDetailsForm } from "../ApplicationScreenDetailsForm";
import { ApplicationScreenProperties } from "../ApplicationScreenProperties";

import "./ApplicationScreenDetails.scss";

export interface IApplicationScreenDetailsProps
  extends WithTranslation,
    RouteComponentProps<{ id: string }, StaticContext, { prevId: string }> {
  actionType?: string;
  screen?: IApplicationScreenModel;
  platforms: IPlatformModel[];
  getScreen: ActionCreator<
    ApplicationConfigurationStore.Types.IGetApplicationScreenAction
  >;
  configuration: IStateModel<IApplicationConfigurationModel>;
  isProcessingData: boolean;
  isLoadingData: boolean;
  updateScreen: ActionCreator<
    ApplicationConfigurationStore.Types.IUpdateApplicationScreenAction
  >;
  deleteScreen: ActionCreator<
    ApplicationConfigurationStore.Types.IDeleteApplicationScreenAction
  >;
  selectPlatforms: ActionCreator<Action>;
  copyScreen: ActionCreator<
    ApplicationConfigurationStore.Types.ICopyApplicationScreenAction
  >;
  getConfiguration: ActionCreator<
    ApplicationConfigurationStore.Types.IGetApplicationConfigurationAction
  >;
  publishConfiguration: ActionCreator<
    ApplicationConfigurationStore.Types.IPublishApplicationConfigurationAction
  >;
  error?: IErrorModel;
}

const TabContent: React.FC<{
  sider: React.ReactNode;
  header?: string;
  children?: React.ReactNode;
}> = (props) => (
  <Layout>
    <Content>
      <SectionGrid>
        <SectionGridItem header={props.header}>
          {props.children}
        </SectionGridItem>
      </SectionGrid>
    </Content>
    {props.sider}
  </Layout>
);

export interface IApplicationScreenDetailsState {
  activeTabKey: string;
  screen?: IApplicationScreenModel;
  component?: IApplicationComponentModel;
  hasChanges: boolean;
}

export class ApplicationScreenDetails extends React.Component<
  IApplicationScreenDetailsProps,
  IApplicationScreenDetailsState
> {
  static contextType = AppFeedbackContext;
  declare context: React.ContextType<typeof AppFeedbackContext>;

  public state: Readonly<IApplicationScreenDetailsState> = {
    activeTabKey:
      new URLSearchParams(this.props.location.search).get("tab") ?? "DETAILS",
    screen: this.props.screen,
    hasChanges: false,
  };

  public componentDidMount() {
    const {
      getScreen,
      match,
      platforms,
      selectPlatforms,
      getConfiguration,
    } = this.props;

    getScreen(match.params.id);
    getConfiguration(this.props.location?.state?.prevId);

    if (!platforms || platforms.length === 0) {
      selectPlatforms();
    }
  }

  componentDidUpdate(prevProps: IApplicationScreenDetailsProps) {
    const {
      actionType,
      match,
      error,
      getScreen,
      t,
      configuration,
    } = this.props;

    if (match.params.id !== prevProps.match.params.id) {
      getScreen(match.params.id);
    }

    if (prevProps.actionType === actionType) {
      return;
    }

    switch (actionType) {
      case ApplicationConfigurationStore.Consts
        .DELETE_APPLICATION_SCREEN_FAILURE:
        return this.context.notification.error({
          message: t("APPLICATION_SCREEN_DETAILS__DELETE_SCREEN_FAILURE"),
          description: error ? error.Message : undefined,
        });
      case ApplicationConfigurationStore.Consts
        .DELETE_APPLICATION_SCREEN_SUCCESS:
        this.context.notification.success({
          message: t("APPLICATION_SCREEN_DETAILS__DELETE_SCREEN_SUCCESS"),
        });
        window.history.back();
        break;
      case ApplicationConfigurationStore.Consts
        .UPDATE_APPLICATION_SCREEN_FAILURE:
        return this.context.notification.error({
          message: t("APPLICATION_SCREEN_DETAILS__UPDATE_SCREEN_FAILURE"),
          description: error ? error.Message : undefined,
        });
      case ApplicationConfigurationStore.Consts
        .UPDATE_APPLICATION_SCREEN_SUCCESS:
        return this.context.notification.success({
          message: t("APPLICATION_SCREEN_DETAILS__UPDATE_SCREEN_SUCCESS"),
        });
      case ApplicationConfigurationStore.Consts.COPY_APPLICATION_SCREEN_SUCCESS:
        this.context.notification.success({
          message: t("APPLICATION_SCREEN_COPY_SCREEN_SUCCESS"),
        });
        break;
      case ApplicationConfigurationStore.Consts.COPY_APPLICATION_SCREEN_FAILURE:
        this.context.notification.error({
          message: t("APPLICATION_SCREEN_COPY_SCREEN_FAILURE"),
        });
        break;
      case ApplicationConfigurationStore.Consts
        .PUBLISH_APPLICATION_CONFIGURATION_SUCCESS:
        this.context.notification.success({
          message: t("APPLICATION_CONFIGURATION__PUBLISH_SUCCESS"),
        });
        break;
      case ApplicationConfigurationStore.Consts
        .PUBLISH_APPLICATION_CONFIGURATION_FAILURE:
        this.context.notification.error({
          description: configuration?.Error?.Message,
          message: t("APPLICATION_CONFIGURATION__PUBLISH_FAILED"),
        });
        break;
      default:
        break;
    }
  }

  static getDerivedStateFromProps(
    props: Readonly<IApplicationScreenDetailsProps>,
    state: IApplicationScreenDetailsState
  ) {
    if (props.screen !== state.screen) {
      let component: IApplicationComponentModel | undefined;

      if (state.component) {
        component = props?.screen?.Components?.find(
          (row: IApplicationComponentModel) => row.Id === state.component?.Id
        );
      }

      return {
        screen: props.screen,
        component: component,
        hasChanges: false,
      };
    }

    return null;
  }

  private onTabClick = (key: string) => {
    this.setState({ activeTabKey: key });
  };

  private onPublishClick = () => {
    const { t, configuration } = this.props;

    if (!configuration?.Data) {
      return;
    }

    this.context.modal.confirm({
      title: t("APPLICATION_CONFIGURATION__PUBLISH_TITLE"),
      content: t("APPLICATION_CONFIGURATION__PUBLISH_MESSAGE", {
        configurationName: configuration?.Data.Name,
      }),
      okText: t("APPLICATION_CONFIGURATION__PUBLISH"),
      cancelText: t("COMMON_CANCEL"),
      onOk: this.onPublish,
    });
  };

  public onPublish = () => {
    const { configuration, publishConfiguration } = this.props;

    if (!configuration?.Data) {
      return;
    }

    publishConfiguration(configuration.Data.Id, configuration.Data.RowVersion);
  };

  private onSaveClick = () => {
    const { updateScreen } = this.props;
    const { screen } = this.state;

    if (!screen) {
      return;
    }

    updateScreen(screen);
  };

  public onRefreshClick = () => {
    const { getScreen, match, getConfiguration } = this.props;

    getScreen(match.params.id);
    getConfiguration(this.props.location?.state?.prevId);
  };

  public onCopyClick = () => {
    const { screen, t } = this.props;

    if (!screen) {
      return;
    }

    this.context.modal.confirm({
      title: t("APPLICATION_SCREEN_COPY_SCREEN"),
      content: `${t("APPLICATION_SCREEN_COPY_SCREEN_CONFIRMATION")} ${
        screen.Name
      }?`,
      okText: t("BUTTON_OK"),
      cancelText: t("BUTTON_CANCEL"),
      onOk: this.onCopyConfirm,
    });
  };

  public onCopyConfirm = () => {
    const { screen, copyScreen } = this.props;

    copyScreen(screen);
  };

  private onDeleteClick = () => {
    const { t, screen } = this.props;

    if (!screen) {
      return;
    }

    this.context.modal.confirm({
      title: t("DELETE_SCREEN"),
      content: t("DELETE_SCREEN_MESSAGE", { screenName: screen.Name }),
      okText: t("BUTTON_OK"),
      cancelText: t("BUTTON_CANCEL"),
      onOk: this.onConfirmDelete,
    });
  };

  public onConfirmDelete = () => {
    const { deleteScreen, screen } = this.props;

    deleteScreen(screen);
  };

  public render() {
    const { isProcessingData, isLoadingData, t, configuration } = this.props;
    const { screen, component, activeTabKey, hasChanges } = this.state;

    const applicationScreenContext: IApplicationScreenContext = {
      screen,
      component,
      onScreenChange: (screenData: IApplicationScreenModel) => {
        const { screen } = this.state;

        if (!screen) {
          return;
        }

        Object.assign(screen, screenData);

        if (screen.RecordStatus === RecordStatus.NoChange) {
          screen.RecordStatus = RecordStatus.Updated;
        }

        this.setState({ screen: screen, hasChanges: true });
      },
      onComponentAdd: (component: IApplicationComponentModel) => {
        const { screen } = this.state;

        if (!screen) {
          return;
        }

        if (!screen.Components) {
          screen.Components = [];
        }

        const newComponent: IApplicationComponentModel = {
          ...component,
        };

        const minId =
          screen.Components.length > 0
            ? screen.Components.reduce(
                (min, component) => (component.Id < min ? component.Id : min),
                screen.Components[0].Id
              )
            : 0;

        newComponent.Id = minId >= 0 ? -1 : minId - 1;
        newComponent.ApplicationConfigurationId =
          screen.ApplicationConfigurationId;
        newComponent.ApplicationScreenId = screen.Id;
        newComponent.IsVisible = true;
        newComponent.RecordStatus = RecordStatus.Inserted;
        newComponent.Sequence =
          screen.Components.length > 0
            ? Math.max(...screen.Components.map((c) => c.Sequence)) + 1
            : 0;

        if (!newComponent.Guid) {
          newComponent.Guid = GuidHelper.newGuid();
        }

        if (!newComponent.PlatformCode) {
          newComponent.PlatformCode = PlatformType.Any;
        }

        screen.Components.push(newComponent);

        this.setState({ screen, component: newComponent, hasChanges: true });
      },
      onComponentSelect: (component?: IApplicationComponentModel) => {
        this.setState({ component: component });
      },
      onComponentChange: (component: IApplicationComponentModel) => {
        const { screen } = this.state;

        if (!screen || !screen.Components) {
          return;
        }

        const componentIndex = screen.Components.findIndex(
          (row: IApplicationComponentModel) => row.Id === component.Id
        );

        if (componentIndex >= 0) {
          screen.Components[componentIndex] = Object.assign({}, component);

          if (
            screen.Components[componentIndex].RecordStatus ===
            RecordStatus.NoChange
          ) {
            screen.Components[componentIndex].RecordStatus =
              RecordStatus.Updated;
          }

          this.setState({
            screen,
            component: screen.Components[componentIndex],
            hasChanges: true,
          });
        }
      },
      onComponentsChange: (components: IApplicationComponentModel[]) => {
        const { screen } = this.state;

        if (!screen || !screen.Components) {
          return;
        }

        for (const component of components) {
          const componentIndex = screen.Components.findIndex(
            (row: IApplicationComponentModel) => row.Id === component.Id
          );

          if (componentIndex >= 0) {
            screen.Components[componentIndex] = Object.assign({}, component);
          }
        }

        this.setState({ screen, hasChanges: true });
      },
      onComponentDelete: (componentId: number) => {
        const { screen } = this.state;

        if (!screen || !screen.Components) {
          return;
        }

        const componentIndex = screen.Components.findIndex(
          (row: IApplicationComponentModel) => row.Id === componentId
        );

        if (componentIndex >= 0) {
          if (
            screen.Components[componentIndex].RecordStatus ===
            RecordStatus.Inserted
          ) {
            screen.Components.splice(componentIndex, 1);
          } else {
            screen.Components[componentIndex].RecordStatus =
              RecordStatus.Deleted;
          }
        }

        this.setState({ screen, component: undefined, hasChanges: true });
      },
      onNewChanges: (isChanged: boolean) => {
        this.setState({ hasChanges: isChanged });
      },
    };

    let componentTypeTag: React.ReactNode;

    if (component && component.RecordStatus !== RecordStatus.Deleted) {
      componentTypeTag = ComponentTypeHelper.getTag(
        component.ComponentTypeCode,
        { marginLeft: "16px" }
      );
    }

    const zeroWidthTriggerStyle: React.CSSProperties = {
      top: "60px",
      height: "33px",
      fontSize: "18px",
      lineHeight: "33px",
    };

    const propertiesSiderStyle: React.CSSProperties = {
      display: activeTabKey === "DETAILS" ? "none" : "block",
    };

    const sider = (
      <Sider
        className="ApplicationScreenDetails__Properties"
        style={propertiesSiderStyle}
        collapsed={!this.state.component}
        reverseArrow={true}
        collapsedWidth={0}
        width={600}
        zeroWidthTriggerStyle={zeroWidthTriggerStyle}
      >
        <SectionGrid>
          <SectionGridItem>
            <Heading
              title={
                <>
                  {t("CONFIGURATION_PROPERTIES__TITLE")}
                  {componentTypeTag}
                </>
              }
              actions={
                <Button
                  icon={<Icon type="arrow-right" />}
                  onClick={() => this.setState({ component: undefined })}
                />
              }
            />
            <ApplicationComponentProperties
              key={`ApplicationComponentProperties-${screen?.Id}`}
            />
          </SectionGridItem>
        </SectionGrid>
      </Sider>
    );

    const getBreadcrumbProps = (): IBreadcrumbProps => {
      return generateBreadcrumb([
        {
          path: `${ROUTES.CONFIGURATION_LIST}`,
          breadcrumbName: t("APPLICATION_CONFIGURATION_TITLE"),
        },
        {
          path: `${ROUTES.CONFIGURATION_DETAILS}/${this.props.location?.state?.prevId}`,
          breadcrumbName: t("APPLICATION_CONFIGURATION__MAIN_CONFIGURATION"),
        },
        {
          path: `${ROUTES.CONFIGURATION_SCREEN_DETAILS}/${screen?.Id}`,
          breadcrumbName:
            screen?.Name ?? t("APPLICATION_CONFIGURATION__SCREEN_DETAILS"),
        },
      ]);
    };

    return (
      <div className="ApplicationScreenDetails">
        <Spin spinning={isProcessingData || isLoadingData}>
          <ApplicationScreenProvider value={applicationScreenContext}>
            <PageHeader
              title={screen?.Name}
              breadcrumb={getBreadcrumbProps()}
              onBack={() => window.history.back()}
              extra={
                <>
                  <Button
                    type="primary"
                    key="action-save"
                    icon={<Icon type="upload" />}
                    title={t("APPLICATION_CONFIGURATION__PUBLISH_TITLE")}
                    loading={configuration.IsProcessing}
                    disabled={configuration.IsProcessing}
                    onClick={this.onPublishClick}
                  >
                    {t("BUTTON_PUBLISH")}
                  </Button>
                  <Button
                    key="action-save"
                    icon={<Icon type="save" />}
                    title={t("BUTTON_SAVE")}
                    loading={isProcessingData}
                    disabled={!hasChanges || isProcessingData || isLoadingData}
                    onClick={this.onSaveClick}
                  >
                    {t("BUTTON_SAVE")}
                  </Button>

                  <Button
                    key="action-reload"
                    icon={<Icon type="reload" />}
                    onClick={this.onRefreshClick}
                    title={t("BUTTON_REFRESH_TITLE")}
                  />
                  <Button
                    key="copy-reload"
                    icon={<Icon type="copy" />}
                    onClick={this.onCopyClick}
                    title={t("BUTTON_COPY")}
                  />
                  <Button
                    icon={<Icon type="delete" />}
                    title={t("DELETE_SCREEN")}
                    onClick={this.onDeleteClick}
                  />
                </>
              }
            ></PageHeader>
            <PageContent>
              <Tabs
                defaultActiveKey={activeTabKey}
                onTabClick={this.onTabClick}
              >
                <TabPane
                  key="DETAILS"
                  tab={t("APPLICATION_CONFIGURATION__DETAILS_TAB")}
                >
                  <TabContent
                    sider={sider}
                    header={t(
                      "APPLICATION_SCREEN_DETAILS__GENERAL_INFORMATION"
                    )}
                  >
                    <ApplicationScreenDetailsForm
                      key={`ApplicationScreenDetailsForm-${screen?.Id}`}
                      isActive={activeTabKey === "DETAILS"}
                    />
                  </TabContent>
                </TabPane>
                {ScreenTypeHelper.configurable(screen?.ScreenTypeCode) && (
                  <TabPane key="COMPONENTS" tab={t("MODEL_COMPONENTS")}>
                    <TabContent sider={sider}>
                      <ApplicationScreenComponents
                        key={`ApplicationScreenComponents-${screen?.Id}`}
                      />
                    </TabContent>
                  </TabPane>
                )}
                <TabPane
                  key="DESIGNER"
                  tab={t("APPLICATION_SCREEN_DETAILS__DESIGNER_TAB")}
                >
                  <TabContent sider={sider}>
                    <DesignerModule.Components.ScreenDesigner
                      key={`ScreenDesigner-${screen?.Id}`}
                    />
                  </TabContent>
                </TabPane>
                {ScreenTypeHelper.parametrizable(screen?.ScreenTypeCode) && (
                  <TabPane
                    key="PROPERTIES"
                    tab={t("CONFIGURATION_PROPERTIES__TITLE")}
                  >
                    <TabContent sider={null}>
                      <ApplicationScreenProperties
                        key={`ApplicationScreenProperties-${screen?.Id}`}
                      />
                    </TabContent>
                  </TabPane>
                )}
              </Tabs>
            </PageContent>
          </ApplicationScreenProvider>
        </Spin>
      </div>
    );
  }
}
