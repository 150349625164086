import { useEffect, useRef, useState } from "react";

export function useSyncedState<T>(
  setter: (oldState?: T) => T,
  deps: any[]
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [state, setState] = useState<T>(setter);
  const initialized = useRef(false);
  useEffect(() => {
    if (initialized.current) {
      setState(setter);
    } else {
      initialized.current = true;
    }
  }, deps);
  return [state, setState];
}
