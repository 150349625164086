import React from "react";
import { Choose, ChooseOption, Form } from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import {
  NotificationRecipientTypesService,
  INotificationRecipientTypeModel,
  useDataLoader,
} from "@bms/common-services";
import { INotificationField } from "./INotificationField";

const notificationRecipientTypesService = new NotificationRecipientTypesService().promisify();

export const RecipientTypeField = ({
  notification,
  isEditMode,
  isHidden,
  onChangeRecipients,
}: INotificationField) => {
  const { t } = useTranslation();
  const notificationsRecipientTypesLoader = useDataLoader({
    loader: () => notificationRecipientTypesService.select(),
    deps: [],
  });

  if (isHidden) {
    return null;
  }

  return (
    <Form.Item
      name="RecipientType"
      label={t("NOTIFICATION_RECIPIENT_FIELD")}
      key="RecipientType"
      initialValue={isEditMode ? notification?.RecipientType || "" : ""}
    >
      <Choose
        placeholder={t("NOTIFICATION_RECIPIENT_INPUT_FIELD")}
        key="RecipientType"
        onChange={(value: string) => onChangeRecipients?.(value)}
      >
        {notificationsRecipientTypesLoader.data?.map(
          (type: INotificationRecipientTypeModel) => (
            <ChooseOption key={type.Code} value={type.Code}>
              {t(type.TranslationKey)}
            </ChooseOption>
          )
        )}
      </Choose>
    </Form.Item>
  );
};
