import { IErrorModel, MessageModel } from "../../models";
import {
  ChatActionsTypes,
  USER_CONNECTED,
  USER_DISCONNECTED,
  SEND_CHAT_MESSAGE,
  SEND_CHAT_MESSAGE_SUCCESS,
  SEND_CHAT_MESSAGE_FAILURE,
  RECEIVE_CHAT_MESSAGE,
} from "./types";

export const userConnected = (id: string): ChatActionsTypes => {
  return {
    id,
    type: USER_CONNECTED,
  };
};

export const userDisconnected = (id: string): ChatActionsTypes => {
  return {
    id,
    type: USER_DISCONNECTED,
  };
};

export const sendMessage = (data: MessageModel): ChatActionsTypes => {
  return {
    data,
    type: SEND_CHAT_MESSAGE,
  };
};

export const sendMessageSuccess = (data: MessageModel): ChatActionsTypes => {
  return {
    data,
    type: SEND_CHAT_MESSAGE_SUCCESS,
  };
};

export const sendMessageFailure = (error?: IErrorModel): ChatActionsTypes => {
  return {
    error,
    type: SEND_CHAT_MESSAGE_FAILURE,
  };
};

export const receiveMessage = (
  roomId: number,
  data: MessageModel
): ChatActionsTypes => {
  return {
    data,
    roomId,
    type: RECEIVE_CHAT_MESSAGE,
  };
};

export const ChatActions = {
  receiveMessage,
  sendMessage,
  userConnected,
  userDisconnected,
};
