/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  AssetStreamsService,
  AssetType,
  IAssetModel,
  IErrorModel,
} from "@bms/common-services";
import {
  Button,
  Col,
  Icon,
  IFormValues,
  Input,
  Row,
  Alert,
  Form,
} from "@bms/common-ui";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { AssetBrowserModal } from "../../../Asset/components/AssetBrowserModal";
import { FormModal } from "../../../../components";

const formLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};

const assetStreamsService = new AssetStreamsService();

export interface ICreateMediaChannelModalProps {
  visible: boolean;
  onCancel?: () => void;
  onSuccess?: (assetId: number) => void;
}

export const CreateMediaChannelModal = ({
  visible,
  onCancel,
  onSuccess,
}: ICreateMediaChannelModalProps) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const [processing, setProcessing] = useState<boolean>(false);
  const [error, setError] = useState<IErrorModel>();
  const [browserVisible, setBrowserVisible] = useState<boolean>(false);
  const [selectedAsset, setSelectedAsset] = useState<IAssetModel>();

  const onCloseModal = () => {
    form.resetFields();
    onCancel?.();
  };

  const onFinish = async (values: IFormValues) => {
    setError(undefined);

    if (!values.AssetId) {
      return;
    }

    setProcessing(true);

    try {
      const assetId = await assetStreamsService
        .create(values.AssetId)
        .toPromise();
      setProcessing(false);
      form.resetFields();
      onSuccess?.(assetId);
    } catch (error) {
      setError(error as IErrorModel);
      setProcessing(false);
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      AssetId: selectedAsset?.Id,
      AssetTitle: selectedAsset?.Title,
    });
  }, [selectedAsset]);

  const renderAssetField = () => {
    return (
      <Form.Item>
        <Row wrap={false}>
          <Col flex="auto" style={{ paddingRight: "8px" }}>
            <Form.Item
              label={t("CREATE_MEDIA_CHANNEL_MODAL_ASSET_LABEL")}
              name="AssetTitle"
              rules={[
                {
                  required: true,
                  message: t("REQUIRED_VALIDATION_MESSAGE"),
                },
              ]}
            >
              <Input
                disabled
                placeholder={t("CREATE_MEDIA_CHANNEL_MODAL_ASSET_PLACEHOLDER")}
              />
            </Form.Item>
          </Col>
          <Col flex="none" style={{ textAlign: "right" }}>
            <Form.Item label=" ">
              {selectedAsset && (
                <Button
                  icon={<Icon type="delete" />}
                  onClick={() => {
                    setSelectedAsset(undefined);
                  }}
                  style={{ marginRight: "8px" }}
                />
              )}
              <Button
                icon={<Icon type="edit" />}
                onClick={() => {
                  setBrowserVisible(true);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>
    );
  };

  const renderError = () => {
    if (error) {
      return (
        <Form.Item>
          <Alert
            message={t("CREATE_MEDIA_CHANNEL_MODAL_FAILURE_MESSAGE")}
            description={error.Message}
            type="error"
            closable
            showIcon
          />
        </Form.Item>
      );
    }

    return null;
  };

  return (
    <>
      <FormModal
        isVisible={visible}
        isLoading={processing}
        isNewForm={true}
        isDisabled={processing}
        isDeleteButtonEnabled={false}
        createFormTitle={t("CREATE_MEDIA_CHANNEL_MODAL_TITLE")}
        editFormTitle={t("CREATE_MEDIA_CHANNEL_MODAL_TITLE")}
        modalClassName="CreateMediaChannelModal"
        submitFormName="CreateMediaChannelForm"
        confirmButtonTransKey="BUTTON_CREATE"
        onCloseModal={onCloseModal}
      >
        <Form
          form={form}
          name="CreateMediaChannelForm"
          className="CreateMediaChannelForm"
          layout="vertical"
          onFinish={onFinish}
          {...formLayout}
        >
          <Form.Item hidden={true} name="AssetId">
            <Input hidden />
          </Form.Item>
          {renderAssetField()}
          {renderError()}
        </Form>
      </FormModal>
      <AssetBrowserModal
        visible={browserVisible}
        selectionMode={"single"}
        selectedType={[AssetType.Live]}
        onSelect={(asset) => {
          setSelectedAsset(asset);
        }}
        onCancel={() => setBrowserVisible(false)}
      />
    </>
  );
};
