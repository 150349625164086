import { Observable, Observer } from "rxjs";
import { HTTP_METHOD } from "../../constants/http";
import { IAssetEventBuyModel, IAssetPaymentListModel } from "../../models";
import { AxiosSubscriber } from "../AxiosSubscriber";
import { PromisifiableBase } from "../Base";

class IAssetPaymentSearchFilterModel {}

export class AssetPaymentsService extends PromisifiableBase {
  get url(): string {
    return "/AssetPayments";
  }

  public buy = (assetId: number): Observable<IAssetEventBuyModel> =>
    new Observable(
      (observer: Observer<IAssetEventBuyModel>) =>
        new AxiosSubscriber(observer, {
          data: {
            AssetId: assetId,
          },
          method: HTTP_METHOD.POST,
          url: `${this.url}/Buy`,
        })
    );

  public search = (
    filter: IAssetPaymentSearchFilterModel
  ): Observable<IAssetPaymentListModel> =>
    new Observable(
      (observer: Observer<IAssetPaymentListModel>) =>
        new AxiosSubscriber(observer, {
          data: filter,
          method: HTTP_METHOD.POST,
          url: `${this.url}/Search`,
        })
    );
}
