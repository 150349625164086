/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  ApplicationConfigurationStore,
  DataProvider,
  ICommonAppState,
  IPlaylistSourceModel,
  PlayListSourceTypeHelper,
  IPlaylistSourceSearchFilterModel,
} from "@bms/common-services";
import {
  Button,
  Dropdown,
  Icon,
  InputSearch,
  ITableColumnProps,
  ITableFilter,
  ITablePaginationConfig,
  ITableRowSelection,
  Link,
  Menu,
  MenuItem,
  MenuItemClickEvent,
  Modal,
  setTableColumnSearchProps,
  Table,
  TabPane,
  Tabs,
} from "@bms/common-ui";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import "./ListComponentSourceBrowserModal.scss";
import { ListComponentSourcePredefinedTable } from "./components";

export interface IListComponentSourceBrowserModalProps {
  visible: boolean;
  onCancel?: () => void;
  onSelect?: (row: IPlaylistSourceModel) => void;
}

const playlistSelector = (state: ICommonAppState) =>
  state.applicationConfiguration;

export const ListComponentSourceBrowserModal: React.FC<IListComponentSourceBrowserModalProps> = (
  props
) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedRow, setSelectedRow] = useState<IPlaylistSourceModel | null>();
  const [activeTabKey, setActiveTabKey] = useState("general");
  const { playlistBrowser } = useSelector(playlistSelector);
  const filter: IPlaylistSourceSearchFilterModel = playlistBrowser.Filter || {
    PageNumber: 1,
    PageSize: 10,
    IncludeCount: true,
  };

  const browsePlaylist = useCallback(
    (filter: IPlaylistSourceSearchFilterModel) =>
      dispatch(ApplicationConfigurationStore.Actions.browsePlaylist(filter)),
    [dispatch]
  );

  useEffect(() => {
    browsePlaylist(filter);
  }, []);

  const onAdditionalOptionsMenuItemClick = (e: MenuItemClickEvent) => {
    switch (e.key) {
      case "OPTION_CLEAR_FILTERS":
        onClearFiltersClick();
        break;
    }
  };

  const _additionalOptionsMenu: React.ReactElement = (
    <Menu onClick={onAdditionalOptionsMenuItemClick}>
      <MenuItem key="OPTION_CLEAR_FILTERS">
        {t("MENU_OPTION_CLEAR_FILTERS")}
      </MenuItem>
    </Menu>
  );

  const onRefreshClick = () => browsePlaylist(filter);

  const onClearFiltersClick = () => {
    const newFilter: IPlaylistSourceSearchFilterModel = {
      PageSize: playlistBrowser.Filter?.PageSize || 10,
      PageNumber: 1,
      IncludeCount: true,
    };
    browsePlaylist(newFilter);
  };

  const getColumnsProps = (): Array<
    ITableColumnProps<IPlaylistSourceModel>
  > => {
    return [
      {
        key: "Name",
        dataIndex: "Name",
        ellipsis: true,
        title: t("MODEL_NAME"),
        filteredValue: filter.Name ? [filter.Name] : null,
        ...setTableColumnSearchProps("Title"),
      },
      {
        key: "Description",
        dataIndex: "Description",
        ellipsis: true,
        title: t("MODEL_DESCRIPTION"),
        filteredValue: filter.Description ? [filter.Description] : null,
        ...setTableColumnSearchProps("Description"),
      },
      {
        key: "Type",
        dataIndex: "Type",
        width: "160px",
        title: t("MODEL_TYPE"),
        render: (_: any, row: IPlaylistSourceModel) => {
          return (
            <span>{row.TypeDisplayName || PlayListSourceTypeHelper.getDescription(row.Type)}</span>
          );
        },
      },
    ];
  };

  const onTableChange = (
    pagination: ITablePaginationConfig,
    filters: ITableFilter
  ) => {
    const newFilter: IPlaylistSourceSearchFilterModel = {
      ...filter,
      Name: filters.Name?.length ? `${filters.Name[0]}` : undefined,
      Description: filters.Description?.length
        ? `${filters.Description[0]}`
        : undefined,
      PageSize: pagination.pageSize,
      PageNumber: pagination.current,
    };

    browsePlaylist(newFilter);
  };

  const onSearch = (value: string) => {
    const newFilter: IPlaylistSourceSearchFilterModel = {
      ...filter,
      FullTextSearch: value,
      PageNumber: 1,
    };

    browsePlaylist(newFilter);
  };

  const tablePagination: ITablePaginationConfig = {
    pageSize: filter?.PageSize,
    total: playlistBrowser.TotalCount,
    current: filter?.PageNumber,
    showTotal: (total, range) =>
      t("TABLE_PAGINATION_TOTAL", {
        rangeFrom: range[0],
        rangeTo: range[1],
        total: total,
      }),
    showSizeChanger: true,
    defaultPageSize: 10,
    pageSizeOptions: ["10", "30", "50", "100"],
  };
  const columns = getColumnsProps();

  const onCancelClick = () => {
    const { onCancel } = props;

    if (onCancel) {
      onCancel();
    }
  };

  const onSelectClick = () => {
    const { onSelect } = props;

    if (onSelect && selectedRow) {
      onSelect(selectedRow);
    }
  };

  const onTableRow = (row: IPlaylistSourceModel) => {
    return {
      onClick: (_: React.MouseEvent) => {
        setSelectedRow(row);
      },
      onDoubleClick: (_: React.MouseEvent) => {
        const { onSelect } = props;

        if (onSelect) {
          onSelect(row);
        } else {
          setSelectedRow(row);
        }
      },
    };
  };

  const rowSelection: ITableRowSelection<IPlaylistSourceModel> = {
    onChange: (_: React.Key[], selectedRows: IPlaylistSourceModel[]) => {
      if (selectedRows.length > 0) {
        setSelectedRow(selectedRows[0]);
      }
    },
    type: "radio",
    selectedRowKeys: selectedRow?.Id ? [selectedRow.Id] : [],
  };

  const hasSelectedRow = selectedRow ? true : false;

  const tabBarExtraContent = useMemo(() => {
    if (activeTabKey !== "general") {
      return null;
    }

    let linkToAddPlaylist = DataProvider.ApplicationConfiguration?.linkToAddPlaylist?.();

    return (
      <>
        <InputSearch
          key="search"
          placeholder={t("SEARCH_PLACEHOLDER")}
          defaultValue={filter?.FullTextSearch}
          onSearch={onSearch}
          style={{ width: 250, marginRight: 8 }}
          allowClear
        />
        {linkToAddPlaylist && (
          <Link
            target={linkToAddPlaylist.target}
            to={linkToAddPlaylist.url}
            style={{ marginRight: 8 }}
          >
            <Button
              key="add"
              icon={<Icon type="plus" />}
              title={t(
                "APPLICATION_CONFIGURATION__PLAYLIST_BROWSER_ADD_BUTTON"
              )}
            />
          </Link>
        )}
        <Button
          key="reload"
          icon={<Icon type="reload" />}
          onClick={onRefreshClick}
          title={t("BUTTON_REFRESH_TITLE")}
          style={{ marginRight: 8 }}
        />
        <Dropdown key="more-options" overlay={_additionalOptionsMenu}>
          <Button key="option-more" icon={<Icon type="more" />}></Button>
        </Dropdown>
      </>
    );
  }, [activeTabKey, filter]);

  return (
    <>
      <Modal
        title={t("APPLICATION_CONFIGURATION__PLAYLIST_BROWSER_TITLE")}
        onCancel={onCancelClick}
        visible={props.visible}
        className="ListComponentSourceBrowserModal"
        wrapClassName="ListComponentSourceBrowserModal"
        closable={false}
        centered={true}
        width="70%"
        footer={
          <>
            <Button key="cancel" onClick={onCancelClick}>
              {t("BUTTON_CANCEL")}
            </Button>
            <Button
              key="cancel"
              disabled={!hasSelectedRow}
              onClick={onSelectClick}
            >
              {t("BUTTON_SELECT")}
            </Button>
          </>
        }
      >
        <Tabs
          activeKey={activeTabKey}
          onTabClick={(activeKey) => {
            setSelectedRow(null);
            setActiveTabKey(activeKey);
          }}
          tabBarExtraContent={tabBarExtraContent}
        >
          <TabPane
            key="general"
            tab={t("APPLICATION_CONFIGURATION_SOURCE_BROWSER__TAB_GENERAL")}
          >
            <Table<IPlaylistSourceModel>
              rowKey="Id"
              columns={columns}
              dataSource={playlistBrowser.Entities}
              loading={playlistBrowser.IsLoading}
              pagination={tablePagination}
              onChange={onTableChange}
              rowSelection={rowSelection}
              onRow={onTableRow}
              style={{ height: 400 }}
              scroll={{ y: 300 }}
            />
          </TabPane>
          {DataProvider.ApplicationConfiguration?.browsePredefinedPlaylist && (
            <TabPane
              key="predefined"
              tab={t(
                "APPLICATION_CONFIGURATION_SOURCE_BROWSER__TAB_PREDEFINED"
              )}
            >
              <ListComponentSourcePredefinedTable
                isActive={activeTabKey === "predefined"}
                onSelect={setSelectedRow}
                onChoose={(row) => {
                  setSelectedRow(row);
                  props.onSelect?.(row);
                }}
              />
            </TabPane>
          )}
        </Tabs>
      </Modal>
    </>
  );
};
