import {
  IResendConfirmationModel,
  IUserModel,
  UserService,
  useServiceCaller,
} from "@bms/common-services";
import {
  Col,
  Form,
  InputNumber,
  Row,
  TimePicker,
  useAppFeedback,
} from "@bms/common-ui";
import { FormModal } from "../../../../components";
import dayjs from "dayjs";
import React from "react";
import { useTranslation } from "react-i18next";

const userService = new UserService().promisify();

interface UserResendConfirmationEmailByUserModalProps {
  user: IUserModel;
  visible: boolean;
  onCancel: () => void;
  onSuccess?: () => void;
}

interface ResendConfirmationEmailFormValues {
  TokenValidationDays?: number;
  TokenValidationTime?: number;
}

export const UserResendConfirmationEmailByUserModal: React.FC<UserResendConfirmationEmailByUserModalProps> = ({
  user,
  visible,
  onCancel,
  onSuccess,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { notification } = useAppFeedback();

  const [resendConfirmationEmailByUser, { processing }] = useServiceCaller(
    async (values: ResendConfirmationEmailFormValues) => {
      const payload: IResendConfirmationModel = {
        SearchFilter: {
          UserId: user.Id,
        },
        TokenValidationTime: `${values.TokenValidationDays || 0}`,
      };

      if (values.TokenValidationTime) {
        const tokenValidationTime = dayjs(values.TokenValidationTime);
        payload.TokenValidationTime += `.${tokenValidationTime.format(
          "HH:mm:ss"
        )}`;
      }

      const result = await userService.resendConfirmationEmails(payload);

      if (!result.ok) {
        return notification.error({
          message: t(
            "RESEND_CONFIRMATION_EMAIL_FAILURE_MESSAGE",
            "Failed to send confirmation email"
          ),
          description: result.error?.Message,
        });
      }

      notification.success({
        message: t(
          "RESEND_CONFIRMATION_EMAIL_SUCCESS_MESSAGE",
          "You have successfully sent confirmation email"
        ),
      });
      return onSuccess?.();
    },
    [user.Id]
  );

  return (
    <FormModal
      isLoading={processing}
      isVisible={visible}
      isNewForm={true}
      isDeleteButtonEnabled={false}
      createFormTitle={t("USER_RESEND_CONFIRMATION_EMAIL_TITLE")}
      editFormTitle={t("USER_RESEND_CONFIRMATION_EMAIL_TITLE")}
      modalClassName="UserResendConfirmationEmailByUserModal"
      submitFormName="ResendConfirmationEmailForm"
      onCloseModal={onCancel}
      onDeleteButtonClick={() => {}}
    >
      <Form
        form={form}
        name="ResendConfirmationEmailForm"
        className="ResendConfirmationEmailForm"
        onFinish={resendConfirmationEmailByUser}
        style={{ textAlign: "left" }}
        labelCol={{
          xs: { span: 24 },
          sm: { span: 24 },
          md: { span: 24 },
          lg: { span: 24 },
        }}
        wrapperCol={{
          xs: { span: 24 },
          sm: { span: 24 },
          md: { span: 24 },
          lg: { span: 24 },
        }}
      >
        <Form.Item
          name="Information"
          label={t(
            "USER_RESEND_CONFIRMATION_EMAIL_INFORMATION_LABEL",
            "Information"
          )}
          labelAlign="left"
        >
          <label>
            {t(
              "USER_RESEND_CONFIRMATION_EMAIL_INFORMATION",
              "The email confirmation link will be re-sent to the selected user."
            )}
          </label>
        </Form.Item>
        <Form.Item
          name="TokenValidation"
          label={t(
            "USER_RESEND_CONFIRMATION_EMAIL_TOKEN_VALIDATION_INTERVAL_LABEL",
            "Token validation interval"
          )}
          labelAlign="left"
        >
          <Row gutter={8}>
            <Col>
              <Form.Item
                name="TokenValidationDays"
                key="TokenValidationDays"
                label={t(
                  "USER_RESEND_CONFIRMATION_EMAIL_TOKEN_VALIDATION_DAYS_LABEL",
                  "Days"
                )}
                initialValue="1"
              >
                <InputNumber
                  min={0}
                  max={30}
                  placeholder={t(
                    "USER_RESEND_CONFIRMATION_EMAIL_TOKEN_VALIDATION_DAYS_PLACEHOLDER",
                    "Enter token validation days interval"
                  )}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="TokenValidationTime"
                key="TokenValidationTime"
                label={t(
                  "USER_RESEND_CONFIRMATION_EMAIL_TOKEN_VALIDATION_TIME_LABEL",
                  "Time"
                )}
              >
                <TimePicker
                  placeholder={t(
                    "USER_RESEND_CONFIRMATION_EMAIL_TOKEN_VALIDATION_TIME_PLACEHOLDER",
                    "Select a token validation time"
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </FormModal>
  );
};
