import React from "react";
import { Row } from "../Row";
import { Col } from "../Col";
import "./CountExtra.scss";

interface IPlayedCountExtraProps {
  count: number;
  icon?: React.ReactNode;
}

export const CountExtra: React.FC<IPlayedCountExtraProps> = ({ count, icon }) =>
  Number.isFinite(count) ? (
    <Row className="tile-count-extra" justify="end" align="middle">
      {icon && <Col>{icon}</Col>}
      <Col>{count}</Col>
    </Row>
  ) : null;
