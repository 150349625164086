import {
  IQuizCategoryModel,
  IQuizCategoriesListModel,
  IQuizCategoriesSearchFilterModel,
} from "../../models";
import { DictionaryServiceBase } from "../Base";

export class QuizCategoryService extends DictionaryServiceBase<
  IQuizCategoryModel,
  number,
  IQuizCategoriesListModel,
  IQuizCategoriesSearchFilterModel
> {
  get url(): string {
    return "/QuizCategories";
  }
}
