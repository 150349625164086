import {
  CellType,
  CellTypeHelper,
  IApplicationComponentModel,
  IApplicationComponentPropertyModel,
  PropertyType,
} from "@bms/common-services";
import { ComponentPropertyModel } from "./ComponentPropertyModel";

export class ApplicationSearchPropertyModel extends ComponentPropertyModel {
  CellType: CellType = CellType.Default;

  constructor(component?: IApplicationComponentModel) {
    super();

    if (component) {
      this.init(component);
    }
  }

  initProperties(component: IApplicationComponentModel): void {
    if (!component.Properties) {
      component.Properties = [];
    }

    let cellTypeProperty = component.Properties.find(
      (row: IApplicationComponentPropertyModel) => row.Name === "CellType"
    );

    if (!cellTypeProperty) {
      this.onPropertyChange("CellType", component);
    }
  }

  setProperties(component: IApplicationComponentModel): void {
    let cellTypeProperty = component.Properties?.find(
      (row: IApplicationComponentPropertyModel) => row.Name === "CellType"
    );

    if (cellTypeProperty && cellTypeProperty.Value) {
      this.CellType = CellTypeHelper.getValue(
        cellTypeProperty.Value.StringValue || "DEFAULT"
      );
    }
  }

  setProperty(property: IApplicationComponentPropertyModel) {
    switch (property.Name) {
      case "CellType":
        property.PropertyType = PropertyType.String;
        property.IsEnumerable = false;
        break;
    }
  }

  setPropertyValue(property: IApplicationComponentPropertyModel) {
    switch (property.Name) {
      case "CellType":
        if (property.Value) {
          property.Value.StringValue = CellTypeHelper.getStringValue(
            this.CellType
          );
        }
        break;
    }
  }
}
