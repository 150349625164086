import React, { useState } from "react";
import {
  Button,
  DatePicker,
  Form,
  IFormValues,
  Modal,
  useAppFeedback,
} from "@bms/common-ui";
import {
  IErrorModel,
  INotificationModel,
  NotificationsService,
} from "@bms/common-services";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

const userNotificationService = new NotificationsService();

export interface INotificationSendModalProps {
  visible: boolean;
  notification?: INotificationModel;
  onCancel?: () => void;
  onSuccess?: () => void;
}

export const NotificationSendModal: React.FC<INotificationSendModalProps> = ({
  visible,
  notification: data,
  onCancel,
  onSuccess,
}: INotificationSendModalProps) => {
  const { t } = useTranslation();
  const { notification } = useAppFeedback();
  const [form] = Form.useForm();

  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [error, setError] = useState<IErrorModel>();

  const isFinished = !isProcessing && error ? true : false;

  const reset = () => {
    form?.resetFields(["ScheduledOn"]);
    setError(undefined);
  };

  const onFinish = async (values: IFormValues) => {
    if (!data || !data.RowVersion) {
      return;
    }

    setIsProcessing(true);

    try {
      const scheduledOn = values.ScheduledOn
        ? values.ScheduledOn.toISOString()
        : null;

      await userNotificationService
        .send(data.Id, data.RowVersion, scheduledOn)
        .toPromise();
      setIsProcessing(false);
      onSuccess?.();
      reset();
      notification.success({
        message: t("NOTIFICATION_SEND_SUCCESSFULLY"),
      });
    } catch (error) {
      setError(error as IErrorModel);
      setIsProcessing(false);
      notification.error({
        message: t("NOTIFICATION_SEND_FAILURE"),
      });
    }
  };

  const onCancelModal = () => {
    onCancel?.();
    reset();
  };

  const renderFooter = () => {
    if (isFinished) {
      return null;
    }

    return (
      <>
        <Button
          key="cancel"
          onClick={onCancelModal}
          type="link"
          disabled={isProcessing}
        >
          {t("BUTTON_CANCEL")}
        </Button>
        <Button
          form="NotificationSendForm"
          key="submit"
          htmlType="submit"
          type="primary"
          loading={isProcessing}
          disabled={isProcessing}
        >
          {t("BUTTON_SEND")}
        </Button>
      </>
    );
  };

  return (
    <Modal
      title={t("NOTIFICATION_SEND_TITLE")}
      footer={renderFooter()}
      processing={isProcessing}
      width={600}
      visible={visible}
      destroyOnClose={true}
      maskClosable={false}
      onCancel={onCancelModal}
    >
      <Form
        form={form}
        name="NotificationSendForm"
        className="NotificationSendForm"
        onFinish={onFinish}
      >
        <Form.Item
          name="ScheduledOn"
          label={t("NOTIFICATION_SCHEDULEDON_FIELD")}
          labelCol={{
            xs: { span: 24 },
            sm: { span: 12 },
            md: { span: 6 },
            lg: { span: 6 },
          }}
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 12 },
            md: { span: 12 },
            lg: { span: 12 },
          }}
        >
          <DatePicker
            placeholder={t("NOTIFICATION_SCHEDULEDON_INPUT_FIELD")}
            showToday={true}
            showTime
            style={{ width: `100%` }}
            disabledDate={(current) =>
              current.isBefore(dayjs().subtract(1, "minutes").toDate())
            }
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
