import Payment from "payment";

export const validateCardNumber = (errorMessage: string) => ({
  validator: (
    _rule: any,
    value: string,
    callback: (error?: string) => void
  ) => {
    if (!Payment.fns.validateCardNumber(value)) {
      callback(errorMessage);
    }
    callback();
  },
});

export const validateCardExpiry = (errorMessage: string) => ({
  validator: (
    _rule: any,
    value: string,
    callback: (error?: string) => void
  ) => {
    if (!Payment.fns.validateCardExpiry(value)) {
      callback(errorMessage);
    }
    callback();
  },
});

export const validateCardCVC = (errorMessage: string) => ({
  validator: (
    _rule: any,
    value: string,
    callback: (error?: string) => void
  ) => {
    if (!Payment.fns.validateCardCVC(value)) {
      callback(errorMessage);
    }
    callback();
  },
});
