import { IErrorModel } from "../../models";
import { IAssetSourceEntities } from "../../models/Asset/IAssetSourceEntities";

export const GET_SOURCE_ENTITIES = "GET_SOURCE_ENTITIES";
export const GET_SOURCE_ENTITIES_SUCCESS = "GET_SOURCE_ENTITIES_SUCCESS";
export const GET_SOURCE_ENTITIES_FAILURE = "GET_SOURCE_ENTITIES_FAILURE";

export type ISourceEntitiesState = IAssetSourceEntities[]

export interface IGetSourceEntitiesAction {
  type: typeof GET_SOURCE_ENTITIES;
  onSuccess?: (data: ISourceEntitiesState[]) => void;
}

export interface IGetSourceEntitiesSuccessAction {
  type: typeof GET_SOURCE_ENTITIES_SUCCESS;
  payload: IAssetSourceEntities[]
}

export interface IGetSourceEntitiesFailureAction {
  type: typeof GET_SOURCE_ENTITIES_FAILURE;
  error?: IErrorModel;
}

export type SourceEntitiesActionsTypes =
  | IGetSourceEntitiesAction
  | IGetSourceEntitiesSuccessAction
  | IGetSourceEntitiesFailureAction;
