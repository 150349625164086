import { ICommonAppState } from "@bms/common-services";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { withScreenContext } from "../../../../context";
import { withScreenDesignerContext } from "../../context";
import {
  ApplicationHeaderPreview as ApplicationHeaderPreviewDefinition,
  IApplicationHeaderPreviewDispatchProps,
  IApplicationHeaderPreviewOwnProps,
  IApplicationHeaderPreviewStateProps,
} from "./ApplicationHeaderPreview";

const mapStateToProps = (
  state: ICommonAppState
): IApplicationHeaderPreviewStateProps => {
  return {};
};

const mapDispatchToProps = (
  dispatch: Dispatch
): IApplicationHeaderPreviewDispatchProps => ({});
export const ApplicationHeaderPreview = connect<
  IApplicationHeaderPreviewStateProps,
  IApplicationHeaderPreviewDispatchProps,
  IApplicationHeaderPreviewOwnProps,
  ICommonAppState
>(
  mapStateToProps,
  mapDispatchToProps
)(
  withTranslation()(
    withScreenDesignerContext(
      withScreenContext(ApplicationHeaderPreviewDefinition)
    )
  )
);
