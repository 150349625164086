import {
  IErrorModel,
  INotificationListModel,
  INotificationSearchFilterModel,
  IUserNotificationListModel,
  IUserNotificationModel,
  IUserNotificationSearchFilterModel,
} from "../../models";
import * as Consts from "./consts";
import { NotificationActionsTypes } from "./types";

export const searchNotifications = (
  filter: INotificationSearchFilterModel
): NotificationActionsTypes => {
  return {
    filter,
    type: Consts.SEARCH_NOTIFICATIONS,
  };
};

export const searchNotificationsSuccess = (
  data: INotificationListModel
): NotificationActionsTypes => {
  return {
    payload: data,
    type: Consts.SEARCH_NOTIFICATIONS_SUCCESS,
  };
};

export const searchNotificationsFailure = (
  error?: IErrorModel
): NotificationActionsTypes => {
  return {
    error,
    type: Consts.SEARCH_NOTIFICATIONS_FAILURE,
  };
};

export const searchUserNotifications = (
  filter: IUserNotificationSearchFilterModel
): NotificationActionsTypes => {
  return {
    filter,
    type: Consts.SEARCH_USER_NOTIFICATIONS,
  };
};

export const searchUserNotificationsSuccess = (
  data: IUserNotificationListModel
): NotificationActionsTypes => {
  return {
    payload: data,
    type: Consts.SEARCH_USER_NOTIFICATIONS_SUCCESS,
  };
};

export const searchUserNotificationsFailure = (
  error?: IErrorModel
): NotificationActionsTypes => {
  return {
    error,
    type: Consts.SEARCH_USER_NOTIFICATIONS_FAILURE,
  };
};

export const notificationReceived = (
  data: IUserNotificationModel
): NotificationActionsTypes => {
  return {
    payload: data,
    type: Consts.NOTIFICATION_RECEIVED,
  };
};

export const markAsRead = (data: number[]): NotificationActionsTypes => {
  return {
    payload: data,
    type: Consts.NOTIFICATIONS_MARK_AS_READ,
  };
};

export const markAsReadSuccess = (data: number[]): NotificationActionsTypes => {
  return {
    payload: data,
    type: Consts.NOTIFICATIONS_MARK_AS_READ_SUCCESS,
  };
};

export const markAsReadFailure = (
  error?: IErrorModel
): NotificationActionsTypes => {
  return {
    error,
    type: Consts.NOTIFICATIONS_MARK_AS_READ_FAILURE,
  };
};

export const Actions = {
  markAsRead,
  notificationReceived,
  searchNotifications,
  searchUserNotifications,
};
