import { ICommonAppState } from "..";

export const assetSelector = (state: ICommonAppState) => state.asset;

export const assetByIdSelector = (
  state: ICommonAppState,
  id?: string | number
) => {
  if (id) {
    return state.asset.details[id];
  }
};
