import { push, replace } from "connected-react-router";
import { ActionsObservable, ofType, StateObservable } from "redux-observable";
import { of } from "rxjs";
import { catchError, filter, map, mapTo, switchMap } from "rxjs/operators";
import { ICommonAppState } from "..";
import { IErrorModel, isFieldError } from "../../models";
import { ResetPasswordService } from "../../services";
import {
  resetPasswordFailure,
  resetPasswordLinkFailure,
  resetPasswordLinkSuccess,
  resetPasswordSuccess,
} from "./actions";
import {
  IResetPasswordAction,
  IResetPasswordLinkAction,
  IResetPasswordLinkFailureAction,
  IResetPasswordLinkSuccessAction,
  IResetPasswordSuccessAction,
  RESET_PASSWORD,
  RESET_PASSWORD_LINK,
  RESET_PASSWORD_LINK_FAILURE,
  RESET_PASSWORD_LINK_SUCCESS,
  RESET_PASSWORD_SUCCESS,
} from "./types";

const resetPasswordService: ResetPasswordService = new ResetPasswordService();

// Reset password
const resetPasswordEpic = (
  action$: ActionsObservable<IResetPasswordAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(RESET_PASSWORD),
    switchMap((action: IResetPasswordAction) =>
      resetPasswordService.resetPassword(action.data).pipe(
        map(() => resetPasswordSuccess(action.data, action.redirectUrl)),
        catchError((error: IErrorModel) => {
          return of(resetPasswordFailure(error));
        })
      )
    )
  );

export const resetPasswordSuccessEpic = (
  action$: ActionsObservable<IResetPasswordSuccessAction>
) => action$.pipe(ofType(RESET_PASSWORD_SUCCESS), mapTo(push("/login")));

// Reset password link
const resetPasswordLinkEpic = (
  action$: ActionsObservable<IResetPasswordLinkAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(RESET_PASSWORD_LINK),
    switchMap((action: IResetPasswordLinkAction) =>
      resetPasswordService.resetPasswordLink(action.data).pipe(
        map(() => resetPasswordLinkSuccess(action.data)),
        catchError((error: IErrorModel) => of(resetPasswordLinkFailure(error)))
      )
    )
  );

export const resetPasswordLinkSuccessEpic = (
  action$: ActionsObservable<IResetPasswordLinkSuccessAction>
) =>
  action$.pipe(
    ofType(RESET_PASSWORD_LINK_SUCCESS),
    mapTo(replace("/forgot-password-success"))
  );

export const resetPasswordLinkFailureEpic = (
  action$: ActionsObservable<IResetPasswordLinkFailureAction>
) =>
  action$.pipe(
    ofType(RESET_PASSWORD_LINK_FAILURE),
    filter((action: IResetPasswordLinkFailureAction) =>
      action.error ? !isFieldError(action.error) : true
    ),
    mapTo(replace("/forgot-password-success"))
  );

export const resetPasswordEpics = [
  resetPasswordEpic,
  resetPasswordSuccessEpic,
  resetPasswordLinkEpic,
  resetPasswordLinkSuccessEpic,
  resetPasswordLinkFailureEpic,
];
