export enum PlatformType {
  Any = "ANY",
  AndroidPhone = "ANDROID_PHONE",
  AndroidTablet = "ANDROID_TABLET",
  AndroidTV = "ANDROID_TV",
  AppleTV = "APPLE_TV",
  iOSPhone = "IOS_PHONE",
  iPad = "IPAD",
  Tizen = "TIZEN",
  Web = "WEB",
  WebOS = "WEB_OS",
  Roku = "ROKU",
  HbbTV = "HBB_TV",
}
