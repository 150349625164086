import {
  ComponentType,
  ComponentTypeHelper,
  IApplicationComponentModel,
  IApplicationScreenModel,
  ItemsAlignmentType,
  RecordStatus,
} from "@bms/common-services";
import { ApplicationScreenContext } from "../../../../context/ApplicationScreenContext";
import React, { useContext, useMemo } from "react";
import {
  ApplicationFooterItemPropertyModel,
  ApplicationFooterPropertyModel,
} from "../../models";
import { ApplicationFooterItemComponentPreview } from "../ApplicationFooterItemComponentPreview";
import "./ApplicationFooterPreview.scss";

export interface IApplicationFooterPreviewProps {
  applicationFooterScreen: IApplicationScreenModel;
}

export const ApplicationFooterPreview = ({
  applicationFooterScreen,
}: IApplicationFooterPreviewProps) => {
  const { screen, component, onComponentSelect } = useContext(
    ApplicationScreenContext
  );

  const screenComponent = screen?.Components?.find(
    (component) =>
      component.ComponentTypeCode === ComponentType.ScreenProperties
  );
  const screenProperties = new ApplicationFooterPropertyModel(screenComponent);

  const onFooterComponentSelect = (componentId: number) => {
    if (
      screen &&
      screen.Id === applicationFooterScreen.Id &&
      screen.Components &&
      onComponentSelect
    ) {
      const component = screen.Components.find(
        (row: IApplicationComponentModel) => row.Id === componentId
      );

      onComponentSelect(component);
    }
  };

  const renderFooterItemsForWeb = useMemo(() => {
    const itemsView: React.ReactNode[] = [];
    const footerComponents = screen?.Components?.filter(
      (component) =>
        component.ComponentTypeCode === ComponentType.ApplicationFooterItem &&
        component.RecordStatus !== RecordStatus.Deleted
    );

    if (footerComponents && footerComponents.length) {
      for (let i = 0; i < footerComponents.length; i++) {
        const screenComponent = footerComponents[i];
        const footerProperties = new ApplicationFooterItemPropertyModel(
          screenComponent
        );

        const style: React.CSSProperties = {
          borderWidth: "1px",
          borderStyle: "dashed",
          borderColor: ComponentTypeHelper.getColor(
            screenComponent.ComponentTypeCode
          ),
          cursor: "pointer",
        };

        if (component && component.Id === screenComponent.Id) {
          style.borderWidth = "2px";
          style.borderStyle = "solid";
        }

        itemsView.push(
          <div
            key={screenComponent.Id}
            className="Component"
            style={style}
            onClick={() => onFooterComponentSelect(screenComponent.Id)}
          >
            <ApplicationFooterItemComponentPreview
              key={screenComponent.Guid}
              component={screenComponent}
              properties={footerProperties}
            />
          </div>
        );
      }
    }
    return itemsView;
  }, [screen?.Components]);
  const itemsAlignment =
    screenProperties.ItemsAlignment === ItemsAlignmentType.JustifyCenter
      ? "fit-content(100%)"
      : screenProperties.Columns
      ? `${100 / screenProperties.Columns}%`
      : "20%";

  return (
    <div
      className="ApplicationFooterPreview"
      style={{
        backgroundImage: `url(${screenProperties.BackgroundAbsoluteUrl})`,
      }}
    >
      <div
        className="ApplicationFooterPreview__Grid "
        style={{
          gridTemplateColumns: `repeat(${screenProperties.Columns}, ${itemsAlignment})`,
        }}
      >
        {renderFooterItemsForWeb}
      </div>
    </div>
  );
};
