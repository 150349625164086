import React, { useRef, useState } from "react";
import { getRandomColorHex, PageContent } from "@bms/common-ui";
import { SalesRevenueChart } from "./SalesRevenueChart";
import { useSalesRevenueDataProvider } from "../../hooks";
import { SalesRevenueHeader } from "./SalesRevenueHeader";
import {
  SalesRevenuePaymentTypeSalesData,
  SalesRevenueSalesDataCard,
} from "./SalesRevenueCards";
import "./SalesRevenue.scss";
import { IAnalyticsSalesRevenueAssetModel } from "@bms/common-services";

export interface IAnalyticsSalesRevenueAssetWithColorsModel
  extends IAnalyticsSalesRevenueAssetModel {
  Color: string;
}

export const SalesRevenue = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [assetsListWithColors, setAssetsListWithColors] = useState<
    IAnalyticsSalesRevenueAssetWithColorsModel[]
  >([]);
  const {
    data,
    filter,
    loading,
    refreshData,
    setFilter,
    resetFilter,
  } = useSalesRevenueDataProvider();

  if (data?.Results && assetsListWithColors?.length === 0) {
    const datas = data.Results.map((o) => ({
      ...o,
      Color: getRandomColorHex(),
    }));
    setAssetsListWithColors(datas);
  }

  return (
    <PageContent ref={ref} className="SalesRevenue">
      <SalesRevenueHeader
        filter={filter}
        setFilter={setFilter}
        refreshData={refreshData}
        resetFilter={resetFilter}
      />

      <SalesRevenueChart
        data={data?.Results}
        assetsListWithColors={assetsListWithColors}
        filter={filter}
        isLoading={loading}
        aggregationPeriod={filter.AggregationPeriod}
      />

      <div className="SalesRevenue__cards-container">
        <SalesRevenueSalesDataCard data={data} isLoading={loading} />
        <SalesRevenuePaymentTypeSalesData data={data} isLoading={loading} />
      </div>
    </PageContent>
  );
};
