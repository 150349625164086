/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { HTTP_RESPONSE_TYPE } from "../../../../constants";
import {
  IErrorModel as ICommonErrorModel,
  IAssetCollectionModel,
  IPlaylistSourceModel,
} from "../../../../models";
import { PlayListSourceType } from "../../../../modules";
import { IPlaylistModel } from "../models";

const EXTERNAL_SOURCE = "JWPlayer";

export class ModelsMapperHelper {
  static toAssetCollectionModel(entity: IPlaylistModel): IAssetCollectionModel {
    let result: IAssetCollectionModel = {
      Id: entity.id,
      Name: entity.metadata?.title,
      Description: entity.metadata?.description,
      Created: entity.created,
      CreatedByFullName: "Unknown",
      ExternalSource: EXTERNAL_SOURCE,
    };

    return result;
  }

  static toPlaylistSourceModel(entity: IPlaylistModel): IPlaylistSourceModel {
    let result: IPlaylistSourceModel = {
      Id: entity.id,
      Name: entity.metadata?.title,
      Description: entity.metadata?.description,
    };

    switch (entity.playlist_type) {
      case "search":
        result.Type = PlayListSourceType.Search;
        result.TypeDisplayName = "Search";
        break;
      case "recommendations":
        result.Type = PlayListSourceType.Recommendations;
        result.TypeDisplayName = "Recommendations";
        break;
      case "watchlist":
        result.Type = PlayListSourceType.Custom;
        result.TypeDisplayName = "Watchlist";
        result.TypeOptions = [
          PlayListSourceType.MyFavorites,
          PlayListSourceType.RecentlyWatched,
        ];
        break;
      default:
        result.Type = PlayListSourceType.Standard;
        break;
    }

    return result;
  }

  static toErrorModel(error: any): ICommonErrorModel {
    return {
      Message: error?.message,
      ResultType: HTTP_RESPONSE_TYPE.ERROR,
    };
  }
}
