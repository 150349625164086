import {
  IApplicationComponentModel,
  IApplicationComponentPropertyModel,
  PropertyType,
} from "@bms/common-services";
import { ComponentPropertyModel } from "./ComponentPropertyModel";

export class ApplicationRegisterPropertyModel extends ComponentPropertyModel {
  BackgroundUrl?: string;

  BackgroundAbsoluteUrl?: string;

  constructor(component?: IApplicationComponentModel) {
    super();

    if (component) {
      this.init(component);
    }
  }

  initProperties(component: IApplicationComponentModel): void {
    if (!component.Properties) {
      component.Properties = [];
    }

    let BackgroundUrlProperty = component.Properties.find(
      (row: IApplicationComponentPropertyModel) => row.Name === "BackgroundUrl"
    );

    if (!BackgroundUrlProperty) {
      this.onPropertyChange("BackgroundUrl", component);
    }
  }

  setProperties(component: IApplicationComponentModel): void {
    let BackgroundUrlProperty = component.Properties?.find(
      (row: IApplicationComponentPropertyModel) => row.Name === "BackgroundUrl"
    );

    if (BackgroundUrlProperty && BackgroundUrlProperty.Value) {
      this.BackgroundUrl = BackgroundUrlProperty.Value.StringValue;
      this.BackgroundAbsoluteUrl = BackgroundUrlProperty.Value.UrlValue;
    }
  }

  setProperty(property: IApplicationComponentPropertyModel) {
    switch (property.Name) {
      case "BackgroundUrl":
        property.PropertyType = PropertyType.Url;
        property.IsEnumerable = false;
        break;
    }
  }

  setPropertyValue(property: IApplicationComponentPropertyModel) {
    switch (property.Name) {
      case "BackgroundUrl":
        if (property.Value) {
          property.Value.StringValue = this.BackgroundUrl;
          property.Value.UrlValue = this.BackgroundAbsoluteUrl;
        }
        break;
    }
  }
}
