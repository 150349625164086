import { ActionsObservable, StateObservable, ofType } from "redux-observable";
import { RatingActionTypes, RatingState } from "./types";
import {
  RATING_CATEGORIES_SEARCH,
  RATING_CATEGORY_DELETE,
  RATING_CATEGORY_INSERT,
  RATING_CATEGORY_UPDATE,
} from "./consts";
import { RatingCategoriesService } from "../../services";
import { catchError, map, switchMap } from "rxjs/operators";
import {
  IErrorModel,
  IRatingCategoryFilterModel,
  IRatingCategoryModel,
} from "../../models";
import { of } from "rxjs";
import {
  deleteRatingCategoryFailure,
  deleteRatingCategorySuccess,
  insertRatingCategoryFailure,
  insertRatingCategorySuccess,
  searchRatingCategoriesFailure,
  searchRatingCategoriesSuccess,
  updateRatingCategoryFailure,
  updateRatingCategorySuccess,
} from "./actions";

const ratingCategoriesService = new RatingCategoriesService();

export const searchRatingCategoriesEpic = (
  action$: ActionsObservable<RatingActionTypes>,
  state: StateObservable<RatingState>
) =>
  action$.pipe(
    ofType(RATING_CATEGORIES_SEARCH),
    switchMap((action) =>
      ratingCategoriesService
        .search(action.payload as IRatingCategoryFilterModel)
        .pipe(
          map((data) => {
            return searchRatingCategoriesSuccess(data);
          }),
          catchError((error: IErrorModel) =>
            of(searchRatingCategoriesFailure(error))
          )
        )
    )
  );

export const insertRatingCategoriesEpic = (
  action$: ActionsObservable<RatingActionTypes>
) =>
  action$.pipe(
    ofType(RATING_CATEGORY_INSERT),
    switchMap((action) =>
      ratingCategoriesService
        .insert(action.payload as IRatingCategoryModel)
        .pipe(
          map(() => {
            return insertRatingCategorySuccess();
          }),
          catchError((error: IErrorModel) =>
            of(insertRatingCategoryFailure(error))
          )
        )
    )
  );

export const deleteRatingCategoriesEpic = (
  action$: ActionsObservable<RatingActionTypes>
) =>
  action$.pipe(
    ofType(RATING_CATEGORY_DELETE),
    switchMap((action) =>
      ratingCategoriesService
        .delete(action.payload as IRatingCategoryModel)
        .pipe(
          map(() => {
            return deleteRatingCategorySuccess();
          }),
          catchError((error: IErrorModel) =>
            of(deleteRatingCategoryFailure(error))
          )
        )
    )
  );

export const updateRatingCategoriesEpic = (
  action$: ActionsObservable<RatingActionTypes>
) =>
  action$.pipe(
    ofType(RATING_CATEGORY_UPDATE),
    switchMap((action) =>
      ratingCategoriesService
        .update(action.payload as IRatingCategoryModel)
        .pipe(
          map(() => {
            return updateRatingCategorySuccess();
          }),
          catchError((error: IErrorModel) =>
            of(updateRatingCategoryFailure(error))
          )
        )
    )
  );

export const ratingEpics = [
  searchRatingCategoriesEpic,
  insertRatingCategoriesEpic,
  deleteRatingCategoriesEpic,
  updateRatingCategoriesEpic,
];
