import { CommonStore, ICommonAppState } from "@bms/common-services";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { DictionaryPlatformList as DictionaryPlatformListDefinition } from "./DictionaryPlatformList";

const mapStateToProps = (state: ICommonAppState) => {
  return {
    platforms: state.common.platforms,
    isLoadingData: state.common.isLoadingData,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  selectPlatforms: () => {
    return dispatch(CommonStore.Actions.selectPlatforms());
  },
});

export const DictionaryPlatformList = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(DictionaryPlatformListDefinition));
