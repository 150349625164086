import React, { useEffect, useState } from "react";
import { Button } from "@bms/common-ui";
import { FormModal } from "../../../../components";
import { useTranslation } from "react-i18next";
import { TranslationImportForm } from "./TranslationImportForm";
import { useSelector } from "react-redux";
import {
  ApplicationConfigurationStore,
  ICommonAppState,
} from "@bms/common-services";

const commonSelector = (state: ICommonAppState) => state.common;

interface TranslationImportExportModalProps {
  visible: boolean;
  closeModal: () => void;
}

export const TranslationImportModal: React.FC<TranslationImportExportModalProps> = (
  props
) => {
  const { visible, closeModal } = props;
  const { actionType } = useSelector(commonSelector);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    switch (actionType) {
      case ApplicationConfigurationStore.Consts.IMPORT_TRANSLATIONS:
        setIsProcessing(true);
      case ApplicationConfigurationStore.Consts.IMPORT_TRANSLATIONS_SUCCESS:
        setIsProcessing(false);
      case ApplicationConfigurationStore.Consts.IMPORT_TRANSLATIONS_FAILURE:
        setIsProcessing(false);
    }
  }, [actionType]);

  return (
    <div className="TranslationImportExportModal">
      <FormModal
        isLoading={isProcessing}
        isVisible={visible}
        isNewForm={true}
        isDeleteButtonEnabled={false}
        createFormTitle={t("TRANSLATION_IMPORT_TRANSLATION")}
        editFormTitle={t("TRANSLATION_IMPORT_TRANSLATION")}
        modalClassName="ImportTranslationsModal"
        submitFormName="TranslationImportForm"
        onCloseModal={closeModal}
        onDeleteButtonClick={() => {}}
      >
        <TranslationImportForm />
      </FormModal>
    </div>
  );
};
