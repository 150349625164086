import {
  IErrorModel,
  ITextComponentModel,
  UploadFileInfoModel,
} from "../../models";
import * as Consts from "./consts";
import {
  TextComponentsActionsTypes,
  IGetTextComponentAction,
  IInsertTextComponentAction,
  IUpdateTextComponentAction,
  IGetTextComponentAttachmentUploadUrlAction,
  IGetTextComponentAttachmentUrlAction,
} from "./types";

// GET_TEXT_COMPONENT
export const getTextComponent = (
  textComponentId: number,
  onSuccess?: (data: ITextComponentModel) => void
): IGetTextComponentAction => {
  return {
    textComponentId,
    onSuccess,
    type: Consts.GET_TEXT_COMPONENT,
  };
};

export const getTextComponentSuccess = (
  data: ITextComponentModel
): TextComponentsActionsTypes => {
  return {
    payload: data,
    type: Consts.GET_TEXT_COMPONENT_SUCCESS,
  };
};

export const getTextComponentFailure = (
  textComponentId: number,
  error?: IErrorModel
): TextComponentsActionsTypes => {
  return {
    error,
    textComponentId,
    type: Consts.GET_TEXT_COMPONENT_FAILURE,
  };
};

// GET_TEXT_COMPONENT_ATTACHEMENT_UPLOAD_URL
export const getTextComponentAttachmentUploadUrl = (
  textComponentId: number,
  attachmentGuid: string,
  onSuccess?: (data: UploadFileInfoModel) => void
): IGetTextComponentAttachmentUploadUrlAction => {
  return {
    attachmentGuid,
    onSuccess,
    textComponentId,
    type: Consts.GET_TEXT_COMPONENT_ATTACHEMENT_UPLOAD_URL,
  };
};

export const getTextComponentAttachmentUploadUrlSuccess = (
  textComponentId: number,
  attachmentGuid: string,
  data: UploadFileInfoModel
): TextComponentsActionsTypes => {
  return {
    attachmentGuid,
    payload: data,
    textComponentId,
    type: Consts.GET_TEXT_COMPONENT_ATTACHEMENT_UPLOAD_URL_SUCCESS,
  };
};

export const getTextComponentAttachmentUploadUrlFailure = (
  textComponentId: number,
  attachmentGuid: string,
  error?: IErrorModel
): TextComponentsActionsTypes => {
  return {
    attachmentGuid,
    error,
    textComponentId,
    type: Consts.GET_TEXT_COMPONENT_ATTACHEMENT_UPLOAD_URL_FAILURE,
  };
};

// GET_TEXT_COMPONENT_ATTACHEMENT_URL
export const getTextComponentAttachmentUrl = (
  textComponentId: number,
  attachmentGuid: string,
  onSuccess?: (data: UploadFileInfoModel) => void
): IGetTextComponentAttachmentUrlAction => {
  return {
    attachmentGuid,
    textComponentId,
    type: Consts.GET_TEXT_COMPONENT_ATTACHEMENT_URL,
    onSuccess,
  };
};

export const getTextComponentAttachmentUrlSuccess = (
  textComponentId: number,
  attachmentGuid: string,
  data: UploadFileInfoModel
): TextComponentsActionsTypes => {
  return {
    attachmentGuid,
    payload: data,
    textComponentId,
    type: Consts.GET_TEXT_COMPONENT_ATTACHEMENT_URL_SUCCESS,
  };
};

export const getTextComponentAttachmentUrlFailure = (
  textComponentId: number,
  attachmentGuid: string,
  error?: IErrorModel
): TextComponentsActionsTypes => {
  return {
    attachmentGuid,
    error,
    textComponentId,
    type: Consts.GET_TEXT_COMPONENT_ATTACHEMENT_URL_FAILURE,
  };
};

// INSERT_TEXT_COMPONENT
export const insertTextComponent = (
  data: ITextComponentModel,
  onSuccess?: (data: ITextComponentModel) => void
): IInsertTextComponentAction => {
  return {
    data,
    onSuccess,
    type: Consts.INSERT_TEXT_COMPONENT,
  };
};

export const insertTextComponentSuccess = (
  data: ITextComponentModel
): TextComponentsActionsTypes => {
  return {
    payload: data,
    type: Consts.INSERT_TEXT_COMPONENT_SUCCESS,
  };
};

export const insertTextComponentFailure = (
  textComponentId?: number,
  error?: IErrorModel
): TextComponentsActionsTypes => {
  return {
    error,
    textComponentId,
    type: Consts.INSERT_TEXT_COMPONENT_FAILURE,
  };
};

// UPDATE_TEXT_COMPONENT
export const updateTextComponent = (
  data: ITextComponentModel,
  onSuccess?: (data: ITextComponentModel) => void
): IUpdateTextComponentAction => {
  return {
    data,
    onSuccess,
    type: Consts.UPDATE_TEXT_COMPONENT,
  };
};

export const updateTextComponentSuccess = (
  data: ITextComponentModel
): TextComponentsActionsTypes => {
  return {
    payload: data,
    type: Consts.UPDATE_TEXT_COMPONENT_SUCCESS,
  };
};

export const updateTextComponentFailure = (
  textComponentId?: number,
  error?: IErrorModel
): TextComponentsActionsTypes => {
  return {
    error,
    textComponentId,
    type: Consts.UPDATE_TEXT_COMPONENT_FAILURE,
  };
};

export const Actions = {
  getTextComponent,
  getTextComponentAttachmentUploadUrl,
  getTextComponentAttachmentUrl,
  insertTextComponent,
  updateTextComponent,
};
