import { Observable, Observer } from "rxjs";
import { HTTP_METHOD } from "../../constants";
import { AxiosSubscriber } from "../AxiosSubscriber";
import { PromisifiableBase } from "..";
import {
  ICreatorStreamModel,
  IUserStreamModel,
  IUserLiveChannelModel,
} from "../..";

export class UserStreamsService extends PromisifiableBase {
  get url(): string {
    return "/UserStreams";
  }

  public createOrUpdateUserLiveChannel = (
    data: IUserLiveChannelModel
  ): Observable<undefined> =>
    new Observable(
      (observer: Observer<undefined>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: `${this.url}/CreateOrUpdateUserLiveChannel`,
        })
    );

  public deleteUserLiveChannel = (
    data: IUserLiveChannelModel
  ): Observable<undefined> =>
    new Observable(
      (observer: Observer<undefined>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: `${this.url}/DeleteUserLiveChannel`,
        })
    );

  public getChannel = (userId: number): Observable<ICreatorStreamModel> =>
    new Observable(
      (observer: Observer<ICreatorStreamModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: {
              userId,
            },
          },
          method: HTTP_METHOD.GET,
          url: `${this.url}/GetChannel`,
        })
    );

  public getUserStream = (userId: number): Observable<IUserStreamModel> =>
    new Observable(
      (observer: Observer<IUserStreamModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: {
              userId,
            },
          },
          method: HTTP_METHOD.GET,
          url: `${this.url}/GetUserStream`,
        })
    );
}
