import React from "react";
import { WithScreenDesignerContextProps } from "../../context";
import { ApplicationFooterItemPropertyModel } from "../../models";
import { IComponentPreviewBaseProps } from "../ComponentPreviewBase";
import "./ApplicationFooterItemComponentPreview.scss";

export interface IApplicationFooterItemComponentPreviewOwnProps
  extends IComponentPreviewBaseProps {
  properties: ApplicationFooterItemPropertyModel;
}

export interface IApplicationFooterItemComponentPreviewProps
  extends IApplicationFooterItemComponentPreviewOwnProps,
    WithScreenDesignerContextProps {}

export function ApplicationFooterItemComponentPreview({
  properties,
}: IApplicationFooterItemComponentPreviewProps) {
  const iconUrl = properties.IconAbsoluteUrl
    ? properties.IconAbsoluteUrl
    : properties.IconUrl;
  const renderIcon = () => {
    return (
      <img
        src={iconUrl}
        className="ApplicationFooterItemComponentPreview__Icon"
      />
    );
  };
  return (
    <div className="ApplicationFooterItemComponentPreview">
      {iconUrl && renderIcon()}
      {properties.Title && <span>{properties?.Title}</span>}
    </div>
  );
}
