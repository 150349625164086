import React from "react";
import { useTranslation } from "react-i18next";
import { Form, useValidate } from "@bms/common-ui";
import {
  IModelBase,
  ITestCaseModel,
  ITestCaseRelationModel,
  TestCasesService,
} from "@bms/common-services";
import { LazyChoose } from "../../../../../../components";
import { PlatformTypeHelper } from "../../../../../../helpers";

type TestCaseLazyChoice = ITestCaseRelationModel & IModelBase;

interface TestCaseFieldProps {
  name: string;
  label: string;
  placeholder?: string;
  platforms?: string[];
}

const testCasesService = new TestCasesService().promisify();

export const TestCaseField = (props: TestCaseFieldProps) => {
  const { name, label, platforms, placeholder } = props;

  const { t } = useTranslation();
  const validate = useValidate();

  const loader = (search: string) =>
    testCasesService.search({
      PageNumber: 1,
      PageSize: 100,
      FullTextSearch: search,
      Platforms: platforms,
    });

  const candidateToOption = (item: ITestCaseModel) => {
    const platforms = item.Platforms?.map((platform) =>
      PlatformTypeHelper.getDescription(platform.PlatformCode)
    ).join(", ");

    return {
      label: `${item.Code!} (${platforms})`,
      value: item.Id.toString(),
      item: {
        TestCaseId: item.Id,
        TestCaseCode: item.Code,
        TestCaseName: item.Name,
      },
    };
  };

  const selectedToOption = (item: TestCaseLazyChoice) => {
    return {
      label: item.TestCaseCode!,
      value: item.TestCaseId.toString(),
      item,
    };
  };

  return (
    <Form.Item
      name={name}
      label={label}
      key={name}
      rules={validate.required({
        choiceField: true,
        message: t("REQUIRE_TEST_CASES_VALIDATION_MESSAGE"),
      })}
    >
      <LazyChoose<ITestCaseModel, TestCaseLazyChoice>
        placeholder={placeholder}
        multiple={false}
        disabled={!Boolean(platforms)}
        loaderDeps={[platforms]}
        loader={loader}
        candidateToOption={candidateToOption}
        selectedToOption={selectedToOption}
      />
    </Form.Item>
  );
};
