import { CreatorStreamState } from "@bms/common-services";
import { Tag } from "@bms/common-ui";
import React from "react";

export interface ChannelStateTagProps {
  state: CreatorStreamState;
}

export const MediaChannelStateTag = ({ state }: ChannelStateTagProps) => {
  let color;

  switch (state) {
    case CreatorStreamState.Starting:
      color = "#c4e200";
      break;
    case CreatorStreamState.Ready:
    case CreatorStreamState.Running:
      color = "#0a0";
      break;
    case CreatorStreamState.Stopping:
      color = "#066fbf";
      break;
    case CreatorStreamState.Stopped:
      color = "#1b06bf";
      break;
    case CreatorStreamState.Deleting:
      color = "#dc6d44";
      break;
    case CreatorStreamState.Deleted:
      color = "#dc4446";
      break;
  }

  return <Tag color={color}>{state}</Tag>;
};
