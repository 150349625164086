import React from "react";

export function Layouts() {
  return <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 15"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0.125 0.208344V14.7917H19.9167V0.208344H0.125ZM2.20833 2.29168H17.8333V6.45834H2.20833V2.29168ZM2.20833 12.7083V8.54168H6.375V12.7083H2.20833ZM8.45833 12.7083V8.54168H17.8333V12.7083H8.45833Z" />
  </svg>
}
