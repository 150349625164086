import React from "react";
import { Button as AntButton } from "antd";
import {
  ButtonProps as AntButtonProps,
  ButtonType as AntButtonType,
} from "antd/lib/button";
import "./Button.scss";

export type ButtonType = AntButtonType | "action";

export interface IButtonProps extends Omit<AntButtonProps, "type"> {
  type?: ButtonType;
}

export const Button = React.forwardRef((props: IButtonProps) => {
  const { children, type, ...rest } = props;

  return (
    <AntButton type={type as AntButtonType} {...rest}>
      {children}
    </AntButton>
  );
});
