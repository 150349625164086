import {
  IApplicationComponentModel,
  IApplicationComponentPropertyModel,
  ItemsAlignmentType,
  ItemsAlignmentTypeHelper,
  PropertyType,
} from "@bms/common-services";
import { ComponentPropertyModel } from "./ComponentPropertyModel";

export class ApplicationFooterPropertyModel extends ComponentPropertyModel {
  BackgroundUrl?: string;

  BackgroundAbsoluteUrl?: string;

  Columns?: number;

  ItemsAlignment?: ItemsAlignmentType = ItemsAlignmentType.FitToContent;

  constructor(component?: IApplicationComponentModel) {
    super();

    if (component) {
      this.init(component);
    }
  }

  initProperties(component: IApplicationComponentModel): void {
    if (!component.Properties) {
      component.Properties = [];
    }

    const columnsProperty = component.Properties.find(
      (row: IApplicationComponentPropertyModel) => row.Name === "Columns"
    );

    if (!columnsProperty) {
      this.onPropertyChange("Columns", component);
    }

    const itemsAlignmentProperty = component.Properties.find(
      (row: IApplicationComponentPropertyModel) => row.Name === "ItemsAlignment"
    );

    if (!itemsAlignmentProperty) {
      this.onPropertyChange("ItemsAlignment", component);
    }

    const BackgroundUrlProperty = component.Properties.find(
      (row: IApplicationComponentPropertyModel) => row.Name === "BackgroundUrl"
    );

    if (!BackgroundUrlProperty) {
      this.onPropertyChange("BackgroundUrl", component);
    }
  }

  setProperties(component: IApplicationComponentModel): void {
    const columnsProperty = component.Properties?.find(
      (row: IApplicationComponentPropertyModel) => row.Name === "Columns"
    );

    if (columnsProperty && columnsProperty.Value) {
      this.Columns = columnsProperty.Value.NumericValue;
    }

    const itemsAlignmentProperty = component.Properties?.find(
      (row: IApplicationComponentPropertyModel) => row.Name === "ItemsAlignment"
    );

    if (itemsAlignmentProperty && itemsAlignmentProperty.Value) {
      this.ItemsAlignment = ItemsAlignmentTypeHelper.getValue(
        itemsAlignmentProperty.Value.StringValue
      );
    }

    const backgroundUrlProperty = component.Properties?.find(
      (row: IApplicationComponentPropertyModel) => row.Name === "BackgroundUrl"
    );

    if (backgroundUrlProperty && backgroundUrlProperty.Value) {
      this.BackgroundUrl = backgroundUrlProperty.Value.StringValue;
      this.BackgroundAbsoluteUrl = backgroundUrlProperty.Value.UrlValue;
    }
  }

  setProperty(property: IApplicationComponentPropertyModel) {
    switch (property.Name) {
      case "Columns":
        property.PropertyType = PropertyType.Numeric;
        property.IsEnumerable = false;
        break;
      case "ItemsAlignment":
        property.PropertyType = PropertyType.String;
        property.IsEnumerable = false;
        break;
      case "BackgroundUrl":
        property.PropertyType = PropertyType.Url;
        property.IsEnumerable = false;
        break;
    }
  }

  setPropertyValue(property: IApplicationComponentPropertyModel) {
    switch (property.Name) {
      case "Columns":
        if (property.Value) {
          property.Value.NumericValue = this.Columns;
        }
        break;
      case "ItemsAlignment":
        if (property.Value) {
          property.Value.StringValue = ItemsAlignmentTypeHelper.getStringValue(
            this.ItemsAlignment
          );
        }
        break;
      case "BackgroundUrl":
        if (property.Value) {
          property.Value.StringValue = this.BackgroundUrl;
          property.Value.UrlValue = this.BackgroundAbsoluteUrl;
        }
        break;
    }
  }
}
