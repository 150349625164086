import React, { memo } from "react";
import { Text } from "../Typography";
interface ILabeledValueTextProps {
  label?: any;
  value?: any;
}

export const LabeledValueText = memo(
  ({ label, value }: ILabeledValueTextProps) => (
    <p>
      <Text>{label}:&nbsp;</Text>
      <Text style={{ fontWeight: 700 }}>
        <strong>{value}</strong>
      </Text>
    </p>
  )
);
