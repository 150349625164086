import { IProductModel } from "@bms/common-services";
import {
  AgeRestrictionTag,
  Button,
  Col,
  Form,
  Link,
  Row,
  SectionGrid,
  SectionGridItem,
} from "@bms/common-ui";
import React from "react";
import { useTranslation } from "react-i18next";
import { AssetTypeTag } from "../../../Asset/components";
import { ROUTES as ASSET_ROUTES } from "../../../Asset/constants";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

export interface IProductFormProps {
  product?: IProductModel;
}

export const ProductForm: React.FC<IProductFormProps> = ({ product }) => {
  const { t } = useTranslation();

  return (
    <SectionGrid style={{ maxWidth: "900px" }}>
      <SectionGridItem header={t("COMMON_GENERAL_INFORMATION_TILE")}>
        <Form name="ProductForm" {...formItemLayout} className="ProductForm">
          <Row
            direction="column"
            justify="space-between"
            className="full-height"
          >
            <Col>
              <Form.Item label={t("MODEL_TITLE")}>
                <span>{product?.Title}</span>
              </Form.Item>
              <Form.Item label={t("MODEL_DESCRIPTION")}>
                <span>{product?.Description}</span>
              </Form.Item>
              <Form.Item label={t("MODEL_TYPE_CODE")}>
                <AssetTypeTag type={product?.TypeCode} />
              </Form.Item>
              <Form.Item label={t("MODEL_AGE_RESTRICTION")}>
                <AgeRestrictionTag age={product?.AgeRestrictionFrom} />
              </Form.Item>
              <Form.Item {...tailFormItemLayout}>
                <Link to={`${ASSET_ROUTES.ASSET_DETAILS}/${product?.Id}`}>
                  <Button
                    style={{ float: "right" }}
                    type="default"
                    htmlType="submit"
                  >
                    {t("BUTTON_SEE_DETAILS")}
                  </Button>
                </Link>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </SectionGridItem>
    </SectionGrid>
  );
};
