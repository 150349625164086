import { Observable, Observer } from "rxjs";
import { HTTP_METHOD } from "../../constants/http";
import { AxiosSubscriber } from "../AxiosSubscriber";
import { IAdvertisementBoardTypeModel } from "../../models";

export class AdvertisementBoardTypeService {
  get url(): string {
    return "/AdBlocksBoardTypes";
  }

  public select = (): Observable<IAdvertisementBoardTypeModel[]> =>
    new Observable(
      (observer: Observer<IAdvertisementBoardTypeModel[]>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `${this.url}/Select`,
        })
    );
}
