import { BrandingForm } from "../BrandingForm";
import React from "react";

import { Modal } from "@bms/common-ui";
import {
  IApplicationConfigurationBrandingModel,
  IApplicationConfigurationBrandingKeyTypeModel,
} from "@bms/common-services";

import { useTranslation } from "react-i18next";
import { createBranding } from "../../../../../helpers";

interface IBrandingModal {
  brandData?: IApplicationConfigurationBrandingModel;
  brandingsKeys?: IApplicationConfigurationBrandingKeyTypeModel[];
  configurationId?: number;
  visible: boolean;
  onCancel: () => any;
  onOk: () => any;
}

export const BrandingModal: React.FC<IBrandingModal> = ({
  brandData,
  brandingsKeys,
  configurationId = 0,
  visible,
  onCancel,
  onOk,
}) => {
  const { t } = useTranslation();

  const title = brandData
    ? t("BRANDING_MODAL_TITLE_EDIT")
    : t("BRANDING_MODAL_TITLE_ADD_NEW");

  const initialBrandData = createBranding({
    ApplicationConfigurationId: configurationId,
  });

  return (
    <Modal
      style={{ minWidth: "10em" }}
      title={title}
      destroyOnClose={true}
      onCancel={onCancel}
      onOk={onOk}
      visible={visible}
      footer={null}
    >
      <BrandingForm
        handleClose={onCancel}
        brandData={brandData || initialBrandData}
        brandingsKeys={brandingsKeys}
      />
    </Modal>
  );
};
