import React from "react";
import {
  Col,
  Form,
  IFormValues,
  Input,
  required,
  Row,
  Spin,
  Switch,
  TextArea,
} from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { ILanguageModel } from "@bms/common-services";

export interface IDictionaryLanguageFormProps {
  language: ILanguageModel;
  isProcessing: boolean;
  updateLanguage: (language: ILanguageModel) => void;
  insertLanguage: (language: ILanguageModel) => void;
}

export const DictionaryLanguageForm: React.FC<IDictionaryLanguageFormProps> = (
  props
) => {
  const { language, insertLanguage, updateLanguage, isProcessing } = props;
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const isNewLanguage = language.Id < 0;

  const onFinish = async (values: IFormValues) => {
    const data: ILanguageModel = {
      ...language,
      ...values,
      Sequence: parseInt(values.Sequence, 10),
    };

    if (isNewLanguage) {
      delete data.Sequence;

      insertLanguage(data);
    } else {
      updateLanguage(data);
    }
  };

  const renderSequenceField = () => {
    if (!isNewLanguage) {
      return (
        <Form.Item
          name="Sequence"
          label={t("MODEL_SEQUENCE")}
          rules={[required()]}
        >
          <Input placeholder={t("MODEL_SEQUENCE")} />
        </Form.Item>
      );
    }
  };

  return (
    <Spin spinning={isProcessing}>
      <Form
        name="DictionaryLanguageForm"
        className="DictionaryLanguageForm"
        form={form}
        initialValues={{ ...language, Sequence: `${language.Sequence}` }}
        onFinish={onFinish}
        layout="vertical"
      >
        <Row direction="column" justify="space-between" className="full-height">
          <Col>
            <Form.Item
              name="Code"
              label={t("MODEL_CODE")}
              rules={[required()]}
            >
              <Input placeholder={t("MODEL_CODE")} />
            </Form.Item>

            <Form.Item
              name="Name"
              label={t("MODEL_NAME")}
              rules={[required()]}
            >
              <Input placeholder={t("MODEL_NAME")} />
            </Form.Item>

            <Form.Item
              name="Description"
              label={t("MODEL_DESCRIPTION")}
            >
              <TextArea
                placeholder={t("MODEL_DESCRIPTION")}
                autoSize={{ minRows: 4 }}
              />
            </Form.Item>
            {renderSequenceField()}
            <Form.Item>
              <Row gutter={8}>
                <Col>
                  <Form.Item name="UpToDate" valuePropName="checked">
                    <Switch />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="UpToDate">
                    <label>{t("MODEL_UP_TO_DATE")}</label>
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
            <Form.Item>
              <Row gutter={8}>
                <Col>
                  <Form.Item name="IsRTL" valuePropName="checked">
                    <Switch />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="IsRTL">
                    <label>{t("MODEL_IS_RTL")}</label>
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};
