import { ICommonAppState } from "@bms/common-services";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
  ApplicationSearchProperties as ApplicationSearchPropertiesDefinition,
  ApplicationSearchPropertiesDispatchProps,
  ApplicationSearchPropertiesOwnProps,
  ApplicationSearchPropertiesStateProps,
} from "./ApplicationSearchProperties";

const mapStateToProps = (
  state: ICommonAppState
): ApplicationSearchPropertiesStateProps => {
  return {
    isProcessingData: state.applicationConfiguration.isProcessingData,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch
): ApplicationSearchPropertiesDispatchProps => ({});

export const ApplicationSearchProperties = connect<
  ApplicationSearchPropertiesStateProps,
  ApplicationSearchPropertiesDispatchProps,
  ApplicationSearchPropertiesOwnProps,
  ICommonAppState
>(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ApplicationSearchPropertiesDefinition));
