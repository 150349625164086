import { PropertyType } from "../../../enums";

export class PropertyTypeHelper {
  static getValue(
    value: string | number | undefined
  ): PropertyType | undefined {
    switch (value) {
      case 0:
      case "String":
        return PropertyType.String;
      case 1:
      case "Integer":
        return PropertyType.Integer;
      case 2:
      case "Numeric":
        return PropertyType.Numeric;
      case 3:
      case "Boolean":
        return PropertyType.Boolean;
      case 4:
      case "DateTimeOffset":
        return PropertyType.DateTimeOffset;
      case 5:
      case "Guid":
        return PropertyType.Guid;
      case 6:
      case "Component":
        return PropertyType.Component;
      case 7:
      case "ComponentReference":
        return PropertyType.ComponentReference;
      case 8:
      case "Url":
        return PropertyType.Url;
      default:
        return undefined;
    }
  }
}
