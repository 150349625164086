import {
  ICommonAppState,
  IResourceModel,
  CommonStore,
} from "@bms/common-services";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { TranslationDetailsForm as TranslationDetailsFormDefinition } from "./TranslationDetailsForm";

const mapStateToProps = (state: ICommonAppState) => {
  return {
    actionType: state.common.actionType,
    isProcessingData: state.common.isProcessingData,
    error: state.common.error,
    applications: state.common.applications,
    languages: state.common.languages,
    platforms: state.common.platforms,
    resources: state.common.resources,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateResource: (resource: IResourceModel) => {
    return dispatch(CommonStore.Actions.updateResource(resource));
  },
  insertResource: (resource: IResourceModel) => {
    return dispatch(CommonStore.Actions.insertResource(resource));
  },
});

export const TranslationDetailsForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(TranslationDetailsFormDefinition);
