import { Observable, Observer } from "rxjs";
import { HTTP_METHOD } from "../../constants/http";
import {
  IListModelBase,
  IQuizQuestionModel,
  UploadFileInfoModel,
} from "../../models";
import { AxiosSubscriber } from "../AxiosSubscriber";
import { SimpleServiceBase } from "../Base";

export class QuizQuestionService extends SimpleServiceBase<
  IQuizQuestionModel,
  IListModelBase<IQuizQuestionModel>,
  number,
  {}
> {
  get url(): string {
    return "/QuizQuestions";
  }

  public getUploadFileInfo = (
    guid: string,
    index: number,
    key?: string
  ): Observable<UploadFileInfoModel> =>
    new Observable(
      (observer: Observer<UploadFileInfoModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: {
              quizQuestionGuid: guid,
              imageNumber: index,
              key,
            },
          },
          method: HTTP_METHOD.GET,
          url: `${this.url}/GetUploadFileInfo`,
        })
    );
}
