import React, { useCallback, useRef, useState } from "react";
import {
  ComponentType,
  IApplicationScreenModel,
  PlatformType,
} from "@bms/common-services";
import { Button, Icon } from "@bms/common-ui";
import {
  ApplicationIntroPropertyModel,
  LayoutOptionsModel,
} from "../../models";
import { VolumeIcon, VolumeMuteIcon } from "../../../../../../resources/icons";

import "./ApplicationIntroPreview.scss";

import contentWebUrl from "./resources/intro-content-web.png";

export interface IApplicationIntroPreviewProps {
  screen: IApplicationScreenModel;
  layoutOptions: LayoutOptionsModel;
  children?: React.ReactNode;
}

export const ApplicationIntroPreview: React.FC<IApplicationIntroPreviewProps> = ({
  screen,
  layoutOptions,
  children,
}: IApplicationIntroPreviewProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMute, setIsMute] = useState(true);
  const component = screen.Components?.find(
    (component) =>
      component.ComponentTypeCode === ComponentType.ScreenProperties
  );
  const properties = new ApplicationIntroPropertyModel(component);

  const onVideoPlayPauseToggle = useCallback(() => {
    if (isPlaying) {
      videoRef.current?.pause();
      setIsPlaying(false);
    } else {
      videoRef.current?.play();
      setIsPlaying(true);
    }
  }, [isPlaying]);

  const onVideoVolumeToggle = useCallback(() => {
    setIsMute(!isMute);
  }, [isMute]);

  const renderContent = () => {
    if (
      layoutOptions.Platform === PlatformType.Web ||
      layoutOptions.Platform === PlatformType.Any
    ) {
      let videoView: React.ReactNode;

      if (properties.BackgroundVideoAbsoluteUrl) {
        videoView = (
          <>
            <video ref={videoRef} muted={isMute} loop>
              <source
                src={properties.BackgroundVideoAbsoluteUrl}
                type="video/mp4"
              />
            </video>
            <Button
              shape="circle"
              type="text"
              className="video-play-pause-button"
              title={isPlaying ? "Pause" : "Play"}
              icon={
                <Icon
                  type={isPlaying ? "PauseCircle" : "PlayCircle"}
                  className="video-button-icon"
                />
              }
              onClick={onVideoPlayPauseToggle}
            />
            <Button
              shape="circle"
              type="text"
              className="video-mute-button"
              title={isMute ? "Unmute" : "Mute"}
              icon={
                isMute ? (
                  <VolumeMuteIcon className="video-mute-button-icon" />
                ) : (
                  <VolumeIcon className="video-mute-button-icon" />
                )
              }
              onClick={onVideoVolumeToggle}
            />
          </>
        );
      }

      return (
        <>
          {videoView}
          <img
            style={{
              position: "absolute",
              objectFit: "cover",
              width: "100%",
              height: "100%",
            }}
            alt={"content"}
            src={contentWebUrl}
          />
        </>
      );
    }
  };

  return (
    <div
      className="ApplicationIntroPreview"
      style={{ position: "relative", display: "block" }}
    >
      {renderContent()}
      {children}
    </div>
  );
};
