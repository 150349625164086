import * as Consts from "./consts";
import { CommonActionsTypes, ICommonState } from "./types";

export const initialState: ICommonState = {
  actionType: undefined,
  resources: {
    Entities: [],
    TotalCount: 0,
    Filter: {
      LanguageCodes: [],
      PageNumber: 1,
      PageSize: 10,
      IncludeCount: true,
    },
  },
  resourcesBrowser: {
    Entities: [],
    TotalCount: 0,
    Filter: {
      LanguageCodes: ["en"],
      PageNumber: 1,
      PageSize: 10,
      IncludeCount: true,
    },
  },
  resourcesByKey: {
    Entities: [],
    TotalCount: 0,
    Filter: {
      PageNumber: 1,
      PageSize: 10,
      IncludeCount: true,
    },
  },
  applications: [],
  languages: [],
  platforms: [],
  consents: [],
  isLoadingData: false,
  isProcessingData: false,
};

export const commonReducer = (
  state = initialState,
  action: CommonActionsTypes
): ICommonState => {
  state.actionType = action.type;

  switch (action.type) {
    case Consts.SEARCH_RESOURCE: {
      return {
        ...state,
        resources: {
          ...state.resources,
          IsLoading: true,
          Error: undefined,
        },
      };
    }
    case Consts.SEARCH_RESOURCE_SUCCESS: {
      return {
        ...state,
        resources: {
          ...action.payload,
          IsLoading: false,
        },
      };
    }
    case Consts.SEARCH_RESOURCE_FAILURE: {
      return {
        ...state,
        resources: {
          ...state.resources,
          IsLoading: false,
          Error: action.error,
        },
      };
    }
    case Consts.BROWSE_RESOURCE: {
      return {
        ...state,
        resourcesBrowser: {
          ...state.resourcesBrowser,
          Entities: [],
          IsLoading: true,
        },
      };
    }
    case Consts.BROWSE_RESOURCE_SUCCESS: {
      return {
        ...state,
        resourcesBrowser: {
          ...action.payload,
          IsLoading: false,
        },
      };
    }
    case Consts.BROWSE_RESOURCE_FAILURE: {
      return {
        ...state,
        resourcesBrowser: {
          ...state.resourcesBrowser,
          IsLoading: false,
          Error: action.error,
        },
      };
    }
    case Consts.SELECT_RESOURCE: {
      return {
        ...state,
        isLoadingData: true,
      };
    }
    case Consts.SELECT_RESOURCE_SUCCESS: {
      return {
        ...state,
        resourcesByKey: action.payload,
        isLoadingData: false,
      };
    }
    case Consts.SELECT_RESOURCE_FAILURE: {
      return {
        ...state,
        error: action.error,
        isLoadingData: false,
      };
    }
    case Consts.SELECT_APPLICATION: {
      return {
        ...state,
        applications: [],
        isLoadingData: true,
      };
    }
    case Consts.SELECT_APPLICATION_SUCCESS: {
      return {
        ...state,
        applications: action.payload,
        isLoadingData: false,
      };
    }
    case Consts.SELECT_APPLICATION_FAILURE: {
      return {
        ...state,
        error: action.error,
        isLoadingData: false,
      };
    }
    case Consts.SELECT_LANGUAGE: {
      return {
        ...state,
        languages: [],
        isLoadingData: true,
      };
    }
    case Consts.SELECT_LANGUAGE_SUCCESS: {
      return {
        ...state,
        languages: action.payload,
        isLoadingData: false,
      };
    }
    case Consts.SELECT_LANGUAGE_FAILURE: {
      return {
        ...state,
        error: action.error,
        isLoadingData: false,
      };
    }
    case Consts.UPDATE_LANGUAGE: {
      return {
        ...state,
        isProcessingData: true,
      };
    }
    case Consts.UPDATE_LANGUAGE_SUCCESS: {
      return {
        ...state,
        language: action.payload,
        isProcessingData: false,
      };
    }
    case Consts.UPDATE_LANGUAGE_FAILURE: {
      return {
        ...state,
        error: action.error,
        isProcessingData: false,
      };
    }
    case Consts.SELECT_PLATFORM: {
      return {
        ...state,
        platforms: [],
        isLoadingData: true,
      };
    }
    case Consts.SELECT_PLATFORM_SUCCESS: {
      return {
        ...state,
        platforms: action.payload,
        isLoadingData: false,
      };
    }
    case Consts.SELECT_PLATFORM_FAILURE: {
      return {
        ...state,
        error: action.error,
        isLoadingData: false,
      };
    }
    case Consts.UPDATE_RESOURCE: {
      return {
        ...state,
        isProcessingData: true,
      };
    }
    case Consts.UPDATE_RESOURCE_SUCCESS: {
      return {
        ...state,
        resource: action.payload,
        isProcessingData: false,
      };
    }
    case Consts.UPDATE_RESOURCE_FAILURE: {
      return {
        ...state,
        error: action.error,
        isProcessingData: false,
      };
    }
    case Consts.INSERT_RESOURCE: {
      return {
        ...state,
        isProcessingData: true,
      };
    }
    case Consts.INSERT_RESOURCE_SUCCESS: {
      return {
        ...state,
        resource: action.payload,
        isProcessingData: false,
      };
    }
    case Consts.INSERT_RESOURCE_FAILURE: {
      return {
        ...state,
        error: action.error,
        isProcessingData: false,
      };
    }
    case Consts.DELETE_RESOURCE: {
      return {
        ...state,
        isProcessingData: true,
      };
    }
    case Consts.DELETE_RESOURCE_SUCCESS: {
      return {
        ...state,
        resource: undefined,
        isProcessingData: false,
      };
    }
    case Consts.DELETE_RESOURCE_FAILURE: {
      return {
        ...state,
        error: action.error,
        isProcessingData: false,
      };
    }
    case Consts.DELETE_RESOURCES_BY_KEY: {
      return {
        ...state,
        isProcessingData: true,
      };
    }
    case Consts.DELETE_RESOURCES_BY_KEY_SUCCESS: {
      return {
        ...state,
        resource: undefined,
        isProcessingData: false,
      };
    }
    case Consts.DELETE_RESOURCES_BY_KEY_FAILURE: {
      return {
        ...state,
        error: action.error,
        isProcessingData: false,
      };
    }
    case Consts.SELECT_CONSENT: {
      return {
        ...state,
        isLoadingData: true,
      };
    }
    case Consts.SELECT_CONSENT_SUCCESS: {
      return {
        ...state,
        consents: action.payload,
        isLoadingData: false,
      };
    }
    case Consts.SELECT_CONSENT_FAILURE: {
      return {
        ...state,
        error: action.error,
        isLoadingData: false,
      };
    }
    case Consts.UPDATE_CONSENT: {
      return {
        ...state,
        isProcessingData: true,
      };
    }
    case Consts.UPDATE_CONSENT_SUCCESS: {
      return {
        ...state,
        consent: action.payload,
        isProcessingData: false,
      };
    }
    case Consts.UPDATE_CONSENT_FAILURE: {
      return {
        ...state,
        error: action.error,
        isProcessingData: false,
      };
    }
    case Consts.INSERT_CONSENT: {
      return {
        ...state,
        isProcessingData: true,
      };
    }
    case Consts.INSERT_CONSENT_SUCCESS: {
      return {
        ...state,
        consent: action.payload,
        isProcessingData: false,
      };
    }
    case Consts.INSERT_CONSENT_FAILURE: {
      return {
        ...state,
        error: action.error,
        isProcessingData: false,
      };
    }
    case Consts.DELETE_CONSENT: {
      return {
        ...state,
        isProcessingData: true,
      };
    }
    case Consts.DELETE_CONSENT_SUCCESS: {
      return {
        ...state,
        consent: undefined,
        isProcessingData: false,
      };
    }
    case Consts.DELETE_CONSENT_FAILURE: {
      return {
        ...state,
        error: action.error,
        isProcessingData: false,
      };
    }
    default:
      return state;
  }
};
