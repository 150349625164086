import React from "react";
import { difference } from "lodash";
import { ITransferItem, ITransferProps, Transfer } from "../Transfer";
import { TableWithDraggableSorter } from "../TableWithDraggableSorter";
import { ITableCurrentDataSource, ITableFilter, ITablePaginationConfig, ITableSorterResult, Table } from "../Table/Table";

const ROW_HEIGHT = 42;
const PAGINATION_HEIGHT = 30;

export interface ITableTransferProps extends ITransferProps {
  dataTarget: any[];
  leftColumns: any[];
  rightColumns: any[];
  pageSize: number;
  leftLoading?: boolean;
  rightLoading?: boolean;
  draggable?: boolean;
  onMoveRow?: (dragIndex: number, hoverIndex: number) => void;
  onTableChange?: (
    direction: "left" | "right",
    pagination: ITablePaginationConfig,
    filters: ITableFilter,
    sorter: ITableSorterResult<ITransferItem>,
    extra: ITableCurrentDataSource<ITransferItem>
  ) => void;
}

export const TableTransfer: React.FC<ITableTransferProps> = ({
  pageSize,
  draggable,
  onMoveRow,
  leftLoading,
  rightLoading,
  leftColumns,
  rightColumns,
  onTableChange,
  dataTarget,
  dataSource,
  onChange,
  ...restProps
}) => (
  <Transfer
    dataSource={[]}
    showSelectAll={false}
    onChange={(targetKeys, direction, moveKeys) => {
      onChange?.(
        targetKeys,
        direction,
        direction === "left" && draggable
          ? moveKeys.map((index) => dataTarget[Number(index)].key)
          : moveKeys
      );
    }}
    {...restProps}
  >
    {({
      direction,
      onItemSelectAll,
      onItemSelect,
      selectedKeys: listSelectedKeys,
      disabled: listDisabled,
    }) => {
      const columns = direction === "left" ? leftColumns : rightColumns;
      const source = direction === "left" ? dataSource : dataTarget;

      const draggableDirection = direction === "right" && draggable;
      const commonProps = {
        rowSelection: {
          getCheckboxProps: (item: any) => ({
            disabled: listDisabled || item.disabled,
            className: "aaaa",
          }),
          onSelectAll(selected: any, selectedRows: any) {
            const treeSelectedKeys = selectedRows
              .filter((item: any) => item && !item.disabled)
              .map(({ key, index }: any) => (draggableDirection ? index : key));
            const diffKeys = selected
              ? difference(treeSelectedKeys, listSelectedKeys)
              : difference(listSelectedKeys, treeSelectedKeys);
            onItemSelectAll(diffKeys, selected);
          },
          onSelect({ key, index }: any, selected: any) {
            onItemSelect(draggableDirection ? index : key, selected);
          },
          selectedRowKeys: listSelectedKeys,
        },
        rowClassName: "transfer-table-row",
        loading: direction === "left" ? leftLoading : rightLoading,
        columns,
        dataSource: source,
        onChange: (
          pagination: ITablePaginationConfig,
          filters: ITableFilter,
          sorter: ITableSorterResult<ITransferItem>,
          extra: ITableCurrentDataSource<ITransferItem>) => {
          onItemSelectAll(listSelectedKeys, false);
          onTableChange?.(direction, pagination, filters, sorter, extra);
        },
        size: "small" as "small",
        style: {
          pointerEvents: listDisabled ? "none" : undefined,
        } as React.CSSProperties,
      };

      if (draggableDirection) {
        return (
          <TableWithDraggableSorter
            {...commonProps}
            dragType="handler"
            handlerRight
            pagination={false}
            scroll={{
              y: `${pageSize * ROW_HEIGHT + PAGINATION_HEIGHT}px`,
              x: 0,
            }}
            onMoveRow={(dragIndex, hoverIndex) => {
              onItemSelectAll(listSelectedKeys, false);
              onMoveRow?.(dragIndex, hoverIndex);
            }}
            onRow={({ index, disabled: itemDisabled }) => ({
              style: {
                cursor: itemDisabled ? "not-allowed" : "pointer",
              },
              onClick: () => {
                if (itemDisabled || listDisabled) {
                  return;
                }
                onItemSelect(index, !listSelectedKeys.includes(index));
              },
            })}
          />
        );
      }

      return (
        <Table
          {...commonProps}
          pagination={{ pageSize, showSizeChanger: false }}
          onRow={({ key, disabled: itemDisabled }) => ({
            style: {
              cursor: itemDisabled ? "not-allowed" : "pointer",
            },
            onClick: () => {
              if (itemDisabled || listDisabled) {
                return;
              }
              onItemSelect(key, !listSelectedKeys.includes(key));
            },
          })}
        />
      );
    }}
  </Transfer>
);
