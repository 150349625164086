import { SimpleServiceBase } from "../Base";
import {
  ITestCaseModel,
  ITestCasesFilterModel,
  ITestCasesListModel,
} from "../../models";

export class TestCasesService extends SimpleServiceBase<
  ITestCaseModel,
  ITestCasesListModel,
  number,
  ITestCasesFilterModel
> {
  get url() {
    return "/TestCases";
  }
}
