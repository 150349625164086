import { IConfigModel } from "../../models/Config";
import { IErrorModel } from "../../models/ErrorModel";

export const GET_CONFIGURATION = "GET_CONFIGURATION";
export const GET_CONFIGURATION_SUCCESS = "GET_CONFIGURATION_SUCCESS";
export const GET_CONFIGURATION_FAILURE = "GET_CONFIGURATION_FAILURE";

export interface IConfigState {
  config?: IConfigModel;
  error?: IErrorModel;
  isLoading?: boolean;
}

export interface IGetConfigAction {
  type: typeof GET_CONFIGURATION;
}

export interface IGetConfigSuccessAction {
  type: typeof GET_CONFIGURATION_SUCCESS;
  payload: IConfigModel;
}

export interface IGetConfigFailureAction {
  type: typeof GET_CONFIGURATION_FAILURE;
  error?: IErrorModel;
}

export type ConfigurationActionsTypes =
  | IGetConfigAction
  | IGetConfigSuccessAction
  | IGetConfigFailureAction;
