import {
  ApplicationConfigurationStore,
  ICommonAppState,
} from "@bms/common-services";
import { ApplicationScreensTypesList as ApplicationScreensTypesListDefinition } from "./ApplicationScreensTypesList";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { withTranslation } from "react-i18next";

const mapStateToProps = (state: ICommonAppState) => {
  return {
    isLoadingData: state.applicationConfiguration.isLoadingData,
    screensTypes: state.applicationConfiguration.screensTypes,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  selectScreensTypes: () => {
    return dispatch(ApplicationConfigurationStore.Actions.selectScreensTypes());
  },
});

export const ApplicationScreensTypesList = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ApplicationScreensTypesListDefinition));
