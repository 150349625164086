import { ActionsObservable, ofType, StateObservable } from "redux-observable";
import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { ICommonAppState } from "..";
import {
  IApplicationModel,
  IConsentModel,
  IErrorModel,
  ILanguageModel,
  IPlatformModel,
  IResourceModel,
  IResourcesListModel,
} from "../../models";
import {
  ApplicationService,
  ConsentService,
  LanguageService,
  PlatformService,
  ResourceService,
} from "../../services";
import {
  browseResourceFailure,
  browseResourceSuccess,
  deleteConsentFailure,
  deleteConsentSuccess,
  deleteResourceFailure,
  deleteResourcesByKeyFailure,
  deleteResourcesByKeySuccess,
  deleteResourceSuccess,
  insertConsentFailure,
  insertConsentSuccess,
  insertResourceFailure,
  insertResourceSuccess,
  searchResourceFailure,
  searchResourceSuccess,
  selectApplicationsFailure,
  selectApplicationsSuccess,
  selectConsentsFailure,
  selectConsentsSuccess,
  selectLanguagesFailure,
  selectLanguagesSuccess,
  selectPlatformsFailure,
  selectPlatformsSuccess,
  selectResourceFailure,
  selectResourceSuccess,
  updateConsentFailure,
  updateConsentSuccess,
  updateLanguageFailure,
  updateLanguageSuccess,
  updateResourceFailure,
  updateResourceSuccess,
} from "./actions";
import * as Consts from "./consts";
import {
  IBrowseResourceAction,
  IDeleteConsentAction,
  IDeleteResourceAction,
  IDeleteResourcesByKeyAction,
  IInsertConsentAction,
  IInsertResourceAction,
  ISearchResourceAction,
  ISelectApplicationAction,
  ISelectConsentsAction,
  ISelectLanguageAction,
  ISelectPlatformAction,
  ISelectResourceAction,
  IUpdateConsentAction,
  IUpdateLanguageAction,
  IUpdateResourceAction,
} from "./types";

const resourceService = new ResourceService();
const applicationService = new ApplicationService();
const languageService = new LanguageService();
const platformService = new PlatformService();
const consentService = new ConsentService();

const searchResourceEpic = (
  action$: ActionsObservable<ISearchResourceAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.SEARCH_RESOURCE),
    switchMap((action: ISearchResourceAction) =>
      resourceService.search(action.filter).pipe(
        map((data: IResourcesListModel) => {
          data.Filter = action.filter;
          return searchResourceSuccess(data);
        }),
        catchError((error: IErrorModel) => of(searchResourceFailure(error)))
      )
    )
  );

const browseResourceEpic = (
  action$: ActionsObservable<IBrowseResourceAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.BROWSE_RESOURCE),
    switchMap((action: IBrowseResourceAction) =>
      resourceService.search(action.filter).pipe(
        map((data: IResourcesListModel) => {
          data.Filter = action.filter;
          return browseResourceSuccess(data);
        }),
        catchError((error: IErrorModel) => of(browseResourceFailure(error)))
      )
    )
  );

const selectResourceEpic = (
  action$: ActionsObservable<ISelectResourceAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.SELECT_RESOURCE),
    switchMap((action: ISelectResourceAction) =>
      resourceService.selectByResourceKey(action.resourceKey).pipe(
        map((data: IResourcesListModel) => {
          return selectResourceSuccess(data);
        }),
        catchError((error: IErrorModel) => of(selectResourceFailure(error)))
      )
    )
  );

const selectApplicationsEpic = (
  action$: ActionsObservable<ISelectApplicationAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.SELECT_APPLICATION),
    switchMap((action: ISelectApplicationAction) =>
      applicationService.select().pipe(
        map((data: IApplicationModel[]) => {
          return selectApplicationsSuccess(data);
        }),
        catchError((error: IErrorModel) => of(selectApplicationsFailure(error)))
      )
    )
  );

const selectLanguagesEpic = (
  action$: ActionsObservable<ISelectLanguageAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.SELECT_LANGUAGE),
    switchMap((action: ISelectLanguageAction) =>
      languageService.select().pipe(
        map((data: ILanguageModel[]) => {
          return selectLanguagesSuccess(data);
        }),
        catchError((error: IErrorModel) => of(selectLanguagesFailure(error)))
      )
    )
  );

const updateLanguageEpic = (
  action$: ActionsObservable<IUpdateLanguageAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.UPDATE_LANGUAGE),
    switchMap((action: IUpdateLanguageAction) =>
      languageService.update(action.payload).pipe(
        map((data: ILanguageModel) => {
          return updateLanguageSuccess(data);
        }),
        catchError((error: IErrorModel) => of(updateLanguageFailure(error)))
      )
    )
  );

const selectPlatformsEpic = (
  action$: ActionsObservable<ISelectPlatformAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.SELECT_PLATFORM),
    switchMap((action: ISelectPlatformAction) =>
      platformService.select().pipe(
        map((data: IPlatformModel[]) => {
          return selectPlatformsSuccess(data);
        }),
        catchError((error: IErrorModel) => of(selectPlatformsFailure(error)))
      )
    )
  );

const updateResourceEpic = (
  action$: ActionsObservable<IUpdateResourceAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.UPDATE_RESOURCE),
    switchMap((action: IUpdateResourceAction) =>
      resourceService.update(action.payload).pipe(
        map((data: IResourceModel) => {
          return updateResourceSuccess(data);
        }),
        catchError((error: IErrorModel) => of(updateResourceFailure(error)))
      )
    )
  );

const insertResourceEpic = (
  action$: ActionsObservable<IInsertResourceAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.INSERT_RESOURCE),
    switchMap((action: IInsertResourceAction) =>
      resourceService.insert(action.payload).pipe(
        map((data: IResourceModel) => {
          return insertResourceSuccess(data);
        }),
        catchError((error: IErrorModel) => of(insertResourceFailure(error)))
      )
    )
  );

const deleteResourceEpic = (
  action$: ActionsObservable<IDeleteResourceAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.DELETE_RESOURCE),
    switchMap((action: IDeleteResourceAction) =>
      resourceService.delete(action.payload).pipe(
        map(() => {
          return deleteResourceSuccess();
        }),
        catchError((error: IErrorModel) => of(deleteResourceFailure(error)))
      )
    )
  );

const deleteResourcesByKeyEpic = (
  action$: ActionsObservable<IDeleteResourcesByKeyAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.DELETE_RESOURCES_BY_KEY),
    switchMap((action: IDeleteResourcesByKeyAction) =>
      resourceService.deleteByResourceKey(action.payload).pipe(
        map(() => {
          return deleteResourcesByKeySuccess();
        }),
        catchError((error: IErrorModel) =>
          of(deleteResourcesByKeyFailure(error))
        )
      )
    )
  );

const selectConsentEpic = (
  action$: ActionsObservable<ISelectConsentsAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.SELECT_CONSENT),
    switchMap((action: ISelectConsentsAction) =>
      consentService.select().pipe(
        map((data: IConsentModel[]) => {
          return selectConsentsSuccess(data);
        }),
        catchError((error: IErrorModel) => of(selectConsentsFailure(error)))
      )
    )
  );

const updateConsentEpic = (
  action$: ActionsObservable<IUpdateConsentAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.UPDATE_CONSENT),
    switchMap((action: IUpdateConsentAction) =>
      consentService.update(action.payload).pipe(
        map((data: IConsentModel) => {
          return updateConsentSuccess(data);
        }),
        catchError((error: IErrorModel) => of(updateConsentFailure(error)))
      )
    )
  );

const insertConsentEpic = (
  action$: ActionsObservable<IInsertConsentAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.INSERT_CONSENT),
    switchMap((action: IInsertConsentAction) =>
      consentService.insert(action.payload).pipe(
        map((data: IConsentModel) => {
          return insertConsentSuccess(data);
        }),
        catchError((error: IErrorModel) => of(insertConsentFailure(error)))
      )
    )
  );

const deleteConsentEpic = (
  action$: ActionsObservable<IDeleteConsentAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.DELETE_CONSENT),
    switchMap((action: IDeleteConsentAction) =>
      consentService.delete(action.payload).pipe(
        map(() => {
          return deleteConsentSuccess();
        }),
        catchError((error: IErrorModel) => of(deleteConsentFailure(error)))
      )
    )
  );

export const commonEpics = [
  selectApplicationsEpic,
  selectLanguagesEpic,
  updateLanguageEpic,
  selectPlatformsEpic,
  searchResourceEpic,
  browseResourceEpic,
  selectResourceEpic,
  updateResourceEpic,
  insertResourceEpic,
  deleteResourceEpic,
  deleteResourcesByKeyEpic,
  selectConsentEpic,
  insertConsentEpic,
  updateConsentEpic,
  deleteConsentEpic,
];
