import React, { memo } from "react";
import { AnalyticsAggregationPeriodType } from "@bms/common-services";
import { useTranslation } from "react-i18next";
import { Choose, ChooseOption } from "@bms/common-ui";

interface IAssetImpressionsAggregationTypeSelect {
  value?: AnalyticsAggregationPeriodType;
  onChange?: (newValue: AnalyticsAggregationPeriodType) => void;
}

export const AssetImpressionsAggregationTypeSelect = memo(
  ({
    value = AnalyticsAggregationPeriodType.YEAR,
    onChange,
  }: IAssetImpressionsAggregationTypeSelect) => {
    const { t } = useTranslation();

    const onValueChange = (newValue: string) => {
      if (onChange) {
        onChange(newValue as AnalyticsAggregationPeriodType);
      }
    };

    return (
      <Choose
        value={value}
        onChange={onValueChange}
        getPopupContainer={(trigger) => trigger.parentNode}
      >
        {Object.keys(AnalyticsAggregationPeriodType)
          .map(
            (key) =>
              AnalyticsAggregationPeriodType[
                key as keyof typeof AnalyticsAggregationPeriodType
              ]
          )
          .map((val, index) => {
            let translatedValue;
            switch (val) {
              case AnalyticsAggregationPeriodType.YEAR:
                translatedValue = "COMMON_YEAR";
                break;
              case AnalyticsAggregationPeriodType.MONTH:
                translatedValue = "COMMON_MONTH";
                break;
              case AnalyticsAggregationPeriodType.DAY:
                translatedValue = "COMMON_DAY";
                break;
            }
            return (
              <ChooseOption key={`select--${val}--${index}`} value={val}>
                {t(translatedValue)}
              </ChooseOption>
            );
          })}
      </Choose>
    );
  }
);
