export const SELECT_CURRENCIES = "SELECT_CURRENCIES";
export const SELECT_CURRENCIES_SUCCESS = "SELECT_CURRENCIES_SUCCESS";
export const SELECT_CURRENCIES_FAILURE = "SELECT_CURRENCIES_FAILURE";

export const UPDATE_CURRENCY = "UPDATE_CURRENCY";
export const UPDATE_CURRENCY_SUCCESS = "UPDATE_CURRENCY_SUCCESS";
export const UPDATE_CURRENCY_FAILURE = "UPDATE_CURRENCY_FAILURE";

export const INSERT_CURRENCY = "INSERT_CURRENCY";
export const INSERT_CURRENCY_SUCCESS = "INSERT_CURRENCY_SUCCESS";
export const INSERT_CURRENCY_FAILURE = "INSERT_CURRENCY_FAILURE";

export const DELETE_CURRENCY = "DELETE_CURRENCY";
export const DELETE_CURRENCY_SUCCESS = "DELETE_CURRENCY_SUCCESS";
export const DELETE_CURRENCY_FAILURE = "DELETE_CURRENCY_FAILURE";